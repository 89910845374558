import request from './config/request';

const search = (filter, page = 1, size = 25) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/units` +
        query,
        vtAuth: true
    })
}

const create = async unit_data => {
    return request({
        method: "POST",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/units`,
        data: unit_data,
        vtAuth: true
    })
}

const deleteUnit = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/units/${id}`,
        vtAuth: true
    })
}

const update = async (id, unit_data) => {
    return request({
        method: "POST",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/units/${id}`,
        data: unit_data,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/units/${id}/edit`,
        vtAuth: true
    })
}

const findUnit = async (id) => {
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/find-unit/${id}`,
        vtAuth: true
    })
}

export { search, create, update, loadDataEditform, deleteUnit,findUnit }
