import React from "react"
import { useSelector, useDispatch } from "react-redux"
import Snowfall from "react-snowfall"
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

import { Link } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import profile from "assets/images/profile-img.png"
import logovitta2 from "assets/images/logovitta2.jpg"

import { userLogin } from "store/auth/actions"

const Login = () => {
    const dispatch = useDispatch()

    const loginState = useSelector(state => state.auth.login)

    const handleValidSubmit = async (event, values) => {
        dispatch(userLogin(values))
    }

    return (
        <React.Fragment>
            {/* <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                }}
            >
                <Snowfall
                    color="#5ac5fa"
                    style={{ position: "fixed", zIndex: "10000" }}
                />
            </div> */}
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">
                                                    Bem Vindo de Volta!
                                                </h5>
                                                <p>
                                                    Faça login para acessar o
                                                    VittáNet.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img
                                                src={profile}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logovitta2}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="100"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v)
                                            }}
                                        >
                                            {loginState.error &&
                                            typeof loginState.error ===
                                                "string" ? (
                                                <Alert color="danger">
                                                    {loginState.error}
                                                </Alert>
                                            ) : null}
                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    value=""
                                                    className="form-control"
                                                    placeholder="Seu email"
                                                    type="email"
                                                    required
                                                />
                                            </div>

                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label="Senha"
                                                    value=""
                                                    type="password"
                                                    required
                                                    placeholder="Insira sua Senha"
                                                />
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Lembrar me
                                                </label>
                                            </div>

                                            <div className="mt-3">
                                                {loginState.loading ? (
                                                    <div className="mt-4 text-center">
                                                        <Spinner
                                                            className="mr-2"
                                                            color="primary"
                                                        />
                                                        <p>Verificando...</p>
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Acessar
                                                    </button>
                                                )}
                                            </div>

                                            {/*
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            {//outras formas de login 
                            }
                          </li>
                          <li className="list-inline-item">
                          </li>
                        </ul>
                      </div>
                      */}

                                            {/*
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1" />
                          Esqueceu sua Senha?
                        </Link>
                      </div>
                    */}
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            {/*
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
              */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Login
