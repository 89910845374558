import request from "./config/request"

const create = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/vitta-cards`,
        data: data,
        vtAuth: true,
    })
}
const createDependent = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/dependents`,
        data: data,
        vtAuth: true,
    })
}
const deleteDependent = async (id) => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/dependents/${id}`,
        data: {},
        vtAuth: true,
    })
}
const changeStatus = async (id, data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/${id}/status`,
        data: data,
        vtAuth: true,
    })
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.expand_dependents)
        query += "&expand_dependents=" + filter.expand_dependents
    if (filter.term) query += "&term=" + filter.term
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards` +
            query,
        vtAuth: true,
    })
}

export { create, changeStatus, search, createDependent,deleteDependent }
