import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Modal, Row, Col } from "reactstrap"

const ModalInfoPartnerStatus = ({ show, data, onClose }) => {
    if (!show) return <></>
    return (
        <Modal isOpen={show} centered={true} size="xs">
            <div className="card-body">
                <Row>
                    <Col xs="12" sm="10">
                        <Col sm="12">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#8f969d",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#8f969d", fontWeight: "500" }}
                            >
                                Aguardando detalhes
                            </span>
                        </Col>
                        {/* <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#c5ab3d",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#c5ab3d", fontWeight: "500" }}
                            >
                                Amostra no Setor Técnico
                            </span>
                        </Col> */}
                        {/* <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#336a9c",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#336a9c", fontWeight: "500" }}
                            >
                                Paciente Cadastrado
                            </span>
                        </Col> */}
                        {/* <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#c4822d",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#c4822d", fontWeight: "500" }}
                            >
                                Amostra na Distribuição - CDA
                            </span>
                        </Col> */}
                        <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#266634",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#266634", fontWeight: "500" }}
                            >
                                Resultado Liberado
                            </span>
                        </Col>
                        <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#8d4ca2",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#8d4ca2", fontWeight: "500" }}
                            >
                                Cancelado
                            </span>
                        </Col>
                        {/* <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#a3a87d",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#a3a87d", fontWeight: "500" }}
                            >
                                Resultado Parcial
                            </span>
                        </Col> */}
                        <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#6b5c91",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#6b5c91", fontWeight: "500" }}
                            >
                                Rejeitar e/ou recoletar
                            </span>
                        </Col>
                        <Col sm="12" className="mt-2">
                            {" "}
                            <i
                                className="fas fa-circle"
                                style={{
                                    position: "relative",
                                    top: "3px",
                                    cursor: "pointer",
                                    fontSize: 23,
                                    color: "#b55e43",
                                    fontWeight: "bold",
                                }}
                            ></i>{" "}
                            <span
                                style={{ color: "#b55e43", fontWeight: "500" }}
                            >
                                Pendência Técnica
                            </span>
                        </Col>
                    </Col>
                    <Col xs="12" sm="2">
                        <button
                            style={{
                                fontSize: "2rem",
                                position: "relative",
                                top: "-8px",
                            }}
                            type="button"
                            onClick={() => {
                                onClose()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalInfoPartnerStatus
