import request from "./config/request"

const pceReportExport = filter => {
    let query = ""
    if (filter?.professional_id) {
        if (query == "") {
            query += "?professional_id=" + filter?.professional_id?.join(",")
        } else {
            query += "&professional_id=" + filter?.professional_id?.join(",")
        }
    }

    if (filter?.specialty_id) {
        if (query == "") {
            query += "?specialty_id=" + filter?.specialty_id?.join(",")
        } else {
            query += "&specialty_id=" + filter?.specialty_id?.join(",")
        }
    }

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/pce/report-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

export { pceReportExport }
