import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Modal,
    Label,
    Card,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    CardText,
    Nav,
    Table,
    Button,
    Input,
    CardBody,
    CardHeader,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import classnames from "classnames"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import { useDispatch } from "react-redux"
import AsyncSelect from "react-select/async"
import { search as searchAttendance } from "services/vitta-core/attendance"
import { createReport } from "services/vitta-core/attendance"
import SweetAlert from "react-bootstrap-sweetalert"
import draftToHtml from "draftjs-to-html"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import {
    convertToRaw,
    EditorState,
    ContentState,
    convertFromRaw,
} from "draft-js"
import debounce from "debounce-promise";

const InputTextt = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" className="mb-3" {...props} {...field} />

const InputEditor = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => (
    <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
        }}
        className="mb-2"
        onEditorStateChange={option => {
            setFieldValue(field.name, option)
        }}
        editorState={props.editorState}
    />
)

const AsyncSelectInputt = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const ModalReportAttendance = ({ show, data, onClose }) => {
    const defaultItemValues = {
        title: "",
        text_object: null,
        attendance: "",
    }

    const [pageData, setPageData] = useState(defaultItemValues)
    // const { patient_id } = useParams()
    const closedService = "closedservice";
    const dispatch = useDispatch()

    const loadAttendancesInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                
                let response = await searchAttendance({term:inputValue, patient_id:data,status:closedService})

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label:
                            /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.ana_main_complaint,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsAttendance = debounce(loadAttendancesInputOptions,600)

    const onSubmit = async values => {
        try {
            let dataSubmit = {
                title: values.title,
                text: draftToHtml(
                    convertToRaw(values.text_object.getCurrentContent())
                ),
                text_object: convertToRaw(
                    values.text_object.getCurrentContent()
                ),
                attendance_id: values.attendance.value,
            }

            if (
                (dataSubmit.title && dataSubmit.title === "") ||
                dataSubmit.title === null
            ) {
                return false
            } else if (
                (dataSubmit.attendance_id && dataSubmit.attendance_id === "") ||
                dataSubmit.attendance_id === null
            ) {
                return false
            } else if (true) {
                await createReport(data, dataSubmit)
                onClose()
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro criado com Sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                        type: "error",
                    })
                )
                console.log(e)
            }
        }
    }

    return (
        <Modal
            isOpen={show}
            // toggle={() => { toggleModal() }}
            centered={false}
            size="lg"
        >
            <CardBody>
                <Formik
                    initialValues={pageData}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({ values, defaultItemValues }) => (
                        <Form>
                            <div className="modal-header">
                                <div className="modal-title " id="myModalLabel">
                                    <h4>Adicionar Laudo</h4>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row className="mb-2 ">
                                    <Col sm="12">
                                        <Label className="mt-2">
                                            {" "}
                                            <strong>Título</strong>{" "}
                                        </Label>
                                        <FastField
                                            name={`title`}
                                            defaulValue={values.title}
                                            component={InputTextt}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Label>
                                            {" "}
                                            <strong>Texto</strong>{" "}
                                        </Label>
                                        <FastField
                                            name={"text_object"}
                                            editorState={values.text_object}
                                            component={InputEditor}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Label className="mt-2">
                                            Selecione um Atendimento Médico
                                        </Label>
                                        <Field
                                            name="attendance"
                                            defaultOptions
                                            loadOptions={
                                                debounceOptionsAttendance
                                            }
                                            component={AsyncSelectInputt}
                                            defaulValue={values.attendance}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <div>
                                    <Button
                                        type="submit"
                                        className="btn btn-info"
                                    >
                                        Criar
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Modal>
    )
}

export default ModalReportAttendance
