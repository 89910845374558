import React, { useState, useEffect, useRef } from "react"
import { Modal, CardBody } from "reactstrap"
import ZOOP_SDK_PAYMENT_TYPES from "utils/consts/zoop-sdk-payment-types"

const ModalCardPayments = ({
    show,
    data,
    data_form,
    setControlModal,
    registerCardTransactions,
    confirmCancelPayment,
    action,
}) => {
    const disconnectWSRef = useRef()
    const [isVisible, setIsVisible] = useState(true)
    const [hideCancelOperation, setHideCancelOperation] = useState(false)
    const [status, setStatus] = useState({
        code: "starting",
        message: "Iniciando comunicação",
    })

    const getIconClass = () => {
        switch (status.code) {
            case "starting":
                return `text-info fas fa-broadcast-tower ${
                    isVisible ? "visible" : "invisible"
                }`
            case "waiting":
                return `text-info fas fa-hand-point-up ${
                    isVisible ? "visible" : "invisible"
                }`
            case "processing":
                return "text-info fas fa-sync-alt fa-spin"
            case "approved":
                return "text-info fas fa-check-circle"
            case "failed":
                return "text-info fas fa-times-circle"
            default:
                return ""
        }
    }
  
    useEffect(() => {
        if (!show) return

        const transactionId = data.referenceId
        const actionParameter = action

        function initializeExternalScript() {
            if (typeof vittaPagBox === "function") {
                vittaPagBox(transactionId, actionParameter)
            }
        }

      
        if(typeof vittaPagBox == "undefined" )
        {
            
                const script = document.createElement("script")
            // Adiciona um timestamp ao URL do script para evitar cache
            script.src = `${
                process.env.REACT_APP_VITTA_PAG_SERVER
            }/checkout/public/external-script.js?${new Date().getTime()}`
            script.async = true
            
            script.onload = initializeExternalScript

            document.body.appendChild(script)
        }
        else
        {
            initializeExternalScript();
        }
 
        // return () => {
        //     document.body.removeChild(script)
        // }
    }, [show])

  

    if (!show) return null

    return (
        <Modal
            toggle={() => {
                setControlModal({ show: false, data: null })
            }}
            isOpen={show}
            centered
            size="md"
        >
            <CardBody
                id="my-container-iframe"
                className="text-center"
                style={{ fontSize: "24px" }}
            ></CardBody>
        </Modal>
    )
}

export default ModalCardPayments
