import React from "react"
import { Input, Row, Col, Label } from "reactstrap"
import { FastField } from "formik"
const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="text"
        {...field}
        {...props}
        disabled={props.disabled}
        style={{ backgroundColor: props.disabled ? "#f2f2f2" : "",color:props.disabled ?"#a7a7a7" : "" }}
    />
)

const TextComponent = ({ label, name, informed, disabled }) => {
    return (
        <Row>
            <Col sm="12">
                <Label>{label}</Label>
                <FastField
                    name={name}
                    component={TextInput}
                    defaultValue={informed ? informed?.value : ""}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}

export default TextComponent
