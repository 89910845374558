import React, { useMemo, useState, useRef } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import { search as UserServiceSearch } from "services/vitta-core/user"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import OS_STATUS from "utils/consts/os-status"
import { useHistory } from "react-router-dom"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import ReportOSExec from "pages/Reports/ExecutionOrderService/report"
import { reportExecOS as reportExecOS } from "services/vitta-core/os"
import debounce from "debounce-promise"

const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const ExecutionOrderServiceIndex = () => {
    const history = useHistory()
    const inputStatus = useRef()
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })
    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await UserServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadPatientsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await PatientServiceSearch(
                    { term: inputValue,fields:"id,name" },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    // const debounceOptionsUser= debounce(loadUserInputOptions,600)
    const debounceOptionsPatient = debounce(loadPatientsInputOptions, 600)

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await professionalServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )

                let list_values = []

                response.data.forEach(element => {
                    list_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const onSubmit = async (data_form, actions) => {
        try {
            let unit_ids = []
            for (let i = 0; i < data_form.unit_id.length; i++) {
                unit_ids.push(data_form.unit_id[i].value)
            }
            let auxData = {}
            let data = {}
            auxData.user = data_form.user_id?.value
                ? { id: data_form.user_id.value, name: data_form.user_id.label }
                : null
            auxData.patient = data_form.patient_id?.value
                ? {
                      id: data_form.patient_id.value,
                      name: data_form.patient_id.label,
                  }
                : null
            auxData.unit = unit_ids
            auxData.start_created_at = data_form.start_created_at
            auxData.end_created_at = data_form.end_created_at
            auxData.professional = data_form.professional_id?.value
                ? {
                      id: data_form.professional_id.value,
                      name: data_form.professional_id.label,
                  }
                : null
            auxData.os_status_item = {
                label: inputStatus?.current?.state?.value?.label,
                value: inputStatus?.current?.state?.value?.value,
            }
            auxData.start_exec_in = data_form.start_exec_in
            auxData.end_exec_in = data_form.end_exec_in

            let result = await reportExecOS(
                {
                    patient_id: auxData.patient?.id,
                    unit_id: auxData.unit,
                    start_created_at: auxData.start_created_at,
                    end_created_at: auxData.end_created_at,
                    start_exec_in: auxData.start_exec_in,
                    end_exec_in: auxData.end_exec_in,
                    statusOSItem: auxData?.os_status_item?.value,
                    professional_id: auxData?.professional?.id,
                },
                1,
                1000
            )

            if (!result || !auxData || !data_form) {
                return
            }

            data.result = result
            data.auxData = auxData
    
            setReportControl({
                show: true,
                filter: data,
            })
        } catch (e) {
            if (e?.response?.data === "date_search_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Data inválida!",
                        text:
                            "Por favor, verifique se as datas de busca foram digitadas corretamente. Obs: O Limite de busca é de 30 dias.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text:
                            "Ocorreu algum problema, verifique se todos os campos foram digitados corretamente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        }
    }

    if (reportControl.show) {
        return <ReportOSExec filter={reportControl.filter} />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Execução OS"
                    />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Execução OS
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    user_id: null,
                                                    patient_id: null,
                                                    unit_id: Scope.unit
                                                        ? [
                                                              {
                                                                  label: Scope.unit.name?.substr(
                                                                      0,
                                                                      40
                                                                  ),
                                                                  value:
                                                                      Scope.unit
                                                                          .id,
                                                              },
                                                          ]
                                                        : null,
                                                    start_created_at: todayOnlyDate,
                                                    end_created_at: todayOnlyDate,
                                                    professional_id: null,
                                                    start_exec_in: todayOnlyDate,
                                                    end_exec_in: todayOnlyDate,
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form
                                                        method="POST"
                                                        action="/pagamentos-recebidos/relatorio"
                                                    >
                                                        {/* <Row className="d-flex justify-content-center">
                                            <Col sm="8" className="mt-2">
                                                <Label>Usuário</Label>
                                                <Field
                                                    name="user_id"
                                                    placeholder="Selecione o Usuário"   
                                                    classNamePrefix="select2-selection"
                                                    cacheOptions
                                                    defaultOptions 
                                                    defaultValue={formProps.user}
                                                    loadOptions={loadUserInputOptions}
                                                    component={AsyncSelectInput}
                                                />       
                                            </Col>
                                            </Row> */}
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-1"
                                                            >
                                                                <Label>
                                                                    Paciente
                                                                </Label>
                                                                <Field
                                                                    name="patient_id"
                                                                    placeholder="Selecionar Paciente"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.patient
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Profissional
                                                                </Label>
                                                                <Field
                                                                    name="professional_id"
                                                                    placeholder="Selecionar Profissional"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.professional_id
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsProfessional
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Unidade
                                                                </Label>
                                                                <Field
                                                                    name={`unit_id`}
                                                                    placeholder="Selecione a Unidade"
                                                                    classNamePrefix="select2-selection"
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Status Item
                                                                    OS
                                                                </Label>
                                                                <Select
                                                                    name="statusOSItem"
                                                                    ref={
                                                                        inputStatus
                                                                    }
                                                                    defaultValue={{
                                                                        label:
                                                                            "Todos",
                                                                        value: null,
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            label:
                                                                                "Todos",
                                                                            value: null,
                                                                        },
                                                                        {
                                                                            label:
                                                                                "Finalizado",
                                                                            value: 1,
                                                                        },
                                                                        {
                                                                            label:
                                                                                "Aberto",
                                                                            value: 2,
                                                                        },
                                                                    ]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data Início
                                                                    Execução{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name="start_exec_in"
                                                                    type="date"
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.values
                                                                            ?.start_exec_in
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Data Final
                                                                    Execução
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_exec_in"
                                                                    }
                                                                    type="date"
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.values
                                                                            ?.end_exec_in
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data inicial
                                                                    OS{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "start_created_at"
                                                                    }
                                                                    type="date"
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.values
                                                                            ?.start_created_at
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Data final
                                                                    OS
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_created_at"
                                                                    }
                                                                    type="date"
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.values
                                                                            ?.end_created_at
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                align="end"
                                                                className="mt-4"
                                                            >
                                                                <Button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    Gerar
                                                                    Relatório
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExecutionOrderServiceIndex
