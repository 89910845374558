import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    Badge,
    Media,
    Table
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Link } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"
import REQUEST_TYPES from 'utils/consts/request-prof-types'
const SalesQuoteAnalytical = ({ filter, data, state }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })

    useEffect(() => {
        const loadData = async () => {
            try {
         
                setPageData({
                    header: {
                        unit: filter?.unit_id,
                        status: filter?.status,
                        patient: filter?.patient_id,
                        start_created_at: filter.start_created_at,
                        end_created_at:filter.end_created_at,
                    },
                    data: data,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (data) {
            loadData()
        } else history.push("/relatorios/orcamentos-analitico")
    }, [data])

    const printReport = () => {
        window.print()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Orçamentos - Analítico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                <Row>
                                        <Col sm="6">
                                            <p>
                                                Data Inicial:{" "}
                                                {pageData.header
                                                    ?.start_created_at
                                                    ? new Date(
                                                          pageData.header
                                                              ?.start_created_at +
                                                              " 00:00:00"
                                                      ).toLocaleDateString(
                                                          "pt-BR"
                                                      )
                                                    : null}{" "}
                                                {" - "}
                                                Data Final:{" "}
                                                {pageData.header?.end_created_at
                                                    ? new Date(
                                                          pageData.header
                                                              ?.end_created_at +
                                                              " 00:00:00"
                                                      ).toLocaleDateString(
                                                          "pt-BR"
                                                      )
                                                    : null}
                                                <br />
                                                Unidades:{" "}
                                                {pageData.header?.unit
                                                    ? pageData.header?.unit.map(
                                                          (row, index) =>
                                                              row.label +
                                                              (index ==
                                                              pageData.header
                                                                  ?.unit
                                                                  .length -
                                                                  1
                                                                  ? ""
                                                                  : ", ")
                                                      )
                                                    : ""}{" "}
                                                <br />
                                                Status:{" "}
                                                {pageData.header?.status
                                                    ? pageData.header?.status.map(
                                                          (row, index) =>
                                                              row.label +
                                                              (index ==
                                                              pageData.header
                                                                  ?.status
                                                                  .length -
                                                                  1
                                                                  ? ""
                                                                  : ", ")
                                                      )
                                                    : ""}{" "}
                                                <br />
                                                Paciente:
                                                {pageData.header?.patient?.label
                                                    ? pageData.header?.patient
                                                          ?.label
                                                    : " Sem filtro"}
                                            
                                               
                                                <br />
                                                Usuário:
                                                {pageData.header?.user?.label
                                                    ? pageData.header?.user
                                                          ?.label
                                                    : " Sem filtro"}
                                            </p>
                                        </Col>
                                        <Col sm="6">
                                            <div align="end">
                                                <div className="d-none d-print-block">
                                                    <img
                                                        src={logovitta}
                                                        alt=""
                                                        height="95px"
                                                        className="mb-3"
                                                    />
                                                </div>
                                                <Link
                                                to="#"
                                                className="btn btn-dark d-print-none mr-2"
                                                onClick={()=>state(false)}
                                                >
                                                <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                                </Link>
                                                <Link
                                                    to="/relatorios/orcamentos-analitico"
                                                    className="btn btn-primary d-print-none"
                                                    onClick={printReport}
                                                >
                                                    Imprimir
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
 
                                <h4 align="center">
                                   Orçamentos - Analítico
                                </h4>
                                <br/>

                                <div className="table-responsive">
                                    <Table className="table">
                                        <thead className="table-light">
                                            <tr align="center">
                                                <th scope="col">Data</th>
                                                <th scope="col">Unidade</th>
                                                <th scope="col">Orçamento(#)</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Paciente</th>
                                                <th scope="col">Item</th>
                                                <th scope="col">Quant.</th>
                                                <th scope="col">Val.Unit</th>
                                                <th scope="col">Val.Total</th>
                                                <th scope="col">Profissional Exec.</th>
                                                <th scope="col">Médico Solicitante</th>
                                                <th scope="col">Solicitação Médica</th>
                                                <th scope="col">Usuário</th>
                                                <th scope="col">Grupo Item</th>
                                                
                                                {/* <th scope="col">Total Clínica</th>
                                                <th scope="col">Total Repasse Médico</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data?.map(
                                                (row, index) => (
                                                    <tr align="center" key={index}>
                                                        <td>
                                                            {new Date(row.created_at).toLocaleDateString('pt-BR')}
                                                        </td>
                                                        <td>
                                                            {row?.name_unit}
                                                        </td>
                                                        <td>
                                                            {row?.sales_quote_id  ? "#" + row.sales_quote_id : ''}
                                                        </td>
                                                        <td>
                                                            { pageData.header?.status?.map((rowMap, index) => rowMap.value == row.status ? rowMap.label : "")}
                                                        </td>
                                                        <td>
                                                            {row.patient_name}
                                                        </td>
                                                        <td>
                                                            {row.name_item}
                                                        </td>
                                                        <td>
                                                            {row.quantity}
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.price)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.total_value)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                            {row?.professional_name}
                                                        </td>
                                                        <td>{row?.indic_professional_id && row?.indic_professional_name ? row?.indic_professional_name : row?.request_prof_name ? row?.request_prof_name : ''}</td>
                                                        <td> {row?.request_prof_type == REQUEST_TYPES.REQUEST_EXTERNAL ? "Externa" :   row?.request_prof_type == REQUEST_TYPES.REQUEST_INTERNAL  ? "Interna" : "NÃO"} </td> 
                                                        <td>
                                                            {row?.name_user}
                                                        </td>
                                                        <td>
                                                            {row?.group_name}
                                                        </td>
                                                      
                                                        {/* <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.unit_total_value)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.professional_total_value)/100)
                                                            }
                                                        </td> */}
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                    </div>

                                
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default SalesQuoteAnalytical
