import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Button } from "reactstrap"
import ServicesGeneral from "../ComponentsModalSecondary/ServicesGeneral"
import "./modalSecondary.css"
const ModalLogs = ({ show, data, onClose }) => {
    return (
        <Modal
            isOpen={show}
            centered={false}
            size="xl"
            style={{ maxWidth: "1600px", width: "70%" }}
        >
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Log - #{data?.id}
                        <i className="fas fa-exchange-alt text-dark fa-2x ml-3"></i>
                    </h5>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <Row className="card-body">
                <Col sm="12">
                    <div className="card-title">
                        Valores Atualizados Campos Principais
                    </div>
                    <table className="table">
                        <thead className="thead-light">
                            <tr align="start">
                                <th scope="col">Nome campo</th>
                                <th scope="col">Antigo valor</th>
                                <th scope="col">Novo valor</th>
                            </tr>
                        </thead>

                        {data?.diff_data
                            ? JSON.parse(data?.diff_data)?.data?.map(
                                  (branch, index) => (
                                      <tr key={index}>
                                          <th>
                                              {branch.field &&
                                              branch.field != "services"
                                                  ? branch.field
                                                  : null}
                                          </th>
                                          <th style={{ color: "red" }}>
                                              {branch.field &&
                                              branch.field != "services"
                                                  ? branch.old_value
                                                  : null}
                                          </th>
                                          <th style={{ color: "green" }}>
                                              {branch.field &&
                                              branch.field != "services"
                                                  ? branch.new_value
                                                  : null}
                                          </th>
                                      </tr>
                                  )
                              )
                            : ""}
                    </table>
                </Col>
                {(data && data?.entity == "professional") || "partner" ? (
                    <ServicesGeneral data={data}></ServicesGeneral>
                ) : (
                    ""
                )}
            </Row>

            <div className="card-body" align="right">
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="btn btn-dark waves-effect"
                    data-dismiss="modal"
                >
                    <span className="text-white"> Fechar</span>
                </button>
            </div>
        </Modal>
    )
}

export default ModalLogs