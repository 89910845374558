import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Modal,
    CardBody,
    UncontrolledTooltip,
    Button,
    Table,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import ModalAssessment from "pages/SalesDocs/Registration/Odontologic/HandleAssessmentModal"
import "./styles.css"
import SERVICES_TYPES from "utils/consts/services-types"
import SALES_DOCS_TYPES from "utils/consts/sales-docs-type"
import { create as createSalesOrders } from "services/vitta-core/sales-order"
const AddSalesOrderModal = ({ show, data, quotes, loadDataPage, onClose }) => {
    const dispatch = useDispatch()
    const [budgetItemsQuote, setBudgetItemsQuote] = useState([])
    const [chosenProcedures, setChosenProcedures] = useState([])
    const [modalAssessment, setModalAssessment] = useState({
        show: false,
        data: null,
    })
    const [activeTooltip, setActiveTooltip] = useState(null)
    const [showLoading, setShowLoading] = useState(false)
    const [totalChosenProcedures, setTotalChosenProdecures] = useState(0)
    const [dataModal, setDataModal] = useState([])
    const addChosenProcedure = (indexDoc, itemQuote) => {
        if (!chosenProcedures.includes(itemQuote?.id)) {
            setChosenProcedures([...chosenProcedures, itemQuote])

            let newData = [...dataModal]
            let items = newData[indexDoc]?.items
            items = items?.filter(e => e?.id != itemQuote?.id)
            newData[indexDoc].items = items
            setDataModal(newData)
        }
        setActiveTooltip(null)
    }
    const removeChosenProcedure = item => {
        let removeItem = chosenProcedures.filter(
            chosenItem => item.id != chosenItem.id
        )
        setChosenProcedures(removeItem)
        let newData = [...dataModal]
        let docToAdd = newData?.find(e => e?.id == item?.doc_id)
        docToAdd.items = [...docToAdd.items, item]
        setActiveTooltip(null)
    }
    const joinQuotes = () => {
        try {
            let joinedItemQuotes = []
            for (let i = 0; i < quotes?.length; i++) {
                let quote = quotes[i]
                joinedItemQuotes = joinedItemQuotes?.concat(quote?.items)
            }
            setChosenProcedures([])
            setBudgetItemsQuote(joinedItemQuotes)
        } catch (e) {
            console.log(e)
        }
    }

    const onNavigate = url => {
        window.open(url, "_blank")
    }
    const getNowDateFormated = () => {
        var date = new Date()

        var ano = date.getFullYear()
        var mes = (date.getMonth() + 1).toString().padStart(2, "0")
        var dia = date.getDate().toString().padStart(2, "0")

        var dateFormated = ano + "-" + mes + "-" + dia
        return dateFormated
    }

    const applyAll = (indexDoc, doc_id) => {
        setChosenProcedures(
            [...chosenProcedures].concat([...dataModal[indexDoc]?.items])
        )
        let newData = [...dataModal]
        newData[indexDoc].items = []
        setDataModal(newData)
    }
    const removeAll = () => {
        let newData = [...dataModal]
        for (let i = 0; i < newData?.length; i++) {
            let useDoc = newData[i]
            useDoc.items = [...useDoc.items].concat(
                chosenProcedures.filter(e => e?.doc_id == useDoc.id)
            )
        }
        setDataModal(newData)
        // setBudgetItemsQuote([...budgetItemsQuote].concat([...chosenProcedures]))
        setChosenProcedures([])
    }

    const onSubmit = async () => {
        setShowLoading(true)
        try {
            if (chosenProcedures?.length <= 0) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "É necessário selecionar ao menos um procedimento da lista de procedimentos orçados!",
                        type: "info",
                        showConfirm: true,
                    })
                )
                setShowLoading(false)
                return
            }

            let dataToCreate = {
                service_type: SERVICES_TYPES.DENTAL,
                assessment_id: data?.id,
                patient_id: data?.patient_id,
                unit_id: data?.unit_id,
                obs: `Gerado por: Avaliação odontológica #${data?.id}`,
                items: [],
            }
            let items = []
            for (let i = 0; i < chosenProcedures?.length; i++) {
                let useProcedure = chosenProcedures[i]
                items.push({
                    id: null,
                    exec_in: getNowDateFormated(),
                    item_id: useProcedure?.item_id,
                    price: useProcedure?.price,
                    price_table_id: useProcedure?.price_table_id,
                    quantity: useProcedure?.quantity,
                    tooth_number: useProcedure?.tooth_number,
                    price_lab: useProcedure?.price_lab,
                    lab_cost_id: useProcedure?.lab_cost_id,
                })
            }

            dataToCreate.items = items
            await createSalesOrders(dataToCreate)
            await loadDataPage()
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Pedido de venda criado com sucesso!",
                    type: "success",
                })
            )
            onClose()
        } catch (e) {
            setShowLoading(false)
            console.log(e)
            if (
                e?.response?.data?.error_code == "patient_register_incomplete"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "Paciente com cadastro incompleto, preencha as informações e tente novamente...",
                        type: "warning",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...!",
                    text:
                        "Ocorreu algo inesperado, tente novamente mais tarde!",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
        setShowLoading(false)
    }
    const calcChosenProcedures = () => {
        let total = 0
        for (let i = 0; i < chosenProcedures?.length; i++) {
            total += chosenProcedures[i]?.price
        }
        setTotalChosenProdecures(total)
    }
    useEffect(() => {
        calcChosenProcedures()
    }, [chosenProcedures])

    useEffect(() => {
        if (show) {
            const newData = JSON.parse(JSON.stringify(quotes))
            setDataModal(newData)
            setChosenProcedures([])
        }
    }, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            style={{ maxWidth: "85%" }}
            className="modal-custom-size "
        >
            <CardBody>
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>

                <Row>
                    <Col sm="6">
                        <div className="">
                            <div className="" id="">
                                <h4>Adicionar pedido de venda</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm="6" align="end"></Col>
                </Row>
                <hr />
                <div className="modal-body">
                    <Row>
                        <Col sm="6">
                            {dataModal?.map((item, indexDoc) => (
                                <div key={"quote" + indexDoc}>
                                    {item?.items?.length > 0 ? (
                                        <>
                                            <Row>
                                                <Col sm="6">
                                                    <h4
                                                        className="text-info"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            onNavigate(
                                                                `/orcamentos/${item?.id}`
                                                            )
                                                        }}
                                                    >
                                                        Orçamento #{item?.id}{" "}
                                                        <i className="fas fa-external-link-alt ml-2"></i>
                                                    </h4>
                                                </Col>
                                                <Col sm="6" align="end">
                                                    <a
                                                        href="#"
                                                        onClick={() => {
                                                            applyAll(
                                                                indexDoc,
                                                                item?.id
                                                            )
                                                        }}
                                                        style={{
                                                            position:
                                                                "relative",
                                                            top: "8px",
                                                        }}
                                                    >
                                                        Escolher todos
                                                    </a>
                                                </Col>
                                            </Row>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Procedimento</th>
                                                        <th>Dente</th>
                                                        <th>Valor</th>
                                                        <th>
                                                            {" "}
                                                            <span
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    left:
                                                                        "26px",
                                                                }}
                                                            >
                                                                Ações
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item?.items?.map(
                                                        (itemQuote, index) => (
                                                            <tr
                                                                key={
                                                                    "quoteitem" +
                                                                    index
                                                                }
                                                            >
                                                                <td>
                                                                    {" "}
                                                                    <strong className="text-info">
                                                                        <i
                                                                            onClick={() => {
                                                                                let dataToShow = {
                                                                                    ...itemQuote,
                                                                                }

                                                                                dataToShow.item_id = {
                                                                                    label:
                                                                                        itemQuote
                                                                                            ?.item
                                                                                            ?.name,
                                                                                    value:
                                                                                        itemQuote
                                                                                            ?.item
                                                                                            ?.id,
                                                                                }
                                                                                dataToShow.price_table =
                                                                                    dataToShow?.price_table /
                                                                                    100
                                                                                // dataToShow.price_lab =
                                                                                //     dataToShow?.price_lab *
                                                                                //     100
                                                                                setModalAssessment(
                                                                                    {
                                                                                        show: true,
                                                                                        data: dataToShow,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            className="fas fa-tasks"
                                                                            style={{
                                                                                cursor:
                                                                                    "pointer",
                                                                                fontSize:
                                                                                    "17px",
                                                                                marginRight:
                                                                                    "8px",
                                                                            }}
                                                                            id={
                                                                                "details" +
                                                                                itemQuote?.id
                                                                            }
                                                                        ></i>
                                                                        <UncontrolledTooltip
                                                                            target={
                                                                                "details" +
                                                                                itemQuote?.id
                                                                            }
                                                                        >
                                                                            Detalhes
                                                                            do
                                                                            procedimento
                                                                        </UncontrolledTooltip>
                                                                    </strong>
                                                                    {
                                                                        itemQuote
                                                                            ?.item
                                                                            ?.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        itemQuote?.tooth_number
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {"R$ " +
                                                                        new Intl.NumberFormat(
                                                                            "pt-br",
                                                                            {
                                                                                style:
                                                                                    "decimal",
                                                                                currency:
                                                                                    "BRL",
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2,
                                                                            }
                                                                        ).format(
                                                                            parseInt(
                                                                                itemQuote?.price *
                                                                                    itemQuote?.quantity
                                                                            ) /
                                                                                100
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "row",
                                                                            gap:
                                                                                "25px",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <strong className="text-info">
                                                                            <i
                                                                                onClick={() => {
                                                                                    addChosenProcedure(
                                                                                        indexDoc,
                                                                                        itemQuote
                                                                                    )
                                                                                }}
                                                                                className="fas fa-arrow-right"
                                                                                style={{
                                                                                    cursor:
                                                                                        "pointer",
                                                                                    fontSize:
                                                                                        "20px",
                                                                                }}
                                                                                id={`addChos-${indexDoc}-${index}`}
                                                                                onMouseEnter={() =>
                                                                                    setActiveTooltip(
                                                                                        `addChos-${indexDoc}-${index}`
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    setActiveTooltip(
                                                                                        null
                                                                                    )
                                                                                }
                                                                            ></i>
                                                                            <UncontrolledTooltip
                                                                                isOpen={
                                                                                    activeTooltip ===
                                                                                    `addChos-${indexDoc}-${index}`
                                                                                }
                                                                                target={`addChos-${indexDoc}-${index}`}
                                                                            >
                                                                                Utilizar
                                                                                Procedimento
                                                                            </UncontrolledTooltip>
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </>
                                    ) : null}
                                </div>
                            ))}
                            {!dataModal?.find(e => e?.items?.length > 0) ? (
                                <>
                                    {" "}
                                    <Row>
                                        <Col sm="6">
                                            <h4 className="text-info">
                                                Procedimentos Orçados
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Procedimento</th>
                                                <th>Dente</th>
                                                <th>Valor</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                    </Table>
                                </>
                            ) : null}
                        </Col>
                        <Col sm="6">
                            <Row>
                                <Col sm="6">
                                    <h4 className="text-success">
                                        Procedimentos Escolhidos
                                    </h4>
                                </Col>
                                <Col sm="6" align="end">
                                    <a
                                        href="#"
                                        style={{
                                            position: "relative",
                                            top: "8px",
                                        }}
                                        onClick={() => {
                                            removeAll()
                                        }}
                                    >
                                        Remover todos
                                    </a>
                                </Col>
                            </Row>

                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Procedimento</th>
                                        <th>Dente</th>
                                        <th>Valor</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chosenProcedures?.map(
                                        (itemQuote, index) => (
                                            <tr key={"quoteitem" + index}>
                                                <td>
                                                    {" "}
                                                    <strong className="text-success">
                                                        <i
                                                            onClick={() => {
                                                                let dataToShow = {
                                                                    ...itemQuote,
                                                                }

                                                                dataToShow.item_id = {
                                                                    label:
                                                                        itemQuote
                                                                            ?.item
                                                                            ?.name,
                                                                    value:
                                                                        itemQuote
                                                                            ?.item
                                                                            ?.id,
                                                                }
                                                                dataToShow.price_table =
                                                                    dataToShow?.price_table /
                                                                    100
                                                                // dataToShow.price_lab =
                                                                //     dataToShow?.price_lab *
                                                                //     100
                                                           
                                                                setModalAssessment(
                                                                    {
                                                                        show: true,
                                                                        data: dataToShow,
                                                                    }
                                                                )
                                                            }}
                                                            className="fas fa-tasks"
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                                fontSize:
                                                                    "17px",
                                                                marginRight:
                                                                    "8px",
                                                            }}
                                                            id={
                                                                "detailsChos" +
                                                                itemQuote?.id
                                                            }
                                                        ></i>
                                                        <UncontrolledTooltip
                                                            target={
                                                                "detailsChos" +
                                                                itemQuote?.id
                                                            }
                                                        >
                                                            Detalhes do
                                                            procedimento
                                                        </UncontrolledTooltip>
                                                    </strong>{" "}
                                                    {itemQuote?.item?.name}
                                                </td>
                                                <td>
                                                    {"R$ " +
                                                        new Intl.NumberFormat(
                                                            "pt-br",
                                                            {
                                                                style:
                                                                    "decimal",
                                                                currency: "BRL",
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ).format(
                                                            parseInt(
                                                                itemQuote?.price *
                                                                    itemQuote?.quantity
                                                            ) / 100
                                                        )}
                                                </td>
                                                <td>
                                                    {itemQuote?.tooth_number}
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            gap: "25px",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <strong className="text-success">
                                                            <i
                                                                onClick={() => {
                                                                    removeChosenProcedure(
                                                                        itemQuote
                                                                    )
                                                                }}
                                                                className="fas fa-arrow-left"
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                                id={`removeChos-${index}`}
                                                                onMouseEnter={() =>
                                                                    setActiveTooltip(
                                                                        `removeChos-${index}`
                                                                    )
                                                                }
                                                                onMouseLeave={() =>
                                                                    setActiveTooltip(
                                                                        null
                                                                    )
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                isOpen={
                                                                    activeTooltip ===
                                                                    `removeChos-${index}`
                                                                }
                                                                target={`removeChos-${index}`}
                                                            >
                                                                Remover
                                                                Procedimento
                                                            </UncontrolledTooltip>
                                                        </strong>
                                                        {/* <strong className="text-success">
                                                            <i
                                                                className="fas fa-eye"
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                                id={
                                                                    "shoppingChos" +
                                                                    itemQuote?.id
                                                                }
                                                                onClick={() => {
                                                                    onNavigate(
                                                                        `/orcamentos/${itemQuote?.doc_id}`
                                                                    )
                                                                }}
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                target={
                                                                    "shoppingChos" +
                                                                    itemQuote?.id
                                                                }
                                                            >
                                                                Visualizar
                                                                Orçamento
                                                            </UncontrolledTooltip>
                                                        </strong> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col sm="7">
                            <small>
                                {" "}
                                <strong className="text-info">
                                    Obs: O pedido de venda será criado a partir
                                    dos procedimentos escolhidos.
                                </strong>
                            </small>
                        </Col>
                        <Col sm="5" align="end">
                            {" "}
                            <strong className="text-info">
                                Total:{" "}
                                {"R$ " +
                                    new Intl.NumberFormat("pt-br", {
                                        style: "decimal",
                                        currency: "BRL",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(
                                        parseInt(totalChosenProcedures) / 100
                                    )}
                            </strong>{" "}
                        </Col>
                    </Row> */}
                </div>
                <hr />
                <Row className="">
                    <Col sm="5" align="start">
                        {" "}
                        <strong className="text-info">
                            Total:{" "}
                            {"R$ " +
                                new Intl.NumberFormat("pt-br", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    parseInt(totalChosenProcedures) / 100
                                )}
                        </strong>{" "}
                    </Col>
                    <Col align="end">
                        <Button
                            size="sm"
                            disabled={showLoading}
                            type="button"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Fechar
                        </Button>{" "}
                        {chosenProcedures?.length > 0 ? (
                            <Button
                                size="sm"
                                color="info"
                                type="button"
                                disabled={showLoading}
                                onClick={() => {
                                    dispatch(
                                        showSweetAlertDialog({
                                            title: "Atenção ",
                                            text:
                                                "Tem certeza que deseja criar o registro com base nesses procedimentos?",
                                            type: "warning",
                                            onConfirmAction: () => {
                                                onSubmit()
                                            },
                                            showCancel: true,
                                            showConfirm: true,
                                            cancelBtnText: "Não",
                                            confirmBtnText: "Sim",
                                        })
                                    )
                                }}
                            >
                                Criar pedido de venda
                            </Button>
                        ) : null}
                    </Col>
                </Row>
            </CardBody>
            <ModalAssessment
                show={modalAssessment?.show}
                data={modalAssessment?.data}
                doc_service_type={SERVICES_TYPES.DENTAL}
                details={true}
                onClose={() => {
                    setModalAssessment({
                        show: false,
                        data: null,
                        details: false,
                    })
                }}
            />
        </Modal>
    )
}

export default AddSalesOrderModal
