import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Button, UncontrolledTooltip } from "reactstrap"
import { Formik, Form } from "formik"
import TextComponent from "./Components/TextComponent"
import SelectComponent from "./Components/SelectComponent"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
const ModalAdditionalData = ({
    show,
    additionalData,
    onClose,
    setAdditionalData,
    disabled,
    materialDetail
}) => {
    if (!show)
        return <></>
    const [pageData, setPageData] = useState({})
    const [mountData, setMountData] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        mountDataPage()
    }, [additionalData])

    const mountDataPage = () => {
        try {
            if (additionalData?.additionalDataForm) {
                setPageData({ ...additionalData?.additionalDataForm })
            }
            
            let additionalItems = additionalData ? [...additionalData?.additionalDataItems] : [];
            let dataPage = []
            if(additionalItems && additionalItems?.length >0)
            {
                for (let i = 0; i < additionalItems?.length; i++) {
                    let use = additionalItems[i]
                    if (!use || !use?.required) continue
                    let settingsPage = {
                        label: use?.informed ? use?.label : use?.description,
                        model: use?.model,
                        name: use?.name,
                        required: use?.required,
                        type: use?.type,
                        options: [],
                        informed: use?.informed,
                    }
                    if (use.model == "select_options") {
                        for (let j = 0; j < use?.options?.length; j++) {
                            let useOption = use?.options[j]
                            settingsPage?.options?.push({
                                label: !use?.informed
                                    ? useOption?.name
                                    : useOption?.label,
                                value: !use?.informed
                                    ? useOption?.id
                                    : useOption?.value,
                            })
                        }
                    }
                    dataPage.push(settingsPage)
                }
            }
           
            setMountData(dataPage)
        } catch (e) {
            console.log(e)
        }
    }
    const handleFormSubmit = values => {
        try {
            let keys = Object.keys(values)
            if (keys.length != Object.keys(mountData).length) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "É necessário preencher todas as informações adicionais!",
                        type: "info",
                        showConfirm: true,
                    })
                )
            }
            let supplementaryInfoData = []

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                let findData = mountData.find(e => e.name == key)
                if (!findData) return
                let supplementaryInfo = {
                    name: key,
                }
                if (values[key]?.value) {
                    supplementaryInfo.value = values[key].value
                    supplementaryInfo.objectData = { ...values[key] }
                } else {
                    supplementaryInfo.value = values[key]
                }
                findData.informed = supplementaryInfo
                supplementaryInfoData.push(findData)
            }
            setAdditionalData(
                values,
                Object.assign(mountData, ...supplementaryInfoData)
            )
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "As informações foram salvas!",
                    type: "success",
                })
            )
            onClose()
        } catch (e) {
            console.log(e)
            dispatch(
                showToast({
                    title: "Ops...!",
                    text:
                        "Ocorreu algo inesperado, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    return (
        <Formik initialValues={pageData} enableReinitialize={true}>
            {({ values }) => (
                <Form>
                    <Modal isOpen={show} centered={false} size="md">
                        <div className="card-body">
                            <Row>
                                <Col xs="12" sm="10">
                                    <div
                                        className="modal-title "
                                        id="myModalLabel"
                                    >
                                        <h5>
                                            <i
                                                className="fas fa-clipboard-list text-info fa-lg mr-2"
                                                style={{
                                                    position: "relative",
                                                    top: "-1px",
                                                }}
                                            ></i>
                                            Informações Adicionais
                                        </h5>
                                    </div>
                                </Col>
                                <Col xs="12" sm="2">
                                    <button
                                        style={{ fontSize: "2rem" }}
                                        type="button"
                                        onClick={() => {
                                            onClose()
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    {" "}
                                    <small className="text-info font-weight-bold">
                                        *
                                        {!disabled
                                            ? "Preencha as informações adicionais abaixo:"
                                            : "Informações adicionais inseridas na criação do pedido:"}
                                    </small>
                                </Col>
                            </Row>
                            <section className="mt-3">
                                {mountData?.map((item, index) => (
                                    <div className="mt-1" key={index}>
                                        {item?.model == "select_options" ? (
                                            <SelectComponent
                                                label={item?.label}
                                                name={item?.name}
                                                options={item?.options}
                                                informed={item?.informed}
                                                disabled={disabled}
                                            />
                                        ) : (
                                            <TextComponent
                                                label={item?.label}
                                                name={item?.name}
                                                informed={item?.informed}
                                                disabled={disabled}
                                            />
                                        )}
                                    </div>
                                ))}
                            </section>
                            {!disabled ? (
                                <Row className="mt-3">
                                    <Col align="end">
                                        <Button
                                            onClick={() => {
                                                handleFormSubmit(values)
                                            }}
                                            color="info"
                                            className="btn-sm"
                                            id="saveinfo"
                                        >
                                            <i className="fas fa-save fa-lg"></i>
                                        </Button>
                                    </Col>
                                    <UncontrolledTooltip
                                        placement="left"
                                        target="saveinfo"
                                        style={{
                                            backgroundColor: "#50a5f1",
                                            color: "white",
                                        }}
                                    >
                                        Salvar informações adicionais
                                    </UncontrolledTooltip>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default ModalAdditionalData
