export default () => {
    let authUser = localStorage.getItem("vittanet.identity");
   
    if(authUser)
    {
        authUser = JSON.parse(authUser);
        if(authUser.token) return authUser.token;
    }

    return null;
}