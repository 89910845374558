import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import StarRatings from "react-star-ratings"
import { Link, useHistory } from "react-router-dom"

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Col,
    Container,
    Row,
    Input,
    Label,
    Table,
    Badge,
    UncontrolledTooltip,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { Formik, Field, Form } from "formik"
import authUtils from "utils/auth"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

import AsyncSelect from "react-select/async"
import ModalAppointment from "./ModalAppointment"

import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { search as appointmentBookServiceSearch } from "services/vitta-core/appointment-book"
import { searchCascadeView as appointmentServiceSearchCascadeView } from "services/vitta-core/appointment"
import { searchCascadeViewProf as appointmentServiceSearchCascadeViewProf } from "services/vitta-core/appointment"
import debounce from "debounce-promise"
import Select from "react-select"
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => (
    <Select
      {...field}
      {...props}
      onChange={option => {
        setFieldValue(field.name, option)
      }}
    />
  )

const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const AppointmentList = () => {
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)
    const refSearchForm = useRef()
    const ExecMode = [
        { value: '', label: "Todos" },
        { value: 2, label: "Online (Videoconferência)" },
        { value: 1, label: "Presencial" },
      ]
    const today = new Date().toLocaleDateString("pt-BR")
    const todayOnlyDate =
        today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

    const [pageData, setPageData] = useState({
        appointment_books: [],
        service_data: [],
        appointment_status: {},
    })

    const [pageControl, setPageControl] = useState({
        searching: false,
    })

    const searchDefault = {
        start_date: todayOnlyDate,
        end_date: todayOnlyDate,
        unit_id: Scope?.unit?.id
            ? { label: Scope?.unit?.name, value: Scope?.unit?.id }
            : null,
        appointment_book_id: null,
        service_id: null,
        professional_id: null,
        patient_id: null,
        execution_mode:ExecMode[2]
    }

    const [modalAppointmentData, setModalAppointmentData] = useState({
        show: false,
        data: null,
    })

    const [controlStatePermission, setControlStatePermission] = useState(
        "view_appointment"
    )
    const dispatch = useDispatch()

    const onSaveAppointment = data => {
        //todo - Alterar status/dados do agendamento no state pagedata
        // console.log("onSaveAppointment", data)
        refSearchForm.current.handleSubmit()
    }

    const loadUnitInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let data_values = []

                User.units.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf" },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label:
                            `#${element.id} ` +
                            element.name?.substr(0, 40) +
                            (element.cpf ? ` - CPF: ${element.cpf}` : ""),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadServiceInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            if (!inputValue) return resolve([])

            try {
                const statusActive = 1
                let response = await itemServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadAppointmentBookInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await appointmentBookServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUnit = debounce(loadUnitInputOptions, 600)
    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )
    const debounceOptionsService = debounce(loadServiceInputOptions, 600)
    const debounceOptionsAppointment = debounce(
        loadAppointmentBookInputOptions,
        600
    )

    const controlPermissionAppointment = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_appointment") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return "view_appointment"
        } else {
            return "view_my_prof_appointment"
        }
    }, [User])

    const isValidDate = d => {
        return d instanceof Date && !isNaN(d)
    }
    const onSubmit = async data => {
        if (pageControl.searching) return

        setPageControl({ ...pageControl, ...{ searching: true } })

        if (
            !isValidDate(new Date(data?.start_date)) ||
            !isValidDate(new Date(data?.end_date))
        ) {
            dispatch(
                showSweetAlertDialog({
                    title: "Data inválida!",
                    text: "Verifique se a data está correta e tente novamente.",
                    type: "error",
                    showConfirm: true,
                })
            )
            setPageControl({ ...pageControl, ...{ searching: false } })
            return
        }

        try {
            let response = null
            let filter = {
                start_date: data.start_date,
                end_date: data.end_date,
                unit_id: data.unit_id?.value ? data.unit_id.value : null,
                appointment_book_id: data.appointment_book_id?.value
                    ? data.appointment_book_id.value
                    : null,
                service_id: data.service_id?.value
                    ? data.service_id.value
                    : null,
                professional_id: data.professional_id?.value
                    ? data.professional_id.value
                    : null,
                patient_id: data.patient_id?.value
                    ? data.patient_id.value
                    : null,
                execution_mode: data.execution_mode?.value
            }
            if (controlPermissionAppointment == "view_my_prof_appointment") {
                response = await appointmentServiceSearchCascadeViewProf(filter)
            } else {
                response = await appointmentServiceSearchCascadeView(filter)
            }

            setPageData({
                appointment_books: response?.appointment_books,
                service_data: response?.service_data,
                appointment_status: response?.appointment_status,
                searchRequested: true,
            })

            setPageControl({ ...pageControl, ...{ searching: false } })

            //console.log("onSubmit search", response);
        } catch (e) {
            setPageControl({ ...pageControl, ...{ searching: false } })

            console.log(e)

            let textError =
                "Falha ao buscar, verifique os dados inseridos ou tente mais tarde..."

            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "date_invalid_30":
                        textError =
                            "Com base nos filtros, a busca pode ser realizada com um período de datas de no máximo 30 dias."
                        break
                    case "date_invalid_90":
                        textError =
                            "Com base nos filtros, a busca pode ser realizada com um período de datas de no máximo 90 dias."
                        break
                    case "date_invalid_7":
                        textError =
                            "Com base nos filtros, a busca pode ser realizada com um período de datas de no máximo 7 dias."
                        break
                    case "date_invalid":
                        textError = "Data inválida !"
                        break
                    case "professional_not_found":
                        textError = "Profissional não encontrado!"
                        break
                }
            }

            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const renderStatusBadge = status => {
        let status_name = null
        let badgeColor = "badge-soft-primary"

        switch (status) {
            case pageData.appointment_status.AGENDADO:
                status_name = "Agendado"
                break
            case pageData.appointment_status.BLOQUEADO:
                status_name = "Bloqueado"
                badgeColor = "badge-soft-danger"
                break
            case pageData.appointment_status.CANCELADO:
                status_name = "Cancelado (Clínica)"
                badgeColor = "badge-soft-danger"
                break
            case pageData.appointment_status.COMPARECEU:
                status_name = "Compareceu"
                badgeColor = "badge-soft-warning"
                break
            case pageData.appointment_status.CONCLUIDO:
                status_name = "Concluído"
                badgeColor = "badge-soft-dark"
                break
            case pageData.appointment_status.CONFIRMADO:
                status_name = "Confirmado"
                badgeColor = "badge-soft-warning"
                break
            case pageData.appointment_status.DESMARCADO:
                status_name = "Desmarcado (Paciente)"
                badgeColor = "badge-soft-danger"
                break
            case pageData.appointment_status.DISPONIVEL:
                status_name = "Disponível"
                badgeColor = "badge-soft-success"
                break
            case pageData.appointment_status.EM_ATENDIMENTO:
                status_name = "Em Atendimento"
                badgeColor = "badge-soft-warning"
                break
            case pageData.appointment_status.EM_ESPERA:
                status_name = "Em Espera"
                badgeColor = "badge-soft-success"
                break
            case pageData.appointment_status.FALTOU:
                status_name = "Faltou"
                badgeColor = "badge-soft-warning"
                break
            case pageData.appointment_status.FALTOU_CONFIRMADO:
                status_name = "Faltou Confirmado"
                badgeColor = "badge-soft-warning"
                break
            case pageData.appointment_status.PAGO:
                status_name = "Pago"
                break
            case pageData.appointment_status.AGUARDANDO_RECEPCAO:
                status_name = "Aguardando Recepção"
                badgeColor = "badge-soft-pink"
                break
            case pageData.appointment_status.AGUARDANDO_CAIXA:
                status_name = "Aguardando Caixa"
                badgeColor = "badge-soft-pink"
                break
            case pageData.appointment_status.AGUARDANDO_LABORATORIO:
                status_name = "Aguardando Laboratório"
                badgeColor = "badge-soft-pink"
                break
        }

        return (
            <Badge
                className={`font-size-14 ` + badgeColor}
                style={{
                    marginRight: "4px",
                }}
                pill
            >
                <small>{status_name}</small>
            </Badge>
        )
    }

    const renderBadgeServicesAccepted = (service_ids, limit = 2) => {
        let service_names = []
        for (let i = 0; i < service_ids.length; i++) {
            for (let j = 0; j < pageData.service_data.length; j++) {
                if (service_ids[i] == pageData.service_data[j].id) {
                    service_names.push(pageData.service_data[j].name)
                    break
                }
            }
            if (service_names.length == limit) break
        }

        const loadServiceNames = () => {
            return service_names.map((service, index) => (
                <Badge
                    key={index}
                    className={`font-size-14 badge-soft-primary`}
                    style={{ marginRight: "2px" }}
                    pill
                >
                    <small>{service}</small>
                </Badge>
            ))
        }

        const loadAdditional = () => {
            if (service_ids.length > limit) {
                return <small>{" + " + (service_ids.length - limit)}</small>
            }
            return ""
        }

        return (
            <>
                {" "}
                {loadServiceNames()} {loadAdditional()}{" "}
            </>
        )
    }

    const renderBadgeServiceAppointment = service => {
        return (
            <Badge
                key={0}
                className={`font-size-14 badge-soft-primary`}
                style={{ marginRight: "4px" }}
                pill
            >
                <small>{service}</small>
            </Badge>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Agenda" breadcrumbItem="Agendamentos" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={searchDefault}
                                        onSubmit={onSubmit}
                                        innerRef={refSearchForm}
                                    >
                                        <Form>
                                            <Row className="mb-3">
                                                <Col sm="3">
                                                    <Label>Data Inicial</Label>
                                                    <Field
                                                        name="start_date"
                                                        component={DateInput}
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    <Label>Data Final</Label>
                                                    <Field
                                                        name="end_date"
                                                        component={DateInput}
                                                    />
                                                </Col>

                                                <Col sm="3">
                                                    <Label>Unidade</Label>
                                                    <Field
                                                        name="unit_id"
                                                        placeholder="Selecionar Unidade"
                                                        cacheOptions
                                                        defaultOptions
                                                        isClearable
                                                        loadOptions={
                                                            debounceOptionsUnit
                                                        }
                                                        component={
                                                            AsyncSelectInput
                                                        }
                                                    />
                                                </Col>
                                                {controlPermissionAppointment &&
                                                controlPermissionAppointment ==
                                                    "view_my_prof_appointment" ? (
                                                    <Col sm="3">
                                                        <Label>Paciente</Label>
                                                        <Field
                                                            name="patient_id"
                                                            placeholder="Selecionar Paciente"
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsPatient
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col sm="3">
                                                        <Label>Agenda</Label>
                                                        <Field
                                                            name="appointment_book_id"
                                                            placeholder="Selecionar Agenda"
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsAppointment
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row className="mb-3">
                                                {controlPermissionAppointment &&
                                                controlPermissionAppointment ==
                                                    "view_my_prof_appointment" ? (
                                                    ""
                                                ) : (
                                                    <Col sm="3">
                                                        <Label>Serviço</Label>
                                                        <Field
                                                            name="service_id"
                                                            placeholder="Selecionar Serviço"
                                                            cacheOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsService
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                )}

                                                {controlPermissionAppointment &&
                                                controlPermissionAppointment ==
                                                    "view_my_prof_appointment" ? (
                                                    <Col sm="3">
                                                        <Label>
                                                            Profissional
                                                        </Label>
                                                        <Field
                                                            name="professional_id"
                                                            placeholder="Selecionar Profissional"
                                                            value={{
                                                                label:
                                                                    User?.name,
                                                                value: User?.id,
                                                            }}
                                                            isDisabled={true}
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col sm="3">
                                                        <Label>
                                                            Profissional
                                                        </Label>
                                                        <Field
                                                            name="professional_id"
                                                            placeholder="Selecionar Profissional"
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsProfessional
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                )}

                                                {controlPermissionAppointment &&
                                                controlPermissionAppointment ==
                                                    "view_my_prof_appointment" ? (
                                                    ""
                                                ) : (
                                                    <Col sm="3">
                                                        <Label>Paciente</Label>
                                                        <Field
                                                            name="patient_id"
                                                            placeholder="Selecionar Paciente"
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsPatient
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                
                                                <Col sm="2">
                                                    <Label>Execução</Label>
                                                    <Field
                                                        name="execution_mode"
                                                        options={
                                                            ExecMode
                                                        }
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        />
                                                </Col>

                                                <Col
                                                    sm="1"
                                                    md="1"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary waves-effect waves-light form-control"
                                                    >
                                                        <span className="bx bx-search-alt fa-lg mt-1" />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {pageControl.searching ? (
                        <Row align="center" className="mt-5 mb-5">
                            <Col sm="12" className="mt-5 mb-5">
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Col>
                        </Row>
                    ) : null}

                    {!pageControl.searching &&
                    pageData?.appointment_books?.length == 0 &&
                    pageData.searchRequested
                        ? "Não há registros que correspondam com sua busca."
                        : null}

                    {pageData?.appointment_books?.map((itemDate, indexDate) =>
                        itemDate.units.map((itemUnit, indexUnit) =>
                            itemUnit.appointment_books.map(
                                (itemAppointmentBook, indexAppointmentBook) => (
                                    <Row key={indexAppointmentBook}>
                                        <Col xs="12">
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <CardTitle className="mb-2 d-flex">
                                                                <span className="mt-1">
                                                                    {" "}
                                                                    Agenda{" "}
                                                                    {
                                                                        itemAppointmentBook.name
                                                                    }
                                                                </span>

                                                                {controlPermissionAppointment &&
                                                                controlPermissionAppointment !=
                                                                    "view_my_prof_appointment" &&
                                                                itemAppointmentBook?.rating ? (
                                                                    <span
                                                                        id={
                                                                            "ratingtooltip" +
                                                                            itemAppointmentBook?.id
                                                                        }
                                                                        style={{
                                                                            marginLeft:
                                                                                "20px",
                                                                            cursor:
                                                                                "pointer",
                                                                        }}
                                                                    >
                                                                        <StarRatings
                                                                            rating={
                                                                                itemAppointmentBook
                                                                                    ?.rating
                                                                                    ?.rating
                                                                            }
                                                                            starRatedColor="#ffd700"
                                                                            starDimension="20px"
                                                                            starSpacing="4px"
                                                                            numberOfStars={
                                                                                4
                                                                            }
                                                                            name="rating"
                                                                        />{" "}
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "ratingtooltip" +
                                                                                itemAppointmentBook?.id
                                                                            }
                                                                        >
                                                                            {itemAppointmentBook
                                                                                ?.rating
                                                                                ?.rating ==
                                                                            4
                                                                                ? "Classificação : Ouro"
                                                                                : itemAppointmentBook
                                                                                      ?.rating
                                                                                      ?.rating ==
                                                                                  3
                                                                                ? "Classificação : Prata"
                                                                                : itemAppointmentBook
                                                                                      ?.rating
                                                                                      ?.rating ==
                                                                                  2
                                                                                ? "Classificação : Bronze"
                                                                                : itemAppointmentBook
                                                                                      ?.rating
                                                                                      ?.rating ==
                                                                                  1
                                                                                ? "Classificação : Bronze"
                                                                                : "Sem Classificação"}
                                                                        </UncontrolledTooltip>
                                                                    </span>
                                                                ) : null}
                                                            </CardTitle>

                                                            <CardSubtitle className="mb-2">
                                                                {
                                                                    itemDate.date_description
                                                                }
                                                            </CardSubtitle>

                                                            {itemAppointmentBook.obs &&
                                                            itemAppointmentBook
                                                                .obs.length >
                                                                0 ? (
                                                                <CardSubtitle className="mb-2">
                                                                    <small>
                                                                        {
                                                                            itemAppointmentBook.obs
                                                                        }
                                                                    </small>
                                                                </CardSubtitle>
                                                            ) : null}
                                                        </Col>

                                                        <Col
                                                            sm="3"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "flex-end",
                                                            }}
                                                        >
                                                            {itemAppointmentBook.accept_fit ? (
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-info waves-effect waves-light form-control"
                                                                    style={{
                                                                        padding:
                                                                            "0px",
                                                                        width:
                                                                            "36px",
                                                                        height:
                                                                            "36px",
                                                                    }}
                                                                    title="Adicionar Encaixe"
                                                                    onClick={() => {
                                                                        setModalAppointmentData(
                                                                            {
                                                                                show: true,
                                                                                data: {
                                                                                    fit: true,
                                                                                    date: {
                                                                                        date:
                                                                                            itemDate.date,
                                                                                    },
                                                                                    appointment_book: itemAppointmentBook,
                                                                                    unit: {
                                                                                        id:
                                                                                            itemUnit?.id,
                                                                                        name:
                                                                                            itemUnit?.name,
                                                                                    },
                                                                                },
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus font-size-18" />
                                                                </button>
                                                            ) : null}
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <Table className="table mb-0">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th
                                                                            style={{
                                                                                width:
                                                                                    "10%",
                                                                            }}
                                                                        >
                                                                            Horário
                                                                        </th>
                                                                        <th
                                                                            style={{}}
                                                                        >
                                                                            Detalhes
                                                                        </th>

                                                                        {/* <th></th> */}

                                                                        <th
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "flex-end",
                                                                            }}
                                                                        >
                                                                            Ações
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {itemAppointmentBook.slots.map(
                                                                        (
                                                                            slot,
                                                                            indexSlot
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    indexSlot
                                                                                }
                                                                                style={{
                                                                                    cursor:
                                                                                        "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        !slot.locked
                                                                                    )
                                                                                        setModalAppointmentData(
                                                                                            {
                                                                                                show: true,
                                                                                                data: {
                                                                                                    ...slot,
                                                                                                    date: {
                                                                                                        date:
                                                                                                            itemDate.date,
                                                                                                        description:
                                                                                                            itemDate.date_description,
                                                                                                    },
                                                                                                    appointment_book: itemAppointmentBook,
                                                                                                    unit: {
                                                                                                        id:
                                                                                                            itemUnit?.id,
                                                                                                        name:
                                                                                                            itemUnit?.name,
                                                                                                    },
                                                                                                },
                                                                                            }
                                                                                        )
                                                                                }}
                                                                            >
                                                                                {controlPermissionAppointment ==
                                                                                    "view_my_prof_appointment" &&
                                                                                (slot
                                                                                    ?.appointment
                                                                                    ?.status ==
                                                                                    pageData
                                                                                        .appointment_status
                                                                                        .DESMARCADO ||
                                                                                    slot
                                                                                        ?.appointment
                                                                                        ?.status ==
                                                                                        pageData
                                                                                            .appointment_status
                                                                                            .CANCELADO) ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td
                                                                                        style={{
                                                                                            display:
                                                                                                "flex",
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            className="bx bxs-time-five"
                                                                                            style={{
                                                                                                color: slot.appointment
                                                                                                    ? "#50a5f1"
                                                                                                    : slot.locked
                                                                                                    ? "#f46a6a"
                                                                                                    : "#228B22",
                                                                                                fontSize:
                                                                                                    "1.1rem",
                                                                                                marginRight:
                                                                                                    "5px",
                                                                                            }}
                                                                                        />{" "}
                                                                                        {
                                                                                            slot
                                                                                                .time
                                                                                                .start
                                                                                        }{" "}
                                                                                    </td>
                                                                                )}
                                                                                {controlPermissionAppointment ==
                                                                                    "view_my_prof_appointment" &&
                                                                                slot.appointment &&
                                                                                (slot
                                                                                    ?.appointment
                                                                                    ?.status ==
                                                                                    pageData
                                                                                        .appointment_status
                                                                                        .DESMARCADO ||
                                                                                    slot
                                                                                        ?.appointment
                                                                                        ?.status ==
                                                                                        pageData
                                                                                            .appointment_status
                                                                                            .CANCELADO) ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td>
                                                                                        {slot.appointment ? (
                                                                                            <>
                                                                                                {renderStatusBadge(
                                                                                                    slot
                                                                                                        .appointment
                                                                                                        .status
                                                                                                )}
                                                                                                {slot
                                                                                                    .appointment
                                                                                                    ?.arrival_at ? (
                                                                                                    <Badge
                                                                                                        className={`font-size-14 badge-soft-success`}
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                "2px",
                                                                                                        }}
                                                                                                        pill
                                                                                                    >
                                                                                                        <small>
                                                                                                            Chegada:
                                                                                                            <strong>
                                                                                                                {" "}
                                                                                                                {new Date(
                                                                                                                    slot?.appointment?.arrival_at
                                                                                                                ).toLocaleTimeString(
                                                                                                                    "pt-BR",
                                                                                                                    {
                                                                                                                        hour:
                                                                                                                            "2-digit",
                                                                                                                        hour12: false,
                                                                                                                        minute:
                                                                                                                            "2-digit",
                                                                                                                    }
                                                                                                                )}
                                                                                                            </strong>
                                                                                                        </small>
                                                                                                    </Badge>
                                                                                                ) : null}
                                                                                                {slot
                                                                                                    .appointment
                                                                                                    .is_fit ? (
                                                                                                        
                                                                                                //     slot
                                                                                                //     .appointment
                                                                                                //     .sales_order_id ? (
                                                                                                //     <Badge
                                                                                                //         className={`font-size-14 badge-soft-warning`}
                                                                                                //         style={{
                                                                                                //             marginRight:
                                                                                                //                 "2px",
                                                                                                //         }}
                                                                                                //         pill
                                                                                                //     >
                                                                                                //         <small>
                                                                                                //             {
                                                                                                //                 "Odontologia"
                                                                                                //             }
                                                                                                //         </small>
                                                                                                //     </Badge>
                                                                                                // ): 
                                                                                                <Badge
                                                                                                        className={`font-size-14 badge-soft-warning`}
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                "2px",
                                                                                                        }}
                                                                                                        pill
                                                                                                    >
                                                                                                        <small>
                                                                                                            {
                                                                                                                "Encaixe"
                                                                                                            }
                                                                                                        </small>
                                                                                                    </Badge>
                                                                                                    
                                                                                                ) : null}
                                                                                                
                                                                                                {renderBadgeServiceAppointment(
                                                                                                    slot
                                                                                                        .appointment
                                                                                                        ?.service
                                                                                                        ?.name
                                                                                                )}
                                                                                                {
                                                                                                    slot
                                                                                                        .appointment
                                                                                                        .patient
                                                                                                        .name
                                                                                                }
                                                                                            </>
                                                                                        ) : slot.locked ? (
                                                                                            <>
                                                                                                <Badge
                                                                                                    className={`font-size-14 badge-soft-danger`}
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            "2px",
                                                                                                    }}
                                                                                                    pill
                                                                                                >
                                                                                                    <small>
                                                                                                        {
                                                                                                            "Bloqueado"
                                                                                                        }
                                                                                                    </small>
                                                                                                </Badge>{" "}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Badge
                                                                                                    className={`font-size-14 badge-soft-success`}
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            "2px",
                                                                                                    }}
                                                                                                    pill
                                                                                                >
                                                                                                    <small>
                                                                                                        {
                                                                                                            "Livre"
                                                                                                        }
                                                                                                    </small>
                                                                                                </Badge>

                                                                                                {renderBadgeServicesAccepted(
                                                                                                    slot
                                                                                                        .time
                                                                                                        .services,
                                                                                                    2
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </td>
                                                                                )}
                                                                                {controlPermissionAppointment ==
                                                                                    "view_my_prof_appointment" &&
                                                                                (slot
                                                                                    ?.appointment
                                                                                    ?.status ==
                                                                                    pageData
                                                                                        .appointment_status
                                                                                        .DESMARCADO ||
                                                                                    slot
                                                                                        ?.appointment
                                                                                        ?.status ==
                                                                                        pageData
                                                                                            .appointment_status
                                                                                            .CANCELADO) ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td
                                                                                        style={{
                                                                                            display:
                                                                                                "flex",
                                                                                            justifyContent:
                                                                                                "flex-end",
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            className="far fa-eye font-size-18 mr-3"
                                                                                            style={{
                                                                                                cursor:
                                                                                                    "pointer",
                                                                                            }}
                                                                                        />
                                                                                        {/*<i className="fas fa-dollar-sign font-size-18"></i>*/}
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            )
                        )
                    )}
                </Container>
            </div>
            <ModalAppointment
                show={modalAppointmentData.show}
                data={modalAppointmentData.data}
                onSuccessManage={onSaveAppointment}
                onClose={() => {
                    setModalAppointmentData({ show: false, data: null })
                }}
            ></ModalAppointment>
        </React.Fragment>
    )
}

export default AppointmentList
