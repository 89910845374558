import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if(filter.type) query+="&type=" + filter.type
    if(filter.status) query+="&status=" + filter.status
    if(filter.item_id) query+="&item_id=" + filter.item_id
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners` +
        query,
        vtAuth: true
    })
}

const getItems = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.partner_id) query += "&partner_id=" + filter.partner_id
    if(filter?.item_id)query+= "&item_id="+ filter?.item_id?.join(',')
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners/items` +
        query,
        vtAuth: true
    })
}

const searchByItem = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.item_id) query += "&item_id=" + filter.item_id
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners/searchbyitem` +
        query,
        vtAuth: true
    })
}

const create = async partner_data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/partners`,
        data: partner_data,
        vtAuth: true
    })
}

const update = async (id, partner_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners/${id}`,
        data: partner_data,
        vtAuth: true
    })
}

const deletePartner = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/partners/${id}`,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners/${id}/edit`,
        vtAuth: true
    })
}

const getCompleteItems = async id =>{
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/partners/${id}/fullitems`,
        vtAuth: true
    })
}

export {
    search,
    create,
    update,
    loadDataEditform,
    deletePartner,
    getItems,
    searchByItem,
    getCompleteItems
}
