import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"
import {
    showReceivable,
    showProfessionalReceivables,
} from "services/vitta-core/professional-transfer-invoices"

const Receivable = () => {
    const history = useHistory()
    const userAuth = useSelector(state => state.auth.access?.user)
    const controlPermissionProfessional = useMemo(() => {
        let found = false
        for (let i = 0; i < userAuth?.access_roles?.length; i++) {
            let record = userAuth?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_appointment") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            return false
        }
    }, [userAuth])
    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })
    const [controlState, setControlState] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        const loadData = async () => {
            try {
                setControlState(true)
                let result = null
                let dataSend = {}
                let search = location.search

                const params = new URLSearchParams(search)
                const professional_id = params.get("professional_id")
                const date_payment_forecast_start = params.get(
                    "date_payment_forecast_start"
                )
                const date_payment_forecast_end = params.get(
                    "date_payment_forecast_end"
                )
                const unit_id = params.get("unit_id")
                const sales_order_id = params.get("sales_order_id")
                dataSend = {
                    date_payment_forecast_start: date_payment_forecast_start,
                    date_payment_forecast_end: date_payment_forecast_end,
                    professional_id: professional_id,
                    unit_id: unit_id,
                    sales_order_id: sales_order_id,
                }
                if (controlPermissionProfessional) {
                    result = await showReceivable(dataSend)
                } else {
                    result = await showProfessionalReceivables(dataSend)
                }

                setPageData({
                    header: {
                        professional: result?.professional,
                        start_date_exec: date_payment_forecast_start,
                        end_date_exec: date_payment_forecast_end,
                    },
                    totalGroups: result?.totalGroups,
                    totalSumGroups: result?.totalSumGroups,
                    data: result?.report_result,
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
                if (e?.response?.data) {
                    switch (e.response.data.error_code) {
                        case "date_period_limit_exceeded":
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Filtro de data inválido!",
                                    text:
                                        "É possível filtrar no máximo 6 meses de diferença entre datas, verifique os filtros de data e tente novamente.",
                                    type: "error",
                                    onConfirmAction: () => {
                                        history.push(
                                            "/relatorios/repasses-medicos"
                                        )
                                        dispatch(
                                            showToast({
                                                title:
                                                    "Você foi redirecionado!",
                                                type: "success",
                                            })
                                        )
                                    },
                                    showConfirm: true,
                                    confirmBtnText: "Entendi!",
                                })
                            )

                            return
                    }
                }
            }
        }
        loadData()
    }, [])

    const printReport = () => {
        window.print()
    }

    const calcTotalNota = items => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]
            total += useItem.quantity * (useItem.price ? useItem.price : 0)
        }

        return total
    }

    return (
        <React.Fragment>
            <div className="page-content" translate="no">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Repasse Médico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody translate="no">
                                    <div align="end">
                                        <Link
                                            to="/relatorios/repasses-medicos"
                                            className="btn btn-dark d-print-none mr-2"
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Button
                                            className="btn btn-primary d-print-none"
                                            color="primary"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Button>
                                        <br />
                                        <br />
                                    </div>
                                    <Row>
                                        <Col
                                            md="8"
                                            className="d-flex justify-content-start align-items-center"
                                        >
                                            <img
                                                src={logovitta}
                                                alt=""
                                                height="80px"
                                                className="mb-2"
                                            />
                                            Previsão Exec. Inicial:{" "}
                                            {pageData.header?.start_date_exec
                                                ? new Date(
                                                      pageData.header
                                                          ?.start_date_exec +
                                                          " 00:00:00"
                                                  ).toLocaleDateString("pt-BR")
                                                : null}{" "}
                                            {" - "}
                                            Previsão Exec. Final:{" "}
                                            {pageData.header?.end_date_exec
                                                ? new Date(
                                                      pageData.header
                                                          ?.end_date_exec +
                                                          " 00:00:00"
                                                  ).toLocaleDateString("pt-BR")
                                                : null}
                                            <br />
                                            {/* <div className="d-flex flex-column  ">
                                                <h4>Unidade {pageData.header?.unit?.name}</h4>
                                                <div className="">CNPJ: {pageData.header?.unit?.cnpj}</div>
                                            </div> */}
                                        </Col>

                                        <Col
                                            md="4"
                                            className="d-flex  flex-column "
                                        >
                                            <div className="">
                                                <strong>Profissional:</strong>{" "}
                                                {pageData.header?.professional
                                                    ? pageData.header
                                                          ?.professional?.name
                                                    : null}
                                            </div>
                                        </Col>
                                        <Col sm="12"></Col>
                                    </Row>

                                    <br />
                                    <h4 align="center">Recebíveis</h4>
                                    <br />
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">ID Pedido</th>
                                                <th scope="col">
                                                    Nome unidade
                                                </th>
                                                <th scope="col">
                                                    Total a receber pedido (R$)
                                                </th>
                                                {/* <th scope="col">ID OS</th> */}
                                                <th scope="col">
                                                    Total recebido pedido (R$)
                                                </th>
                                                <th scope="col">
                                                    Previsão pagamento parcial
                                                </th>
                                                <th scope="col">
                                                    Valor pagamento parcial (R$)
                                                </th>
                                                {/* <th scope="col">Item Nota</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData?.data?.map(
                                                (row, index) => (
                                                    <tr>
                                                        <td>
                                                            {
                                                                row?.sales_order_id
                                                            }
                                                        </td>
                                                        <td>
                                                            {row?.unit_name}
                                                        </td>
                                                        <td>
                                                            
                                                                R${" "}
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(parseInt(row?.total_receive_order)/100)
                                                                }
                                                                
                                                            
                                                        </td>

                                                        <td>
                                                        R${" "}
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(parseInt(row?.total_order_received)/100)
                                                                }
                                                           
                                                        </td>
                                                        <td>
                                                        {new Date(row?.partial_payment_forecast).toLocaleDateString('pt-BR')}
                                                          
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(parseInt(row?.partial_payment_amount)/100)
                                                                }
                                                           
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    {controlState ? (
                                        <div
                                            className="spinner-border text-info"
                                            role="status"
                                            style={{
                                                position: "absolute",
                                                left: "633px",
                                                top: "410",
                                            }}
                                        >
                                            <span className="sr-only">
                                                Sincronizando...
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Receivable
