import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as dentalEvaluationSearch } from "services/vitta-core/assessments"
import ASSESSMENTS_STATUS from "utils/consts/assessments-status"
import debounce from "debounce-promise"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const DentalEvaluationList = () => {
    const [DentalEvaluationList, setDentalEvaluationList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const [modalPaymentData, setModalPaymentData] = useState({
        show: false,
        data: null,
    })

    const [pageControl, setPageControl] = useState({ searching: false })
    const [status, setStatus] = useState([
        {
            label: "Todos",
            value:null,
        },
        {
            label: "Em Avaliação",
            value: ASSESSMENTS_STATUS.UNDER_EVALUATION,
        },
        {
            label: "Em Negociação",
            value: ASSESSMENTS_STATUS.IN_NEGOCIATION,
        },
        {
            label: "Negociado",
            value: ASSESSMENTS_STATUS.CONCLUDED,
        },
    ])

    const inputPatient = useRef()
    const inputStartCreatedAt = useRef()
    const inputEndCreatedAt = useRef()
    const inputStatus = useRef()
    const inputRefUnit = useRef()

    const dispatch = useDispatch()
    const history = useHistory()
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)

    const search = async page => {
        // if (pageControl.searching) return
        let sizePerPage = 10
        setPageControl({ ...pageControl, ...{ searching: true } })
        try {
            //limpando data table
            setDentalEvaluationList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: 0,
                custom: true,
                data: [],
            })

            let searchObj = {}
    
    
            let patient_id = inputPatient.current.select?.state?.value?.value
            let start_created_at = inputStartCreatedAt.current.value
            let end_created_at = inputEndCreatedAt.current.value
            let status = inputStatus.current?.state?.value?.value
    
            searchObj.expand_patient = true
            searchObj.expand_user = true
            searchObj.unit_id = inputRefUnit.current?.state?.value?.value
    
            if (patient_id) searchObj.patient_id = patient_id
            if (start_created_at) searchObj.start_created_at = start_created_at
            if (end_created_at) searchObj.end_created_at = end_created_at
            if (status) searchObj.status = status

            let data = await dentalEvaluationSearch(searchObj, page, sizePerPage)

            setDentalEvaluationList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data?.total_pages * sizePerPage,
                custom: true,
                data: data?.data,
            })
        } catch (e) {
            console.log("error", e)
        }
        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    useEffect(() => {
        search()
    }, [])

    const onTableChange = (a, b, c) => {
        if (a == "pagination") {
            search(b.page)
        }
    }

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"  },
                    1,
                    20
                )

                let patients_values = []

                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)

    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: `#${element.id} ` + element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-4 mb-4">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Avaliações"
                        breadcrumbItem="Lista de Avaliações"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            DentalEvaluationList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={
                                                    DentalEvaluationList.data ||
                                                    []
                                                }
                                                columns={DocsListColumns()}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mt-1 mb-2">
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Filtrar
                                                                    Unidade
                                                                </Label>
                                                                <Select
                                                                    name="unit_id"
                                                                    defaultValue={{
                                                                        label:
                                                                            `#${Scope.unit.id} ` +
                                                                            Scope.unit.name?.substr(
                                                                                0,
                                                                                40
                                                                            ),
                                                                        value:
                                                                            Scope
                                                                                .unit
                                                                                .id,
                                                                    }}
                                                                    ref={
                                                                        inputRefUnit
                                                                    }
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="3"
                                                            >
                                                                <Label>
                                                                    Filtrar
                                                                    Paciente
                                                                </Label>
                                                                <AsyncSelect
                                                                    name="patient_id"
                                                                    placeholder="Selecione o Paciente"
                                                                    ref={
                                                                        inputPatient
                                                                    }
                                                                    cacheOptions
                                                                    isClearable
                                                                    defaultOptions
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                    noOptionsMessage={() =>
                                                                        "Sem Resultados. Digite para Buscar"
                                                                    }
                                                                />
                                                            </Col>

                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Criado a
                                                                    partir de{" "}
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputStartCreatedAt
                                                                    }
                                                                    defaultValue={
                                                                        todayOnlyDate
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Criado até
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputEndCreatedAt
                                                                    }
                                                                    defaultValue={
                                                                        todayOnlyDate
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Status
                                                                </Label>
                                                                <Select
                                                                    ref={
                                                                        inputStatus
                                                                    }
                                                                    defaultValue={{
                                                                        label:
                                                                            "Todos",
                                                                        value: null,
                                                                    }}
                                                                    options={
                                                                        status
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="1"
                                                                className="mt-1"
                                                            >
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                    }}
                                                                    type="button"
                                                                    color="primary"
                                                                    className="mt-4"
                                                                    onClick={() =>
                                                                        search()
                                                                    }
                                                                >
                                                                    Buscar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "thead-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            onTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DentalEvaluationList
