import React from "react"
import {
    Modal,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ComponentInvoice from "../ComponentsInvoices/index"


const ModalExecutionComissions = ({ show, data, onClose }) => {

    return (
        <Modal
            isOpen={show}
            centered={false}
            size="xl"
        >
              <ComponentInvoice data={data} onClose={onClose}/>
   
        </Modal>
    )
}

export default ModalExecutionComissions
