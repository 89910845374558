import React from "react"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { hideToast } from "store/layout/actions"
import { useDispatch, useSelector } from 'react-redux';

const ToastNotification = props => {

  const dispatch = useDispatch();

  const ToastMessage = useSelector(state => state.Layout.toastNotification)

  if(ToastMessage.show){
    toastr.options = ToastMessage.options
        let hideEvent = () => dispatch(hideToast())
        toastr.options.onHidden = hideEvent
        toastr.options.onclick = hideEvent
        toastr.options.onCloseClick = hideEvent

        if (ToastMessage.type === "info")
            toastr.info(ToastMessage.text, ToastMessage.title)
        else if (ToastMessage.type === "warning")
            toastr.warning(ToastMessage.text, ToastMessage.title)
        else if (ToastMessage.type === "error")
            toastr.error(ToastMessage.text, ToastMessage.title)
        else toastr.success(ToastMessage.text, ToastMessage.title)
  }

  return (<></>);
}

export default ToastNotification
