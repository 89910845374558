import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
    Badge,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import { Link, useLocation } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { paymentsReport as paymentsReportSalesOrderPaymentService } from "services/vitta-core/sales-order-payment"
import { useHistory } from "react-router-dom"
import SALES_ORDERS_PAYMENT_STATUS from "utils/consts/sales-orders-payment-status"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog } from "store/actions"
import TRANSACTION_MODES from "utils/consts/transaction-mode"

const ReportPaymentReceived = ({ filter, state, result }) => {
    const history = useHistory()
    const [controlState, setControlState] = useState(true)
    const dispatch = useDispatch()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })

    useEffect(() => {
        const loadData = async () => {
            try {
                // let startDate = filter.start_created_at
                //     ? new Date(
                //           filter.start_created_at + " 00:00:00"
                //       ).toISOString()
                //     : null
                // let endDate = filter.end_created_at
                //     ? new Date(
                //           filter.end_created_at + " 23:59:59"
                //       ).toISOString()
                //     : null
                // setControlState(true)
                // let result = await paymentsReportSalesOrderPaymentService(
                //     {
                //         created_user_id: filter.created_user?.id,
                //         unit_id: filter.unit,
                //         start_created_at: startDate,
                //         end_created_at: endDate,
                //     },
                //     1,
                //     1000
                // )
                //old
                // let total_report = 0
                // let total_cc = 0
                // let total_cd = 0
                // let total_money = 0
                // let total_pix = 0
                // if (result && result.data) {
                //     for (let i = 0; i < result.data.length; i++) {
                //         let usePay = result.data[i]

                //         if (usePay.status != SALES_ORDERS_PAYMENT_STATUS.PAID)
                //             continue

                //         total_report += usePay.value
                //         if (usePay.pay_form_id == "CD") {
                //             total_cd += usePay.value
                //         } else if (usePay.pay_form_id == "CC") {
                //             total_cc += usePay.value
                //         } else if (usePay.pay_form_id == "R$") {
                //             total_money += usePay.value
                //         } else if (usePay.pay_form_id == "PIX") {
                //             total_pix += usePay.value
                //         }
                //     }
                // }

                let total_report = 0
                let total_cc = 0
                let total_cd = 0
                let total_money = 0
                let total_pix = 0
                let total_cc_manual = 0
                let total_cc_electronic = 0
                let total_cd_manual = 0
                let total_cd_electronic = 0
                let total_money_manual = 0
                let total_money_electronic = 0
                let total_pix_manual = 0
                let total_pix_electronic = 0
                if (result && result.data) {
                    for (let i = 0; i < result.data.length; i++) {
                        let usePay = result.data[i]

                        if (usePay.status != SALES_ORDERS_PAYMENT_STATUS.PAID)
                            continue

                        total_report += usePay.value

                        if (usePay.pay_form_id == "CD") {
                            total_cd += usePay.value
                            if (
                                usePay.transaction_mode ==
                                TRANSACTION_MODES.ELECTRONIC
                            ) {
                                total_cd_electronic += usePay.value
                            } else {
                                total_cd_manual += usePay.value
                            }
                        } else if (usePay.pay_form_id == "CC") {
                            total_cc += usePay.value
                            if (
                                usePay.transaction_mode ==
                                TRANSACTION_MODES.ELECTRONIC
                            ) {
                                total_cc_electronic += usePay.value
                            } else {
                                total_cc_manual += usePay.value
                            }
                        } else if (usePay.pay_form_id == "R$") {
                            total_money += usePay.value
                            if (
                                usePay.transaction_mode ==
                                TRANSACTION_MODES.ELECTRONIC
                            ) {
                                total_money_electronic += usePay.value
                            } else {
                                total_money_manual += usePay.value
                            }
                        } else if (usePay.pay_form_id == "PIX") {
                            total_pix += usePay.value
                            if (
                                usePay.transaction_mode ==
                                TRANSACTION_MODES.ELECTRONIC
                            ) {
                                total_pix_electronic += usePay.value
                            } else {
                                total_pix_manual += usePay.value
                            }
                        }
                    }
                }
                setPageData({
                    header: {
                        unit: filter.unit,
                        created_user: filter.created_user,
                        start_created_at: filter.start_created_at
                            ? new Date(filter.start_created_at + " 00:00:00")
                            : null,
                        end_created_at: filter.end_created_at
                            ? new Date(filter.end_created_at + " 23:59:59")
                            : null,
                        total_report: total_report,
                        total_cc: total_cc,
                        total_cd: total_cd,
                        total_money: total_money,
                        total_pix: total_pix,
                        total_cc_manual: total_cc_manual,
                        total_cc_electronic: total_cc_electronic,
                        total_cd_manual: total_cd_manual,
                        total_cd_electronic: total_cd_electronic,
                        total_money_manual: total_money_manual,
                        total_money_electronic: total_money_electronic,
                        total_pix_manual: total_pix_manual,
                        total_pix_electronic: total_pix_electronic,
                    },
                    data: result.data,
                })

                setControlState(false)
            } catch (e) {
                setControlState(false)
                //state(false)
                console.log(e)
                // if(e?.response?.data?.error_code==='just_one_unit'){
                //     return dispatch(
                //         showSweetAlertDialog({
                //             title: "Ops... ",
                //             text:
                //                 "É permitido apenas uma unidade no modo visualização",
                //             type: "error",
                //         })
                //     )
                // }
                // if (e?.response?.status == 422) {
                //     return dispatch(
                //         showSweetAlertDialog({
                //             title: "Ops... ",
                //             text:
                //                 "Insira um período",
                //             type: "error",
                //         })
                //     )
                // }
            }
        }

        if (filter) {
            loadData()
        } else history.push("/pagamentos-recebidos")
    }, [filter])

    const printReport = () => {
        window.print()
    }

    const renderBadgeStatus = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case SALES_ORDERS_PAYMENT_STATUS.PAID:
                text = "Pago"
                badgeClass = "badge-soft-success"
                break
            case SALES_ORDERS_PAYMENT_STATUS.CANCELED:
                text = "Cancelado"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Pagamentos recebidos"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div align="end">
                                        <Link
                                            to="#"
                                            className="btn btn-dark d-print-none mr-2"
                                            onClick={() => state(false)}
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Link
                                            to="/relatorios/pagamentos-recebidos"
                                            className="btn btn-primary d-print-none"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Link>
                                    </div>
                                    <AvForm align="center">
                                        <img
                                            src={logovitta}
                                            alt=""
                                            height="75px"
                                            className="mb-2"
                                        />
                                        <h2 align="center">
                                            {pageData.header?.unit?.name}
                                        </h2>
                                        <p />
                                        <h4>
                                            Relatório de recebimento -{" "}
                                            {pageData.header?.start_created_at
                                                ? new Date(
                                                      pageData.header?.start_created_at
                                                  ).toLocaleDateString("pt-BR")
                                                : null}{" "}
                                            a{" "}
                                            {pageData.header?.end_created_at
                                                ? new Date(
                                                      pageData.header?.end_created_at
                                                  ).toLocaleDateString("pt-BR")
                                                : null}
                                        </h4>
                                        <h5>
                                            {pageData.header?.created_user
                                                ? "Usuário: " +
                                                  pageData.header?.created_user
                                                      ?.name
                                                : null}
                                        </h5>
                                        <p></p>
                                        {controlState ? (
                                            <Row>
                                                <Col align="center">
                                                    <div
                                                        className="spinner-border text-info"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">
                                                            Sincronizando...
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <>
                                                <table className="table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">
                                                                Data
                                                            </th>
                                                            <th scope="col">
                                                                ID
                                                            </th>
                                                            <th scope="col">
                                                                Paciente
                                                            </th>
                                                            <th scope="col">
                                                                CPF
                                                            </th>
                                                            <th scope="col">
                                                                Pedido de Venda
                                                            </th>

                                                            <th scope="col">
                                                                Forma de
                                                                Pagamento
                                                            </th>

                                                            <th scope="col">
                                                                Parcelas
                                                            </th>

                                                            <th scope="col">
                                                                Status
                                                            </th>

                                                            <th scope="col">
                                                                Valor (R$)
                                                            </th>

                                                            <th scope="col">
                                                                Usuário
                                                                Recebimento
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pageData.data.map(
                                                            (row, index) => (
                                                                <tr>
                                                                    <td>
                                                                        {row.created_at
                                                                            ? new Date(
                                                                                  row.created_at
                                                                              ).toLocaleDateString(
                                                                                  "pt-BR",
                                                                                  {
                                                                                      hour:
                                                                                          "2-digit",
                                                                                      hour12: false,
                                                                                      minute:
                                                                                          "2-digit",
                                                                                      second:
                                                                                          "2-digit",
                                                                                  }
                                                                              )
                                                                            : null}{" "}
                                                                    </td>
                                                                    <td>
                                                                        {row.id}
                                                                    </td>
                                                                    <th>
                                                                        {
                                                                            row
                                                                                .sales_order
                                                                                ?.patient
                                                                                ?.name
                                                                        }
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            row
                                                                                .sales_order
                                                                                ?.patient
                                                                                ?.cpf
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        #
                                                                        {
                                                                            row
                                                                                .sales_order
                                                                                ?.id
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {row.pay_form_id ===
                                                                        "R$"
                                                                            ? "Dinheiro"
                                                                            : row.pay_form_id ===
                                                                              "CC"
                                                                            ? "Cartão de Crédito "
                                                                            : row.pay_form_id ===
                                                                              "CD"
                                                                            ? "Cartão de Débito "
                                                                            : row.pay_form_id}
                                                                        {row.transaction_mode ===
                                                                        TRANSACTION_MODES.ELECTRONIC ? (
                                                                            <small>
                                                                                <strong>
                                                                                    {" "}
                                                                                    (Eletrônico)
                                                                                </strong>
                                                                            </small>
                                                                        ) : row.pay_form_id !==
                                                                          "R$" ? (
                                                                            <small>
                                                                                <strong>
                                                                                    {" "}
                                                                                    (Manual)
                                                                                </strong>
                                                                            </small>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            row.installments
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {renderBadgeStatus(
                                                                            row.status
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {(row.value >
                                                                        0
                                                                            ? row.value /
                                                                              100
                                                                            : 0
                                                                        ).toLocaleString(
                                                                            "pt-BR",
                                                                            {
                                                                                style:
                                                                                    "currency",
                                                                                currency:
                                                                                    "BRL",
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {row.created_user
                                                                            ? row.created_user.name.substr(
                                                                                  0,
                                                                                  20
                                                                              )
                                                                            : null}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                                <br />
                                                <br />
                                                <div
                                                    className="payment-summary"
                                                    style={{
                                                        textAlign: "left",
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                        color: "#333",
                                                    }}
                                                >
                                                    {pageData.header
                                                        ?.total_cc_manual >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-credit-card payment-icon manual mr-2"></i>
                                                                Total em Cartão
                                                                de Crédito:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_cc_manual /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Manual)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_cc_electronic >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-credit-card payment-icon electronic mr-2"></i>
                                                                Total em Cartão
                                                                de Crédito:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_cc_electronic /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Eletrônico)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_cd_manual >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-credit-card payment-icon manual mr-2"></i>
                                                                Total em Cartão
                                                                de Débito:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_cd_manual /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Manual)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_cd_electronic >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-credit-card payment-icon electronic mr-2"></i>
                                                                Total em Cartão
                                                                de Débito:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_cd_electronic /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Eletrônico)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_money > 0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-money-bill-wave payment-icon mr-2"></i>
                                                                Total em
                                                                Dinheiro:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_money /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_pix_manual >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-piggy-bank payment-icon manual mr-2"></i>
                                                                Total em PIX:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_pix_manual /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Manual)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_pix_electronic >
                                                        0 && (
                                                        <>
                                                            <span className="payment-item">
                                                                <i className="fas fa-piggy-bank payment-icon electronic mr-2"></i>
                                                                Total em PIX:{" "}
                                                                <strong className="payment-amount">
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_pix_electronic /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                                <small className="payment-mode ml-1">
                                                                    (Eletrônico)
                                                                </small>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {pageData.header
                                                        ?.total_report > 0 && (
                                                        <>
                                                            <span
                                                                className="payment-item"
                                                                style={{
                                                                    fontSize:
                                                                        "1.2em",
                                                                    fontWeight:
                                                                        "bold",
                                                                    color:
                                                                        "#343a40",
                                                                }}
                                                            >
                                                                <i className="fas fa-receipt payment-icon total mr-2"></i>
                                                                Total
                                                                Recebimentos:{" "}
                                                                <strong
                                                                    className="payment-amount"
                                                                    style={{
                                                                        color:
                                                                            "#28a745",
                                                                    }}
                                                                >
                                                                    {(
                                                                        pageData
                                                                            .header
                                                                            ?.total_report /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                </strong>
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                </div>
                                                {/* <div
                                            classname=""
                                            style={{ textAlign: "left" }}
                                        >
                                            Total em Cartão de Crédito:{" "}
                                            {pageData.header?.total_cc > 0
                                                ? (
                                                      pageData.header
                                                          ?.total_cc / 100
                                                  ).toLocaleString("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  })
                                                : 0}{" "}
                                            <br />
                                            Total em Cartão de Débito:{" "}
                                            {pageData.header?.total_cd > 0
                                                ? (
                                                      pageData.header
                                                          ?.total_cd / 100
                                                  ).toLocaleString("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  })
                                                : 0}{" "}
                                            <br />
                                            Total em Dinheiro:{" "}
                                            {pageData.header?.total_money > 0
                                                ? (
                                                      pageData.header
                                                          ?.total_money / 100
                                                  ).toLocaleString("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  })
                                                : 0}{" "}
                                            <br />
                                            Total em PIX:{" "}
                                            {pageData.header?.total_pix > 0
                                                ? (
                                                      pageData.header
                                                          ?.total_pix / 100
                                                  ).toLocaleString("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  })
                                                : 0}{" "}
                                            <br />
                                            Total Recebimentos:{" "}
                                            {pageData.header?.total_report > 0
                                                ? (
                                                      pageData.header
                                                          ?.total_report / 100
                                                  ).toLocaleString("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  })
                                                : 0}{" "}
                                            <br />
                                        </div> */}
                                            </>
                                        )}
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ReportPaymentReceived
