import React, {useEffect} from "react"
import { useDispatch } from "react-redux"

import { loadVersion } from "services/vitta-core/auto-update"
import { showSweetAlertDialog } from "store/layout/actions"

const AutoUpdater = () => {

  const dispatch = useDispatch()

  console.log("Load Auto updater");

  useEffect(() => {
    //console.log('App useeffect Auto updater')

    let intervalMinutes = 1;

    let intervalHandler = null;

    const semverGreaterThan = (versionA, versionB) => {
      const versionsA = versionA.split(/\./g);
    
      const versionsB = versionB.split(/\./g);
      while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
    
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
      }
      return false;
    };

    const checkNewVersion = async () => {

      try {
        let version = await loadVersion();

        if (version && version.web && process.env.REACT_APP_VITTANET_WEB_VERSION && semverGreaterThan(version.web, process.env.REACT_APP_VITTANET_WEB_VERSION)) {
          console.log("Nova versão encontrada.", process.env.REACT_APP_VITTANET_WEB_VERSION, "=>", version.web);
          dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Existe uma nova versão do sistema! Atualize para receber as novas funcionalidades e melhorias. Deseja atualizar agora?",
                type: "warning",
                onConfirmAction: () => {
                    if ('serviceWorker' in navigator) {
                      navigator.serviceWorker.getRegistrations()
                      .then(function(registrations) {
                         for(let registration of registrations) {
                            registration.unregister();
                         }
                      });
                    } 
                    if (caches) {
                      // Service worker cache should be cleared with caches.delete()
                      caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                      });
                    }
                    window.location.reload(true);
                },
                onCancelAction: () => {
                  //console.log("cancelado");
                },
                closeOnClickOutside: false,
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Depois",
                confirmBtnText: "Atualizar",
            })
        )
        }

      }
      catch (e) {
        console.log(e);
      }

    };

    checkNewVersion();

    intervalHandler = setInterval(checkNewVersion, intervalMinutes * 60 * 1000);

  }, [])

  return (
    <></>
  )
}

export default AutoUpdater
