import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.status) query += "&status=" + filter.status
    if(filter.fields) query +="&fields=" + filter.fields
    // if (filter.expand_vitta_cards) query += "&expand_vitta_cards=" + filter.expand_vitta_cards

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/patients` +
        query,
        vtAuth: true
    })
}

const create = async patient_data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/patients`,
        data: patient_data,
        vtAuth: true
    })
}

const deletePatient = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/patients/${id}`,
        vtAuth: true
    })
}

const update = async (id, patient_data) => {
    return request({
        method: "POST",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/patients/${id}`,
        data: patient_data,
        vtAuth: true
    })
}

const loadDataAddform = async () => {
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/patients/add`,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:   process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/patients/${id}`,
        vtAuth: true
    })
}

const findPatient = async (id,expand_vitta_cards) => {
    let query = '';
    if(expand_vitta_cards)
    {
        query = `?expand_vitta_cards=${expand_vitta_cards}`
    }
    return request({
        method: "GET",
        url:   process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/find-patient/${id}` +
        query,
        vtAuth: true
    })
}
const findBenefits = async (subscription_id) => {
  
    return request({
        method: "GET",
        url:   process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/find-benefits/${subscription_id}`,
        vtAuth: true
    })
}

export {
    search,
    create,
    update,
    loadDataAddform,
    loadDataEditform,
    deletePatient,
    findPatient,
    findBenefits
}
