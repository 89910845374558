import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector } from "react-redux"

import { search as PatientServiceSearch } from "services/vitta-core/patient"
import AsyncSelect from "react-select/async"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch } from "react-redux"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import { search as userServiceSearch } from "services/vitta-core/user"
import { useHistory } from "react-router-dom"
import debounce from "debounce-promise"
import { exportVittaCardContracts } from "services/vitta-core/vitta-card-contract"
import VITTA_CARD_CONTRACT_STATUS from "utils/consts/vitta-card-contract-status"
import VITTA_CARD_STATUS from "utils/consts/vitta-card-status"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const VittaCardContract = () => {
    const history = useHistory()
    const Scope = useSelector(state => state.scope)
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })
    const dispatch = useDispatch()
    const [controlState, setControlState] = useState(false)
    const userAuth = useSelector(state => state.auth.access?.user)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = [
            {
                label: "Todas",
                value: null,
            },
        ]
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadPatientsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await PatientServiceSearch(
                    { term: inputValue, fields: "id,name" },
                    1,
                    25
                )

                let users_values = []
                users_values.push({ label: "Sem filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientsInputOptions, 600)
    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)

    const onSubmit = async (data_form, actions) => {
        try {
            let unit_ids = []
            let status_vitta_card_contract_ids = []
            let status_vitta_card_ids = []
            // if (!data_form.unit_id || data_form.unit_id.length == 0) {
            //     return dispatch(
            //         showSweetAlertDialog({
            //             title: "Ops...",
            //             text: "Informe pelo menos uma Unidade Clínica.",
            //             type: "error",
            //             showConfirm: true,
            //         })
            //     )
            // }
            //descomentar depois
            // if (
            //     !data_form.status_contract ||
            //     data_form.status_contract.length == 0
            // )
            // {
            //     return dispatch(
            //         showSweetAlertDialog({
            //             title: "Ops...",
            //             text: "Informe pelo menos uma Unidade Clínica.",
            //             type: "error",
            //             showConfirm: true,
            //         })
            //     )
            // }
            // if (
            //     !data_form.status_vitta_card ||
            //     data_form.status_vitta_card.length == 0
            // )
            // {
            //     return dispatch(
            //         showSweetAlertDialog({
            //             title: "Ops...",
            //             text: "Informe pelo menos .",
            //             type: "error",
            //             showConfirm: true,
            //         })
            //     )
            // }
            if (
                !(data_form?.start_created_at && data_form?.end_created_at) &&
                !(data_form?.start_signed_at && data_form?.end_signed_at) &&
                !(data_form?.start_canceled_at && data_form?.end_canceled_at)
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: "Informe pelo menos um dos filtros de data.",
                        type: "info",
                        showConfirm: true,
                    })
                )
            }

            for (let i = 0; i < data_form?.unit_id?.length; i++) {
                unit_ids.push(data_form?.unit_id[i]?.value)
            }
            for (let i = 0; i < data_form?.status_vitta_card?.length; i++) {
                status_vitta_card_ids.push(
                    data_form?.status_vitta_card[i]?.value
                )
            }
            for (let i = 0; i < data_form?.status_contract?.length; i++) {
                status_vitta_card_contract_ids.push(
                    data_form?.status_contract[i]?.value
                )
            }

            let auxData = {}
            auxData.patient = data_form?.patient_id?.value
                ? {
                      id: data_form?.patient_id?.value,
                      name: data_form?.patient_id?.label,
                  }
                : null

            auxData.unit_id = unit_ids
            auxData.status_contract = status_vitta_card_contract_ids
            auxData.status_vitta_card = status_vitta_card_ids
            auxData.user = data_form?.user_id?.value
                ? {
                      id: data_form?.user_id?.value,
                      name: data_form?.user_id?.label,
                  }
                : null
            auxData.start_created_at = data_form?.start_created_at
            auxData.end_created_at = data_form?.end_created_at
            auxData.start_signed_at = data_form?.start_signed_at
            auxData.end_signed_at = data_form?.end_signed_at
            auxData.start_canceled_at = data_form?.start_canceled_at
            auxData.end_canceled_at = data_form?.end_canceled_at

            if (data_form?.format?.value == "excel") {
                setControlState(true)
                let response = await exportVittaCardContracts({
                    user_id: auxData?.user?.id,
                    unit_id: auxData?.unit_id,
                    status_vitta_card: auxData?.status_vitta_card,
                    status_contract: auxData?.status_contract,
                    patient_id: auxData?.patient?.id,
                    start_created_at: auxData.start_created_at,
                    end_created_at: auxData.end_created_at,
                    start_signed_at: auxData.start_signed_at,
                    end_signed_at: auxData.end_signed_at,
                    start_canceled_at: auxData.start_canceled_at,
                    end_canceled_at: auxData.end_canceled_at,
                })

                var headers = response.headers
                var blob = new Blob([response.data], {
                    type: headers["content-type"],
                })
                var link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = "contratos"
                link.click()
                setControlState(false)
                return
            }

            // setReportControl({
            //     show: true,
            //     filter: auxData,
            // })
        } catch (e) {
            console.log(e)
            setControlState(false)
            if (e?.response?.status == 422) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Filtro de data inválido!",
                        text:
                            "É possível filtrar no máximo 6 meses de diferença entre datas, verifique os filtros de data e tente novamente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
        }
    }

    if (reportControl.show) {
        let query = `/relatorios/contratos-vitta-card/view?unit_id=${reportControl?.filter?.unit_id}`
        if (reportControl?.filter?.patient) {
            query += "&patient_id=" + reportControl?.filter?.patient?.id
        }
        if (reportControl?.filter?.status_vitta_card) {
            query +=
                "&status_vitta_card=" + reportControl?.filter?.status_vitta_card
        }
        if (reportControl?.filter?.status_contract) {
            query +=
                "&status_contract=" + reportControl?.filter?.status_contract
        }
        if (reportControl?.filter?.user) {
            query += "&user_id=" + reportControl?.filter?.user?.id
        }
        if (reportControl?.filter?.start_created_at) {
            query +=
                "&start_created_at=" + reportControl?.filter?.start_created_at
        }
        if (reportControl?.filter?.end_created_at) {
            query += "&end_created_at=" + reportControl?.filter?.end_created_at
        }
        if (
            reportControl?.filter?.start_signed_at &&
            reportControl?.filter?.end_signed_at
        ) {
            query +=
                "&start_signed_at=" + reportControl?.filter?.start_signed_at
        }
        if (reportControl?.filter?.end_signed_at) {
            query += "&end_signed_at=" + reportControl?.filter?.end_signed_at
        }
        if (reportControl?.filter?.start_canceled_at) {
            query +=
                "&start_canceled_at=" + reportControl?.filter?.start_canceled_at
        }
        if (reportControl?.filter?.end_canceled_at) {
            query +=
                "&end_canceled_at=" + reportControl?.filter?.end_canceled_at
        }

        history.push(query)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Contratos Cartões vittá / Relatórios" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-2"
                                            >
                                                Contratos Cartões{" "}
                                                <span className="text-info">
                                                    Vittá{" "}
                                                </span>
                                                <i className="fas fa-plus text-info"></i>
                                            </h3>

                                            <Formik
                                                initialValues={{
                                                    user_id: {
                                                        value: null,
                                                        label: "Sem Filtro",
                                                    },

                                                    patient_id: {
                                                        value: null,
                                                        label: "Sem Filtro",
                                                    },

                                                    unit_id: [
                                                        // {
                                                        //     label: Scope.unit.name?.substr(
                                                        //         0,
                                                        //         40
                                                        //     ),
                                                        //     value:
                                                        //         Scope.unit.id,
                                                        // },
                                                        {
                                                            label: "Todas",
                                                            value: null,
                                                        },
                                                    ],
                                                    start_created_at: todayOnlyDate,
                                                    end_created_at: todayOnlyDate,
                                                    start_signed_at: null,
                                                    end_signed_at: null,
                                                    start_canceled_at: null,
                                                    end_canceled_at: null,
                                                    status_vitta_card: null,
                                                    status_contract: null,
                                                    format: {
                                                        label: "Excel",
                                                        value: "excel",
                                                    },
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form
                                                        method="POST"
                                                        action="/pagamentos-recebidos/relatorio"
                                                    >
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Unidade
                                                                </Label>
                                                                <Field
                                                                    name={`unit_id`}
                                                                    placeholder="Selecione a Unidade"
                                                                    classNamePrefix="select2-selection"
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Vendedor
                                                                </Label>
                                                                <Field
                                                                    name="user_id"
                                                                    placeholder="Selecionar usuário"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.user_id
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsUser
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Paciente
                                                                </Label>
                                                                <Field
                                                                    name="patient_id"
                                                                    placeholder="Selecionar Paciente"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.patient
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                    noOptionsMessage={() =>
                                                                        "Sem Resultados. Digite para Buscar"
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data criação
                                                                    inicial{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "start_created_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            .start_created_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Data criação
                                                                    final
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_created_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            ?.end_created_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Assinatura
                                                                    inicial{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "start_signed_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            .start_signed_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Assinatura
                                                                    final
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_signed_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            ?.end_signed_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Cancelamento
                                                                    inicial{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "start_canceled_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            .start_canceled_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Cancelamento
                                                                    final
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_canceled_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            ?.end_canceled_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Status
                                                                        Cartão
                                                                        Vittá +
                                                                    </Label>
                                                                    <Field
                                                                        name="status_vitta_card"
                                                                        placeholder={
                                                                            "Selecione"
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label:
                                                                                    "Ativado",
                                                                                value:
                                                                                    VITTA_CARD_STATUS.ACTIVATED,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Desativado",
                                                                                value:
                                                                                    VITTA_CARD_STATUS.DISABLED,
                                                                            },
                                                                        ]}
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Status
                                                                        Contrato
                                                                    </Label>
                                                                    <Field
                                                                        name="status_contract"
                                                                        placeholder={
                                                                            "Selecione"
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label:
                                                                                    "Assinado",
                                                                                value:
                                                                                    VITTA_CARD_CONTRACT_STATUS.SIGNED,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Criado",
                                                                                value:
                                                                                    VITTA_CARD_CONTRACT_STATUS.CREATED,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Cancelado",
                                                                                value:
                                                                                    VITTA_CARD_CONTRACT_STATUS.CANCELED,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Aguardando assinatura",
                                                                                value:
                                                                                    VITTA_CARD_CONTRACT_STATUS.WAITING_SIGN,
                                                                            },
                                                                        ]}
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Formato
                                                                        de Saída
                                                                    </Label>
                                                                    <Field
                                                                        name="format"
                                                                        placeholder={
                                                                            "Selecione"
                                                                        }
                                                                        options={[
                                                                            // {
                                                                            //     label:
                                                                            //         "Visualização",
                                                                            //     value:
                                                                            //         "view",
                                                                            // },
                                                                            {
                                                                                label:
                                                                                    "Excel",
                                                                                value:
                                                                                    "excel",
                                                                            },
                                                                        ]}
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                        isMulti={
                                                                            false
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="3"
                                                                align="start"
                                                                className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <div
                                                                        className="spinner-border text-dark"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Loading...
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                sm="5"
                                                                align="end"
                                                                className="mt-2"
                                                            >
                                                                {controlState ? (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        disabled
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default VittaCardContract
