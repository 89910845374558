import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Container, Row, Form, Input } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import ListUnitsColumns from "./ListUnitsColumns"

//import do services de usar dps alterar para os corretos
import { search as unitServiceSearch, deleteUnit } from "services/vitta-core/unit"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

const ListUnit = () => {

    const [unitList, setUnitList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const dispatch = useDispatch()

    const inputRefSearch = useRef();

    const defaultPageControl = {
        searching: false
    };

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async (page = 1) => {

        if(pageControl.searching) return;

        let sizePerPage = 10

        let termSearch = inputRefSearch?.current?.value;

        //Set is searching
        setPageControl({ ...pageControl, ...{ searching: true }});
        //Clear Data Table
        setUnitList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        let filter = {
            term: termSearch
        }

        try {
            let data = await unitServiceSearch(filter, page, sizePerPage)

            
            setUnitList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages*sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false }});
    }

    // eslint-disable-next-line no-unused-vars

    const deleteRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            await deleteUnit(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )

            let tempData = { ...unitList }
            tempData.data = unitList.data.filter(elem => elem.id != id)
            setUnitList(tempData)
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    useEffect(() => {
        search(1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page }) => {
        if(type == "pagination")
        {
            search(page)
        }
    }

    const renderNoDataIndication = () => {
        
        if(pageControl.searching) return (
            <Row align="center" className="mt-5 mb-5">  
               <Col sm="12" className="mt-5 mb-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> 
               </Col>
            </Row>
        )
        else
        {
            return <Row className="mt-3 mb-3">  
                <Col sm="12" >
                    Não há registros que correspondam com a sua busca.
                </Col>
            </Row>
        };
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Unidades"
                        breadcrumbItem="Lista de unidades"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(unitList)}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={unitList.data || []}
                                                columns={ListUnitsColumns(
                                                    deleteRecord
                                                )}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <Form onSubmit={(e) => { e.preventDefault(); search() }}>
                                                                <div className="search-box mr-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <Input 
                                                                            type="text" 
                                                                            innerRef={inputRefSearch}
                                                                        >
                                                                        </Input>
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                    
                                                                </div>
                                                                <Button  
                                                                    type="submit" 
                                                                    color="primary" 
                                                                >
                                                                    Buscar
                                                                </Button>
                                                                </Form>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div className="text-sm-right ">
                                                                    <Link to="/unidades/adicionar">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                        >
                                                                            Adicionar
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "thead-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        noDataIndication={ renderNoDataIndication }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListUnit

//const ListUnit = React.memo(ListUnits);
//export default ListUnit;
