import request from './config/request';

const syncEntity = (entity_type, action = {}) => {
   
    let query = "?entity_type=" + entity_type;

    if(action.entity_id) query += "&entity_id=" + action.entity_id;
    if(action.operation) query += "&operation=" + action.operation;
    if(action.last_update_date) query += "&last_update_date=" + action.last_update_date;

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/erp-sync/sync-entity`+
        query,
        vtAuth: true
    })
}

export { syncEntity }
