import { useDispatch } from "react-redux"
import { refreshNfseStatus } from "services/vitta-core/professional-transfer-invoices"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useState } from "react"

const RefreshNfseStatusButton = ({ id, isVisible, onUpdateSuccess }) => {
    const [disabled, setDisabled] = useState(false)

    if (isVisible === false) return <></>

    const dispatch = useDispatch()

    const refreshStatus = async () => {
        if (disabled) return

        try {
            setDisabled(true)

            dispatch(
                showToast({
                    title: "Atualizando nota fiscal",
                    text: "Aguarde...",
                    type: "info",
                })
            )

            const newStatus = await refreshNfseStatus(id)

            dispatch(
                showToast({
                    title: "Nota fiscal atualizada com sucesso",

                    type: "success",
                })
            )

            onUpdateSuccess(newStatus)
        } catch (e) {
            let text = "Ocorreu um erro ao tentar atualizar a nota fiscal"
            if (e?.response?.data?.error) {
                text = e.response.data.error
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: text,
                    type: "error",
                    showConfirm: true,
                })
            )
        } finally {
            setDisabled(false)
        }
    }

    return (
        <button
            className="btn btn-primary"
            disabled={disabled}
            onClick={refreshStatus}
            style={{ widows: "100%" }}
        >
            Atualizar nota fiscal
        </button>
    )
}

export default RefreshNfseStatusButton
