import { convertISODate } from "helpers/dateHelper"
import { convertValueToMonetary } from "helpers/monetaryHelper"
import { Button, Card, CardBody, CardTitle, Table, DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown, UncontrolledTooltip } from "reactstrap"
import CancelContractButton from "./Buttons/CancelContractButton"
import GenChargingButton from "./Buttons/GenChargingButton"
import SendContractButton from "./Buttons/SendContractButton"
import { checkIfCardHasActiveContract, getContractStatusName } from "./Contract"
import { CONTRACT_STATUS } from "./Contract"
import { useState } from "react"

function ContractsList({
    card,
    dispatch,
    onSendContractSuccess,
    onCreateSignatureSuccess,
    redirectToAddContract,
    setShowModalCancel
}) {
    return (
        <Card className="shadow-lg">
            <CardBody>
                <CardTitle>Contratos</CardTitle>
                {card?.contract && card?.contract?.length > 0 ? (
                    <>
                        {" "}
                        <Table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Status</th>
                                    <th>Preço</th>
                                    <th>Desconto</th>
                                    <th>Total</th>
                                    <th>Forma Pgto.</th>
                                    <th>Dependentes</th>
                                    <th>Recorrência</th>
                                    <th>Assinado em</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {card.contract.map((contract, index) => {
                                    return (
                                        <tr index={contract.id}>
                                            <td>#{contract.id}</td>
                                            <td>
                                                {getContractStatusName(
                                                    contract.status
                                                )}
                                            </td>
                                            <td>
                                                {convertValueToMonetary(
                                                    contract.price
                                                )}
                                            </td>
                                            <td>
                                                {convertValueToMonetary(
                                                    contract.discount
                                                )}
                                            </td>
                                            <td>
                                                {convertValueToMonetary(
                                                    contract.value
                                                )}
                                            </td>
                                            <td>
                                                {contract.payment_method_name}
                                            </td>
                                            <td align="center">{contract.dependents_quantity}</td>
                                            <td>{contract.recurrency_name}</td>
                                            <td>
                                                {contract.signed_at
                                                    ? convertISODate(
                                                          contract.signed_at
                                                      )
                                                    : "" }
                                            </td>{" "}
                                            <td style={{ textAlign: "center", paddingTop: "3px"}}>
                                            {contract.status != CONTRACT_STATUS.CANCELED ?
                                                <UncontrolledDropdown>
                                                    <DropdownToggle 
                                                      className="card-drop"
                                                      tag="i"
                                                    >
                                                        <Button
                                                            type="button"
                                                            className="btn btn-info"
                                                            id={"tooltipitem" +index}
                                                        >
                                                            {" "}
                                                            <i className="bx bx-grid-alt fa-lg text-white" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target={"tooltipitem" + index}
                                                        >
                                                            Ações
                                                        </UncontrolledTooltip>
                                                    </DropdownToggle>
                                                    <DropdownMenu
                                                        right
                                                        className="mt-2"
                                                    >
                                                        {!contract.asaas_charging_id && contract.status == CONTRACT_STATUS.SIGNED ?
                                                            <DropdownItem
                                                                id={"it1" + index}
                                                            >
                                                                
                                                                <GenChargingButton
                                                                    onCreateSignatureSuccess={
                                                                        onCreateSignatureSuccess
                                                                    }
                                                                    contract={contract}
                                                                    dispatch={dispatch}
                                                                />
                                                                
                                                            </DropdownItem>
                                                        : null}
                                                        { [CONTRACT_STATUS.CREATED, CONTRACT_STATUS.WAITING_SIGN].includes(contract.status) && ( (contract.channel_sign_request == null) || (contract.channel_sign_request != null && "email" == contract.channel_sign_request )) ? 
                                                            <DropdownItem
                                                                id={"it2" + index}
                                                                >
                                                                    <SendContractButton
                                                                        channel="email"
                                                                        onSendContractSuccess={
                                                                            onSendContractSuccess
                                                                        }
                                                                        contract={contract}
                                                                        dispatch={dispatch}
                                                                />
                                                            </DropdownItem>
                                                        : null }
                                                        { [CONTRACT_STATUS.CREATED, CONTRACT_STATUS.WAITING_SIGN].includes(contract.status) && ( (contract.channel_sign_request == null) || (contract.channel_sign_request != null && "sms" == contract.channel_sign_request )) ? 
                                                            <DropdownItem
                                                                id={"it3" + index}
                                                                >
                                                                    <SendContractButton
                                                                        channel="sms"
                                                                        onSendContractSuccess={
                                                                            onSendContractSuccess
                                                                        }
                                                                        contract={contract}
                                                                        dispatch={dispatch}
                                                                />
                                                            </DropdownItem>
                                                         : null }
                                                        {contract.status != CONTRACT_STATUS.CANCELED ?
                                                            <DropdownItem
                                                        
                                                                id={"it4" + index}
                                                            >
                                                                
                                                                <CancelContractButton
                                                                    contract={contract}
                                                                    setShowModalCancel={setShowModalCancel}
                                                                />
                                                            </DropdownItem>
                                                         : null}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                : null}
                                             
                                              
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <span>
                            Adicione contratos para poder ativar o cartão
                        </span>
                    </div>
                )}

                <div>
                    <Button color="primary" onClick={redirectToAddContract}>
                        Adicionar
                    </Button>
                </div>
            </CardBody>
        </Card>    
    )
}

export default ContractsList
