import React, { useState, useMemo } from "react"

import debounce from "debounce-promise"
import {
    Button,
    CardBody,
    Col,
    Row,
    Input,
    Label,
    CardSubtitle,
} from "reactstrap"

//Import Breadcrumb

//import do services de usar dps alterar para os corretos
import AsyncSelect from "react-select/async"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import Select from "react-select"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { createDependent as createDependent } from "services/vitta-core/vitta-cards"
import KINSHIP_TYPES from "utils/consts/kinship-types"

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            if (field?.name == "service_id") {
                props.loadConfigService(option)
            }

            setFieldValue(field.name, option)
        }}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={async option => {
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
    />
)

const defaultValues = {
    patient_id: null,
    vitta_card_id: null,
    kinship_id: null,
}
const optionsKinship = [
    {
        label: "PAI",
        value: KINSHIP_TYPES.FATHER,
    },
    {
        label: "MÃE",
        value: KINSHIP_TYPES.MOTHER,
    },
    {
        label: "IRMÃO",
        value: KINSHIP_TYPES.BROTHER,
    },
    {
        label: "IRMÃ",
        value: KINSHIP_TYPES.SISTER,
    },
    {
        label: "FILHO",
        value: KINSHIP_TYPES.SON,
    },
    {
        label: "FILHA",
        value: KINSHIP_TYPES.DAUGHTER,
    },
    {
        label: "CÔNJUGE",
        value: KINSHIP_TYPES.SPOUSE,
    },
]
const Dependents = ({ show, data, onClose, handleDataDependent }) => {
    const [pageData, setPageData] = useState(defaultValues)

    const history = useHistory()

    const dispatch = useDispatch()

    const confirmationSubmit = async (values, type) => {
        if (type != "dependents") return
        if (!values?.vitta_card_id || values?.vitta_card_id == "") {
            dispatch(
                showSweetAlertDialog({
                    title: "Preencha o Número do Cartão!",
                    type: "warning",
                    showConfirm: true,
                    confirmBtnText: "OK!",
                })
            )
            return
        }
        if (!values?.patient_id) {
            dispatch(
                showSweetAlertDialog({
                    title: "Preencha o campo Paciente!",
                    type: "warning",
                    showConfirm: true,
                    confirmBtnText: "OK!",
                })
            )
            return
        }
        if (!values?.kinship_id) {
            dispatch(
                showSweetAlertDialog({
                    title: "Preencha o campo Parentesco!",
                    type: "warning",
                    showConfirm: true,
                    confirmBtnText: "OK!",
                })
            )
            return
        }
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma a inclusão de um novo Cartão?",
                type: "warning",
                onConfirmAction: () => {
                    handleCreateDependent(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const handleCreateDependent = async values => {
        try {
            let dataCreate = {
                patient_id: values?.patient_id?.value,
                type: values?.kinship_id?.value,
                vitta_card_id: values?.vitta_card_id?.value,
            }
            let result = await createDependent(dataCreate)

            handleDataDependent(result)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Dependente adicionado ao cartão!",
                    type: "success",
                })
            )

            clear()
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.error_code == "invalid_type_dependent") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse tipo de dependente não existe!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "record_exist") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Este dependente já está vinculado a esse cartão!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "card_exists") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Este cartão já está sendo utilizado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "exists_active_card") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Já existe um cartão ativo para o paciente atualmente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }

            if (e?.response?.data?.error_code == "card_active") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Desative o cartão para adicionar um novo dependente!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "invalid_registered_dependents") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Atenção!",
                            text:"Quantidade de dependentes cadastrados maior que a quantidade contratada.",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const clear = () => {
        let take = { ...pageData }

        take.kinship_id = ""
        take.patient_id = ""
        take.vitta_card_id = ""
        setPageData(take)
    }
    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"},
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label:
                            `#${element.id} ` +
                            element.name?.substr(0, 40) +
                            (element.cpf ? ` - CPF: ${element.cpf} ` : "") +
                            (element.mobile_phone
                                ? ` - Fone: ${element.mobile_phone} `
                                : element?.phone
                                ? ` - Fone: ${element?.phone}`
                                : ""),

                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)

    const optionsVittaCard = useMemo(() => {
        let options = []

        if (data && data?.cards?.length > 0) {
            for (let i = 0; i < data?.cards?.length; i++) {
                let use = data?.cards[i]
                if (use?.card_id && use?.id) {
                    options.push({ label: use?.card_id, value: use?.id })
                }
            }
        }
        return options
    }, [data])

    return (
        <React.Fragment>
            <Formik
                initialValues={pageData}
                enableReinitialize={true}
                onSubmit={confirmationSubmit}
            >
                {({
                    values,
                    isSubmitting,
                    dirty,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <CardBody>
                            <Row>
                                <Col sm="12" className="ml-2">
                                    <CardSubtitle>
                                        Vincule dependentes a um cartão.
                                    </CardSubtitle>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col lg="4" md="12" sm="12" className="mt-1">
                                    <Label className="ml-1">
                                        Número do Cartão
                                    </Label>
                                    <Field
                                        name="vitta_card_id"
                                        placeholder="Busque o cartão..."
                                        options={optionsVittaCard}
                                        component={ReactSelectInput}
                                    />
                                </Col>
                                <Col lg="4" md="6" sm="12" className="mt-1">
                                    <Label className="ml-1">
                                        Paciente (dependente)
                                    </Label>
                                    <FastField
                                        name="patient_id"
                                        placeholder="Selecionar Paciente"
                                        loadOptions={debounceOptionsPatient}
                                        component={AsyncSelectInput}
                                    />
                                </Col>
                                <Col lg="2" md="6" sm="12" className=" mt-1">
                                    <Label className="ml-1">Parentesco</Label>
                                    <FastField
                                        name="kinship_id"
                                        placeholder="Selecione..."
                                        options={optionsKinship}
                                        component={ReactSelectInput}
                                    />
                                </Col>
                                <Col
                                    lg="1"
                                    md="12"
                                    sm="12"
                                    className="mt-2 ml-2"
                                >
                                    <Button
                                        className="btn btn-info mt-4"
                                        onClick={() => {
                                            confirmationSubmit(
                                                values,
                                                "dependents"
                                            )
                                        }}
                                    >
                                        Adicionar{" "}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default Dependents
