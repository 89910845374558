import request from './config/request';

const search = (filter, page = 1, size = 200) => {
    let query = "?page=" + page + "&size=" + size
    if(filter.id) query += "&id=" + filter.id
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id

   
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/items-package` +
        query,
        vtAuth: true
    })
}

export { search}
