import request from "./config/request"

const closePinPadConnection = async data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_TEF_HOST + `/close-connection`
    })
}

export {
    closePinPadConnection
}
