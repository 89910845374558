import request from "./config/request"

const create = async (id, professional_data, vitta_payments_electronic_mode) => {

    const entiy = vitta_payments_electronic_mode ? "vittapag" : "zoop";

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/create/${id}?entity=${entiy}`,
        data: professional_data,
        vtAuth: true,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}
const deleteDocuments = async (id,vitta_payments_electronic_mode) => {

    const entiy = vitta_payments_electronic_mode ? "vittapag" : "zoop";

    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/delete-documents/${id}?entity=${entiy}`,
        vtAuth: true,
    })
}
const downloadProfessionalDocument = async (id, vitta_payments_electronic_mode) => {

    const entiy = vitta_payments_electronic_mode ? "vittapag" : "zoop";

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/${id}/downloadFile?entity=${entiy}`,
        vtAuth: true,
    })
}
export { create, deleteDocuments, downloadProfessionalDocument }
