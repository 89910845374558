import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import listOrderServiceColumns from "pages/OrderService/listOrderService/listOrderServiceColumns"
import { search as OrderServiceSearch } from "services/vitta-core/os"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as professionalSearch } from "services/vitta-core/professional"
import ModalPayment from "pages/SalesDocs/Payment/index"
import debounce from "debounce-promise";
const today = new Date().toLocaleDateString('pt-BR');
const todayOnlyDate = today.substr(6,4) + "-" + today.substr(3,2) + "-" + today.substr(0,2)

const listOrderService = () => {
    const [listOrderService, setLisOrderService] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const [modalPaymentData, setModalPaymentData] = useState({
        show: false,
        data: null,
    })

    const inputPatient = useRef()
    const inputStartCreatedAt = useRef()
    const inputEndCreatedAt = useRef()

    const dispatch = useDispatch()
    const history = useHistory()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let data = await OrderServiceSearch(filter, page, sizePerPage)

            setLisOrderService({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })

            console.log({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,

            });
        } catch (e) {
            console.log("error", e)
        }
    }


    useEffect(() => {
        searchSales(1)
    }, [])

    const searchSales = (page = 1) => {
        let searchObj = {}
        let patient_id = inputPatient.current.select?.state?.value?.value
        let start_created_at = inputStartCreatedAt.current.value
        let end_created_at = inputEndCreatedAt.current.value

        searchObj.expand_patient = true

        if (patient_id) searchObj.patient_id = patient_id
        if (start_created_at) searchObj.start_created_at = start_created_at
        if (end_created_at) searchObj.end_created_at = end_created_at

        search(searchObj, page)
    }

    const onTableChange = (a, b, c) => {

        if (a == "pagination") {
            searchSales(b.page)
        }
    }

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
              
         let response = await patientServiceSearch(
                    { term:inputValue, fields:"id,name,cpf"  },
                    1,
                    10
                )
                let patients_values = []

                //Vazio
                patients_values.push({
                    label: "Sem Filtro",
                    value: null,
                })

                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
const debounceOptionsPatient = debounce(loadPatientInputOptions,600)

    // const loadProfessionalInputOptions = async inputValue => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let response = await professionalSearch(
    //                 { term: inputValue },
    //                 1,
    //                 50
    //             )

    //             let patients_values = []

    //             //Vazio
    //             patients_values.push({
    //                 label: "Sem Filtro",
    //                 value: null,
    //             })

    //             response.data.forEach(element => {
    //                 patients_values.push({
    //                     label:
    //                         `${element.id} - ` +
    //                         element.name +
    //                         (element.cpf ? ` <${element.cpf}>` : ""),
    //                     value: element.id,
    //                 })
    //             })

    //             return resolve(patients_values)
    //         } catch (e) {
    //             console.error(e)
    //             return reject([])
    //         }
    //     })
    // }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Serviços"
                        breadcrumbItem="Ordens de Serviço"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            listOrderService
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                               keyField="id"
                                                data={
                                                    listOrderService.data || []
                                                }
                                                columns={listOrderServiceColumns()}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    
                                                    <React.Fragment>
                                                        <Row className="mb-4" >
                                                            <Col sm="3">
                                                              
                                                                <Label>
                                                                    Filtrar
                                                                    Paciente
                                                                </Label>
                                                                <AsyncSelect
                                                                    name="patient_id"
                                                                    placeholder="Selecione o Paciente"
                                                                    ref={
                                                                        inputPatient
                                                                    }
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                />
                                                            </Col>
                                                        
                                                            <Col sm="2">
                                                                <Label>
                                                                    Criado a
                                                                    partir de{" "}
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputStartCreatedAt
                                                                    }
                                                                    defaultValue={todayOnlyDate}
                                                                ></Input>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Label>
                                                                    Criado até
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputEndCreatedAt
                                                                    }
                                                                    defaultValue={todayOnlyDate}
                                                                ></Input>
                                                            </Col>
                                                            <Col sm="1">
                                                                <div className="">
                                                                    <Button
                                                                    color="primary"
                                                                        className="btn btn-primary mt-4"
                                                                        onClick={
                                                                            () =>  searchSales()
                                                                        }
                                                                    >
                                                                        Buscar
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "thead-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            onTableChange
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalPayment
                    show={modalPaymentData.show}
                    data={modalPaymentData.data}
                    onClose={operations => {
                        setModalPaymentData({ show: false, data: null })
                        if (
                            operations &&
                            (operations.create || operations.cancel)
                        )
                            searchSales()
                    }}
                ></ModalPayment>
            </div>
        </React.Fragment>
    )
}

export default listOrderService
