import { useState } from "react";
import request from "./config/request";
import { useDispatch } from "react-redux";
import { setCashControl } from "../../store/cash-control/actions";

/**
 * Hook customizado para manipulação de operações relacionadas ao controle de caixa.
 * Inclui funcionalidades como abrir, fechar, transferir valores entre caixas e gerenciar movimentações financeiras.
 * @returns {Object} Funções relacionadas ao controle de caixa.
 */
const useCashControl = () => {
    const dispatch = useDispatch();

    const [cacheControl, setCacheControl] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    });

    /**
     * Busca o último caixa criado pelo usuário.
     * @returns {Promise<Object>} Dados do último caixa criado.
     */
    const findLastCashControlByUserId = async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-controls-last-created`,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar o último caixa:", error);
            throw error;
        }
    };

    /**
     * Abre um novo caixa e salva os dados no Redux.
     * @param {number} openValue - Valor de abertura do caixa.
     * @param {number} unitId - ID da unidade do caixa.
     * @returns {Promise<Object>} Dados do caixa aberto.
     */
    const openCashControlService = async (openValue, unitId) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-controls`,
                data: {
                    open_value: openValue,
                    unit_id: unitId,
                },
                vtAuth: true,
            });

            const cashControlData = response;
            dispatch(setCashControl(cashControlData));
            console.log("cashControlData", cashControlData);
            return cashControlData;
        } catch (error) {
            console.error("Erro ao abrir o caixa:", error);
            throw error;
        }
    };

    /**
     * Fecha um caixa com o valor informado.
     * @param {number} id - ID do caixa a ser fechado.
     * @param {number} closeValue - Valor de fechamento do caixa.
     * @returns {Promise<Object>} Dados do fechamento do caixa.
     */
    const closeCashControlService = async (id, closeValue) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-controls/${id}/close`,
                data: {
                    close_value: closeValue,
                },
                vtAuth: true,
            });

            return response.data;
        } catch (error) {
            console.error("Erro ao fechar o caixa:", error);
            throw error;
        }
    };

    /**
     * Cria um novo movimento de caixa (cash move).
     * @param {Object} data - Dados do movimento de caixa.
     * @returns {Promise<Object>} Dados do movimento criado.
     */
    const createCashMove = async (data) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-moves`,
                data,
                vtAuth: true,
            });
            return response.data;
        } catch (error) {
            console.error("Erro ao criar o cash move:", error);
            throw error;
        }
    };

    /**
     * Busca um movimento de caixa por ID.
     * @param {number} id - ID do movimento de caixa.
     * @returns {Promise<Object>} Dados do movimento de caixa.
     */
    const findCashMoveById = async (id) => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-moves/${id}`,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar o cash move pelo ID:", error);
            throw error;
        }
    };

    /**
     * Faz o upload de um comprovante para um movimento de caixa.
     * @param {number} id - ID do movimento de caixa.
     * @param {FormData} formData - Arquivo de comprovante a ser enviado.
     * @returns {Promise<Object>} Resultado do upload.
     */
    const uploadReceipt = async (id, formData) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-moves/${id}/upload-receipt`,
                data: formData,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao enviar o comprovante:", error);
            throw error;
        }
    };

    /**
     * Faz o download de um comprovante de um movimento de caixa.
     * @param {number} id - ID do movimento de caixa.
     * @returns {Promise<string>} URL para download do comprovante.
     */
    const downloadReceipt = async (id) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-moves/${id}/download-receipt`,
                vtAuth: true,
            });

            const downloadUrl = response.downloadUrl;

            if (!downloadUrl) {
                throw new Error('URL de download não encontrada');
            }

            return downloadUrl;
        } catch (error) {
            console.error("Erro ao baixar o comprovante:", error);
            throw error;
        }
    };

    /**
     * Busca e lista registros financeiros de movimentações de caixa.
     * @param {Object} filter - Filtros de busca.
     * @param {number} page - Página atual.
     * @param {number} sizePerPage - Número de itens por página.
     * @returns {Promise<Object>} Resultado da busca.
     */
    const searchCashMoves = async (filter, page, sizePerPage) => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-moves`,
                params: {
                    ...filter,
                    page,
                    size: sizePerPage,
                },
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar registros financeiros:", error);
            throw error;
        }
    };

    /**
     * Busca contas bancárias financeiras.
     * @returns {Promise<Object>} Lista de contas financeiras.
     */
    const findFinancialAccounts = async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/financial-accounts`,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar as Contas Bancárias:", error);
            throw error;
        }
    };

    /**
     * Transfere valor entre caixas.
     * @param {number} originId - ID do caixa de origem.
     * @param {number} destinationId - ID do caixa de destino.
     * @param {number} amount - Valor a ser transferido.
     * @returns {Promise<Object>} Resultado da transferência.
     */
    const transferCashControl = async (originId, destinationId, amount) => {
        try {
            const response = await request({
                method: "POST",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-controls/${destinationId}/transfer/${originId}`,
                data: { amount },
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao realizar a transferência:", error);
            throw error;
        }
    };

    /**
     * Busca caixas abertos hoje.
     * @returns {Promise<Object>} Lista de caixas abertos no dia atual.
     */
    const findOpenCashControlsToday = async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-controls-open-today`,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar caixas abertos hoje:", error);
            throw error;
        }
    };

    /**
     * Busca todos os usuários que têm a permissão cash_control.
     * @returns {Promise<Object>} Lista de usuários com permissão de controle de caixa.
     */
    const findCashControlUsers = async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/cash-control-users`,
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar usuários com controle de caixa:", error);
            throw error;
        }
    };

    /**
     * Busca registros de controle financeiro.
     * @param {Object} filter - Filtros de busca.
     * @param {number} page - Página atual.
     * @param {number} sizePerPage - Tamanho da página.
     * @returns {Promise<Object>} Resultado da busca.
     */
    const searchFinancialControls = async (filter, page, sizePerPage) => {
        try {
            const response = await request({
                method: "GET",
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/financial-controls`,
                params: {
                    ...filter,
                    page,
                    size: sizePerPage,
                },
                vtAuth: true,
            });
            return response;
        } catch (error) {
            console.error("Erro ao buscar registros de controle financeiro:", error);
            throw error;
        }
    };

    /**
     * Exporta o relatório de controle financeiro em formato Excel.
     * @param {Object} filter - Filtros para exportação.
     * @returns {Promise<void>} Inicia o download do arquivo Excel.
     */
    const exportFinancialControlsReport = async (filter) => {
        try {
            // Construção da query params
            const params = new URLSearchParams();
      
            if (filter.unit_id) {
                params.append('unit_id', filter.unit_id);
            }
            if (filter.created_user_id) {
                params.append('created_user_id', filter.created_user_id);
            }
            if (filter.start_created_at) {
                params.append('start_created_at', filter.start_created_at);
            }
            if (filter.end_created_at) {
                params.append('end_created_at', filter.end_created_at);
            }
      
            const query = params.toString() ? `?${params.toString()}` : '';
      
            const response = await request({
                method: 'GET',
                url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/financial-controls/export-excel${query}`,
                responseType: 'arraybuffer', // baixar arquivos binários
                vtAuth: true, 
            });

            // Criação do Blob para o arquivo Excel
            const blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
      
            // Criação de uma URL temporária para o arquivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatório_Controle_Financeiro.xlsx'); // Nome do arquivo
      
            document.body.appendChild(link);
            link.click();
      
            // Limpeza
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Libera a memória associada à URL temporária
        } catch (error) {
            console.error('Erro ao exportar o relatório:', error);
            throw error;
        }
    };

    return {
        cacheControl,
        setCacheControl,
        openCashControlService,
        closeCashControlService,
        searchCashMoves,
        findCashMoveById,
        createCashMove,
        uploadReceipt, 
        downloadReceipt,
        findLastCashControlByUserId,
        findFinancialAccounts,
        transferCashControl,
        findOpenCashControlsToday,
        findCashControlUsers,
        searchFinancialControls,
        exportFinancialControlsReport,
    };
};

export default useCashControl;
