import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
import SALES_ORDER_COMISSION_TYPES from "utils/consts/sales-orders-comission-types"
const calcValue = sales_order_item_comission => {
    let value = 0
    if (sales_order_item_comission && sales_order_item_comission.length > 0) {
        for (let i = 0; i < sales_order_item_comission.length; i++) {
            let use = sales_order_item_comission[i]
            if (
                use &&
                use.comission_type == SALES_ORDER_COMISSION_TYPES.EXECUTION
            ) {
                value += (use.comission_calc_value / 100) * use?.quantity
            }
        }
    }

    return value
}
const getPrice = sales_order_item_comission => {
    let price = 0
    if (sales_order_item_comission && sales_order_item_comission.length > 0) {
        for (let i = 0; i < sales_order_item_comission.length; i++) {
            let use = sales_order_item_comission[i]
            if (
                use &&
                use.comission_type == SALES_ORDER_COMISSION_TYPES.EXECUTION
            ) {
                price = use.comission_calc_value / 100
            }
        }
    }

    return price
}
const RequestColumns = () => {
    return [
        {
            dataField: "exec_inn",
            text: "Data Prevista Execução",
            formatter: (cellContent, row) => {
                return new Date(row?.exec_in).toLocaleDateString("pt-BR")
            },
        },
        {
            dataField: "exec_inn",
            text: "Data Execução",
            formatter: (cellContent, row) => {
                if (row?.mark_exec_at) {
                    return new Date(row?.mark_exec_at).toLocaleDateString(
                        "pt-BR"
                    )
                }

                return null
            },
        },
        // {
        //     dataField: "id",
        //     text: "OS",
        //     formatter: (cellContent, row) => (
        //         <Link
        //             to={"/ordens-servico/" + cellContent}
        //             target={"_blank"}
        //             className="mr-3 text-primary"
        //         >
        //            # {cellContent}
        //         </Link>
        //     ),
        // },
        {
            dataField: "id",
            text: "Ped. Venda",
            formatter: (cellContent, row) => (
                <Link
                    to={"/pedidos/" + row?.sales_order_item?.sales_order?.id}
                    target={"_blank"}
                    className="mr-3 text-primary"
                >
                    # {row?.sales_order_item?.sales_order?.id}
                </Link>
            ),
        },
        {
            dataField: "patientname",
            text: "Paciente",
            formatter: (cellContent, row) => {
                return <span>{row?.sales_order_item?.sales_order?.patient?.name}</span>
            },
        },
        {
            dataField: "profexec",
            text: "Profissional Execução",
            formatter: (cellContent, row) => {
                return (
                    <span>
                    {row?.exec_professional?.name
                        ? row?.exec_professional?.name
                        : null}
                </span>
                    // <span>
                    //     {row?.exec_professional?.name
                    //         ? row?.exec_professional?.name
                    //         : row?.user_mark_exec_data?.name ?? null}
                    // </span>
                )
            },
        },
        {
            isDummyField: true,
            text: "Item",
            formatter: (id, row) => {
                return <span>{row?.sales_order_item?.item?.name}</span>
            },
        },
        {
            isDummyField: true,
            text: "Fase",
            formatter: (id, row) => {
                return <span>{row?.procedure_stage?.name ?? "Sem fase"}</span>
            },
        },
        {
            dataField: "user_mark_exec",
            text: "Usuário Executor",
            formatter: (cellContent, row) => {
                return (
                    <strong>
                    {row?.user_mark_exec_data?.name
                        ? row?.user_mark_exec_data?.name
                        : ""}
                </strong>
                    // <span>
                    //     {row?.exec_professional?.name
                    //         ? row?.exec_professional?.name
                    //         : row?.user_mark_exec_data?.name ?? null}
                    // </span>
                )
            },
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cellContent, row) => (
                <Badge
                    className={
                        row.mark_exec_at
                            ? "font-size-12 badge-soft-success"
                            : "font-size-12 badge-soft-warning"
                    }
                >
                    {row.mark_exec_at ? "Executado" : "Aguardando execução"}
                </Badge>
            ),
        },
     
    ]
}

export default RequestColumns
