import React, { useState, useEffect } from "react"
import { Container, Row, Col, CardBody, Card, Table, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Link } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"

const SalesOrderAnalytical = ({ filter, data, state }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })

    useEffect(() => {
        const loadData = async () => {
            try {
                let totalItens = 0
                for (let i = 0; i < data?.length; i++) {
                    totalItens = totalItens + 1
                }
                // console.log("data",data)
                setPageData({
                    header: {
                        unit: filter?.unit_id,
                        status: filter?.status,
                        patient: filter?.patient_id,
                        start_created_at: filter.start_created_at,
                        end_created_at: filter.end_created_at,
                        professional: filter?.professional_id,
                        user: filter?.user_id,
                        totalItens: totalItens,
                    },
                    data: data,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (data) {
            loadData()
        } else history.push("/relatorios/agendamentos-analitico")
    }, [data])

    const printReport = () => {
        window.print()
    }

    // const returnIndex = () => {
    //     document.location.reload(true)
    // } metodo antigo

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Agendamento - Analítico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            <p>
                                                Data Inicial:{" "}
                                                {pageData.header
                                                    ?.start_created_at
                                                    ? new Date(
                                                          pageData.header
                                                              ?.start_created_at +
                                                              " 00:00:00"
                                                      ).toLocaleDateString(
                                                          "pt-BR"
                                                      )
                                                    : null}{" "}
                                                {" - "}
                                                Data Final:{" "}
                                                {pageData.header?.end_created_at
                                                    ? new Date(
                                                          pageData.header
                                                              ?.end_created_at +
                                                              " 00:00:00"
                                                      ).toLocaleDateString(
                                                          "pt-BR"
                                                      )
                                                    : null}
                                                <br />
                                                Unidades:{" "}
                                                {pageData.header?.unit
                                                    ? pageData.header?.unit.map(
                                                          (row, index) =>
                                                              row.label +
                                                              (index ==
                                                              pageData.header
                                                                  ?.unit
                                                                  .length -
                                                                  1
                                                                  ? ""
                                                                  : ", ")
                                                      )
                                                    : ""}{" "}
                                                <br />
                                                Status:{" "}
                                                {pageData.header?.status
                                                    ? pageData.header?.status.map(
                                                          (row, index) =>
                                                              row.label +
                                                              (index ==
                                                              pageData.header
                                                                  ?.status
                                                                  .length -
                                                                  1
                                                                  ? ""
                                                                  : ", ")
                                                      )
                                                    : ""}{" "}
                                                <br />
                                                Paciente:
                                                {pageData.header?.patient?.label
                                                    ? pageData.header?.patient
                                                          ?.label
                                                    : " Sem filtro"}
                                                <br />
                                                Profissional:{" "}
                                                {pageData.header?.professional
                                                    ?.label
                                                    ? pageData.header
                                                          ?.professional?.label
                                                    : " Sem filtro"}
                                                <br />
                                                Usuário:
                                                {pageData.header?.user?.label
                                                    ? pageData.header?.user
                                                          ?.label
                                                    : " Sem filtro"}
                                            </p>
                                        </Col>
                                        <Col sm="6">
                                            <div align="end">
                                                <div className="d-none d-print-block">
                                                    <img
                                                        src={logovitta}
                                                        alt=""
                                                        height="95px"
                                                        className="mb-3"
                                                    />
                                                </div>
                                                <Link
                                                    to="#"
                                                    className="btn btn-dark d-print-none mr-2"
                                                    onClick={()=>state(false)}
                                                >  
                                                     <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                                 </Link>
                                                {/* <Button
                                                    className="btn btn-dark d-print-none mr-2"
                                                    onClick={() =>
                                                        returnIndex()
                                                    }
                                                >
                                                    <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                                </Button> */}

                                                <Link
                                                    to="/relatorios/agendamentos-analitico"
                                                    className="btn btn-primary d-print-none"
                                                    onClick={printReport}
                                                >
                                                    Imprimir
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>

                                    <h4 align="center">
                                        Agendamentos - Analítico
                                    </h4>
                                    <br />

                                    <div className="table-responsive">
                                        <Table className="table">
                                            <thead className="table-light">
                                                <tr align="center">
                                                    <th scope="col">ID</th>
                                                    <th scope="col">
                                                        Data Marcação
                                                    </th>
                                                    <th scope="col">
                                                        Data Execução
                                                    </th>

                                                    <th scope="col">Unidade</th>
                                                    <th scope="col">Agenda</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">
                                                        Paciente
                                                    </th>

                                                    <th scope="col">
                                                        Profissional
                                                    </th>
                                                    <th scope="col">Serviço</th>
                                                    <th scope="col">Usuário</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageData.data?.map(
                                                    (row, index) => (
                                                        <tr
                                                            align="center"
                                                            key={index}
                                                        >
                                                            <td>
                                                                {
                                                                    row.appointment_id
                                                                }
                                                            </td>
                                                            <td>
                                                                {new Date(
                                                                    row.created_at
                                                                ).toLocaleDateString(
                                                                    "pt-BR",
                                                                    {
                                                                        hour:
                                                                            "2-digit",
                                                                        hour12: false,
                                                                        minute:
                                                                            "2-digit",
                                                                        second:
                                                                            "2-digit",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                {new Date(
                                                                    row.start_exec
                                                                ).toLocaleDateString(
                                                                    "pt-BR",
                                                                    {
                                                                        hour:
                                                                            "2-digit",
                                                                        hour12: false,
                                                                        minute:
                                                                            "2-digit",
                                                                        second:
                                                                            "2-digit",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {row?.name_unit}
                                                            </td>
                                                            <td>
                                                                {
                                                                    row?.name_app_book
                                                                }
                                                            </td>
                                                            <td>
                                                                {pageData?.header?.status?.map(
                                                                    (
                                                                        rowMap,
                                                                        index
                                                                    ) =>
                                                                        rowMap.value ==
                                                                        row.status
                                                                            ? rowMap.label
                                                                            : ""
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    row?.name_patient
                                                                }
                                                            </td>

                                                            <td>
                                                                {
                                                                    row?.name_professional
                                                                }
                                                            </td>
                                                            <td>
                                                                {row?.name_item}
                                                            </td>
                                                            <td>
                                                                {row?.name_user}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                        <br />
                                        <br />
                                        <div
                                            classname=""
                                            style={{ textAlign: "left" }}
                                        >
                                            Total Agendamentos :{" "}
                                            {pageData?.header?.totalItens}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SalesOrderAnalytical
