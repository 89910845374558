import request from "./config/request"

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.start_created_at)
        query += "&start_created_at=" + filter.start_created_at
    if (filter.end_created_at)
        query += "&end_created_at=" + filter.end_created_at
    if (filter.created_at) query += "&created_at=" + filter.created_at
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.status) query += "&status=" + filter.status
    if (filter.doc_type) query += "&doc_type=" + filter.doc_type
    if (filter.expand_patient)
        query += "&expand_patient=" + filter.expand_patient
    if (filter.expand_user) query += "&expand_user=" + filter.expand_user
    if (filter.expand_items) query += "&expand_items=" + filter.expand_items
    if(filter.issue_invoices) query += `&issue_invoices=${filter?.issue_invoices}`
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders` +
            query,
        vtAuth: true,
    })
}

const create = async data => {
    // console.log("create", data);
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders`,
        data: data,
        vtAuth: true,
    })
}

const update = async (id, data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}`,
        data: data,
        vtAuth: true,
    })
}

const createFromAppointment = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/from-appointment`,
        data: { ...data },
        vtAuth: true,
    })
}

const genInvoice = async id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}/invoice`,
        data: {},
        vtAuth: true,
    })
}

const genInvoicesOrders = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/gen-invoices`,
        data: data,
        vtAuth: true,
    })
}

const cancelSalesDoc = async id => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}`,
        vtAuth: true,
    })
}

const loadDataAddform = async () => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/add`,
        vtAuth: true,
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}/edit`,
        vtAuth: true,
    })
}

const getById = async (id, options = {}) => {
    let query = ""

    query += query.length == 0 ? "?" : "&"
    if (options.expand_unit) query += "expand_unit=true"

    query += query.length == 0 ? "?" : "&"
    if (options.expand_revenue_split) query += "expand_revenue_split=true"

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}` +
            query,
        vtAuth: true,
    })
}

const salesAnalysisReport = filter => {
    let query = "?start_created_at=" + filter.start_created_at
    query += "&end_created_at=" + filter.end_created_at
    query += "&report_source=" + filter.report_source
    query += "&unit_id=" + filter.unit_id.join(",")

    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/sales-analysis-report` +
            query,
        vtAuth: true,
    })
}

const salesOrderAnalyticalReport = filter => {
    let query = "?start_created_at=" + filter.start_created_at
    query += "&end_created_at=" + filter.end_created_at
    query += "&status=" + filter.status.join(",")
    query += "&unit_id=" + filter.unit_id.join(",")

    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.user_id) query += "&user_id=" + filter.user_id
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/analytical-report` +
            query,
        vtAuth: true,
    })
}

const salesOrderAnalyticalReportExport = filter => {
    let query = "?start_created_at=" + filter.start_created_at
    query += "&end_created_at=" + filter.end_created_at
    query += "&status=" + filter.status.join(",")
    query += "&unit_id=" + filter.unit_id.join(",")

    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.user_id) query += "&user_id=" + filter.user_id
    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/analytical-report-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}
const execItem = async (id, serviceData) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}/exec-item`,
        data: serviceData,
        vtAuth: true,
    })
}

const cancelExecItem = async (id, serviceData) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/${id}/cancel-exec-item`,
        data: serviceData,
        vtAuth: true,
    })
}
const changeExecItem = async (id, data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-order-items/${id}/exec-item/change`,
        data: data,
        vtAuth: true,
    })
}

const getPendentServicesStages = async sales_order_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/services-stages/pendents/${sales_order_id}`,
        vtAuth: true,
    })
}
const getAppointments = async sales_order_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/get-appointments/${sales_order_id}`,
        vtAuth: true,
    })
}

const getPaidServices = async patient_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-orders/get-paid-services/${patient_id}`,
        vtAuth: true,
    })
}
export {
    search,
    create,
    update,
    cancelSalesDoc,
    loadDataAddform,
    loadDataEditform,
    getById,
    createFromAppointment,
    genInvoice,
    genInvoicesOrders,
    salesAnalysisReport,
    salesOrderAnalyticalReport,
    salesOrderAnalyticalReportExport,
    execItem,
    cancelExecItem,
    getPendentServicesStages,
    getAppointments,
    getPaidServices,
    changeExecItem
}
