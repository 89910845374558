import request from './config/request';
const create = async data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-item-exec`,
        data: data,
        vtAuth: true
    })
}

const remove = async id => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/remove-sales-order-item-exec/${id}`,
        vtAuth: true
    })
}

const findExec = async id => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/find-service-exec/${id}`,
        vtAuth: true
    })
}

const showOdontoExecExport = filter => {
  
    let query = "?unit_id=" + filter.unit_id

    if (filter?.professional_id)
        query += "&professional_id=" + filter.professional_id

    if (filter?.start_date_exec && filter?.end_date_exec) {
        query += "&start_date_exec=" + filter.start_date_exec + "&end_date_exec=" + filter.end_date_exec
    }

    if (filter?.start_sales_order && filter?.end_sales_order) {
        query += "&start_sales_order=" + filter.start_sales_order + "&end_sales_order=" + filter.end_sales_order
    }

   


    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-order-item-exec/report/export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

export { create, remove, findExec, showOdontoExecExport }

