import request from "./config/request"

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.status) query += "&status=" + filter.status
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/specialties` +
            query,
        vtAuth: true,
    })
}

export { search }
