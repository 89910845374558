import request from "./config/request"

const createInvoicesPayments = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-payments`,
        data: data,
        vtAuth: true,
    })
}

const cancelPayment = async id => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-payments/${id}`,
        vtAuth: true,
    })
}

const paymentsReport = (filter, page = 1, size = 100) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.sales_order_id)
        query += "&sales_order_id=" + filter.sales_order_id
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.created_user_id)
        query += "&created_user_id=" + filter.created_user_id
    if (filter.start_created_at)
        query += "&start_created_at=" + filter.start_created_at
    if (filter.end_created_at)
        query += "&end_created_at=" + filter.end_created_at

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-order-payments/payments-report` +
            query,
        vtAuth: true,
    })
}

const getInvoiceNfseLogs = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${id}/taxdocs/logs`,
        vtAuth: true,
    })
}

export {
    createInvoicesPayments,
    cancelPayment,
    paymentsReport,
    getInvoiceNfseLogs,
}
