import request from "./config/request"

const showPixQrCode = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_TEF_HOST + `/show-qrcode`,
        data: data,
        timeout: 3000,
    })
}

export {
    showPixQrCode
}
