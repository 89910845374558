import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"
import SALES_QUOTE_STATUS from "../../../utils/consts/sales-quotes-status"

const OrderandQuoteColumns = (deleteRecord) => [
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "patient.name",
        text: "Paciente",
     
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) => (
            row.created_at ? new Date(row.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"}) : null
        ),
    },
    {
        dataField: "created_user.name",
        text: "Usuário",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent,row) => 
        {
            let text = "";
            let badgeClass = "badge-soft-success";
            switch(cellContent)
            {
                case SALES_QUOTE_STATUS.AVAILABLE:
                    text = "Disponível"
                    badgeClass = "badge-soft-success";
                    break;
                case SALES_QUOTE_STATUS.EXPIRED:
                    text = "Expirado"
                    badgeClass = "badge-soft-danger";
                    break;
                case SALES_QUOTE_STATUS.CONVERTED_INTO_ORDER:
                    text = "Convertido em Pedido"
                    badgeClass = "badge-soft-primary";
                    break;
                    case SALES_QUOTE_STATUS.IN_NEGOTIATION:
                        text = "Em Negociação"
                        badgeClass = "badge-soft-info";
                        break;
            }

           return (
                <Badge
                    className={"font-size-12 "+badgeClass}
                    pill
                >
                {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id,row) => (
            <>
                <Link  className="mr-3 text-primary" 
                 to={ {
                    pathname:"/orcamentos/"+row.id,
                    state:{previousPath:location?.pathname + location.search}
                }}>
                    <i className="fas fa-edit font-size-22" id={"openordertooltip"+row.id}></i>
                    <UncontrolledTooltip placement="top" target={"openordertooltip"+row.id}>
                        Abrir
                    </UncontrolledTooltip>
                </Link>
                { row.status != SALES_QUOTE_STATUS.CONVERTED_INTO_ORDER ?
                <Link to="#" className="text-danger mr-3" onClick={() => {deleteRecord(row.id)}}>
                    <i className="fas fa-trash-alt font-size-22" id={"deleteordertooltip"+row.id}></i>
                    <UncontrolledTooltip placement="top" target={"deleteordertooltip"+row.id}>
                        Excluir
                    </UncontrolledTooltip>
                </Link>
                : null }
            </>
        ),
    },
]

export default OrderandQuoteColumns
