import React from "react"
import { Modal } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import IndexModalPrescriptions from "./index"
import MEDICAL_FORMS_TYPES from "utils/consts/medical-form-types"

const ModalPrescriptions = ({ show, entity_type, data,  onClose, handleModel }) => {
    if(!show) return null
    return (
        <Modal isOpen={show} centered={false} size="xl">
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Modelos {entity_type &&
                                    entity_type ==
                                        MEDICAL_FORMS_TYPES.PRESCRIÇÃO
                                        ? "de Prescrições "
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.ATESTADO
                                        ? "de Atestados "
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.LAUDO
                                        ? "de Laudos "
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.ENCAMINHAMENTO
                                        ? "de Encaminhamentos "
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.CONSENTIMENTO
                                        ? "de Exames, Procedimentos e Cirurgias"
                                        : ""}
                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                    </h5>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <IndexModalPrescriptions
                entity_type={entity_type}
                onClose={onClose}
                handleModel={handleModel}
                values = {data}
              
            />
            <div className="card-body" align="right">
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="btn btn-dark waves-effect"
                    data-dismiss="modal"
                >
                    <span className="text-white"> Fechar</span>
                </button>
            </div>
        </Modal>
    )
}

export default ModalPrescriptions
