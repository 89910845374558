
import request from './config/request';

const uploadCertificate = async (id,dataCertificate) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/upload-certificate/${id}`,
        data:dataCertificate,
        vtAuth: true,
        headers:{
            'Content-Type':'multipart/form-data',
        }
    })
}

export {uploadCertificate}
