import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip, Row, Col } from "reactstrap"

const ListLogsColumns = (renderLogs, RenderModel, deleteRecord) => [
    {
        dataField: "ID",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    {
        dataField: "created_at",
        text: "Registrado em",
        formatter: (cellContent, row) => (
            <div>
                {row?.created_at?.substr(8, 2) +
                    "/" +
                    row?.created_at?.substr(5, 2) +
                    "/" +
                    row?.created_at?.substr(0, 4) +
                    " " +
                    row?.created_at?.substr(11, 8)}
            </div>
        ),
    },
    {
        dataField: "user.name",
        text: "Usuário",
        formatter: (cellContent, row) => <div>{row?.user?.name}</div>,
    },

    {
        dataField: "action",
        text: "Ação LOG",
        formatter: (cellContent, row) => <div>{row?.action}</div>,
    },
    {
        dataField: "log_version",
        text: "Versão LOG",
        formatter: (cellContent, row) => <div>{row?.log_version}</div>,
    },
    // {
    //     dataField: "Text",
    //     text: "Texto",
    //     formatter: (cellContent, row) => (
    //         <div className="text-body font-weight-bold">
    //             {row?.text && row?.text?.length > 50
    //                 ? new DOMParser()
    //                       .parseFromString(row?.text, "text/html")
    //                       .body.textContent.substr(0, 50) + " ... "
    //                 : row?.text
    //                 ? new DOMParser().parseFromString(row?.text, "text/html")
    //                       .body.textContent
    //                 : ""}
    //         </div>
    //     ),
    // },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Row>
                    <Col></Col>
                    <Col sm="2">
                        <Link
                            to="#"
                            className="mr-3 text-dark"
                            onClick={() => {
                                renderLogs(row)
                            }}
                        >
                            <i
                                className="far fa-file-alt font-size-23 mr-4 fa-2x"
                                id={"edittooltip" + row?.id}
                            ></i>
                            <UncontrolledTooltip
                                placement="top"
                                target={"edittooltip" + row?.id}
                            >
                                Mais detalhes
                            </UncontrolledTooltip>
                        </Link>
                    </Col>
                    <Col></Col>
                </Row>
            </>
        ),
    },
]

export default ListLogsColumns