import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Button, CardTitle, Card } from "reactstrap"
import { Link } from "react-router-dom"
import "../LogsModalSecondary/modalSecondary.css"

import { searchLogs as searchLogs } from "services/vitta-core/audit-logs"
const ServicesGeneral = ({ data }) => {
    const [controlStateLink, setControlStateLink] = useState({
        show: false,
        data: null,
    })

    useEffect(() => {
        if (data) {
            setControlStateLink({
                data: JSON?.parse(data?.diff_data)?.child_data,
            })
        }
    }, [data])
    const handleToggle = i => {
        let ul1 = document.getElementById("ul1" + i)

        if (ul1) ul1.classList.toggle("active")
    }
    const handleToggleToo = i => {
        let ul2 = document.getElementById("ul2" + i)

        if (ul2) ul2.classList.toggle("active")
    }

    return (
        <React.Fragment>
            {controlStateLink?.data && controlStateLink?.data?.length > 0 ? (
                <Col sm="12" className="mt-3">
                    {controlStateLink?.data?.map((branch, index) =>
                        branch?.action == "updated"
                            ? branch?.data?.map((branchToo, indexToo) => (
                                  <Col>
                                      <CardTitle className="">
                                          Atualização na entidade{" "}
                                          {branch?.entity} - #{branchToo?.id}
                                      </CardTitle>
                                      <table className="table">
                                          <thead className="thead-light">
                                              <tr align="start">
                                                  <th scope="col">
                                                      Nome campo
                                                  </th>
                                                  <th scope="col">
                                                      Antigo valor
                                                  </th>
                                                  <th scope="col">
                                                      Novo valor
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tr key={indexToo}>
                                              <th>
                                                  {branchToo?.field
                                                      ? branchToo?.field
                                                      : ""}
                                              </th>
                                              <th style={{ color: "red" }}>
                                                  {branchToo.field &&
                                                  branchToo.old_value
                                                      ? branchToo.old_value
                                                      : null}
                                              </th>
                                              <th style={{ color: "green" }}>
                                                  {branchToo.field &&
                                                  branchToo.new_value
                                                      ? branchToo.new_value
                                                      : null}
                                              </th>
                                          </tr>
                                      </table>
                                  </Col>
                              ))
                            : ""
                    )}
                </Col>
            ) : (
                ""
            )}

            {controlStateLink?.data && controlStateLink?.data?.length > 0 ? (
                <Col sm="12" className="mt-2">
                    <ul id="myUL">
                        {controlStateLink?.data
                            ? controlStateLink?.data?.map((branch, index) =>
                                  branch?.action == "created"
                                      ? branch?.data?.map(
                                            (branchToo, indexToo) => (
                                                <li
                                                    key={indexToo}
                                                    className="caretprimary"
                                                    id={"li" + indexToo}
                                                    onClick={() =>
                                                        handleToggle(indexToo)
                                                    }
                                                    style={{ fontSize: 15 }}
                                                >
                                                    <b>Inclusão</b> de entidade{" "}
                                                    {branch?.entity} - #
                                                    {branchToo?.id}
                                                    <ul
                                                        className="nested"
                                                        id={"ul1" + indexToo}
                                                    >
                                                        {Object.keys(
                                                            branchToo
                                                        ).map(function (
                                                            key,
                                                            value
                                                        ) {
                                                            return (
                                                                <li
                                                                    className="caret"
                                                                    style={{
                                                                        color:
                                                                            "green",
                                                                    }}
                                                                >
                                                                    {key +
                                                                        " : " +
                                                                        branchToo[
                                                                            key
                                                                        ]}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                            )
                                        )
                                      : ""
                              )
                            : ""}
                    </ul>
                </Col>
            ) : null}

            {controlStateLink?.data && controlStateLink?.data?.length > 0 ? (
                <Col sm="12" className="mt-2">
                    <ul id="myUL">
                        {controlStateLink?.data
                            ? controlStateLink?.data?.map((branch, index) =>
                                  branch?.action == "deleted"
                                      ? branch?.data?.map(
                                            (branchToo, indexToo) => (
                                                <li
                                                    key={indexToo}
                                                    className="caretprimary"
                                                    id={"li2" + indexToo}
                                                    onClick={() =>
                                                        handleToggleToo(
                                                            indexToo
                                                        )
                                                    }
                                                    style={{ fontSize: 15 }}
                                                >
                                                    <b>Exclusão</b> de entidade{" "}
                                                    {branch?.entity} - #
                                                    {branchToo?.id}
                                                    <ul
                                                        className="nested"
                                                        id={"ul2" + indexToo}
                                                    >
                                                        {Object.keys(
                                                            branchToo
                                                        ).map(function (
                                                            key,
                                                            value
                                                        ) {
                                                            return (
                                                                <li
                                                                    className="caret"
                                                                    style={{
                                                                        color:
                                                                            "red",
                                                                    }}
                                                                >
                                                                    {key +
                                                                        " : " +
                                                                        branchToo[
                                                                            key
                                                                        ]}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                            )
                                        )
                                      : ""
                              )
                            : ""}
                    </ul>
                </Col>
            ) : null}
            {!controlStateLink?.data || controlStateLink?.data?.length <= 0 ? (
                <span className="ml-2 mt-3">&nbsp;Não há registros...</span>
            ) : (
                ""
            )}
        </React.Fragment>
    )
}

export default ServicesGeneral
