/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

//show/hide message
export const SHOW_SWEET_ALERT_DIALOG = "SHOW_SWEET_ALERT_DIALOG"
export const HIDE_SWEET_ALERT_DIALOG = "HIDE_SWEET_ALERT_DIALOG"

// show toast
export const SHOW_TOAST = "SHOW_TOAST"
export const HIDE_TOAST = "HIDE_TOAST"

export const TOGGLE_SELECT_UNIT_SCOPE_MODAL = "TOGGLE_SELECT_UNIT_SCOPE_MODAL"