import {
  USER_LOGIN, 
  USER_LOGIN_SUCCESS, 
  USER_LOGIN_FAILURE, 
  USER_LOGOUT_SUCCESS,
} from "./actionTypes"

const initialState = {
  access: null,
  login: {
    loading: false,
    error: null
  }
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        login: {loading: true, error: null},
      }
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        access: action.payload,
        login: {loading: false, error: null},
      }
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        login: {loading: false, error: action.payload},
      }
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        access: null,
      }
  }
  return state
}

export default auth
