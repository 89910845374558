import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import { searchReportProfessional } from "services/vitta-core/professional"
import Breadcrumbs from "components/Common/Breadcrumb"
import Select from "react-select"
import { Formik, Field, Form } from "formik"

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)
const optionsStatus = [
    { value: "1", label: "Ativo" },
    { value: "0", label: "Inativo" },
]

const AllProfessional = () => {
    const [controlState, setControlState] = useState(false)

    const onSubmit =  async (data_form) => {
        let auxData ={}
        auxData.status={
            value: data_form.status_id?.value,
            label: data_form.status_id?.label
        }
        setControlState(true)
        let response = await searchReportProfessional({
            status: auxData.status.value
        })
        var headers = response.headers
        var blob = new Blob([response.data], {
            type: headers["content-type"],
        })
        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = "Profissionais"
        link.click()
        setControlState(false)
        return


    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Todos Profissionais/Relatórios" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Relatório dos Profissionais
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    status_id: optionsStatus[0]
                                                }}
                                                onSubmit={onSubmit}>
                                                <Form>
                                                    <Row className="d-flex justify-content-center mt-2">
                                                        <Col sm="8">
                                                            <Label>
                                                                Status
                                                            </Label>
                                                            <Field
                                                                name="status_id"
                                                                placeholder="Selecione o Status"

                                                                options={
                                                                    optionsStatus
                                                                }
                                                                component={
                                                                    ReactSelectInput
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="d-flex justify-content-center">
                                                        <Col
                                                        sm="2"
                                                        align="start"
                                                        className="mt-4"
                                                        >
                                                            {controlState ? (
                                                                <div
                                                                    className="spinner-border text-dark"
                                                                    role="status"
                                                                >
                                                                    <span className="sr-only">
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                        </Col>

                                                        <Col
                                                            sm="6"
                                                            align="end"
                                                            className="mt-4"
                                                        >
                                                            <Button
                                                                type="submit"
                                                                className="btn btn-dark"
                                                                disabled={controlState}
                                                            >
                                                                Gerar
                                                                Relatório
                                                                <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Formik>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )

}

export default AllProfessional