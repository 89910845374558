import React, { useMemo, useState,useRef, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Label,
    Button,
    Card,
    Input,
    FormGroup,
   
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from 'react-redux';
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async";
import Select from "react-select"
import { search as userServiceSearch } from "services/vitta-core/user"
import { Formik, Field, Form } from 'formik';
import SalesQuoteAnalysisReport from "pages/Reports/SalesQuoteAnalytical/report"
import { salesQuoteAnalyticalReport, salesQuoteAnalyticalReportExport } from "services/vitta-core/sales-quote"
import debounce from "debounce-promise";
import SALES_QUOTES_STATUS from "utils/consts/sales-quotes-status"

const today = new Date().toLocaleDateString('pt-BR');
const todayOnlyDate = today.substr(6,4) + "-" + today.substr(3,2) + "-" + today.substr(0,2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select {...field} {...props} onChange={option => setFieldValue(field.name, option)}/>
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect {...field} {...props} onChange={option => {setFieldValue(field.name, option);}}/>
)

const SalesOrderAnalyticalIndex = () => {

    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false
    });
    const [controlState, setControlState] = useState(false)
    const [firstPage, setFirstPage] = useState(true)
    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.auth.access?.user);
    const Scope = useSelector(state => state.scope)

    useEffect(() => {
        if(!firstPage){
            setReportControl({
                show: false,
                filter: null,
                data: null
            });
	        setFirstPage(true)    
        }
      }, [firstPage]);
   
    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []
  
        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const salesQuoteStatusOptions = useMemo(() => {
        let options_list = [
            { label: "Disponível", value: SALES_QUOTES_STATUS.AVAILABLE },
            { label: "Expirado", value: SALES_QUOTES_STATUS.EXPIRED },
            { label: "Convertido em pedido", value: SALES_QUOTES_STATUS.CONVERTED_INTO_ORDER }
    
        ]
        return options_list

    }, [SALES_QUOTES_STATUS])

    const loadPatientsInputOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try
            {
                let response = await PatientServiceSearch({term: inputValue,fields:"id,name"}, 1, 25);

                let users_values = []
                users_values.push({ label:"Todos", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values);
            }
            catch(e)
            {
                console.error(e);
                return reject([]);
            }
        });
    }
    const debounceOptionsPatient = debounce(loadPatientsInputOptions,600)
    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)
    const onSubmit = async (data_form, actions) => {
   
        try
        {
            if(!data_form.unit_id || data_form.unit_id.length == 0) return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Informe pelo menos uma Unidade Clínica.",
                    type: "error",
                    showConfirm: true,
                })
            )

            if(!data_form.status || data_form.status.length == 0) return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Informe pelo menos um status.",
                    type: "error",
                    showConfirm: true,
                })
            )

            let auxData = { }
            auxData.patient_id = data_form.patient_id?.value ? data_form.patient_id?.value : null;
            auxData.start_created_at = data_form.start_created_at;
            auxData.end_created_at = data_form.end_created_at;
            auxData.user_id = data_form.user_id?.value
            ? data_form.user_id?.value
            : null

            let unit_ids = [];
            for(let i = 0; i < data_form.unit_id.length; i++)
            {
                unit_ids.push(data_form.unit_id[i].value);
            }

            let status = [];
            for(let i = 0; i < data_form.status.length; i++)
            {
                status.push(data_form.status[i].value);
            }

            if(data_form.format?.value == "excel")
            {
                setControlState(true)
                let response = await salesQuoteAnalyticalReportExport(
                    {
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        status: status,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        user_id: auxData.user_id,
                        
                    }
                )

                var headers = response.headers;
                var blob = new Blob([response.data],{type:headers['content-type']});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Orçamentos - Analitico";
                link.click();
                setControlState(false)
            }
            else
            {
                setControlState(true)
                let dataReport = await salesQuoteAnalyticalReport(
                    {
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        status: status,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        user_id: auxData.user_id,
                    }
                )
                 
                if(!dataReport)
                {
                    return ;
                }
        
                setReportControl({
                    show: true,
                    filter: data_form,
                    data: dataReport
                });
                setControlState(false)
            }
               
        }
        catch (e){
            setControlState(false)
            console.log(e);

            let textError = "Ocorreu algum problema, Preencha os campos corretamente e tente novamente."
            
            if(e?.response?.status == 403){
                textError = "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            }

            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "date_period_limit_exceeded":
                        textError = "O Período máximo de filtro entre datas é de 60 dias."
                        break
                }
            }

            
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    if(reportControl.show)
    {
       return <SalesQuoteAnalysisReport filter={reportControl.filter} data={reportControl.data} state={setFirstPage}/>
    }else{
        return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Orçamentos - Analítico"
                    />
                    <Row  >
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                            <Card  >
                                <CardBody>
                                    <h3 align="center" className="mb-4 mt-4">Orçamentos - Analítico</h3>
                                    <Formik
                                        initialValues={{ patient_id: null, unit_id: Scope.unit ? [{
                                            label: Scope.unit.name?.substr(0,40), value: Scope.unit.id
                                          }] : null, start_created_at:todayOnlyDate, end_created_at:todayOnlyDate, format: {
                                            label: "Visualização", value: "view"
                                        }}}
                                        onSubmit={onSubmit}
                                    >
                                        {(formProps) => (
                                        <Form>
                                            <Row  className="d-flex justify-content-center">
                                            <Col sm="8">
                                                <FormGroup>
                                                <Label>Status</Label>
                                                <Field
                                                    name="status"
                                                    placeholder={"Selecione"}
                                                    options={salesQuoteStatusOptions}
                                                    component={ReactSelectInput}
                                                    isMulti={true}
                                                /> 
                                                </FormGroup>
                                     
                                            </Col>
                                            </Row>
                                        <Row  className="d-flex justify-content-center">
                                            <Col sm="8">
                                            <FormGroup>
                                                                <Label>
                                                                    Usuário
                                                                </Label>
                                                                <Field
                                                                    name="user_id"
                                                                    placeholder="Selecionar Usuário"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.user_id
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsUser
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                    noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
                                                                />
                                                                  </FormGroup>
                                            </Col>
                                            <Col sm="8">
                                            <FormGroup>
                                            <Label>
                                                   Paciente
                                                </Label>
                                                <Field
                                                    name="patient_id"
                                                    placeholder="Selecionar Paciente"
                                                    cacheOptions
                                                    defaultOptions 
                                                    isClearable
                                                    defaultValue={formProps.patient}
                                                    loadOptions={debounceOptionsPatient}
                                                    component={AsyncSelectInput}
                                                /> 
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row className="d-flex justify-content-center">
                                            <Col sm="8">
                                            <FormGroup>
                                                <Label>Unidade</Label>
                                                <Field
                                                    name={`unit_id`}
                                                    placeholder="Selecione a Unidade"   
                                                    classNamePrefix="select2-selection"
                                                    options={unitsOptions}
                                                    isMulti={true}
                                                    component={ReactSelectInput}
                                                />   
                                                </FormGroup>    
                                            </Col>
                                            </Row>
                                            <Row className="d-flex justify-content-center">
                                            <Col sm="8" >
                                            <FormGroup>
                                                <Label>
                                                    Data inicial{" "}
                                                </Label>
                                                <Input 
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    name={"start_created_at"}
                                                    type="date"
                                                    defaultValue={formProps?.values?.start_created_at}>
                                                        
                                                </Input>
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row className="d-flex justify-content-center">
                                            <Col sm="8">
                                            <FormGroup>
                                                <Label>
                                                    {" "}
                                                    Data final
                                                </Label>
                                                <Input 
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    name={"end_created_at"}
                                                    type="date" 
                                                    defaultValue={formProps?.values?.end_created_at}>
                                                    
                                                </Input>
                                                </FormGroup>
                                            </Col>
                                            </Row>

                                            <Row  className="d-flex justify-content-center">
                                            <Col sm="8">
                                                <FormGroup>
                                                <Label>Formato de Saída</Label>
                                                <Field
                                                    name="format"
                                                    placeholder={"Selecione"}
                                                    options={[
                                                        {
                                                            label: "Visualização", value: "view"
                                                        },
                                                        {
                                                            label: "Excel", value: "excel"
                                                        }
                                                    ]}
                                                    component={ReactSelectInput}
                                                    isMulti={false}
                                                /> 
                                                </FormGroup>
                                     
                                            </Col>
                                            </Row>

                                            <Row className="d-flex justify-content-center">
                                            
                                            <Col
                                                sm="2"
                                                align="start"
                                                className="mt-4"
                                                >
                                                    {controlState ? (
                                                        <div
                                                            className="spinner-border text-dark"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    ) : null}
                                            </Col>
                                            <Col sm="6" align="end" className="mt-4">
                                                <Button type="submit" disabled ={controlState} className="btn btn-dark">Gerar Relatório
                                                <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                </Button>
                                            </Col>
                                            </Row>
                                      
                                        </Form>
                                        )}
                                    </Formik>  
                                </CardBody>
                            </Card>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
    }

    
}

export default SalesOrderAnalyticalIndex
