import React, { useEffect, useState } from "react"
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Col,
} from "reactstrap"
import { getPreviousAppointments } from "services/vitta-core/appointment"
const ModalOriginAppointment = ({ show, data, setFieldValue, setControlModal, pageData, setPageData }) => {
    const [modalData, setModalData] = useState({
        data: [],
        loading: true,
    })

    const toggle = () => {

      if(!pageData?.slot_data?.origin_appointment)
      {
        setFieldValue('service_id',null);
      }
        setControlModal({
            show: false,
            data:null,
            setFieldValue:null
        })
    }

    const handleCardClick = appointment => {
      let newPageData = {...pageData};
      newPageData.slot_data.origin_appointment = appointment;
      
      setPageData(newPageData);
      toggle();
    }
    const loadAppointmentData = async () => {
      try {
          let existentOriginAppointmentId = pageData?.slot_data?.origin_appointment?.id;
  
          const filters = {
              patient_id: data?.form_data?.patient_id?.value,
              appointment_book_id: data?.slot_data?.appointment_book?.id,
              appointment_id: data?.slot_data?.appointment?.id,
          };
  
          let response = await getPreviousAppointments(filters);
          let stateData = [];
  
         
          if (existentOriginAppointmentId) {
              let found = false;
              for (let i = 0; i < response?.length; i++) {
                  let use = response[i];
                  if (use.id === existentOriginAppointmentId) {
                      use.selected = true;
                      // stateData.unshift(use); // Adiciona o item encontrado como o primeiro elemento
                      stateData.push(use); // Adiciona o item encontrado como o primeiro elemento
                      found = true;
                  } else {
                      stateData.push(use);
                  }
              }
              // Se não encontrou o item com existentOriginAppointmentId, adiciona no início
              if (!found) {
                stateData.push({
                  ...pageData?.slot_data?.origin_appointment,
                  selected: true,
          
              });
                  // stateData.unshift({
                  //     ...pageData?.slot_data?.origin_appointment,
                  //     selected: true,
              
                  // });
              }
          } else {
              stateData = response;
          }
  
          setModalData({
              loading: false,
              data: stateData,
          });
  
          
      } catch (e) {
          console.log("falha ao buscar agendamentos anteriores", e);
      }
  };
    useEffect(() => {
        if (show) {

          setModalData({
            loading:true,
            data:[]
          })

            loadAppointmentData()
        }
    }, [show])

    return (
        <div>
            <Modal isOpen={show} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} style={{ fontWeight: "bold" }}>
                    <i
                        className="fas fa-calendar-alt"
                        style={{ position: "relative", top: "-1px" }}
                    ></i>{" "}
                    Selecione o Agendamento de Origem
                </ModalHeader>
                <ModalBody style={{ maxHeight: "600px", overflowY: "auto" }}>
                    {modalData?.loading ? (
                   <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                   <div className="spinner-border text-info" role="status" style={{ width: "4rem", height: "4rem", borderWidth: "0.3em" }}>
                       <span className="visually-hidden"></span>
                   </div>
               </div>
                    ) : modalData?.data?.length <= 0 ? (
                        "Não encontramos agendamentos anteriores"
                    ) : (
                        <Row>
                            {modalData?.data?.map(appointment => (
                                <Col md="6" key={appointment.id} className="">
                                    <Card
                                        style={{
                                            border:appointment?.selected ? "3px solid #2a7be6" : "1px solid #ccc",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                            transition: "box-shadow 0.3s",
                                        }}
                                        onClick={() =>
                                            handleCardClick(appointment)
                                        }
                                        onMouseOver={e =>
                                            (e.currentTarget.style.boxShadow =
                                                "0 4px 8px rgba(0, 0, 0, 0.2)")
                                        }
                                        onMouseOut={e =>
                                            (e.currentTarget.style.boxShadow =
                                                "none")
                                        }
                                    >
                                        <CardBody>
                                            <CardTitle tag="h5" style={{ display:"flex" }}>
                                                Agendamento #{appointment.id}
                                               {appointment?.selected ? <small style={{ marginLeft:"auto",color:"#2a7be6" }}><strong>Selecionado</strong></small> : null} 
                                            </CardTitle>
                                            <CardText>
                                                <strong>Unidade:</strong>{" "}
                                                {appointment.unit.name} <br />
                                                <strong>
                                                    Usuário Criador:
                                                </strong>{" "}
                                                {appointment?.user?.name}{" "}
                                                <br />
                                                <strong>
                                                   Agendado em:
                                                </strong>{" "}
                                                {new Date(
                                                    appointment.created_at
                                                ).toLocaleDateString('pt-BR')}{" "}
                                                <br />
                                                <strong>
                                                    Data de Execução:
                                                </strong>{" "}
                                                {new Date(
                                                    appointment.start_exec
                                                ).toLocaleDateString('pt-BR')}{" "}
                                                <br />
                                                {/* <strong>
                                                    Data de Término:
                                                </strong>{" "}
                                                {new Date(
                                                    appointment.end_exec
                                                ).toLocaleString()}{" "}
                                                <br /> */}
                                                <strong>Serviço: </strong> 
                                                  {appointment?.service?.name}
                                                <br />
                                            </CardText>
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    handleCardClick(appointment)
                                                }
                                                style={{
                                                    backgroundColor: "#2a7be6",
                                                    borderColor: "#2a7be6",
                                                    width: "100%",
                                                }}
                                            >
                                                <i className="far fa-check-circle"></i>{" "}
                                                Selecionar
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="secondary" onClick={toggle}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalOriginAppointment
