import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;
    if (filter.start_created_at) query += "&start_created_at=" + filter.start_created_at;
    if (filter.end_created_at) query += "&end_created_at=" + filter.end_created_at;
    if (filter.expand_patient) query += "&expand_patient=" + filter.expand_patient;
    if (filter.expand_user) query += "&expand_user=" + filter.expand_user;
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes` + query,
        vtAuth: true
    })
}

const create = async data => {

    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes`,
        data: data,
        vtAuth: true
    })
}

const update = async (id, data) => {
    return request({
        method: "POST",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/${id}`,
        data: data,
        vtAuth: true
    })
}

const deleteSalesDoc = async (id) => {
    return request({
        method: "DELETE",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/${id}`,
        vtAuth: true
    })
}

const loadDataAddform = async() => 
{   
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/add`,
        vtAuth: true
    })
}

const loadDataEditform = async(id) => 
{
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/${id}/edit`,
        vtAuth: true
    })
}

const salesQuoteAnalyticalReport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;    
    if (filter.user_id) query += "&user_id=" + filter.user_id;
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/analytical-report` + query,
        vtAuth: true
    })
}

const salesQuoteAnalyticalReportExport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;  
    if (filter.user_id) query += "&user_id=" + filter.user_id;
    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/analytical-report-export` + query,
        vtAuth: true,
        returnFullResponse: true
    })
}

const createFromExamAttendance = async (id) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-quotes/from-medical-exams/${id}`,
        data: {
            medical_exam_id:id
        },
        vtAuth: true
    })
}


export { search, create, update, deleteSalesDoc, loadDataAddform, loadDataEditform,salesQuoteAnalyticalReport,salesQuoteAnalyticalReportExport, createFromExamAttendance }
