import React from "react"
import { UncontrolledTooltip } from "reactstrap"

const RenderPartnerStatus = ({
    item,
    partner,
    partner_status_id,
    partner_status_name,
    index,
}) => {
    const hermesPardiniLaboratory = 1
    let color = ""

    if (partner == hermesPardiniLaboratory) {
        switch (partner_status_id) {
            case "101":
                color = "#c5ab3d"

                break
            case "90":
                color = "#c5ab3d"
                break
            case "80":
                color = "#c5ab3d"
                break
            case "85":
                color = "#c5ab3d"
                break
            case "42":
                color = "#336a9c"
                break
            case "70":
                color = "#c4822d"
                break
            case "140":
                color = "#266634"
                break
            case "50":
                color = "#8d4ca2"
                break
            case "120":
                color = "#c5ab3d"
                break
            case "130":
                color = "#c5ab3d"
                break
            case "VTNT1":
                color = "#a3a87d"
                break
            case "VTNT2":
                color = "#6b5c91"
                break
            case "VTNT3":
                color = "#b55e43"
                break
            case "VTNT0":
                color = "#8f969d"
                break
        }
    }

    return (
        <div>
            {" "}
            <i
                className="fas fa-circle"
                id={"infostatus" + index}
                style={{
                    cursor: "pointer",
                    fontSize: 20,
                    marginLeft: item?.partner_obs ? "17px" : "31px",
                    color: color,
                    fontWeight: "bold",
                }}
            ></i>
            <UncontrolledTooltip
                target={"infostatus" + index}
                placement="top"
                style={{
                    backgroundColor: color,
                    color: "white",
                    fontWeight: "500",
                }}
            >
                {partner_status_name}
            </UncontrolledTooltip>
        </div>
    )
}

export default RenderPartnerStatus
