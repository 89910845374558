import React from "react"
import {
    Row,
    Col,
    CardBody,
    Card,
    Input,
    Badge,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import SALES_QUOTES_STATUS from "utils/consts/sales-quotes-status"
import { useDispatch } from "react-redux"
import { deleteSalesDoc } from "services/vitta-core/sales-quote"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import SALES_DOCS_TYPE from "utils/consts/sales-docs-type"
import ASSESSMENT_STATUS from "utils/consts/assessments-status"

const SalesQuotesList = ({ data, show, assessment_status, loadDataPage }) => {
    const dispatch = useDispatch()

    const handleDeleteSalesQuote = async id => {
        try {
            await deleteSalesDoc(id)

            await loadDataPage()

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Orçamento excluído com sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...!",
                    text:
                        "Não conseguimos excluir este orçamento, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    const calcDocPrice = items => {
        let total_calc = 0

        for (let i = 0; i < items.length; i++) {
            total_calc += items[i].quantity * items[i].price
        }

        let formated_value =
            "R$ " +
            new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(parseInt(total_calc) / 100)
        return formated_value
    }
    if (!show) return null
    return (
        <React.Fragment>
            {data?.map((item, index) => (
                <div key={"row" + index}>
                    <Row style={{ marginTop: index == 0 ? "20px" : "-15px" }}>
                        <Col sm="10">
                            <Card
                                style={{
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.open(
                                        "/orcamentos/" + item?.id,
                                        "_blank"
                                    )
                                }}
                            >
                                <CardBody style={{ padding: "10px" }}>
                                    <Row>
                                        <Col sm="4">
                                            {" "}
                                            <Badge className="font-size-12 badge-soft-info">
                                                Orçamento Odontológico -{" "}
                                                <strong>#{item?.id}</strong>
                                            </Badge>
                                        </Col>
                                        <Col sm="4" align="center">
                                            <small
                                                className="text-info"
                                                style={{
                                                    fontWeight: "500",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                clique para visualizar
                                            </small>
                                        </Col>
                                        <Col sm="4" align="end">
                                            <strong className="text-info">
                                                {calcDocPrice(item?.items)}
                                            </strong>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="1">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    className="card-drop"
                                    tag="i"
                                    dir
                                    style={{ cursor: "pointer" }}
                                >
                                    <i
                                        id={"actions" + index}
                                        className="fas fa-bars text-info"
                                        style={{
                                            cursor: "pointer",
                                            position: "relative",
                                            top: "9px",
                                            fontSize: "21px",
                                        }}
                                    ></i>
                                </DropdownToggle>

                                <DropdownMenu top className="mt-3">
                                    <DropdownItem
                                        href={`/relatorios/dados-odontologicos?entity_type=${SALES_DOCS_TYPE.SALES_QUOTE}&entity_id=${item?.id}&assessment_id=${item?.assessment_id}`}
                                        target="_blank"
                                    >
                                        <i className="fas fa-print text-primary mr-2" />
                                        Imprimir
                                    </DropdownItem>
                                    <DropdownItem
                                        href={"/orcamentos/" + item?.id}
                                        target="_blank"
                                    >
                                        <i className="fas fa-eye text-primary mr-2" />
                                        Ver Orçamento
                                    </DropdownItem>

                                    {assessment_status ==
                                    ASSESSMENT_STATUS.UNDER_EVALUATION ? (
                                        <DropdownItem
                                            onClick={() => {
                                                dispatch(
                                                    showSweetAlertDialog({
                                                        title: "Atenção ",
                                                        text:
                                                            "Tem certeza que deseja excluir este registro?",
                                                        type: "warning",
                                                        onConfirmAction: () => {
                                                            handleDeleteSalesQuote(
                                                                item?.id
                                                            )
                                                        },
                                                        showCancel: true,
                                                        showConfirm: true,
                                                        cancelBtnText: "Não",
                                                        confirmBtnText: "Sim",
                                                    })
                                                )
                                            }}
                                        >
                                            <i className="fas fa-trash text-danger mr-2" />
                                            Excluir Orçamento
                                        </DropdownItem>
                                    ) : null}
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            {/* <UncontrolledTooltip
                                placement="right"
                                target={"actions" + index}
                                style={{ backgroundColor: "#50a5f1" }}
                            >
                                Ações
                            </UncontrolledTooltip> */}
                        </Col>
                    </Row>
                </div>
            ))}
        </React.Fragment>
    )
}

export default SalesQuotesList
