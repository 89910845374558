import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import { showHistoricExport } from "services/vitta-core/professional-transfer-invoices"
import { showTransferNoteExport } from "services/vitta-core/professional-transfer-invoices"
import debounce from "debounce-promise"
import PROF_TRANSFER_INVOICE_STATUS from "utils/consts/prof-transfer-invoice-status"
import PROF_TRANSFER_INVOICE_PAYMENT_STATUS from "utils/consts/prof-transfer-invoice-payment-status"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="number" {...field} {...props} />

const MedicalPaymentTransferReport = () => {
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })

    const [reportStyle, setReportStyle] = useState(null)
    const [controlState, setControlState] = useState(false)
    const history = useHistory()

    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)
    const dispatch = useDispatch()
    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({
                label: element.name,
                value: element.id,
                cnpj: element.cnpj,
            })
        })
        return options_list
    }, [userAuth])
    const controlPermissionProfessional = useMemo(() => {
        let found = false
        for (let i = 0; i < userAuth?.access_roles?.length; i++) {
            let record = userAuth?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_appointment") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            return false
        }
    }, [userAuth])
    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let list_values = []
                if (reportStyle == 4) {
                    list_values.push({
                        label: "Todos",
                        value: null,
                    })
                }

                response.data.forEach(element => {
                    list_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const onSubmit = async (data_form, actions) => {
        try {
            let auxData = {}
            let unit_ids = []

            if (reportStyle != 4 && reportStyle != 5 && reportStyle != 6) {
                auxData.professional = data_form.professional_id?.value
                    ? {
                          id: data_form.professional_id.value,
                          name: data_form.professional_id.label,
                      }
                    : null
                auxData.unit = {
                    id: data_form.unit_id.value,
                    name: data_form.unit_id.label,
                    cnpj: data_form.unit_id.cnpj,
                }
                auxData.date_exec = data_form.date_exec

                if (auxData.professional == null) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "Coloque um profissional!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }
                if (
                    !auxData.professional ||
                    !auxData.unit ||
                    !auxData.date_exec
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "Ops... ",
                            text: "Preencha Todos os Campos!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }
            }
            if (reportStyle == 4) {
                let arrayStatusPayment = []
                if (
                    !data_form.multi_unit_id ||
                    data_form.multi_unit_id.length == 0
                )
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ops...",
                            text: "Informe pelo menos uma Unidade Clínica.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                for (let i = 0; i < data_form?.multi_unit_id?.length; i++) {
                    unit_ids.push(data_form?.multi_unit_id[i]?.value)
                }
                // if (
                //     data_form?.status_payment &&
                //     data_form?.status_payment.length > 0
                // ) {
                //     for (
                //         let i = 0;
                //         i < data_form?.status_payment?.length;
                //         i++
                //     ) {
                //         arrayStatusPayment.push(
                //             data_form?.status_payment[i]?.value
                //         )
                //     }
                // }

                auxData.professional = data_form.professional_id?.value
                    ? {
                          id: data_form.professional_id.value,
                          name: data_form.professional_id.label,
                      }
                    : null
                auxData.date_exec_init = data_form.date_exec_init
                auxData.date_exec_finish = data_form.date_exec_finish
                auxData.unit_id = unit_ids
                auxData.format = data_form?.format?.value
                // auxData.unit = data_form?.multi_unit_id
                // auxData.status_payment = arrayStatusPayment

                // if (data_form.date_payment_forecast_start) {
                //     auxData.date_payment_forecast_start =
                //         data_form.date_payment_forecast_start
                // }
                // if (data_form.date_payment_forecast_end) {
                //     auxData.date_payment_forecast_end =
                //         data_form.date_payment_forecast_end
                // }

                if (auxData.professional == null && auxData.format != "excel") {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                "No modo visualização é necessário selecionar um profissional em específico!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }

                if (auxData.format == "excel") {
                    setControlState(true)

                    let response = await showHistoricExport({
                        date_exec_init: auxData.date_exec_init,
                        date_exec_finish: auxData.date_exec_finish,
                        unit_id: auxData.unit_id,
                        professional_id: auxData.professional?.id
                            ? auxData.professional?.id
                            : null,
                        // date_payment_forecast_start:
                        //     auxData?.date_payment_forecast_start,
                        // date_payment_forecast_end:
                        //     auxData?.date_payment_forecast_end,
                        // status_payment: auxData?.status_payment,
                    })

                    var headers = response.headers
                    var blob = new Blob([response.data], {
                        type: headers["content-type"],
                    })
                    var link = document.createElement("a")
                    link.href = window.URL.createObjectURL(blob)
                    link.download = "Histórico de Repasses"
                    link.click()
                    setControlState(false)
                    return
                }

                if (
                    !auxData.professional ||
                    !auxData.unit_id ||
                    !auxData.date_exec_init ||
                    !auxData.date_exec_finish ||
                    !auxData.format
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "Ops... ",
                            text: "Preencha Todos os Campos!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }
            }

            if (reportStyle == 5) {
                let arrayStatus = []
                let arrayStatusFiscal = []

                if (
                    !data_form.multi_unit_id ||
                    data_form.multi_unit_id.length == 0
                )
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ops...",
                            text: "Informe pelo menos uma Unidade Clínica.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                for (let i = 0; i < data_form?.multi_unit_id?.length; i++) {
                    unit_ids.push(data_form?.multi_unit_id[i]?.value)
                }

                if (!data_form.status || data_form.status.length == 0) {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ops...",
                            text: "Informe pelo menos um Status.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }

                for (let i = 0; i < data_form?.status?.length; i++) {
                    arrayStatus.push(data_form?.status[i]?.value)
                }
                if (
                    data_form?.status_fiscal &&
                    data_form?.status_fiscal.length > 0
                ) {
                    for (let i = 0; i < data_form?.status_fiscal?.length; i++) {
                        arrayStatusFiscal.push(
                            data_form?.status_fiscal[i]?.value
                        )
                    }
                }

                auxData.professional = data_form.professional_id?.value
                    ? {
                          id: data_form.professional_id.value,
                          name: data_form.professional_id.label,
                      }
                    : null
                auxData.date_exec_init = data_form.date_exec_init
                auxData.date_exec_finish = data_form.date_exec_finish
                auxData.unit_id = unit_ids
                auxData.format = data_form?.format?.value
                auxData.unit = data_form?.multi_unit_id
                auxData.status = arrayStatus
                auxData.status_fiscal = arrayStatusFiscal

                if (auxData.professional == null && auxData.format != "excel") {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                "No modo visualização é necessário selecionar um profissional em específico!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }
                if (auxData.format == "excel") {
                    setControlState(true)

                    let response = await showTransferNoteExport({
                        date_exec_init: auxData.date_exec_init,
                        date_exec_finish: auxData.date_exec_finish,
                        unit_id: auxData.unit_id,
                        professional_id: auxData.professional?.id
                            ? auxData.professional?.id
                            : null,
                        status: auxData?.status,
                        status_fiscal: auxData?.status_fiscal,
                    })

                    var headers = response.headers
                    var blob = new Blob([response.data], {
                        type: headers["content-type"],
                    })
                    var link = document.createElement("a")
                    link.href = window.URL.createObjectURL(blob)
                    link.download = "Notas fiscais"
                    link.click()
                    setControlState(false)
                    return
                }
            }

            if (reportStyle == 6) {
                for (let i = 0; i < data_form?.multi_unit_id?.length; i++) {
                    unit_ids.push(data_form?.multi_unit_id[i]?.value)
                }
                auxData.unit_id = unit_ids
                auxData.professional = data_form.professional_id?.value
                    ? {
                          id: data_form.professional_id.value,
                          name: data_form.professional_id.label,
                      }
                    : null
                auxData.date_payment_forecast_start =
                    data_form.date_payment_forecast_start
                auxData.date_payment_forecast_end =
                    data_form.date_payment_forecast_end

                if (
                    data_form.sales_order_id &&
                    data_form.sales_order_id != ""
                ) {
                    auxData.sales_order_id = data_form.sales_order_id
                }
                if (
                    !auxData.date_payment_forecast_start ||
                    !auxData.date_payment_forecast_end
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "É necessário preencher os campos de data!",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                    return
                }
                if (
                    auxData.professional == null &&
                    controlPermissionProfessional
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                "É necessário preencher o campo de profissional!",
                            type: "error",
                            showConfirm: true,
                        })
                    )

                    return
                }
            }

            setReportControl({
                show: true,
                filter: auxData,
            })
        } catch (e) {
            console.log(e)
            setControlState(false)
            if (e?.response?.status == 422) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Filtro de data inválido!",
                        text:
                            "É possível filtrar no máximo 6 meses de diferença entre datas, verifique os filtros de data e tente novamente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
        }
    }

    if (reportControl.show) {
        if (reportStyle == 3)
            history.push(
                "/relatorios/repasses-medicos/demonstrativo?professional_id=" +
                    reportControl.filter.professional.id +
                    "&unit_id=" +
                    reportControl.filter.unit.id +
                    "&date_exec=" +
                    reportControl.filter.date_exec
            )
        if (reportStyle == 4) {
            let query = `/relatorios/repasses-medicos/historico?professional_id=${
                reportControl.filter.professional.id +
                "&unit_id=" +
                reportControl.filter.unit_id +
                "&start_exec=" +
                reportControl.filter.date_exec_init +
                "&end_exec=" +
                reportControl.filter.date_exec_finish
            }`
            if (reportControl.filter.date_payment_forecast_start) {
                query +=
                    "&date_payment_forecast_start=" +
                    reportControl.filter.date_payment_forecast_start
            }
            if (reportControl.filter.date_payment_forecast_end) {
                query +=
                    "&date_payment_forecast_end=" +
                    reportControl.filter.date_payment_forecast_end
            }
            if (
                reportControl.filter.status_payment &&
                reportControl.filter.status_payment != ""
            ) {
                query +=
                    "&status_payment=" + reportControl.filter.status_payment
            }

            history.push(query)
        }
        if (reportStyle == 5) {
            history.push(
                "/relatorios/repasses-medicos/notas-fiscais?professional_id=" +
                    reportControl.filter.professional.id +
                    "&unit_id=" +
                    reportControl.filter.unit_id +
                    "&start_exec=" +
                    reportControl.filter.date_exec_init +
                    "&end_exec=" +
                    reportControl.filter.date_exec_finish +
                    "&status=" +
                    reportControl?.filter?.status +
                    "&status_fiscal=" +
                    reportControl?.filter?.status_fiscal
            )
        }

        if (reportStyle == 6) {
            let query = `/relatorios/repasses-medicos/recebiveis?date_payment_forecast_start=${
                reportControl.filter.date_payment_forecast_start +
                "&date_payment_forecast_end=" +
                reportControl.filter.date_payment_forecast_end
            }`

            if (reportControl.filter.professional.id) {
                query +=
                    "&professional_id=" + reportControl.filter.professional.id
            }

            if (
                reportControl.filter.unit_id &&
                reportControl.filter.unit_id.length > 0
            ) {
                query += "&unit_id=" + reportControl.filter.unit_id
            }
            if (
                reportControl.filter.sales_order_id &&
                reportControl.filter.sales_order_id != ""
            ) {
                query +=
                    "&sales_order_id=" + reportControl.filter.sales_order_id
            }
            history.push(query)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Repasses Médicos" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Repasses Médicos{" "}
                                                <i className="fas fa-hand-holding-medical fa-lg ml-2"></i>
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    professional_id: null,
                                                    unit_id: Scope.unit
                                                        ? {
                                                              label: Scope.unit.name?.substr(
                                                                  0,
                                                                  40
                                                              ),
                                                              value:
                                                                  Scope.unit.id,
                                                          }
                                                        : null,
                                                    date_exec: todayOnlyDate,
                                                    date_exec_init: todayOnlyDate,
                                                    date_exec_finish: todayOnlyDate,
                                                    date_payment_forecast_start: todayOnlyDate,
                                                    date_payment_forecast_end: todayOnlyDate,
                                                    multi_unit_id: Scope.unit
                                                        ? [
                                                              {
                                                                  label: Scope.unit.name?.substr(
                                                                      0,
                                                                      40
                                                                  ),
                                                                  value:
                                                                      Scope.unit
                                                                          .id,
                                                              },
                                                          ]
                                                        : null,
                                                    format: {
                                                        label: "Visualização",
                                                        value: "view",
                                                    },
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form
                                                        method="POST"
                                                        action="/pagamentos-recebidos/relatorio"
                                                    >
                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Tipo
                                                                        Relatório
                                                                    </Label>
                                                                    {/* //adicionar depois estas visoes
                                                    options={ [
                                                    {
                                                        label: "Repasse Pendente", value: 1
                                                    },
                                                    {
                                                        label: "Repasse Realizado", value: 2
                                                    },
                                                    {
                                                        label: "Demonstrativo de Repasse", value: 3
                                                    },

                                                ] }
                                                 */}

                                                                    <Select
                                                                        placeholder={
                                                                            "Selecione"
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label:
                                                                                    "Demonstrativo de Repasse",
                                                                                value: 3,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Histórico de Repasses",
                                                                                value: 4,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Notas de Repasse",
                                                                                value: 5,
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Recebíveis",
                                                                                value: 6,
                                                                            },
                                                                        ]}
                                                                        onChange={e => {
                                                                            setReportStyle(
                                                                                e.value
                                                                            )
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        {[
                                                            1,
                                                            2,
                                                            3,
                                                            4,
                                                            5,
                                                        ].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Profissional
                                                                        </Label>
                                                                        <Field
                                                                            name="professional_id"
                                                                            placeholder="Selecionar Profissional"
                                                                            defaultValue={
                                                                                formProps.professional_id
                                                                            }
                                                                            loadOptions={
                                                                                debounceOptionsProfessional
                                                                            }
                                                                            component={
                                                                                AsyncSelectInput
                                                                            }
                                                                            noOptionsMessage={() =>
                                                                                "Sem Resultados. Digite para Buscar o Profissional"
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}

                                                        {[1, 2, 3].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col sm="8">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Unidade
                                                                        </Label>
                                                                        <Field
                                                                            name={`unit_id`}
                                                                            placeholder="Selecione a Unidade"
                                                                            classNamePrefix="select2-selection"
                                                                            options={
                                                                                unitsOptions
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}

                                                        {reportStyle == 3 ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-2"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            Execução{" "}
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_exec"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_exec
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {reportStyle == 4 ||
                                                        reportStyle == 5 ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Unidade
                                                                        </Label>
                                                                        <Field
                                                                            name={`multi_unit_id`}
                                                                            placeholder="Selecione a Unidade"
                                                                            classNamePrefix="select2-selection"
                                                                            options={
                                                                                unitsOptions
                                                                            }
                                                                            isMulti={
                                                                                true
                                                                            }
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {reportStyle == 4 ||
                                                        reportStyle == 5 ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            Execução
                                                                            Inicial
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_exec_init"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_exec_init
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {reportStyle == 4 ||
                                                        reportStyle == 5 ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            Execução
                                                                            Final
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_exec_finish"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_exec_finish
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {/* {[4].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    {" "}
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            previsão
                                                                            pagamento
                                                                            inicial
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_payment_forecast_start"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_payment_forecast_start
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    {" "}
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            previsão
                                                                            pagamento
                                                                            final
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_payment_forecast_end"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_payment_forecast_end
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            ""
                                                        )} */}
                                                        {/* {[4].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col sm="8">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Status
                                                                            Pagamento
                                                                        </Label>
                                                                        <Field
                                                                            name="status_payment"
                                                                            placeholder={
                                                                                "Selecione..."
                                                                            }
                                                                            isMulti={
                                                                                true
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    label:
                                                                                        "Pendente",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_PAYMENT_STATUS.PENDENT,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Pago",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_PAYMENT_STATUS.PAID,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Cancelado",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_PAYMENT_STATUS.CANCELED,
                                                                                },
                                                                            ]}
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            ""
                                                        )} */}
                                                        {[5].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Status
                                                                        </Label>
                                                                        <Field
                                                                            name="status"
                                                                            placeholder={
                                                                                "Selecione..."
                                                                            }
                                                                            isMulti={
                                                                                true
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    label:
                                                                                        "Aberta",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_STATUS.OPEN,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Paga",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_STATUS.PAID,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Cancelada",
                                                                                    value:
                                                                                        PROF_TRANSFER_INVOICE_STATUS.CANCELED,
                                                                                },
                                                                            ]}
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {[5].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Status
                                                                            Fiscal
                                                                        </Label>
                                                                        <Field
                                                                            name="status_fiscal"
                                                                            placeholder={
                                                                                "Selecione..."
                                                                            }
                                                                            isMulti={
                                                                                true
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    label:
                                                                                        "Aberta",
                                                                                    value: 1,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Emitida",
                                                                                    value: 2,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Transmitindo",
                                                                                    value: 3,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Falha",
                                                                                    value: 4,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Rejeitada",
                                                                                    value: 5,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Cancelada",
                                                                                    value: 6,
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Substituída",
                                                                                    value: 7,
                                                                                },
                                                                            ]}
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {reportStyle == 4 ||
                                                        reportStyle == 5 ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col sm="8">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Formato
                                                                            de
                                                                            Saída
                                                                        </Label>
                                                                        <Field
                                                                            name="format"
                                                                            placeholder={
                                                                                "Selecione"
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    label:
                                                                                        "Visualização",
                                                                                    value:
                                                                                        "view",
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Excel",
                                                                                    value:
                                                                                        "excel",
                                                                                },
                                                                            ]}
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .format
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                        {[6].includes(
                                                            reportStyle
                                                        ) ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            ID
                                                                            Pedido
                                                                        </Label>
                                                                        <Field
                                                                            name="sales_order_id"
                                                                            placeholder="Digite o número do pedido"
                                                                            defaultValue={
                                                                                formProps.sales_order_id
                                                                            }
                                                                            component={
                                                                                NumberInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                {controlPermissionProfessional ? (
                                                                    <Col
                                                                        sm="8"
                                                                        className="mt-1"
                                                                    >
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Profissional
                                                                            </Label>
                                                                            <Field
                                                                                name="professional_id"
                                                                                placeholder="Selecionar Profissional"
                                                                                defaultValue={
                                                                                    formProps.professional_id
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsProfessional
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar o Profissional"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {/* <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Unidade
                                                                        </Label>
                                                                        <Field
                                                                            name={`multi_unit_id`}
                                                                            placeholder="Selecione a Unidade"
                                                                            classNamePrefix="select2-selection"
                                                                            options={
                                                                                unitsOptions
                                                                            }
                                                                            isMulti={
                                                                                true
                                                                            }
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col> */}

                                                                <Col sm="8">
                                                                    {" "}
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            previsão
                                                                            pagamento
                                                                            inicial
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_payment_forecast_start"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_payment_forecast_start
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="8">
                                                                    {" "}
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            previsão
                                                                            pagamento
                                                                            final
                                                                        </Label>
                                                                        <Input
                                                                            onChange={
                                                                                formProps.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formProps.handleBlur
                                                                            }
                                                                            name={
                                                                                "date_payment_forecast_end"
                                                                            }
                                                                            type="date"
                                                                            defaultValue={
                                                                                formProps
                                                                                    .values
                                                                                    .date_payment_forecast_end
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {reportStyle ? (
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="3"
                                                                    align="start"
                                                                    className="mt-4"
                                                                >
                                                                    {controlState ? (
                                                                        <div
                                                                            className="spinner-border text-dark"
                                                                            role="status"
                                                                        >
                                                                            <span className="sr-only">
                                                                                Loading...
                                                                            </span>
                                                                        </div>
                                                                    ) : null}
                                                                </Col>
                                                                <Col
                                                                    sm="5"
                                                                    align="end"
                                                                    className="mt-4"
                                                                >
                                                                    {controlState ? (
                                                                        <Button
                                                                            type="submit"
                                                                            className="btn btn-dark"
                                                                            disabled
                                                                        >
                                                                            Gerar
                                                                            Relatório
                                                                            <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            type="submit"
                                                                            className="btn btn-dark"
                                                                        >
                                                                            Gerar
                                                                            Relatório
                                                                            <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                        </Button>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MedicalPaymentTransferReport
