import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import classnames from "classnames"
import {
    TabContent,
    TabPane,
    CardText,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Input,
    CardSubtitle,
    NavItem,
    NavLink,
    Nav,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import ListStatementColumns from "./listStatementColumns"
import Select from "react-select"
import { showSweetAlertDialog } from "store/layout/actions"
import { searchFinancialTransaction, getDigitalAccountInfo, searchReceivables } from "services/vitta-core/professional"
import ListReceivablesColumns from "./listReceivablesColumns"
import ModalReceivables from "./modalReceivables"

const today = new Date().toLocaleDateString("pt-BR");

const dateFormat = (date) => {
    if (!date) return today;
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
};
const todayOnlyDateInput = () => {
    const day = new Date().toLocaleDateString("pt-BR", { timeZone: "UTC" })
    return day.substr(6, 4) + "-" + day.substr(3, 2) + "-" + day.substr(0, 2)
}
const dateIn30Days = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const day = currentDate.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    return day.substr(6, 4) + "-" + day.substr(3, 2) + "-" + day.substr(0, 2);
}

const dateLess7Days = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const day = currentDate.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    return day.substr(6, 4) + "-" + day.substr(3, 2) + "-" + day.substr(0, 2);
}


const AccountDigital = () => {
    const User = useSelector(state => state.auth.access.user)
    const [activeTab, setactiveTab] = useState("1")
    const [modal, setModal] = useState({
        show: false,
        data: null
    })
    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }
    const [loadingAccount, setLoadingAccount] = useState(false);
    const dispatch = useDispatch()
    const inputRefDateEnd = useRef()
    const inputRefDateStart = useRef()
    const inputRefDateEndAt = useRef()
    const inputRefDateStartAt = useRef()
    const inputRefDoc = useRef()
    const [status, setStatus] = useState({ label: "Pendente", value: 1 });
    const [receivablesList, setReceivablesList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })
    const [statementList, setStatementList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })
    const [accountData, setAccountData] = useState({
        balance: 0,
        receivables: 0,
        earlyDate: null,
        totalReceivables: 0,
        earlyReceivablesValue: 0,
        account: null
    })

    const [pageControl, setPageControl] = useState({
        searching: false,
    })

    const getAccountData = async () => {
        setLoadingAccount(true)
        try {
            const data = await getDigitalAccountInfo(User.Professional?.id)
            setAccountData({
                balance: data.balance,
                receivables: data.receivables,
                earlyDate: dateFormat(data.earlyDate),
                totalReceivables: data.totalReceivables,
                earlyReceivablesValue: data.earlyReceivablesValue,
                account: data.account
            })
            setLoadingAccount(false)
        } catch (error) {
            console.log(error)
            setLoadingAccount(false)
        }
    }

    const searchFinancialData = async (page = 1) => {
        if (pageControl.searching) return
        let sizePerPage = 10
        let filter = {}
        let dateEnd = inputRefDateEnd.current.value
        let dateStart = inputRefDateStart.current.value
        if (dateEnd) filter.end_created_at = dateEnd
        if (dateStart) filter.start_created_at = dateStart
        filter.professional_id = User.Professional?.id
        if (!dateStart || !dateEnd) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Por favor, preencha as duas datas para realizar a busca.",
                    icon: "warning",
                })
            );
        }
        const startDate = new Date(dateStart);
        const endDate = new Date(dateEnd);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays > 31) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "O período máximo permitido é de 31 dias.",
                    icon: "warning",
                })
            );
        }

        setPageControl({ ...pageControl, ...{ searching: true } })
        setStatementList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        try {
            let data = await searchFinancialTransaction(filter, page, sizePerPage)

            setStatementList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const searchReceivablesData = async (page = 1) => {
        if (pageControl.searching) return
        let sizePerPage = 10
        let filter = {}
        let dateEnd = inputRefDateEndAt.current.value
        let dateStart = inputRefDateStartAt.current.value
        let statusId = status.value
        let docId = inputRefDoc.current?.value
        if (dateEnd) filter.end_at = dateEnd
        if (dateStart) filter.start_at = dateStart
        if (statusId) filter.status = statusId
        if (docId) filter.sales_order_id = docId
        filter.professional_id = User.Professional?.id

        if (!dateStart || !dateEnd) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Por favor, preencha as duas datas para realizar a busca.",
                    icon: "warning",
                })
            );
        }
        const startDate = new Date(dateStart);
        const endDate = new Date(dateEnd);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays > 31) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "O período máximo permitido é de 31 dias.",
                    icon: "warning",
                })
            );
        }

        setPageControl({ ...pageControl, ...{ searching: true } })
        setReceivablesList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        try {
            let data = await searchReceivables(filter, page, sizePerPage)

            setReceivablesList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const handleTableStatementChange = (type, { page }) => {
        if (type == "pagination") {
            searchFinancialData(page)
        }
    }
    const handleTableReceivablesChange = (type, { page }) => {
        if (type == "pagination") {
            searchReceivablesData(page)
        }
    }

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="pt-4 pl-4 pr-4 pb-4">
                    <Col md="12" className="pt-4 pl-4 pr-4 pb-4">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col md="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }

    useEffect(() => {
        getAccountData()
    }, [])
    useEffect(() => {
        if (activeTab == '2') searchFinancialData(1)
        else if (activeTab == '1') searchReceivablesData(1)
    }, [activeTab])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Vittá Pagamentos"
                        breadcrumbItem="Conta Digital"
                    />
                    <Row>
                        <Col sm="3">
                            <Card className="h-10 shadow-sm  bg-white rounded">
                                <CardBody >
                                    <Nav tab style={{ justifyContent: 'space-between' }} className="nav-tabs-custom flex-row">
                                        <span className="menu-title" align="start">
                                            Saldo
                                        </span>
                                        <Button
                                            color="primary"
                                            className="mb-2"
                                            onClick={getAccountData}
                                        >
                                            <i className="mdi mdi-refresh" style={{ fontSize: '16px', padding: 0 }} />
                                        </Button>

                                    </Nav>
                                    {loadingAccount ? (
                                        <Row align="center" style={{ justifyContent: 'center' }} className="pt-4 pl-4 pr-4 pb-4">
                                            <div
                                                className="spinner-border text-primary"
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </Row>
                                    ) : (<>
                                        <Row style={{ justifyContent: 'space-between' }} className="pt-4 pl-4 pr-4">
                                            <CardSubtitle>Saldo em Conta</CardSubtitle>
                                            <CardSubtitle style={{ color: '#11FF00' }}>{"R$ " + (accountData.balance / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</CardSubtitle>

                                        </Row>
                                        {/* <Row style={{ justifyContent: 'space-between' }} className="pt-4 pl-4 pr-4">
                                            <CardSubtitle>Saldo Agendado</CardSubtitle>
                                            <CardSubtitle style={{ color: '#FFAE00' }}>{"R$ " + (accountData.receivables / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</CardSubtitle>
                                        </Row> */}
                                    </>

                                    )}

                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='9'>
                            <Row>
                                <Col >
                                    <Card>
                                        <CardBody>
                                            <Nav
                                                tabs
                                                className="nav-tabs-custom "
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            active:
                                                                activeTab ===
                                                                "1",
                                                        })}
                                                        onClick={() => {
                                                            toggle("1")
                                                        }}
                                                    >
                                                        Recebíveis
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            active:
                                                                activeTab ===
                                                                "2",
                                                        })}
                                                        onClick={() => {
                                                            toggle("2")
                                                        }}
                                                    >
                                                        Extrato
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="12" align="start">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <CardText>
                                                <Row>
                                                    <Col sm="6">
                                                        <Card >
                                                            {loadingAccount ? (
                                                                <CardBody className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                                                                    <div
                                                                        className="spinner-border mt-1 mb-4"
                                                                        role="status"
                                                                        style={{ color: "#FFAE00" }}
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </CardBody>
                                                            ) :
                                                                <CardBody className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                                                                    <h5><strong>Próximo pagamento previsto para {accountData.earlyDate? accountData.earlyDate:''}</strong></h5>
                                                                    <h4 style={{ color: '#FFAE00' }}>{(accountData.earlyReceivablesValue / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
                                                                </CardBody>
                                                            }
                                                        </Card>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Card className="m-0 p-0">
                                                            {loadingAccount ? (
                                                                <CardBody className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                                                                    <div
                                                                        className="spinner-border mt-1 mb-4"
                                                                        role="status"
                                                                        style={{ color: "#FFAE00" }}
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </CardBody>
                                                            ) :
                                                                <CardBody className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                                                                    <h5><strong>Total de recebíveis previsto</strong></h5>
                                                                    <h4 style={{ color: '#FFAE00' }}>{(accountData.totalReceivables / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
                                                                </CardBody>
                                                            }
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <Card>
                                                            <CardBody>
                                                                <PaginationProvider
                                                                    pagination={paginationFactory(
                                                                        receivablesList
                                                                    )}
                                                                >
                                                                    {({
                                                                        paginationProps,
                                                                        paginationTableProps,
                                                                    }) => (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={receivablesList.data || []}
                                                                            columns={ListReceivablesColumns(setModal)}
                                                                            bootstrap4
                                                                        >
                                                                            {toolkitProps => (
                                                                                <React.Fragment>
                                                                                    <Row className="mb-4">
                                                                                        <Col xs="12" md="3">
                                                                                            <Label>Data Recebimento Inicial</Label>
                                                                                            <Input
                                                                                                type="date"
                                                                                                innerRef={inputRefDateStartAt}
                                                                                                defaultValue={todayOnlyDateInput()}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs="12" md="3">
                                                                                            <Label>Data Recebimento Final</Label>
                                                                                            <Input
                                                                                                type="date"
                                                                                                innerRef={inputRefDateEndAt}
                                                                                                defaultValue={dateIn30Days()}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs="12" md="2">
                                                                                            <Label>Status</Label>
                                                                                            <Select
                                                                                                value={status}
                                                                                                onChange={(e) => {
                                                                                                    setStatus(e)
                                                                                                }}
                                                                                                defaultValue={{
                                                                                                    label: "Pendente",
                                                                                                    value: 1,
                                                                                                }}
                                                                                                options={[
                                                                                                    { label: "Pendente", value: 1 },
                                                                                                    { label: "Recebido", value: 2 },
                                                                                                ]}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs="12" md="3">
                                                                                            <Label>ID Pedido de Venda</Label>
                                                                                            <Input type="text" innerRef={inputRefDoc} />
                                                                                        </Col>
                                                                                        <Col xs="12" md="1" className="d-flex align-items-end">
                                                                                            <Button onClick={async () => {
                                                                                                await searchReceivablesData(1)
                                                                                            }} color="primary" className="w-100">
                                                                                                <i className="fas fa-search"></i>
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <Col xl="12">
                                                                                            <div className="table-responsive">
                                                                                                <BootstrapTable
                                                                                                    responsive
                                                                                                    remote
                                                                                                    bordered={
                                                                                                        false
                                                                                                    }
                                                                                                    striped={
                                                                                                        false
                                                                                                    }
                                                                                                    classes={
                                                                                                        "table table-centered table-nowrap"
                                                                                                    }
                                                                                                    headerWrapperClasses={
                                                                                                        "table-light"
                                                                                                    }
                                                                                                    {...toolkitProps.baseProps}
                                                                                                    onTableChange={
                                                                                                        handleTableReceivablesChange
                                                                                                    }
                                                                                                    noDataIndication={
                                                                                                        renderNoDataIndication
                                                                                                    }
                                                                                                    {...paginationTableProps}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="align-items-md-center mt-30">
                                                                                        <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                                            <PaginationListStandalone
                                                                                                {...paginationProps}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    )}
                                                                </PaginationProvider>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </CardText>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="2" align="start">
                                            <CardText>
                                                <Row>
                                                    <Col xs="12">
                                                        <Card>
                                                            <CardBody>
                                                                <PaginationProvider
                                                                    pagination={paginationFactory(
                                                                        statementList
                                                                    )}
                                                                >
                                                                    {({
                                                                        paginationProps,
                                                                        paginationTableProps,
                                                                    }) => (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={statementList.data || []}
                                                                            columns={ListStatementColumns()}
                                                                            bootstrap4
                                                                        >
                                                                            {toolkitProps => (
                                                                                <React.Fragment>
                                                                                    <Row className="mb-4">
                                                                                        <Col xs="12" md="3">
                                                                                            <Label>Data Inicial</Label>
                                                                                            <Input
                                                                                                type="date"
                                                                                                innerRef={inputRefDateStart}
                                                                                                defaultValue={dateLess7Days()}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs="12" md="3">
                                                                                            <Label>Data Final</Label>
                                                                                            <Input
                                                                                                type="date"
                                                                                                innerRef={inputRefDateEnd}
                                                                                                defaultValue={todayOnlyDateInput()}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col xs="12" md="1" className="d-flex align-items-end">
                                                                                            <Button onClick={async () => {
                                                                                                await searchFinancialData(1)
                                                                                            }} color="primary" className="w-100">
                                                                                                <i className="fas fa-search"></i>
                                                                                            </Button>
                                                                                        </Col>

                                                                                    </Row>

                                                                                    <Row>
                                                                                        <Col xl="12">
                                                                                            <div className="table-responsive">
                                                                                                <BootstrapTable
                                                                                                    responsive
                                                                                                    remote
                                                                                                    bordered={
                                                                                                        false
                                                                                                    }
                                                                                                    striped={
                                                                                                        false
                                                                                                    }
                                                                                                    classes={
                                                                                                        "table table-centered table-nowrap"
                                                                                                    }
                                                                                                    headerWrapperClasses={
                                                                                                        "table-light"
                                                                                                    }
                                                                                                    {...toolkitProps.baseProps}
                                                                                                    onTableChange={
                                                                                                        handleTableStatementChange
                                                                                                    }
                                                                                                    noDataIndication={
                                                                                                        renderNoDataIndication
                                                                                                    }
                                                                                                    {...paginationTableProps}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="align-items-md-center mt-30">
                                                                                        <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                                            <PaginationListStandalone
                                                                                                {...paginationProps}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    )}
                                                                </PaginationProvider>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </CardText>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <ModalReceivables show={modal.show} onClose={() => setModal({
                    show: false,
                    data: null
                })} data={modal.data}></ModalReceivables>
            </div>
        </React.Fragment>
    )
}

export default AccountDigital
