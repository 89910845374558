export default {
    arcos: {
        superior: {
            direito: [
                { id: "11", name: "Incisivo Central Superior Direito" },
                { id: "12", name: "Incisivo Lateral Superior Direito" },
                { id: "13", name: "Canino Superior Direito" },
                { id: "14", name: "Primeiro Pré-molar Superior Direito" },
                { id: "15", name: "Segundo Pré-molar Superior Direito" },
                { id: "16", name: "Primeiro Molar Superior Direito" },
                { id: "17", name: "Segundo Molar Superior Direito" },
                { id: "18", name: "Terceiro Molar Superior Direito" },
                // { id: "19", name: "Dente Permanente Supranumerário Hemi-arco" },
            ],
            esquerdo: [
                { id: "21", name: "Incisivo Central Superior Esquerdo" },
                { id: "22", name: "Incisivo Lateral Superior Esquerdo" },
                { id: "23", name: "Canino Superior Esquerdo" },
                { id: "24", name: "Primeiro Pré-molar Superior Esquerdo" },
                { id: "25", name: "Segundo Pré-molar Superior Esquerdo" },
                { id: "26", name: "Primeiro Molar Superior Esquerdo" },
                { id: "27", name: "Segundo Molar Superior Esquerdo" },
                { id: "28", name: "Terceiro Molar Superior Esquerdo" },
                // {
                //     id: "29",
                //     name:
                //         "Dente Permanente Supranumerário Hemi-arco Superior Esquerdo",
                // },
            ],
        },
        inferior: {
            direito: [
                { id: "41", name: "Incisivo Central Inferior Direito" },
                { id: "42", name: "Incisivo Lateral Inferior Direito" },
                { id: "43", name: "Canino Inferior Direito" },
                { id: "44", name: "Primeiro Pré-molar Inferior Direito" },
                { id: "45", name: "Segundo Pré-molar Inferior Direito" },
                { id: "46", name: "Primeiro Molar Inferior Direito" },
                { id: "47", name: "Segundo Molar Inferior Direito" },
                { id: "48", name: "Terceiro Molar Inferior Direito" },
                // {
                //     id: "49",
                //     name:
                //         "Dente Permanente Supranumerário Hemi-arco Inferior Direito",
                // },
            ],
            esquerdo: [
                { id: "31", name: "Incisivo Central Inferior Esquerdo" },
                { id: "32", name: "Incisivo Lateral Inferior Esquerdo" },
                { id: "33", name: "Canino Inferior Esquerdo" },
                { id: "34", name: "Primeiro Pré-molar Inferior Esquerdo" },
                { id: "35", name: "Segundo Pré-molar Inferior Esquerdo" },
                { id: "36", name: "Primeiro Molar Inferior Esquerdo" },
                { id: "37", name: "Segundo Molar Inferior Esquerdo" },
                { id: "38", name: "Terceiro Molar Inferior Esquerdo" },
                // {
                //     id: "39",
                //     name:
                //         "Dente Permanente Supranumerário Hemi-arco Inferior Esquerdo",
                // },
            ],
        },
        deciduo: {
            superior: {
                direito: [
                    {
                        id: "51",
                        name: "Incisivo Central Decíduo Superior Direito",
                    },
                    {
                        id: "52",
                        name: "Incisivo Lateral Decíduo Superior Direito",
                    },
                    { id: "53", name: "Canino Decíduo Superior Direito" },
                    {
                        id: "54",
                        name: "Primeiro Molar Decíduo Superior Direito",
                    },
                    {
                        id: "55",
                        name: "Segundo Molar Decíduo Superior Direito",
                    },
                    // {
                    //     id: "59",
                    //     name:
                    //         "Dente Decíduo Supranumerário Hemi-arco Superior Direito",
                    // },
                ],
                esquerdo: [
                    {
                        id: "61",
                        name: "Incisivo Central Decíduo Superior Esquerdo",
                    },
                    {
                        id: "62",
                        name: "Incisivo Lateral Decíduo Superior Esquerdo",
                    },
                    { id: "63", name: "Canino Decíduo Superior Esquerdo" },
                    {
                        id: "64",
                        name: "Primeiro Molar Decíduo Superior Esquerdo",
                    },
                    {
                        id: "65",
                        name: "Segundo Molar Decíduo Superior Esquerdo",
                    },
                    // {
                    //     id: "69",
                    //     name:
                    //         "Dente Decíduo Supranumerário Hemi-arco Superior Esquerdo",
                    // },
                ],
            },
            inferior: {
                direito: [
                    {
                        id: "81",
                        name: "Incisivo Central Decíduo Inferior Direito",
                    },
                    {
                        id: "82",
                        name: "Incisivo Lateral Decíduo Inferior Direito",
                    },
                    { id: "83", name: "Canino Decíduo Inferior Direito" },
                    {
                        id: "84",
                        name: "Primeiro Molar Decíduo Inferior Direito",
                    },
                    {
                        id: "85",
                        name: "Segundo Molar Decíduo Inferior Direito",
                    },
                    // {
                    //     id: "89",
                    //     name:
                    //         "Dente Decíduo Supranumerário Hemi-arco Inferior Direito",
                    // },
                ],
                esquerdo: [
                    {
                        id: "71",
                        name: "Incisivo Central Decíduo Inferior Esquerdo",
                    },
                    {
                        id: "72",
                        name: "Incisivo Lateral Decíduo Inferior Esquerdo",
                    },
                    { id: "73", name: "Canino Decíduo Inferior Esquerdo" },
                    {
                        id: "74",
                        name: "Primeiro Molar Decíduo Inferior Esquerdo",
                    },
                    {
                        id: "75",
                        name: "Segundo Molar Decíduo Inferior Esquerdo",
                    },
                    // {
                    //     id: "79",
                    //     name:
                    //         "Dente Decíduo Supranumerário Hemi-arco Inferior Esquerdo",
                    // },
                ],
            },
        },
    },
}
