import request from "./config/request"

const create = async (id, professional_data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-services/create/${id}`,
        data: professional_data,
        vtAuth: true,
    })
}

const update = async (id, professional_data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-services/update/${id}`,
        data: professional_data,
        vtAuth: true,
    })
}

export { create, update }
