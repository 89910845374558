import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import { showSweetAlertDialog, showToast } from "store/layout/actions";
import useCashControl from "../../../services/vitta-core/cash-control";
import CashMovesDataTable from "./components/CashMovesDataTable";
import CashControlDashboard from "./components/CashControlDashboard.jsx";

/**
 * Componente principal de listagem de controle de caixa.
 * Gerencia a abertura, fechamento e transferência entre caixas.
 */
const ListCashControl = () => {
    const {
        findLastCashControlByUserId,
        openCashControlService,
        closeCashControlService,
        findOpenCashControlsToday,
        transferCashControl,
    } = useCashControl();

    const dispatch = useDispatch();
    const history = useHistory();
    const Scope = useSelector(state => state.scope);
    const [cashControl, setCashControl] = useState(null);
    const [isCashOpen, setIsCashOpen] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [openModalOpen, setOpenModalOpen] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [openCashControls, setOpenCashControls] = useState([]);
    const [selectedCashControl, setSelectedCashControl] = useState(null);
    const [initialValue, setInitialValue] = useState(0);
    const [loadingOpenCashControl, setLoadingOpenCashControl] = useState(false); // Flag de loading
    const [loadingTransfer, setLoadingTransfer] = useState(false); // Flag de loading para transferência

    // Referência para a tabela de movimentações de caixa
    const cashMovesTableRef = useRef(null);

    /**
     * Verifica o status do último caixa aberto pelo usuário.
     * Atualiza o estado do caixa e a tabela.
     */
    const checkCashControlStatus = async () => {
        try {
            const result = await findLastCashControlByUserId();
            setCashControl(result);

            const isClosed = result.data.close_value !== null && result.data.close_at !== null;
            setIsCashOpen(!isClosed); // Define se o caixa está aberto
            setCanAdd(!isClosed); // Define se o botão "Adicionar" está habilitado
            cashMovesTableRef.current.refreshTable(); // Atualiza a tabela
        } catch (error) {
            console.error("Erro ao verificar status do caixa", error);
        }
    };

    /**
     * Busca os caixas abertos hoje e disponíveis para transferência.
     */
    const fetchOpenCashControls = async () => {
        try {
            const result = await findOpenCashControlsToday();
            setOpenCashControls(result);
        } catch (error) {
            console.error("Erro ao buscar cash controls abertos", error);
        }
    };

    /**
     * Abre o modal de transferência e busca os caixas abertos disponíveis.
     */
    const handleOpenTransferModal = async () => {
        setOpenTransferModal(true);
        await fetchOpenCashControls();
    };

    useEffect(() => {
        checkCashControlStatus();
    }, []);

    /**
     * Abre um novo caixa com o valor fornecido.
     * @param {number} value - Valor inicial do caixa.
     */
    const openCashControl = async (value) => {
        setLoadingOpenCashControl(true); 
        try {
            const unitId = Scope?.unit?.id;
            if (!unitId) {
                throw new Error("Unidade não identificada. Verifique o Scope.");
            }
            let response = await openCashControlService(value, unitId);

            if (response) {
                dispatch(showToast({ title: "Sucesso", text: "Caixa aberto com sucesso!", type: "success" }));
                checkCashControlStatus();
            }

        } catch (error) {
            console.error("Erro ao abrir o caixa:", error);
            dispatch(showToast({ title: "Erro", text: "Não foi possível abrir o caixa. Tente novamente mais tarde.", type: "error" }));
        } finally {
            setOpenModalOpen(false); // Fecha o modal após a operação
            setLoadingOpenCashControl(false);
        }
    };

    /**
     * Fecha o caixa atual.
     */
    const closeCashControl = async () => {
        if (!cashControl) {
            dispatch(showToast({ title: "Erro", text: "Nenhum caixa encontrado.", type: "error" }));
            return;
        }

        try {
            const response = await closeCashControlService(cashControl?.data.id);
            if (response) {
                dispatch(showToast({ title: "Sucesso", text: "Caixa fechado com sucesso!", type: "success" }));
                setIsCashOpen(false); // Caixa fechado, desabilita o botão
                setCanAdd(false); // Desabilita o botão de adicionar
                cashMovesTableRef.current.refreshTable(); // Atualiza a tabela após fechar o caixa
            }
        } catch (error) {
            console.error("Erro ao fechar o caixa", error);
            dispatch(showToast({ title: "Erro", text: "Não foi possível fechar o caixa. Tente novamente mais tarde.", type: "error" }));
        }
    };

    /**
     * Função que lida com o clique no botão de fechar caixa.
     * Abre o modal de confirmação.
     */
    const handleCloseCashControl = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção",
                text: `Tem certeza que deseja fechar o caixa?`,
                type: "warning",
                onConfirmAction: async () => {
                    await closeCashControl();
                    setIsCashOpen(false);
                    setCanAdd(false);
                    cashMovesTableRef.current.refreshTable();
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        );
    };

    /**
     * Função que lida com a transferência de valor entre caixas.
     */
    const handleTransferCashControl = async () => {
        setLoadingTransfer(true); 
        if (!selectedCashControl || transferAmount <= 0) {
            setLoadingTransfer(false); 
            return dispatch(showToast({ title: "Atenção", text: "Selecione um caixa de destino e defina um valor válido.", type: "warning" }));
        }

        try {
            await transferCashControl(cashControl.data.id, selectedCashControl, transferAmount);
            dispatch(showToast({ title: "Sucesso", text: "Transferência realizada com sucesso!", type: "success" }));

            setOpenTransferModal(false);
            checkCashControlStatus();
        } catch (error) {
            console.error("Erro ao realizar a transferência:", error);
            dispatch(showToast({ title: "Erro", text: "Erro ao realizar a transferência.", type: "error" }));
        } finally {
            setLoadingTransfer(false);
        }
    };

    /**
     * Renderiza o botão de abrir ou fechar caixa com base no status atual.
     */
    const renderCashControlButton = () => {
        if (isCashOpen) {
            return (
                <Button type="submit" color="danger" onClick={handleCloseCashControl}>
                    Fechar Caixa
                </Button>
            );
        } else {
            return (
                <Button type="submit" color="success" onClick={() => setOpenModalOpen(true)}>
                    Abrir Caixa
                </Button>
            );
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Usuário" breadcrumbItem="Controle de Caixa" />

                    {/* balões de BI */}
                    <Row className="mb-4">
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {cashControl && cashControl.summary && (
                                        <CashControlDashboard summary={cashControl.summary} />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* tabela de movimentações */}
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2 justify-content-end">
                                        <Col sm="auto">
                                            <Link to={`/controle-caixa/${cashControl?.data.id}/adicionar`}>
                                                <Button type="submit" color="primary" disabled={!canAdd}>
                                                    Adicionar
                                                </Button>
                                            </Link>
                                        </Col>
                                        <Col sm="auto">
                                            <Button color="info" disabled={!isCashOpen} onClick={handleOpenTransferModal}>
                                                Transferir
                                            </Button>
                                        </Col>
                                        <Col sm="auto">
                                            {renderCashControlButton()}
                                        </Col>
                                    </Row>

                                    {/* Tabela com listagem e filtro dos CashMoves */}
                                    <CashMovesDataTable ref={cashMovesTableRef} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
             
             {/* Modal  abertura de caixa */}
            <Modal isOpen={openModalOpen} toggle={() => setOpenModalOpen(!openModalOpen)}>
                <ModalHeader toggle={() => setOpenModalOpen(!openModalOpen)}>
                    Definir valor de abertura
                </ModalHeader>
                <ModalBody>
                    <Label for="initialValue">Valor Inicial</Label>
                    <Input
                        type="text"
                        id="initialValue"
                        value={initialValue ? `R$ ${(initialValue / 100)?.toFixed(2)}` : ''}
                        onChange={(e) => {
                            const numericValue = Number(e.target.value.replace(/\D/g, ''));
                            setInitialValue(numericValue); // Definindo o valor em centavos
                        }}
                        placeholder="Digite o valor inicial do caixa"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (initialValue <= 0) {
                                dispatch(showToast({
                                    title: "Atenção",
                                    text: "O valor inicial deve ser maior que zero.",
                                    type: "warning",
                                }));
                                return;
                            }
                            openCashControl(initialValue);
                        }}
                        disabled={loadingOpenCashControl} 
                    >
                        {loadingOpenCashControl ? 'Abrindo...' : 'Confirmar'}
                    </Button>
                    <Button color="secondary" onClick={() => setOpenModalOpen(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal de transferência entre caixas */}
            <Modal isOpen={openTransferModal} toggle={() => setOpenTransferModal(!openTransferModal)}>
                <ModalHeader toggle={() => setOpenTransferModal(!openTransferModal)}>Transferir valor para outro caixa</ModalHeader>
                <ModalBody>
                    <Label for="cashControlDestination">Caixa de Destino</Label>
                    <Input
                        type="select"
                        id="cashControlDestination"
                        value={selectedCashControl}
                        onChange={(e) => setSelectedCashControl(e.target.value)}
                    >
                        <option value="">Selecione o caixa de destino</option>
                        {openCashControls.map(control => (
                            <option key={control.id} value={control.id}>
                                Caixa de {control.user.name} - Unidade: {control.unit_id}
                            </option>
                        ))}
                    </Input>

                    <Label for="transferAmount" className="mt-3">Valor de Transferência</Label>
                    <Input
                        type="text"
                        id="transferAmount"
                        value={transferAmount ? `R$ ${(transferAmount / 100)?.toFixed(2)}` : ''}
                        onChange={(e) => {
                            const numericValue = Number(e.target.value.replace(/\D/g, ''));
                            setTransferAmount(numericValue);
                        }}
                        placeholder="Digite o valor a ser transferido"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleTransferCashControl} disabled={loadingTransfer}>
                        {loadingTransfer ? 'Transferindo...' : 'Confirmar Transferência'}
                    </Button>
                    <Button color="secondary" onClick={() => setOpenTransferModal(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ListCashControl;
