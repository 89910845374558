import React, { useState, useEffect } from "react"
import { Row, Col, Button, CardBody, Card, CardTitle } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import logovitta from "assets/images/logovitta2.jpg"
import { useParams, Link } from "react-router-dom"
import { getOrderToPrint as getOrderToPrint } from "services/vitta-core/orders-exams"
import QRCode from "react-qr-code"
// import "./print.css"

const printExamRequests = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({
        data: null,
        accessPatient: null,
        datePrediction: null,
    })

    useEffect(() => {
        if (id) loadDataEditPage(id)
        else console.log("não entrou no edit")
    }, [id])

    const handlePrint = () => {
        window.print()
    }
    const loadDataEditPage = async id => {
        try {
            let result = await getOrderToPrint(id)

            if (result) {
                setPageData({
                    data: result?.result,
                    accessPatient: result?.recordAccessPatient
                        ? result?.recordAccessPatient
                        : null,
                    datePrediction: result?.maxValuePrediction,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="page-content">
            <Card>
                <CardBody>
                    <Row>
                        <Col
                            md="6"
                            className="d-flex justify-content-start align-items-center"
                        >
                            <img
                                src={logovitta}
                                alt=""
                                height="80px"
                                className="mb-2"
                            />

                            <div className="d-flex flex-column  mt-1">
                                <h4>Clínica Vittá</h4>
                                <h6>Unidade {pageData?.data?.unit?.name}</h6>
                                <h6>{pageData?.data?.unit?.address}</h6>
                            </div>
                            {/* <div className="d-flex flex-column  ">
                                <h4>Unidade {pageData?.unit?.name}</h4>
                                <div className="">
                                    CNPJ: {pageData?.unit?.cnpj}
                                </div>
                            </div> */}
                        </Col>
                        <Col>
                            <div align="end">
                                <Link
                                    to={`/pacientes/${pageData?.data?.patient?.id}/pedidos-exame?exams_order_id=${pageData?.data?.id}`}
                                    target="_blank"
                                    className="btn btn-secondary mr-1 d-print-none"
                                >
                                    <i className="fas fa-vial fa-lg text-white mr-1"></i>{" "}
                                    Pedido de exames{" "}
                                </Link>
                                <Link
                                    to={`/pedidos/${pageData?.data?.sales_order_id}`}
                                    target="_blank"
                                    className="btn btn-info mr-1 d-print-none"
                                >
                                    <i className="fas fa-cart-plus fa-lg text-white mr-1"></i>{" "}
                                    Pedido de venda
                                </Link>
                                <Button
                                    color="secondary"
                                    onClick={() => handlePrint()}
                                    className="d-print-none btn btn-dark"
                                >
                                    <i className="fas fa-print fa-lg text-white mr-1"></i>{" "}
                                    Imprimir
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="6">
                            <div className="d-flex flex-column  mt-1">
                                <h6>
                                Paciente: {pageData?.data?.patient?.name}
                                </h6>
                                <h6>
                                {pageData?.data?.patient?.birth_date
                                        ? "Data de Nascimento: " +
                                          (pageData?.data?.patient?.birth_date
                                              ? pageData?.data?.patient?.birth_date.substr(
                                                    8,
                                                    2
                                                ) +
                                                "/" +
                                                pageData?.data?.patient?.birth_date.substr(
                                                    5,
                                                    2
                                                ) +
                                                "/" +
                                                pageData?.data?.patient?.birth_date.substr(
                                                    0,
                                                    4
                                                )
                                              : "")
                                        : null}
                                </h6>
                                {pageData?.data?.patient?.mother_name ?    <h6>
                                Nome da mãe: {pageData?.data?.patient?.mother_name}
                                </h6> : null}
                                <h6>
                                    Médico:{" "}
                                    {
                                        pageData?.data?.items[0]
                                            ?.request_professional_name
                                    }
                                </h6>
                            </div>
                        </Col>
                        <Col sm="6" align="center">
                            <div className="d-flex flex-column  mt-1">
                                <h6>Pedido: #{pageData?.data?.id}</h6>
                                <h6>
                                    Data:{" "}
                                    {pageData?.data?.created_at
                                        ? new Date(
                                              pageData?.data?.created_at
                                          ).toLocaleDateString("pt-BR", {
                                              hour: "2-digit",
                                              hour12: false,
                                              minute: "2-digit",
                                              second: "2-digit",
                                          })
                                        : null}
                                </h6>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    {pageData?.data?.items &&
                    pageData?.data?.items?.length > 0 ? (
                        <Row>
                            <Col sm="6">
                                {" "}
                                <CardTitle>Exames Solicitados</CardTitle>
                            </Col>
                            <Col sm="6" align="center">
                                {" "}
                                <CardTitle>Previsão</CardTitle>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                    {pageData?.data?.items && pageData?.data?.items?.length > 0
                        ? pageData?.data?.items?.map((e, index) => (
                              <Row>
                                  <Col sm="6" align="start">
                                      {index + 1} - {e?.item_name}
                                  </Col>

                                  <Col sm="6" align="center">
                                      {e?.result_prediction
                                          ? new Date(
                                                e?.result_prediction
                                            ).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                hour12: false,
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })
                                          : ""}
                                  </Col>
                              </Row>
                          ))
                        : ""}

                    <hr />

                    <Row>
                        <Col sm="10" className="mt-2">
                            <CardTitle>OBSERVAÇÕES CLIENTE</CardTitle>
                            <h6>
                                Previsão do Resultado :{" "}
                                {pageData?.datePrediction
                                    ? new Date(
                                          pageData?.datePrediction
                                      ).toLocaleDateString("pt-BR", {
                                          hour: "2-digit",
                                          hour12: false,
                                          minute: "2-digit",
                                          second: "2-digit",
                                      })
                                    : ""}{" "}
                            </h6>
                            <h6>
                                {" "}
                                {pageData?.accessPatient ? (
                                    <strong>
                                        Código de Acesso:{" "}
                                        {pageData?.accessPatient?.username} -
                                        SENHA:{" "}
                                        {pageData?.accessPatient?.password}
                                    </strong>
                                ) : (
                                    ""
                                )}
                            </h6>
                            Já está disponível o serviço de entrega de
                            resultados via internet:{" "}
                            <strong>
                                {process.env.REACT_APP_PATIENT_AREA_WEBAPP}
                                /exames
                            </strong>
                        </Col>

                        <Col sm="2" align="center">
                            <QRCode
                                size="85"
                                value={
                                    process.env.REACT_APP_PATIENT_AREA_WEBAPP +
                                    `/exames?u=${pageData?.accessPatient?.username}&p=${pageData?.accessPatient?.password}`
                                }
                            />
                            <br />
                            <br />
                            Leia o QR Code para acessar os resultados.
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default printExamRequests
