import React,{useEffect,useRef,useState} from "react"
import { Modal, Row, Col, Input } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import AvatarEditor from "react-avatar-editor"
// import { Avatar, Button,Box, Slider } from "@material-ui/icons"

import "./style.css"

const ModalImage = ({ show, data, onClose }) => {
    let editor = ""
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img:null,
        zoom: 1,
        croppedImg: "",
    })

    const [objectImage, setObjectImage] = useState({})

    const handleSlider = e => {
        const scale = parseFloat(e?.target?.value)

        setPicture({
            ...picture,
            zoom: scale,
        })
    }

    const setEditorRef = ed => {
        editor = ed
    }

    const handleSave = async e => {
        try
        {     
                const canvasScaled = editor.getImageScaledToCanvas()
               
             
                const croppedImg =  canvasScaled.toDataURL()
            
                let res = await fetch(croppedImg)
                let myBlob = await res.blob()
                let fileObj = new File([myBlob], objectImage.name, {
                    type: myBlob.type,
                })
                let data = { objectImage: fileObj, src: croppedImg }
    
                onClose(data)
            
        }
        catch(e)
        {
            console.log(e)
        }
     
    }

    const handleFileChange = e => {
        if (!e?.target?.files[0]) {
            return
        }

        setObjectImage(e?.target?.files[0])
        
      
        let url = URL.createObjectURL(e.target.files[0])
                   //    console.log(url,e?.target?.files[0])
        setPicture({
            ...picture,
            img: url,
            cropperOpen: true,
        })
    }

    // useEffect(() => {
    //   if(show && data)
    //   {
    //     // let image = new Image()
    //     // image.setAttribute('crossOrigin', 'anonymous')
    //     // image.src = data + '?time=' + new Date().valueOf();
        
    //     // console.log("teste",image)
    //     // fetch(img,{
    //     //     mode: 'cors',
    //     //     headers: {
    //     //       'Access-Control-Allow-Origin':'*'
    //     //     }}).then(function(response) {
    //     //     return response.blob();
    //     //   }).then(function(myBlob) {
              
    //     //     var objectURL = URL.createObjectURL(myBlob);
    //     //     console.log("teste",objectURL)
    //     //     // myImage.src = objectURL;
    //     //   });

    //     // let file = new File([image], "img.png",{type:"image/png", lastModified:new Date().getTime()});
       
    //     // let url = URL.createObjectURL(file)

    //     // console.log(url)
       
    //           setPicture({
    //         ...picture,
    //           img:data,
    //           cropperOpen:true,
    //       })

    //   }
    // }, [show])

    return (
        <Modal
            isOpen={show}
            centered={false}
            size="lg"
            style={{ border: "1px solid #343a40" }}
        >
            <div
                className="modal-header"
                style={{ backgroundColor: "#343a40" }}
            >
                <Col sm="8" align="end">
                    <div className="modal-title " id="myModalLabel">
                        <h3 style={{ color: "white" }}>
                            Atualizar foto de perfil
                        </h3>
                    </div>
                </Col>
                <Col sm="4">
                    {" "}
                    <button
                        type="button"
                        onClick={() => {
                            let src = {
                                src: picture?.croppedImg
                                    ? picture?.croppedImg
                                    : data,
                            }

                            onClose(src)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true" style={{ color: "white" }}>
                            &times;
                        </span>
                    </button>
                </Col>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#343a40" }}>
                <Row className="mt-2">
                    <Col sm="12" align="center">
                        {picture.cropperOpen ? (
                            <AvatarEditor
                                id="avatar"
                               
                                ref={setEditorRef}
                                image={picture?.img}
                                width={300}
                                height={300}
                                border={10}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={picture?.zoom}
                                rotate={0}
                                
                                
                            />
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm="2" align="end">
                        {" "}
                        {picture?.cropperOpen ? (
                            <i className="fas fa-minus text-white fa-lg"></i>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col sm="8" align="center">
                        {picture.cropperOpen ? (
                            <Input
                                type="range"
                                step={0.1}
                                value={picture.zoom}
                                min={1}
                                max={12}
                                onChange={handleSlider}
                            ></Input>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col sm="2">
                        {" "}
                        {picture?.cropperOpen ? (
                            <i className="fas fa-plus text-white fa-lg"></i>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
                {picture?.cropperOpen ? (
                    <div className="mt-4 mb-4">
                        <i className="fas fa-globe text-white fa-lg mr-2"></i>{" "}
                        <span className="text-white">
                            {" "}
                            Esta foto de perfil é pública.
                        </span>{" "}
                    </div>
                ) : (
                    ""
                )}
                <Row className="mt-2 mb-2">
                    <Col sm="12">
                        <Input
                            type="file"
                            onChange={handleFileChange}
                            name="arquivo"
                            id="arquivo"
                            className="form-control"
                            
                        ></Input>
                    </Col>
                </Row>
            </div>
            {/* {!picture?.cropperOpen ? <img    width={300}
                                height={300} src={picture?.croppedImg ? picture?.croppedImg : ''}></img> : ''} */}

            <div
                className="modal-footer"
                style={{ backgroundColor: "#343a40" }}
            >
                <button
                    type="button"
                    onClick={() => {
                        let src = {
                            src: picture?.croppedImg
                                ? picture?.croppedImg
                                : data,
                        }
                        onClose(src)
                    }}
                    className="btn btn-secondary waves-effect text-white"
                    data-dismiss="modal"
                    style={{ backgroundColor: "#343a40" }}
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    onClick={() => {
                        handleSave()
                    }}
                    disabled={picture?.cropperOpen ? false : true}
                    className="btn btn-primary waves-effect text-white"
                    data-dismiss="modal"
                >
                    Salvar
                </button>
            </div>
        </Modal>
    )
}

export default ModalImage
