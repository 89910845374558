import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input
} from "reactstrap"
import Select from "react-select"
import { searchGeneralDashboard } from "services/vitta-core/general-dashboard"
import { Formik, Field, Form } from "formik"
import { PieChart } from 'react-minimal-pie-chart'
import { showSweetAlertDialog } from "store/layout/actions"


const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate = today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)
const ReactSelectInput = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Select
    {...field}
    {...props}
    onChange={option => {
      props.state(false)
      if (option.value == "custom") {
        props.stateCustom(true)
      }
      else props.stateCustom(false)
      setFieldValue(field.name, option)
    }}
  />
)

const ReactSelectInput2 = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Select
    {...field}
    {...props}
    onChange={option => {
      props.state(false)
      setFieldValue(field.name, option)
    }}
  />
)
const TextDateInput = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue },
  ...props
}) => <Input type="date" {...field}
  onChange={option => {
    props.state(false)
    setFieldValue(field.name, option.target.value)
  }}{...props} />


const Dashboard = () => {

  const [DashboardControl, setDashboardControl] = useState({
    data: [],
    initial: true
  });
  const [ChangeSelect, setChangeSelect] = useState(true)
  const userAuth = useSelector(state => state.auth.access?.user)
  const Scope = useSelector(state => state.scope)
  const [CustomSelect, setCustomSelect] = useState(false)
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const Period = [
    { value: "hoje", label: "Hoje" },
    { value: "ontem", label: "Ontem" },
    { value: "mes", label: "Este Mês" },
    { value: "custom", label: "Customizado" },
  ]

  const unitsOptions = useMemo(() => {
    if (!userAuth?.units) return []

    let options_list = []
    userAuth.units.forEach(element => {
      options_list.push({ label: element.name, value: element.id })
    })
    return options_list
  }, [userAuth])

  useEffect(async () => {
    if (Scope.unit?.id && DashboardControl.initial) {
      let response = await searchGeneralDashboard({
        unit_id: Scope.unit?.id,
        period_id: Period[0].value,
      })
      if (response) setLoading(false) //stop loading
      //console.log(response)
      setDashboardControl({
        data: response,
        initial: false
      })
    }
  }, [Scope.unit])

  const onSubmit = async (data_form) => {
    setChangeSelect(true)
    if (!DashboardControl.initial) setLoading(true) //true, pois loading fica falso no useEffect
    try {
      let auxData = {}
      auxData.unit = {
        id: data_form.unit_id?.value,
        name: data_form.unit_id?.label,
      }
      auxData.period = {
        id: data_form.period?.value,
        name: data_form.period?.label,
      }
      auxData.DStart = CustomSelect ? data_form.DStart : todayOnlyDate
      auxData.DEnd = CustomSelect ? data_form.DEnd : todayOnlyDate
      let response = await searchGeneralDashboard({
        unit_id: auxData.unit.id,
        period_id: auxData.period.id,
        DStart: auxData.DStart,
        DEnd: auxData.DEnd
      })
      if (response) setLoading(false) //stop loading
      setDashboardControl({
        data: response,
        initial: false
      })
      return
    } catch (e) {
      setLoading(false) //stop loading
      if (e?.response?.data?.error_code == "date_error") {  
        return dispatch(
          showSweetAlertDialog({
            title: "Ops... ",
            text: "Data Inválida",
            type: "error",
            showConfirm: true,
          })
        )

      }
    }

  }

  let dataMock = [
    { title: 'Previstos', value: DashboardControl.data.AnotherAppointment ? DashboardControl.data.AnotherAppointment.previstos : 0, color: '#85C1E9' },
    { title: 'Concluídos', value: DashboardControl.data.AnotherAppointment ? parseInt(DashboardControl.data.AnotherAppointment.concluidos) : 0, color: '#2E86C1' },
  ];

  return (
    Scope.unit ?//cada vez que renderiza o Scope atualiza
      <div className="page-content">
        <Container fluid>
          <Formik
            initialValues={{
              unit_id: { label: Scope.unit.name, value: Scope.unit.id },
              period: Period[0],
              DStart: todayOnlyDate,
              DEnd: todayOnlyDate
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row className="mb-2 mr-1">
                  <Col className="mt-2" sm={CustomSelect ? "4" : "6"}>
                    <h1>Dashboard</h1>
                  </Col>
                  <Col >
                    <Row>
                      <Col className={CustomSelect?"mr-2 ml-0":"ml-2"}>
                        <p
                          style={{
                            fontSize: '70%',
                            margin: "0px",
                            fontWeight: "bold",
                          }}>
                          Unidade</p>
                        <Field
                          name="unit_id"
                          placeholder="Selecione a Unidade"
                          options={
                            unitsOptions
                          }
                          component={
                            ReactSelectInput2
                          }
                          state={setChangeSelect}
                          stateCustom={setCustomSelect}
                        />
                      </Col>
                      <Col >
                        <p
                          style={{
                            fontSize: '70%',
                            margin: "0px",
                            fontWeight: "bold",
                          }}>
                          Período</p>
                        <Field
                          name="period"
                          placeholder="Selecione um Periodo"
                          options={
                            Period
                          }
                          component={
                            ReactSelectInput
                          }
                          state={setChangeSelect}
                          stateCustom={setCustomSelect}
                        />
                      </Col>
                      {CustomSelect ?
                        <React.Fragment>
                          <Col>
                            <p
                              style={{
                                fontSize: '70%',
                                margin: "0px",
                                fontWeight: "bold",
                              }}>
                              Data Inicial</p>

                            <Field
                              name="DStart"
                              state={setChangeSelect}
                              component={
                                TextDateInput
                              }>
                            </Field>
                          </Col>
                          <Col>
                            <p
                              style={{
                                fontSize: '70%',
                                margin: "0px",
                                fontWeight: "bold",
                              }}>
                              Data Final</p>
                            <Field
                              name="DEnd"
                              state={setChangeSelect}
                              component={
                                TextDateInput
                              }>
                            </Field>
                          </Col>
                        </React.Fragment> : ""}
                      <Col sm="0" style={{ marginTop: "15px" }}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={ChangeSelect}
                        >
                          <span className="bx bx-search-alt fa-lg" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {loading ?
                  <>
                    <Row style={{ top: "50%", left: "50%", position: "absolute" }}>
                      <Col sm="12">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Col>
                    </Row>
                  </> :
                  <>
                    <Row className="mt-3" >
                      <Col lg="3" md="6" sm="6">
                        <Card style ={{height:"100px"}} className="mini-stats-wid">
                          <CardBody className="shadow-sm  bg-white rounded">
                            <Row>
                              <Col md="4" xs="5">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="fas fa-solid fa-calendar font-size-24" />
                                  </span>
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-center">
                                  <p className="h6 text-muted" >
                                    Agendamentos Criados
                                  </p>
                                  <h3 className="font-weight-bold">{DashboardControl.data.AppointmnetCriados ? DashboardControl.data.AppointmnetCriados.criados : 0}</h3>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3" md="6" sm="6">
                        <Card style ={{height:"100px"}} className="mini-stats-wid">
                          <CardBody className="shadow-sm  bg-white rounded">
                            <Row>
                              <Col md="4" xs="5">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="fas fa-solid fa-user-plus font-size-24" />
                                  </span>
                                </div>
                              </Col>

                              <Col md="8" xs="7">
                                <div className="text-center">
                                  <p className="h6 text-muted" >
                                    Novos Pacientes
                                    <p
                                      style={{
                                        fontSize: '55%',
                                        margin: "0px",
                                        fontWeight: "bold",
                                      }}>
                                      Todas Unidades</p>
                                  </p>

                                  <h3 style={{ margin: "0px" }} className="font-weight-bold">{DashboardControl.data.Patients ? "+ " + DashboardControl.data.Patients.novos_pacientes : "+ " + 0}</h3>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="3" md="6" sm="6">
                        <Card style ={{height:"100px"}} className="mini-stats-wid">
                          <CardBody className="shadow-sm  bg-white rounded">
                            <Row>
                              <Col md="4" xs="5">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="fas fa-shopping-cart font-size-24" />
                                  </span>
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-center">
                                  <p className="h6 text-muted" >
                                    Pedidos de Venda
                                  </p>
                                  <h3 className="font-weight-bold">{DashboardControl.data.Orders ? DashboardControl.data.Orders.pedidos_vendas : 0}</h3>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="3" md="6" sm="6">
                        <Card style ={{height:"100px"}} className="mini-stats-wid">
                          <CardBody className="shadow-sm  bg-white rounded">
                            <Row>
                              <Col md="4" xs="5">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="fas fa-solid fa-star font-size-24" />
                                  </span>
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-center">
                                  <p className="h6 text-muted" >
                                    PCE
                                  </p>
                                  <h3 className="font-weight-bold">{DashboardControl.data.Pce ? new Intl.NumberFormat(
                                    "pt-br",
                                    {
                                      style:
                                        "decimal",

                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  ).format(
                                    DashboardControl.data.Pce.score_pce
                                  ) : 0}</h3>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card>
                          <CardBody className="shadow-sm  bg-white rounded">
                            <h4 className="card-title mb-4">Agendamentos</h4>
                            <div style={{
                              position: "relative",
                              height: "400px"
                            }}>
                              {!isSubmitting && !DashboardControl.initial ?
                                <PieChart
                                  data={dataMock}
                                  animate={true}
                                  lineWidth={18}
                                  paddingAngle={18}
                                  animationDuration={1500}
                                  rounded
                                  label={({ x, y, dx, dy, dataEntry }) => (
                                    <text
                                      x={x}
                                      y={y}
                                      dx={dx}
                                      dy={dy}
                                      dominant-baseline="central"
                                      text-anchor="middle"
                                      style={{
                                        fontSize: '4.4px',
                                        fill: dataEntry.color
                                      }}
                                    >{dataEntry.title + ": " + dataEntry.value}
                                    </text>)}

                                /> : ""
                              }

                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardBody className="shadow-sm  bg-white rounded">
                            <h4 className="card-title mb-4">Serviços Vendidos</h4>
                            <div style={{
                              position: "relative",
                              height: "400px",
                              overflow: "auto",
                            }}>
                              <Table className="table table-hover table-striped">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">
                                      Grupo do Serviço
                                    </th>
                                    <th scope="col">Quantidade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {DashboardControl.data.Item?.map(
                                    (row, index) => (
                                      <tr align="center" key={index}>
                                        <td align="start" >
                                          {row.group_name}
                                        </td>
                                        <td>
                                          {row.items_count}
                                        </td>

                                      </tr>
                                    )
                                  )}

                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card>
                          <CardBody className="shadow-sm  bg-white rounded">
                            <h4 className="card-title mb-4">Contratos Cartões Vittá <i className="fas fa-plus"></i></h4>
                            <div style={{
                              position: "relative",
                              overflow: "auto",
                            }}>
                              <Table className="table table-hover table-striped">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Criados</th>
                                    <th scope="col">Assinados</th>
                                    <th scope="col">Ativos</th>
                                    <th scope="col">Cancelados</th>
                                    <th scope="col">Pendentes</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr align="center">
                                    <td align="start">
                                      {DashboardControl.data.CardStatus?.Criados}
                                    </td>
                                    <td align="start">
                                      {DashboardControl.data.CardStatus?.Assinados}
                                    </td>
                                    <td align="start">
                                      {DashboardControl.data.CardStatus?.Ativos}
                                    </td>
                                    <td align="start">
                                      {DashboardControl.data.CardStatus?.Cancelados}
                                    </td>
                                    <td align="start">
                                      {DashboardControl.data.CardStatus?.Pendentes}
                                    </td>
                                  </tr>

                                </tbody>
                              </Table>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                }
              </Form>
            )}
          </Formik>

        </Container>
      </div> : ""

  )
}

export default Dashboard
