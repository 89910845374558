const axios = require("axios")
const qs = require("qs")

const printLabelByBrowserPrint = async (command_label,config_printer)=>
{
    let data = {
        data: command_label,
        device:config_printer
    };

    let config = {
        method: "POST",
        url:
            process.env.REACT_APP_PRINT_URL_BROWSER_PRINTER
            +
            "/write",
        headers: {
            "accept": "*/*",
            "content-type": "text/plain;charset=UTF-8",
        },
        //não pode mandar header
        data: data,
        timeout: 2000,
    }

    let dataResult = await axios.request(config);

    return dataResult.data;
}

const printLabelByPrinterProxy = async (command_label)=>
{
    let data = qs.stringify({
        documentData: command_label,
    })

    let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url:
            process.env.REACT_APP_PRINT_ETIQUETA_LAB +
            "/gti-printer-proxy/api/printer/print",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
    }

    let dataResult = await axios.request(config);

    return dataResult.data;
}

const getConfigBrowserPrinter = async ()=>
{
    let config = {
        method: "GET",
        url:
            process.env.REACT_APP_PRINT_URL_BROWSER_PRINTER +
            "/default?type=printer",
        timeout: 2000,
    }

    let data = await axios.request(config);

    return data.data;
}
export {
    printLabelByPrinterProxy,
    printLabelByBrowserPrint,
    getConfigBrowserPrinter
}
