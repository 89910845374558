import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"


const ScheduleLockColumns = (deleteRecord) => [
    
  
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    
    {
        dataField: "name",
        text: "Nome da Agenda",
        formatter: (cellContent, row) => (
           
             row?.appointment_book?.name
            
        ),
    },
    {
        dataField: "unit",
        text: "Unidade",
        formatter: (cellContent, row) => (
           
             row?.unit?.name
            
        ),
    },
    {
        dataField: "user",
        text: "Usuário",
        formatter: (cellContent, row) => (
           
             row?.user?.name ?   row?.user?.name  : ''
            
        ),
    },
    {
        dataField: "date_start",
        text: "Data Inicial",
        formatter: (cellContent, row) => (
            new Date(row.date_start).toLocaleDateString()
        ),
    },
    {
        dataField: "date_end",
        text: "Data Final",
        formatter: (cellContent, row) => (
            new Date(row.date_end).toLocaleDateString('pt-BR')
        ),
    },
    {
        dataField: "time_start",
        text: "Horário Inicial",
        formatter: (cellContent, row) => (
            new Date(row.date_start).toLocaleTimeString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit", })
        ),
    },
    {
        dataField: "time_end",
        text: "Horário Final",
        formatter: (cellContent, row) => (
            new Date(row.date_end).toLocaleTimeString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit", })
        ),
    },
   
   
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id,row) => (
            <>
         
                <Link to="#" className="text-danger" onClick={() => { deleteRecord(row?.id, row?.unit.id)}}>
                    <i className="mdi mdi-trash-can-outline font-size-24 mr-3" id={"deletetooltip" + row?.id} align="end" />
                    <UncontrolledTooltip placement="top" target={"deletetooltip" + row?.id}>
                        Excluir
                     </UncontrolledTooltip>
                </Link>
          
            </>
        ),
    },
]

export default ScheduleLockColumns