import { call, put, takeEvery } from "redux-saga/effects"

import { SET_UNIT_SCOPE } from "./actionTypes"

function* sagaSetUnitScope(action) 
{
  localStorage.setItem("vittanet.scope.unit", JSON.stringify(action.payload))
}

function* scopeSaga() 
{
  yield takeEvery(SET_UNIT_SCOPE, sagaSetUnitScope)
}

export default scopeSaga
