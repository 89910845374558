import React, { useState, useEffect, useRef } from "react"
import { Modal, CardBody } from "reactstrap"
import ZOOP_SDK_PAYMENT_TYPES from "utils/consts/zoop-sdk-payment-types"

const CheckPaymentLoading = ({ show }) => {
    if (!show) return null

    return (
        <Modal isOpen={show} centered size="sm">
            <CardBody id="my-container-iframe" className="text-center">
                <div
                    class="spinner-border text-info"
                    style={{ width: "5rem", height: "5rem" }}
                    role="status"
                ></div>
                {/* <div className="mt-3"><span>Processando...</span></div> */}
            </CardBody>
        </Modal>
    )
}

export default CheckPaymentLoading
