module.exports = {
    INDICACAO: 1,
    GOOGLE: 2,
    INSTAGRAM: 3,
    RADIO: 4,
    ACAO_SOCIAL: 5,
    TV: 6,
    PANFLETAGEM: 7
}

