import React from "react"
import {
    Row,
    Col,
    Label,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Button,
} from "reactstrap"
import { FieldArray, FastField } from "formik"

const RenderExamRequests = ({
    values,
    AsyncSelectInput,
    setFieldValue,
    setControlModalAdditionalData,
}) => {
    return (
        <div>
            <Row className="mt-3">
                <Col sm="12">
                    <CardTitle>Itens</CardTitle>
                    <CardSubtitle className="">
                        Selecione um laboratório para comparar os itens.
                    </CardSubtitle>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col sm="2" className="d-none d-lg-block">
                    <Label className="ml-2">Exames Vittá</Label>
                </Col>

                <Col sm="2" className="d-none d-lg-block">
                    <Label>Exames Laboratório</Label>
                </Col>

                <Col sm="2" className="d-none d-lg-block">
                    <Label>Material Vittá</Label>
                </Col>

                <Col sm="2" className="d-none d-lg-block">
                    <Label>Material Laboratório</Label>
                </Col>

                <Col sm="2" className="d-none d-lg-block">
                    <Label>Conservante</Label>
                </Col>
            </Row>

            <Row className="mt-1">
                <Col sm="12">
                    <FieldArray
                        name="items"
                        render={arrayHelpers => (
                            <div>
                                {values && values?.items?.length > 0
                                    ? values?.items?.map((item, index) => (
                                          <>
                                              <Row
                                                  className="ml-1"
                                                  key={item.indexArr}
                                              >
                                                  <Col
                                                      xs="12"
                                                      md="12"
                                                      lg="2"
                                                      style={{
                                                          paddingLeft: "0px",
                                                      }}
                                                  >
                                                      <FormGroup
                                                          style={{
                                                              fontSize:
                                                                  "0.7rem",
                                                          }}
                                                      >
                                                          <Label className="d-block d-lg-none">
                                                              Exames Vittá
                                                          </Label>{" "}
                                                          {/* Visível somente em tamanhos de tela xs, sm */}
                                                          <FastField
                                                              name={`items[${index}].item_id`}
                                                              placeholder="Selecione o Item"
                                                              classNamePrefix="select2-selection"
                                                              defaultValue={
                                                                  item.item_id
                                                              }
                                                              component={
                                                                  AsyncSelectInput
                                                              }
                                                              isDisabled={true}
                                                          />
                                                      </FormGroup>
                                                  </Col>
                                                  {item?.partner_item_code ? (
                                                      <Col
                                                          xs="12"
                                                          md="12"
                                                          lg="2"
                                                          style={{
                                                              paddingLeft:
                                                                  "0px",
                                                          }}
                                                      >
                                                          <FormGroup
                                                              style={{
                                                                  fontSize:
                                                                      "0.7rem",
                                                              }}
                                                          >
                                                              <Label className="d-block d-lg-none">
                                                                  Exames
                                                                  Laboratório
                                                              </Label>{" "}
                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                              <FastField
                                                                  name={`items[${index}].partner_item_code`}
                                                                  placeholder="Selecione o Item"
                                                                  classNamePrefix="select2-selection"
                                                                  defaultValue={
                                                                      item.partner_item_code
                                                                  }
                                                                  component={
                                                                      AsyncSelectInput
                                                                  }
                                                                  isDisabled={
                                                                      true
                                                                  }
                                                              />
                                                          </FormGroup>
                                                      </Col>
                                                  ) : values?.partner_id ? (
                                                      <small className="text-danger mt-3">
                                                          Necessário que exista
                                                          um exame do
                                                          laboratório vinculado.
                                                      </small>
                                                  ) : (
                                                      ""
                                                  )}

                                                  {item?.exams_material_id ? (
                                                      <Col
                                                          xs="12"
                                                          md="12"
                                                          lg="2"
                                                          style={{
                                                              paddingLeft:
                                                                  "0px",
                                                          }}
                                                      >
                                                          <FormGroup
                                                              style={{
                                                                  fontSize:
                                                                      "0.7rem",
                                                              }}
                                                          >
                                                              <Label className="d-block d-lg-none">
                                                                  Materiais
                                                              </Label>{" "}
                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                              <FastField
                                                                  name={`items[${index}].exams_material_id`}
                                                                  placeholder="Selecione o Item"
                                                                  classNamePrefix="select2-selection"
                                                                  defaultValue={
                                                                      item.exams_material_id
                                                                  }
                                                                  component={
                                                                      AsyncSelectInput
                                                                  }
                                                                  isDisabled={
                                                                      true
                                                                  }
                                                              />
                                                          </FormGroup>
                                                      </Col>
                                                  ) : item?.partner_item_code &&
                                                    values?.partner_id ? (
                                                      <Col
                                                          xs="12"
                                                          md="12"
                                                          lg="2"
                                                      >
                                                          {" "}
                                                          <small className="mt-3 text-danger">
                                                              Necessário que
                                                              exista um material
                                                              vinculado aos
                                                              serviços.
                                                          </small>{" "}
                                                      </Col>
                                                  ) : (
                                                      ""
                                                  )}
                                                  {item?.partner_exam_material_code ? (
                                                      <Col
                                                          xs="12"
                                                          md="12"
                                                          lg="2"
                                                          style={{
                                                              paddingLeft:
                                                                  "0px",
                                                          }}
                                                      >
                                                          <FormGroup
                                                              style={{
                                                                  fontSize:
                                                                      "0.7rem",
                                                              }}
                                                          >
                                                              <Label className="d-block d-lg-none">
                                                                  Material
                                                                  Laboratório
                                                              </Label>{" "}
                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                              <FastField
                                                                  name={`items[${index}].partner_exam_material_code`}
                                                                  placeholder="Selecione o Item"
                                                                  classNamePrefix="select2-selection"
                                                                  value={{
                                                                      label:
                                                                          item?.partner_exam_material_code,
                                                                      value: null,
                                                                  }}
                                                                  component={
                                                                      AsyncSelectInput
                                                                  }
                                                                  isDisabled={
                                                                      true
                                                                  }
                                                              />
                                                          </FormGroup>
                                                      </Col>
                                                  ) : values?.partner_id ? (
                                                      <Col
                                                          sm="2"
                                                          className="mt-2"
                                                      >
                                                          {" "}
                                                          <small
                                                              className="mt-3 text-danger"
                                                              align="start"
                                                          >
                                                              Material não
                                                              vinculado ao
                                                              parceiro.
                                                          </small>{" "}
                                                      </Col>
                                                  ) : (
                                                      ""
                                                  )}
                                                  {item?.exams_preservative_id ? (
                                                      <Col
                                                          xs="12"
                                                          md="12"
                                                          lg="2"
                                                          style={{
                                                              paddingLeft:
                                                                  "0px",
                                                          }}
                                                      >
                                                          <FormGroup
                                                              style={{
                                                                  fontSize:
                                                                      "0.7rem",
                                                              }}
                                                          >
                                                              <Label className="d-block d-lg-none">
                                                                  Conservantes
                                                              </Label>{" "}
                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                              <FastField
                                                                  name={`items[${index}].exams_preservative_id`}
                                                                  classNamePrefix="select2-selection"
                                                                  defaultValue={
                                                                      item
                                                                          .exams_preservative_id
                                                                          ?.value
                                                                  }
                                                                  component={
                                                                      AsyncSelectInput
                                                                  }
                                                                  isDisabled={
                                                                      true
                                                                  }
                                                              />
                                                          </FormGroup>
                                                      </Col>
                                                  ) : (
                                                      ""
                                                  )}
                                                  {values?.partner_id ? (
                                                      <Col sm="2">
                                                          <Button
                                                              color="danger"
                                                              onClick={() => {
                                                                  arrayHelpers.remove(
                                                                      index
                                                                  )
                                                              }}
                                                          >
                                                              <i className="fas fa-trash-alt fa-lg"></i>
                                                          </Button>

                                                          {(item?.additional_data &&
                                                              item
                                                                  ?.additional_data
                                                                  ?.additionalDataItems
                                                                  ?.length >
                                                                  0) ||
                                                          item?.partner_exam_material_code ==
                                                              "DIV" ? (
                                                              <Button
                                                                  className="ml-3"
                                                                  color={
                                                                      item?.additional_data?.additionalDataItems?.find(
                                                                          e =>
                                                                              e?.informed
                                                                      )
                                                                          ? "success"
                                                                          : "warning"
                                                                  }
                                                                  onClick={() => {
                                                                      if (
                                                                          item?.partner_exam_material_code ==
                                                                          "DIV"
                                                                      ) {
                                                                          let exists = item?.additional_data?.additionalDataItems?.find(
                                                                              e =>
                                                                                  e?.name ==
                                                                                  "MaterialDetail"
                                                                          )
                                                                          if (
                                                                              !exists
                                                                          )
                                                                              item?.additional_data?.additionalDataItems?.push(
                                                                                  {
                                                                                      model:
                                                                                          "free_text",
                                                                                      description:
                                                                                          "Especifique amostra/material",
                                                                                      type:
                                                                                          "string",
                                                                                      name:
                                                                                          "MaterialDetail",
                                                                                      required: true,
                                                                                  }
                                                                              )
                                                                      }
                                                                      setControlModalAdditionalData(
                                                                          {
                                                                              show: true,
                                                                              data:
                                                                                  item.additional_data,
                                                                              setAdditionalData: (
                                                                                  newDataForm,
                                                                                  newDataItems
                                                                              ) => {
                                                                                  setFieldValue(
                                                                                      `items[${index}].additional_data.additionalDataForm`,
                                                                                      newDataForm
                                                                                  )
                                                                                  setFieldValue(
                                                                                      `items[${index}].additional_data.additionalDataItems`,
                                                                                      newDataItems
                                                                                  )
                                                                              },
                                                                              disabled: false,
                                                                          }
                                                                      )
                                                                  }}
                                                              >
                                                                  {item?.additional_data?.additionalDataItems?.find(
                                                                      e =>
                                                                          e?.informed
                                                                  ) ? (
                                                                      <i className="fas fa-clipboard-check fa-lg text-white">
                                                                          {" "}
                                                                      </i>
                                                                  ) : (
                                                                      <i className="fas fa-clipboard fa-lg text-white"></i>
                                                                  )}
                                                              </Button>
                                                          ) : (
                                                              ""
                                                          )}
                                                      </Col>
                                                  ) : (
                                                      ""
                                                  )}
                                              </Row>
                                              <Row className="d-md-none">
                                                  <Col>
                                                      <hr />
                                                  </Col>
                                              </Row>{" "}
                                          </>
                                      ))
                                    : null}
                            </div>
                        )}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default RenderExamRequests
