import React, { useEffect, useState, useMemo, useRef } from "react"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { search as searchItemsComissions } from "services/vitta-core/item-exec-comissions"
import ExecutionComissionsColumns from "pages/ExecutionComissions/ExecutionComissionsColumns"
import ModalExecutionComissions from "pages/ExecutionComissions/ModalExecutionComissions"
import { search as itemServiceSearch } from "services/vitta-core/item"
import debounce from "debounce-promise"
import ITEM_EXEC_COMISSION_TYPES from "utils/consts/item-exec-comission-types"

const listExecutionComissions = () => {
    const [reportList, setReportList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 20,
        custom: true,
        data: [],
        show_execution_filter: true,
    })

    const [modalReportData, setModalReportData] = useState({
        show: false,
        data: null,
    })
    const [modalExecComissionData, setModalExecComissionData] = useState({
        show: false,
        data: null,
    })
    const inputUnit = useRef()
    const inputItem = useRef()
    const inputProfessional = useRef()
    const inputModeExec = useRef()
    const inputTypeComission = useRef()
    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)

    const defaultPageControl = {
        searching: false,
    }
    const ExecModeOption = [
        { value: null, label: "Todos" },
        { value: 2, label: "Online" },
        { value: 1, label: "Presencial" },
    ]

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async (filter, page) => {
        if (pageControl.searching) return

        let sizePerPage = 10

        //Set is searching
        setPageControl({ ...pageControl, ...{ searching: true } })
        //Clear Data Table
        setReportList({
            ...reportList,
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        try {
            // console.log("filter",filter)
            // filter?.unit_id? filter?.unit_id : filter.unit_id = Scope?.unit?.id;

            let record = await searchItemsComissions(filter, page, sizePerPage)

            setReportList({
                ...reportList,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: record.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    useEffect(() => {
        searchOptions(1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page }) => {
        if (type == "pagination") {
            searchOptions(page)
        }
    }

    const showModalExeComission = data => {
        // console.log("data",data)

        let service_stages = []

        if (data?.service_stages?.length > 0) {
            service_stages = data?.service_stages?.map(e => {
                return {
                    label: e?.stages?.name,
                    value: e?.stages?.id,
                    config: e,
                }
            })

            service_stages.push({
                label: "Todas as fases",
                value: null,
            })
        } else {
            service_stages = [
                {
                    label: "Sem fase",
                    value: null,
                },
            ]
        }

        let dataEdit = {
            rowComissionId: data?.id,
            item_id: {
                label: data?.item?.name,
                value: data?.item?.id,
                service_stages: service_stages,
            },
            unit:
                data?.unit != null
                    ? { label: data?.unit?.name, value: data?.unit?.id }
                    : { label: "Todas Unidades", value: null },
            comission_fix: data?.comission_fix / 100,
            comission_percent: data?.comission_percent / 100,
            price: data?.item?.price_table_item?.price / 100,
            execution_mode: data?.execution_mode,
            professional_exec_id:
                data?.professional_exec != null
                    ? {
                          label: data?.professional_exec?.name,
                          value: data?.professional_exec?.id,
                      }
                    : { label: "Todos Profissionais", value: null },
            stage_id: data?.stage_id,
            comission_type: data?.comission_type,
        }

        setModalExecComissionData({
            show: true,
            data: { unitsOptions: unitsOptions, dataEdit: dataEdit },
        })
    }

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = [{ label: "Todas Unidades", value: null }]
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await itemServiceSearch(
                    {
                        term: inputValue,
                        unit_id: Scope?.unit?.id,
                        status: statusActive,
                    },
                    1,
                    20
                )

                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceItems = debounce(loadItemsInputOptions, 600)
    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Todos Profissionais",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )
    const searchOptions = (page = 1) => {
        let searchObj = {}
        let unit_id = inputUnit?.current?.state?.value?.value
        let item_id = inputItem.current.select?.state?.value?.value
        let professional_id =
            inputProfessional.current.select?.state?.value?.value
        if (unit_id) searchObj.unit_id = unit_id
        if (item_id) searchObj.item_id = item_id
        if (professional_id) searchObj.professional_id = professional_id
        searchObj.execution_mode = inputModeExec?.current?.state?.value?.value
        searchObj.comission_type = inputTypeComission?.current?.state?.value?.value

        if(searchObj.comission_type == ITEM_EXEC_COMISSION_TYPES.INDICATION)
        {
            searchObj.execution_mode = null;
        }
        
        search(searchObj, page)
    }

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Comissões de Execução"
                        breadcrumbItem="Lista de comissões"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="12" align="start" className="mb-4">
                                            <Button
                                                className="btn"
                                                color="primary"
                                                onClick={() => {
                                                    setModalExecComissionData({
                                                        show: true,
                                                        data: {
                                                            unitsOptions: unitsOptions,
                                                            scope: Scope,
                                                        },
                                                    })
                                                }}
                                            >
                                             <i className="fas fa-plus"></i>   Adicionar
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="2" lg="3" md="4" sm="12">
                                            <Label>Unidade</Label>
                                            <Select
                                                name={`unit_id`}
                                                placeholder="Selecione a Unidade"
                                                defaultValue={{
                                                    label: Scope?.unit?.name,
                                                    value: Scope?.unit?.id,
                                                }}
                                                classNamePrefix="select2-selection"
                                                options={unitsOptions}
                                                isMulti={false}
                                                isClearable
                                                ref={inputUnit}
                                            />
                                        </Col>
                                        <Col xl="3" lg="4" md="6" sm="12">
                                            <Label>Profissional</Label>
                                            <AsyncSelect
                                                name="profissional_id"
                                                placeholder="Selecione o profissional"
                                                classNamePrefix="select2-selection"
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={
                                                    debounceOptionsProfessional
                                                }
                                                noOptionsMessage={() =>
                                                    "Sem Resultados. Digite para Buscar"
                                                }
                                                ref={inputProfessional}
                                            />
                                        </Col>
                                        <Col xl="3" lg="4" md="6" sm="12">
                                            <Label>Serviço</Label>
                                            <AsyncSelect
                                                name="item_id"
                                                placeholder="Selecione o Serviço"
                                                classNamePrefix="select2-selection"
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={debounceItems}
                                                noOptionsMessage={() =>
                                                    "Sem Resultados. Digite para Buscar"
                                                }
                                                ref={inputItem}
                                            />
                                        </Col>
                                        <Col xl="2" lg="3" md="4" sm="12">
                                            <Label>Tipo de comissão</Label>
                                            <Select
                                                name={`comission_type`}
                                                defaultValue={{
                                                    label: "Execução",
                                                    value:
                                                        ITEM_EXEC_COMISSION_TYPES.EXECUTION,
                                                }}
                                                classNamePrefix="select2-selection"
                                                options={[
                                                    {
                                                        label: "Execução",
                                                        value:
                                                            ITEM_EXEC_COMISSION_TYPES.EXECUTION,
                                                    },
                                                    {
                                                        label: "Indicação",
                                                        value:
                                                            ITEM_EXEC_COMISSION_TYPES.INDICATION,
                                                    },
                                                ]}
                                                ref={inputTypeComission}
                                                onChange={e => {
                                                    let state = {
                                                        ...reportList,
                                                    }
                                                    state.show_execution_filter =
                                                        e?.value ==
                                                        ITEM_EXEC_COMISSION_TYPES?.EXECUTION
                                                            ? true
                                                            : false
                                                    setReportList(state)
                                                }}
                                            />
                                        </Col>
                                        {reportList?.show_execution_filter ? (
                                            <Col xl="2" lg="3" md="4" sm="12">
                                                <Label>Execução</Label>
                                                <Select
                                                    name={`execution_mode`}
                                                    defaultValue={
                                                        ExecModeOption[2]
                                                    }
                                                    classNamePrefix="select2-selection"
                                                    options={ExecModeOption}
                                                    ref={inputModeExec}
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xl="2" lg="3" md="4" sm="12">
                                            <div>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    color="primary"
                                                    onClick={() =>
                                                        searchOptions()
                                                    }
                                                >
                                                  <i className="fas fa-search"></i>  Buscar
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            reportList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={reportList.data || []}
                                                columns={ExecutionComissionsColumns(
                                                    showModalExeComission
                                                )}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2"></Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                        <ModalExecutionComissions
                            show={modalExecComissionData.show}
                            data={modalExecComissionData.data}
                            execModeOption={ExecModeOption}
                            onClose={response => {
                                setModalExecComissionData({
                                    show: false,
                                    data: null,
                                })
                                if (response?.register)
                                    searchOptions(reportList.page)
                            }}
                        ></ModalExecutionComissions>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default listExecutionComissions
