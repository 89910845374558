import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

/**
 * Modal de Cancelamento de Pagamento.
 * Exibe uma interface para que o usuário possa cancelar um pagamento, inserindo um motivo e confirmando a ação.
 * @param {Object} props - Propriedades do componente.
 * @param {boolean} props.isOpen - Indica se o modal está aberto ou fechado.
 * @param {Function} props.onClose - Função chamada para fechar o modal.
 * @param {Function} props.onConfirm - Função chamada ao confirmar o cancelamento do pagamento.
 * @param {string} [props.confirmButtonText="Sim"] - Texto opcional do botão de confirmação.
 * @param {string} [props.cancelButtonText="Não"] - Texto opcional do botão de cancelamento.
 * @returns {JSX.Element} - Elemento JSX do modal de cancelamento.
 */
const PaymentCancellationModal = ({ isOpen, onClose, onConfirm, confirmButtonText = "Sim", cancelButtonText = "Não" }) => {
    // Estado para armazenar o motivo do cancelamento
    const [cancellationReason, setCancellationReason] = useState("");

    // habilita/desabilita o botão de confirmação
    const minLength = 5;
    const isConfirmDisabled = cancellationReason.length < minLength;

    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalHeader toggle={onClose}>Cancelar Pagamento</ModalHeader>
            <ModalBody>
                <h5 className="modal-title my-2">
                    Insira o motivo do cancelamento 
                    <span style={{ color: 'red', transition: 'opacity 0.1s', opacity: isConfirmDisabled ? 1 : 0 }}>*</span>
                </h5>
                <textarea
                    value={cancellationReason}
                    onChange={(e) => setCancellationReason(e.target.value)}
                    placeholder="Digite a razão do cancelamento"
                    className="form-control"
                    rows="7"
                    style={{ fontSize: "16px" }}
                />
                <div style={{ height: '20px' }}>
                    <p style={{ color: 'red', marginTop: '10px', fontSize: '12px', transition: 'opacity 0.2s', opacity: isConfirmDisabled ? 1 : 0 }}>
                        O motivo do cancelamento deve ter pelo menos 5 caracteres.
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-sm btn-danger waves-effect" onClick={onClose}>
                    {cancelButtonText}
                </Button>
                <Button
                    className="btn btn-sm btn-info waves-effect"
                    onClick={() => onConfirm(cancellationReason)}
                    disabled={isConfirmDisabled}
                >
                    {confirmButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PaymentCancellationModal;