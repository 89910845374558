import axios from 'axios';

const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_VITTA_TEF_HOST
});

async function request(axiosConfig){

    if(!axiosConfig) return null;

    if(!axiosConfig.headers)
    {
        axiosConfig.headers = defaultHeaders;
    }
    else
    {
        if(!axiosConfig.headers["Content-Type"]) axiosConfig.headers["Content-Type"] = defaultHeaders['Content-Type'];
        if(!axiosConfig.headers["Accept"]) axiosConfig.headers["Accept"] = defaultHeaders['Accept'];
    }

    let data = await instance.request(axiosConfig);

    if(axiosConfig.returnFullResponse) return data;

    return data.data;
}

export default request;