import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Badge,
    Input, 
    Label,
    UncontrolledTooltip
} from "reactstrap"

import SALES_DOCS_STATUS from "utils/consts/sales-invoices-status"
import SALES_INVOICES_STATUS from "utils/consts/sales-invoices-status"

const InvoicesColumns = (CancelInvoice) => {
    return [
    {
        isDummyField: true,
        text: "ID Nota",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "created_at",
        text: "Data Nota",
        formatter: (cellContent, row) =>
            new Date(row.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"})
    },
    {
        isDummyField: true,
        text: "Total",
        formatter: (id, row) => {
            let total = 0;
            for(let i = 0; i < row?.items?.length; i++)
            {
                let useItem = row.items[i];
                total += useItem.quantity * (useItem.price ? useItem.price : 0)
            }

            return "R$ " + new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(total/100)
        },
    },
    {
        dataField: "patient.name",
        text: "Paciente",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            let text = ""
            let badgeClass = "badge-soft-success"
            switch (cellContent) {
                
                case SALES_DOCS_STATUS.PAID:
                    text = "Pago"
                    badgeClass = "badge-soft-success"
                    break
                case SALES_DOCS_STATUS.ISSUED:
                    text = "Notas Fiscais Emitidas"
                    badgeClass = "badge-soft-primary"
                    break
                case SALES_DOCS_STATUS.CANCELED:
                    text = "Cancelado"
                    badgeClass = "badge-soft-danger"
                    break
            }

            return (
                <Badge className={"font-size-12 " + badgeClass} pill>
                    {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
               
                {  row?.status === SALES_INVOICES_STATUS.CANCELED ? "" : <Link
                    to={"/pedidos/"+row?.sales_order_id}
                    className="mr-3  text-primary "
                  
                >
                    <i className="fas fa-external-link-alt font-size-22" id={"invoicetooltip"+row.id}></i>
                    <UncontrolledTooltip placement="top" target={"invoicetooltip"+row.id}>
                      Ver pedido
                    </UncontrolledTooltip>
                </Link> }
                {
                    row?.status == SALES_INVOICES_STATUS.PAID ?   <Link
                    to="#"
                    className="mr-3 text-danger"
                    onClick={()=>{CancelInvoice(row)}}
                >
                     <i className="fas fa-times-circle font-size-22 dark" id={"cancelinvoicetooltip"+row.id}></i>
                    <UncontrolledTooltip placement="top" target={"cancelinvoicetooltip"+row.id}>
                        Cancelar Nota
                    </UncontrolledTooltip>
                           
                </Link>
                : null
                }
              
            </>
        )
    }
]
}

export default InvoicesColumns
