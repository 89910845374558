import React, { useEffect, useState, useMemo } from "react"
import { useSelector } from "react-redux"
import debounce from "debounce-promise"
import {
    Col,
    Row,
    Input,
    Label,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Badge,
    UncontrolledTooltip,
} from "reactstrap"

import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { search as partnerServiceSearch } from "services/vitta-core/partner"
import { getItems } from "services/vitta-core/partner"
import { Formik, Field, Form, FastField } from "formik"
import { create } from "services/vitta-core/orders-exams"
import {
    cancelExamsOrder,
    cancelExamsOrderItem,
    consultExamsOrder,
    syncExamOrder,
} from "services/vitta-core/orders-exams"
import { getExamOrderResults } from "services/vitta-core/orders-exams"
import { getExamOrderItemResult } from "services/vitta-core/orders-exams"
import EXAMS_ORDER_STATUS from "utils/consts/exams-orders-status"
import ModalAdditionalData from "./ModalAdditionalData/ModalAdditionalData"
import RenderExamRequests from "./RenderExamRequests"
import ShowExamRequests from "./ShowExamRequests"
import AsyncSelect from "react-select/async"
import EXAMS_INTEGRATION_PARTNERS_STATUS from "utils/consts/exams-integration-partners-status"
import ModalInfoPartnerObs from "./ModalInfoPartnerObs"
import ModalInfoPartnerStatus from "./ModalInfoPartnerStatus"
import ModalPrintChoice from "./ModalPrintChoice/ModalPrintChoice"
import { printLabelByBrowserPrint,printLabelByPrinterProxy,getConfigBrowserPrinter } from "services/vitta-core/printers"
const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        cacheOptions
        defaultOptions
        classNamePrefix="select2-selection"
        onChange={async option => {
            try {
                if (field?.name == "partner_id") {
                    let item_ids = []
                    for (
                        let j = 0;
                        j < props?.valuesProps?.items?.length;
                        j++
                    ) {
                        if (
                            process?.env?.REACT_APP_ITEM_EXAM_LABORATORY_GROUP_IDS?.includes(
                                props?.valuesProps?.items[j]?.group_id
                            )
                        )
                            if (
                                !item_ids.includes(
                                    props?.valuesProps?.items[j]?.item_id?.value
                                )
                            ) {
                                item_ids.push(
                                    props?.valuesProps?.items[j]?.item_id?.value
                                )
                            }
                    }

                    let result = await getItems(
                        {
                            partner_id: option?.value,
                            item_id: item_ids,
                        },
                        1,
                        300
                    )

                    let arrayFinally = [...props?.valuesProps?.items]

                    if (result && result?.data?.length > 0) {
                        for (let j = 0; j < arrayFinally?.length; j++) {
                            let use = arrayFinally[j]
                            let found = false
                            let objFinally = {}

                            for (let i = 0; i < result?.data?.length; i++) {
                                let useDb = result?.data[i]

                                if (use?.item_id?.value == useDb?.item_id) {
                                    objFinally.partner_item_code = {
                                        label:
                                            useDb?.partner_item_code +
                                            " - " +
                                            useDb?.partner_item_name,
                                        value: useDb?.partner_item_code,
                                    }
                                    if (useDb?.material) {
                                        objFinally.exams_material_id = {
                                            label:
                                                useDb?.exams_material_id +
                                                " - " +
                                                useDb?.material?.name,
                                            value: useDb?.exams_material_id,
                                        }
                                        objFinally.partner_exam_material_code =
                                            useDb?.material?.exams_material?.partner_exam_material_code
                                    }

                                    if (useDb?.preservative) {
                                        objFinally.exams_preservative_id = {
                                            label:
                                                useDb?.exams_preservative_id +
                                                " - " +
                                                useDb?.preservative?.name,
                                            value: useDb?.exams_preservative_id,
                                        }
                                    } else {
                                        objFinally.exams_preservative_id = {}
                                    }
                                    let additionalDataItems = []
                                    if (
                                        useDb?.additional_data &&
                                        JSON.parse(useDb?.additional_data)
                                            .length > 0
                                    ) {
                                        additionalDataItems = JSON.parse(
                                            useDb?.additional_data
                                        )
                                    }
                                    if (
                                        useDb?.material?.exams_material
                                            ?.partner_exam_material_code ==
                                        "DIV"
                                    ) {
                                        additionalDataItems.push({
                                            model: "free_text",
                                            description: "Especifique amostra/material",
                                            type: "string",
                                            name: "MaterialDetail",
                                            required: true,
                                        })
                                    }

                                    objFinally.item_id = use?.item_id
                                    objFinally.quantity = use?.quantity
                                    objFinally.sales_order_item_id = use?.id
                                    objFinally.additional_data = {
                                        additionalDataForm: null,
                                        additionalDataItems: additionalDataItems,
                                    }

                                    found = true
                                    break
                                }
                            }

                            if (found) {
                                arrayFinally[j] = objFinally
                                arrayFinally[j].indexArr = j
                            }
                        }
                    }

                    if (arrayFinally && arrayFinally.length > 0) {
                        let obj = { ...props?.valuesProps }
                        obj.items = arrayFinally
                        obj.partner_id = option

                        props?.setPageData(obj)
                    }
                }
            } catch (e) {
                console.log(e)
            }
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
    />
)

const defaultValues = {
    patient_id: "",
    partner_id: null,
    cod_order_exam_vitta: "",
    items: [],
}
const IndexModalExamRequests = ({
    data,
    renderRecord,
    setControlSearch,
    onClose,
}) => {
    const [pageData, setPageData] = useState(defaultValues)
    const [useData, setUseData] = useState(defaultValues)
    const [showLoading, setShowLoading] = useState(false)
    const [
        controlModalAdditionalData,
        setControlModalAdditionalData,
    ] = useState({
        show: false,
        data: null,
        setAdditionalData: null,
        disabled: false,
    })
    const [
        controlModalInfoPartnerObs,
        setControlModalInfoPartnerObs,
    ] = useState({
        show: false,
        data: null,
        type: null,
    })
    const [controlModalPrintChoice, setControlModalPrintChoice] = useState({
        show: false,
        data: null,
    })
    const [
        controlModalInfoPartnerStatus,
        setControlModalInfoPartnerStatus,
    ] = useState({
        show: false,
        data: null,
    })
    const User = useSelector(state => state.auth.access.user)

    useEffect(() => {
        let itemsExam = []

        if (data?.items && data?.items?.length > 0) {
            for (let i = 0; i < data?.items?.length > 0; i++) {
                let item = data?.items[i]
                if (!item?.item_id?.item?.group_id) {
                    continue
                }

                if (
                    process?.env?.REACT_APP_ITEM_EXAM_LABORATORY_GROUP_IDS?.includes(
                        item?.item_id?.item?.group_id
                    )
                ) {
                    //duplicar items caso a quantidade for maior que 1.
                    if (item?.quantity > 1) {
                        for (let i = 0; i < item?.quantity; i++) {
                            itemsExam.push({
                                item_id: {
                                    label: item?.item_id?.label,
                                    value: item?.item_id?.value,
                                },
                                group_id: item?.item_id?.item?.group_id,
                                quantity: 1,
                                additional_data: item?.additional_data,
                                print_bar_code_command:
                                    item?.print_bar_code_command,
                                partner_status_id: item?.partner_status_id,
                                partner_status: item?.partner_status,
                                partner_obs: item?.partner_obs,
                            })
                        }
                    } else {
                        itemsExam.push({
                            id: item?.id,
                            item_id: {
                                label: item?.item_id?.label,
                                value: item?.item_id?.value,
                            },
                            status: item?.status,
                            group_id: item?.item_id?.item?.group_id,
                            quantity: item?.quantity,
                            additional_data: item?.additional_data,
                            print_bar_code_command:
                                item?.print_bar_code_command,
                            partner_status_id: item?.partner_status_id,
                            partner_status: item?.partner_status,
                            partner_obs: item?.partner_obs,
                        })
                    }
                }
            }
            let val = { ...pageData }
            if (data?.partner) {
                val.partner_id = data?.partner
                val.partner_order_id = data?.partner_order_id
            }
            val.patient_id = data?.patient?.label
            val.items = itemsExam

            if (data?.id) {
                handleView(val)
            } else {
                setPageData(val)
                setUseData(val)
            }
        }
    }, [data])

    const handleView = async data => {
        try {
            let item_ids = []
            for (let j = 0; j < data?.items?.length; j++) {
                if (!item_ids.includes(data?.items[j]?.item_id?.value)) {
                    item_ids.push(data?.items[j]?.item_id?.value)
                }
            }

            let result = await getItems(
                {
                    partner_id: data?.partner_id?.value,
                    item_id: item_ids,
                },
                1,
                200
            )

            let arrayFinally = [...data?.items]

            if (result && result?.data?.length > 0) {
                for (let j = 0; j < arrayFinally?.length; j++) {
                    let use = arrayFinally[j]
                    let found = false
                    let objFinally = {}
                    let objMaterial = {}
                    let objPreservative = {}
                    let partner_exam_material_code = null
                    let additional_data = null
                    for (let i = 0; i < result?.data?.length; i++) {
                        let useDb = result?.data[i]

                        if (use?.item_id?.value == useDb?.item_id) {
                            found = true
                            if (use?.additional_data) {
                                additional_data = {
                                    additionalDataForm: null,
                                    additionalDataItems: JSON.parse(
                                        use?.additional_data
                                    ),
                                }
                            }
                            objFinally = {
                                label:
                                    useDb?.partner_item_code +
                                    " - " +
                                    useDb?.partner_item_name,
                                value: useDb?.partner_item_code,
                            }
                            objMaterial = {
                                label:
                                    useDb?.exams_material_id +
                                    " - " +
                                    useDb?.material?.name,
                                value: useDb?.material?.id,
                            }
                            partner_exam_material_code =
                                useDb?.material?.exams_material
                                    ?.partner_exam_material_code
                            if (useDb?.preservative) {
                                objPreservative = {
                                    label:
                                        useDb?.exams_preservative_id +
                                        " - " +
                                        useDb?.preservative?.name,
                                    value: useDb?.id,
                                }
                            }
                            break
                        }
                    }

                    if (found) {
                        arrayFinally[j].partner_item_code = objFinally
                        arrayFinally[j].exams_material_id = objMaterial
                        arrayFinally[j].exams_preservative_id = objPreservative
                        arrayFinally[j].status = use.status
                        arrayFinally[
                            j
                        ].partner_exam_material_code = partner_exam_material_code
                        arrayFinally[j].exam_order_item_id = use?.id
                        arrayFinally[j].additional_data = additional_data
                    }
                }
            }

            if (arrayFinally && arrayFinally.length > 0) {
                let obj = { ...data }
                obj.items = arrayFinally

                setPageData(obj)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadPartnerInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await partnerServiceSearch(
                    { term: inputValue, status: 1 },
                    1,
                    20
                )

                let partner_values = []
                response.data.forEach(element => {
                    partner_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(partner_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPartner = debounce(loadPartnerInputOptions, 600)

    const dispatch = useDispatch()
    const confirmationSubmit = async values => {
        if (!values?.partner_id) {
            dispatch(
                showSweetAlertDialog({
                    title: "Preencha o campo laboratório!",
                    type: "warning",
                    showConfirm: true,
                })
            )
            return
        }
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja criar uma nova solicitação de exame?",
                type: "warning",
                onConfirmAction: () => {
                    handleCreateExamRequest(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const handleCreateExamRequest = async values => {
        try {
            setShowLoading(true)
            if (!values?.items || values?.items?.length <= 0) {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "É necessário ao menos um item para gerar um pedido de exame!",
                        type: "warning",
                        showConfirm: true,
                    })
                )
                setShowLoading(false)
                return
            }
            let items = JSON.parse(JSON.stringify(values?.items))

            for (let i = 0; i < items.length; i++) {
                let material_detail = null
                let item = items[i]
                let additionalDataFormatted = []
                let additionalDataNotFilled = item?.additional_data?.additionalDataItems?.find(
                    e => !e?.informed
                )
                if (additionalDataNotFilled) {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                "É necessário preencher as informações adicionais!",
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    setShowLoading(false)
                    return
                }
                for (
                    let j = 0;
                    j < item?.additional_data?.additionalDataItems?.length;
                    j++
                ) {
                    let useAdditionalItem =
                        item?.additional_data?.additionalDataItems[j]
                    if (useAdditionalItem?.name == "MaterialDetail") {
                        material_detail = useAdditionalItem?.informed?.value
                    }

                    let additionalItemFormatted = {
                        type: useAdditionalItem?.model,
                        name: useAdditionalItem?.informed?.name,
                        value: useAdditionalItem?.informed?.value,
                        description: useAdditionalItem?.label,
                    }
                    if (useAdditionalItem?.model == "select_options") {
                        additionalItemFormatted.select_label_value =
                            useAdditionalItem?.informed?.objectData?.label
                    }
                    additionalDataFormatted.push(additionalItemFormatted)
                }
                item.additional_data = additionalDataFormatted
                item.material_detail = material_detail
            }

            let dataSubmit = {
                patient_id: data?.patient?.value,
                unit_id: data?.unit_id?.value,
                sales_order_id: data?.sales_order_id,
                partner_id: values?.partner_id?.value,
                request_professional_id: data?.request_professional_id,
                request_professional_name: data?.request_professional_name,
                items: items,
            }

            let result = await create(dataSubmit)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    type: "success",
                    showConfirm: true,
                })
            )
            let response = await renderRecord(result?.id)
            setShowLoading(false)
            let dataModal = { ...response }
            //pré definições de impressão
            setControlModalPrintChoice({
                show: true,
                data: dataModal,
            })
        } catch (e) {
            setShowLoading(false)
            console.log(e)
            if (e?.response?.data?.error_code == "not_found_item_partner") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ação negada!",
                        text:
                            "Para criar uma solicitação de exame, é necessário que todos os itens do parceiro também estejam vinculados aos itens do VittáNet.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "not_found_material") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ação negada!",
                        text:
                            "Para criar uma solicitação de exame, é necessário que todos os itens do parceiro também possuam um material.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "not_bond_material_partner") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ação negada!",
                        text:
                            "Para criar uma solicitação de exame, é necessário o vínculo de materiais com o parceiro.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "data_patient_not_valid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Cadastro do Paciente incompleto!",
                        text:
                            "Para criar uma solicitação de exame, é necessário que os campos Nome, CPF, Data de Nascimento, Gênero(Sexo) estejam preenchidos...!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "could_not_create") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Houve um erro na comunicação com o parceiro!",
                        text:
                            "Código de erro: " +
                            e?.response?.data?.failure_code +
                            ". Entre em contato com o suporte técnico da vittá.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "material_detail_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Informação complementar inválida!",
                        text:"O campo 'Especifique amostra/material', pode ter no máximo 40 caracteres.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "no_access_integration") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:"Unidade não disponível para integração com o Laboratório.",
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const handleConsultExamsOrder = async () => {
        try {
            setShowLoading(true)
            let result = null
            if (data?.id) {
                result = await consultExamsOrder(data?.id)
                if (result?.hasResult) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "Foram encontrados resultados de exames!",
                            type: "success",
                            showConfirm: true,
                        })
                    )
                } else {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                "Os resultados de exames ainda não foram disponibilizados!",
                            type: "info",
                            showConfirm: true,
                        })
                    )
                }

                await renderRecord(data?.id)
            }
            setShowLoading(false)
        } catch (e) {
            console.log(e)
            setShowLoading(false)
        }
    }

    const confirmationCancelExamOrder = async values => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja cancelar esse pedido?",
                type: "warning",
                onConfirmAction: () => {
                    handleCancelExamOrder()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const handleCancelExamOrder = async () => {
        try {
            setShowLoading(true)
            let response = null
            if (data?.id) {
                response = await cancelExamsOrder(data?.id)
                if (response.resultError) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "ops...!",
                            text:
                                "Não foi possível cancelar este Pedido de Exame.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                    setShowLoading(false)
                    return
                } else {
                    setShowLoading(false)
                    dispatch(
                        showToast({
                            title: "Pedido de Exame Cancelado!",
                            type: "success",
                            showConfirm: true,
                        })
                    )
                }

                await renderRecord(data?.id)
                setShowLoading(false)
                setControlSearch(true)
            }
        } catch (e) {
            console.log(e)
            setShowLoading(false)
            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const confirmationCancelExamOrderItem = async values => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja cancelar esse item do Pedido de exame?",
                type: "warning",
                onConfirmAction: () => {
                    handleCancelExamOrderItem(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleCancelExamOrderItem = async values => {
        try {
            setShowLoading(true)
            let response = await cancelExamsOrderItem(data?.id, values?.id)
            if (response.resultError) {
                dispatch(
                    showSweetAlertDialog({
                        title: "ops...!",
                        text: "Não foi possível cancelar este exame.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                setShowLoading(false)
                return
            } else {
                dispatch(
                    showToast({
                        title: "Item Cancelado!",
                        type: "success",
                        showConfirm: true,
                    })
                )
            }
            await renderRecord(data?.id)
            setControlSearch(true)
            setShowLoading(false)
        } catch (e) {
            console.log(e)
            setShowLoading(false)

            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const renderStatusBadge = status => {
        let text = ""
        let badgeClass = ""
        switch (status) {
            case EXAMS_ORDER_STATUS.CANCELED:
                text = "Cancelado"
                badgeClass = "badge-soft-danger"
                break
            case EXAMS_ORDER_STATUS.CONCLUDED:
                text = "Concluído"
                badgeClass = "badge-soft-success"
                break
            case EXAMS_ORDER_STATUS.IN_PROGRESS:
                text = "Em Andamento"
                badgeClass = "badge-soft-info"
                break
            case EXAMS_ORDER_STATUS.REJECTED:
                text = "Rejeitado"
                badgeClass = "badge-soft-warning"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const renderStatusIntegrationBadge = status => {
        let text = ""
        let badgeClass = ""
        switch (status) {
            case EXAMS_INTEGRATION_PARTNERS_STATUS.INTEGRATED:
                text = "Integrado"
                badgeClass = "badge-soft-success"
                break
            case EXAMS_INTEGRATION_PARTNERS_STATUS.ERROR_INTEGRATION:
                text = "Erro integração"
                badgeClass = "badge-soft-danger"
                break
            case EXAMS_INTEGRATION_PARTNERS_STATUS.AWAITING_INTEGRATION:
                text = "Aguardando integração"
                badgeClass = "badge-soft-info"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const handleDownloadExamOrder = async () => {
        let response = await getExamOrderResults(data?.id)
        if (response) {
            window.open(response.url, "_blank")
        }
    }
    const handleDownloadExamOrderItem = async values => {
        let response = await getExamOrderItemResult(data?.id, values?.id)
        if (response) {
            window.open(response.url, "_blank")
        }
    }
    // const handlePrintCodeBar = values => {
    //     try {
    //         const axios = require("axios")
    //         const qs = require("qs")
    //         let data = qs.stringify({
    //             documentData: `\nN\nR30,10\nD8\nA210,0,0,1,1,1,N,\"PACIENTE ACACIO TESTE TESTE\"\nB285,65,0,2,2,4,70,N,\"202522094701\"\nA210,20,0,1,1,1,N,\"202-5220947-01  29/03/23 10:38\"\nA210,35,0,1,1,1,N,\"CONV: 12000\"\nA330,35,0,1,1,1,N,\"PED.CONV: 1937396\"\nA210,50,0,1,1,1,N,\"LINHA VERDE\"\nA420,50,0,1,1,1,N,\"DN 10/04/2021\"\nA195,220,3,1,1,1,R,\"   NTO\"\nA210,143,0,1,1,1,N,\"SORO\"\nA210,158,0,1,1,1,N,\"\"\nA210,173,0,1,1,1,N,\"VOL: 500ul\"\nA450,173,0,1,1,1,N,\"(REFRIG.)\"\nA210,188,0,1,1,1,N,\"COV-LC\"\nA210,206,0,1,1,1,N,\"\"\nP1\n\nN\n`,
    //         })

    //         let config = {
    //             method: "post",
    //             maxBodyLength: Infinity,
    //             url:
    //                 "http://10.1.1.95:8080/gti-printer-proxy/api/printer/print",
    //             headers: {
    //                 "Content-Type": "application/x-www-form-urlencoded",
    //             },
    //             data: data,
    //         }

    //         axios
    //             .request(config)
    //             .then(response => {
    //                 console.log(JSON.stringify(response.data))
    //             })
    //             .catch(error => {
    //                 console.log(error)
    //             })

    //     } catch (e) {
    //         console.log(e)
    //     }
    // }
    const getPrintBarCode = recipients => {
        if (!recipients) return []
        let dataPrint = []
        try {
            let dataParse = JSON.parse(recipients)
            if (dataParse?.length > 0) {
                for (let i = 0; i < dataParse.length; i++) {
                    let print_bar_code_command =
                        dataParse[i]?.print_bar_code_command
                    dataPrint.push(print_bar_code_command)
                }
            }
        } catch (e) {
            console.log(e)
        }
        return dataPrint
    }
    const confirmBulkPrintLabels = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja imprimir todas as etiquetas?",
                type: "warning",
                onConfirmAction: () => {
                    let dataPrintBarCode = getPrintBarCode(data?.recipients)
                    bulkPrintLabels(dataPrintBarCode)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const bulkPrintLabels = async command_labels => {
            for (let i = 0; i < command_labels?.length; i++) {
                let command = command_labels[i]
                if(command) await printLabel(command);
            }
    }

    const printLabel = async (command_label) => {
       try
       {
            let getConfigPrinter = await getConfigBrowserPrinter();
            if(getConfigPrinter && getConfigPrinter.uid)
            {
                await printLabelByBrowserPrint(command_label,getConfigPrinter);
            }
            else
            {
                await printLabelByPrinterProxy(command_label);
            }
       }
       catch(e)
       {
       // console.log("no printer config",e);
        await printLabelByPrinterProxy(command_label);
       }
    }
   

    const handleSyncExamOrder = async id => {
        try {
            if (!id) return
            await syncExamOrder(id)
            await renderRecord(id)
            dispatch(
                showToast({
                    title: "Pedido sincronizado!",
                    type: "success",
                    showConfirm: true,
                })
            )
        } catch (e) {
            console.log(e)
            if (e?.response?.status == "404") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops...!",
                        text:
                            "Não conseguimos fazer a comunicação com o serviço externo, tente novamente mais tarde...",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const hasPermissionToCancelExamsOders = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "cancel_exams_orders") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])

    return (
        <React.Fragment>
            <Formik
                initialValues={pageData}
                enableReinitialize={true}
                onSubmit={confirmationSubmit}
            >
                {({
                    values,
                    isSubmitting,
                    dirty,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        {showLoading ? (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    zIndex: "3000",
                                }}
                                className="spinner-border text-info"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}

                        {data?.id ? (
                            <Row className="mb-2">
                                <Col
                                    xs="6"
                                    align="start"
                                    className="d-md-none mt-2"
                                >
                                    <Label>Status</Label>{" "}
                                    {renderStatusBadge(data?.status)}
                                </Col>
                                <Col xs="6" align="end" className="d-md-none">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="card-drop"
                                            tag="i"
                                            dir
                                        >
                                            <i className="bx bx-list-ul fa-2x" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleConsultExamsOrder()
                                                }}
                                                disabled={
                                                    data?.status ==
                                                        EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                    !showLoading
                                                        ? false
                                                        : true
                                                }
                                            >
                                                <i className="fas fa-eye text-primary mr-1" />
                                                Consultar Resultados Lab.
                                            </DropdownItem>

                                            <DropdownItem
                                                disabled={
                                                    data?.status ==
                                                        EXAMS_ORDER_STATUS.CANCELED ||
                                                    data?.status ==
                                                        EXAMS_ORDER_STATUS.REJECTED
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    confirmBulkPrintLabels()
                                                }}
                                            >
                                                <i className="fas fa-print mr-1"></i>
                                                Imprimir Etiquetas
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={
                                                    data?.status ==
                                                    EXAMS_ORDER_STATUS.CONCLUDED
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    handleDownloadExamOrder()
                                                }}
                                            >
                                                <i className="fas fa-file mr-1"></i>
                                                Ver Resultados
                                            </DropdownItem>
                                            {hasPermissionToCancelExamsOders ? (
                                                <DropdownItem
                                                    onClick={() => {
                                                        confirmationCancelExamOrder()
                                                    }}
                                                    disabled={
                                                        data?.status ==
                                                            EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                        !showLoading
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <i className="fas fa-ban text-danger mr-1" />
                                                    Cancelar pedido
                                                </DropdownItem>
                                            ) : (
                                                ""
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                        <Row>
                            <Col xs="12" md="6" className="mt-1">
                                <Label>
                                    Paciente{" "}
                                    <Link
                                        to={
                                            "/pacientes/" +
                                            data?.patient?.value +
                                            "/dados"
                                        }
                                        target="_blank"
                                    >
                                        <i
                                            className="fas fa-user ml-1"
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                color: "#0894d4",
                                            }}
                                            id="patientinput"
                                        ></i>{" "}
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={"patientinput"}
                                            style={{
                                                backgroundColor: "#0894d4",
                                            }}
                                        >
                                            Cadastro do paciente
                                        </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <FastField
                                    name={`patient_id`}
                                    component={TextInput}
                                    readOnly={true}
                                    disabled={true}
                                    isDisabled={true}
                                    style={{
                                        backgroundColor: "#f2f2f2",
                                        color: "#adadad",
                                    }}
                                />
                            </Col>
                            <Col xs="12" md="5" className="mt-1">
                                <Label>
                                    Laboratório{" "}
                                    {!data?.id ? (
                                        <small className="text-danger">
                                            <strong>(Obrigatório)</strong>
                                        </small>
                                    ) : (
                                        ""
                                    )}
                                </Label>
                                <Field
                                    name="partner_id"
                                    placeholder="Buscar laboratório..."
                                    component={AsyncSelectInput}
                                    loadOptions={debounceOptionsPartner}
                                    setPageData={setPageData}
                                    valuesProps={useData}
                                    isDisabled={data?.id ? true : false}
                                />
                            </Col>
                            {data?.id ? (
                                <Col
                                    xs="12"
                                    md="1"
                                    align="center"
                                    className="d-none d-md-block mt-4"
                                >
                                    <UncontrolledDropdown className="mt-2">
                                        <DropdownToggle
                                            className="card-drop"
                                            tag="i"
                                            dir
                                            style={{ cursor: "pointer" }}
                                        >
                                            <i
                                                className="bx bx-list-ul"
                                                style={{ fontSize: "30px" }}
                                            />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleConsultExamsOrder()
                                                }}
                                                disabled={
                                                    data?.status ==
                                                        EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                    data?.partner_order_id
                                                        ? false
                                                        : true
                                                }
                                            >
                                                <i className="fas fa-eye text-primary mr-1" />
                                                Consultar Resultados Lab.
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={
                                                    data?.status ==
                                                        EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                    data?.partner_order_id
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    let labels = data?.items?.filter(
                                                        e =>
                                                            e?.print_bar_code_command
                                                    )
                                                    confirmBulkPrintLabels(
                                                        labels
                                                    )
                                                }}
                                            >
                                                <i className="fas fa-print mr-1"></i>
                                                Imprimir Etiquetas
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={
                                                    data?.status ==
                                                    EXAMS_ORDER_STATUS.CONCLUDED
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    handleDownloadExamOrder()
                                                }}
                                            >
                                                <i className="fas fa-file mr-1"></i>
                                                Ver Resultados
                                            </DropdownItem>
                                            {/* {data?.status ==
                                                EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                            !data?.partner_order_id ? (
                                                <DropdownItem
                                                    onClick={() => {
                                                        handleSyncExamOrder(
                                                            data?.id
                                                        )
                                                    }}
                                                >
                                                    <i className="fas fa-sync mr-1"></i>
                                                    Enviar para o Lab.
                                                </DropdownItem>
                                            ) : (
                                                ""
                                            )} */}
                                            {hasPermissionToCancelExamsOders ? (
                                                <DropdownItem
                                                    onClick={() => {
                                                        confirmationCancelExamOrder()
                                                    }}
                                                    disabled={
                                                        data?.status ==
                                                            EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                        !showLoading
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <i className="fas fa-ban text-danger mr-1" />
                                                    Cancelar pedido
                                                </DropdownItem>
                                            ) : (
                                                ""
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            ) : (
                                ""
                            )}
                        </Row>
                        {data?.id ? (
                            <Row className="mt-2">
                                <Col xs="12" md="6" lg="3" className="mt-1">
                                    <Label>Pedido Vittá </Label>
                                    <FastField
                                        name={`cod_order_exam_vitta`}
                                        component={TextInput}
                                        readOnly={true}
                                        disabled={true}
                                        isDisabled={true}
                                        style={{
                                            backgroundColor: "#f2f2f2",
                                            color: "#adadad",
                                        }}
                                        value={data?.id}
                                    />
                                </Col>
                                <Col xs="12" md="5" lg="3" className="mt-1">
                                    <Label>Pedido Laboratório </Label>
                                    <FastField
                                        name={`cod_order_exam_lab`}
                                        component={TextInput}
                                        readOnly={true}
                                        disabled={true}
                                        isDisabled={true}
                                        style={{
                                            backgroundColor: "#f2f2f2",
                                            color: "#adadad",
                                        }}
                                        defaultValue={data?.partner_order_id}
                                    />
                                </Col>
                                <Col
                                    xs="12"
                                    md="4"
                                    lg="2"
                                    align="end"
                                    className="mt-2 d-none d-md-block"
                                >
                                    <Label>Status</Label>
                                    <div
                                        style={{
                                            position: "relative",
                                            left: "36px",
                                        }}
                                    >
                                        {renderStatusBadge(data?.status)}
                                    </div>
                                </Col>
                                <Col
                                    xs="12"
                                    md="4"
                                    lg="3"
                                    className="mt-2 d-none d-md-block"
                                    align="center"
                                >
                                    <Label>Status Integração</Label>
                                    <div className="ml-3">
                                        {" "}
                                        {renderStatusIntegrationBadge(
                                            data?.partner_status_integration
                                        )}
                                    </div>
                                </Col>
                                {data?.partner_obs ? (
                                    <Col
                                        xs="12"
                                        md="4"
                                        lg="1"
                                        className="mt-2 d-none d-md-block"
                                        align="end"
                                    >
                                        <div className="">
                                            {" "}
                                            <i
                                                className="far fa-eye"
                                                id="partnerobsexamorder"
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "25px",
                                                    position: "relative",
                                                    top: "17px",
                                                    left: "-29px",
                                                    color: "#0195d5",
                                                }}
                                                onClick={() => {
                                                    setControlModalInfoPartnerObs(
                                                        {
                                                            show: true,
                                                            data:
                                                                data?.partner_obs,
                                                            type:
                                                                "exams_orders",
                                                        }
                                                    )
                                                }}
                                            ></i>
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"partnerobsexamorder"}
                                                style={{
                                                    backgroundColor: "#0894d4",
                                                }}
                                            >
                                                Observações até o momento
                                            </UncontrolledTooltip>
                                        </div>
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                        ) : (
                            ""
                        )}
                        {!data?.id ? (
                            <RenderExamRequests
                                values={values}
                                AsyncSelectInput={AsyncSelectInput}
                                setFieldValue={setFieldValue}
                                setControlModalAdditionalData={
                                    setControlModalAdditionalData
                                }
                            />
                        ) : (
                            <ShowExamRequests
                                values={values}
                                setControlModalAdditionalData={
                                    setControlModalAdditionalData
                                }
                                confirmationCancelExamOrderItem={
                                    confirmationCancelExamOrderItem
                                }
                                handleDownloadExamOrderItem={
                                    handleDownloadExamOrderItem
                                }
                                printLabel={printLabel}
                                setControlModalInfoPartnerObs={
                                    setControlModalInfoPartnerObs
                                }
                                setControlModalInfoPartnerStatus={
                                    setControlModalInfoPartnerStatus
                                }
                                hasPermissionToCancelExamsOders={
                                    hasPermissionToCancelExamsOders
                                }
                            />
                        )}

                        {!data?.id ? (
                            <div className="" align="right">
                                <hr />
                                <button
                                  disabled={showLoading}
                                    type="button"
                                    onClick={() => {
                                       if(!showLoading) onClose()
                                    }}
                                    className="btn btn-danger waves-effect"
                                    data-dismiss="modal"
                                >
                                    <span className="text-white">
                                        {" "}
                                        Cancelar
                                    </span>
                                </button>
                                <button
                                    disabled={showLoading}
                                    type="submit"
                                    className="btn btn-info waves-effect ml-2"
                                    data-dismiss="modal"
                                >
                                    <span className="text-white">
                                        {" "}
                                        Confirmar Solicitação
                                    </span>
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </Form>
                )}
            </Formik>
            <ModalAdditionalData
                show={controlModalAdditionalData?.show}
                additionalData={controlModalAdditionalData?.data}
                setAdditionalData={controlModalAdditionalData.setAdditionalData}
                disabled={controlModalAdditionalData.disabled}
                onClose={() => {
                    setControlModalAdditionalData({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalInfoPartnerObs
                show={controlModalInfoPartnerObs?.show}
                data={controlModalInfoPartnerObs?.data}
                onClose={() => {
                    setControlModalInfoPartnerObs({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalInfoPartnerStatus
                show={controlModalInfoPartnerStatus?.show}
                data={controlModalInfoPartnerStatus?.data}
                onClose={() => {
                    setControlModalInfoPartnerStatus({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalPrintChoice
                show={controlModalPrintChoice?.show}
                data={controlModalPrintChoice?.data}
                bulkPrintLabels={bulkPrintLabels}
                getPrintBarCode={getPrintBarCode}
                onClose={() => {
                    setControlModalPrintChoice({
                        show: false,
                        data: null,
                    })
                }}
            />
        </React.Fragment>
    )
}

export default IndexModalExamRequests
