import React from "react"
import { Modal, Row, Col } from "reactstrap"

const ModalInfoPartnerObs = ({ show, data, onClose }) => {
    if (!show || !data) return <></>
    return (
        <Modal isOpen={show} centered={false} size="md">
            <div className="card-body">
                <Row>
                    <Col xs="12" sm="10">
                        <div className="modal-title " id="myModalLabel">
                            <h5>
                                <i
                                    className="fas fa-file-alt text-info fa-lg mr-2"
                                    style={{
                                        position: "relative",
                                        top: "-1px",
                                    }}
                                ></i>
                                Últimas observações consultadas
                            </h5>
                        </div>
                    </Col>
                    <Col xs="12" sm="2">
                        <button
                            style={{
                                fontSize: "2rem",
                                position: "relative",
                                top: "-8px",
                            }}
                            type="button"
                            onClick={() => {
                                onClose()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col
                        sm="12"
                        style={{ color: "#0195d5", fontWeight: "bold" }}
                    >
                        {data?.split("-")?.length > 1
                            ? data
                                  ?.split("-")
                                  ?.map((item, index) => <p>- {item}</p>)
                            : data}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalInfoPartnerObs
