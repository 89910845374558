import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Badge,
    Button,
    UncontrolledTooltip,
} from "reactstrap"
import { Link, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import {
    create,
    cancelAttendance,
    search,
    deleteAttendance,
} from "services/vitta-core/attendance"

const AttendanceForms = props => {
    const [activeTab, setactiveTab] = useState("1")

    const userAuth = useSelector(state => state.auth.access?.user)
    const { patient_id, attendance_id } = useParams()
    const Scope = useSelector(state => state.scope)

    const [pageData, setPageData] = useState({
        attendances: [],
    })
    const [modalReportAttendance, setModalReportAttendanceData] = useState({
        show: false,
        data: null,
    })

    const [controlState, setControlState] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const attendanceCanceled = 2
    const dataSubmit = {
        unit_id: Scope.unit.id,
        patient_id: patient_id,
        appointment_id: props?.appointment_id ? props?.appointment_id : null,
    }

    const onSubmit = async id => {
        let response = null
        try {
            setControlState(true)

            let statusAttendance = null

            for (let i = 0; i < pageData.attendances.length; i++) {
                if (
                    pageData.attendances[i].status === 1 &&
                    userAuth.Professional?.id ===
                        pageData.attendances[i].professional?.id
                ) {
                    statusAttendance = true
                }
            }

            if (statusAttendance) {
                dispatch(
                    showToast({
                        text: "Finalize o atendimento pendente!",
                        type: "error",
                    })
                )
                setControlState(false)
                return
            }

            let data = await create(dataSubmit)

            history.push(
                "/pacientes/" + patient_id + "/atendimentos/" + data.id
            )

            setControlState(false)
            dispatch(
                showToast({
                    title: "OK!",
                    text: "Você foi redirecionado!",
                    type: "success",
                })
            )
        } catch (e) {
            setControlState(false)
            if (
                e?.response?.data?.error_code?.error_code ==
                "patient_register_incomplete"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Faltam Dados !",
                        text:
                            "O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, sexo, data de nascimento, dados de contato e endereço.",
                        type: "error",
                    })
                )
                return
            } else if (
                e?.response?.data?.error_code?.error_code ==
                "patient_not_active"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Paciente inativo!",
                        text:
                            "Altera o status do paciente e tente novamente...",
                        type: "error",
                    })
                )
                return
            } else if (
                e?.response?.data?.error_code?.error_code ==
                "professional_not_found"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Profissional não encontrado!",
                        text:
                            "Não existe profissional ligado ao seu usuário, entre em contato com o relacionamento médico.",
                        type: "error",
                    })
                )
                return
            } else if (
                e?.response?.data?.error_code?.error_code ==
                "attendance_in_open"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atendimento aberto!",
                        text: e?.response?.data?.error_code?.error_message,
                        type: "error",
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    let count = 0
    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                setControlState(true)

                let data = await search({ patient_id: patient_id })
                let result = data.data

                //tratar dados
                for (let i = 0; i < result.length; i++) {
                    let useData = result[i]
                    useData.created_at = new Date(
                        useData.created_at
                    ).toLocaleDateString("pt-BR", {
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                        second: "2-digit",
                    })
                    useData.updated_at = new Date(
                        useData.updated_at
                    ).toLocaleDateString("pt-BR", {
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                        second: "2-digit",
                    })
                }

                setPageData({
                    ...pageData,
                    attendances: result,
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
                count = count + 1
                if (!navigator.onLine) {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ocorreu algum problema!",
                            text:
                                "Verifique sua conexão de internet e tente novamente...",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                } else if (count < 3) {
                    loadDataEditPage()
                } else {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ocorreu algum problema, contate o suporte!",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }
            }
        }

        if (patient_id) loadDataEditPage()
        else console.log("não entrou no edit")
    }, [])

    const deleteRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            await deleteAttendance(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )
            let tempData = { ...pageData }

            tempData.attendances = pageData.attendances.filter(
                elem => elem.id != id
            )

            setPageData(tempData)
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }
    const confirmCancelRecord = async data => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção!",
                text:
                    "Tem certeza que deseja cancelar esse registro? essa ação é irreversível.",
                type: "warning",
                onConfirmAction: () => {
                    handleCancelAttendance(data)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleCancelAttendance = async values => {
        try {
            await cancelAttendance(values?.id)
            let tempData = { ...pageData }

            let indexObj = tempData.attendances.findIndex(
                e => e.id == values?.id
            )

            if (tempData.attendances[indexObj]) {
                tempData.attendances[indexObj].status = attendanceCanceled
            }

            setPageData(tempData)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Atendimento Cancelado.",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.error_code?.error_code == "user_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Sem permissão!",
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code?.error_code == "open_service") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atendimento em andamento!",
                        type: "error",
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }

    return (
        <Card>
            {controlState ? (
                <div
                    className="spinner-grow text-info mt-3 ml-5"
                    role="status"
                    style={{
                        position: "absolute",
                        left: "400px",
                    }}
                >
                    <span className="sr-only">Sincronizando...</span>
                </div>
            ) : (
                ""
            )}
            {controlState ? (
                <div
                    className="spinner-grow text-dark mt-3 ml-5"
                    role="status"
                    style={{
                        position: "absolute",
                        left: "425px",
                    }}
                >
                    <span className="sr-only">Sincronizando...</span>
                </div>
            ) : (
                ""
            )}
            <CardBody>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CardTitle
                        className="mb-4"
                        style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            position: "relative",
                            top: "-3px",
                        }}
                    >
                        <i className="fas fa-book-medical"></i> Histórico de
                        Atendimentos
                    </CardTitle>
                    <div style={{ display: "flex", marginLeft: "auto" }}>
                        <a
                            onClick={() => {
                                history.push("/agendamentos")
                            }}
                            href="#"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "24px",
                                padding: "2px 8px",
                                backgroundColor: "#2c7be5",
                                color: "white",
                                fontWeight: "500",
                                borderRadius: "5px",
                                textDecoration: "none",
                                transition: "background-color 0.3s",
                                fontSize: "12px",
                                lineHeight: "1",
                            }}
                            onMouseOver={e =>
                                (e.currentTarget.style.backgroundColor =
                                    "#207fa6")
                            }
                            onMouseOut={e =>
                                (e.currentTarget.style.backgroundColor =
                                    "#2c7be5")
                            }
                        >
                            <i
                                className="fas fa-calendar-alt"
                                style={{ marginRight: "5px" }}
                            ></i>
                            Agendamentos
                        </a>
                    </div>
                </div>
                <li className="event-list list-unstyled mb-4">
                    <Media>
                        <i className="fas fa-plus-circle font-size-18 mt-2 " />
                        <div className="mr-5">
                            <h5 className="font-size-14 mt-2">
                                <i className="bx bx-right-arrow-alt font-size-16 text-primary  ml-3 mr-3" />
                            </h5>
                        </div>

                        <Media body>
                            {controlState ? (
                                <Button color="info" disabled>
                                    Iniciar Novo Atendimento{" "}
                                    <i className="fas fa-lock ml-1 fa-lg"></i>
                                </Button>
                            ) : (
                                <Button
                                    color="info"
                                    onClick={() => {
                                        onSubmit()
                                    }}
                                >
                                    Iniciar Novo Atendimento{" "}
                                    <i className="fas fa-comment-medical ml-1 fa-lg"></i>
                                </Button>
                            )}
                        </Media>
                    </Media>
                </li>

                {pageData?.attendances?.length == 0
                    ? "Ainda não há atendimentos para este paciente..."
                    : ""}

                {pageData.attendances.map(item => (
                    <ul className="list-unstyled">
                        <li className="event-list">
                            <Media className="mb-4">
                                <Row>
                                    <Col sm="12">
                                        <div className="mr-3">
                                            <div className="font-size-14 mt-3 text-dark">
                                                {item.created_at.substr(0, 10)}
                                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2" />
                                            </div>
                                        </div>
                                        <div className="mr-3">
                                            <small className="font-weight-bold">
                                                {" "}
                                                <i className="far fa-clock fa-lg mr-1 font-size-12 text-dark"></i>
                                                {item.created_at.substr(11)}
                                            </small>
                                        </div>
                                    </Col>
                                </Row>

                                <Media body>
                                    <CardBody className="shadow-lg  bg-white rounded">
                                        <Row>
                                            <Col
                                                sm="9"
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {item?.status != 2 ? (
                                                    <span>
                                                        {item?.ana_main_complaint?.substr(
                                                            0,
                                                            251
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            textDecoration:
                                                                "line-through",
                                                        }}
                                                    >
                                                        {item?.ana_main_complaint?.substr(
                                                            0,
                                                            251
                                                        )}
                                                    </span>
                                                )}
                                            </Col>

                                            <Col sm="3">
                                                {item.status === 1 &&
                                                item.professional?.id ===
                                                    userAuth.Professional
                                                        ?.id ? (
                                                    <div align="end">
                                                        <Link
                                                            to={
                                                                "/pacientes/" +
                                                                patient_id +
                                                                "/atendimentos/" +
                                                                item.id
                                                            }
                                                            className="mr-3"
                                                        >
                                                            <i
                                                                className="fas fa-edit fa-lg font-size-22"
                                                                id={
                                                                    "iconedittooltip" +
                                                                    item.id
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "iconedittooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Editar Registro
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link
                                                            to={
                                                                "/pacientes/" +
                                                                patient_id +
                                                                "/exportacao-prontuario?attendance_id=" +
                                                                item.id
                                                            }
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fas fa-print fa-lg font-size-22 mr-3"
                                                                id={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Imprimir
                                                                Atendimento
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link
                                                            to={"#"}
                                                            onClick={() => {
                                                                deleteRecord(
                                                                    item?.id
                                                                )
                                                            }}
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <i
                                                                className="fas fa-trash-alt fa-lg font-size-22"
                                                                id={
                                                                    "icontrashtooltip" +
                                                                    item.id
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "icontrashtooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Excluir Registro
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        <Link
                                                            to={
                                                                "/pacientes/" +
                                                                patient_id +
                                                                "/atendimentos/" +
                                                                item.id
                                                            }
                                                            className=""
                                                        >
                                                            <i
                                                                className="fas fa-eye fa-lg font-size-22"
                                                                id={
                                                                    "iconeyetooltip" +
                                                                    item.id
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "iconeyetooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Visualizar
                                                                Atendimento
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link
                                                            to={
                                                                "/pacientes/" +
                                                                patient_id +
                                                                "/exportacao-prontuario?attendance_id=" +
                                                                item.id
                                                            }
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fas fa-print fa-lg font-size-22 ml-3"
                                                                id={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Imprimir
                                                                Atendimento
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        {item.user_id ===
                                                            userAuth.id &&
                                                        item?.status === 0 ? (
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    confirmCancelRecord(
                                                                        item
                                                                    )
                                                                }}
                                                            >
                                                                <i
                                                                    className="fas fa-times fa-lg font-size-22 ml-3"
                                                                    id={
                                                                        "canceltooltip" +
                                                                        item.id
                                                                    }
                                                                ></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={
                                                                        "canceltooltip" +
                                                                        item.id
                                                                    }
                                                                >
                                                                    Cancelar
                                                                    Atendimento
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col
                                                sm="6"
                                                className={
                                                    item.professional?.id ===
                                                    userAuth.Professional?.id
                                                        ? "font-weight-bold"
                                                        : ""
                                                }
                                            >
                                                {item?.professional?.name}{" "}
                                                {item.professional?.id ===
                                                userAuth.Professional?.id ? (
                                                    <i className="fas fa-hand-holding-medical"></i>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                Atendimento: #{item.id}{" "}
                                                {item.status === 1 ? (
                                                    <Badge
                                                        className="badge-soft-warning font-size-13"
                                                        pill
                                                    >
                                                        <i className="fas fa-notes-medical"></i>{" "}
                                                        Em andamento{" "}
                                                    </Badge>
                                                ) : item.status === 0 ? (
                                                    <Badge
                                                        className="badge-soft-primary font-size-13"
                                                        pill
                                                    >
                                                        <i className="fas fa-clipboard-check"></i>
                                                        <i className="fas fa-lock  ml-1"></i>{" "}
                                                        Finalizado{" "}
                                                    </Badge>
                                                ) : item.status === 2 ? (
                                                    <Badge
                                                        className="badge-soft-danger font-size-13"
                                                        pill
                                                    >
                                                        <i className="fas fa-times"></i>
                                                        <i className="fas fa-lock ml-1"></i>{" "}
                                                        Cancelado{" "}
                                                    </Badge>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>
                                    </CardBody>{" "}
                                </Media>
                            </Media>
                        </li>
                    </ul>
                ))}
            </CardBody>
            {/* <ModalTimeLineAttendances
                show={modalReportAttendance.show}
                data={modalReportAttendance.data}
                onClose={() => {
                    console.log("teste")
                    setModalReportAttendanceData({
                        show: false,
                        data: null,
                    })
                }}
                onConfirm={async id => {
                    try {
                        let dataSubmit = {
                            patient_id: patient_id,
                            unit_id: Scope.unit.id,
                            osItem_id: id ? id : null,
                        }

                        let data = await create(dataSubmit)
                        setModalReportAttendanceData({
                            show: false,
                        })
                        history.push(
                            "/pacientes/" +
                                patient_id +
                                "/atendimentos/" +
                                data.id
                        )
                    } catch (e) {
                        if (
                            e?.response?.data?.error_code?.error_code ==
                            "patient_register_incomplete"
                        ) {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Faltam Dados !",
                                    text:
                                        "O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, data de nascimento, dados de contato e endereço.",
                                    type: "error",
                                })
                            )
                            return
                        } else if (
                            e?.response?.data?.error_code?.error_code ==
                            "patient_not_active"
                        ) {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Paciente inativo!",
                                    text:
                                        "Altera o status do paciente e tente novamente...",
                                    type: "error",
                                })
                            )
                            return
                        } else if (
                            e?.response?.data?.error_code?.error_code ==
                            "professional_not_found"
                        ) {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Profissional não encontrado!",
                                    text:
                                        "Não existe profissional ligado a um usuário, contate o suporte ou tente mais tarde...",
                                    type: "error",
                                })
                            )
                            return
                        }

                        dispatch(
                            showSweetAlertDialog({
                                title: "Ops...",
                                text:
                                    "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                                type: "error",
                            })
                        )
                        console.log(e)
                    }
                }}
            /> */}
        </Card>
    )
}

export default AttendanceForms
