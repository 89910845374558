import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { search as ProfessionalServiceSearch } from "services/vitta-core/professional"
import { search as itemServiceSearch } from "services/vitta-core/item"
import {searchReportItemExecComission} from "services/vitta-core/item-exec-comissions"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"


import debounce from "debounce-promise"

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const ExecComissions = () => {
    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)
    const [controlState, setControlState] = useState(false)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        options_list.push({ label: "Todas Unidades", value: null })
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await ProfessionalServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Todos Profissionais", value: null })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )

    const loadItemsInputOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try {
                //if(!inputValue) return resolve([])
                let response = await itemServiceSearch({ term: inputValue }, 1, 20);

                let data_values = [];

                response.data.forEach(element => {
                    data_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(data_values);
            }
            catch (e) {
                console.error(e);
                return reject([]);
            }
        });
    }
    const debounceItems = debounce(loadItemsInputOptions, 600)


    const onSubmit = async (data_form) => {
        let auxData = {}
        auxData.professional = {
            id: data_form.professional_id?.value,
            name: data_form.professional_id?.label,
        }

        auxData.unit = {
            id: data_form.unit_id?.value,
            name: data_form.unit_id?.label,
        }
        auxData.service = {
            id: data_form.service_id?.value,
            name: data_form.service_id?.label
        }
        setControlState(true)
        let response = await searchReportItemExecComission({
            unit_id: auxData.unit.id,
            professional_id: auxData.professional.id,
            item_id: auxData.service.id
        })
        //console.log(response)
        var headers = response.headers
        var blob = new Blob([response.data], {
            type: headers["content-type"],
        })
        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = "Comissões"
        link.click()
        setControlState(false)
        return

       
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Comissões de Execução/Relatórios" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Comissões de Execução
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    service_id: null,
                                                    unit_id: { label: Scope.unit.name, value: Scope.unit.id },
                                                    professional_id: null

                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Unidade
                                                                </Label>
                                                                <Field
                                                                    name="unit_id"
                                                                    placeholder="Selecione a Unidade"

                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Profissional
                                                                </Label>
                                                                <Field
                                                                    name="professional_id"
                                                                    placeholder="Selecionar profissional"
                                                                    cacheOptions
                                                                    isClearable
                                                                    defaultOptions
                                                                    loadOptions={
                                                                        debounceOptionsProfessional
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Serviço
                                                                </Label>
                                                                <Field
                                                                    name="service_id"
                                                                    placeholder="Selecione o Serviço"
                                                                    defaultOptions
                                                                    isClearable
                                                                    cacheOptions
                                                                    loadOptions={debounceItems}
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }

                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                            sm="2"
                                                            align="start"
                                                            className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <div
                                                                        className="spinner-border text-dark"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Loading...
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                sm="6"
                                                                align="end"
                                                                className="mt-4"
                                                            >
                                                                <Button
                                                                    type="submit"
                                                                    className="btn btn-dark"
                                                                    disabled={controlState}
                                                                >
                                                                    Gerar
                                                                    Relatório
                                                                    <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExecComissions
