import React, { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import debounce from 'debounce-promise';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { search as appointmentBooksServiceSearch } from "services/vitta-core/appointment-book"
import calendarListColumns from "pages/Schedule/ScheduleSetting/List/ListColumns"

const ListSchedule = () => {
    // const [modal, setModal] = useState()
    const [searchTerm, setSearchTerm] = useState('');
    const [CalendarList, setCalendarList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const dispatch = useDispatch()

    const { SearchBar } = Search

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let data = await appointmentBooksServiceSearch(filter, page, sizePerPage)

            setCalendarList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages*sizePerPage,
                custom: true,
                data: data.data,
            })
     
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "", expand_professional: true, expand_unit: true }, 1)
    }, [])


    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        if(type == "pagination")
        {
            search({ term: searchText, expand_professional: true, expand_unit: true }, page)
        }else if(type == "search")
        {
            search({ term: searchText, expand_professional: true, expand_unit: true }, 1)
        }
    }

    const toggleModal = () => {
        setModal(!modal)
    }
    const debouncedSearch = useCallback(debounce((filter, page) => {
        search(filter, page);
    }, 1000), []);

    const handleSearchChange = async (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        await debouncedSearch({ term: value, expand_professional: true, expand_unit: true }, 1);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Lista" breadcrumbItem="Agenda" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            CalendarList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={CalendarList.data || []}
                                                columns={calendarListColumns()}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="4">
                                                                <div className="search-box mr-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=" "
                                                                        value={searchTerm}
                                                                        onChange={handleSearchChange}
                                                                    />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="8">
                                                                <div className="text-sm-right ">
                                                                    <Link to="/agendas/adicionar">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                        >
                                                                            Adicionar
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListSchedule
