import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

/**
 * Componente para exibir balões de BI com informações de resumo financeiro.
 * @param {Object} summary - Objeto contendo os dados de resumo financeiro.
 */
const CashControlDashboard = ({ summary }) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
        }).format(value / 100); //  valores em centavos
    };

    const getIconClass = (title) => {
        const iconClasses = {
            "PIX": "fas fa-qrcode",
            "Dinheiro": "fas fa-money-bill-wave",
            "Cartão de Crédito": "fas fa-credit-card",
            "Total": "fas fa-coins",
        };
        return iconClasses[title] || "fas fa-wallet";
    };

    const renderBICard = (title, data) => (
        <Col lg="3" md="6" sm="6" className="mb-3">
            <Card className="mini-stats-wid" style={{ height: "150px" }}>
                <CardBody className="shadow-sm bg-white rounded">
                    <Row className="h-100 align-items-center">
                        <Col md="4" xs="5">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                    <i className={getIconClass(title) + " font-size-24"} />
                                </span>
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="text-center">
                                <p className="h6 text-muted">{title}</p>
                                <h6 className="font-weight-bold">Entrada: {formatCurrency(data.entrada || 0)}</h6>
                                <h6 className="font-weight-bold">Saída: {formatCurrency(data.saida || 0)}</h6>
                                <h6 className="font-weight-bold">Em Caixa: {formatCurrency(data.emCaixa || 0)}</h6>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );

    return (
        <Row className="mt-3">
            {renderBICard("PIX", summary.PIX)}
            {renderBICard("Dinheiro", summary.Dinheiro)}
            {renderBICard("Cartão", summary.Cartao)}
            {renderBICard("Total", summary.Total)}
        </Row>
    );
};

export default CashControlDashboard;
