import React, { useState, useRef } from "react"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
    FormGroup,
    CardHeader,
    Collapse,
    Badge,
} from "reactstrap"
import AsyncSelect from "react-select/async"
import Breadcrumbs from "components/Common/Breadcrumb"

import { useDispatch } from "react-redux"

import { showSweetAlertDialog, showToast } from "store/layout/actions"

import { search as searchFailures } from "services/vitta-core/integration-failures"

import { search as patientServiceSearch } from "services/vitta-core/patient"

import { search as professionalServiceSearch } from "services/vitta-core/professional"

import { search as itemServiceSearch } from "services/vitta-core/item"

import { syncEntity } from "services/vitta-core/integration-erp"

import debounce from "debounce-promise"

const Integrations = () => {
    const inputIntegrationItemSelect = useRef()
    const inputIntegrationItemDate = useRef()
    const inputIntegrationPatientSelect = useRef()
    const inputIntegrationNoteTaxText = useRef()
    const inputIntegrationReceivingOrdersText = useRef()
    const inputIntegrationSalesOrdersAdvText = useRef()
    const inputIntegrationNoteTransferMedicText = useRef()
    const inputIntegrationPaymentTransferText = useRef()
    const inputIntegrationProfessionalSelect = useRef()

    const dispatch = useDispatch()

    const handleSynchronizeItemsPendings = async () => {
        try {
            await syncEntity("items", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeItemsAll = async () => {
        try {
            await syncEntity("items", { operation: "all" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeItem = async id => {
        try {
            await syncEntity("items", { operation: "single", entity_id: id })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeItemForDateExec = async date => {
        if (!date) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Informe a Data",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        try {
            await syncEntity("items", {
                operation: "items_last_update",
                last_update_date: date,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizePatientsPendings = async () => {
        try {
            await syncEntity("patients", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizePatients = async id => {
        try {
            await syncEntity("patients", { operation: "single", entity_id: id })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeNotesTaxs = async id => {
        try {
            await syncEntity("sales_invoices_advi", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeNotesTaxsPendings = async () => {
        try {
            await syncEntity("sales_invoices_advi", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeNotesTransferMedic = async id => {
        try {
            await syncEntity("prof_transfer_invoice", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeNotesTransferMedicPendings = async () => {
        try {
            await syncEntity("prof_transfer_invoice", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeSalesOrdersAdv = async id => {
        try {
            await syncEntity("sales_orders_advi", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeSalesOrdersAdvPendings = async () => {
        try {
            await syncEntity("sales_orders_advi", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeReceivingOrders = async id => {
        try {
            await syncEntity("sales_order_payment_advi", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeReceivingOrdersPendings = async () => {
        try {
            await syncEntity("sales_order_payment_advi", {
                operation: "pending",
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizePaymentTransfer = async id => {
        try {
            await syncEntity("prof_transfer_invoice_payment", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizePaymentTransferPendings = async () => {
        try {
            await syncEntity("prof_transfer_invoice_payment", {
                operation: "pending",
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeProfessionalsPendings = async () => {
        try {
            await syncEntity("professionals", { operation: "pending" })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const handleSynchronizeProfessionals = async id => {
        try {
            await syncEntity("professionals", {
                operation: "single",
                entity_id: id,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Integração Iniciada!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Houve um erro ao tentar realizar a integração..",
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const [syncLoading, setSyncLoading] = useState(false)

    const loadingSyncButtons = () => {
        setSyncLoading(true)

        setTimeout(() => setSyncLoading(false), 10000)
    }

    const [tab, setTab] = useState(0)
    const [listErrors, setListErrors] = useState(null)

    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }

    const loadFailuresData = async (entity_type, tab_state) => {
        let data = null
        if ((entity_type && tab == null) || tab != tab_state) {
            data = await searchFailures({
                entity_type: entity_type,
                integration:
                    tab_state != 8 ? "sapb1" : "hermes_pardini_laboratory",
            })
        }
        if (!data) {
            return
        }

        setListErrors(data.data)
    }

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    { term: inputValue, status: 1, fields:"id,name,cpf" },
                    1,
                    20
                )

                let patients_values = []
                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadItemInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await itemServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await professionalServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )

                let items_values = []

                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)
    const debounceOptionsItem = debounce(loadItemInputOptions, 600)
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )

    return (
        <div className="page-content">
            <Breadcrumbs title="Vittá-Net X SAP" breadcrumbItem="Integrações" />
            <Row>
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Itens</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>SAP -&gt; VittáNet</Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeItemsPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {/*
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Todos Items</Label>
                                    <Input
                                        name=""
                                        placeholder="Todos"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>SAP -&gt; VittáNet</Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizeItemsAll()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            */}

                            {/*sincronizar um item somente atrapalha na sincronização geral*/}
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Item</Label>
                                    <AsyncSelect
                                        name="inputIntegrationItemSelect"
                                        placeholder="Busque"
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={debounceOptionsItem}
                                        ref={inputIntegrationItemSelect}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>SAP -&gt; VittáNet </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeItem(
                                                    inputIntegrationItemSelect
                                                        .current.select?.state
                                                        ?.value?.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar por data de exec.</Label>
                                    <Input
                                        name="inputIntegrationItemDate"
                                        type="date"
                                        innerRef={inputIntegrationItemDate}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>SAP -&gt; VittáNet </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeItemForDateExec(
                                                    inputIntegrationItemDate
                                                        .current.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)
                                            HandleTab(1),
                                                loadFailuresData("items", "1")
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 1 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 1}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Pacientes</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizePatientsPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Paciente</Label>
                                    <AsyncSelect
                                        name="inputIntegrationPatientSelect"
                                        placeholder="Busque"
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={debounceOptionsPatient}
                                        ref={inputIntegrationPatientSelect}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizePatients(
                                                    inputIntegrationPatientSelect
                                                        .current.select?.state
                                                        ?.value?.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(2),
                                                loadFailuresData(
                                                    "patients",
                                                    "2"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 2 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 2}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>
                                Integração de Pedidos de Venda (Fatura de
                                Adiantamento)
                            </h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeSalesOrdersAdvPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pedidos</Label>
                                    <Input
                                        name="inputIntegrationSalesOrdersAdvText"
                                        type="text"
                                        innerRef={
                                            inputIntegrationSalesOrdersAdvText
                                        }
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeSalesOrdersAdv(
                                                    inputIntegrationSalesOrdersAdvText
                                                        .current.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(5),
                                                loadFailuresData(
                                                    "sales_orders_advi",
                                                    "5"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 5 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 5}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>
                                Integração de Recebimento de Pedidos de Venda
                                (Contas a Receber)
                            </h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeReceivingOrdersPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Rec. Pedido</Label>
                                    <Input
                                        name="inputIntegrationReceivingOrdersText"
                                        type="text"
                                        innerRef={
                                            inputIntegrationReceivingOrdersText
                                        }
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeReceivingOrders(
                                                    inputIntegrationReceivingOrdersText
                                                        .current.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(9),
                                                loadFailuresData(
                                                    "sales_order_payment_advi",
                                                    "9"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 9 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 9}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                {/*
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Pagamentos de Repasse</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizePaymentTransferPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pag. Repasse</Label>
                                    <Input
                                        name="inputIntegrationPaymentTransferText"
                                        type="text"
                                        innerRef={
                                            inputIntegrationPaymentTransferText
                                        }
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizePaymentTransfer(inputIntegrationPaymentTransferText.current.value)
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(6),
                                                loadFailuresData(
                                                    "prof_transfer_invoice_payment",
                                                    "6"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 6 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 6}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td className="text-danger" style={{wordBreak: "break-all" }}>
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                 */}
            </Row>
            <Row>
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Notas Fiscais de Saída</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeNotesTaxsPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Nota</Label>
                                    <Input
                                        name="inputIntegrationNoteTaxText"
                                        type="text"
                                        innerRef={inputIntegrationNoteTaxText}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons()
                                                handleSynchronizeNotesTaxs(
                                                    inputIntegrationNoteTaxText
                                                        .current.value
                                                )
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(4),
                                                loadFailuresData(
                                                    "sales_invoices_advi",
                                                    "4"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 4 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 4}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Pedidos de Exames</h5>

                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            HandleTab(8),
                                                loadFailuresData(
                                                    "exams_orders",
                                                    "8"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 8 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 8}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td>#{row?.id}</td>
                                                            <td
                                                                className="text-danger"
                                                                style={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                {/*
                <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Notas de Repasse Médico</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizeNotesTransferMedicPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Nota</Label>
                                    <Input
                                        name="inputIntegrationNoteTransferMedicText"
                                        type="text"
                                        innerRef={
                                            inputIntegrationNoteTransferMedicText
                                        }
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizeNotesTransferMedic(inputIntegrationNoteTransferMedicText.current.value)
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)

                                            HandleTab(3),
                                                loadFailuresData(
                                                    "prof_transfer_invoice",
                                                    "3"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 3 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 3}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td className="text-danger" style={{wordBreak: "break-all" }}>
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                 */}
            </Row>

            <Row>
                {/* <Col md="6">
                    <Card style={{ boxShadow: "2px 2px 3px #9E9E9E" }}>
                        <CardBody>
                            <h5>Integração de Profissionais</h5>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Pendentes</Label>
                                    <Input
                                        name=""
                                        placeholder="Pendentes"
                                        disabled
                                        className="bg-light"
                                        type="text"
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizeProfessionalsPendings()
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md="8">
                                    <Label>Sincronizar Profissional</Label>
                                    <AsyncSelect
                                        name="inputIntegrationProfessionalSelect"
                                        placeholder="Busque"
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={debounceOptionsProfessional}
                                        ref={inputIntegrationProfessionalSelect}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label>VittáNet -&gt; SAP </Label>
                                    <div>
                                        <Button
                                            color="primary"
                                            disabled={syncLoading}
                                            onClick={() => {
                                                loadingSyncButtons();
                                                handleSynchronizeProfessionals(inputIntegrationProfessionalSelect.current.select?.state?.value?.value)
                                            }}
                                        >
                                            Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "50px",
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            HandleTab(7),
                                                loadFailuresData(
                                                    "professionals",
                                                    "7"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-danger font-size-12 mt-1 ">
                                                    Últimos erros de integração{" "}
                                                    <i className="fas fa-exclamation-circle fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 7 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-2x">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-2x">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Collapse isOpen={tab == 7}>
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Erro</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listErrors?.map(
                                                    (row, index) => (
                                                        <tr key={row.id}>
                                                            <td className="text-danger" style={{wordBreak: "break-all" }}>
                                                                <strong>
                                                                    Ação:{" "}
                                                                    {
                                                                        row?.action
                                                                    }
                                                                </strong>{" "}
                                                                <br />{" "}
                                                                <strong>
                                                                    Erro:
                                                                </strong>{" "}
                                                                {
                                                                    row?.response_data
                                                                }{" "}
                                                            </td>
                                                            <td className="text-dark">
                                                                {row?.created_at
                                                                    ? new Date(
                                                                          row.created_at
                                                                      ).toLocaleDateString(
                                                                          "pt-BR",
                                                                          {
                                                                              hour:
                                                                                  "2-digit",
                                                                              hour12: false,
                                                                              minute:
                                                                                  "2-digit",
                                                                              second:
                                                                                  "2-digit",
                                                                          }
                                                                      )
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col> */}
            </Row>
        </div>
    )
}

export default Integrations
