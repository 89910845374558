import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { updateEntity, updateExamItem } from "services/vitta-core/attendance"
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from "draft-js"

const handleForm = async (
    attedance_id,
    stateSync,
    datasyncstate,
    entityID,
    field,
    lastFormState,
    entity,
    itemExam,
    optionSelect
) => {
    let itemsEntity = {}
    let fieldValue = field?.value

    try {
        if (field?.name?.includes(".title")) {
            if (
                lastFormState[field.name] == field.value &&
                lastFormState[field.name] != undefined
            ) {
                return
            }

            itemsEntity.title = field?.value
        } else if (field?.name?.includes(".item_id")) {
            if (
                lastFormState[field.name] == field.value &&
                lastFormState[field.name] != undefined
            ) {
                return
            }
        } else if (field?.name?.includes(".quantity")) {
            if (
                field.value == 0 ||
                (lastFormState[field.name] == field.value &&
                    lastFormState[field.name] != undefined)
            ) {
                return
            }
        } else if (field?.name?.includes(".text_object")) {
            itemsEntity.text = draftToHtml(
                convertToRaw(field?.value?.getCurrentContent())
            )
            fieldValue = itemsEntity?.text
            if (
                lastFormState[field.name] == itemsEntity?.text &&
                lastFormState[field.name] != undefined
            ) {
                return
            }
            itemsEntity.text_object = convertToRaw(
                field?.value?.getCurrentContent()
            )
        } else if (field?.name?.includes(".evolution")) {
            if (
                lastFormState[field.name] == field.value &&
                lastFormState[field.name] != undefined
            ) {
                return
            }

            itemsEntity.text = field?.value
        } else if (field?.name?.includes(".specialty_id")) {
            itemsEntity.specialty_id = optionSelect?.value
        }

        itemsEntity.id = entityID
        itemsEntity.attendance_id = attedance_id
        itemsEntity.entity = entity

        if (itemExam) {
            let data = { ...itemExam, item_id: itemExam?.item_id?.value }
            stateSync(0)
            let record = await updateExamItem(data, attedance_id, entityID)
            datasyncstate(record?.form)

            stateSync(1)
        } else {
            stateSync(0)
            let result = await updateEntity(itemsEntity, attedance_id, entityID)

            datasyncstate(result?.form)
            stateSync(1)
        }

        lastFormState[field.name] = fieldValue
    } catch (e) {
        console.log(e)
    }
}

export default handleForm
