const convertISODate = date => {
    return new Date(date).toLocaleDateString("pt-BR", {
        hour: "2-digit",
        hour12: false,
        minute: "2-digit",
        second: "2-digit",
    })
}

module.exports = { convertISODate }
