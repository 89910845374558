import React from "react"
import { Modal, Row, Col, CardBody, CardTitle } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const ModalInfo = ({ show, data, onClose }) => {
    return (
        <Modal isOpen={show} centered={false} size="xl">
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Informações complementares
                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                    </h5>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="card-body">
                <Row className="mb-3">
                    <Col sm="6">
                        <CardTitle>- Pessoa Jurídica</CardTitle>
                        * Cópia do cartão do CNPJ ou cópia do contrato social da
                        empresa.
                        <br />
                        * Documento de identidade com foto (RG e CPF ou CNH) do
                        proprietário/sócios.
                        <br />
                        * Comprovante de residência em seu nome (ou familiares)
                        do proprietário/sócios.
                        <br />
                        * Comprovante da atividade exercida (cartão de visita,
                        link de site, nota fiscal de compra de produtos ou
                        prestação de serviços, etc).
                        <br />* Os documentos devem ser do titular indicado no
                        cadastro e devem estar legíveis.
                    </Col>
                    <Col sm="6">
                        <CardTitle>- Pessoa Física</CardTitle>
                        * Documento de identidade com foto (RG e CPF ou CNH).
                        <br />
                        * Comprovante de residência em seu nome (ou de
                        familiares).
                        <br />* Comprovante da atividade exercida (cartão de
                        visita, link de site, nota fiscal de compra de produtos
                        ou prestação de serviços, etc).
                    </Col>
                </Row>
                <Col sm="12" align="end">
                    {" "}
                    <button
                        align="right"
                        type="button"
                        onClick={() => {
                            onClose()
                        }}
                        className="btn btn-dark waves-effect"
                        data-dismiss="modal"
                    >
                        <span className="text-white"> Fechar</span>
                    </button>
                </Col>
            </div>
        </Modal>
    )
}

export default ModalInfo
