import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"
import { showHistoric } from "services/vitta-core/professional-transfer-invoices"

const historicTransfer = () => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })
    const [controlState, setControlState] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        const loadData = async () => {
            try {
                setControlState(true)
                let dataSend = {}
                let search = location.search
                const params = new URLSearchParams(search)
                const professional_id = params.get("professional_id")
                const unit_id = params.get("unit_id")
                const date_exec_init = params.get("start_exec")
                const date_exec_finish = params.get("end_exec")
                // const date_payment_forecast_start = params.get(
                //     "date_payment_forecast_start"
                // )
                // const date_payment_forecast_end = params.get(
                //     "date_payment_forecast_end"
                // )
                // const status_payment = params.get("status_payment")

                // if (date_payment_forecast_start) {
                //     dataSend.date_payment_forecast_start = date_payment_forecast_start
                // }
                // if (date_payment_forecast_end) {
                //     dataSend.date_payment_forecast_end = date_payment_forecast_end
                // }
                dataSend = {
                    date_exec_init: date_exec_init,
                    date_exec_finish: date_exec_finish,
                    unit_id: unit_id,
                    professional_id: professional_id,
                }
                let result = await showHistoric(dataSend)

                setPageData({
                    header: {
                        professional: result?.professional,
                        start_date_exec: date_exec_init,
                        end_date_exec: date_exec_finish,
                        unit: result?.units,
                    },
                    totalGroups: result?.totalGroups,
                    totalSumGroups: result?.totalSumGroups,
                    data: result?.orderByTransferNoteId,
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
                if (e?.response?.data) {
                    switch (e.response.data.error_code) {
                        case "date_period_limit_exceeded":
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Filtro de data inválido!",
                                    text:
                                        "É possível filtrar no máximo 6 meses de diferença entre datas, verifique os filtros de data e tente novamente.",
                                    type: "error",
                                    onConfirmAction: () => {
                                        history.push(
                                            "/relatorios/repasses-medicos"
                                        )
                                        dispatch(
                                            showToast({
                                                title:
                                                    "Você foi redirecionado!",
                                                type: "success",
                                            })
                                        )
                                    },
                                    showConfirm: true,
                                    confirmBtnText: "Entendi!",
                                })
                            )

                            return
                    }
                }
            }
        }
        loadData()
    }, [])

    const printReport = () => {
        window.print()
    }

    const calcTotalNota = items => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]
            total += useItem.quantity * (useItem.price ? useItem.price : 0)
        }

        return total
    }

    return (
        <React.Fragment>
            <div className="page-content" translate="no">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Repasse Médico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody translate="no">
                                    <div align="end">
                                        <Link
                                            to="/relatorios/repasses-medicos"
                                            className="btn btn-dark d-print-none mr-2"
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Button
                                            className="btn btn-primary d-print-none"
                                            color="primary"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Button>
                                        <br />
                                        <br />
                                    </div>
                                    <Row>
                                        <Col
                                            md="8"
                                            className="d-flex justify-content-start align-items-center"
                                        >
                                            <img
                                                src={logovitta}
                                                alt=""
                                                height="80px"
                                                className="mb-2"
                                            />
                                            Data Exec. Inicial:{" "}
                                            {pageData.header?.start_date_exec
                                                ? new Date(
                                                      pageData.header
                                                          ?.start_date_exec +
                                                          " 00:00:00"
                                                  ).toLocaleDateString("pt-BR")
                                                : null}{" "}
                                            {" - "}
                                            Data Exec. Final:{" "}
                                            {pageData.header?.end_date_exec
                                                ? new Date(
                                                      pageData.header
                                                          ?.end_date_exec +
                                                          " 00:00:00"
                                                  ).toLocaleDateString("pt-BR")
                                                : null}
                                            <br />
                                            Unidades:{" "}
                                            {pageData.header?.unit
                                                ? pageData.header?.unit.map(
                                                      (row, index) =>
                                                          row.name +
                                                          (index ==
                                                          pageData.header?.unit
                                                              .length -
                                                              1
                                                              ? ""
                                                              : ", ")
                                                  )
                                                : "Todas"}{" "}
                                            <br />
                                            {pageData.header?.patient
                                                ? "Paciente: " +
                                                  pageData.header?.patient
                                                      ?.label
                                                : ""}
                                            {/* <div className="d-flex flex-column  ">
                                                <h4>Unidade {pageData.header?.unit?.name}</h4>
                                                <div className="">CNPJ: {pageData.header?.unit?.cnpj}</div>
                                            </div> */}
                                        </Col>

                                        <Col
                                            md="4"
                                            className="d-flex  flex-column "
                                        >
                                            <div className="">
                                                <strong>Profissional:</strong>{" "}
                                                {pageData.header?.professional
                                                    ? pageData.header
                                                          ?.professional?.name
                                                    : null}
                                            </div>
                                        </Col>
                                        <Col sm="12"></Col>
                                    </Row>

                                    <br />
                                    <h4 align="center">
                                        Histórico de Repasses
                                    </h4>
                                    <br />
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">ID Nota</th>
                                                <th scope="col">Data Nota</th>
                                                <th scope="col">Ped. Venda</th>
                                                {/* <th scope="col">ID OS</th> */}
                                                <th scope="col">Paciente</th>
                                                <th scope="col">Unidade</th>
                                                {/* <th scope="col">Item Nota</th> */}
                                                <th scope="col">
                                                    Forma de Pagamento
                                                </th>
                                                <th scope="col">
                                                    ID Pagamento
                                                </th>
                                                <th scope="col">
                                                    Valor Pago (R$)
                                                </th>
                                                <th scope="col">
                                                    Total Nota (R$)
                                                </th>
                                                <th>Data Execução</th>
                                                <th scope="col">
                                                    Data Repasse
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData?.data?.map(
                                                (row, index) => (
                                                    <tr>
                                                        <td>
                                                            #
                                                            {
                                                                row.transfer_invoice_id
                                                            }
                                                        </td>
                                                        <td>
                                                            {row.created_at
                                                                ? new Date(
                                                                      row.created_at
                                                                  ).toLocaleDateString(
                                                                      "pt-BR"
                                                                  )
                                                                : null}{" "}
                                                        </td>
                                                        <td>
                                                            #
                                                            <Link
                                                                to={
                                                                    "/pedidos/" +
                                                                    row?.sales_order_id
                                                                }
                                                                target="_blank"
                                                            >
                                                                {
                                                                    row?.sales_order_id
                                                                }
                                                            </Link>
                                                        </td>
                                                        {/* <td>#{row.os_id}</td> */}
                                                        <td>
                                                            #
                                                            {row.patient_id +
                                                                " - " +
                                                                row.patient_name}
                                                        </td>
                                                        <td>
                                                            #
                                                            {row.unit_id +
                                                                " - " +
                                                                row.unit_name}
                                                        </td>

                                                        <td>
                                                            {row.pay_form_id ==
                                                            "R$"
                                                                ? "Dinheiro"
                                                                : row.pay_form_id ==
                                                                  "CC"
                                                                ? "Cartão de Crédito"
                                                                : row.pay_form_id ==
                                                                  "CD"
                                                                ? "Cartão de Débito"
                                                                : row.pay_form_id ==
                                                                  "PIX"
                                                                ? "PIX"
                                                                : row.pay_form_id ==
                                                                  "CRT"
                                                                ? "Cortesia"
                                                                : ""}
                                                        </td>

                                                        <td>
                                                            {row?.payment_id}
                                                        </td>

                                                        <td>
                                                            {"R$ " +
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(
                                                                    row?.ptip_payment /
                                                                        100
                                                                )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {"R$ " +
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(
                                                                    calcTotalNota(
                                                                        row.items
                                                                    ) / 100
                                                                )}
                                                        </td>
                                                        {/* <td>
                                                            {row.items.map(
                                                                data => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                data.item_name
                                                                            }{" "}
                                                                            (
                                                                            {
                                                                                data.quantity
                                                                            }
                                                                            ) -
                                                                            R${" "}
                                                                            {new Intl.NumberFormat(
                                                                                "pt-br",
                                                                                {
                                                                                    style:
                                                                                        "decimal",
                                                                                    currency:
                                                                                        "BRL",
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            ).format(
                                                                                data.quantity *
                                                                                    ((data.price
                                                                                        ? data.price
                                                                                        : 0) /
                                                                                        100)
                                                                            )}
                                                                            <br />
                                                                        </>
                                                                    )
                                                                }
                                                            )}
                                                        </td> */}
                                                        
                                                        <td>
                                                            {row.exec_at
                                                                ? new Date(
                                                                      row.exec_at
                                                                  ).toLocaleDateString(
                                                                      "pt-BR"
                                                                  )
                                                                : null}
                                                        </td>
                                                        <td>
                                                            {row.transfer_date
                                                                ? new Date(
                                                                      row.transfer_date
                                                                  ).toLocaleDateString(
                                                                      "pt-BR"
                                                                  )
                                                                : null}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    {controlState ? (
                                        <div
                                            className="spinner-border text-info"
                                            role="status"
                                            style={{
                                                position: "absolute",
                                                left: "633px",
                                                top: "410",
                                            }}
                                        >
                                            <span className="sr-only">
                                                Sincronizando...
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <br />

                                    <br />
                                    {pageData?.totalGroups?.map(
                                        (row, index) => (
                                            <div
                                                classname=""
                                                style={{ textAlign: "left" }}
                                            >
                                                {"Total Recebimento por " +
                                                    row?.name +
                                                    " : " +
                                                    "R$ " +
                                                    new Intl.NumberFormat(
                                                        "pt-br",
                                                        {
                                                            style: "decimal",
                                                            currency: "BRL",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ).format(
                                                        row?.total_value / 100
                                                    )}
                                                <br />
                                            </div>
                                        )
                                    )}
                                    <strong>
                                        {pageData?.totalSumGroups
                                            ? "Total Recebimentos : " +
                                              "R$ " +
                                              new Intl.NumberFormat("pt-br", {
                                                  style: "decimal",
                                                  currency: "BRL",
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                              }).format(
                                                  pageData?.totalSumGroups / 100
                                              )
                                            : null}
                                    </strong>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default historicTransfer
