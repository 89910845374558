import React, { useEffect, useState, useMemo, useRef } from "react"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { search as searchInvoices } from "services/vitta-core/sales-invoice"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import InvoicesColumns from "pages/SalesDocs/ListInvoices/InvoicesColumns"
import debounce from "debounce-promise"
import SALES_DOC_TYPE from "utils/consts/sales-docs-type"
import { cancelSalesInvoiceById } from "services/vitta-core/sales-invoice"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

const listInvoices = () => {
    const dispatch = useDispatch()
    const [invoiceList, setInvoiceList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const today = new Date().toLocaleDateString("pt-BR")
    const todayOnlyDate =
        today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

    const inputUnit = useRef()
    const inputPatient = useRef()
    const inputStartCreatedAt = useRef()
    const userAuth = useSelector(state => state.auth.access?.user)

    const defaultPageControl = {
        searching: false,
    }

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async (filter, page) => {
        
        if (pageControl.searching) return

        let sizePerPage = 10

        //Set is searching
         setPageControl({ ...pageControl, ...{ searching: true } })
        //Clear Data Table
        setInvoiceList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })
       
        try {
            filter.doc_type = SALES_DOC_TYPE.SALES_INVOICE
            let record = await searchInvoices(filter, page, sizePerPage)

            setPageControl({ ...pageControl, ...{ searching: false } })

            setInvoiceList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: record.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    useEffect(() => {
        let searchObj = {}

        searchOptions(1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const onTableChange = (a, b, c) => {
        if (a == "pagination") {
            searchOptions(b.page)
        }
    }

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = [];
        /*let options_list = [
            {
                label: "Todas Unidades",
                value: null,
            },
        ]*/
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const searchOptions = (page = 1) => {

        let searchObj = {}
        let unit_id = inputUnit?.current?.state?.value?.value
        let patient_id = inputPatient?.current?.select?.state?.value?.value
        let created_at = inputStartCreatedAt.current.value

        if (unit_id) searchObj.unit_id = unit_id
        if (patient_id) searchObj.patient_id = patient_id
        if (created_at) searchObj.created_at = created_at

        searchObj.expand_items = true;
        searchObj.expand_patient = true;

        search(searchObj, page)
    }

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }
    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"  },
                    1,
                    20
                )

                let patients_values = []

                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)
    const CancelInvoice = async invoice => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja cancelar este registro?",
                type: "warning",
                onConfirmAction: () => {
                    cancelNF(invoice)
                    /*
                    if(invoice.erp_sales_doc_id)
                    {
                        CancelInvoiceErpSync(invoice)
                    }
                    else
                    {
                        cancelNF(invoice)
                    }
                    */
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
       
    }

    const CancelInvoiceErpSync = async invoice => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Esta Nota fiscal já foi Sincronizada com o SAP. Tem certeza que deseja cancelar este registro? Você deverá cancelar a nota também no SAP.",
                type: "warning",
                onConfirmAction: () => {
                    cancelNF(invoice)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
       
    }

    const cancelNF = async invoice => {
        try {
            if (!invoice) return
            
            await cancelSalesInvoiceById(invoice.id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Cancelado com Sucesso!",
                    type: "success",
                })
            )

            searchOptions(invoiceList.page)

        } catch (e) {

            let textError = "Houve um erro ao processar sua solicitação, tente novamente mais tarde...";

            if(e?.response?.status == 403){
                textError = "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            }
            else if(e?.response?.data)
            {
                switch (e.response.data.error_code) {
                    case "sales_invoice_erp_synchronized":
                        textError = "A nota fiscal desse pedido já foi sincronizada com o SAP e não pode mais ser cancelada."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Listagem"
                        breadcrumbItem="Notas Fiscais"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="3">
                                            <Label>Unidade</Label>
                                            <Select
                                                name={`unit_id`}
                                                placeholder="Selecione a Unidade"
                                                classNamePrefix="select2-selection"
                                                options={unitsOptions}
                                                isMulti={false}
                                                isClearable
                                                ref={inputUnit}
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Label>Paciente</Label>
                                            <AsyncSelect
                                                name="patient_id"
                                                placeholder="Selecione o paciente"
                                                classNamePrefix="select2-selection"
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={
                                                    debounceOptionsPatient
                                                }
                                                noOptionsMessage={() =>
                                                    "Sem Resultados. Digite para Buscar"
                                                }
                                                ref={inputPatient}
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Label>Criado a partir de </Label>
                                            <Input
                                                type="date"
                                                innerRef={inputStartCreatedAt}
                                                defaultValue={todayOnlyDate}
                                            ></Input>
                                        </Col>
                                        <Col sm="3" className="mt-1">
                                            <div className="mt-4">
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        searchOptions()
                                                    }
                                                >
                                                    Buscar
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            invoiceList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={invoiceList.data || []}
                                                columns={InvoicesColumns(
                                                    CancelInvoice
                                                )}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2"></Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            onTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default listInvoices
