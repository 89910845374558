import request from './config/request';

const create = async schedule_data => {
    return request({
        method: "POST",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointment-books`,
        data: schedule_data,
        vtAuth: true
    })
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id
    if (filter.expand_professional) query += "&expand_professional=" + filter.expand_professional
    if (filter.expand_unit) query += "&expand_unit=" + filter.expand_unit

    return request({
        method: "GET",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointment-books` +
        query,
        vtAuth: true
    })
}

const update = async (id, schedule_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/appointment-books/${id}`,
        data: schedule_data,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/appointment-books/${id}/edit`,
        vtAuth: true
    })
}

const loadServicesToAppoint = async (data) => {
    let query = "?appointment_book_id="+data.appointment_book_id;
    query += "&date="+data.date;
    query += "&time="+data.time;

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment-books/services-to-appoint` + query,
        vtAuth: true
    })
}

const findServices = async (data) => {

    let query = "?appointment_book_id="+data.appointment_book_id;
    
    if(data?.is_fit)
    {
        query+="&is_fit=" + data?.is_fit;
    }
    if(data?.date){
        query+="&date=" + data?.date
    }

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment-books/services` + query,
        vtAuth: true
    })
}

export {
    search,
    create,
    update,
    loadDataEditform,
    loadServicesToAppoint,
    findServices
}
