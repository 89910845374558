import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Spinner, UncontrolledTooltip } from "reactstrap"

const PrescriptionAttendanceColumns = (RenderMedicalForm, printPDF, cardLoading, loadingButtonIndex) => [
    {
        dataField: "picture",
        text: "#",
        formatter: (cellContent, user) => (
            <>
                {!user.img ? (
                    <div className="avatar-xs">
                        <span className="avatar-title rounded-circle">
                            {user?.professional?.name.charAt(0)}
                        </span>
                    </div>
                ) : (
                    <div>
                        <img
                            className="rounded-circle avatar-xs"
                            src={user.picture}
                            alt=""
                        />
                    </div>
                )}
            </>
        ),
    },
    {
        dataField: "ID",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) => (
            <div>
                {row?.created_at?.substr(8, 2) +
                    "/" +
                    row?.created_at?.substr(5, 2) +
                    "/" +
                    row?.created_at?.substr(0, 4) +
                    " " +
                    row?.created_at?.substr(11, 8)}
            </div>
        ),
    },
    {
        dataField: "update_at",
        text: "Alterado em",
        formatter: (cellContent, row) => (
            <div>
                {row?.updated_at?.substr(8, 2) +
                    "/" +
                    row?.updated_at?.substr(5, 2) +
                    "/" +
                    row?.updated_at?.substr(0, 4) +
                    " " +
                    row?.updated_at?.substr(11, 8)}
            </div>
        ),
    },
    {
        dataField: "title",
        text: "Título  Formulário",
        formatter: (cellContent, row) => <div>{row?.medical_form?.title}</div>,
    },
    {
        dataField: "Professional",
        text: "Nome do Profissional",
        formatter: (cellContent, row) => (
            <div className="text-body font-weight-bold">
                {row?.professional?.name}
            </div>
        ),
    },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Link
                    to="#"
                    className="mr-3 text-primary"
                    onClick={async (e) => {
                        if(cardLoading){
                            return;
                        }
                        if (row.file && row.integration_id) {
                            await printPDF(row.file, row.id)
                        } else RenderMedicalForm(row)
                    }}
                >
                    &nbsp;&nbsp;&nbsp;{" "}
                    {cardLoading && loadingButtonIndex == row?.id ? (
                  <>
                 <Spinner id={"edittooltip" +loadingButtonIndex} color="primary" size="sm"></Spinner>
                  </>
                ) : (
                    <i
                        className="far fa-file-alt font-size-24 mr-4"
                        id={"edittooltip" + row?.id}
                    ></i>
                )}
                    <UncontrolledTooltip
                        placement="top"
                        target={"edittooltip" + row?.id}
                    >
                        Mais detalhes
                    </UncontrolledTooltip>
                </Link>
            </>
        ),
    },
]

export default PrescriptionAttendanceColumns
