import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/saga"
import LayoutSaga from "./layout/saga"
import ScopeSaga from "./scope/saga"
import CashControlSaga from "./cash-control/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ScopeSaga),
    LayoutSaga(),
    fork(CashControlSaga)
  ])
}
