import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const ProfessionalColumns = deleteRecord => [
    {
        dataField: "picture",
        text: "#",
        formatter: (cellContent, user) => (
            <>
                {!user.picture ? (
                    <div className="avatar-xs">
                        <span className="avatar-title rounded-circle">
                            {user.name.charAt(0)}
                        </span>
                    </div>
                ) : (
                    <div>
                        <img
                            className="rounded-circle avatar-xs"
                            src={user.picture}
                            alt=""
                        />
                    </div>
                )}
            </>
        ),
    },
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "name",
        text: "Nome",
    },
    {
        dataField: "email",
        text: "E-mail",
    },
    {
        dataField: "phone",
        text: "Telefone",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => (
            <Badge
                className={`font-size-12 badge-soft-${
                    row.status == 1 ? "success" : "danger"
                }`}
                pill
            >
                {row.status ? "Ativo" : "Inativo"}
            </Badge>
        ),
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Link
                    to={"/profissionais/editar/" + row.id}
                    className="mr-3 text-primary"
                >
                    <i
                        className="mdi mdi-pencil-box-outline font-size-24 mr-3"
                        id={"edittooltip" + row?.id}
                    />
                    <UncontrolledTooltip
                        placement="top"
                        target={"edittooltip" + row?.id}
                    >
                        Editar
                    </UncontrolledTooltip>
                </Link>
                {/*} <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                        deleteRecord(row.id)
                    }}
                >
                    <i
                        className="mdi mdi-trash-can-outline font-size-24 mr-3"
                        id="deletetooltip"
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                        Excluir
                    </UncontrolledTooltip>
                </Link>*/}
            </>
        ),
    },
]

export default ProfessionalColumns
