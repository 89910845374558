import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    Badge,
    Media,
    Table
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Link } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"

const SalesAnalysis = ({ filter, data, state }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })

    useEffect(() => {
        const loadData = async () => {
            try {
         
                setPageData({
                    header: {
                        report_source: filter?.report_source,
                        unit: filter?.unit_id,
                        patient: filter?.patient_id,
                        start_created_at: filter.start_created_at,
                        end_created_at:filter.end_created_at,
                    },
                    data: data,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (data) {
            loadData()
        } else history.push("/relatorios/analise-vendas")
    }, [data])

    const printReport = () => {
        window.print()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Análise de Vendas"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <p>
                                            Análise: { pageData.header?.report_source?.value == "ORDERS" ? "Vendido" : (pageData.header?.report_source?.value == "INVOICES" ? "Faturado" : "")}<br />
                                            Data Inicial: {pageData.header?.start_created_at
                                                    ? new Date(pageData.header?.start_created_at + " 00:00:00").toLocaleDateString('pt-BR')
                                                    : null} {" - "}
                                            Data Final:{" "}
                                                {pageData.header?.end_created_at
                                                    ? new Date(pageData.header?.end_created_at + " 00:00:00").toLocaleDateString('pt-BR')
                                                    : null}
                                                    <br />
                                            Unidades: {pageData.header?.unit ? pageData.header?.unit.map((row, index) => row.label + (index == pageData.header?.unit.length-1 ? "" : ", ") ) : "Todas"} <br />
                                            {pageData.header?.patient ? "Paciente: " +  pageData.header?.patient?.label : ""}
                                        </p>
                                    </Col>
                                    <Col sm="6">
                                        <div align="end" >
                                            <Link
                                                to="#"
                                                className="btn btn-dark d-print-none mr-2"
                                                onClick={()=>state(false)}
                                            >
                                                <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                            </Link>
                                            <Link
                                                to="/relatorios/analise-vendas"
                                                className="btn btn-primary d-print-none"
                                                onClick={printReport}
                                            >
                                                Imprimir
                                            </Link>
                                        </div>
                                    </Col>
                                 </Row>
 
                                <h4 align="center">
                                    Análise de Vendas - { pageData.header?.report_source?.value == "ORDERS" ? " Vendido" : (pageData.header?.report_source?.value == "INVOICES" ? "Faturado" : "")}
                                </h4>
                                
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <Media>
                                    <Media body>
                                        <p className="text-muted font-weight-medium">
                                        Quantidade de Pedidos
                                        </p>
                                        <h4 className="mb-0">{pageData.data?.general?.sales_count}</h4>
                                    </Media>
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                        <span className="avatar-title">
                                            <i className={"fas fa-shopping-cart font-size-24" } ></i>
                                        </span>
                                    </div>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <Media>
                                    <Media body>
                                        <p className="text-muted font-weight-medium">
                                        Valor Total
                                        </p>
                                        <h4 className="mb-0">R${" "}
                                            {
                                                new Intl.NumberFormat(
                                                    "pt-br",
                                                    {
                                                        style:
                                                            "decimal",
                                                        currency:
                                                            "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ).format(parseInt(pageData.data?.general?.total)/100)
                                            } </h4>
                                    </Media>
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                        <span className="avatar-title">
                                            <i className={"fas fa-dollar-sign font-size-24" } ></i>
                                        </span>
                                    </div>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <Media>
                                    <Media body>
                                        <p className="text-muted font-weight-medium">
                                        Tícket Médio
                                        </p>
                                        <h4 className="mb-0">R${" "}
                                            {
                                               pageData.data?.general?.sales_count &&  parseInt(pageData.data?.general?.sales_count) > 0 ?
                                                new Intl.NumberFormat(
                                                    "pt-br",
                                                    {
                                                        style:
                                                            "decimal",
                                                        currency:
                                                            "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ).format((parseInt(pageData.data?.general?.total)/parseInt(pageData.data?.general?.sales_count))/100)
                                                : "0,00"
                                            } </h4>
                                    </Media>
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                        <span className="avatar-title">
                                            <i className={"fas fa-dollar-sign font-size-24" } ></i>
                                        </span>
                                    </div>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Análise por Grupo de Items</h4>
                                    <div className="table-responsive">
                                    <Table className="table">
                                        <thead className="table-light">
                                            <tr align="center">
                                                <th scope="col">
                                                    Grupo do Item
                                                </th>
                                                <th scope="col">Quant. Items</th>
                                                <th scope="col">Quant. Pedidos</th>
                                                <th scope="col">Valor Total</th>
                                                <th scope="col">Tícket Médio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data?.item_groups?.map(
                                                (row, index) => (
                                                    <tr align="center" key={index}>
                                                        <td align="start" >
                                                            {row.group_name}
                                                        </td>
                                                        <td>
                                                            {row.items_count}
                                                        </td>
                                                        <td>
                                                            {row.sales_count}
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.total)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                            R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format((parseInt(row.total)/parseInt(row.sales_count))/100)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Análise por Unidade</h4>
                                    <div className="table-responsive">
                                    <Table className="table">
                                        <thead className="table-light">
                                            <tr align="center">
                                                <th scope="col">
                                                    Unidade
                                                </th>
                                                <th scope="col">Quant. Pedidos</th>
                                                <th scope="col">Valor Total</th>
                                                <th scope="col">Tícket Médio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data?.units?.map(
                                                (row, index) => (
                                                    <tr align="center" key={index}>
                                                        <td align="start" >
                                                            {row.name}
                                                        </td>
                                                        <td>
                                                            {row.sales_count}
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.total)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                            R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format((parseInt(row.total)/parseInt(row.sales_count))/100)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Análise por Data</h4>
                                    <div className="table-responsive">
                                    <Table className="table">
                                        <thead className="table-light">
                                            <tr align="center">
                                                <th scope="col">
                                                    Data
                                                </th>
                                                <th scope="col">Quant. Pedidos</th>
                                                <th scope="col">Valor Total</th>
                                                <th scope="col">Tícket Médio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data?.dates?.map(
                                                (row, index) => (
                                                    <tr align="center" key={index}>
                                                        <td align="start" >
                                                            {new Date(row.created + " 00:00:00").toLocaleDateString('pt-BR')}
                                                        </td>
                                                        <td>
                                                            {row.sales_count}
                                                        </td>
                                                        <td>
                                                        R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format(parseInt(row.total)/100)
                                                            }
                                                        </td>
                                                        <td>
                                                            R${" "}
                                                            {
                                                                new Intl.NumberFormat(
                                                                    "pt-br",
                                                                    {
                                                                        style:
                                                                            "decimal",
                                                                        currency:
                                                                            "BRL",
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                ).format((parseInt(row.total)/parseInt(row.sales_count))/100)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default SalesAnalysis
