import { useState } from "react"
import { Button, Spinner } from "reactstrap"
import { createChargingSignature } from "services/vitta-core/vitta-card-contract"
import { showSweetAlertDialog } from "store/layout/actions"
import { CONTRACT_STATUS } from "../Contract"

function GenChargingButton({
    contract,
    onCreateSignatureSuccess,
    dispatch,
}) {
    const [state, setState] = useState({
        loading: false,
    })

    async function handle() {
        setState({
            ...state,
            loading: true,
        })

        try {
            await createChargingSignature(contract.card_id, contract.id)
            onCreateSignatureSuccess(contract.id)
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Erro ao criar cobrança do contrato",
                    text: e?.response?.data?.error,
                    type: "error",
                    showConfirm: true,
                })
            )
        } finally {
            setState({
                ...state,
                loading: false,
            })
        }
    }

    async function confirm() {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma a geração de cobrança para este contrato?",
                type: "warning",
                onConfirmAction: () => {
                    handle()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    let textButton = "Gerar Cobrança";

    if (contract.asaas_charging_id || contract.status !== CONTRACT_STATUS.SIGNED) return <><i className="fas fa-file-invoice-dollar"></i>{" "}<Button disabled="disabled" size="sm" color="link" style={{ fontSize: 11, color: "info" }}>{textButton}</Button></>

    if (state.loading) return <Spinner size="sm" color="success" />

    return (
        <><i className="fas fa-file-invoice-dollar"></i>{" "}<Button size="sm" color="link" style={{ fontSize: 11, color: "info" }} onClick={confirm}>{textButton}</Button></>
    )
}

export default GenChargingButton
