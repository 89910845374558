import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Modal, Row, Col, UncontrolledTooltip } from "reactstrap"
import IndexModalExamRequests from "./Components/Index"
import EXAMS_ORDER_STATUS from "utils/consts/exams-orders-status"
const ModalExamRequests = ({ show, data, renderRecord, onClose }) => {
    const [controlSearch, setControlSearch] = useState(false)
    if (!show || !data) return <></>
    return (
        <Modal
            isOpen={show}
            centered={false}
            size="xl"
            style={{ maxWidth: "90%", width: "100%" }}
        >
            <div className="card-body">
                <Row>
                    <Col xs="12" sm="10" className="mt-1">
                        <div className="modal-title " id="myModalLabel">
                            <h5>
                                <i className="fas fa-file-medical-alt text-info fa-lg mr-2"></i>
                                {!data?.id
                                    ? "Pedido de Exames Parceiros "
                                    : `#${data?.id} - Pedido de Exames`}
                            </h5>
                        </div>
                    </Col>
                    <Col xs="6" sm="1" align="end">
                        {data?.id &&
                        data?.status != EXAMS_ORDER_STATUS.CANCELED ? (
                            <Link
                                id="infoprint"
                                target={"_blank"}
                                to={"/imprimir/pedidos-de-exame/" + data?.id}
                                className="btn btn-light"
                                style={{
                                    backgroundColor: "#0894d4",
                                    fontWeight: "bold",
                                }}
                                align="end"
                            >
                                <i className="fas fa-print fa-lg text-white"></i>
                                <UncontrolledTooltip
                                    placement="left"
                                    target={"infoprint"}
                                    style={{
                                        backgroundColor: "#0894d4",
                                    }}
                                >
                                    Clique para imprimir
                                </UncontrolledTooltip>
                            </Link>
                        ) : (
                            ""
                        )}
                    </Col>
                    {data?.id ?   <Col xs="6" sm="1">
                        <button
                            style={{
                                fontSize: 40,
                                position: "relative",
                                top: "-6px",
                            }}
                            type="button"
                            onClick={() => {
                                onClose(controlSearch)
                                setControlSearch(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col> : null}
                  
                </Row>
                {show ? (
                    <IndexModalExamRequests
                        show={show}
                        data={data}
                        renderRecord={renderRecord}
                        setControlSearch={setControlSearch}
                        onClose={onClose}
                    ></IndexModalExamRequests>
                ) : (
                    ""
                )}
            </div>
        </Modal>
    )
}

export default ModalExamRequests
