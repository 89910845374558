import React, { useState, useEffect } from "react"
import { Row, Col, Modal, Table, CardBody } from "reactstrap"
import { findBenefits } from "services/vitta-core/patient"
const ModalBenefits = ({ show, data, onClose }) => {
    const [dataForm, setDataForm] = useState([])
    const loadDataModal = async () => {
        try {
            let dataTable = []
            let result = await findBenefits(data?.subscription?.subscription_id)
            for (let i = 0; i < result?.length; i++) {
                let use = result[i]
                dataTable.push({
                    business_partner_subscription:
                        use.business_partner_subscription,
                    name_benefit: use.name_benefit,
                    total_amount_plan: use.total_amount_plan,
                    reserved_amount: use.reserved_amount,
                    quantity_used: use.quantity_used,
                    available_quantity: use.available_quantity,
                    is_unlimited: use.is_unlimited,
                })
            }
            setDataForm(dataTable)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (show && data?.subscription) loadDataModal()
    }, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            toggle={() => {
                onClose()
            }}
            size="xl"
        >
            <CardBody>
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <Row>
                    <Col sm="12">
                        <div className="">
                            <div className="modal-title " id="myModalLabel">
                                <h4>Benefícios</h4>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="modal-body" style={{maxHeight:"500px",overflow:"auto"}}>
                    <Row>
                        <Col sm="12">
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th scope="col">Benefício</th>
                                        <th scope="col">Quantidade do plano</th>
                                        {/* <th scope="col">
                                            Quantidade reservada
                                        </th> */}
                                        <th scope="col">Quantidade usada</th>
                                        <th scope="col">
                                            Quantidade disponível
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataForm?.length > 0
                                        ? dataForm?.map((item, index) => (
                                              <tr key={"row" + index}>
                                                  <td>{item?.name_benefit}</td>
                                                  <td
                                                      style={{
                                                          textAlign: "center",
                                                      }}
                                                  >
                                                      { !item?.is_unlimited ? item?.total_amount_plan :"Ilimitado"}
                                                  </td>
                                                  {/* <td
                                                      style={{
                                                          textAlign: "center",
                                                      }}
                                                  >
                                                      {item?.reserved_amount}
                                                  </td> */}
                                                  <td
                                                      style={{
                                                          textAlign: "center",
                                                      }}
                                                  >
                                                      {item?.quantity_used}
                                                  </td>
                                                  <td
                                                      style={{
                                                          textAlign: "center",
                                                      }}
                                                  >
                                                      {!item?.is_unlimited ?  item?.available_quantity : "Ilimitado"}
                                                  </td>
                                              </tr>
                                          ))
                                        : null}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Modal>
    )
}

export default ModalBenefits
