import { Formik, Field, Form } from "formik"
import { useDispatch } from "react-redux"
import { Modal, Row, Col, Label, Input, Button, Alert, FormGroup } from "reactstrap"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import debounce from "debounce-promise"
import { showSweetAlertDialog } from "store/layout/actions"
import { loadDataEditform } from "services/vitta-core/appointment-book"
import { search as appointmentBookServiceSearch } from "services/vitta-core/appointment-book"
import { useState } from "react"

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            props.state(false)
            props.stateSelect([])
            setFieldValue(field.name, option)
        }}
    />
)
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const weekday = [
    "segunda", "terca", "quarta", "quinta", "sexta", "sabado", "domingo"
]

const loadAppointmentBookInputOptions = async inputValue => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!inputValue) return resolve([])

            let response = await appointmentBookServiceSearch(
                { term: inputValue },
                1,
                20
            )
            let data_values = []
            response.data.forEach(element => {
                data_values.push({
                    label: `#${element.id} ` + element.name?.substr(0, 40),
                    value: element.id,
                    group_id: element.group_id
                })
            })
            return resolve(data_values)
        } catch (e) {
            console.error(e)
            return reject([])
        }
    })
}
const debounceOptionsAppointment = debounce(
    loadAppointmentBookInputOptions,
    600
)

const ModalCopyAnother = ({ show, onClose, data, remover, adicionar }) => {
    const [visible, setVisible] = useState(false)
    const [AnotherSelect, setSelect] = useState([])
    const [NonEmptyPeriods, setNonEmptyPeriods] = useState([])
    const dispatch = useDispatch()
    const handlePeriod = async book_value => {
        try {
            let data = await loadDataEditform(
                book_value.value
            )
            let flag = 0
            let select_values = []
            let period_values = []
            for (let i = 0; i < weekday.length; i++) {
                if (data?.appointment_book.periods[weekday[i]].length > 0) {
                    for (let x = 0; x < data.appointment_book.periods[weekday[i]].length; x++) {
                        select_values.push({
                            label: data.appointment_book.periods[weekday[i]][x].weekday.label + " / " + data.appointment_book.periods[weekday[i]][x].start + " às " + data.appointment_book.periods[weekday[i]][x].end,
                            value: flag
                        })
                        period_values.push(data.appointment_book.periods[weekday[i]][x])
                        flag = flag + 1
                    }
                }
            }
            setSelect(select_values)
            setVisible(true)
            setNonEmptyPeriods(period_values)
        } catch (e) {
            console.log(e)
        }
        return
    }
    const onSubmit = async form_data => {
        if (form_data.book == null || form_data.period == null) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Preencha os campos corretamente",
                    type: "error",
                })
            )
        }
        return dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Se for encontrado um serviço semelhante entre os períodos, será descartado o escolhido para cópia e mantido o que já existe. Deseja confirmar o procedimento?",
                type: "warning",
                onConfirmAction: () => {
                    handlesubmit(form_data.period)
                },
                showCancel: true,
                showConfirm: true,
                confirmBtnText: "Sim",
                cancelBtnText: "Não",

            })
        )

    }
    const handlesubmit = form => {

        if (data.length == 1 && data[0]?.service_id == null) remover(data[0]) //removendo valor inicial nulo

        for (let i = 0; i < NonEmptyPeriods[form.value].services.length; i++) {
            let auxService = {}
            auxService.duration = NonEmptyPeriods[form.value].services[i].duration
            auxService.accept_online_appointment = NonEmptyPeriods[form.value].services[i].accept_online_appointment
            auxService.service_id = {
                label: NonEmptyPeriods[form.value].services[i].service.name,
                value: NonEmptyPeriods[form.value].services[i].service_id,
                group_id: NonEmptyPeriods[form.value].services[i].service.group_id,
            }
            if (data.length == 0 ||
                !data.find(e => e.service_id?.value == NonEmptyPeriods[form.value].services[i].service_id)) adicionar(auxService)


        }

        setVisible(false)
        setSelect([])
        setNonEmptyPeriods([])
        onClose()
        return
    }

    return (
        <Formik
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={{ book: null, period: null }}
        >
            {({ values, resetForm, dirty }) => (
                <Modal
                    size="lg"
                    isOpen={show}
                    centered={true}
                >
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Cópia de outra agenda
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                    resetForm()
                                    setVisible(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col
                                    lg="5"
                                >
                                    <FormGroup>
                                        <Label>
                                            Selecione a agenda que deseja copiar
                                        </Label>
                                        <Field
                                            name="book"
                                            placeholder="Selecionar Agenda"
                                            loadOptions={
                                                debounceOptionsAppointment
                                            }
                                            component={
                                                AsyncSelectInput
                                            }
                                            state={setVisible}
                                            stateSelect={setSelect}
                                            noOptionsMessage={() =>
                                                "Sem Resultados. Digite para Buscar"
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                {
                                    visible ?
                                        <Col
                                            lg="5"
                                        >
                                            <FormGroup>
                                                <Label> Selecione o período que deseja copiar </Label>
                                                <Field
                                                    name="period"
                                                    placeholder="Selecionar Período"
                                                    options={AnotherSelect}
                                                    component={
                                                        ReactSelectInput
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        :
                                        <Col>

                                            <Button
                                                type="button"
                                                color="primary"
                                                style={{ marginTop: "27px" }}
                                                disabled={!dirty}
                                                onClick={() => {
                                                    if (!visible) values.period = null //limpa o campo do periodo
                                                    handlePeriod(values.book)
                                                }}
                                            >
                                                Buscar
                                            </Button>
                                        </Col>
                                }

                            </Row>

                        </div>
                        <div className="modal-footer">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!visible}
                            >
                                Salvar
                            </Button>

                            <Button
                                onClick={() => {
                                    onClose()
                                    resetForm()
                                    setVisible(false)
                                }}
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                            >
                                Cancelar
                            </Button>
                        </div>
                    </Form>
                </Modal>
            )
            }
        </Formik >
    )
}

export default ModalCopyAnother