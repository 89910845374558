import React, { useEffect, useState } from "react"

import { Switch /*, BrowserRouter as Router*/ } from "react-router-dom"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

 /*import fakeBackend from "./helpers/fakebackend/api-requests-mock/fakeBackend"
// Activating fake backend
if(process.env.REACT_APP_USE_FAKE_BACKEND == "true")
{
    fakeBackend()
}
*/

// sweet alert
import { useSelector, useDispatch } from "react-redux"

//actions
import { userLoginSuccess } from "store/auth/actions"
import { setUnitScope } from "store/scope/actions"

import { getStoredAccess, me as userServiceMe } from "services/vitta-core/user"
import SelectUnitScopeModal from "components/Common/SelectUnitScopeModal"
import CustomSweetAlert from "components/Common/CustomSweetAlert"
import ToastNotification from "components/Common/ToastNotification"
import AutoUpdater from "components/Common/AutoUpdater"

const App = () => {
    console.log("App.js")

    const [loading, setLoading] = useState(true);

    const Layout = VerticalLayout

    const dispatch = useDispatch()
    
    const Access = useSelector(state => state.auth.access);

    useEffect(() => {
        console.log('" App useeffect')
        const loadStoredAccess = async () => {
            let storedAccess = null;
            try {
                storedAccess = await getStoredAccess()
                if (storedAccess) dispatch(userLoginSuccess(storedAccess))

                let unitScope = localStorage.getItem("vittanet.scope.unit");
                if(unitScope) unitScope = JSON.parse(unitScope);
                if(unitScope) dispatch(setUnitScope(unitScope));
                
            } catch (e) {
                console.log(e)
            } //Enviar erro para o Sentry

            if(!storedAccess) setLoading(false); //Não tem acesso no storage. Libera a tela
        }

        loadStoredAccess()
    }, [])

    useEffect(() => {

        let checkAccess = async () => {
            if(Access?.user && loading)
            {   
                //check if token is valid, getting user data
                //When api return 401 in any request, SPA redirect to login page
                let data = await userServiceMe();

                if(data && data.id) setLoading(false); //Acesso do storage chegou no redux, Libera a tela
            }
        }

        //Check Stored Access
        checkAccess();

    }, [Access])

    const renderRoutes = () => {
        return (
            <Switch>
                {authRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={false}
                    />
                ))}

                {userRoutes.map((route, idx) => (

                    <Authmiddleware
                    
                        path={route.path}
                        layout={Layout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={true}
                        exact
                        user={Access?.user}
                    />
                    
                ))}
            </Switch>
        )
    }

    if(loading) return (<>
        <Row style={{top: "50%", left: "50%", position: "absolute"}}>  
           <Col sm="12">
           <div className="spinner-border text-primary" role="status">
       <span className="sr-only">Loading...</span>
     </div> 
           </Col>
           </Row></>);

    return (
        <React.Fragment>
            <CustomSweetAlert />
            <ToastNotification />
            <SelectUnitScopeModal />
            <AutoUpdater />
            {renderRoutes()}
        </React.Fragment>
    )
}

export default App
