import React, { useState, useEffect } from "react"
import { Modal, Button, Input, Label, Row, Col } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const ModalSave = ({ show, onClose, handleSave, data }) => {
    const [inputValue, setInputValue] = useState()
    const handleTitleModel = () => {
        handleSave(inputValue, data)
    }
    useEffect(() => {
        if (show) {
            clearModal()
        }
    }, [show])

    const clearModal = () => {
        setInputValue(data?.title)
    }
    return (
        <Modal isOpen={show} centered={true}>
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Modelos{" "}
                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                    </h5>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <Row className="modal-body">
                <Col xs="12">
                    {" "}
                    <Label>
                        {" "}
                        <strong>
                            Qual Título deseja atribuir a esse Modelo?
                        </strong>{" "}
                    </Label>
                    <Input
                        type="text"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                    ></Input>
                </Col>
            </Row>

            <div className="card-body" align="right">
                <button
                    type="button"
                    className="btn btn-dark waves-effect mr-2"
                    data-dismiss="modal"
                    onClick={() => handleTitleModel()}
                >
                    <span className="text-white"> Criar</span>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="btn btn-info waves-effect"
                    data-dismiss="modal"
                >
                    <span className="text-white"> Fechar</span>
                </button>
            </div>
        </Modal>
    )
}

export default ModalSave
