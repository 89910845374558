import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if(filter.group_id && filter.group_id.length>0) query += "&group_id=" + filter.group_id.join(',')//grupo do item
    if (filter.item_id) query += "&item_id=" + filter.item_id
    if(filter.type) query += "&type=" + filter.type // type do item(tipo do item(produto ou serviço))
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.status) query += "&status=" + filter.status
    if(filter.id && filter.id.length > 0) query += "&id=" + filter.id.join(',')
    if(filter.origin && filter.origin != '') query += `&origin=${filter?.origin}`
    if(filter.needed_price) query += "&needed_price=true" 
    if(filter.needed_stages) query += "&needed_stages=true"
   
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/items` +
        query,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/items/${id}/edit`,
        vtAuth: true,
    })
}

const update = async (id, price_items_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/items/${id}`,
        data: price_items_data,
        vtAuth: true
    })
}

const create = async price_items_data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/items`,
        data: price_items_data,
        vtAuth: true
    })
}

export { search, loadDataEditform, create, update }
