import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Modal,
    CardBody,
    Label,
    FormGroup,
    Input,
    UncontrolledTooltip,
    Button,
    Table,
} from "reactstrap"
import { getPendentServicesStages } from "services/vitta-core/sales-order"
import { useDispatch } from "react-redux"
import uniqid from "uniqid"
import { showSweetAlertDialog, showToast } from "store/actions"
import NumberFormat from "react-number-format"
import { Field } from "formik"
const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {

            const newItems = [...props.itens];
            
            newItems[props.index].input_price = option.floatValue * 100;

            props.changeItens(newItems)
        }}
        {...props}
    />
)

const HandleSelectItemsToPayModal = ({
    show,
    data,
    setControlOdontologicData,
    onClose,
}) => {
    const [joinItems, setJoinItems] = useState([])
    const [chosenItems, setChosenItems] = useState([])
    const [totalChosenProcedures, setTotalChosenProdecures] = useState(0)
    const dispatch = useDispatch()
    const loadData = async () => {
        try {
            let joinedItems = []
            let totalAmount = 0;
            let totalExpected = data.total_order * 100
            let pendentDataToPay = await getPendentServicesStages(data?.id)
            if (pendentDataToPay && pendentDataToPay?.length > 0) {
                let lastItemIndex = pendentDataToPay.length - 1;
                let isLastItem = false;
                for (let i = 0; i < pendentDataToPay?.length; i++) {
                    let useItem = pendentDataToPay[i]
                    let tamVt = useItem?.available_stages?.length
                    if (
                        useItem?.available_stages?.length <= 0 &&
                        useItem?.available_stages?.length <= 0
                    ) {
                        tamVt = 1
                    }
                    for (let j = 0; j < tamVt; j++) {
                        if (i == lastItemIndex && j == tamVt - 1) {
                            isLastItem = true;
                        }
                        let useStage = useItem?.available_stages[j]
                       
                        let amount_paid = 0
                        if (useItem?.sales_order_payment_item?.length > 0) {
                            if (useItem?.available_stages.length > 0) {

                                const stageId = useStage.stage_id
                                const onlyStageIdPayment = useItem.sales_order_payment_item.filter(e => e.stage_id == stageId)
                                for (let i = 0; i < onlyStageIdPayment?.length; i++) {
                                    amount_paid += onlyStageIdPayment[i]?.amount_paid
                                }

                            } else {
                                for (let i = 0; i < useItem?.sales_order_payment_item.length; i++) {
                                    amount_paid += useItem?.sales_order_payment_item[i].amount_paid
                                }
                            }

                        }    
                        let priceRef = useStage?.percent_value
                        ? Math.trunc(
                            (useItem?.price *
                                useItem?.quantity *
                                (useStage?.percent_value / 10000)) - amount_paid
                        )
                        : Math.trunc(
                            (useItem?.price * useItem?.quantity) - amount_paid
                        );

                        if (!isLastItem) {
                            totalAmount += priceRef;
                        } else {
                            priceRef = Math.round(totalExpected - totalAmount);
                            
                        }
                        let dataToPush = {
                            uniq_iq: uniqid(),
                            sales_order_id: useItem?.doc_id,
                            sales_order_item_id: useItem?.id,
                            item_id: useItem?.item?.id,
                            item_name: useItem?.item?.name,
                            quantity: useItem?.quantity,
                            price_ref: priceRef,

                            price: isLastItem? Math.trunc(priceRef): //math.trunc aqui pois esse price serve para fazer comparacores e nao é alterado em input, por isso precisa estar com trunc
                            useStage?.percent_value
                                ? Math.trunc(
                                    useItem?.price *
                                    useItem?.quantity *
                                    (useStage?.percent_value / 10000)
                                )
                                : Math.trunc(
                                    useItem?.price * useItem?.quantity
                                ),
                            // price_table:
                            //     useItem?.price_table / useItem.total_stages,
                            tooth_number: useItem?.tooth_number,
                            price_table_id: useItem?.price_table_id,
                            price_lab: useItem?.price_lab,
                            stage_id: useStage?.stage_id,
                            stage_name: useStage?.procedure_stage?.name,
                            percent_stage_value: useStage?.percent_value,
                            stage_sequence: useStage?.stage_sequence,
                            input_price: priceRef,
                        }
                        joinedItems.push(dataToPush)
                    }
                }
            }
        
            setJoinItems(joinedItems)
            setChosenItems([])
        } catch (e) {
            console.log(e)
        }
    }
    const handleChoseAll = () => {
        setChosenItems([...chosenItems].concat([...joinItems]))
        setJoinItems([])
    }
    const handleRemoveAll = () => {
        setJoinItems([...joinItems].concat([...chosenItems]))
        setChosenItems([])
    }
    const handleChooseItem = item => {
        let data = [...chosenItems]
        if (data.find(e => e.uniq_iq == item.uniq_iq)) return
        data.push(item)
        setChosenItems(data)
        setJoinItems([...joinItems].filter(e => e?.uniq_iq != item?.uniq_iq))
    }
    const handleRemoveItem = item => {
        setChosenItems(
            [...chosenItems].filter(e => e?.uniq_iq != item?.uniq_iq)
        )
        let data = [...joinItems]
        let verifyIem = data.find(e => e?.uniq_iq == item?.uniq_iq)
        if (verifyIem) return
        data.push({ ...item })
        setJoinItems(data)
    }
    const calcChosenProcedures = () => {
        let total = 0
        for (let i = 0; i < chosenItems?.length; i++) {
            total += chosenItems[i]?.input_price
        }
        // //arrendondar se necessário
        // if (joinItems?.length <= 0) {
        //     if (total < data?.total_order * 100) {
        //         let items = [...chosenItems]
        //         let diff = data?.total_order * 100 - total
        //         items[0].price = items[0]?.price + diff
        //         setChosenItems(items)
        //     }
        // }
        total = Math.round(total);
        setTotalChosenProdecures(total)
    }

    const onSubmit = () => {
        // let items = [...chosenItems];
        // if(joinItems?.length<=0)
        // {
        //     if((totalChosenProcedures) < (data?.total_order*100))
        //     {
        //         let diff = (data?.total_order*100) - (totalChosenProcedures);
        //         items[0].price = items[0]?.price + diff;
        //     }
        // }
        const items = [...chosenItems];

        //arrendondar se necessário
        let total = 0
        let diff = 0
        for (let i = 0; i < chosenItems?.length; i++) {
            total += chosenItems[i]?.input_price
        }
        // if (joinItems?.length <= 0) { ja estou arrendonda usando o metodo de ajustar o valor do ultimo item
        //     let roundedTotalOrder = Math.round(data?.total_order * 100);         
        //     if ((roundedTotalOrder-total)<=1) {
        //         diff = roundedTotalOrder - total;
        //         items[0].input_price = items[0]?.input_price + diff;
        //         items[0].price = items[0]?.price + diff;
        //         items[0].price_ref = items[0]?.price_ref + diff;
        //         setChosenItems(items);
        //     }
        // }



        for (let item of items) {
            if (item.input_price <= 0) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: 'Valor do serviço não pode ser menor ou igual a 0',
                        type: "error",
                        showConfirm: true,
                    })
                )

            }


        
            if (item.input_price > item.price) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: 'Valor do serviço não pode ser maior que o valor total',
                        type: "error",
                        showConfirm: true,
                    })
                )

            }


            if (item.input_price > item.price_ref) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: 'Valor do serviço não pode ser maior que o valor de referência',
                        type: "error",
                        showConfirm: true,
                    })
                )

            }
        }

        let dataToReturn = {
            totalSelectedItems: total+diff,
            selectedItems: items,
        }
        onClose({ response: dataToReturn })
    }
    useEffect(() => {
        calcChosenProcedures()
    }, [chosenItems])

    useEffect(() => {
        if (show) {
            loadData()
        }
    }, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            style={{ maxWidth: "85%" }}
            className="modal-custom-size"
        >
            <CardBody>
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>

                <Row>
                    <Col sm="6">
                        <div className="">
                            <div className="" id="">
                                <h3>O que deseja pagar? </h3>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className="modal-body">
                    <Row>
                        <Col sm="6">
                            <Row>
                                <Col sm="6">
                                    <h4 className="text-info">Pendentes</h4>
                                </Col>
                                <Col sm="6" align="end">
                                    <a
                                        href="#"
                                        style={{
                                            position: "relative",
                                            top: "8px",
                                        }}
                                        onClick={() => {
                                            handleChoseAll()
                                        }}
                                    >
                                        Escolher todos
                                    </a>
                                </Col>
                            </Row>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Procedimento</th>
                                        <th>Dente</th>
                                        <th>Fase</th>
                                        <th>Valor</th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {joinItems?.map((itemJoined, index) => (
                                        <tr key={"ChosenItem" + index}>
                                            <td>{itemJoined?.item_name}</td>
                                            <td>{itemJoined?.tooth_number}</td>
                                            <td>{itemJoined?.stage_name}</td>
                                            <td>
                                                {"R$ " +
                                                    new Intl.NumberFormat(
                                                        "pt-br",
                                                        {
                                                            style: "decimal",
                                                            currency: "BRL",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ).format(
                                                        parseInt(
                                                            (itemJoined?.price_ref)
                                                        ) / 100
                                                    )}
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "25px",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <strong className="text-info">
                                                        <i
                                                            onClick={() => {
                                                                handleChooseItem(
                                                                    itemJoined
                                                                )
                                                            }}
                                                            className="fas fa-arrow-right"
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                    </strong>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm="6">
                            <Row>
                                <Col sm="6">
                                    <h4 className="text-success">Escolhidos</h4>
                                </Col>
                                <Col sm="6" align="end">
                                    <a
                                        href="#"
                                        style={{
                                            position: "relative",
                                            top: "8px",
                                        }}
                                        onClick={() => {
                                            handleRemoveAll()
                                        }}
                                    >
                                        Remover todos
                                    </a>
                                </Col>
                            </Row>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Procedimento</th>
                                        <th>Dente</th>
                                        <th>Fase</th>
                                        <th>Valor</th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chosenItems?.map((itemChosen, index) => (
                                        <tr key={"quoteitem" + index}>
                                            <td>{itemChosen?.item_name}</td>
                                            <td>{itemChosen?.tooth_number}</td>
                                            <td>{itemChosen?.stage_name}</td>
                                            <td>
                                                <Field
                                                    name={`item${index}${itemChosen?.item_name}.input_price`}
                                                    defaultValue={
                                                        itemChosen?.price_ref / 100
                                                    }
                                                    displayType={
                                                        "input"
                                                    }
                                                    thousandSeparator={
                                                        "."
                                                    }
                                                    decimalSeparator={
                                                        ","
                                                    }
                                                    decimalScale={
                                                        2
                                                    }
                                                    fixedDecimalScale={
                                                        true
                                                    }
                                                    allowEmptyFormatting={
                                                        true
                                                    }
                                                    prefix={
                                                        "R$ "
                                                    }
                                                    itens={chosenItems}
                                                    changeItens={setChosenItems}
                                                    index={index}
                                                    className={`form-control`}
                                                    component={
                                                        CurrencyInput
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "25px",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <strong className="text-success">
                                                        <i
                                                            onClick={() => {
                                                                handleRemoveItem(
                                                                    itemChosen
                                                                )
                                                            }}
                                                            className="fas fa-arrow-left"
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                    </strong>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                <hr />
                <Row className="">
                    <Col sm="5" align="start">
                        {" "}
                        <strong className="text-info">
                            Total:{" "}
                            {"R$ " +
                                new Intl.NumberFormat("pt-br", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    parseInt(totalChosenProcedures) / 100
                                )}
                        </strong>{" "}
                    </Col>
                    <Col align="end">
                        <Button
                            size="sm"
                            type="button"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Fechar
                        </Button>{" "}
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            onClick={() => {
                                dispatch(
                                    showSweetAlertDialog({
                                        title: "Atenção ",
                                        text:
                                            "Tem certeza que deseja pagar estes items?",
                                        type: "warning",
                                        onConfirmAction: () => {
                                            onSubmit()
                                        },
                                        showCancel: true,
                                        showConfirm: true,
                                        cancelBtnText: "Não",
                                        confirmBtnText: "Sim",
                                    })
                                )
                            }}
                        >
                            Atualizar valores
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Modal>
    )
}

export default HandleSelectItemsToPayModal
