import React, { useMemo, useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { search as userServiceSearch } from "services/vitta-core/user"
import { paymentsReport as paymentsReportSalesOrderPaymentService } from "services/vitta-core/sales-order-payment"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import { useHistory } from "react-router-dom"
import ReportPaymentReceived from "./report"
import { post } from "helpers/fakebackend/config/api_helper"
import {paymentsReportSalesOrderPaymentServicerExport} from "services/vitta-core/sales-order-payment"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

import debounce from "debounce-promise"
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const PaymentReceived = () => {
    const history = useHistory()

    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
        result: null
    })

    const [firstPage, setFirstPage] = useState(true)
    useEffect(() => {
        if(!firstPage){
            setReportControl({
                show: false,
                filter: null,
                result:null,
            })
            setFirstPage(true)    
        }
    }, [firstPage]);

    const userAuth = useSelector(state => state.auth.access?.user)
    const dispatch = useDispatch()
    const [controlState, setControlState] = useState(false)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)

    const onSubmit = async (data_form, actions) => {
        if(!data_form.unit_id){ // sem unidade, consulta fica sem sales_order
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Insira a Unidade",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
        let unit_id = data_form.unit_id.map(item => item.value);
        let auxData = {}
        auxData.created_user = data_form.user_id?.value
            ? { id: data_form.user_id.value, name: data_form.user_id.label }
            : null
        auxData.unit = unit_id.join(',')
        auxData.start_created_at = data_form.start_created_at
        auxData.end_created_at = data_form.end_created_at
        //Depois enviar via request
        // history.push(
        // {
        //     pathname: '/pagamentos-recebidos/relatorio',
        //     search: '?query=abc',
        //     state: { detail: 'some_value' }
        // });
        try {
            if (data_form.format?.value == "excel"){
                setControlState(true)
                let result = await paymentsReportSalesOrderPaymentServicerExport(
                    {
                        created_user_id: auxData.created_user?.id,
                        unit_id: auxData.unit,
                        start_created_at: auxData?.start_created_at,
                        end_created_at: auxData?.end_created_at,
                    },
                )
                var headers = result.headers
                var blob = new Blob([result.data], {
                    type: headers["content-type"],
                })
                var link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = "Pagamento Recebidos"
                link.click()
                setControlState(false)
                return
            }
            
                let startDate = auxData.start_created_at
                ? new Date(
                    auxData.start_created_at + " 00:00:00"
                  ).toISOString()
                : null
                let endDate = auxData.end_created_at
                ? new Date(
                    auxData.end_created_at + " 23:59:59"
                ).toISOString()
                : null
                setControlState(true)     
                
                let result = await paymentsReportSalesOrderPaymentService(
                    {
                        created_user_id: auxData.created_user?.id,
                        unit_id: auxData.unit,
                        start_created_at: startDate,
                        end_created_at: endDate,
                    },
                    1,
                    1000
                )
                //console.log(result)
                setControlState(false)
                setReportControl({
                    show: true,
                    filter: auxData,
                    result: result,
                })  
            
            
        } catch (e) {
            setControlState(false)
            if(e?.response?.data?.error_code==='just_one_unit'){
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "É permitido apenas uma unidade no modo visualização",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            if (e?.response?.status == 422) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Insira um período corretamente",
                        type: "error",
                        showConfirm: true,
                        
                    })
                )
            }
        }

       
    }

    if (reportControl.show) {
        return <ReportPaymentReceived filter={reportControl.filter} state={setFirstPage} result={reportControl.result} />
    }else{
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Pagamentos Recebidos/Relatórios" />
                        <Row>
                            <Col xs="12">
                                <Row className="d-flex justify-content-center">
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <h3
                                                    align="center"
                                                    className="mb-4 mt-4"
                                                >
                                                    Pagamentos Recebidos
                                                </h3>
                                                <Formik
                                                    initialValues={{
                                                        user_id: null,
                                                        unit_id: null,
                                                        start_created_at: null,
                                                        end_created_at: null,
                                                        format: {
                                                            label: "Visualização",
                                                            value: "view",
                                                        },
                                                    }}
                                                    onSubmit={onSubmit}
                                                >
                                                    {formProps => (
                                                        <Form
                                                            method="POST"
                                                            action="/pagamentos-recebidos/relatorio"
                                                        >
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-1"
                                                                >
                                                                    <Label>
                                                                        Usuário
                                                                    </Label>
                                                                    <Field
                                                                        name="user_id"
                                                                        placeholder="Selecionar Usuário"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        defaultValue={
                                                                            formProps.user_id
                                                                        }
                                                                        loadOptions={
                                                                            debounceOptionsUser
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="d-flex justify-content-center mt-2">
                                                                <Col sm="8">
                                                                    <Label>
                                                                        Unidade
                                                                    </Label>
                                                                    <Field
                                                                        name={`unit_id`}
                                                                        placeholder="Selecione a Unidade"
                                                                        classNamePrefix="select2-selection"
                                                                        options={
                                                                            unitsOptions
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-2"
                                                                >
                                                                    <Label>
                                                                        Data de
                                                                        recebimento
                                                                        inicial{" "}
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "start_created_at"
                                                                        }
                                                                        type="date"
                                                                    ></Input>
                                                                </Col>
                                                            </Row>
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                    sm="8"
                                                                    className="mt-2"
                                                                >
                                                                    <Label>
                                                                        {" "}
                                                                        Data de
                                                                        recebimento
                                                                        final
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "end_created_at"
                                                                        }
                                                                        type="date"
                                                                    ></Input>
                                                                </Col>
                                                            </Row>
                                                            <Row className="d-flex justify-content-center">
                                                                <Col sm="8" className="mt-2">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Formato
                                                                            de Saída
                                                                        </Label>
                                                                        <Field
                                                                            name="format"
                                                                            placeholder={
                                                                                "Selecione"
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    label:
                                                                                        "Visualização",
                                                                                    value:
                                                                                        "view",
                                                                                },
                                                                                {
                                                                                    label:
                                                                                        "Excel",
                                                                                    value:
                                                                                        "excel",
                                                                                },
                                                                            ]}
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row className="d-flex justify-content-center">
                                                                <Col
                                                                sm="2"
                                                                align="start"
                                                                className="mt-4"
                                                                >
                                                                    {controlState ? (
                                                                        <div
                                                                            className="spinner-border text-dark"
                                                                            role="status"
                                                                        >
                                                                            <span className="sr-only">
                                                                                Loading...
                                                                            </span>
                                                                        </div>
                                                                    ) : null}
                                                                </Col>
                                                                <Col
                                                                    sm="6"
                                                                    align="end"
                                                                    className="mt-4"
                                                                >
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        disabled={controlState}
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
        
    }

    
}

export default PaymentReceived
