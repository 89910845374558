import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Input,
    Label,
    Modal,
    FormGroup,
} from "reactstrap"
import AsyncSelect from "react-select/async"
import { Formik, Field, Form, FieldArray } from "formik"
import { search as itemServiceSearch } from "services/vitta-core/item"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import ModalCopyAnother from "./ModalCopyAnother"
import { showSweetAlertDialog } from "store/layout/actions"
import debounce from "debounce-promise"

const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />

const SwitchInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <div className="custom-switch custom-switch-lg mb-3" dir="ltr">
        <Input
            type="checkbox"
            className="custom-control-input"
            {...field}
            {...props}
            /*onChange={value => { 
                setFieldValue(field.name, value.target.checked)
            }}*/
            onClick={() => {
                /*this.setState({
                  toggleSwitchLarge: !this.state
                    .toggleSwitchLarge,
                })*/
            }}
        />
        <label className="custom-control-label" htmlFor={props.id}></label>
    </div>
)

const TexttimeInput2 = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="time" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        //{                   if() {} setfield }
        onChange={option => {
            if (option.value == "data") {
                props.setState(true)
            }
            setFieldValue(field.name, option)
        }}
    />
)
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const optionsWeekDay = [
    { value: "segunda", label: "Segunda-Feira" },
    { value: "terca", label: "Terça-Feira" },
    { value: "quarta", label: "Quarta-Feira" },
    { value: "quinta", label: "Quinta-Feira" },
    { value: "sexta", label: "Sexta-Feira" },
    { value: "sabado", label: "Sábado" },
    { value: "domingo", label: "Domingo" },
    { value: "data", label: "Data Específica" },
]
const defaultModalItemValues = {
    service_id: null,
    duration: 0,
    accept_online_appointment: true,
    is_person_execution: true,
    is_online_execution: false,
}

const PeriodModal = ({ show, onClose, data, add, remove }) => {
    const [State, setState] = useState(false)
    const dispatch = useDispatch()
    const User = useSelector(state => state.auth.access.user)
    const [handleCopyModal, setHandleCopyModal]= useState({
        show: false,
        data: null,
    })
    //console.log("data PeriodModal", data)
    if (!show) return <></>

    const ModalPeriodsDefaultValues = {
        start: "null",
        end: "null",
        accept_fit: false,
        date: null,
        services: [defaultModalItemValues],
    }

    if (data.item && data.item.data) {
        if (data.isOverlap) ModalPeriodsDefaultValues.date = data.item.data.date

        ModalPeriodsDefaultValues.start = data.item.data.start
        ModalPeriodsDefaultValues.end = data.item.data.end
        ModalPeriodsDefaultValues.accept_fit = data.item.data.accept_fit
        ModalPeriodsDefaultValues.services = data.item.data.services
    }


    const loadItemInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1;
                let response = await itemServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                        group_id: element.group_id
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsItem = debounce(loadItemInputOptions, 600)

    const onSubmit = form_data => {
        let formSubmit = JSON.parse(JSON.stringify(form_data))
        //todo - nao deixar incluir data anterior a atual
        //todo - nao deixar incluir períodos que entram em conflito de horário
        //duração mínima 10min
        const services = formSubmit?.services;

        if (services) {
            for (const element of services) {
                if(!element.is_person_execution && !element.is_online_execution){
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Configuração Inválida!",
                            text:
                                "É necessário selecionar pelo menos um modo de execução!",
                            type: "error",
                        })
                    );
                }
                if (element?.duration < 10) {
                    if (element?.service_id?.group_id == 6 && (element.service_id?.label?.toLowerCase().includes('usg') || element.service_id?.label?.toLowerCase().includes('ultrassom'))
                    ) {
                        if (element?.duration < 8) {
                            return dispatch(
                                showSweetAlertDialog({
                                    title: "Configuração Inválida!",
                                    text:
                                        "Existem serviços de Ultrassonografia com duração mínima menor que 8 minutos, verifique e tente novamente!",
                                    type: "error",
                                })
                            );
                        }
                    } else {
                        return dispatch(
                            showSweetAlertDialog({
                                title: "Configuração Inválida!",
                                text:
                                    "Existem serviços com duração mínima menor que 10 minutos, verifique e tente novamente!",
                                type: "error",
                            })
                        );
                    }
                }
            }
        }

        let Start = formSubmit.start.split(":")
        let End = formSubmit.end.split(":")
        let DurationMin = (parseInt(End[0])*60 + parseInt(End[1])) - (parseInt(Start[0])*60 + parseInt(Start[1])) //minutos End - minutos Start
        if (DurationMin<0){
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Horário de Início não pode ser maior que horário final.",
                    type: "error",
                })
            )
        }

        //todo, verificar se tem pelo menos um serviço para salvar e se o campo duração é maior que 0
        for(let i =0; i<formSubmit.services.length; i++){
            let useService = formSubmit.services[i]
            if (!useService.service_id || useService.duration < 1) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Necessário inserir pelo menos um serviço e a duração precisa ser maior que 0.",
                        type: "error",
                    })
                )
            }
            if(useService.duration> DurationMin){ //BUG DURACAO MAIOR Q PERIODO
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Duração do serviço maior do que o período.",
                        type: "error",
                    })
                )
            }
        }

        if (data.isOverlap || State) {
            if (!formSubmit.date || formSubmit.date.length == 0) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Informe uma data válida.",
                        type: "error",
                    })
                )
            }

            if (!State) {
                if (data.item) {
                    remove(data.item.index)
                    add(formSubmit)
                } else {
                    add(formSubmit)
                }

            } else {
                data.overlap.push(formSubmit)
            }


            onClose()
            setState(false)
            return;
        }
        if (data?.iscopy) {
            if (!formSubmit.weekday) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Selecione um dia da semana.",
                        type: "error",
                    })
                )
            }

            if (data.periods[formSubmit.weekday.value].length > 0) {
                for (let i = 0; i < data.periods[formSubmit.weekday.value].length; i++) {
                    let valor = data.periods[formSubmit.weekday.value][i]
                    let DtStart = valor.start.split(":")
                    let DtEnd = valor.end.split(":")
                    let FormStart = (parseInt(Start[0])*60 + parseInt(Start[1]))
                    let FormEnd = (parseInt(End[0])*60 + parseInt(End[1]))
                    if (FormStart >= (parseInt(DtStart[0])*60 + parseInt(DtStart[1])) && FormStart < (parseInt(DtEnd[0])*60 + parseInt(DtEnd[1])) ||
                        FormEnd > (parseInt(DtStart[0])*60 + parseInt(DtStart[1])) && FormStart < (parseInt(DtEnd[0])*60 + parseInt(DtEnd[1]))) {
                        return dispatch(
                            showSweetAlertDialog({
                                title: "Ops... ",
                                text:
                                    "Identificado um conflito de horário para esse dia da semana. Verifique os dados inseridos e tente novamente.",
                                type: "error",
                            })
                        )
                    }
                }

            }
            data.periods[formSubmit.weekday.value].push(formSubmit);
            onClose()
            setState(false)
            return;

        }

        formSubmit.weekday = data.weekday //O weekday será predefinido, nao virá do form. devido utilizar funções add e remove da propria lista do weekday correspondente

        if (!formSubmit.weekday.value) return alert("opsss dia da semana")

        let actualPeriods = data.periods[formSubmit.weekday.value]
        /*if (data.item) {
            //Alteração
            actualPeriods = data.periods[formSubmit.weekday.value].filter(
                element =>
                    element.start != formSubmit.start &&
                    element.end != formSubmit.end
            )


        }*/
        var start_time_vality = formSubmit.start
        var end_time_vality = formSubmit.end
        var start_time_init = parseInt(formSubmit.start.substr(0, 2)) * 60
        var start_time_finaly = parseInt(formSubmit.start.substr(3, 2))
        var end_time_init = parseInt(formSubmit.end.substr(0, 2)) * 60
        var end_time_finaly = parseInt(formSubmit.end.substr(3, 2))

        start_time_vality = start_time_init + start_time_finaly
        end_time_vality = end_time_init + end_time_finaly


        for (let i = 0; i < actualPeriods.length; i++) {
            let usePeriod = actualPeriods[i]

            var start_time_vality_period = usePeriod.start
            var end_time_vality_period = usePeriod.end

            var start_time_init_period =
                parseInt(usePeriod.start.substr(0, 2)) * 60
            var start_time_finaly_period = parseInt(
                usePeriod.start.substr(3, 2)
            )
            var end_time_init_period = parseInt(usePeriod.end.substr(0, 2)) * 60
            var end_time_finaly_period = parseInt(usePeriod.end.substr(3, 2))

            start_time_vality_period =
                start_time_init_period + start_time_finaly_period
            end_time_vality_period =
                end_time_init_period + end_time_finaly_period

            /* if (start_time_vality >= end_time_vality) { //ja foi verificado com durationMin<0
                 return dispatch(
                     showSweetAlertDialog({
                         title: "Ops... ",
                         text:
                             "Horário de Início não pode ser maior que horário final.",
                         type: "error",
                     })
                 )
             }*/

            if (
                !(
                    start_time_vality >= end_time_vality_period ||
                    end_time_vality <= start_time_vality_period
                ) && !(data.item && data.item.data && data.item.data && data.item.data.start == usePeriod.start && data.item.data.end == usePeriod.end)
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Identificado um conflito de horário para esse dia da semana. Verifique os dados inseridos e tente novamente.",
                        type: "error",
                    })
                )
            }
        }

        //console.log("origin data", data);
        //console.log("onSubmit data", formSubmit);

        if (data.item) {
            remove(data.item.index)
            add(formSubmit)
        } else {
            add(formSubmit)
        }

        onClose()
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={ModalPeriodsDefaultValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({ values }) => (
                    <Modal
                        size="xl"
                        isOpen={true}
                    //toggle={() => { }
                    >
                        <Form>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Configurações do Período
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                        setState(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col xs="12">
                                        <Card className="shadow-sm">
                                            <CardBody>
                                                <CardTitle className="mb-4">
                                                    Dados do Período
                                                </CardTitle>
                                                <Row> 
                                                    {!data.isOverlap && !State ?
                                                        <Col md="7">
                                                            <Label>Dia da Semana</Label>
                                                            <Field
                                                                name="weekday"
                                                                component={
                                                                    ReactSelectInput
                                                                }
                                                                isMulti={false}
                                                                options={optionsWeekDay}
                                                                defaultValue={
                                                                    !data.iscopy ? data.weekday : { label: "Selecionar", value: null }//weekday será predefinido
                                                                }
                                                                isDisabled={data?.iscopy ? false : true}
                                                                setState={setState}
                                                            />
                                                        </Col>
                                                        :
                                                        <Col md="5">
                                                            <Label>Data</Label>
                                                            <Field
                                                                name="date"
                                                                component={
                                                                    DateInput
                                                                }
                                                                defaultValue={
                                                                    data?.item?.date
                                                                }
                                                            />
                                                        </Col>
                                                    }
                                                    <Col sm='12'  md="2">
                                                        <Label>
                                                            Aceita Encaixe?
                                                        </Label>
                                                        <Field
                                                            id="period_accept_fit"
                                                            name={`accept_fit`}
                                                            checked={
                                                                values.accept_fit
                                                            }
                                                            component={SwitchInput}
                                                        />
                                                    </Col>
                                                    {/* {values.accept_fit ?(
                                                        
                                                        <Col md="3" >
                                                        <Label>
                                                            Encaixes Dentro do Período
                                                        </Label>

                                                        <Field
                                                            name="within_period"
                                                            component={
                                                                NumberInput
                                                            }
                                                            defaultValue={
                                                                values.within_period
                                                            }
                                                            
                                                        />
                                                    </Col>
                                                    
                                                    ):''} */}
                                                </Row>
                                                <Row>
                                                    <Col md="5" className="mt-3">
                                                        <Label>
                                                            Início do Período(hora)
                                                        </Label>
                                                        <Field
                                                            name="start"
                                                            placeholder="Selecionar Período Inicial"
                                                            component={
                                                                TexttimeInput2
                                                            }
                                                            defaultValue={
                                                                values?.start
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md="5" className="mt-3">
                                                        <Label>
                                                            Fim do Período(hora)
                                                        </Label>
                                                        <Field
                                                            name="end"
                                                            placeholder="Selecionar Período Final"
                                                            component={
                                                                TexttimeInput2
                                                            }
                                                            defaultValue={
                                                                values?.end
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>

                                        <Card className="shadow-sm">
                                            <CardBody>
                                                <CardTitle>
                                                    Serviços Disponíveis
                                                </CardTitle>
                                                <CardSubtitle className="mb-3 text-info" style={{fontSize:"12px"}}><b>Duração mínima: 8 minutos para serviços de ultrassom e 10 minutos para os demais serviços.</b></CardSubtitle>
                                                <Row>
                                                    {/* Visível somente em tamanhos de tela md, lg, xl*/}
                                                    <Col lg="3" className="d-none d-lg-block">
                                                        <Label>Serviço</Label>
                                                    </Col>
                                                    <Col lg="2" className="d-none d-lg-block">
                                                        <Label>
                                                            Duração (minutos)
                                                        </Label>
                                                    </Col>
                                                    <Col lg="2" className="d-none d-lg-block">
                                                        <Label>
                                                            Agendamento Online
                                                        </Label>
                                                    </Col>
                                                    <Col lg="2" className="d-none d-lg-block">
                                                        <Label>
                                                        Execução Presencial
                                                        </Label>
                                                    </Col>
                                                    <Col lg="2" className="d-none d-lg-block">
                                                        <Label>
                                                        Execução Online
                                                        </Label>
                                                    </Col>
                                                    {/*<Col lg="2" className="d-none d-lg-block">
                                                        <Label>
                                                            Idade Mínima
                                                        </Label>
                                                        </Col>*/}
                                                    <Col lg="1" className="d-none d-lg-block"></Col>
                                                </Row>
                                                <Row>
                                                    {/* fim item fixo */}
                                                    <Col lg="12" className="">
                                                        <FieldArray
                                                            name="services"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {values &&
                                                                        values?.services
                                                                            ?.length > 0
                                                                        ? values.services.map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) => (
                                                                                <Row
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                   
                                                                                >
                                                                                    

                                                                                    <Col
                                                                                        lg="3"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                name={`services[${index}].service_id`}
                                                                                                placeholder="Selecione "
                                                                                                cacheOptions
                                                                                                defaultOptions
                                                                                                loadOptions={
                                                                                                    debounceOptionsItem
                                                                                                }
                                                                                                classNamePrefix="select2-selection"
                                                                                                component={
                                                                                                    AsyncSelectInput
                                                                                                }
                                                                                                isClearable
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg="2"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                name={`services[${index}].duration`}
                                                                                                component={
                                                                                                    NumberInput
                                                                                                }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg="2"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                name={`services[${index}].accept_online_appointment`}
                                                                                                id={`services[${index}].accept_online_appointment`}
                                                                                                checked={
                                                                                                    item.accept_online_appointment
                                                                                                }
                                                                                                component={
                                                                                                    SwitchInput
                                                                                                }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    
                                                                                    <Col
                                                                                        lg="2"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                name={`services[${index}].is_person_execution`}
                                                                                                id={`services[${index}].is_person_execution`}
                                                                                                checked={
                                                                                                    item.is_person_execution
                                                                                                }
                                                                                                component={
                                                                                                    SwitchInput
                                                                                                }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                    <Col
                                                                                        lg="2"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                name={`services[${index}].is_online_execution`}
                                                                                                id={`services[${index}].is_online_execution`}
                                                                                                checked={
                                                                                                    item.is_online_execution
                                                                                                }
                                                                                                component={
                                                                                                    SwitchInput
                                                                                                }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                           

                                                                                    <Col
                                                                                        lg="1"
                                                                                    >
                                                                                        <FormGroup>
                                                                                            <Button
                                                                                                color="danger"
                                                                                                onClick={() =>
                                                                                                    arrayHelpers.remove(
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-trash-alt"></i>
                                                                                            </Button>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        )
                                                                        : null}
                                                                    <br />
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            arrayHelpers.push(
                                                                                defaultModalItemValues
                                                                            )
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </Button>
                                                                    <Button
                                                                        color="warning"
                                                                        style={{ marginLeft: "10px" }}
                                                                        onClick={() => {
                                                                            setHandleCopyModal(
                                                                                {
                                                                                    remover: arrayHelpers.remove,
                                                                                    adicionar: arrayHelpers.push,
                                                                                    show: true,
                                                                                    data: values.services

                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-bolt"></i>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* fim linha */}
                                                <br></br>
                                            </CardBody>
                                        </Card>

                                        <div className="modal-footer">
                                            <Button
                                                type="submit"
                                                //    onClick={onSubmit2}
                                                data-dismiss="modal"
                                                color="primary"
                                            >
                                                Salvar
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    onClose()
                                                    setState(false)
                                                }}
                                                type="button"
                                                className="btn btn-danger waves-effect waves-light"
                                            >
                                                Cancelar
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Modal>
                )}
            </Formik>
            <ModalCopyAnother
                show={handleCopyModal.show}
                onClose={() => {
                    setHandleCopyModal({ show: false, data: null })
                }}
                data={handleCopyModal.data}
                remover = {handleCopyModal.remover}
                adicionar = {handleCopyModal.adicionar}>

            </ModalCopyAnother>
        </React.Fragment>

    )
}

export default PeriodModal
