import React from "react"
import {
    Modal,Row,Col,CardBody
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"



const ModalGenInvoices = ({ show, data, onClose }) => {

    return (
        <Modal
            isOpen={show}
            centered={true}
            size="xs"
            
        >
            <CardBody>
            <Row className="justify-content-center text-dark" style={{height:60}}>
                <Col xs={12} style={{textAlign:"center"}}>
              <h5>Aguarde, Notas fiscais em processo de Emissão!</h5>
              
                </Col>
                <div className="spinner-border text-primary align-items-center"  role="status" />

            </Row>
            </CardBody>
         
            
                
            
   
        </Modal>
    )
}

export default ModalGenInvoices
