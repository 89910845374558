import {
    Row,
    Col,
    Button,
    CardTitle,
    Label,
    Input,
    CardHeader,
    Collapse,
    FormGroup,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
    Spinner,
} from "reactstrap"
import { search as searchUnits } from "services/vitta-core/unit"
import React, { useEffect, useState } from "react"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import { showSweetAlertDialog } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { createEntity, deleteEntity, removePrescriptionMemed, getPdfMemed } from "services/vitta-core/attendance"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import ModalPrescriptions from "./ModalModels/Components/Modal"
import ModalSaveModels from "./ModalModels/Components/ModalSaveModels"
import handleMedicalFormUpdate from "pages/Patients/Detail/pages/GeneralAttendance/Functions/handleMedicalFormUpdate"
import MEDICAL_FORM_TYPES from "utils/consts/medical-form-types"
import { createModel } from "services/vitta-core/medical-forms-models"
import MEDICAL_FORMS_TYPES from "utils/consts/medical-form-types"
import RenderForm from "pages/Patients/Detail/pages/GeneralAttendance/components/renderForm"
import { getMemedInfo } from "services/vitta-core/professional"
const InputText = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="text"
        {...field}
        {...props}
        onBlur={e => {
            field.onBlur(e)

            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalPrescription"
            )
        }}
    />
)

const InputEditor = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => (
    <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        className="mb-2"
        onEditorStateChange={option => {
            setFieldValue(field.name, option)
        }}
        toolbar={{
            options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "image",
                "remove",
                "history",
            ],
        }}
        onBlur={() => {
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalPrescription"
            )
        }}
        editorState={props.editorState}
        readOnly={props.disabled}
    />
)

const lastFormState = {}
var auxArray = []
const MedicalPrescritions = ({
    items,
    defaultItem,
    datasyncstate,
    attendance_id,
    stateSync,
    patient_id,
    errors,
    touched,
    professional,
    patient,
    unit,
    setFieldValue
}) => {
    const [tab, setTab] = useState(0)
    const [modalPrescriptionData, setModalPrescriptionData] = useState({
        show: false,
        entity_type: null,
        data: null,
    })
    const [loading, setLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false)
    const [loadingButtonIndex, setLoadingButtonIndex] = useState(null);
    const userAuth = useSelector(state => state.auth.access?.user);
    const Scope = useSelector(state => state.scope)
    const [modalSaveModels, setModalSaveModels] = useState({
        show: false,
        data: null,
    })
    //const [auxArray, setAuxArray] = useState([])
    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    function formatarData(dataString) {
        if (!dataString) return ""
        const partesDaData = dataString.split("-");


        return `${partesDaData[2]}/${partesDaData[1]}/${partesDaData[0]}`;
    }

    const dispatch = useDispatch()
    const handleCreate = async data => {
        try {
            stateSync(0)
            let created = null

            let itemValue = {}

            itemValue.entity = "MedicalPrescription"
            itemValue.attendance_id = attendance_id
            itemValue.title = ""
            itemValue.text_object = ""

            if (data) {
                created = await createEntity(data, attendance_id)
            } else {
                created = await createEntity(itemValue, attendance_id)
            }

            itemValue.id = created.id
            datasyncstate(created?.form)
            stateSync(1)
            if (itemValue.id) {
                return itemValue
            }
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ocorreu algum problema ! ",
                    text:
                        "Houve um erro, tente novamente ou contate o suporte.",
                    type: "error",
                    showConfirm: true,
                })
            )
            stateSync(2)
        }
    }

    const handleDelete = async item => {
        let dataDelete = {
            entity: "MedicalPrescription",
        }
        stateSync(0)
        await deleteEntity(item.id, "MedicalPrescription", attendance_id)
        stateSync(1)
    }
    const handleDeleteMemed = async memedId =>{
        stateSync(0)
        await removePrescriptionMemed(professional.id, memedId)
        stateSync(1)
    }
    const handleBG = id => {
        let element = document.getElementById(id)
        element.classList.add("bg-info")
        element.classList.add("text-white")
    }
    const removeBG = id => {
        let element = document.getElementById(id)
        element.classList.remove("bg-info")
        element.classList.remove("text-white")
    }
    const viewForm = data => {
        let content = { ...data, unit: unit, created_at: items?.created_at, entity: "MedicalPrescription" }
        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true
        if (data?.text_object && data?.text_object != "") {
            content.text = draftToHtml(
                convertToRaw(data?.text_object.getCurrentContent())
            )
        }

        RenderForm({
            showProfessionalSignature,
            ShowPatientData,
            showPrintButton,
            patient,
            professional,
            content,
        })
    }
    async function printPDF(pdfUrl, index, memedId) {
        setCardLoading(true)
        setLoadingButtonIndex(index)
        let pdf = pdfUrl
        const script = document.getElementById("memedScript");
        if(!pdf && script){
            try {
                const result = await getPdfMemed(script?.dataset?.token, memedId)
                pdf = result.result
            } catch (error) {
                console.log(error)
                setCardLoading(false)
                setLoadingButtonIndex(null)
                if(error.response?.data?.error_code){
                            
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ops... ",
                            text:error.response.data.error_message,
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }
            }
            
        }
        const response = await fetch(pdf);
        const blob = await response.blob();


        const objectURL = window.URL.createObjectURL(blob);


        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        iframe.onload = function () {

            setTimeout(() => {
                iframe.contentWindow.print();
            }, 100);
        };

        iframe.src = objectURL;
        setCardLoading(false)
        setLoadingButtonIndex(null)
    }
    const sendMemedToBd = async(arrayHelpers, prescriptionData) =>{
        try {
            stateSync(0)
            const script = document.getElementById("memedScript");
            let created = null
            let itemValue = {}
    
            if (prescriptionData.reimpressao) return //nao criar registro duplicado no banco
            let integrationData =
            {
                "prescricao": {
                    "pacienteId": prescriptionData.prescricao?.paciente.id,
                    "additionalData": prescriptionData.prescricao?.additionalData,
                    "workplace": prescriptionData.prescricao?.workplace,
                    "prescriptionUuid": prescriptionData.prescricao?.prescriptionUuid,
                    "medicamentos": prescriptionData.prescricao?.medicamentos,
                    "id": prescriptionData.prescricao?.id,
                    "medicos_id": prescriptionData.prescricao?.medicos_id,
                    "prescricao_editada_id": prescriptionData.prescricao?.prescricao_editada_id,
                    "lme": prescriptionData.prescricao?.lme,
                    "opcoes_receituario_id": prescriptionData.prescricao?.opcoes_receituario_id,
                    "created_at": prescriptionData.prescricao?.created_at,
                    "signed": prescriptionData.prescricao?.signed,
                    "renew": prescriptionData.prescricao?.renew,
                    "version": prescriptionData.prescricao?.version,
                    "prescriptionType": prescriptionData.prescricao?.prescriptionType
                }
            }
    
    
            itemValue.entity = "MedicalPrescription"
            itemValue.attendance_id = attendance_id
            itemValue.integration_id = prescriptionData.prescricao?.id
            itemValue.integration_provider = 'Memed'
            itemValue.integration_data = JSON.stringify(integrationData)
            itemValue.prof_memed_id = script?.dataset?.token
    
    
            created = await createEntity(itemValue, attendance_id)
            itemValue.id = created.id
            itemValue.file = created?.form.file
            delete itemValue.prof_memed_id
            auxArray = [...items.itemsPrescriptions, itemValue]
          
            arrayHelpers.push(itemValue)
            datasyncstate(created?.form)
            stateSync(1)
            
    
        } catch (error) {
            console.log(error)
        }
    }
    const clickMemedButton = async (arrayHelpers) => {
        setLoading(true)

        try {
            if (typeof MdSinapsePrescricao === 'undefined') {
                await loadMemed(arrayHelpers)
            }


            if (MdSinapsePrescricao) {
                const unit = userAuth?.units?.find(e=>e.id==Scope.unit.id)
                await MdHub.command.send("plataforma.prescricao", "setPaciente", {
                    idExterno: `${patient.id}${patient.cpf}`,
                    nome: `${patient.name}`,
                    cpf: `${patient.cpf}`,
                    telefone: `${patient.mobile_phone ? patient.mobile_phone : ''}`,
                    data_nascimento: formatarData(patient.birth_date),
                    endereco: `${patient.addr_address}, ${patient.addr_number}`,
                    cidade: `${patient?.county?.name }`,  

                });
                if(unit){
                    await MdHub.command.send("plataforma.prescricao", "setWorkplace", {
                        city: unit.city,
                        state: unit.state,              
                        local_name: unit.name,
                        address: unit.address,
                        phone: `${unit.phone}`, 
                        
                      });
                }
                MdHub.event.remove('prescricaoImpressa')
                MdHub.event.add('prescricaoImpressa', async function (prescriptionData) {
                    await sendMemedToBd(arrayHelpers, prescriptionData )
                });
                
                
                MdHub.module.show("plataforma.prescricao");

            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Módulo Memed não encontrado.",
                        type: "warning",
                        showConfirm: true,
                    })
                )
            }



        } catch (e) {
            console.log(e);
            dispatch(
                showSweetAlertDialog({
                    title:
                        "Módulo Memed não encontrado.",
                    type: "warning",
                    showConfirm: true,
                })
            )
        } finally {
            setLoading(false)
        }
    }

    const loadMemed = (arrayHelpers) => {
        return new Promise(
            async (resolve, reject) => {

                if (!document.getElementById('memedScript')) {
                    try {
                        const profMemed = await getMemedInfo(professional.id)
                        const script = document.createElement("script");
                        script.type = "text/javascript";
                        script.id = "memedScript";
                        script.dataset.token = profMemed.token;
                        script.src = process.env.REACT_APP_MEMED_SCRIPT_URL;
                        script.dataset.color = "#2596be";
                        script.addEventListener("load", () => {
                            loadMemedFunctionality(arrayHelpers, resolve, reject)
                        });
                        document.body.appendChild(script);
                    } catch (error) {
                        setLoading(false)
                        if(error.response?.data?.error_code){
                            
                            return dispatch(
                                showSweetAlertDialog({
                                    title: "Ops... ",
                                    text:error.response.data.error_message,
                                    type: "error",
                                    showConfirm: true,
                                })
                            )
                        }
                        
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    "Erro ao conectar com o Memed ",
                                type: "error",
                                showConfirm: true,
                            })
                        )
                    }


                } else resolve(true)
            }
        )
    }
  

    const loadMemedFunctionality = (arrayHelpers, resolve, reject) => {

        MdSinapsePrescricao.event.add("core:moduleInit", async function (module) {
            if (module.name === "plataforma.prescricao") {
                MdHub.event.add('prescricaoExcluida', async (prescriptionId) => {
                    
                   

                    try {
                            
                        await handleDeleteMemed(prescriptionId)
                       
                       

                        arrayHelpers.pop()
                        
                       
                        
                    } catch (error) {
                        stateSync(1)
                    }
                        
                        
                  
                  
                    
                });

                await MdHub.command.send("plataforma.prescricao", "setFeatureToggle", {
                    removePrescription: false,
                    guidesOnboarding: false,
                    editIdentification: false,
                    optionsPrescription: false,
                    dropdownSync: false
                }); 

                resolve(true)

            }
        });


    }

    return (
        <React.Fragment>
            <CardTitle>Prescrições</CardTitle>
            <FieldArray
                name="itemsPrescriptions"
                render={(arrayHelpers) => (
                    <div>
                        {items && items.itemsPrescriptions.length > 0
                            ? items.itemsPrescriptions.map((item, index) => (
                                <Row key={index} className="mb-2 ">
                                    <Col sm="11" className="mt-4">
                                        <CardHeader
                                            style={{
                                                cursor: "pointer",
                                                height: "50px",
                                            }}
                                            className="shadow-lg bg-white"
                                            onClick={() => {
                                                //   setTab(!index)

                                                HandleTab(index)
                                            }}
                                        >
                                            <Row>
                                                <Col sm="6" className="mt-1">
                                                    <Label>
                                                        #Prescrição {item.integration_provider ? item.integration_provider : ''} -{" "}
                                                        {index ? index : 0}{" "}
                                                    </Label>
                                                </Col>
                                                {!item.integration_provider &&
                                                    <Col sm="6">
                                                        <div align="end">
                                                            {" "}
                                                            {tab == index ? (
                                                                <i className="fas fas fa-arrow-up fa-2x">
                                                                    {" "}
                                                                </i>
                                                            ) : (
                                                                <i className="fas fa-arrow-down fa-2x">
                                                                    {" "}
                                                                </i>
                                                            )}{" "}
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                        </CardHeader>
                                    </Col>
                                    <Col md="1" className="mt-4">
                                        {items.status &&
                                            items.status === 1 ? (
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="card-drop"
                                                    tag="i"
                                                >
                                                    <Button
                                                        type="button"
                                                        disabled={cardLoading && loadingButtonIndex === index}
                                                        className="btn btn-info mt-1"
                                                        id={
                                                            "tooltipprescripptionn" +
                                                            index
                                                        }
                                                    >
                                                        {" "}
                                                        {cardLoading && loadingButtonIndex === index ? <Spinner color="white" size="sm"></Spinner> : <i className="bx bx-grid-alt fa-lg text-white" />}
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={
                                                            "tooltipprescripptionn" +
                                                            index
                                                        }
                                                    >
                                                        Ações
                                                    </UncontrolledTooltip>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    right
                                                    className="mt-2"
                                                >
                                                    {!item.integration_provider && (
                                                        <DropdownItem
                                                            id={"pc4" + index}
                                                            onMouseOver={() => {
                                                                handleBG(
                                                                    "pc4" + index
                                                                )
                                                            }}
                                                            onMouseLeave={() => {
                                                                removeBG(
                                                                    "pc4" + index
                                                                )
                                                            }}
                                                            onClick={async () => {
                                                                try {
                                                                    setModalSaveModels(
                                                                        {
                                                                            show: true,
                                                                            data: item,
                                                                        }
                                                                    )
                                                                } catch (e) {
                                                                    console.log(e)
                                                                }
                                                            }}
                                                        >
                                                            <i className="fas fa-plus"></i>{" "}
                                                            Salvar Como Modelo
                                                            {/* <Button
                                                              id="iconedittooltip9349"
                                                              color="primary"
                                                              onClick={async () => {
                                                                  try {
                                                                      setModalSaveModels(
                                                                          {
                                                                              show: true,
                                                                              data: item,
                                                                          }
                                                                      )
                                                                  } catch (e) {
                                                                      console.log(
                                                                          e
                                                                      )
                                                                  }
                                                              }}
                                                          >
                                                              Salvar Como Modelo{" "}
                                                              <i className="fas fa-plus ml-1"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "iconedittooltip9349"
                                                                  }
                                                              >
                                                                  Clique para
                                                                  adicionar
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                        </DropdownItem>
                                                    )}

                                                    <DropdownItem
                                                        id={"pc3" + index}
                                                        disabled={cardLoading}
                                                        onMouseOver={() => {
                                                            handleBG(
                                                                "pc3" + index
                                                            )
                                                        }}
                                                        onMouseLeave={() => {
                                                            removeBG(
                                                                "pc3" + index
                                                            )
                                                        }}
                                                        onClick={async () => {
                                                            if (item.integration_id && item.integration_provider) {


                                                                return dispatch(
                                                                    showSweetAlertDialog({
                                                                        title:
                                                                            "Tem certeza que deseja realizar essa execução?",
                                                                        text: "Será excluído a prescrição.",
                                                                        type: "warning",
                                                                        onConfirmAction: async () => {
                                                                            try {
                                                                                setCardLoading(true)
                                                                                setLoadingButtonIndex(index)
                                                                                await removePrescriptionMemed(professional.id, item.integration_id)
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                                setCardLoading(false)
                                                                                setLoadingButtonIndex(null)
                                                                            } catch (error) {
                                                                                setCardLoading(false)
                                                                                setLoadingButtonIndex(null)
                                                                                console.log(error)
                                                                            }

                                                                        },
                                                                        showCancel: true,
                                                                        showConfirm: true,
                                                                        cancelBtnText: "Não",
                                                                        confirmBtnText: "Confirmar",
                                                                    })
                                                                )




                                                            } else {
                                                                await handleDelete(
                                                                    item
                                                                )



                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>{" "}
                                                        Excluir
                                                        {/* <Button
                                                              id={
                                                                  "trash5" +
                                                                  index
                                                              }
                                                              color="danger"
                                                              className="inner "
                                                              onClick={async () => {
                                                                  await handleDelete(
                                                                      item
                                                                  ),
                                                                      arrayHelpers.remove(
                                                                          index
                                                                      )
                                                              }}
                                                          >
                                                              Excluir{" "}
                                                              <i className="fas fa-trash-alt"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "trash5" +
                                                                      index
                                                                  }
                                                              >
                                                                  Clique para
                                                                  excluir
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                    </DropdownItem>
                                                    {!item.integration_provider ? (
                                                        <DropdownItem
                                                            onClick={() => {
                                                                viewForm(item)
                                                            }}
                                                            id={"pc6" + index}
                                                            onMouseOver={() => {
                                                                handleBG(
                                                                    "pc6" + index
                                                                )
                                                            }}
                                                            onMouseLeave={() => {
                                                                removeBG(
                                                                    "pc6" + index
                                                                )
                                                            }}
                                                        >
                                                            <i className="fas fa-binoculars"></i>{" "}
                                                            Pré-visualizar
                                                        </DropdownItem>
                                                    ) : (
                                                        <DropdownItem
                                                            onClick={async () => {
                                                                printPDF(item.file, index, item.integration_id)
                                                            }}
                                                            id={"pc6" + index}
                                                            onMouseOver={() => {
                                                                handleBG(
                                                                    "pc6" + index
                                                                )
                                                            }}
                                                            onMouseLeave={() => {
                                                                removeBG(
                                                                    "pc6" + index
                                                                )
                                                            }}
                                                        >
                                                            <i className="fas fa-print"></i>{" "}
                                                            Imprimir
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                    {!item.integration_provider &&
                                        <Col sm="11" className="mt-2 ">

                                            <Collapse isOpen={tab == index}>

                                                <>
                                                    <FormGroup>

                                                        <Label className="mt-2">
                                                            {" "}
                                                            <strong>
                                                                Título
                                                            </strong>{" "}
                                                        </Label>
                                                        <FastField
                                                            name={`itemsPrescriptions[${index}].title`}
                                                            component={InputText}
                                                            defaultValue={item?.title}
                                                            maxlength="100"
                                                            stateSync={stateSync}
                                                            datasyncstate={
                                                                datasyncstate
                                                            }
                                                            entityId={item?.id}
                                                            lastFormState={
                                                                lastFormState
                                                            }
                                                            attendanceId={
                                                                attendance_id
                                                            }
                                                            disabled={
                                                                items.status === 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            style={
                                                                items.status === 0
                                                                    ? {
                                                                        cursor:
                                                                            "not-allowed",
                                                                        background:
                                                                            "#f8f9fa",
                                                                    }
                                                                    : {
                                                                        cursor:
                                                                            "text",
                                                                    }
                                                            }
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>
                                                            {" "}
                                                            <strong>
                                                                Texto
                                                            </strong>{" "}
                                                        </Label>
                                                        <FastField
                                                            name={`itemsPrescriptions[${index}].text_object`}
                                                            editorState={
                                                                item?.text_object
                                                            }
                                                            stateSync={stateSync}
                                                            datasyncstate={
                                                                datasyncstate
                                                            }
                                                            component={InputEditor}
                                                            entityId={item?.id}
                                                            lastFormState={
                                                                lastFormState
                                                            }
                                                            attendanceId={
                                                                attendance_id
                                                            }
                                                            disabled={
                                                                items.status === 0
                                                                    ? true
                                                                    : false
                                                            }
                                                        />

                                                    </FormGroup>

                                                </>


                                            </Collapse>

                                        </Col>
                                    }
                                </Row>
                            ))
                            : null}

                        {items.status && items.status === 1 ? (
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        {/* Visível somente em tamanhos de tela xs, sm */}
                                        {/* <Button
                                            type="button"
                                            className="btn btn-info mt-2 mr-2"
                                            onClick={async () => {
                                                try {
                                                    let aux = { prof: professional, patient: patient, unit: unit }
                                                    setModalPrescriptionData(
                                                        {
                                                            show: true,
                                                            entity_type:
                                                                MEDICAL_FORMS_TYPES.PRESCRIÇÃO,
                                                            data: aux
                                                        }
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-marker"></i> Modelo
                                        </Button> */}
                                        <Button
                                            type="button"
                                            className="btn btn-info mt-2 mr-2"
                                            onClick={async () => {
                                                try {
                                                    let item = await handleCreate()
                                                    if (item) {
                                                        arrayHelpers.push(
                                                            item
                                                        )
                                                    }
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-plus"></i> Manual
                                        </Button>
                                        <Button
                                            type="button"
                                            disabled={loading}
                                            className="btn btn-info mt-2 mr-2"
                                            onClick={async () => {
                                                if (!patient.cpf) return dispatch(
                                                    showSweetAlertDialog({
                                                        title:
                                                            "Paciente sem CPF ",
                                                        type: "warning",
                                                        showConfirm: true,
                                                    })
                                                )
                                                await clickMemedButton(arrayHelpers)

                                            }}
                                        >
                                            {loading ? <Spinner color="white" size="sm"></Spinner> : <><i className="fas fa-notes-medical"></i><span> Memed</span></>}
                                        </Button>
                                        <ModalPrescriptions
                                            show={modalPrescriptionData?.show}
                                            entity_type={
                                                modalPrescriptionData?.entity_type
                                            }
                                            data={modalPrescriptionData?.data}
                                            onClose={response => {
                                                setModalPrescriptionData({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleModel={async data => {
                                                try {
                                                    let stateEditor = EditorState.createWithContent(
                                                        convertFromRaw(
                                                            JSON.parse(
                                                                data?.text_object
                                                            )
                                                        )
                                                    )

                                                    data.entity =
                                                        "MedicalPrescription"
                                                    data.attendance_id = attendance_id
                                                    data.text_object = convertToRaw(
                                                        stateEditor.getCurrentContent()
                                                    )

                                                    let item = await handleCreate(
                                                        data
                                                    )
                                                    let itemModal = { ...item }
                                                    if (data && itemModal) {
                                                        itemModal.title =
                                                            data?.title
                                                        itemModal.text_object = stateEditor

                                                        arrayHelpers.push(
                                                            itemModal
                                                        )
                                                        setModalPrescriptionData(
                                                            {
                                                                show: false,
                                                                data: null,
                                                            }
                                                        )
                                                    }
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo selecionado! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Ocorreu algum problema ! ",
                                                            text:
                                                                "Houve um erro, tente novamente ou contate o suporte.",
                                                            type: "error",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                }
                                            }}
                                        ></ModalPrescriptions>
                                        <ModalSaveModels
                                            show={modalSaveModels.show}
                                            data={modalSaveModels.data}
                                            onClose={response => {
                                                setModalSaveModels({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleSave={async (title, data) => {
                                                try {
                                                    if (
                                                        title == null ||
                                                        !title ||
                                                        title == ""
                                                    ) {
                                                        dispatch(
                                                            showSweetAlertDialog(
                                                                {
                                                                    title:
                                                                        "Modelo não salvo devido a ausência de título!",

                                                                    type:
                                                                        "error",
                                                                    showConfirm: true,
                                                                }
                                                            )
                                                        )
                                                        setModalSaveModels({
                                                            show: false,
                                                            data: null,
                                                        })
                                                        return
                                                    }
                                                    if (
                                                        !data?.text_object ==
                                                        null ||
                                                        !data?.text_object == ""
                                                    ) {
                                                        if (
                                                            (convertToRaw(
                                                                data.text_object.getCurrentContent()
                                                            ).blocks.length ===
                                                                1) &
                                                            (convertToRaw(
                                                                data.text_object.getCurrentContent()
                                                            ).blocks[0].text ===
                                                                "")
                                                        ) {
                                                            dispatch(
                                                                showSweetAlertDialog(
                                                                    {
                                                                        title:
                                                                            'Para criar um modelo, é necessário que os campos  "Título" e "Texto" sejam preenchidos!',
                                                                        type:
                                                                            "warning",
                                                                        showConfirm: true,
                                                                    }
                                                                )
                                                            )
                                                            return
                                                        }
                                                    } else {
                                                        dispatch(
                                                            showSweetAlertDialog(
                                                                {
                                                                    title:
                                                                        'Para criar um modelo, é necessário que os campos  "Título" e "Texto" sejam preenchidos!',
                                                                    type:
                                                                        "warning",
                                                                    showConfirm: true,
                                                                }
                                                            )
                                                        )
                                                        return
                                                    }
                                                    const dataSave = {
                                                        title: title,
                                                        form_type:
                                                            MEDICAL_FORM_TYPES.PRESCRIÇÃO,
                                                        text: draftToHtml(
                                                            convertToRaw(
                                                                data?.text_object.getCurrentContent()
                                                            )
                                                        ),
                                                        text_object: convertToRaw(
                                                            data?.text_object.getCurrentContent()
                                                        ),
                                                    }
                                                    await createModel(dataSave)

                                                    setModalSaveModels({
                                                        show: false,
                                                        data: null,
                                                    })

                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo adicionado com sucesso! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        ></ModalSaveModels>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            />
        </React.Fragment>
    )
}

export default MedicalPrescritions
