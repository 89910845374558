import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
    Modal,
    Row,
    Col,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    Card,
    CardBody,
} from "reactstrap"
import IndexCard from "./Cards"
import Dependents from "./Dependents"
import classnames from "classnames"
import { search as SearchCards } from "services/vitta-core/vitta-cards"
import AddContract from "./AddContract"
import ContractsList from "./ContractsList/ContractsList"
import ModalCancelContract from "./ModalCancelContract"

const ModalCardVitta = ({ show, data, onClose }) => {
    const defaultValues = {
        card_id: "",
        cards: [],
    }
    const [showModalCancel, setShowModalCancel] = useState({
        show:false,
        contract:null
    })
    const [activeTab, setactiveTab] = useState("1")
    const [dataDepedent, setDataDependent] = useState(null)

    const [pageData, setPageData] = useState(defaultValues)
    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }
    const handleDataDependent = valuesDep => {
        try {
            setDataDependent(valuesDep)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (data?.patient_id) {
            search()
        }
    }, [show])
    const search = async () => {
        try {
            let filter = {
                patient_id: data?.patient_id,
                expand_dependents: 1,
            }
            let find = await SearchCards(filter)

            let take = { ...pageData }
            if (find && find?.data?.length > 0) {
                take.cards = find?.data
            }
            setPageData(take)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal
            isOpen={show}
            centered={false}
            size="xl"

            // style={{ maxWidth: "1600px", width: "50%" }}
        >
            <Row className="card-body">
                <Col sm="11">
                    {" "}
                    <div className="modal-title " id="myModalLabel">
                        <h5>
                            <i className="fas fa-address-card text-dark fa-2x mr-2"></i>
                            Cartões Vittá <i className="fas fa-plus"></i>{" "}
                            Vantagens
                        </h5>
                    </div>
                </Col>
                <Col sm="1">
                    {data?.id ? (
                        <Link
                            target={"_blank"}
                            to={"/imprimir/pedidos-de-exame/" + data?.id}
                            className="btn btn-dark"
                            align="end"
                        >
                            <i className="fas fa-print fa-lg text-white"></i>
                        </Link>
                    ) : (
                        ""
                    )}
                    <button
                        type="button"
                        onClick={() => {
                            onClose()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Col>
            </Row>

            <CardBody>
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink
                            style={{
                                cursor: "pointer",
                            }}
                            className={classnames({
                                active: activeTab === "1",
                            })}
                            onClick={() => {
                                toggle("1")
                            }}
                        >
                            Cartões
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{
                                cursor: "pointer",
                            }}
                            className={classnames({
                                active: activeTab === "2",
                            })}
                            onClick={() => {
                                toggle("2")
                            }}
                        >
                            Adicionar Dependentes
                        </NavLink>
                        <NavLink></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{
                                cursor: "pointer",
                            }}
                            className={classnames({
                                active: activeTab === "3",
                            })}
                            onClick={() => {
                                toggle("3")
                            }}
                        >
                            Adicionar Contratos
                        </NavLink>
                        <NavLink></NavLink>
                    </NavItem>
                </Nav>
            </CardBody>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <IndexCard
                        show={show}
                        data={pageData}
                        onClose={onClose}
                        dataDepedent={dataDepedent}
                        search={search}
                        patient_id={data?.patient_id}
                        redirectToAddContract={() => {
                            toggle("3")
                        }}
                        redirectToCreateDependents={() => {
                            toggle("2")
                        }}
                        showModalCancel={showModalCancel}
                        setShowModalCancel={setShowModalCancel}
                    />
                </TabPane>
                <TabPane tabId="2">
                    {pageData?.cards?.length > 0 ? (
                        <Dependents
                            show={show}
                            data={pageData}
                            onClose={onClose}
                            handleDataDependent={handleDataDependent}
                        />
                    ) : (
                        <CardBody>
                            Sem cartões registrados para adicionar um
                            dependente.
                        </CardBody>
                    )}
                </TabPane>
                <TabPane tabId="3">
                    {pageData?.cards?.length > 0 ? (
                        <AddContract
                            data={pageData}
                            onSuccess={() => {
                                toggle("1")
                                search()
                            }}
                        />
                    ) : (
                        <CardBody>
                            Sem cartões registrados para adicionar um contrato.
                        </CardBody>
                    )}
                </TabPane>
            </TabContent>
            <ModalCancelContract
             show={showModalCancel.show}
             onClose={()=>setShowModalCancel({show:false, contract:null})}
             search={search}
             contract = {showModalCancel.contract}
            >
            </ModalCancelContract>
        </Modal>
    )
}

export default ModalCardVitta
