import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Button, Card, CardBody, Col, Row, Badge, Label } from "reactstrap"
import debounce from "debounce-promise"
import ExamOrderColumns from "pages/Patients/Detail/pages/ExamOrder/ExamOrderColumns"
import EXAMS_INTEGRATION_PARTNERS_STATUS from "utils/consts/exams-integration-partners-status"
import { useDispatch } from "react-redux"
import { searchOrderExams as searchOrderExams } from "services/vitta-core/orders-exams"
import { getById as getById } from "services/vitta-core/orders-exams"
import ModalExamOrder from "pages/SalesDocs/ModalExamRequests/Modal"
import EXAMS_ORDER_STATUS from "utils/consts/exams-orders-status"
import { search as searchSalesOrder } from "services/vitta-core/sales-order"
import AddExamsOrdersModal from "./Components/AddExamsOrdersModal"
const listExamOrders = props => {
    const { patient_id } = useParams()
    const [list, setList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })

    const [controlModalExamRequest, setControlModalExamRequest] = useState({
        show: false,
        data: null,
    })
    const [
        controlModalAddExamsOrders,
        setControlModalAddExamsOrders,
    ] = useState({
        show: false,
        data: null,
    })
    const inputSalesOrdersRef = useRef()
    const inputExamsOrderRef = useRef()
    const inputPartnerStatusIntegrationRef = useRef()
    const [pageControl, setPageControl] = useState({ searching: false })
    const search = async (page = 1, searchByQuery) => {
        setPageControl({ ...pageControl, ...{ searching: true } })
        let sizePerPage = 10
        let openModalWithData = false
        try {
            //clear data
            setList({
                sizePerPage: sizePerPage,
                totalSize: 0,
                custom: true,
                data: [],
            })

            let objSearch = {
                patient_id: patient_id,
            }
            if (inputSalesOrdersRef.current.select?.state?.value?.value) {
                objSearch.sales_order_id =
                    inputSalesOrdersRef.current.select?.state?.value?.value
            }
            if (inputExamsOrderRef.current.select?.state?.value?.value) {
                objSearch.exams_order_id =
                    inputExamsOrderRef.current.select?.state?.value?.value
                openModalWithData = true
            }

            if (
                inputPartnerStatusIntegrationRef.current?.select?.state
                    ?.selectValue[0]?.value
            ) {
                objSearch.partner_status_integration =
                    inputPartnerStatusIntegrationRef.current?.select?.state?.selectValue[0]?.value
            }

            let record = await searchOrderExams(objSearch, page, sizePerPage)

            setList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: record?.data,
            })
            if (
                searchByQuery &&
                openModalWithData &&
                record?.data?.length > 0
            ) {
                renderRecord(objSearch?.exams_order_id)
            }
        } catch (e) {
            console.log("error", e)
        }
        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const searchByQuery = (entity_id, entity) => {
        if (entity == "sales_orders") {
            inputSalesOrdersRef.current.select.state.value = {
                label: "#" + entity_id.toString(),
                value: entity_id,
            }
        } else {
            inputExamsOrderRef.current.select.state.value = {
                label: "#" + entity_id.toString(),
                value: entity_id,
            }
        }
        let searchByQuery = true
        search(1, searchByQuery)
    }

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page }) => {
        if (type == "pagination") {
            search(page)
        }
    }
    const renderRecord = async id => {
        try {
            let result = await getById(id)
            if (result) {
                let items = []
                let obj = {
                    id: id,
                    partner_order_id: result?.partner_order_id,
                    patient: {
                        label: result?.patient?.name,
                        value: result?.patient?.id,
                    },
                    partner: {
                        label: result?.partner?.name,
                        value: result?.partner?.id,
                    },
                    status: result?.status,
                    partner_status_integration:
                        result?.partner_status_integration,
                    partner_status: result?.partner_status,
                    partner_obs: result?.partner_obs,
                    recipients: result?.recipients,
                    sales_order_id: result?.sales_order_id,
                    result_path: result?.result_path,
                }
                if (result?.items && result?.items?.length > 0) {
                    for (let i = 0; i < result?.items?.length; i++) {
                        let use = result?.items[i]

                        let objUse = {
                            id: use?.id,
                            item_id: {
                                label: use?.item_name,
                                value: use?.item_id,
                                item: {
                                    group_id: use?.item?.group_id,
                                },
                            },
                            quantity: use?.quantity,
                            status: use?.status,
                            additional_data: use?.additional_data,
                            print_bar_code_command: use?.print_bar_code_command,
                            partner_status_id: use?.partner_status_id,
                            partner_status: use?.partner_status,
                            partner_obs: use?.partner_obs,
                        }
                        items.push(objUse)
                    }
                }

                obj.items = items

                if (result?.partner_order_id) {
                    setControlModalExamRequest({
                        show: true,
                        data: obj,
                    })
                } else {
                    setControlModalAddExamsOrders({
                        show: true,
                        data: obj,
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const RenderMedicalForm = async data => {
        let items = []
        let obj = {
            id: data?.id,
            patient: { label: data?.patient?.name, value: data?.patient?.id },
            partner: { label: data?.partner?.name, value: data?.partner?.id },
            status: data?.status,
            bar_code_data: data?.bar_code_data,
            partner_order_id: data?.partner_order_id,
            partner_status_integration: data?.partner_status_integration,
            partner_obs: data?.partner_obs,
            recipients: data?.recipients,
            sales_order_id: data?.sales_order_id,
            result_path: data?.result_path,
        }
        if (data?.items && data?.items?.length > 0) {
            for (let i = 0; i < data?.items?.length; i++) {
                let use = data?.items[i]
                let objUse = {
                    id: use?.id,
                    item_id: {
                        label: use?.item_name,
                        value: use?.item_id,
                        item: {
                            group_id: use?.item?.group_id,
                        },
                    },
                    quantity: use?.quantity,
                    status: use?.status,
                    additional_data: use?.additional_data,
                    print_bar_code_command: use?.print_bar_code_command,
                    partner_status_id: use?.partner_status_id,
                    partner_status: use?.partner_status,
                    partner_obs: use?.partner_obs,
                }
                items.push(objUse)
            }
        }
        obj.items = items

        if (data?.partner_order_id) {
            setControlModalExamRequest({
                show: true,
                data: obj,
            })
        } else {
            setControlModalAddExamsOrders({
                show: true,
                data: obj,
            })
        }
    }
    const renderStatusBadge = status => {
        let text = ""
        let badgeClass = ""
        switch (status) {
            case EXAMS_ORDER_STATUS.CANCELED:
                text = "Cancelado"
                badgeClass = "badge-soft-danger"
                break
            case EXAMS_ORDER_STATUS.CONCLUDED:
                text = "Concluído"
                badgeClass = "badge-soft-success"
                break
            case EXAMS_ORDER_STATUS.IN_PROGRESS:
                text = "Em Andamento"
                badgeClass = "badge-soft-info"
                break
            case EXAMS_ORDER_STATUS.REJECTED:
                text = "Rejeitado"
                badgeClass = "badge-soft-warning"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const renderStatusIntegrationBadge = status => {
        let text = ""
        let badgeClass = ""
        switch (status) {
            case EXAMS_INTEGRATION_PARTNERS_STATUS.INTEGRATED:
                text = "Integrado"
                badgeClass = "badge-soft-success"
                break
            case EXAMS_INTEGRATION_PARTNERS_STATUS.ERROR_INTEGRATION:
                text = "Erro integração"
                badgeClass = "badge-soft-danger"
                break
            case EXAMS_INTEGRATION_PARTNERS_STATUS.AWAITING_INTEGRATION:
                text = "Aguardando integração"
                badgeClass = "badge-soft-info"
                break
            default:
                text = "Manual"
                badgeClass = "badge-soft-warning"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const loadSalesOrderInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await searchSalesOrder(
                    { term: inputValue, patient_id: patient_id },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Sem filtro",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} `,
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsSalesOrder = debounce(loadSalesOrderInputOptions, 600)
    const loadExamsOrderInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await searchOrderExams(
                    { term: inputValue, patient_id: patient_id },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Sem filtro",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} `,
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsExamsOrder = debounce(loadExamsOrderInputOptions, 600)
    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-4 mb-4">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }

    useEffect(() => {
        let searchQuery = location.search
        const params = new URLSearchParams(searchQuery)
        // const exam_order_id = params.get("exam_order_id")
        // if (exam_order_id) {
        //     renderRecord(exam_order_id)
        // }
        const sales_order_id = params.get("sales_order_id")
        const exams_order_id = params.get("exams_order_id")
        if (sales_order_id) {
            searchByQuery(sales_order_id, "sales_orders")
        } else if (exams_order_id) {
            searchByQuery(exams_order_id, "exams_orders")
        } else {
            search(1)
        }
    }, [])

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <h4>Resultados de Pedidos de Exames</h4>
                                </Col>
                                <Col sm="6" align="end">
                                    <strong>
                                        {" "}
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setControlModalAddExamsOrders({
                                                    show: true,
                                                    data: null,
                                                })
                                            }}
                                        >
                                            <i className="fas fa-file-medical fa-lg"></i>{" "}
                                            Adicionar
                                        </a>
                                    </strong>
                                </Col>
                            </Row>
                            {/* <Breadcrumbs
                                title="Lista"
                                breadcrumbItem="Pedidos de Exames"
                            /> */}
                            <PaginationProvider
                                pagination={paginationFactory(list)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={list?.data || []}
                                        columns={ExamOrderColumns(
                                            RenderMedicalForm,
                                            renderStatusBadge,
                                            renderStatusIntegrationBadge
                                        )}
                                        bootstrap4
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-3 mt-3">
                                                    <Col
                                                        sm="3"
                                                        className="mt-1"
                                                    >
                                                        <Label>
                                                            N.º Pedido de exames
                                                        </Label>

                                                        <AsyncSelect
                                                            placeholder="Digite para Buscar..."
                                                            name="exams_order_id"
                                                            loadOptions={
                                                                debounceOptionsExamsOrder
                                                            }
                                                            ref={
                                                                inputExamsOrderRef
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        sm="3"
                                                        className="mt-1"
                                                    >
                                                        <Label>
                                                            N.º Pedido de venda
                                                        </Label>

                                                        <AsyncSelect
                                                            placeholder="Digite para Buscar..."
                                                            name="sales_order_id"
                                                            loadOptions={
                                                                debounceOptionsSalesOrder
                                                            }
                                                            ref={
                                                                inputSalesOrdersRef
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        sm="3"
                                                        className="mt-1"
                                                    >
                                                        <Label>
                                                            Status Integração
                                                        </Label>
                                                        <Select
                                                            placeholder="Selecione"
                                                            name="status_integration"
                                                            ref={
                                                                inputPartnerStatusIntegrationRef
                                                            }
                                                            options={[
                                                                {
                                                                    label:
                                                                        "Todos",
                                                                    value: null,
                                                                },
                                                                {
                                                                    label:
                                                                        "Integrado",
                                                                    value:
                                                                        EXAMS_INTEGRATION_PARTNERS_STATUS.INTEGRATED,
                                                                },
                                                                {
                                                                    label:
                                                                        "Aguardando integração",
                                                                    value:
                                                                        EXAMS_INTEGRATION_PARTNERS_STATUS.AWAITING_INTEGRATION,
                                                                },
                                                                {
                                                                    label:
                                                                        "Erro integração",
                                                                    value:
                                                                        EXAMS_INTEGRATION_PARTNERS_STATUS.ERROR_INTEGRATION,
                                                                },
                                                            ]}
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados. Digite para Buscar"
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        sm="1"
                                                        className="mt-2"
                                                    >
                                                        <Button
                                                            type="button"
                                                            className="btn btn-info mt-4"
                                                            onClick={() => {
                                                                search()
                                                            }}
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                noDataIndication={
                                                                    renderNoDataIndication
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalExamOrder
                show={controlModalExamRequest?.show}
                data={controlModalExamRequest?.data}
                renderRecord={renderRecord}
                onClose={controlSearch => {
                    setControlModalExamRequest({
                        show: false,
                        data: null,
                    })
                    if (controlSearch) {
                        search()
                    }
                }}
            />
            <AddExamsOrdersModal
                show={controlModalAddExamsOrders?.show}
                data={controlModalAddExamsOrders?.data}
                patient_id={patient_id}
                onClose={e => {
                    setControlModalAddExamsOrders({
                        show: false,
                        data: null,
                    })

                    if (e?.action) {
                        search()
                    }
                }}
            />
        </React.Fragment>
    )
}

export default listExamOrders
