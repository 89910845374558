const removeStoredAccess = () => {
    localStorage.removeItem("vittanet.identity")
    localStorage.removeItem("vittanet.scope.unit")
    return null
}

const hasPermission = (user, permission) => 
{
    if(!user) return false;
    
    for(let i = 0; i < user.access_roles.length; i++)
    {
        let useRole = user.access_roles[i];
        let permissionObj = useRole.permissions.find(e => e.name == permission);
        if(!permissionObj && permission =="view_appointment")
        {
            permissionObj = useRole.permissions.find(e => e.name == "view_my_prof_appointment");
        }
        if(permissionObj) return true;
    }
    
    return false;
}

export default { removeStoredAccess, hasPermission };