import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const ListUnitsColumns = (deleteRecord) => [
  {
    text: "ID",
    dataField: "id",
  },
  {
    dataField: "name",
    text: "Nome",
  },
  {
    dataField: "cnpj",
    text: "CNPJ",
  },
  {
    dataField: "address",
    formatter: (cellContent, row) => (
      <>
        {row.address + ", " + row.district + ". "+ row.city + "-" + row.state}
      </>
    ),
    text: "Endereço",
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, row) => (
      <Badge
        className={`font-size-12 badge-soft-${row.status == 1 ? "success" : "danger"}`}
        pill
      >
        {row.status == 1 ? "Ativo" : "Inativo"}
      </Badge>
    ),
  },
  {
    isDummyField: true,
    text: "Ações",
    formatter: (id,row) => (
      <>
        <Link to={"/unidades/editar/"+row.id} className="mr-3 text-primary">
          <i className="mdi mdi-pencil-box-outline font-size-24 mr-3" id="edittooltip" />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Editar
          </UncontrolledTooltip>
        </Link>
        
        {/*<Link Link to="#" className="text-danger" onClick={() => {deleteRecord(row.id)}}>
        <i className="mdi mdi-trash-can-outline font-size-24 mr-3" id="deletetooltip" />
          <UncontrolledTooltip placement="top" target="deletetooltip">
           Excluir
          </UncontrolledTooltip>
    </Link>*/}
      </>
    ),
  },
]

export default ListUnitsColumns
