import React, { useMemo, useState, useRef } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Label,
    Button,
    Card,
    Input,
    FormGroup,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import SalesAnalysisReport from "pages/Reports/SalesAnalysis/report"
import { pceReportExport } from "services/vitta-core/professionals-pce"
import debounce from "debounce-promise"
import { search as searchSpecialties } from "services/vitta-core/specialty"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const SalesAnalysisIndex = () => {
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })
    const [controlState, setControlState] = useState(false)

    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = [
            {
                label: "Todas",
                value: null,
            },
        ]
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadSpecialtyOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchSpecialties(
                    { term: inputValue },
                    1,
                    25
                )

                let values = [
                    {
                        label: "Todas",
                        value: null,
                    },
                ]
                response.data.forEach(element => {
                    values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsSpecialty = debounce(loadSpecialtyOptions, 600)

    const loadProfessionalInputOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try
            {
                let response = await professionalServiceSearch({term: inputValue}, 1, 20);

                let list_values = [
                    {
                        label: "Todos",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    list_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values);
            }
            catch(e)
            {
                console.error(e);
                return reject([]);
            }
        });
    }
    const debounceOptionsProfessional = debounce(loadProfessionalInputOptions,600)


    const onSubmit = async (data_form, actions) => {
        try {
            let professional_ids = []
            let specialty_ids = []
            // for (let i = 0; i < data_form?.unit_id?.length; i++) {
            //     unit_ids.push(data_form?.unit_id[i]?.value)
            // }
            for (let i = 0; i < data_form?.professional_id?.length; i++) {
                professional_ids.push(data_form?.professional_id[i]?.value)
            }
            for (let i = 0; i < data_form?.specialty_id?.length; i++) {
                specialty_ids.push(data_form?.specialty_id[i]?.value)
            }
            setControlState(true)
            let response = await pceReportExport({
                specialty_id: specialty_ids,
                professional_id:professional_ids,
            })

            var headers = response.headers
            var blob = new Blob([response.data], {
                type: headers["content-type"],
            })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = "Relatório - PCE"
            link.click()
            setControlState(false)
            return
        } catch (e) {
            setControlState(false)
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Ocorreu algum problema, Preencha os campos corretamente e tente novamente.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Relatórios" breadcrumbItem="PCE" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Relatório PCE Atual
                                               
                                            </h3>
                                            <div align="center">
                                            <small>últimos 90 dias</small>
                                            </div>
                                           
                                        
                                            <Formik
                                                initialValues={{
                                                    specialty_id: {
                                                        label: "Todas",
                                                        value: null,
                                                    },
                                                    professional_id: {
                                                        label: "Todas",
                                                        value: null,
                                                    },
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form>
                                                          <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Profissional
                                                                    </Label>
                                                                    <Field
                                                                        name="professional_id"
                                                                        placeholder="Selecionar profissional"
                                                                        cacheOptions
                                                                        isClearable
                                                                        defaultOptions
                                                                        defaultValue={
                                                                            formProps.professional
                                                                        }
                                                                        loadOptions={
                                                                            debounceOptionsProfessional
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Especialidade
                                                                    </Label>
                                                                    <Field
                                                                        name="specialty_id"
                                                                        placeholder="Selecionar especialidade"
                                                                        cacheOptions
                                                                        isClearable
                                                                        defaultOptions
                                                                        defaultValue={
                                                                            formProps.patient
                                                                        }
                                                                        loadOptions={
                                                                            debounceOptionsSpecialty
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Unidade
                                                                    </Label>
                                                                    <Field
                                                                        name={`unit_id`}
                                                                        placeholder="Selecione a Unidade"
                                                                        classNamePrefix="select2-selection"
                                                                        options={
                                                                            unitsOptions
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                            sm="2"
                                                            align="start"
                                                            className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <div
                                                                        className="spinner-border text-dark"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Loading...
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                sm="6"
                                                                align="end"
                                                                className="mt-4"
                                                            >
                                                                <Button
                                                                    type="submit"
                                                                    className="btn btn-dark"
                                                                    disabled={controlState}
                                                                >
                                                                    Gerar
                                                                    Relatório{" "}
                                                                    <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SalesAnalysisIndex
