import React, { useState, useEffect } from "react"
import { Modal, Button, Input, Label, Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const ModalExamItemCheck = ({ show, onClose, data, handleModel }) => {
    const [item, setItem] = useState([])
    const [checkState, setCheckState] = useState([])
    useEffect(() => {
        if (data?.form) {
            setCheckState(new Array(data.form.length).fill(false))
        }
    }, [data])

    const handleCheck = (position) => {
        const updatedCheckedState = checkState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckState(updatedCheckedState)
    }
    if(!show) return null
    return (
        <Modal isOpen={show} centered={false} size="xl">
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Selecione os Serviços{" "}
                        <i className="fas fa-clipboard-check text-dark fa-2x"></i>

                    </h5>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        setItem([])
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ height: "550px", overflowY: "auto" }} >
                <Row>
                    <Col>
                        <Card style={{ paddingLeft: "8px" }} className="mb-0">
                            <CardBody className="pt-0 pb-0 pl-0">
                                <CardTitle>{data && data.title ? data.title : ''}</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2 card-body">
                    {data?.form && data?.form.length > 0 ?

                        data.form.reduce((columns, itemExam, indexExam) => {
                            const columnIndex = Math.floor(indexExam / (data.form.length / 3)); // Definir o índice da coluna
                            if (!columns[columnIndex]) {
                                columns[columnIndex] = []; // Inicializar a coluna se ainda não existir
                            }

                            columns[columnIndex].push(
                                <Col className="custom-control custom-checkbox mt-4 mb-3">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={checkState[indexExam]}
                                        id={`[${indexExam}]itemExam`}
                                        onChange={(e) => {
                                            handleCheck(indexExam);
                                            let value = item;
                                            if (e.target.checked) {
                                                value.push(itemExam);
                                            } else {
                                                value = item.filter((e) => e.id !== itemExam.id);
                                            }
                                            setItem(value);
                                        }}
                                    />
                                    <label
                                        className="custom-control-label text-break"
                                        style={{ paddingTop: "1px" }}
                                        htmlFor={`[${indexExam}]itemExam`}
                                    >
                                        {itemExam.item.name}
                                    </label>
                                </Col>
                            );
                            return columns;
                        }, [])
                            .map((column, index) => (
                                <Col className="custom-control custom-checkbox" sm="4" style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'flex-start',
                                    height:'100%',
                                }}>
                                    {column}
                                </Col>
                            ))


                        :

                        <Col>
                            <strong className>Modelo não possui serviços, deseja confirmar?</strong>
                        </Col>

                    }
                </Row>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px", marginRight: "24px", marginLeft: "24px" }}>
                <div className="custom-control custom-checkbox mt-2 mb-2" style={{ display: "flex" }} >
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={false}
                        id={`Allitems`}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const arr = [...checkState].fill(true)
                                setCheckState(arr)
                                setItem([...data.form])

                            } else {
                                const arr = [...checkState].fill(false)
                                setCheckState(arr)
                                setItem([])

                            }

                        }

                        }
                    />
                    <label
                        className="custom-control-label"
                        style={{ paddingTop: '1px' }}
                        htmlFor={`Allitems`}
                    >
                        {'Marcar/Desmarcar Tudo'}
                    </label>
                </div>
                <div style={{ display: "flex" }}>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect mr-2"
                        data-dismiss="modal"
                        onClick={() => {
                            let data_filter = { ...data }
                            data_filter.form = [...item]
                            handleModel(data_filter)
                        }}
                    >
                        <span className="text-white"> Confirmar</span>
                    </button>

                    <button
                        type="button"
                        onClick={() => {
                            setItem([])
                            onClose()
                        }}
                        className="btn btn-dark waves-effect"
                        data-dismiss="modal"
                    >
                        <span className="text-white"> Fechar</span>
                    </button>
                </div>
            </div>


        </Modal>
    )
}
export default ModalExamItemCheck