import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Label,
    Input,
    FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
import "toastr/build/toastr.min.css"
import { me } from "services/vitta-core/user"
import { useDispatch, useSelector } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useHistory } from "react-router-dom"
import { updateUserPassword } from "services/vitta-core/user"
import logoupload from "assets/images/logoupload.png"
import { Formik, Field, Form, FieldArray } from "formik"
import ModalImage from "pages/Professionals/RegistrationProfessionals/ModalImage/ModalImage"
import FormData from "form-data"
import { uploadImageUser } from "services/vitta-core/user"
import "./style.css"
const PasswordInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="password" {...field} {...props} />

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} disabled className="bg-light" />

const defaultData = {
    lastPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    name: "",
    email: "",
}

const handleUsers = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState(defaultData)
    const userAuth = useSelector(state => state.auth.access?.user)
    const dispatch = useDispatch()
    const history = useHistory()

    const handleSubmit = async data => {
        let dataSubmit = {
            lastPassword: data.lastPassword,
            newPassword: data.newPassword,
            confirmNewPassword: data.confirmNewPassword,
        }

        try {
            await updateUserPassword(userAuth.id, dataSubmit)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Senha Atualizada!",
                    type: "success",
                })
            )
            history.push("/dashboard")
        } catch (e) {
            dispatch(
                showToast({
                    title: "Ops...",
                    text: "Você digitou informações erradas!",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    useEffect(() => {
        if (userAuth.id) {
            loadDataUser(userAuth?.id)
        }
    }, [userAuth.id])
    const loadDataUser = async id => {
        let user = await me()
        setPageData(user)
    }

    const [controlModalImage, setControlModalImage] = useState({
        show: false,
        data: null,
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Perfil do usuário"
                        breadcrumbItem=" Dados do usuário"
                    />

                    <Formik
                        initialValues={pageData}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Row>
                                    <Col xs="12">
                                        <Row>
                                            <Col sm="6">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            Informações básicas
                                                        </CardTitle>
                                                        <CardSubtitle></CardSubtitle>
                                                        <Row className="mt-5">
                                                            <Col sm="4">
                                                                <div className="form-group col-12 mw-20 hover-zoom ">
                                                                    <div className="card-picture d-flex justify-content-center align-items-center ">
                                                                        <img
                                                                            style={{
                                                                                cursor:
                                                                                    "pointer",
                                                                            }}
                                                                            src={
                                                                                controlModalImage?.data
                                                                                    ? controlModalImage?.data
                                                                                    : values?.picture
                                                                                    ? values?.picture
                                                                                    : logoupload
                                                                            }
                                                                            id="img-doctor"
                                                                            alt="doctor"
                                                                            height={
                                                                                controlModalImage?.data ||
                                                                                values?.picture
                                                                                    ? "140"
                                                                                    : "130"
                                                                            }
                                                                            name="doctor"
                                                                            width={
                                                                                controlModalImage?.data ||
                                                                                values?.picture
                                                                                    ? "140"
                                                                                    : "120"
                                                                            }
                                                                            className="mt-2 conteudo"
                                                                            onClick={() => {
                                                                                setControlModalImage(
                                                                                    {
                                                                                        show: true,
                                                                                        data: controlModalImage?.data
                                                                                            ? controlModalImage?.data
                                                                                            : null,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        />
                                                                        <label>
                                                                            <small></small>
                                                                        </label>
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        id="input-doctor"
                                                                        name="picture"
                                                                        accept="/image"
                                                                        className="d-none "
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col sm="8">
                                                                <Row>
                                                                    <Col sm="12">
                                                                        {" "}
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Nome
                                                                            </Label>
                                                                            <Field
                                                                                name="name"
                                                                                component={
                                                                                    TextInput
                                                                                }
                                                                                value={
                                                                                    values?.name
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                E-mail
                                                                            </Label>
                                                                            <Field
                                                                                name="email"
                                                                                component={
                                                                                    TextInput
                                                                                }
                                                                                value={
                                                                                    values?.email
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="6">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            Alteração de Senha
                                                        </CardTitle>
                                                        <CardSubtitle className="mb-3">
                                                            Informe os dados
                                                            para prosseguir
                                                        </CardSubtitle>
                                                        <Row>
                                                            <Col sm="12">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Senha
                                                                        antiga
                                                                    </Label>
                                                                    <Field
                                                                        name="lastPassword"
                                                                        component={
                                                                            PasswordInput
                                                                        }
                                                                        value={
                                                                            values.lastPassword
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Nova
                                                                        senha
                                                                    </Label>
                                                                    <Field
                                                                        name="newPassword"
                                                                        component={
                                                                            PasswordInput
                                                                        }
                                                                        value={
                                                                            values.newPassword
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Confirmar
                                                                        nova
                                                                        senha
                                                                    </Label>
                                                                    <Field
                                                                        name="confirmNewPassword"
                                                                        component={
                                                                            PasswordInput
                                                                        }
                                                                        value={
                                                                            values.confirmNewPassword
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div align="end">
                                    <Button color="primary" type="submit">
                                        Salvar
                                    </Button>
                                    <Link
                                        to="/dashboard"
                                        className="btn btn-danger ml-1"
                                    >
                                        Cancelar
                                    </Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </div>
            <ModalImage
                show={controlModalImage.show}
                onClose={async data => {
                    try {
                        const formDataUser = new FormData()
                        if (data?.src && data?.objectImage) {
                            formDataUser.append(
                                "image",
                                data?.objectImage,
                                data?.objectImage?.name
                            )

                            let url = await uploadImageUser(
                                userAuth?.id,
                                formDataUser
                            )

                            if (!url.public_url) {
                                dispatch(
                                    showSweetAlertDialog({
                                        title: "Ops... ",
                                        text:
                                            "Houve um erro ao processar sua solicitação,tente mais tarde...",
                                        type: "error",
                                    })
                                )
                                return
                            }

                            setControlModalImage({
                                show: false,
                                data: data?.src ? data?.src : url.public_url,
                            })
                            dispatch(
                                showToast({
                                    title: "Sucesso",
                                    text: "Imagem Atualizada!",
                                    type: "success",
                                })
                            )
                        } else {
                            setControlModalImage({
                                show: false,
                                data: controlModalImage.data,
                            })
                        }
                    } catch (e) {
                        console.log(e)
                        if (e?.response?.data?.error_code == "file_invalid") {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Formato inválido ! ",
                                    text:
                                        "Verifique o arquivo e tente novamente.",
                                    type: "error",
                                })
                            )
                            return
                        } else {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Ops... ",
                                    text:
                                        "Houve um erro ao processar sua solicitação, verifique o formato do arquivo enviado ou tente novamente mais tarde.",
                                    type: "error",
                                })
                            )
                        }
                    }
                }}
            ></ModalImage>
        </React.Fragment>
    )
}

export default handleUsers
