import React, { useEffect, useState } from "react"
import {  useParams } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {  Card, CardBody, Col, Container, Row } from "reactstrap"
import RenderForm from "../GeneralAttendance/components/renderForm"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import CertificateAttendanceColumn from "pages/Patients/Detail/pages/CertificateAttendance/CertificateAttendanceColumns"
import { useDispatch,useSelector } from "react-redux"
import { searchCertificates as searchCertificates } from "services/vitta-core/attendance"


const listCertificates = (props) => {
    const { patient_id } = useParams()
    const [certificateList, setCertificateList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })

    const [modalCertificateData, setModalCertificateData] = useState({
        show: false,
        data: null,
    })
    const Scope = useSelector(state => state.scope);
    const { SearchBar } = Search

    const dispatch = useDispatch()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let dataCertificate = []
            let dataFormatCertificate = []

            let record = await searchCertificates({
                patient_id: patient_id,
            },page, sizePerPage)

            for (let i = 0; i < record.data.length; i++) {
                dataCertificate[i] = record.data[i].medical_certificate
                for (let j = 0; j < dataCertificate.length; j++) {
                    for (let m = 0; m < dataCertificate[j].length; m++) {
                        dataCertificate[j][m].professional =
                            record.data[j].professional
                            dataCertificate[j][m].unit =  record.data[j].unit
                    }
                }
            }
            for (let i = 0; i < dataCertificate.length; i++) {
                for (let j = 0; j < dataCertificate[i].length; j++) {
                    dataFormatCertificate.push(dataCertificate[i][j])
                }
            }

            setCertificateList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: dataFormatCertificate,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect( async () => {
        search({ term: "" }, 1)
        
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }

    const RenderMedicalForm = async data => {
        let content = {...data, entity:"MedicalCertificate"  }
        const professional = {...data?.professional}
        const patient = {...props?.patientData}
        const showProfessionalSignature = true;
        const ShowPatientData = true;
        const showPrintButton = true;
        
        RenderForm({showProfessionalSignature,ShowPatientData,showPrintButton,patient,professional,content});
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Breadcrumbs
                                title="Atestados"
                                breadcrumbItem="Lista de Atestados"
                            />
                            <PaginationProvider
                                pagination={paginationFactory(certificateList)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={certificateList.data || []}
                                        columns={CertificateAttendanceColumn(
                                            RenderMedicalForm
                                        )}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
             
            </Row>
        </React.Fragment>
    )
}

export default listCertificates
