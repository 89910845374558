import request from './config/request';

const analyze = async (id, data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/approval-requests/${id}/analyze`,
        data: data,
        vtAuth: true
    })
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.expand_requested_user) query += "&expand_requested_user=true"
    if (filter.status) query += "&status=" + filter.status
    if (filter.entity_type) query += "&entity_type=" + filter.entity_type
    
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/approval-requests` +
        query,
        vtAuth: true
    })
}


export { search, analyze }
