import React, { useState, useEffect } from "react"
import { Row, Col, Modal, Table, CardBody, Badge } from "reactstrap"
import { getAppointments } from "services/vitta-core/sales-order"
import APPOINTMENT_STATUS from "utils/consts/appointments-status"
const ModalHistoricAppointments = ({ show, sales_order_id, onClose }) => {
    const [dataForm, setDataForm] = useState([])
    const loadDataModal = async () => {
        try {
            let result = []
            result = await getAppointments(sales_order_id)
            setDataForm(result)
        } catch (e) {
            console.log(e)
        }
    }
    const renderBadgeStatus = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case APPOINTMENT_STATUS.AGENDADO:
                text = "AGENDADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CONFIRMADO:
                text = "CONFIRMADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.DESMARCADO:
                text = "DESMARCADO (PACIENTE)"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CANCELADO:
                text = "CANCELADO (CLÍNICA)"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.FALTOU:
                text = "FALTOU"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.FALTOU_CONFIRMADO:
                text = "FALTOU CONFIRMADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.EM_ESPERA:
                text = "EM ESPERA"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.EM_ATENDIMENTO:
                text = "EM ATENDIMENTO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CONCLUIDO:
                text = "CONCLUÍDO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.COMPARECEU:
                text = "COMPARECEU"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.PAGO:
                text = "PAGO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_RECEPCAO:
                text = "AGUARDANDO RECEPÇÃO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_CAIXA:
                text = "AGUARDANDO CAIXA"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_LABORATORIO:
                text = "AGUARDANDO LABORATÓRIO"
                badgeClass = "badge-soft-dark"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    useEffect(() => {
        if (sales_order_id) loadDataModal()
    }, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            toggle={() => {
                onClose()
            }}
            centered
            size="xl"
        >
            <CardBody>
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <Row>
                    <Col sm="12">
                        <div className="">
                            <div className="modal-title " id="myModalLabel">
                                <h4>Histórico de Agendamentos</h4>
                            </div>
                        </div>
                        <hr />
                    </Col>
                </Row>
                <div className="modal-body" style={{paddingTop:"0.5px"}}>
                    <Row>
                        <Col sm="12">
                            <Table responsive style={{fontSize:"13px"}}>
                                <thead style={{ fontWeight: "bold",background:"#50a5f1",color:"white" }}>
                                    <tr>
                                        <th scope="col">Data e Hora</th>
                                        <th scope="col">Serviço</th>
                                        <th scope="col">Agenda</th>
                                        {/* <th scope="col">Agendado por</th>
                                        <th scope="col">Agendado em</th> */}
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataForm?.map((item, index) => (
                                        <tr key={"row" + index}>
                                            <td>
                                                {" "}
                                                {item?.start_exec
                                                    ? new Date(
                                                          item.start_exec
                                                      ).toLocaleDateString(
                                                          "pt-BR",
                                                          {
                                                              hour: "2-digit",
                                                              hour12: false,
                                                              minute: "2-digit",
                                                              second: "2-digit",
                                                          }
                                                      )
                                                    : null}
                                            </td>
                                            <td>{item?.service?.name}</td>
                                            <td>
                                                {item?.appointment_book?.name}
                                            </td>
                                            {/* <td>{item?.user?.name}</td>
                                            <td>
                                                {item?.created_at
                                                    ? new Date(
                                                          item.created_at
                                                      ).toLocaleDateString(
                                                          "pt-BR",
                                                          {
                                                              hour: "2-digit",
                                                              hour12: false,
                                                              minute: "2-digit",
                                                              second: "2-digit",
                                                          }
                                                      )
                                                    : null}
                                            </td> */}
                                            <td>
                                                {renderBadgeStatus(
                                                    item?.status
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Modal>
    )
}

export default ModalHistoricAppointments
