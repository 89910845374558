import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.name) query += "&name=" + filter.name
    if (filter.country) query += "&country=" + filter.country
    if (filter.state) query += "&state=" + filter.state
    if (filter.ibge_code) query += "&ibge_code=" + filter.ibge_code

    if(filter.id && filter.id.length > 0) query += "&id=" + filter.id.join(',')

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/counties` +
        query,
        vtAuth: true
    })
}

export { search }
