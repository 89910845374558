import { Row, Col, Card, CardBody } from "reactstrap"

import { useParams } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import logoupload from "assets/images/logoupload.png"

// const calc_age = dob => {
//     var diff_ms = Date.now() - dob.getTime()
//     var age_dt = new Date(diff_ms)

//     return Math.abs(age_dt.getUTCFullYear() - 1970)
// }

const calc_age = dateString => {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0))
    // Example date of birth.
    var dobDate = new Date(dateString)

    var years = nowDate.getFullYear() - dobDate.getFullYear()
    var months = nowDate.getMonth() - dobDate.getMonth()
    var days = nowDate.getDate() - dobDate.getDate()

    // Work out the difference in months.
    months += years * 12
    if (days < 0) {
        months -= 1
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months)
    // Calculate the difference in milliseconds.
    var diff = nowDate - dobDate
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000)
    // Now convert months back to years and months.
    years = parseInt(months / 12)
    months -= years * 12
    // Format age as "xx years, yy months, zz days"
    var text = ""

    text = years + (years > 1 ? " Anos" : years == 0 ? " Anos" : " Ano")

    if (text.length) {
        text = text + ", "

        text =
            text +
            months +
            (months > 1 ? " Meses" : months == 0 ? " Meses" : " Mês")
    }

    days = days + 1
    if (text.length) {
        text = text + ", "
    }
    text = text + days + (days > 1 ? " Dias" : days == 0 ? " dias" : " dia")

    if (nowDate == dobDate) {
        text = "Recém Nascido"
    }
    return text
}

const Header = props => {
    //functions image

    /*function doctorimg() {
            const imgdoctor = document.getElementById("img-doctor")
            const inputdoctor = document.getElementById("input-doctor")

            imgdoctor.addEventListener("click", () => inputdoctor.click())

            inputdoctor.addEventListener("change", () => {
                if (inputdoctor.files.length <= 0) {
                    return false
                }

                let file = new FileReader()

                file.onload = () => {
                    imgdoctor.src = file.result
                }
                file.readAsDataURL(inputdoctor.files[0])
            })
        }
        */
    const { id } = useParams()
    const [pageData, setPageData] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                setPageData({
                    patient: props?.patientData,
                    attendance: props?.attendanceData,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (props) loadDataEditPage()
        else console.log("não entrou no edit")
    }, [props])

    const renderAge = dateNasc => {
        if (!dateNasc) return ""
        let age = calc_age(new Date(dateNasc + " 23:59:59"))

        return <strong>{age} </strong>
    }

    return (
        <Card className="shadow-sm  bg-white rounded">
            <CardBody>
                <Row>
                    <Col sm="2" align="center">
                        {/* <FormGroup onLoad={() => {}}>
                            <div className="form-group">
                                <div className="card-picture d-flex justify-content-center align-items-center ">
                                    <img
                                        src={logoupload}
                                        id="img-doctor"
                                        alt="doctor"
                                        height="150"
                                        name="doctor"
                                        width="112"
                                    />
                                    <label></label>
                                </div>
                                <input
                                    type="file"
                                    id="input-doctor"
                                    name="picture"
                                    accept="/image"
                                    className="d-none "
                                />
                            </div>
                        </FormGroup> */}

                        <i
                            className="fas fa-user"
                            style={{ fontSize: 100 }}
                        ></i>
                    </Col>

                    <Col sm="6" align="">
                        <Row>
                            <Col>
                                <h3>
                                    #{pageData?.patient?.id}{" "}
                                    {pageData?.patient?.name}{" "}
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Sexo:{" "}
                                {pageData?.patient?.gender == "M"
                                    ? "Masculino"
                                    : pageData?.patient?.gender == "F"
                                    ? "Feminino"
                                    : "Indefinido"}{" "}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {pageData?.patient?.birth_date
                                    ? "Data de Nascimento: " +
                                      (pageData?.patient?.birth_date
                                          ? pageData.patient.birth_date.substr(
                                                8,
                                                2
                                            ) +
                                            "/" +
                                            pageData.patient.birth_date.substr(
                                                5,
                                                2
                                            ) +
                                            "/" +
                                            pageData.patient.birth_date.substr(
                                                0,
                                                4
                                            )
                                          : "")
                                    : null}
                            </Col>
                            <Col align="center">
                                Idade:{" "}
                                {renderAge(pageData?.patient?.birth_date)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Endereço : {pageData?.patient?.addr_address} -{" "}
                                {pageData?.patient?.county?.name} -{" "}
                                {pageData?.patient?.addr_state}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm="2">
                        {props?.controlState ? (
                            <div
                                className="spinner-grow text-info mt-4"
                                role="status"
                                style={{
                                    position: "absolute",
                                    left: "40px",
                                }}
                            >
                                <span className="sr-only">
                                    Sincronizando...
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                        {props?.controlState ? (
                            <div
                                className="spinner-grow text-dark mt-4"
                                role="status"
                                style={{
                                    position: "absolute",
                                    left: "64px",
                                }}
                            >
                                <span className="sr-only">
                                    Sincronizando...
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col
                        sm="2"
                        className="d-flex justify-content-center align-items-center position-relative"
                        style={{ padding: "10px" }}
                    >
                        {pageData?.attendance?.status === 1 ? (
                            <div
                                style={{
                                    backgroundColor: "#ffc107",
                                    color: "#fff",
                                    borderRadius: "25px",
                                    padding: "10px 20px",
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <i
                                    className="fas fa-notes-medical"
                                    style={{
                                        marginRight: "8px",
                                        fontSize: "18px",
                                    }}
                                ></i>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                    }}
                                >
                                    Em andamento
                                </span>
                            </div>
                        ) : pageData?.attendance?.status === 0 ? (
                            <div
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    borderRadius: "25px",
                                    padding: "10px 20px",
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <i
                                    className="fas fa-lock"
                                    style={{
                                        marginRight: "5px",
                                        fontSize: "18px",
                                    }}
                                ></i>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    Finalizado
                                </span>
                            </div>
                        ) : pageData?.attendance?.status === 2 ? (
                            <div
                                style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    borderRadius: "25px",
                                    padding: "10px 20px",
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <i
                                    className="fas fa-lock"
                                    style={{
                                        marginRight: "5px",
                                        fontSize: "18px",
                                    }}
                                ></i>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    Cancelado
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Header
