import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import RenderForm from "../GeneralAttendance/components/renderForm"
import { useDispatch } from "react-redux"
import { searchReports as searchReports } from "services/vitta-core/attendance"
import ModalReport from "../ModalRenderMedicalForm"
import ReportAttendanceColumns from "pages/Patients/Detail/pages/ReportAttendance/ReportAttendanceColumns"
import ModalReportAttendance from "./ModalReportAttendance"
const listReports = (props) => {
    const { patient_id } = useParams()
    const [reportList, setReportList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })

    const [modalReportData, setModalReportData] = useState({
        show: false,
        data: null,
    })
    const [modalReportAttendance, setModalReportAttendanceData] = useState({
        show: false,
        data: null,
    })

    const { SearchBar } = Search

    const dispatch = useDispatch()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let dataReport = []
            let dataFormatReport = []

            let record = await searchReports({
                patient_id: patient_id,
            },page, sizePerPage)

            for (let i = 0; i < record.data.length; i++) {
                dataReport[i] = record.data[i].medical_reports
                for (let j = 0; j < dataReport.length; j++) {
                    for (let m = 0; m < dataReport[j].length; m++) {
                        dataReport[j][m].professional =
                            record.data[j].professional
                        dataReport[j][m].unit =  record.data[j].unit
                    }
                }
            }

            for (let i = 0; i < dataReport.length; i++) {
                for (let j = 0; j < dataReport[i].length; j++) {
                    dataFormatReport.push(dataReport[i][j])
                }
            }

            setReportList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage ,
                custom: true,
                data: dataFormatReport,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "" }, 1)
    }, [modalReportAttendance.data])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }

   
    const RenderMedicalForm = async data => {
        
        let content = {...data,  entity:"MedicalReport"}
        const professional = {...data?.professional}
        const patient = {...props?.patientData}
        const showProfessionalSignature = true;
        const ShowPatientData = true;
        const showPrintButton = true;
        
        RenderForm({showProfessionalSignature,ShowPatientData,showPrintButton,patient,professional,content});
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">
                                <Col sm="6">
                                    <h4>Lista de laudos</h4>
                                </Col>
                                <Col sm="6" align="end">
                                    <Button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setModalReportAttendanceData({
                                                show: true,
                                                data: patient_id,
                                            })
                                        }}
                                    >
                                        <i className="fas fa-file-medical fa-2x"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <PaginationProvider
                                pagination={paginationFactory(reportList)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={reportList.data || []}
                                        columns={ReportAttendanceColumns(
                                            RenderMedicalForm
                                        )}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
                <ModalReportAttendance
                    show={modalReportAttendance.show}
                    data={modalReportAttendance.data}
                    onClose={() => {
                        setModalReportAttendanceData({
                            show: false,
                            data: null,
                        })
                    }}
                ></ModalReportAttendance>
                {/* <ModalReport
                    show={modalReportData.show}
                    data={modalReportData.data}
                    onClose={() => {
                        setModalReportData({ show: false, data: null })
                    }}
                ></ModalReport> */}
            </Row>
        </React.Fragment>
    )
}

export default listReports
