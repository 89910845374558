import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useSelector } from "react-redux"

const Footer = () => {
  const Scope = useSelector(state => state.scope);

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>© VittaNet v1.30.1</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Unidade {Scope.unit?.name}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
