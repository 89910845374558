import React, { useState, useEffect } from "react"
import { Row, Col, Button, CardBody, Card, Badge } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import logovitta from "assets/images/logovitta2.jpg"
import { useParams } from "react-router-dom"
import { searchChartPatient } from "services/vitta-core/attendance"
import "./style.css"
const ChartExport = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({})
    const [controlPage, setControlPage] = useState(0)
    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                setControlPage(1)
                let search = location.search
                const params = new URLSearchParams(search)
                const attendance_id = params.get("attendance_id")
                let record = null
                let filter = {}
                let showCanceled = false
                if (attendance_id) {
                    filter.attendance_id = attendance_id
                }

                record = await searchChartPatient(id, filter)

                let patientHeader = {
                    name: record?.patientData?.name,
                    cpf: record?.patientData?.cpf,
                    birth_date: record?.patientData?.birth_date,
                }

                if (record?.data?.find(e => e?.status == 2)) {
                    showCanceled = true
                }
                setPageData({
                    header: {
                        patientHeader,
                    },
                    data: record?.data,
                    showCanceled: showCanceled,
                })
                setControlPage(0)
                window.setTimeout(handlePrint, 500)
                // console.log("teste",record)
            } catch (e) {
                console.log("catch", e)
            }
        }

        if (id) loadDataEditPage()
        else console.log("não entrou no edit")
    }, [id])

    const handlePrint = () => {
        window.print()
    }

    return (
        <div className="page-content">
            <Card>
                <CardBody>
                    <Row>
                        <Col
                            md="8"
                            className="d-flex justify-content-start align-items-center"
                        >
                            <img
                                src={logovitta}
                                alt=""
                                height="100px"
                                className="mb-2"
                            />

                            <div className="d-flex flex-column  ">
                                <div>Clínica vittá</div>
                                <div>
                                    Paciente:{" "}
                                    {pageData?.header?.patientHeader?.name}
                                </div>
                                <div className="">
                                    Data de nascimento:{" "}
                                    {pageData?.header?.patientHeader?.birth_date
                                        ? pageData?.header?.patientHeader?.birth_date?.substr(
                                              8,
                                              2
                                          ) +
                                          "/" +
                                          pageData?.header?.patientHeader?.birth_date?.substr(
                                              5,
                                              2
                                          ) +
                                          "/" +
                                          pageData?.header?.patientHeader?.birth_date?.substr(
                                              0,
                                              4
                                          )
                                        : ""}
                                </div>
                            </div>
                        </Col>
                        <Col sm="2" align="end">
                            {pageData?.showCanceled ? (
                                <Badge
                                    className="badge-soft-danger font-size-14 mt-4"
                                    pill
                                >
                                    Atendimento Cancelado
                                </Badge>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col sm="2" align="end">
                            <Button
                                color="dark"
                                onClick={() => handlePrint()}
                                className="d-print-none"
                            >
                                Imprimir <i className="fas fa-print fa-lg"></i>
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <h4 align="center"> Dados Clínicos</h4>

                    <br />
                    <hr />
                    {/* <ComponentInvoice data={pageData} /> */}
                    {controlPage == 1 ? (
                        <Row className="mt-5">
                            <Col align="center">
                                <div
                                    className="spinner-border text-info"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}

                    {pageData?.data?.length > 0 ? (
                        pageData?.data?.map((row, index) => (
                            <table
                                className="table table-bordered mt-5 border-5 table-attendances"
                                style={{ borderWidth: "3px" }}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">
                                            {" "}
                                            <label htmlFor="date">
                                                Data
                                            </label>{" "}
                                            <br />
                                            {row?.created_at
                                                ? row?.created_at?.substr(
                                                      8,
                                                      2
                                                  ) +
                                                  "/" +
                                                  row?.created_at?.substr(
                                                      5,
                                                      2
                                                  ) +
                                                  "/" +
                                                  row?.created_at?.substr(
                                                      0,
                                                      4
                                                  ) +
                                                  " " +
                                                  row?.created_at?.substr(11, 8)
                                                : null}{" "}
                                        </th>
                                        <th scope="col">
                                            {" "}
                                            <label htmlFor="date">
                                                Atendimento
                                            </label>{" "}
                                            <br />#{row?.id}
                                        </th>
                                        <th scope="col">
                                            <label htmlFor="professional">
                                                Profissional
                                            </label>{" "}
                                            <br />
                                            {row?.professional?.name}
                                        </th>
                                        <th scope="col">
                                            <label htmlFor="consl">
                                                Conselho
                                            </label>{" "}
                                            <br />
                                            {row?.professional?.register_type
                                                ? row?.professional
                                                      ?.register_type + " "
                                                : ""}
                                            {row?.professional?.register_code
                                                ? row?.professional
                                                      ?.register_code + " "
                                                : ""}
                                            {row?.professional?.register_uf
                                                ? row?.professional?.register_uf
                                                : ""}
                                        </th>
                                        {/* <th scope="col"><label htmlFor="unit">Unidade</label>
                                                                <br />
                                                                Goiânia</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {row?.ana_main_complaint &&
                                    row?.ana_main_complaint != "" ? (
                                        <tr>
                                            <td
                                                colspan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    Detalhes Atendimento:{" "}
                                                </strong>{" "}
                                                {row?.ana_main_complaint}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}

                                    {row?.ana_hda && row?.ana_hda != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    Histórico de doença atual
                                                    (HDA):{" "}
                                                </strong>{" "}
                                                {row?.ana_hda}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.ana_hpp && row?.ana_hpp != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    História Patológica
                                                    Pregressa (HPP):{" "}
                                                </strong>{" "}
                                                {row?.ana_hpp}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.ana_hf && row?.ana_hf != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    Histórico familiar:{" "}
                                                </strong>{" "}
                                                {row?.ana_hf}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.exam_physical &&
                                    row?.exam_physical != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>Exame Físico: </strong>{" "}
                                                {row?.exam_physical}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.diag_hypotheses &&
                                    row?.diag_hypotheses != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    Hipóteses diagnosticadas:{" "}
                                                </strong>{" "}
                                                {row?.diag_hypotheses}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.diag_definitive &&
                                    row?.diag_definitive != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    {" "}
                                                    Diagnóstico definitivo:{" "}
                                                </strong>{" "}
                                                {row?.diag_definitive}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.diag_treatment &&
                                    row?.diag_treatment != "" ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong> Tratamento: </strong>{" "}
                                                {row?.diag_treatment}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.evolution?.length > 0
                                        ? row?.evolution?.map(
                                              (rowEvo, indexEvo) => (
                                                  <tr>
                                                      <td
                                                          colSpan={1}
                                                          style={{
                                                              wordBreak:
                                                                  "break-word",
                                                          }}
                                                      >
                                                          <label>
                                                              #{indexEvo} -
                                                              Evolução <br />
                                                              <strong>
                                                                  {" "}
                                                                  {rowEvo?.created_at
                                                                      ? rowEvo?.created_at?.substr(
                                                                            8,
                                                                            2
                                                                        ) +
                                                                        "/" +
                                                                        rowEvo?.created_at?.substr(
                                                                            5,
                                                                            2
                                                                        ) +
                                                                        "/" +
                                                                        rowEvo?.created_at?.substr(
                                                                            0,
                                                                            4
                                                                        ) +
                                                                        " " +
                                                                        rowEvo?.created_at?.substr(
                                                                            11,
                                                                            8
                                                                        )
                                                                      : null}
                                                              </strong>{" "}
                                                          </label>
                                                      </td>{" "}
                                                      <td
                                                          colSpan={3}
                                                          style={{
                                                              wordBreak:
                                                                  "break-word",
                                                          }}
                                                      >
                                                          {rowEvo?.text}
                                                      </td>
                                                  </tr>
                                              )
                                          )
                                        : ""}
                                    {row?.cid && row?.cid?.name != "" ? (
                                        <tr>
                                            <td colSpan={4}>
                                                <strong>CID: </strong>{" "}
                                                {row?.cid?.name}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                    {row?.drugs?.length > 0 ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>
                                                    Medicamentos em uso:
                                                </strong>
                                                {row?.drugs?.length > 0
                                                    ? row?.drugs?.map(
                                                          (
                                                              rowDrug,
                                                              indexDrug
                                                          ) => (
                                                              <span>
                                                                  {" "}
                                                                  {rowDrug?.drug_name +
                                                                      ","}
                                                              </span>
                                                          )
                                                      )
                                                    : ""}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}

                                    {row?.problems?.length > 0 ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>Comorbidades: </strong>
                                                {row?.problems?.length > 0
                                                    ? row?.problems?.map(
                                                          (
                                                              rowProblemCom,
                                                              indexDrug
                                                          ) => (
                                                              <span>
                                                                  {" "}
                                                                  {rowProblemCom.type ==
                                                                  0
                                                                      ? rowProblemCom?.problem_name +
                                                                        ","
                                                                      : ""}
                                                              </span>
                                                          )
                                                      )
                                                    : ""}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}

                                    {row?.problems?.length > 0 ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <strong>Alergias: </strong>
                                                {row?.problems?.length > 0
                                                    ? row?.problems?.map(
                                                          (
                                                              rowAllergy,
                                                              indexDrug
                                                          ) => (
                                                              <span>
                                                                  {" "}
                                                                  {rowAllergy.type ==
                                                                  1
                                                                      ? rowAllergy?.problem_name +
                                                                        ","
                                                                      : ""}
                                                              </span>
                                                          )
                                                      )
                                                    : ""}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                </tbody>
                            </table>
                        ))
                    ) : (
                        <Row>
                            <Col align="center">
                                {" "}
                                <h5>Sem registros de atendimento...</h5>
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-5">
                        <Col>
                            <strong>
                                Gerado em:{" "}
                                {new Date().toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    hour12: false,
                                    minute: "2-digit",
                                    second: "2-digit",
                                })}
                            </strong>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default ChartExport
