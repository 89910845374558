import request from './config/request';

const search = (filter, page = 1, size = 10) => {
  
    let query = "?page=" + page + "&size=" + size
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    if (filter.status) query += "&status=" + filter.status;
    if (filter.created_at) query += "&created_at=" + filter.created_at;
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;
    if (filter.expand_items) query += "&expand_items=" + filter.expand_items;
    if (filter.expand_patient) query += "&expand_patient=" + filter.expand_patient;
    if (filter.expand_items_item) query += "&expand_items_item=" + filter.expand_items_item;

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-invoices` + query,
        vtAuth: true
    })
}

const professionalTransfersInvoicesReport = (filter) => {
  
    let query = "?owner_professional_id=" + filter.owner_professional_id;
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    if (filter.doc_created_at) query += "&doc_created_at=" + filter.doc_created_at;
    if (filter.status_owner_prof_transfer) query += "&status_owner_prof_transfer=" + filter.status_owner_prof_transfer;

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-invoices/report/professional-transfer-payments` + query,
        vtAuth: true
    })
}

const cancelSalesInvoiceDoc = async (id) => {
    return request({
        method: "DELETE",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-invoices/sales-order/${id}`,
        vtAuth: true
    })
}

const cancelSalesInvoiceById = async (id) => {
    return request({
        method: "POST",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-invoices/${id}/cancel`,
        vtAuth: true
    })
}

export { search, professionalTransfersInvoicesReport, cancelSalesInvoiceById, cancelSalesInvoiceDoc }