
// states = [{
//   sigla: "TO" ,
//   name: "Tocantins"
// }];

const states =
  [
    {
      sigla: "AC",
      estado: "Acre"
    },
    {
      sigla: "AL",
      estado: "Alagoas"
    },
    {
      sigla: "AP",
      estado: "Amapá"
    },
    {
      sigla: "AM",
      estado: "Amazonas"
    },
    {
      sigla: "BA",
      estado: "Bahia"
    },
    {
      sigla: "CE",
      estado: "Ceará"
    },
    {
      sigla: "ES",
      estado: "Espírito santo"
    },
    {
      sigla: "GO",
      estado: "Goiás"
    },
    {
      sigla: "MA",
      estado: "Maranhão"
    },
    {
      sigla: "MT",
      estado: "Mato grosso"
    },
    {
      sigla: "MS",
      estado: "Mato grosso do sul"
    },
    {
      sigla: "MG",
      estado: "Minas gerais"
    },
    {
      sigla: "PA",
      estado: "Pará"
    },
    {
      sigla: "PB",
      estado: "Paraíba"
    },
    {
      sigla: "PR",
      estado: "Paraná"
    },
    {
      sigla: "PE",
      estado: "Pernambuco   "
    },
    {
      sigla: "PI",
      estado: "Piauí "
    },
    {
      sigla: "RJ",
      estado: "Rio de janeiro"
    },
    {
      sigla: "RN ",
      estado: "Rio grande do norte"
    },
    {
      sigla: "RS",
      estado: "Rio grande do sul"
    },
    {
      sigla: "RO",
      estado: "Rondonia"
    },
    {
      sigla: "RR",
      estado: "Roraima"
    },
    {
      sigla: "SC",
      estado: "Santa catarina"
    },
    {
      sigla: "SP",
      estado: "São paulo"
    },
    {
      sigla: "SE",
      estado: "Sergipe"
    },
    {
      sigla: "TO",
      estado: "Tocantins"
    },
    {
      sigla: "DF",
      estado: "Distrito federal"
    },



  ];


export default states

