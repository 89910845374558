import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip, Row, Col, Button } from "reactstrap"

const PrescriptionAttendanceColumns = (RenderMedicalForm, RenderModel,deleteRecord) => [
    // {
    //     dataField: "ID",
    //     text: "ID",
    //     formatter: (cellContent, row) => (
    //         <Link to="#" className="text-body font-weight-bold">
    //             {row?.id}
    //         </Link>
    //     ),
    // },
    // {
    //     dataField: "created_at",
    //     text: "Criado em",
    //     formatter: (cellContent, row) => (
    //         <div>
    //             {row?.created_at?.substr(8, 2) +
    //                 "/" +
    //                 row?.created_at?.substr(5, 2) +
    //                 "/" +
    //                 row?.created_at?.substr(0, 4) +
    //                 " " +
    //                 row?.created_at?.substr(11, 8)}
    //         </div>
    //     ),
    // },
    // {
    //     dataField: "user.name",
    //     text: "Usuário",
    //     formatter: (cellContent, row) => <div>{row?.user?.name}</div>,
    // },

    {
        dataField: "title",
        text: "Título",
        formatter: (cellContent, row) => <div>{row?.title}</div>,
    },
    {
        dataField: "Text",
        text: "Texto",
        formatter: (cellContent, row) => (
            <div className="text-body font-weight-bold">
                {row?.text && row?.text?.length > 100
                    ? new DOMParser()
                          .parseFromString(row?.text, "text/html")
                          .body.textContent.substr(0, 100) + " ... "
                    : row?.text
                    ? new DOMParser().parseFromString(row?.text, "text/html")
                          .body.textContent
                    : ""}
            </div>
        ),
    },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Row>
                    <div className="ml-2 mr-4">
                        <Button
                            color="primary" 
                            style={{width:"100px"}}
                            onClick={() => {
                                RenderModel(row)
                            }}
                        >
                            Usar
                        </Button>
                    </div>
                    {/* <Col sm="2">
                        <Link
                            to="#"
                            className="mr-3 text-dark"
                            onClick={() => {
                                RenderMedicalForm(row)
                            }}
                        >
                            <i
                                className="far fa-file-alt font-size-23 mr-4 fa-lg"
                                id={"edittooltip" + row?.id}
                            ></i>
                            <UncontrolledTooltip
                                placement="top"
                                target={"edittooltip" + row?.id}
                            >
                                Mais detalhes
                            </UncontrolledTooltip>
                        </Link>
                    </Col> */}
                    { row.specialty_id ? null :
                        <div className="mt-2 ml-2" >
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    deleteRecord(row?.id)
                                }}
                            >
                                Excluir
                            </Link>
                        </div>
                    }
                </Row>
            </>
           
        ),
    },
]

export default PrescriptionAttendanceColumns
