import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const history = createBrowserHistory()

const store = createStore(
  rootReducer({
    router: {history}
  }),
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history), // for dispatching history actions
    )
  )
)
sagaMiddleware.run(rootSaga)

export default store;