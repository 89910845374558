const { default: request } = require("./config/request")

async function cancelContract(card_id, id, data) {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/${card_id}/contracts/${id}/cancel`,
        data: data,
        vtAuth: true,
    })
}

async function getCreateData() {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/contracts/getCreateData`,
        data: {},
        vtAuth: true,
    })
}

async function createContract(card_id, data) {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/${card_id}/contracts/create`,
        data,
        vtAuth: true,
    })
}

async function sendContract(card_id, id, channel) {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/${card_id}/contracts/${id}/send?channel=${channel}`,
        data: {},
        vtAuth: true,
    })
}

async function createChargingSignature(card_id, id) {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/${card_id}/contracts/${id}/create-asaas-signature`,
        data: {},
        vtAuth: true,
    })
}
const showVittaCardContracts = filter => {
    let query = "?unit_id=" + filter?.unit_id
    if (filter?.user_id) query += "&user_id=" + filter?.user_id
    if (filter?.status_vitta_card)
        query += "&status_vitta_card=" + filter?.status_vitta_card
    if (filter?.status_contract)
        query += "&status_contract=" + filter?.status_contract
    if (filter?.patient_id) query += "&patient_id=" + filter?.patient_id
    if (filter?.start_created_at)
        query += "&start_created_at=" + filter?.start_created_at
    if (filter?.end_created_at)
        query += "&end_created_at=" + filter?.end_created_at
    if (filter?.start_signed_at)
        query += "&start_signed_at=" + filter?.start_signed_at
    if (filter?.end_signed_at)
        query += "&end_signed_at=" + filter?.end_signed_at
    if (filter?.start_canceled_at)
        query += "&start_canceled_at=" + filter?.start_canceled_at
    if (filter?.end_canceled_at)
        query += "&end_canceled_at=" + filter?.end_canceled_at

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/contracts/report/show` +
            query,
        vtAuth: true,
    })
}
const exportVittaCardContracts = filter => {
    let query = "";
    if(filter?.start_created_at && filter?.end_created_at)
    {
        query  = "?start_created_at=" + filter?.start_created_at + "&end_created_at=" + filter?.end_created_at;
    }
    else if(filter?.start_signed_at && filter?.end_signed_at)
    {
        query  = "?start_signed_at=" + filter?.start_signed_at + "&end_signed_at=" + filter?.end_signed_at;
    }
    else if(filter?.start_canceled_at && filter?.end_canceled_at)
    {
        query  = "?start_canceled_at=" + filter?.start_canceled_at + "&end_canceled_at=" + filter?.end_canceled_at;
    }

     
    if (filter?.unit_id) query += "&unit_id=" + filter?.unit_id;
    if (filter?.user_id) query += "&user_id=" + filter?.user_id
    if (filter?.status_vitta_card && filter?.status_vitta_card != "")
        query += "&status_vitta_card=" + filter?.status_vitta_card
    if (filter?.status_contract && filter?.status_contract != "")
        query += "&status_contract=" + filter?.status_contract
    if (filter?.patient_id) query += "&patient_id=" + filter?.patient_id
    if (filter?.start_created_at  && !query.includes("start_created_at"))
        query += "&start_created_at=" + filter?.start_created_at
    if (filter?.end_created_at && !query.includes("end_created_at"))
        query += "&end_created_at=" + filter?.end_created_at
    if (filter?.start_signed_at && !query.includes("start_signed_at"))
        query += "&start_signed_at=" + filter?.start_signed_at
    if (filter?.end_signed_at && !query.includes("end_signed_at"))
        query += "&end_signed_at=" + filter?.end_signed_at
    if (filter?.start_canceled_at && !query.includes("start_canceled_at"))
        query += "&start_canceled_at=" + filter?.start_canceled_at
    if (filter?.end_canceled_at && !query.includes("end_canceled_at"))
        query += "&end_canceled_at=" + filter?.end_canceled_at

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/vitta-cards/contracts/report/export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}
const loadCancelReason = ()=>{
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/vitta-cards/contracts/cancel_reason`,
        vtAuth: true
    })
}
export {
    cancelContract,
    createContract,
    sendContract,
    createChargingSignature,
    getCreateData,
    showVittaCardContracts,
    exportVittaCardContracts,
    loadCancelReason
}
