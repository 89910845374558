import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const ExamOrderColumns = (RenderMedicalForm, renderStatusBadge,renderStatusIntegrationBadge,renderManualExamOrder) => [
    // {
    //     dataField: "picture",
    //     text: "#",
    //     formatter: (cellContent, user) => (
    //         <>
    //             {!user.img ? (
    //                 <div className="avatar-xs">
    //                     <span className="avatar-title rounded-circle">
    //                         {user?.professional?.name.charAt(0)}
    //                     </span>
    //                 </div>
    //             ) : (
    //                 <div>
    //                     <img
    //                         className="rounded-circle avatar-xs"
    //                         src={user.picture}
    //                         alt=""
    //                     />
    //                 </div>
    //             )}
    //         </>
    //     ),
    // },
    {
        dataField: "ID",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    {
        dataField: "partner",
        text: "Laboratório",
        formatter: (cellContent, row) => <div>{row?.partner?.name}</div>,
    },
    {
        dataField: "patient",
        text: "Paciente",
        formatter: (cellContent, row) => <div>{row?.patient?.name}</div>,
    },
    {
        dataField: "unit",
        text: "Unidade",
        formatter: (cellContent, row) => <div>{row?.unit?.name}</div>,
    },
    {
        dataField: "user",
        text: "Usuário",
        formatter: (cellContent, row) => <div>{row?.user?.name}</div>,
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) => (
            <div>
                {row?.created_at?.substr(8, 2) +
                    "/" +
                    row?.created_at?.substr(5, 2) +
                    "/" +
                    row?.created_at?.substr(0, 4) +
                    " " +
                    row?.created_at?.substr(11, 8)}
            </div>
        ),
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => (
            <div>{renderStatusBadge(row?.status)}</div>
        ),
    },
    {
        dataField: "status_integration",
        text: "Status Integração",
        formatter: (cellContent, row) => (
            <div>{renderStatusIntegrationBadge(row?.partner_status_integration)}</div>
        ),
    },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <span
                    style={{ cursor: "pointer" }}
                    className="mr-2 text-primary"
                    onClick={() => {
                        RenderMedicalForm(row)
                    }}
                >
                    {/* {row?.partner_order_id ? } */}
                {row?.partner_order_id ?     <><i
                        className="far fa-file-alt font-size-24"
                        id={"edittooltip" + row?.id}
                    ></i>
                    <UncontrolledTooltip
                        placement="top"
                        target={"edittooltip" + row?.id}
                    >
                        Mais detalhes
                    </UncontrolledTooltip></> :     <><i
                        className="fas fa-eye font-size-24"
                        id={"edittooltip" + row?.id}
                    ></i>
                    <UncontrolledTooltip
                        placement="top"
                        target={"edittooltip" + row?.id}
                    >
                    Visualizar
                    </UncontrolledTooltip></>}
                
                </span>
                <Link
                    target="_blank"
                    to={"/pedidos/" + row?.sales_order_id}
                    className="mr-4 text-primary"
                >
                    &nbsp;&nbsp;&nbsp;{" "}
                    <i
                        className="fas fa-cart-plus font-size-24"
                        id={"edittooltipp" + row?.id}
                    ></i>
                    <UncontrolledTooltip
                        placement="top"
                        target={"edittooltipp" + row?.id}
                    >
                        Visualizar Pedido de Venda
                    </UncontrolledTooltip>
                </Link>
            </>
        ),
    },
]

export default ExamOrderColumns
