import React, { useState, useEffect, useMemo, useRef } from "react"
import {
    Row,
    Col,
    Modal,
    Label,
    Input,
    UncontrolledTooltip,
    CardBody,
    CardHeader,
    Collapse,
    Badge,
    InputGroup,
    InputGroupAddon,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Button,
    Spinner,
} from "reactstrap"
import { create } from "services/vitta-core/attendance"
import { Link, useHistory } from "react-router-dom"
import { Formik, Field, Form } from "formik"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import { useDispatch, useSelector } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as appointmentBookServiceSearch } from "services/vitta-core/appointment-book"
import {
    create as appointmentServiceCreate,
    update as appointmentServiceUpdate,
    loadDataEditform,
} from "services/vitta-core/appointment"
import { findServices as findServices } from "services/vitta-core/appointment-book"
import { createFromAppointment as createSalesOrderFromAppointment } from "services/vitta-core/sales-order"
import { entityStatusChange } from "services/vitta-core/entity-status-change"
import APPOINTMENT_STATUS from "utils/consts/appointments-status"
import debounce from "debounce-promise"
import { verifyAssessmentsByAppointment } from "services/vitta-core/assessments"
import ModalExecStage from "./ModalExecStage"
import { findPatient } from "services/vitta-core/patient"
import ModalBenefits from "pages/SalesDocs/Registration/Benefits/ModalBenefits"
import ModalOriginAppointment from "../ModalOriginAppointment"
import { searchCascadeView } from "services/vitta-core/appointment"
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={async option => {
            if (field.name == "appointment_book_id") {
                props.setControlLoad(true)
                let service_response = await findServices({
                    appointment_book_id: option.value,
                })
                let data_values = []
                service_response?.services?.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name,
                        value: element.id,
                        group: element.group_id,
                    })
                })

                props.state.cloud_data.services_to_appoint = data_values
                props.state.cloud_data.config_services =
                    service_response?.config
                props.state.slot_data.appointment_book = option.element
                props.setControlLoad(false)
            }
            if (field?.name == "patient_id") {
                setFieldValue("service_id", null)
                props.state.slot_data.origin_appointment = null
                props.setControlSubscriptionData({
                    checked: false,
                    data: null,
                    loading: false,
                    error: false,
                })

                props.state.form_data.patient_id = option
            }

            setFieldValue(field.name, option)
        }}
    />
)

const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="textarea" {...field} {...props} />

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const {
        pageData,
        dispatch,
        exec,
        loadConfigService,
        setPageData,
        group,
        state,
        values,
    } = props

    return (
        <Select
            {...field}
            {...props}
            onChange={option => {
                if (field?.name === "service_id") {
                    if (
                        option.is_online_execution &&
                        !option.is_person_execution
                    ) {
                        exec([{ value: 2, label: "Online (Videoconferência)" }])
                        setFieldValue("execution_mode", {
                            value: 2,
                            label: "Online (Videoconferência)",
                        })
                    } else if (!option.is_online_execution) {
                        exec([{ value: 1, label: "Presencial" }])
                        setFieldValue("execution_mode", {
                            value: 1,
                            label: "Presencial",
                        })
                    } else {
                        exec([
                            { value: 2, label: "Online (Videoconferência)" },
                            { value: 1, label: "Presencial" },
                        ])
                    }

                    loadConfigService(option)
                    state(group.includes(option.group))
                }

                let appointmentBookIdBySo = values?.appointment_book_id_by_so
                let dateBySo = values?.date_by_so

                if (field?.name === "appointment_book_id_by_so") {
                    if (option?.info_slots?.length > 0) {
                        const newPageData = { ...pageData }
                        newPageData.schedule_by_so_config.times = []
                        newPageData.schedule_by_so_config.dates = [
                            ...option.info_slots,
                        ]
                        setPageData(newPageData)

                        const isValidOptionSelected = option.info_slots.find(
                            e => e?.value === values?.date?.value
                        )
                        if (!isValidOptionSelected) {
                            setFieldValue("date_by_so", {
                                label: "Informe Data",
                                value: null,
                            })
                        }
                    }

                    setFieldValue("time_by_so", {
                        label: "Informe Horário",
                        value: null,
                    })

                    appointmentBookIdBySo = option

                    setFieldValue("obs_by_so", option.obs)
                    if (option?.professional) {
                        setFieldValue("professional_id_by_so", {
                            label: option?.professional?.name,
                            value: option?.professional?.id,
                        })
                    } else {
                        setFieldValue("professional_id_by_so", null)
                    }
                }

                if (field?.name === "date_by_so") {
                    if (option?.appointment_books?.length > 0) {
                        const newPageData = { ...pageData }
                        newPageData.schedule_by_so_config.times = []
                        newPageData.schedule_by_so_config.schedules = [
                            ...option.appointment_books,
                        ]
                        setPageData(newPageData)

                        const isValidOptionSelected = option.appointment_books.find(
                            e => e?.value === values?.appointment_book_id?.value
                        )
                        if (!isValidOptionSelected) {
                            setFieldValue("appointment_book_id_by_so", null)
                        }
                    }
                    setFieldValue("time_by_so", {
                        label: "Informe Horário",
                        value: null,
                    })
                    dateBySo = option
                }

                if (appointmentBookIdBySo?.value && dateBySo?.value) {
                    let times = []
                    if (dateBySo?.slots) {
                        times = dateBySo?.slots?.map(slot => ({
                            label: `${slot?.time?.start}`,
                            value: `${slot?.time?.start}`, //time_start_exec
                            // label: `${slot?.time?.start} às ${slot?.time?.end}`,
                            // value: `${slot?.time?.start}`, //time_start_exec
                        }))
                    } else {
                        times = appointmentBookIdBySo?.info_slots?.slots?.map(
                            slot => ({
                                label: `${slot?.time?.start}`,
                                value: `${slot?.time?.start}`, //time_start_exec
                            })
                            // slot => ({
                            //     label: `${slot?.time?.start} às ${slot?.time?.end}`,
                            //     value: `${slot?.time?.start}`, //time_start_exec
                            // })
                        )
                    }

                    const newPageData = { ...pageData }
                    newPageData.schedule_by_so_config.times = times
                    setPageData(newPageData)
                }

                setFieldValue(field.name, option)
            }}
        />
    )
}

const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const TextTimeInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="time" {...field} {...props} />

const ModalAppointment = ({ show, data, onClose, onSuccessManage }) => {
    const history = useHistory()
    const [execMode, setExecMode] = useState([
        { value: 2, label: "Online (Videoconferência)" },
        { value: 1, label: "Presencial" },
    ])

    const startDateRef = useRef(null)
    const endDateRef = useRef(null)

    const defaultFormData = {
        patient_id: null,
        service_id: null,
        date: "",
        time: null,
        status: null,
        obs: "",
        virtual_meeting_url: "",
        execution_mode: execMode[1],
        appointment_book_id_by_so: { label: "Informe Agenda", value: null },
        date_by_so: { label: "Informe Data", value: null },
        time_by_so: { label: "Informe Horário", value: null },
        obs_by_so: "",
        time_by_so_txt: "",
        show_time_by_so_txt: false,
        professional_id_by_so: null,
        schedule_by_so: false,
        is_fit_by_so: false,
    }
    const itemGroupOdonto = [2, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]

    const defaultSlotData = {
        appointment_book: null,
        appointment: null,
        time: null,
        date: "",
        origin_appointment: null,
    }

    const [pageData, setPageData] = useState({
        form_data: defaultFormData,
        slot_data: defaultSlotData,
        cloud_data: {
            services_to_appoint: [],
            sales_order: null,
        },
        schedule_by_so_config: {
            start_date: null,
            end_date: null,
            schedules: [],
            times: [],
            dates: [],
            sales_order_item_id: null,
            sales_order_id: null,
            loading:false,
            loaded:false
        },
    })
    
    const [loadingGenerateSO, setLoadingGenerateSO] = useState(false)
    const [controlButton, setControlButton] = useState(false)
    const [groupOdonto, setGroupOdonto] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [modalExecStage, setModalExecStage] = useState({
        data: null,
        show: false,
    })
    const [tab, setTab] = useState(0)
    const [controlConfigServiceAge, setControlConfigServiceAge] = useState("")
    const [listEntityChangesDataDb, setListEntityChangesDataDb] = useState([])
    const [stateToggle, setStateToggle] = useState(false)
    const [controlLoad, setControlLoad] = useState(false)
    const [controlAssessmentLoading, setControlAssessmentLoading] = useState(
        false
    )
    const [controlSubscriptionData, setControlSubscriptionData] = useState({
        checked: false,
        data: null,
        loading: false,
        error: false,
    })

    const [modalBenefits, setModalBenefits] = useState({
        show: false,
        data: null,
    })
    const [modalOriginAppointment, setModalOriginAppointment] = useState({
        show: false,
        data: null,
        setFieldValue: null,
    })
    const User = useSelector(state => state.auth.access.user)
    const [
        loadingEntityStatusChangesState,
        setLoadingEntityStatusChangesState,
    ] = useState("0")
    const [
        entityChangesAppointmentId,
        setEntityChangesAppointmentId,
    ] = useState(null)

    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    function toggle() {
        setStateToggle(!stateToggle)
    }
    const StatusOptions = [
        {
            label: "Disponível",
            value: APPOINTMENT_STATUS.DISPONIVEL,
            isDisabled: true,
        },
        {
            label: "Bloqueado",
            value: APPOINTMENT_STATUS.BLOQUEADO,
            isDisabled: true,
        },
        { label: "Agendado", value: APPOINTMENT_STATUS.AGENDADO },
        { label: "Confirmado", value: APPOINTMENT_STATUS.CONFIRMADO },
        { label: "Compareceu", value: APPOINTMENT_STATUS.COMPARECEU },
        { label: "Faltou", value: APPOINTMENT_STATUS.FALTOU },
        {
            label: "Faltou Confirmado",
            value: APPOINTMENT_STATUS.FALTOU_CONFIRMADO,
        },
        {
            label: "Desmarcado (Paciente)",
            value: APPOINTMENT_STATUS.DESMARCADO,
        },
        { label: "Cancelado (Clínica)", value: APPOINTMENT_STATUS.CANCELADO },
        { label: "Em Espera", value: APPOINTMENT_STATUS.EM_ESPERA },
        { label: "Em Atendimento", value: APPOINTMENT_STATUS.EM_ATENDIMENTO },
        { label: "Concluído", value: APPOINTMENT_STATUS.CONCLUIDO },
        { label: "Pago", value: APPOINTMENT_STATUS.PAGO },
        {
            label: "Aguardando Recepção",
            value: APPOINTMENT_STATUS.AGUARDANDO_RECEPCAO,
        },
        {
            label: "Aguardando Caixa",
            value: APPOINTMENT_STATUS.AGUARDANDO_CAIXA,
        },
        {
            label: "Aguardando Laboratório",
            value: APPOINTMENT_STATUS.AGUARDANDO_LABORATORIO,
        },
    ]

    const dispatch = useDispatch()
    const handleBG = id => {
        let element = document.getElementById(id)
        element.classList.add("bg-secondary")
        element.classList.add("text-white")
    }
    const removeBG = id => {
        let element = document.getElementById(id)
        element.classList.remove("bg-secondary")
        element.classList.remove("text-white")
    }

    const handleModalOriginAppointment = setFieldValue => {
        setModalOriginAppointment({
            show: true,
            data: pageData,
            setFieldValue: setFieldValue,
        })
    }

    const loadData = async () => {
        try {
            //Clear modal data
            setPageData({
                form_data: defaultFormData,
                slot_data: defaultSlotData,
                cloud_data: {
                    services_to_appoint: [],
                    sales_order: null,
                },
            })

            setControlSubscriptionData({
                checked: false,
                data: null,
                loading: false,
                error: false,
            })
            setControlConfigServiceAge("")
            setListEntityChangesDataDb([])
            setEntityChangesAppointmentId(null)
            setTab(0)

            let auxPageData = {
                form_data: defaultFormData,
                slot_data: defaultSlotData,
                cloud_data: {
                    services_to_appoint: [],
                    sales_order: null,
                },
                schedule_by_so_config: {
                    start_date: null,
                    end_date: null,
                    schedules: [],
                    times: [],
                    dates: [],
                    sales_order_item_id: null,
                    sales_order_id: null,
                },
            }

            if (data.schedule_by_so && !data?.appointment) {
                if (data?.patient_data) {
                    auxPageData.form_data.patient_id = {
                        label: data?.patient_data?.name,
                        value: data?.patient_data?.id,
                    }
                }
                if (data?.service_id) {
                    auxPageData.form_data.service_id = {
                        label: data?.service_id?.label,
                        value: data?.service_id?.value,
                    }
                }

                auxPageData.form_data.schedule_by_so = true

                const currentDate = new Date().toISOString().split("T")[0]

                auxPageData.schedule_by_so = true
                auxPageData.schedule_by_so_config = {
                    start_date: currentDate,
                    end_date: currentDate,
                    schedules: [],
                    dates: [],
                    times: [],
                    sales_order_item_id: data.sales_order_item_id,
                    sales_order_id: data.sales_order,
                }
                //loadDataToScheduleBySO()
            }

            auxPageData.slot_data = {
                appointment_book: data.appointment_book,
                appointment: data.appointment,
                origin_appointment: null,
                //origin_appointment:data?.appointment?.origin_appointment ? data?.appointment?.origin_appointment : null,
                time: data.time,
                date: data.date,
                isFit: data.fit,
                unit: data?.unit,
            }

            //iscompleteregisterpatient
            if (
                data?.appointment?.patient?.name &&
                data?.appointment?.patient?.cpf &&
                data?.appointment?.patient?.birth_date &&
                (data?.appointment?.patient?.phone ||
                    data?.appointment?.patient?.mobile_phone) &&
                data?.appointment?.patient?.addr_state &&
                data?.appointment?.patient?.addr_county_id &&
                data?.appointment?.patient?.addr_district &&
                data?.appointment?.patient?.addr_address &&
                data?.appointment?.patient?.addr_cep
            ) {
                auxPageData.slot_data.isCompleteRegisterPatient = true
            }
            if (data.appointment) {
                auxPageData.form_data.patient_id = {
                    label: data.appointment.patient.name,
                    value: data.appointment.patient.id,
                }
                auxPageData.form_data.service_id = {
                    label: data.appointment.service.name,
                    value: data.appointment.service.id,
                }

                if (
                    itemGroupOdonto.find(
                        e => e == data.appointment.service.group_id
                    )
                )
                    setGroupOdonto(true)
                else setGroupOdonto(false)
                auxPageData.form_data.obs = data.appointment.obs
                auxPageData.form_data.virtual_meeting_url =
                    data.appointment.virtual_meeting_url
                const exec_mode = execMode.find(
                    e => e.value == data.appointment.execution_mode
                )

                auxPageData.form_data.execution_mode = exec_mode
                for (let i = 0; i < StatusOptions.length; i++) {
                    if (StatusOptions[i].value == data.appointment.status) {
                        auxPageData.form_data.status = StatusOptions[i]
                        break
                    }
                }
                if (data.appointment.status == APPOINTMENT_STATUS.CONCLUIDO)
                    setShowButton(true)
                try {
                    let dataEdit = await loadDataEditform(data.appointment.id)

                    if (dataEdit && dataEdit.sales_order) {
                        auxPageData.cloud_data.sales_order =
                            dataEdit.sales_order
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // setPageData(auxPageData)

            // if (!data.appointment && !data.fit) {
            //     let service_response = await itemServiceSearch(
            //         {
            //             id: auxPageData.slot_data.time.services,
            //         },
            //         1,
            //         1000
            //     )

            //     let data_values = []

            //     service_response?.data?.forEach(element => {
            //         data_values.push({
            //             label:
            //                 `#${element.id} ` + element.name?.substr(0, 40),
            //             value: element.id,
            //         })
            //     })

            //     auxPageData.cloud_data.services_to_appoint = data_values
            // }

            // if (!data.appointment && data.fit) {
            //     let service_response = await findServices({
            //         appointment_book_id: data.appointment_book.id,
            //     })

            //     let data_values = []

            //     service_response?.forEach(element => {
            //         data_values.push({
            //             label:
            //                 `#${element.id} ` + element.name?.substr(0, 40),
            //             value: element.id,
            //         })
            //     })

            //     auxPageData.cloud_data.services_to_appoint = data_values
            // }
            if (!data.sales_order) {
                let service_response = await findServices({
                    appointment_book_id: data.appointment_book.id,
                    is_fit: data?.fit,
                    date: data?.date?.date,
                })
                let data_values = []
                service_response?.services?.forEach(element => {
                    let label = `#${element.id} ` + element.name

                    if (element.is_person_execution) {
                        label += " -PRESENCIAL"
                    }
                    if (element.is_online_execution) {
                        label += " -ONLINE"
                    }

                    data_values.push({
                        label: label,
                        value: element.id,
                        group: element.group_id,
                        is_person_execution: element.is_person_execution,
                        is_online_execution: element.is_online_execution,
                    })
                })

                auxPageData.cloud_data.services_to_appoint = data_values
                auxPageData.cloud_data.config_services =
                    service_response?.config
            }
            setExecMode([
                { value: 2, label: "Online (Videoconferência)" },
                { value: 1, label: "Presencial" },
            ])
            setPageData(auxPageData)

            //todo - Criar regras de exibição de status
            //Se está confirmado nao pode voltar pra disponível... se está x nao pode ir pra y...
        } catch (e) {
            console.log(e)
            onClose()
        }
    }

    const renderSubscriptionData = patient_id => {
        if (controlSubscriptionData.loading) {
            return (
                <>
                    {" "}
                    <small className="text-info">
                        Verificando assinaturas...
                    </small>{" "}
                    <Spinner
                        size={"sm"}
                        color="info"
                        style={{
                            position: "relative",
                            top: "-3px",
                        }}
                    />
                </>
            )
        } else if (
            controlSubscriptionData?.checked &&
            controlSubscriptionData?.data
        ) {
            return (
                <i
                    className="far fa-address-card text-success fa-lg"
                    style={{
                        cursor: "pointer",
                        top: "-1px",
                        position: "relative",
                    }}
                    id="signaturetooltip"
                    onClick={() => {
                        setModalBenefits({
                            show: true,
                            data:
                                controlSubscriptionData?.data
                                    ?.expand_vitta_cards,
                        })
                    }}
                >
                    {" "}
                    <UncontrolledTooltip
                        placement="top"
                        target={"signaturetooltip"}
                    >
                        Cliente Vittá + Vantagens
                    </UncontrolledTooltip>
                </i>
            )
        } else if (controlSubscriptionData?.error) {
            return (
                <i
                    className="fas fa-signal text-danger fa-lg"
                    style={{
                        cursor: "pointer",
                        top: "-2px",
                        position: "relative",
                    }}
                    id="nosignaltooltip"
                >
                    {" "}
                    <UncontrolledTooltip
                        placement="top"
                        target={"nosignaltooltip"}
                    >
                        Sem conexão com vittá +
                    </UncontrolledTooltip>
                </i>
            )
        } else if (
            controlSubscriptionData?.checked &&
            !controlSubscriptionData?.data
        ) {
            return (
                <i
                    className="far fa-address-card text-danger fa-lg"
                    style={{
                        cursor: "pointer",
                        top: "-1px",
                        position: "relative",
                    }}
                    id="nosignaturetooltip"
                >
                    {" "}
                    <UncontrolledTooltip
                        placement="top"
                        target={"nosignaturetooltip"}
                    >
                        Sem Assinaturas
                    </UncontrolledTooltip>
                </i>
            )
        }
        return (
            <i
                className="far fa-list-alt fa-lg text-info"
                style={{ cursor: "pointer", top: "-1px", position: "relative" }}
                id="signaturetooltip"
                onClick={() => {
                    verifyPatientHasSubscription(patient_id)
                }}
            >
                {" "}
                <UncontrolledTooltip
                    placement="top"
                    target={"signaturetooltip"}
                >
                    Verificar assinaturas
                </UncontrolledTooltip>
            </i>
        )
    }

    const verifyPatientHasSubscription = async patient_id => {
        if (!patient_id || controlSubscriptionData.loading) return
        let data = { ...controlSubscriptionData }
        data.loading = true
        setControlSubscriptionData(data)
        try {
            let expand_vitta_cards = 1
            let getData = await findPatient(patient_id, expand_vitta_cards)
            if (getData?.expand_vitta_cards?.subscription?.subscription_id) {
                setControlSubscriptionData({
                    checked: true,
                    data: getData,
                    error: false,
                    loading: false,
                })
            } else {
                setControlSubscriptionData({
                    checked: true,
                    data: null,
                    error: false,
                    loading: false,
                })
            }
        } catch (e) {
            console.log(e)
            setControlSubscriptionData({
                has: false,
                data: null,
                error: true,
                loading: false,
            })
        }
        // data.loading = false;
        // setControlSubscriptionData(data);
    }

    const loadDataToScheduleBySO = async () => {
        const filters = {
            unit_id: pageData?.slot_data?.unit?.id,
            service_id: pageData?.form_data?.service_id?.value,
            start_date: pageData?.schedule_by_so_config?.start_date,
            end_date: pageData?.schedule_by_so_config?.end_date,
            execution_mode: pageData?.form_data?.execution_mode?.value,
        }

        if (pageData.schedule_by_so_config.loading) return

        let cp = { ...pageData }
        cp.schedule_by_so_config.schedules = []
        cp.schedule_by_so_config.dates = []
        cp.schedule_by_so_config.loading = true
        setPageData(cp)

        let newData = {... pageData};
        try {
            let data = await searchCascadeView(filters)

            let schedules = []
            let dates = []

            for (let i = 0; i < data?.appointment_books?.length; i++) {
                let use = data?.appointment_books[i]
                let unitWithBooks = use?.units?.find(
                    e => e?.id == filters?.unit_id
                )
                let freeSchedules = []
                for (
                    let j = 0;
                    j < unitWithBooks?.appointment_books?.length;
                    j++
                ) {
                    let appointmentBook = unitWithBooks?.appointment_books[j]
                    let filterFreeRecord = appointmentBook.slots?.filter(
                        e =>
                            !e?.appointment &&
                            e?.time?.services.includes(filters?.service_id)
                    )
                    if (filterFreeRecord?.length > 0) {
                        let dataToPush = {}

                        let bookAlreadyExists = schedules.find(
                            e => e?.value == appointmentBook?.id
                        )
                        if (bookAlreadyExists) {
                            dataToPush = { ...bookAlreadyExists }
                            let existsPeriod = dataToPush?.info_slots?.find(
                                e => e?.date == use?.date
                            )
                            if (existsPeriod) {
                                let cpPeriod = { ...existsPeriod }
                                cpPeriod.slots.concat(filterFreeRecord)
                                dataToPush = dataToPush.info_slots.filter(
                                    e => e?.date != existsPeriod?.date
                                )
                                dataToPush.info_slots.push(cpPeriod)
                            } else {
                                dataToPush.info_slots.push({
                                    value: use?.date,
                                    label: use?.date_description,
                                    date: use?.date,
                                    date_description: use?.date_description,
                                    slots: filterFreeRecord,
                                })
                            }

                            // dataToPush.info_slots.slots = dataToPush.info_slots.slots.concat(
                            //     filterFreeRecord
                            // )

                            schedules = schedules.filter(
                                e => e?.value != appointmentBook?.id
                            )
                        } else {
                            dataToPush = {
                                label: appointmentBook?.name,
                                obs: appointmentBook?.obs,
                                value: appointmentBook?.id,
                                professional: appointmentBook?.professional,
                                info_slots: [
                                    {
                                        value: use?.date,
                                        label: use?.date_description,
                                        date: use?.date,
                                        date_description: use?.date_description,
                                        slots: filterFreeRecord,
                                    },
                                ],
                            }
                        }

                        freeSchedules.push({
                            value: appointmentBook?.id,
                            label: appointmentBook?.name,
                            obs: appointmentBook?.obs,
                            professional: appointmentBook?.professional,
                            info_slots: {
                                date: use?.date,
                                date_description: use?.date_description,
                                slots: filterFreeRecord,
                            },
                        })

                        schedules.push(dataToPush)
                    }
                }

                dates.push({
                    date: use?.date,
                    date_description: use?.date_description,
                    label: use?.date_description,
                    value: use?.date,
                    appointment_books: freeSchedules,
                })
            }

            
            newData.schedule_by_so_config.schedules = schedules
            newData.schedule_by_so_config.dates = dates
            newData.form_data.appointment_book_id_by_so = {
                label: "Informe Agenda",
                value: null,
            }
            newData.form_data.date_by_so = { label: "Informe Data", value: null }
            newData.form_data.time_by_so = { label: "Informe Horário", value: null }
         

            //console.log("caiuaqqq", data)
        } catch (e) {
            console.log(e);
            newData = {...pageData};
        }

        newData.schedule_by_so_config.loading = false;
        newData.schedule_by_so_config.loaded = true;
        setPageData(newData)
    }

    useEffect(() => {
        if (data?.date || data?.sales_order) loadData()
    }, [data && show])

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await patientServiceSearch(
                    {
                        term: inputValue,
                        fields: "id,name,cpf,mobile_phone,phone",
                    },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label:
                            `#${element.id} ` +
                            element.name?.substr(0, 40) +
                            (element.cpf ? ` - CPF: ${element.cpf} ` : "") +
                            (element.mobile_phone
                                ? ` - Fone: ${element.mobile_phone} `
                                : element?.phone
                                ? ` - Fone: ${element?.phone}`
                                : ""),

                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadAppointmentBookInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await appointmentBookServiceSearch(
                    { term: inputValue, expand_professional: true },
                    1,
                    20
                )
                let data_values = []
                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                        element: element,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsAppointment = debounce(
        loadAppointmentBookInputOptions,
        600
    )

    const loadConfigService = async service => {
        let configAge = {}

        for (let i = 0; i < pageData?.cloud_data?.config_services.length; i++) {
            let use = pageData?.cloud_data?.config_services[i]
            if (use?.service_id == service?.value) {
                configAge = use

                break
            }
        }

        setControlConfigServiceAge(configAge)
    }

    const handleMountConfig = () => {
        let min_age = null
        let max_age = null
        if (
            controlConfigServiceAge?.min_age_years ||
            controlConfigServiceAge?.min_age_months
        ) {
            min_age = (
                <>
                    <span className="text-danger"> Idade Mínima: </span>{" "}
                    {controlConfigServiceAge?.min_age_years
                        ? controlConfigServiceAge?.min_age_years + " anos"
                        : null}{" "}
                    {controlConfigServiceAge?.min_age_months
                        ? controlConfigServiceAge?.min_age_months + " meses  "
                        : null}
                </>
            )
        }
        if (
            controlConfigServiceAge?.max_age_years ||
            controlConfigServiceAge?.mag_age_months
        ) {
            max_age = (
                <>
                    <span className="text-danger"> Idade Máxima: </span>{" "}
                    {controlConfigServiceAge?.max_age_years
                        ? controlConfigServiceAge?.max_age_years + " anos"
                        : null}{" "}
                    {controlConfigServiceAge?.max_age_months
                        ? controlConfigServiceAge?.max_age_months + " meses"
                        : null}
                </>
            )
        }

        return (
            <>
                {min_age} {max_age}
            </>
        )
    }

    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)

    const genSalesOrderFromAppointment = async id => {

        if(loadingGenerateSO) return;

        let appointment_id = pageData.slot_data?.appointment?.id

        if (!appointment_id) return

        setLoadingGenerateSO(true);

        let subscription = null
        if (controlSubscriptionData?.data?.expand_vitta_cards) {
            subscription = controlSubscriptionData?.data?.expand_vitta_cards
        }
        let data = {
            appointment_id: appointment_id,
            subscription: subscription,
        }
        try {
            await createSalesOrderFromAppointment(data)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Pedido gerado com Sucesso!",
                    type: "success",
                })
            )

             onClose()
             
        } catch (e) {
            let textError = "Falha ao gerar Pedido."

            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "patient_register_incomplete":
                        textError =
                            "O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, data de nascimento, sexo, dados de contato e endereço."
                        break
                    case "patient_not_active":
                        textError =
                            "O Paciente precisa estar com seu cadastro ativo."
                        break
                    case "patient_not_found":
                        textError = "O Paciente não foi encontrado."
                        break
                    case "appointment_status_can_not_create_sales_order":
                        textError =
                            "Status do agendamento não permitido para geração do pedido de venda."
                        break
                }
            }

             dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        setLoadingGenerateSO(false);
    }
    const handleCreateAttendance = async props => {
        try {
            setControlButton(true)
            const dataSubmit = {
                unit_id: props?.appointment?.unit_id,
                patient_id: props?.appointment?.patient?.id,
                appointment_id: props?.appointment?.id,
            }

            let data = await create(dataSubmit)

            let url = `/pacientes/${dataSubmit?.patient_id}/atendimentos/${data?.id}`
            setControlButton(false)

            if (data?.service_created_by_appointment && data?.id) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção! ",
                        text:
                            "Já existe um atendimento vinculado a este agendamento, deseja ser redirecionado?",
                        type: "warning",
                        onConfirmAction: () => {
                            history.push(url)
                        },
                        showCancel: true,
                        showConfirm: true,
                        cancelBtnText: "Não",
                        confirmBtnText: "Sim",
                    })
                )
            }

            if (data?.id) {
                history.push(url)
                // window.open(url)
            }
        } catch (e) {
            console.log(e)
            setControlButton(false)
            if (
                e?.response?.data?.error_code?.error_code ==
                "attendance_in_open"
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title:
                            "Já existe um atendimento em aberto para este paciente!",
                        text:
                            "Finalize o atendimento pendente e tente novamente...",
                        type: "warning",
                        showConfirm: true,
                    })
                )
            }

            if (
                e?.response?.data?.error_code?.error_code ==
                "invalid_appointment_status"
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Status inválido!",
                        text:
                            "O atendimento só pode ser criado quando o status está 'Em Espera'.",
                        type: "warning",
                        showConfirm: true,
                    })
                )
            }

            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Ocorreu algum problema!",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const onSubmit = async (data_form, actions) => {

        let isFit = pageData.slot_data.isFit ? true : false;

        let isUpdate = pageData.slot_data?.appointment?.id ? true : false
        let saveData = {}

        if (data_form.schedule_by_so) {
            data_form.date = data_form.date_by_so.value
            data_form.time = data_form.time_by_so.value
            saveData.sales_order_item_id =
                pageData?.schedule_by_so_config?.sales_order_item_id
            saveData.sales_order_id =
                pageData?.schedule_by_so_config?.sales_order_id

            if(data_form?.is_fit_by_so)
            {
                isFit = true;

                if(!data_form?.time_by_so_txt || data_form?.time_by_so_txt == "")
                {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Atenção",
                            text: "Informe o horário do encaixe",
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                }

                data_form.time = data_form?.time_by_so_txt;
            }
        }

        //todo - Adicionar modal de confirmação de agendamento / alteração
        if (isUpdate) {
            if (!data_form.status) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Informe o Status do Agendamento.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.service_id) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Selecione o serviço que deseja agendar.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            saveData = {
                ...saveData,
                status: data_form.status.value,
                obs: data_form.obs,
                service_id: data_form?.service_id?.value,
                virtual_meeting_url: data_form.virtual_meeting_url,
                execution_mode: data_form?.execution_mode?.value,
                origin_appointment_id: pageData?.slot_data?.origin_appointment
                    ?.id
                    ? pageData?.slot_data?.origin_appointment?.id
                    : null,
            }
        } else {
            if (!data_form.patient_id) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Selecione o Paciente que deseja o agendamento.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.service_id) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Selecione o serviço que deseja agendar.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (
                (pageData.slot_data.isFit || data.sales_order) &&
                !data_form.date
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Informe a Data para o agendamento.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (
                (pageData.slot_data.isFit || data.sales_order) &&
                !data_form.time
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Informe o Horário para o agendamento.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            if (
                !data_form?.appointment_book_id_by_so?.value &&
                !pageData.slot_data.appointment_book
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Informe a Agenda para o agendamento.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            saveData = {
                ...saveData,
                book_id: data_form?.appointment_book_id_by_so?.value
                    ? data_form?.appointment_book_id_by_so?.value
                    : pageData.slot_data.appointment_book.id,
                date_exec:
                    pageData.slot_data.isFit || data.sales_order
                        ? data_form.date
                        : pageData.slot_data.date.date,
                time_start_exec:
                    pageData.slot_data.isFit || data.sales_order
                        ? data_form.time
                        : pageData.slot_data.time.start,
                service_id: data_form.service_id.value,
                patient_id: data_form.patient_id.value,
                is_fit:isFit,
                obs: data_form.obs,
                virtual_meeting_url: data_form.virtual_meeting_url,
                execution_mode: data_form?.execution_mode?.value,
                sales_order_id: data.sales_order,
                origin_appointment_id: pageData?.slot_data?.origin_appointment
                    ?.id
                    ? pageData?.slot_data?.origin_appointment?.id
                    : null,
            }
        }

        try {
            if (isUpdate) {
                await appointmentServiceUpdate(
                    pageData.slot_data.appointment.id,
                    saveData
                )
            } else {
                await appointmentServiceCreate(saveData)
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            if (groupOdonto) {
                if (data_form.status?.value == APPOINTMENT_STATUS.CONCLUIDO) {
                    setShowButton(true)
                    onSuccessManage(saveData)

                    setPageData(element => ({
                        ...element,
                        slot_data: {
                            ...element.slot_data,
                            appointment: {
                                ...element.slot_data.appointment,
                                service: {
                                    ...element.slot_data.appointment.service,
                                    id: data_form.service_id.value,
                                },
                            },
                        },
                    }))
                } else {
                    setShowButton(false)
                    onSuccessManage(saveData)
                    return onClose()
                }
            } else {
                if (!data.sales_order) {
                    onSuccessManage(saveData)
                }
                setShowButton(false)
                return onClose()
            }
        } catch (e) {
            setShowButton(false)
            console.log(e)
            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "time-slot-no-longer-available-24-retroat":
                        textError =
                            "Esse horário não está mais disponível para agendamentos. Agendamentos podem ser realizados de foram retroativa até 24 horas depois do horário de execução."
                        break
                    case "book-not-accept-fit":
                        textError = "Esta agenda não aceita encaixes."
                        break
                    case "book-period-not-accept-fit":
                        textError =
                            "Esta agenda aceita encaixes, mas este horário está em um período específico que não aceita encaixes."
                        break
                    case "book-period-no-longer-fits":
                        title = "Atenção!"
                        textError =
                            "Esta agenda permite encaixes, mas já atingiu sua capacidade máxima."
                        break
                    case "time-slot-invalid-service":
                        textError =
                            "Este serviço não está disponível neste horário desta agenda."
                        break
                    case "time-slot-invalid-service-online-execution":
                        textError =
                            "Este serviço não aceita o modo online de execução."
                        break
                    case "time-slot-invalid-service-person-execution":
                        textError =
                            "Este serviço não aceita o modo presencial de execução."
                        break
                    case "time-slot-not-accept-online-appointment":
                        textError =
                            "Este horário está em um período específico que não aceita agendamentos online. "
                        break
                    case "time-slot-not-available":
                        textError =
                            "Este horário não está disponível para agendamentos."
                        break
                    case "time-slot-locked":
                        textError =
                            "Este horário está bloqueado para agendamentos."
                        break
                    case "order_existent":
                        title =
                            "Não foi possível alterar o serviço do agendamento!"
                        textError =
                            "Já existe um pedido relacionado a esse agendamento. Cancele o pedido ou cancele o agendamento e crie um novo."
                        break
                    case "config_age_invalid":
                        title =
                            "Essa Agenda não aceita pacientes com essa faixa etária!"
                        textError = "Contate o supervisor de agendas..."
                        break
                    case "not_within_period":
                        textError =
                            "A marcação de consultas de retorno só é permitida se a data do retorno estiver dentro de um período de 15 dias a partir da consulta anterior."
                        break
                    case "need_sales_order_paid_to_change_status":
                        title = "Atenção!"
                        textError =
                            "Necessário que exista um pedido de venda gerado a partir deste agendamento e o mesmo deve estar pago."
                        break
                    case "need_finished_attendance_to_change_status":
                        title = "Atenção!"
                        textError =
                            "Necessário que exista um atendimento médico gerado a partir deste agendamento e o mesmo deve estar concluído, verifique se o mesmo não se encontra com status 'em andamento' e tente novamente."
                        break
                }
            }

            return dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const handleEntityStatusChange = async (
        entity_type,
        entity_id,
        tabState
    ) => {
        try {
            setLoadingEntityStatusChangesState("1")
            let data = null

            if (tab != tabState && entityChangesAppointmentId != entity_id) {
                data = await entityStatusChange(entity_type, entity_id)
            }

            if (data) {
                setListEntityChangesDataDb(data)
            }

            setEntityChangesAppointmentId(entity_id)
            setLoadingEntityStatusChangesState("0")
        } catch (e) {
            console.log(e)
        }
    }

    const renderBadgeStatus = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case APPOINTMENT_STATUS.AGENDADO:
                text = "AGENDADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CONFIRMADO:
                text = "CONFIRMADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.DESMARCADO:
                text = "DESMARCADO (PACIENTE)"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CANCELADO:
                text = "CANCELADO (CLÍNICA)"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.FALTOU:
                text = "FALTOU"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.FALTOU_CONFIRMADO:
                text = "FALTOU CONFIRMADO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.EM_ESPERA:
                text = "EM ESPERA"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.EM_ATENDIMENTO:
                text = "EM ATENDIMENTO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.CONCLUIDO:
                text = "CONCLUÍDO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.COMPARECEU:
                text = "COMPARECEU"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.PAGO:
                text = "PAGO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_RECEPCAO:
                text = "AGUARDANDO RECEPÇÃO"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_CAIXA:
                text = "AGUARDANDO CAIXA"
                badgeClass = "badge-soft-dark"
                break
            case APPOINTMENT_STATUS.AGUARDANDO_LABORATORIO:
                text = "AGUARDANDO LABORATÓRIO"
                badgeClass = "badge-soft-dark"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const viewAttendanceButton = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_medical_attendance") {
                    found = true
                    break
                }
            }
            if (found) {
                break
            }
        }

        if (found) {
            return true
        } else {
            return false
        }
    }, [User])

    const fomartReminder = text => {
        try {
            const splitName = text.split(" ")
            if (!splitName || splitName?.length <= 0) {
                return ""
            }
            const catchFirstName = splitName[0]
            const lowerFirstNme = catchFirstName.toLowerCase()

            return (
                lowerFirstNme.charAt(0).toUpperCase() + lowerFirstNme.substr(1)
            )
        } catch (e) {
            console.log(e)
        }
    }
    const professionalFormatReminder = text => {
        try {
            const splitName = text.split(" ")
            let nameFormated = ""

            if (splitName && splitName?.length > 0) {
                for (let i = 0; i < splitName?.length; i++) {
                    let use = splitName[i]
                    nameFormated = nameFormated + " " + fomartReminder(use)
                }
            }

            return nameFormated
        } catch (e) {
            console.log(e)
        }
    }
    const patientFormatReminder = text => {
        try {
            const splitName = text.split(" ")
            let nameFormated = ""

            if (splitName && splitName?.length > 0) {
                for (let i = 0; i < splitName?.length; i++) {
                    if (i < 2) {
                        let use = splitName[i]
                        nameFormated = nameFormated + " " + fomartReminder(use)
                    }
                }
            }

            return nameFormated
        } catch (e) {
            console.log(e)
        }
    }
    const handleAssessmentOdontologic = async appointment => {
        setControlAssessmentLoading(true)
        try {
            let getEvaluationOdontologic = await verifyAssessmentsByAppointment(
                appointment?.id
            )
            window.open(
                `/avaliacoes/${getEvaluationOdontologic?.assessment_id}`,
                "_blank"
            )
        } catch (e) {
            console.log(e)
            setControlAssessmentLoading(false)
            if (e?.response?.data?.error_code == "professional_not_found") {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Profissional não encontrado!",
                        text:
                            "Não encontramos um vínculo profissional referente ao seu usuário, contate nossa equipe.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops...!",
                    text: "Ocorreu algo inesperado, favor tentar novamente...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
        setControlAssessmentLoading(false)
    }
    const hasOdontologicPermission = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "manage_assessments") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])
    if (!show) return null
    return (
        <Modal
            isOpen={show}
            // toggle={() => { toggleModal() }}
            centered={true}
            size="lg"
        >
            <Formik
                initialValues={pageData.form_data}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({ values, isSubmitting, dirty, setFieldValue }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Agendamento{" "}
                                {pageData.slot_data?.appointment?.id
                                    ? "#" + pageData.slot_data?.appointment?.id
                                    : ""}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setShowButton(false)
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {pageData?.schedule_by_so ? (
                                <>
                                    {/* <small><strong className="text-danger">Filtre o período desejado e marque o agendamento.</strong></small> */}
                                    <Row className="mb-2">
                                        <Col sm="6">
                                            <Label for="startDate">
                                                Data de Início
                                            </Label>
                                            <Input
                                                type="date"
                                                name="start_date"
                                                className="form-control"
                                                defaultValue={
                                                    pageData
                                                        ?.schedule_by_so_config
                                                        .start_date
                                                }
                                                onChange={e => {
                                                    let cp = { ...pageData }
                                                    cp.schedule_by_so_config.start_date =
                                                        e?.target?.value

                                                    setPageData(cp)
                                                }}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Label for="endDate">
                                                Data de Fim
                                            </Label>
                                            <Input
                                                type="date"
                                                name="end_date"
                                                className="form-control"
                                                defaultValue={
                                                    pageData
                                                        ?.schedule_by_so_config
                                                        .end_date
                                                }
                                                onChange={e => {
                                                    let cp = { ...pageData }
                                                    cp.schedule_by_so_config.end_date =
                                                        e?.target?.value

                                                    setPageData(cp)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <button
                                                style={{ color: "white" }}
                                                className="btn-sm btn-info"
                                                onClick={() => {
                                                    event.preventDefault()
                                                    loadDataToScheduleBySO()
                                                }}
                                            >
                                                {pageData.schedule_by_so_config
                                                    .loading ? (
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                ) : (
                                                    <i className="fas fa-search"></i>
                                                )}{" "}
                                                Filtrar agendas disponíveis
                                            </button>
                                        </Col>
                                        {pageData?.schedule_by_so_config?.loaded && !pageData?.schedule_by_so_config?.loading? (
                                            <Col sm="6">
                                                {" "}
                                                <strong
                                                    className="text-info"
                                                    style={{
                                                        fontWeight: "500",
                                                        position: "relative",
                                                        top: "7px",
                                                        left: "2px",
                                                    }}
                                                >
                                                    <i className="fas fa-calendar-plus"></i>{" "}
                                                    Agendas encontradas:{" "}
                                                    {
                                                        pageData
                                                            ?.schedule_by_so_config
                                                            ?.schedules?.length
                                                    }
                                                </strong>
                                            </Col>
                                        ) : pageData?.schedule_by_so_config?.loading ? <Col sm="6">
                                        {" "}
                                        <strong
                                            className="text-info"
                                            style={{
                                                fontWeight: "500",
                                                position: "relative",
                                                top: "7px",
                                                left: "2px",
                                            }}
                                        >
                                            <i className="fas fa-spinner fa-spin"></i>{" "}
                                            Buscando agendas disponíveis
                                        </strong>
                                    </Col> : null }
                                    </Row>
                                    {pageData?.schedule_by_so_config?.schedules
                                        ?.length > 0 ? (
                                        <hr />
                                    ) : null}
                                </>
                            ) : null}

                            {values?.obs_by_so && values?.obs_by_so != "" ? (
                                <Row className="mb-2">
                                    <Col sm="12">
                                        Obs. Agenda:{" "}
                                        <strong>{values?.obs_by_so}</strong>
                                    </Col>
                                </Row>
                            ) : null}
                            {pageData.slot_data?.appointment_book?.obs &&
                            pageData.slot_data?.appobintment_book?.obs.length >
                                0 ? (
                                <Row className="mb-2">
                                    <Col sm="12">
                                        Obs. Agenda:{" "}
                                        <strong>
                                            {
                                                pageData.slot_data
                                                    ?.appointment_book?.obs
                                            }
                                        </strong>
                                        {pageData?.slot_data?.appointment
                                            ?.exceeded_limit &&
                                        pageData?.slot_data?.appointment
                                            ?.service_id == 1690 ? (
                                            <div>
                                                <small className="text-danger">
                                                    <strong>
                                                        <i className="fas fa-exclamation-circle"></i>{" "}
                                                        Este Retorno excedeu o
                                                        limite máximo de 15
                                                        dias.
                                                    </strong>
                                                </small>
                                            </div>
                                        ) : null}
                                    </Col>
                                    <Col sm="12" className="mt-1">
                                        <strong>{handleMountConfig()}</strong>
                                    </Col>
                                </Row>
                            ) : null}
                            {pageData?.form_data?.schedule_by_so ? (
                                <>
                                    {pageData?.schedule_by_so_config?.schedules
                                        ?.length > 0 ? (
                                        <>
                                            {" "}
                                            <Row className="mb-2">
                                                <Col sm="6">
                                                    <Label
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                        }}
                                                    >
                                                        {" "}
                                                        <div>Agenda </div>
                                                    </Label>
                                                    <Field
                                                        name="appointment_book_id_by_so"
                                                        placeholder="Selecionar Agenda"
                                                        options={
                                                            pageData
                                                                ?.schedule_by_so_config
                                                                ?.schedules
                                                        }
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        noOptionsMessage={() =>
                                                            "Sem Resultados."
                                                        }
                                                        defaultValue={
                                                            values?.appointment_book_id_by_so
                                                        }
                                                        state={pageData}
                                                        setControlLoad={
                                                            setControlLoad
                                                        }
                                                        setPageData={
                                                            setPageData
                                                        }
                                                        dispatch={dispatch}
                                                        pageData={pageData}
                                                        values={values}
                                                    />
                                                </Col>
                                                <Col sm="6">
                                                    <Label>Profissional</Label>
                                                    <Field
                                                        name="professional_id_by_so"
                                                        placeholder=""
                                                        options={[]}
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        noOptionsMessage={() =>
                                                            "Sem Resultados."
                                                        }
                                                        defaultValue={
                                                            values?.professional_id_by_so
                                                        }
                                                        isDisabled
                                                        state={pageData}
                                                        setControlLoad={
                                                            setControlLoad
                                                        }
                                                        setPageData={
                                                            setPageData
                                                        }
                                                        dispatch={dispatch}
                                                        pageData={pageData}
                                                        values={values}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col sm="6">
                                                    <Label>Data</Label>

                                                    <Field
                                                        name="date_by_so"
                                                        placeholder="Selecionar data"
                                                        options={
                                                            pageData
                                                                ?.schedule_by_so_config
                                                                ?.dates
                                                        }
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        noOptionsMessage={() =>
                                                            "Sem Resultados."
                                                        }
                                                        state={pageData}
                                                        setControlLoad={
                                                            setControlLoad
                                                        }
                                                        setPageData={
                                                            setPageData
                                                        }
                                                        dispatch={dispatch}
                                                        pageData={pageData}
                                                        values={values}
                                                        defaultValue={
                                                            values?.date_by_so
                                                        }
                                                    />
                                                </Col>
                                                <Col sm="6">
                                                    <Label
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            gap: "6px",
                                                        }}
                                                    >
                                                        <div>Horário</div>{" "}
                                                        <div id="iconfitadd">
                                                            <i
                                                                onClick={() => {
                                                                    if (
                                                                        !values?.show_time_by_so_txt
                                                                    ) {
                                                                        setFieldValue(
                                                                            `show_time_by_so_txt`,
                                                                            true
                                                                        )
                                                                        setFieldValue(
                                                                            `is_fit_by_so`,
                                                                            true
                                                                        )
                                                                    } else {
                                                                        setFieldValue(
                                                                            `show_time_by_so_txt`,
                                                                            false
                                                                        )
                                                                        setFieldValue(
                                                                            `is_fit_by_so`,
                                                                            false
                                                                        )
                                                                    }
                                                                }}
                                                                className={`${
                                                                    !values?.show_time_by_so_txt
                                                                        ? `fas fa-calendar-plus fa-lg`
                                                                        : `fas fa-calendar-minus fa-lg`
                                                                }`}
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    color: !values?.show_time_by_so_txt
                                                                        ? "#2a91cc"
                                                                        : "red",
                                                                }}
                                                            ></i>{" "}
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "iconfitadd"
                                                                }
                                                                style={{
                                                                    backgroundColor: !values?.show_time_by_so_txt
                                                                        ? "#2a91cc"
                                                                        : "red",
                                                                    color:
                                                                        "white",
                                                                }}
                                                            >
                                                                <i
                                                                    className={
                                                                        !values?.show_time_by_so_txt
                                                                            ? `fas fa-plus`
                                                                            : "fas fa-minus"
                                                                    }
                                                                ></i>{" "}
                                                                {!values?.show_time_by_so_txt
                                                                    ? "Ativar modo encaixe"
                                                                    : " Remover modo encaixe"}
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    </Label>
                                                    {!values?.show_time_by_so_txt ? (
                                                        <Field
                                                            name="time_by_so"
                                                            placeholder="Selecionar horário"
                                                            isDisabled={
                                                                pageData
                                                                    ?.schedule_by_so_config
                                                                    ?.times
                                                                    ?.length <=
                                                                0
                                                            }
                                                            options={
                                                                pageData
                                                                    ?.schedule_by_so_config
                                                                    ?.times ??
                                                                []
                                                            }
                                                            component={
                                                                ReactSelectInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados."
                                                            }
                                                            state={pageData}
                                                            setControlLoad={
                                                                setControlLoad
                                                            }
                                                            setPageData={
                                                                setPageData
                                                            }
                                                            dispatch={dispatch}
                                                            pageData={pageData}
                                                            values={values}
                                                            defaultValue={
                                                                values?.time_by_so
                                                            }
                                                        />
                                                    ) : (
                                                        <Field
                                                            name="time_by_so_txt"
                                                            placeholder="Selecionar horário"
                                                            component={
                                                                TextTimeInput
                                                            }
                                                            noOptionsMessage={() =>
                                                                "Sem Resultados."
                                                            }
                                                            state={pageData}
                                                            setControlLoad={
                                                                setControlLoad
                                                            }
                                                            setPageData={
                                                                setPageData
                                                            }
                                                            dispatch={dispatch}
                                                            pageData={pageData}
                                                            values={values}
                                                            defaultValue={
                                                                values?.time_by_so
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col sm="6">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexFlow: "wrap",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <Label>
                                                            Paciente{" "}
                                                            <span className="ml-1">
                                                                {pageData
                                                                    ?.slot_data
                                                                    ?.appointment
                                                                    ?.patient_id ? (
                                                                    <Link
                                                                        to={
                                                                            "/pacientes/" +
                                                                            pageData
                                                                                ?.slot_data
                                                                                ?.appointment
                                                                                ?.patient_id +
                                                                            "/dados" +
                                                                            "?appointment_id=" +
                                                                            pageData
                                                                                ?.slot_data
                                                                                ?.appointment
                                                                                ?.id
                                                                        }
                                                                        target={
                                                                            "_blank"
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fas fa-user fa-lg"
                                                                            id="patientinvoicetooltip"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-2px",
                                                                            }}
                                                                        ></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "patientinvoicetooltip"
                                                                            }
                                                                        >
                                                                            Cadastro
                                                                            do
                                                                            paciente
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                ) : data?.patient_data ? (
                                                                    <Link
                                                                        to={
                                                                            "/pacientes/" +
                                                                            data
                                                                                ?.patient_data
                                                                                ?.id +
                                                                            "/dados"
                                                                        }
                                                                        target={
                                                                            "_blank"
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fas fa-user fa-lg"
                                                                            id="patientinvoicetooltip"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-2px",
                                                                            }}
                                                                        ></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "patientinvoicetooltip"
                                                                            }
                                                                        >
                                                                            Cadastro
                                                                            do
                                                                            paciente
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        to="/pacientes/adicionar"
                                                                        className="text-danger"
                                                                        target={
                                                                            "_blank"
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fas fa-user-plus fa-lg"
                                                                            id="patientregistertooltip"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-2px",
                                                                            }}
                                                                        ></i>{" "}
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "patientregistertooltip"
                                                                            }
                                                                        >
                                                                            Cadastrar
                                                                            novo
                                                                            paciente
                                                                        </UncontrolledTooltip>{" "}
                                                                    </Link>
                                                                )}
                                                            </span>{" "}
                                                        </Label>
                                                        <div>
                                                            {values?.patient_id
                                                                ?.value
                                                                ? renderSubscriptionData(
                                                                      values
                                                                          ?.patient_id
                                                                          ?.value
                                                                  )
                                                                : null}
                                                        </div>
                                                    </div>
                                                    {pageData?.slot_data
                                                        ?.appointment
                                                        ?.patient_id ? (
                                                        <Input
                                                            name="patient_id"
                                                            value={
                                                                pageData
                                                                    ?.slot_data
                                                                    ?.appointment
                                                                    ?.patient
                                                                    ?.name
                                                            }
                                                            disabled
                                                            className="bg-light"
                                                        />
                                                    ) : data?.patient_data ? (
                                                        <Field
                                                            name="patient_id"
                                                            isDisabled
                                                            loadOptions={
                                                                debounceOptionsPatient
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            state={pageData}
                                                            dispatch={dispatch}
                                                        />
                                                    ) : (
                                                        <Field
                                                            name="patient_id"
                                                            placeholder="Selecionar Paciente"
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            setControlSubscriptionData={
                                                                setControlSubscriptionData
                                                            }
                                                            isDisabled={
                                                                pageData
                                                                    .slot_data
                                                                    ?.appointment
                                                                    ? true
                                                                    : false
                                                            }
                                                            loadOptions={
                                                                debounceOptionsPatient
                                                            }
                                                            component={
                                                                AsyncSelectInput
                                                            }
                                                            dispatch={dispatch}
                                                            state={pageData}
                                                        />
                                                    )}
                                                </Col>

                                                <Col sm="6">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Label>Serviço</Label>
                                                        {pageData?.slot_data
                                                            ?.origin_appointment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft:
                                                                        "auto",
                                                                    position:
                                                                        "relative",
                                                                    top: "1px",
                                                                }}
                                                            >
                                                                <small className="text-info">
                                                                    Agendamento
                                                                    de origem: #
                                                                    {
                                                                        pageData
                                                                            ?.slot_data
                                                                            ?.origin_appointment
                                                                            ?.id
                                                                    }{" "}
                                                                    -
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "#2c7ae6",
                                                                                textDecoration:
                                                                                    "",
                                                                                cursor:
                                                                                    "pointer",
                                                                                marginLeft:
                                                                                    "5px",
                                                                            }}
                                                                            onClick={() => {
                                                                                handleModalOriginAppointment()
                                                                            }}
                                                                            onMouseEnter={e =>
                                                                                (e.target.style.color =
                                                                                    "#0056b3")
                                                                            }
                                                                            onMouseLeave={e =>
                                                                                (e.target.style.color =
                                                                                    "#2c7ae6")
                                                                            }
                                                                        >
                                                                            Alterar
                                                                        </span>
                                                                    </strong>
                                                                </small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <Field
                                                        name="service_id"
                                                        placeholder="Serviço"
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        loadConfigService={
                                                            loadConfigService
                                                        }
                                                        options={
                                                            pageData?.cloud_data
                                                                ?.services_to_appoint
                                                        }
                                                        noOptionsMessage={() =>
                                                            "Sem Resultados."
                                                        }
                                                        group={itemGroupOdonto}
                                                        state={setGroupOdonto}
                                                        exec={setExecMode}
                                                        handleModalOriginAppointment={
                                                            handleModalOriginAppointment
                                                        }
                                                        setPageData={
                                                            setPageData
                                                        }
                                                        dispatch={dispatch}
                                                        pageData={pageData}
                                                        isDisabled
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <Label>Observações</Label>
                                                    <Field
                                                        name="obs"
                                                        rows="3"
                                                        component={
                                                            TextAreaInput
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            {/* {data?.appointment?.virtual_meeting_url &&( */}
                                            <Row className="mt-2">
                                                <Col sm="6">
                                                    <Label>Execução</Label>
                                                    <Field
                                                        placeholder="Selecione o Modo de Execução"
                                                        name="execution_mode"
                                                        options={execMode}
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                    />
                                                </Col>
                                                {values.execution_mode?.value ==
                                                    2 && (
                                                    <Col sm="6">
                                                        <Label>
                                                            Link Teleconsulta
                                                        </Label>
                                                        <Field
                                                            name="virtual_meeting_url"
                                                            component={
                                                                TextInput
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <Row className="mb-2">
                                        <Col sm="6">
                                            <Label>Agenda</Label>
                                            <Input
                                                type="text"
                                                disabled
                                                className="bg-light"
                                                value={
                                                    pageData.slot_data
                                                        ?.appointment_book?.name
                                                }
                                            />
                                        </Col>

                                        <Col sm="6">
                                            <Label>Profissional</Label>
                                            <Input
                                                type="text"
                                                disabled
                                                className="bg-light"
                                                value={
                                                    pageData.slot_data
                                                        ?.appointment_book
                                                        ?.professional?.name
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm="6">
                                            <Label>Data</Label>
                                            {pageData.slot_data.isFit ? (
                                                <Field
                                                    name="date"
                                                    component={DateInput}
                                                />
                                            ) : (
                                                <Input
                                                    type="text"
                                                    disabled
                                                    className="bg-light"
                                                    value={
                                                        pageData.slot_data?.date
                                                            ?.description
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col sm="6">
                                            <Label>Horário</Label>
                                            {pageData.slot_data.isFit ? (
                                                <Field
                                                    name="time"
                                                    component={TextTimeInput}
                                                />
                                            ) : (
                                                <Input
                                                    type="text"
                                                    disabled
                                                    className="bg-light"
                                                    value={
                                                        pageData.slot_data?.time
                                                            ?.start
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm="6">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    gap: "10px",
                                                }}
                                            >
                                                <Label>
                                                    Paciente{" "}
                                                    <span className="ml-1">
                                                        {pageData?.slot_data
                                                            ?.appointment
                                                            ?.patient_id ? (
                                                            <Link
                                                                to={
                                                                    "/pacientes/" +
                                                                    pageData
                                                                        ?.slot_data
                                                                        ?.appointment
                                                                        ?.patient_id +
                                                                    "/dados" +
                                                                    "?appointment_id=" +
                                                                    pageData
                                                                        ?.slot_data
                                                                        ?.appointment
                                                                        ?.id
                                                                }
                                                                target={
                                                                    "_blank"
                                                                }
                                                            >
                                                                <i
                                                                    className="fas fa-user fa-lg"
                                                                    id="patientinvoicetooltip"
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                        top:
                                                                            "-2px",
                                                                    }}
                                                                ></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={
                                                                        "patientinvoicetooltip"
                                                                    }
                                                                >
                                                                    Cadastro do
                                                                    paciente
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        ) : data?.patient_data ? (
                                                            <Link
                                                                to={
                                                                    "/pacientes/" +
                                                                    data
                                                                        ?.patient_data
                                                                        ?.id +
                                                                    "/dados"
                                                                }
                                                                target={
                                                                    "_blank"
                                                                }
                                                            >
                                                                <i
                                                                    className="fas fa-user fa-lg"
                                                                    id="patientinvoicetooltip"
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                        top:
                                                                            "-2px",
                                                                    }}
                                                                ></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={
                                                                        "patientinvoicetooltip"
                                                                    }
                                                                >
                                                                    Cadastro do
                                                                    paciente
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        ) : (
                                                            <Link
                                                                to="/pacientes/adicionar"
                                                                className="text-danger"
                                                                target={
                                                                    "_blank"
                                                                }
                                                            >
                                                                <i
                                                                    className="fas fa-user-plus fa-lg"
                                                                    id="patientregistertooltip"
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                        top:
                                                                            "-2px",
                                                                    }}
                                                                ></i>{" "}
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={
                                                                        "patientregistertooltip"
                                                                    }
                                                                >
                                                                    Cadastrar
                                                                    novo
                                                                    paciente
                                                                </UncontrolledTooltip>{" "}
                                                            </Link>
                                                        )}
                                                    </span>{" "}
                                                </Label>
                                                <div>
                                                    {values?.patient_id?.value
                                                        ? renderSubscriptionData(
                                                              values?.patient_id
                                                                  ?.value
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                            {/*pageData?.slot_data
                                        ?.isCompleteRegisterPatient &&
                                    data?.appointment?.patient?.id ? (
                                        <Badge
                                            className="badge-soft-success font-size-11 ml-2 "
                                            pill
                                        >
                                            Dados Completos
                                            <i className="fas fa-check fa-lg ml-1"></i>
                                        </Badge>
                                    ) : pageData?.slot_data?.appointment
                                          ?.patient_id ? (
                                        <Badge
                                            className="badge-soft-danger font-size-11 ml-2"
                                            pill
                                        >
                                            Dados Incompletos
                                            <i className="fas fa-exclamation fa-lg ml-1"></i>
                                        </Badge>
                                    ) : (
                                        ""
                                    )*/}
                                            {pageData?.slot_data?.appointment
                                                ?.patient_id ? (
                                                <Input
                                                    name="patient_id"
                                                    value={
                                                        pageData?.slot_data
                                                            ?.appointment
                                                            ?.patient?.name
                                                    }
                                                    disabled
                                                    className="bg-light"
                                                />
                                            ) : data?.patient_data ? (
                                                <Field
                                                    name="patient_id"
                                                    isDisabled
                                                    loadOptions={
                                                        debounceOptionsPatient
                                                    }
                                                    component={AsyncSelectInput}
                                                    state={pageData}
                                                    dispatch={dispatch}
                                                />
                                            ) : (
                                                <Field
                                                    name="patient_id"
                                                    placeholder="Selecionar Paciente"
                                                    cacheOptions
                                                    defaultOptions
                                                    isClearable
                                                    setControlSubscriptionData={
                                                        setControlSubscriptionData
                                                    }
                                                    isDisabled={
                                                        pageData.slot_data
                                                            ?.appointment
                                                            ? true
                                                            : false
                                                    }
                                                    loadOptions={
                                                        debounceOptionsPatient
                                                    }
                                                    component={AsyncSelectInput}
                                                    dispatch={dispatch}
                                                    state={pageData}
                                                />
                                            )}
                                        </Col>

                                        <Col sm="6">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Label>Serviço</Label>
                                                {pageData?.slot_data
                                                    ?.origin_appointment ? (
                                                    <div
                                                        style={{
                                                            marginLeft: "auto",
                                                            position:
                                                                "relative",
                                                            top: "1px",
                                                        }}
                                                    >
                                                        <small className="text-info">
                                                            Agendamento de
                                                            origem: #
                                                            {
                                                                pageData
                                                                    ?.slot_data
                                                                    ?.origin_appointment
                                                                    ?.id
                                                            }{" "}
                                                            -
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "#2c7ae6",
                                                                        textDecoration:
                                                                            "",
                                                                        cursor:
                                                                            "pointer",
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                    onClick={() => {
                                                                        handleModalOriginAppointment()
                                                                    }}
                                                                    onMouseEnter={e =>
                                                                        (e.target.style.color =
                                                                            "#0056b3")
                                                                    }
                                                                    onMouseLeave={e =>
                                                                        (e.target.style.color =
                                                                            "#2c7ae6")
                                                                    }
                                                                >
                                                                    Alterar
                                                                </span>
                                                            </strong>
                                                        </small>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <Field
                                                name="service_id"
                                                placeholder="Serviço"
                                                component={ReactSelectInput}
                                                loadConfigService={
                                                    loadConfigService
                                                }
                                                options={
                                                    pageData?.cloud_data
                                                        ?.services_to_appoint
                                                }
                                                noOptionsMessage={() =>
                                                    "Sem Resultados."
                                                }
                                                group={itemGroupOdonto}
                                                state={setGroupOdonto}
                                                exec={setExecMode}
                                                handleModalOriginAppointment={
                                                    handleModalOriginAppointment
                                                }
                                                setPageData={setPageData}
                                                dispatch={dispatch}
                                                pageData={pageData}
                                                isDisabled={controlLoad}
                                            />
                                        </Col>
                                    </Row>
                                    {pageData?.slot_data?.appointment
                                        ?.patient_id ? (
                                        <Row className="mb-2">
                                            <Col sm="6">
                                                <Label>
                                                    Contato{" "}
                                                    <i className="fas fa-phone-square-alt fa-lg ml-1"></i>
                                                </Label>
                                                <InputGroup
                                                    style={{
                                                        position: "relative",
                                                        zIndex: "3",
                                                    }}
                                                >
                                                    <Input
                                                        name="phone_patient"
                                                        value={
                                                            pageData?.slot_data
                                                                ?.appointment
                                                                ?.patient
                                                                ?.mobile_phone &&
                                                            pageData?.slot_data
                                                                ?.appointment
                                                                ?.patient?.phone
                                                                ? pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.mobile_phone +
                                                                  " / " +
                                                                  pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.phone
                                                                : pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.mobile_phone
                                                                ? pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.mobile_phone
                                                                : pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.phone
                                                                ? pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.phone
                                                                : ""
                                                        }
                                                        disabled
                                                        className="bg-light"
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        {" "}
                                                        <Dropdown
                                                            isOpen={stateToggle}
                                                            toggle={toggle}
                                                        >
                                                            <DropdownToggle
                                                                caret
                                                            >
                                                                <i className="fab fa-whatsapp fa-lg text-white"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {pageData
                                                                    ?.slot_data
                                                                    ?.appointment
                                                                    ?.patient
                                                                    ?.mobile_phone &&
                                                                pageData
                                                                    ?.slot_data
                                                                    ?.appointment
                                                                    ?.patient
                                                                    ?.phone ? (
                                                                    <>
                                                                        <DropdownItem
                                                                            id={
                                                                                "ct3"
                                                                            }
                                                                            onMouseOver={() => {
                                                                                handleBG(
                                                                                    "ct3"
                                                                                )
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                removeBG(
                                                                                    "ct3"
                                                                                )
                                                                            }}
                                                                            onClick={() => {
                                                                                const date = new Date()
                                                                                const hourDate = date?.getHours()
                                                                                const nameUnit =
                                                                                    pageData
                                                                                        ?.slot_data
                                                                                        ?.unit
                                                                                        ?.name

                                                                                const namePatientText =
                                                                                    pageData
                                                                                        ?.slot_data
                                                                                        ?.appointment
                                                                                        ?.patient
                                                                                        ?.name

                                                                                const text = `Olá, ${patientFormatReminder(
                                                                                    namePatientText
                                                                                )}. Espero te encontrar bem! 😁 %0A%0ASomos da Clínica Vittá. Estamos passando para lembrá-lo(a) do *seu compromisso* de${professionalFormatReminder(
                                                                                    pageData?.slot_data?.appointment?.service?.name?.trim()
                                                                                )}, ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.date
                                                                                        ?.description
                                                                                }às ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.time
                                                                                        ?.start
                                                                                } horas, ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.appointment_book
                                                                                        ?.professional
                                                                                        ?.name
                                                                                        ? `*com o(a) Dr(a). ` +
                                                                                          fomartReminder(
                                                                                              pageData.slot_data?.appointment_book?.professional?.name.trim()
                                                                                          ) +
                                                                                          "*"
                                                                                        : ""
                                                                                } ${
                                                                                    nameUnit
                                                                                        ? `- *Unidade ${nameUnit}*`
                                                                                        : ""
                                                                                }.%0A%0A*Sua saúde é nossa prioridade*, e estamos ansiosos para *cuidar de você*. Contamos com sua presença para um momento *dedicado ao seu bem-estar.* Até breve! 🏥%0A%0APodemos confirmar sua presença para *garantir sua vaga na agenda?*%0A%0A%0AClínica Vittá. 💙`
                                                                                const url = `https://api.whatsapp.com/send?phone=55${pageData?.slot_data?.appointment?.patient?.mobile_phone}&text=${text}
                                                                        `
                                                                                window.open(
                                                                                    url,
                                                                                    "_blank"
                                                                                )
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-paper-plane"></i>{" "}
                                                                            Lembrete
                                                                            Agendamento{" "}
                                                                            {
                                                                                pageData
                                                                                    ?.slot_data
                                                                                    ?.appointment
                                                                                    ?.patient
                                                                                    ?.mobile_phone
                                                                            }
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            id={
                                                                                "ct4"
                                                                            }
                                                                            onMouseOver={() => {
                                                                                handleBG(
                                                                                    "ct4"
                                                                                )
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                removeBG(
                                                                                    "ct4"
                                                                                )
                                                                            }}
                                                                            onClick={() => {
                                                                                const date = new Date()
                                                                                const hourDate = date?.getHours()
                                                                                const nameUnit =
                                                                                    pageData
                                                                                        ?.slot_data
                                                                                        ?.unit
                                                                                        ?.name

                                                                                const namePatientText =
                                                                                    pageData
                                                                                        ?.slot_data
                                                                                        ?.appointment
                                                                                        ?.patient
                                                                                        ?.name

                                                                                const text = `Olá, ${patientFormatReminder(
                                                                                    namePatientText
                                                                                )}. Espero te encontrar bem! 😁 %0A%0ASomos da Clínica Vittá. Estamos passando para lembrá-lo(a) do *seu compromisso* de${professionalFormatReminder(
                                                                                    pageData?.slot_data?.appointment?.service?.name?.trim()
                                                                                )}, ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.date
                                                                                        ?.description
                                                                                }às ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.time
                                                                                        ?.start
                                                                                } horas, ${
                                                                                    pageData
                                                                                        .slot_data
                                                                                        ?.appointment_book
                                                                                        ?.professional
                                                                                        ?.name
                                                                                        ? `*com o(a) Dr(a). ` +
                                                                                          fomartReminder(
                                                                                              pageData.slot_data?.appointment_book?.professional?.name.trim()
                                                                                          ) +
                                                                                          "*"
                                                                                        : ""
                                                                                } ${
                                                                                    nameUnit
                                                                                        ? `- *Unidade ${nameUnit}*`
                                                                                        : ""
                                                                                }.%0A%0A*Sua saúde é nossa prioridade*, e estamos ansiosos para *cuidar de você*. Contamos com sua presença para um momento *dedicado ao seu bem-estar.* Até breve! 🏥%0A%0APodemos confirmar sua presença para *garantir sua vaga na agenda?*%0A%0A%0AClínica Vittá. 💙`
                                                                                const url = `https://api.whatsapp.com/send?phone=55${pageData?.slot_data?.appointment?.patient?.phone}&text=${text}
                                                              `
                                                                                window.open(
                                                                                    url,
                                                                                    "_blank"
                                                                                )
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-paper-plane"></i>{" "}
                                                                            Lembrete
                                                                            Agendamento{" "}
                                                                            {
                                                                                pageData
                                                                                    ?.slot_data
                                                                                    ?.appointment
                                                                                    ?.patient
                                                                                    ?.phone
                                                                            }
                                                                        </DropdownItem>
                                                                    </>
                                                                ) : pageData
                                                                      ?.slot_data
                                                                      ?.appointment
                                                                      ?.patient
                                                                      ?.mobile_phone ? (
                                                                    <DropdownItem
                                                                        id={
                                                                            "ct3"
                                                                        }
                                                                        onMouseOver={() => {
                                                                            handleBG(
                                                                                "ct3"
                                                                            )
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            removeBG(
                                                                                "ct3"
                                                                            )
                                                                        }}
                                                                        onClick={() => {
                                                                            const date = new Date()
                                                                            const hourDate = date?.getHours()
                                                                            const nameUnit =
                                                                                pageData
                                                                                    ?.slot_data
                                                                                    ?.unit
                                                                                    ?.name

                                                                            const namePatientText =
                                                                                pageData
                                                                                    ?.slot_data
                                                                                    ?.appointment
                                                                                    ?.patient
                                                                                    ?.name

                                                                            const text = `Olá, ${patientFormatReminder(
                                                                                namePatientText
                                                                            )}. Espero te encontrar bem! 😁 %0A%0ASomos da Clínica Vittá. Estamos passando para lembrá-lo(a) do *seu compromisso* de${professionalFormatReminder(
                                                                                pageData?.slot_data?.appointment?.service?.name?.trim()
                                                                            )}, ${
                                                                                pageData
                                                                                    .slot_data
                                                                                    ?.date
                                                                                    ?.description
                                                                            }às ${
                                                                                pageData
                                                                                    .slot_data
                                                                                    ?.time
                                                                                    ?.start
                                                                            } horas, ${
                                                                                pageData
                                                                                    .slot_data
                                                                                    ?.appointment_book
                                                                                    ?.professional
                                                                                    ?.name
                                                                                    ? `*com o(a) Dr(a). ` +
                                                                                      fomartReminder(
                                                                                          pageData.slot_data?.appointment_book?.professional?.name.trim()
                                                                                      ) +
                                                                                      "*"
                                                                                    : ""
                                                                            } ${
                                                                                nameUnit
                                                                                    ? `- *Unidade ${nameUnit}*`
                                                                                    : ""
                                                                            }.%0A%0A*Sua saúde é nossa prioridade*, e estamos ansiosos para *cuidar de você*. Contamos com sua presença para um momento *dedicado ao seu bem-estar.* Até breve! 🏥%0A%0APodemos confirmar sua presença para *garantir sua vaga na agenda?*%0A%0A%0AClínica Vittá. 💙`
                                                                            const url = `https://api.whatsapp.com/send?phone=55${pageData?.slot_data?.appointment?.patient?.mobile_phone}&text=${text}
                                                          `
                                                                            window.open(
                                                                                url,
                                                                                "_blank"
                                                                            )
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-check"></i>{" "}
                                                                        Lembrete
                                                                        Agendamento
                                                                    </DropdownItem>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </DropdownMenu>{" "}
                                                        </Dropdown>{" "}
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            {pageData.slot_data.appointment ? (
                                                <Col sm="6">
                                                    <Label>Status</Label>
                                                    <Field
                                                        name="status"
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        options={StatusOptions}
                                                        state={setShowButton}
                                                    />
                                                </Col>
                                            ) : null}
                                        </Row>
                                    ) : null}
                                    <Row>
                                        <Col sm="6">
                                            <Label>Observações</Label>
                                            <Field
                                                name="obs"
                                                rows="3"
                                                component={TextAreaInput}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            {pageData?.slot_data?.appointment &&
                                            data?.appointment?.patient_id &&
                                            data?.appointment?.id ? (
                                                <Col className="mt-4">
                                                    {hasOdontologicPermission &&
                                                    pageData?.slot_data
                                                        ?.appointment?.service
                                                        ?.id ==
                                                        process.env
                                                            .REACT_APP_DENTAL_EVALUATION_ITEM_ID ? (
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="12"
                                                                align="center"
                                                                className="mt-2"
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        controlAssessmentLoading
                                                                    }
                                                                    className="btn btn-info"
                                                                    onClick={() => {
                                                                        handleAssessmentOdontologic(
                                                                            pageData
                                                                                ?.slot_data
                                                                                ?.appointment
                                                                        )
                                                                    }}
                                                                >
                                                                    Avaliação
                                                                    Odonto{" "}
                                                                    {controlAssessmentLoading ? (
                                                                        <Spinner
                                                                            size="sm"
                                                                            color="white"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-2px",
                                                                                left:
                                                                                    "5px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <i className="fas fa-tooth mr-2"></i>
                                                                    )}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ) : null}


                                                    {viewAttendanceButton ? (
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="12"
                                                                align="center"
                                                                className="mt-2"
                                                            >
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Attendance Actions"
                                                                    style={{
                                                                        color:
                                                                            "white",
                                                                    }}
                                                                >
                                                                    <Link
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#2c7be5",
                                                                        }}
                                                                        className="btn btn-info"
                                                                        to={`/pacientes/${data?.appointment?.patient_id}/atendimentos`}
                                                                        // onClick={() => {
                                                                        //     handleCreateAttendance(
                                                                        //         data
                                                                        //     )
                                                                        // }}
                                                                    >
                                                                        <i className="fas fa-comment-medical mr-1 fa-lg"></i>
                                                                        Atendimento
                                                                    </Link>
                                                                    <Link
                                                                        className="btn btn-secondary"
                                                                        to={`/pacientes/${data?.appointment?.patient_id}/atendimentos`}
                                                                        target="_blank"
                                                                    >
                                                                        <i className="fas fa-list ml-1 fa-lg"></i>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                </Col>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    {/* {data?.appointment?.virtual_meeting_url &&( */}
                                    <Row className="mt-2">
                                        <Col sm="6">
                                            <Label>Execução</Label>
                                            <Field
                                                placeholder="Selecione o Modo de Execução"
                                                name="execution_mode"
                                                options={execMode}
                                                component={ReactSelectInput}
                                            />
                                        </Col>
                                        {values.execution_mode?.value == 2 && (
                                            <Col sm="6">
                                                <Label>Link Teleconsulta</Label>
                                                <Field
                                                    name="virtual_meeting_url"
                                                    component={TextInput}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            )}

                            {/* )} */}

                            {/* {pageData.slot_data.appointment &&
                            pageData.cloud_data.sales_order == null ? (
                                <Row className="mt-4">
                                    <Col sm="6">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                genSalesOrderFromAppointment()
                                            }}
                                            className="btn btn-secondary waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Gerar Pedido de Venda
                                        </button>
                                    </Col>
                                </Row>
                            ) : null} */}
                        </div>
                        {pageData.slot_data?.appointment?.id ? (
                            <Row>
                                <Col md="12">
                                    <CardHeader
                                        style={{
                                            cursor: "pointer",
                                            height: "40px",
                                            boxShadow: "2px 2px 3px #9E9E9E",
                                            //  background:"orange"
                                        }}
                                        className="mt-3"
                                        onClick={() => {
                                            //   setTab(!index)
                                            HandleTab(1),
                                                handleEntityStatusChange(
                                                    "appointments",
                                                    pageData.slot_data
                                                        ?.appointment?.id,
                                                    "1"
                                                )
                                        }}
                                    >
                                        <Row>
                                            <Col md="6">
                                                {" "}
                                                <Badge className="badge-soft-dark font-size-12">
                                                    Histórico de Status{" "}
                                                    <i className="fas fa-user fa-lg"></i>
                                                </Badge>{" "}
                                            </Col>
                                            <Col md="6">
                                                {" "}
                                                {tab == 1 ? (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-up fa-lg">
                                                            {" "}
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div align="end">
                                                        {" "}
                                                        <i className="fas fas fa-arrow-down fa-lg">
                                                            {" "}
                                                        </i>{" "}
                                                    </div>
                                                )}
                                            </Col>{" "}
                                        </Row>
                                    </CardHeader>
                                </Col>
                            </Row>
                        ) : null}

                        <Row>
                            <Col sm="12">
                                <Collapse isOpen={tab == 1}>
                                    <table className="table">
                                        <thead
                                            className="thead-primary"
                                            style={{
                                                backgroundColor: "#0195d5",
                                                color: "white",
                                            }}
                                        >
                                            <tr>
                                                <th scope="col">Data</th>
                                                <th scope="col">Usuário</th>
                                                <th scope="col">
                                                    Status Anterior
                                                </th>
                                                <th scope="col">
                                                    Status Atual
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingEntityStatusChangesState ==
                                            "1" ? (
                                                <div
                                                    className="spinner-border text-info"
                                                    role="status"
                                                    style={{
                                                        position: "absolute",
                                                        left: "370px",
                                                        top: "100px",
                                                    }}
                                                >
                                                    <span className="sr-only">
                                                        Sincronizando...
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {listEntityChangesDataDb?.map(
                                                (row, index) => (
                                                    <tr key={row.id}>
                                                        <td
                                                            className="text-dark"
                                                            style={{
                                                                wordBreak:
                                                                    "break-all",
                                                            }}
                                                        >
                                                            {row?.created_at
                                                                ? new Date(
                                                                      row.created_at
                                                                  ).toLocaleDateString(
                                                                      "pt-BR",
                                                                      {
                                                                          hour:
                                                                              "2-digit",
                                                                          hour12: false,
                                                                          minute:
                                                                              "2-digit",
                                                                          second:
                                                                              "2-digit",
                                                                      }
                                                                  )
                                                                : null}
                                                            <br />{" "}
                                                        </td>
                                                        <td className="text-dark">
                                                            {row?.user?.name}
                                                        </td>
                                                        <td className="text-dark">
                                                            {renderBadgeStatus(
                                                                row?.previous_status
                                                            )}
                                                        </td>
                                                        <td className="text-dark">
                                                            {renderBadgeStatus(
                                                                row?.current_status
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </Collapse>
                            </Col>
                        </Row>

                        {/* <div className="modal-footer"> */}
                        <CardBody>
                            <hr />
                            <Row>
                                <Col sm="8" align="start">
                                    {pageData.slot_data.appointment &&
                                    pageData.cloud_data.sales_order == null &&
                                    !pageData.slot_data.appointment
                                        .sales_order_id &&
                                    pageData?.slot_data?.appointment?.service
                                        ?.id !=
                                        process.env
                                            .REACT_APP_DENTAL_EVALUATION_ITEM_ID &&
                                    !itemGroupOdonto.find(
                                        e =>
                                            e ==
                                            pageData?.slot_data?.appointment
                                                ?.service.group_id
                                    ) &&
                                    pageData.cloud_data.sales_order == null ? (
                                        <button
                                        disabled={loadingGenerateSO}
                                            type="button"
                                            onClick={() => {
                                                genSalesOrderFromAppointment()
                                            }}
                                            className="btn btn-dark waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Gerar Pedido de Venda{" "}
                                            <i className="fas fa-coins fa-lg"></i>
                                        </button>
                                    ) : pageData?.cloud_data?.sales_order
                                          ?.id ? (
                                        <small>
                                            <Link
                                                to={
                                                    "/pedidos/" +
                                                    pageData?.cloud_data
                                                        ?.sales_order?.id
                                                }
                                                target={"_blank"}
                                            >
                                                {" "}
                                                <i className="fas fa-eye"></i>{" "}
                                                visualizar pedido de venda
                                            </Link>
                                        </small>
                                    ) : null}
                                </Col>
                                <Col sm="4" align="end">
                                    <Button
                                        style={{
                                            padding: "6px 15px",
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            minWidth: "110px",
                                            backgroundColor: "#2b7be2",
                                            border: "none",
                                            color: "white",
                                        }}
                                        type="submit"
                                        className="btn waves-effect waves-light mr-2"
                                        disabled={
                                            isSubmitting ||
                                            !dirty ||
                                            controlLoad
                                        }
                                    >
                                        <i className="fas fa-check-circle mr-1"></i>{" "}
                                        Salvar
                                    </Button>
                                    <Button
                                        style={{
                                            padding: "6px 15px",
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            minWidth: "110px",
                                            backgroundColor: "#6c757d",
                                            border: "none",
                                            color: "white",
                                        }}
                                        type="button"
                                        onClick={() => {
                                            setShowButton(false)
                                            onClose()
                                        }}
                                        className="btn waves-effect"
                                        data-dismiss="modal"
                                    >
                                        <i className="fas fa-window-close mr-1"></i>{" "}
                                        Fechar
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>

                        {/* </div> */}
                    </Form>
                )}
            </Formik>
            <ModalExecStage
                show={modalExecStage.show}
                data={modalExecStage.data}
                onClose={() => {
                    setModalExecStage({
                        show: false,
                        data: null,
                    })
                }}
            ></ModalExecStage>
            <ModalBenefits
                show={modalBenefits?.show}
                data={modalBenefits?.data}
                onClose={() => {
                    setModalBenefits({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalOriginAppointment
                show={modalOriginAppointment.show}
                data={modalOriginAppointment.data}
                setFieldValue={modalOriginAppointment.setFieldValue}
                setControlModal={setModalOriginAppointment}
                pageData={pageData}
                setPageData={setPageData}
            />
        </Modal>
    )
}

export default ModalAppointment
