import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {createFromExamAttendance} from "services/vitta-core/sales-quote"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Card, CardBody, Col, Row } from "reactstrap"
import { showSweetAlertDialog} from "store/layout/actions"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import ExamAttendanceColumn from "pages/Patients/Detail/pages/ExamAttendance/ExamAttendanceColumns"

//import do services de usar dps alterar para os corretos
import { useDispatch } from "react-redux"
import { searchExams as searchExams } from "services/vitta-core/attendance"
import RenderForm from "../GeneralAttendance/components/renderForm"

const listExams = props => {
    const { patient_id } = useParams()
    const history = useHistory();
    const [examList, setExamList] = useState({
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })
    const [controlState, setControlState] = useState(false)
    const [modalExamData, setModalExamData] = useState({
        show: false,
        data: null,
    })
    const { SearchBar } = Search

    const dispatch = useDispatch()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let dataExam = []
            let dataFormatExam = []

            let record = await searchExams({
                patient_id: patient_id,
            },page, sizePerPage)

            
            for (let i = 0; i < record.data.length; i++) {
                dataExam[i] = record.data[i].medical_exams
                for (let j = 0; j < dataExam.length; j++) {
                    for (let m = 0; m < dataExam[j].length; m++) {
                        dataExam[j][m].professional =
                            record.data[j].professional
                        dataExam[j][m].unit =  record.data[j].unit
                        dataExam[j][m].patient = record.data[j].patient
                    }
                }
            }
            for (let i = 0; i < dataExam.length; i++) {
                for (let j = 0; j < dataExam[i].length; j++) {
                    dataFormatExam.push(dataExam[i][j])
                }
            }        

            setExamList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: dataFormatExam,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "" }, 1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }

    const DispatchConfirmMedicalExam = (id)=>{
        dispatch(
            showSweetAlertDialog({
                title: "Deseja gerar o orçamento dessa solicitação? ",
                text:
                    "Obs: Após confirmar, você será redirecionado para o orçamento gerado.",
                type: "warning",
                onConfirmAction: () => {
                    ConfirmSubmitMedicalExams(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Gerar Orçamento",
            })
        )
    }

    const ConfirmSubmitMedicalExams = async id =>{
        try{
            setControlState(true)
            let result = await createFromExamAttendance(id)
            setControlState(false)
            history.push("/orcamentos/"+result.id)
        }catch(e){
            setControlState(false)
            let textError = ""
            if(e?.response?.data){
                switch (e.response.data.code) {
                    case "missing_items":
                        textError = e.response.data.message
                        break
                    case "resource_already_created":
                        textError = e.response.data.message
                        break
                    case "resource_not_found":
                        textError = e.response.data.message
                        break
                    default:
                        textError = "Erro ao gerar o orçamento"
                }

            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )

        }
          

    }

    const RenderMedicalForm = async data => {
        let content = { ...data }
        const professional = { ...data?.professional }
        const patient = { ...data?.patient }
        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true

        RenderForm({
            showProfessionalSignature,
            ShowPatientData,
            showPrintButton,
            patient,
            professional,
            content,
        })
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Breadcrumbs
                                title="Lista"
                                breadcrumbItem="Solicitações Médicas"
                            />
                            {controlState?
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                >
                                <span className="sr-only">
                                    Loading...
                                </span>
                                </div>
                                        :
                            <PaginationProvider
                                pagination={paginationFactory(examList)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={examList.data || []}
                                        columns={ExamAttendanceColumn(
                                            RenderMedicalForm,DispatchConfirmMedicalExam
                                        )}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default listExams
