import request from "./config/request"

const create = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/exam-orders`,
        data: data,
        vtAuth: true,
    })
}

const searchOrderExams = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.sales_order_id)
        query += "&sales_order_id=" + filter.sales_order_id
    if (filter.partner_status_integration) {
        query +=
            "&partner_status_integration=" + filter.partner_status_integration
    }
    if (filter.exams_order_id)
        query += "&exams_order_id=" + filter.exams_order_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exam-orders` +
            query,
        vtAuth: true,
    })
}
const getById = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exam-orders/${id}`,
        vtAuth: true,
    })
}
const getOrderToPrint = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exam-orders/print/${id}`,
        vtAuth: true,
    })
}
const consultExamsOrder = id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/consult-exams-orders/${id}`,
        vtAuth: true,
    })
}
const cancelExamsOrder = id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/${id}/cancel`,
        vtAuth: true,
    })
}
const cancelExamsOrderItem = async (id, exam_order_item_id) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/${id}/cancel/${exam_order_item_id}`,
        vtAuth: true,
    })
}

const getExamOrderResults = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/${id}/download`,
        vtAuth: true,
    })
}
const getExamOrderItemResult = async (id, exam_order_item_id) => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/${id}/exams/${exam_order_item_id}/download`,
        vtAuth: true,
    })
}
const getExamsOrdersBySalesOrder = async sales_order_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/sales-orders/${sales_order_id}`,
        vtAuth: true,
    })
}
const syncExamOrder = async id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exams-orders/${id}/sync`,
        vtAuth: true,
    })
}
const createManually = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/exam-orders/manually`,
        data: data,
        vtAuth: true,
    })
}
const updateManually = async (id,data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/exam-orders/manually/update/${id}`,
        data: data,
        vtAuth: true,
    })
}
const cancelExamsOrderManually = id => {
    return request({
        method: "PUT",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/exam-orders/manually/cancel/${id}`,
        vtAuth: true,
    })
}
export {
    create,
    searchOrderExams,
    getById,
    getOrderToPrint,
    consultExamsOrder,
    cancelExamsOrder,
    cancelExamsOrderItem,
    getExamOrderResults,
    getExamOrderItemResult,
    getExamsOrdersBySalesOrder,
    syncExamOrder,
    createManually,
    updateManually,
    cancelExamsOrderManually
}
