import { useState } from "react"
import { Button, Spinner } from "reactstrap"
import { cancelContract } from "services/vitta-core/vitta-card-contract"
import { showSweetAlertDialog, showToast } from "store/actions"
import { CONTRACT_STATUS } from "../Contract"


function CancelContractButton({ contract, setShowModalCancel }) {

    if (contract.status === CONTRACT_STATUS.CANCELED) return <>
        <i className="fas fa-trash-alt"></i>{" "}
        <Button
            color="link"
            disabled="disabled"
            style={{ fontSize: 11, color: "red" }}
        >
            Cancelar Contrato
        </Button>
    </>

//    if (state.loading) return <Spinner size="sm" color="red" />

    return (
        <>
            <i className="fas fa-trash-alt"></i>{" "}
            <Button
                color="link"
                style={{ fontSize: 11, color: "red" }}
                onClick={()=>{
                    setShowModalCancel({
                        show:true,
                        contract:contract
                    })
                }}
                
            >
                Cancelar Contrato
            </Button>
        </>
    )
}

export default CancelContractButton
