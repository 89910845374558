import React, { useEffect, useState, useRef } from "react"
import { search as userServiceSearch } from "services/vitta-core/user"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MEDICAL_FORMS_TYPES from "utils/consts/medical-form-types"
import debounce from "debounce-promise"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Form,
    Input,
    UncontrolledTooltip,
    FormGroup,
    Label,
} from "reactstrap"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import ListLogsColumns from "pages/AuditLogs/ListLogs/ListLogsColumns"
import ModalSecondary from "../LogsModalSecondary/ModalSecondary"
//import do services de usar dps alterar para os corretos
import AsyncSelect from "react-select/async"
import { useDispatch } from "react-redux"
import { searchModels as searchModels } from "services/vitta-core/medical-forms-models"
import { deleteModel as deleteModel } from "services/vitta-core/medical-forms-models"
import { searchLogs as searchLogs } from "services/vitta-core/audit-logs"

const indexListLogs = ({ data, entity_type, handleModel }) => {
    const [list, setList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })

    const [controlModalLogsSecondary, setControlModalLogsSecondary] = useState({
        show: false,
        data: null,
    })
    const inputUserRef = useRef();
    const inputStartDateRef = useRef();
    const inputEndDateRef = useRef();
    const { SearchBar } = Search

    const dispatch = useDispatch()

    const deleteRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            let tempData = { ...list }

            await deleteModel(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )

            tempData.data = list.data.filter(elem => elem.id != id)

            setList(tempData)
        } catch (e) {
            if (e?.response?.data?.code == "not_permission") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Você não tem permissão!",
                        text:
                            "Só é possível excluir os modelos que você criou.",
                        type: "error",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    const search = async (filter, page) => {
        let sizePerPage = 5
        let record = null
        try {
            let searchObj={
                entity_id:data?.entity_id,
                entity:data?.entity
            };
          
            
            let user_id = inputUserRef.current.select?.state?.value?.value
            let start_created_at = inputStartDateRef.current.value
            let end_created_at = inputEndDateRef.current.value
            if(user_id)searchObj.user_id = user_id;
            if(start_created_at) searchObj.start_created_at = start_created_at;
            if(end_created_at) searchObj.end_created_at = end_created_at;
           
            if (searchObj?.entity_id) {
                
                record = await searchLogs(searchObj,page,sizePerPage);
            }
            

            setList({
                page:page,
                sizePerPage: sizePerPage,
                totalSize: record.total_pages* sizePerPage,
                custom: true,
                data: record?.data,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "" }, 1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }
    const renderLogs = props => {
        setControlModalLogsSecondary({
            show: true,
            data: props,
        })
    }

    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    25
                )

                let users_values = []
                users_values.push({
                    label:"Todos",
                    value:null
                })
                response.data.forEach(element => {
                    users_values.push({
                        label:
                            `${element.id}-` +
                            element.name +
                            `<${element.email}>`,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)
    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card className="shadow-lg p-3 mb-1 bg-white rounded">
                        <CardBody>
                            <Breadcrumbs
                                title="Logs"
                                breadcrumbItem={
                                    entity_type &&
                                    entity_type ==
                                        MEDICAL_FORMS_TYPES.PRESCRIÇÃO
                                        ? "Prescrições"
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.ATESTADO
                                        ? "Atestados"
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.LAUDO
                                        ? "Laudos"
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.ENCAMINHAMENTO
                                        ? "Encaminhamentos"
                                        : entity_type ==
                                          MEDICAL_FORMS_TYPES.CONSENTIMENTO
                                        ? "Exames"
                                        : ""
                                }
                            />
                            <Row className="mb-2">
                                <Col sm="3">
                                    <Label>Usuário do Sistema</Label>

                                    <AsyncSelect
                                        name="user_id"
                                        placeholder="Digite para buscar"
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={
                                            debounceOptionsUser
                                        }
                                        ref={inputUserRef}
                                        defaultValue={{label:"Todos",value:null}}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Label>Data registro inicial</Label>

                                    <Input name="record_start" type="date" innerRef={inputStartDateRef}></Input>
                                </Col>
                                <Col sm="3">
                                    <Label>Data registro final</Label>
                                            <Input name="record_end" type="date" innerRef={inputEndDateRef}></Input>
                                </Col>
                                <Col sm="3">
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            search()
                                        }}
                                    >
                                        {/* <div className="search-box mr-2 d-inline-block">
                                            <div className="position-relative">
                                                <Input
                                                    type="text"
                                                    innerRef={inputRefSearch}
                                                ></Input>
                                                <i className="bx bx-search-alt search-icon" />
                                            </div>
                                        </div> */}
                                        <Button
                                            type="button"
                                            color="dark"
                                            className="mt-4"
                                            id="tooltipprescript"
                                            onClick={() => {
                                                search()
                                            }}
                                        >
                                            <span className="text-white">
                                                {" "}
                                                <i className="bx bx-search-alt fa-lg"></i>
                                            </span>
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"tooltipprescript"}
                                            >
                                                {" "}
                                                Clique para buscar
                                            </UncontrolledTooltip>
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                            <PaginationProvider
                                pagination={paginationFactory(list)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={list.data || []}
                                        columns={ListLogsColumns(renderLogs)}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={true}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap "
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-dark"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalSecondary
                show={controlModalLogsSecondary.show}
                data={controlModalLogsSecondary.data}
                onClose={response => {
                    setControlModalLogsSecondary({
                        show: false,
                        data: null,
                    })
                }}
            ></ModalSecondary>
        </React.Fragment>
    )
}

export default indexListLogs