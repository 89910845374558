import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
} from "reactstrap"
import debounce from "debounce-promise"
import AsyncSelect from "react-select/async"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FastField, Field, Form, Formik } from "formik"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { loadDataEditform, create, update } from "services/vitta-core/access-roles"
import {search as searchPermission} from "services/vitta-core/permissions"

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="text" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            props.stateCustom(false)
            setFieldValue(field.name, option)
        }}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            if(JSON.stringify(option) != JSON.stringify(props.permission)) props.stateCustom(false)
            else props.stateCustom(true)
            
            setFieldValue(field.name, option)
        }}
    />
)

const optionsStatus = [
    { value: "1", label: "Ativo" },
    { value: "0", label: "Inativo" },
]

const defaultAccessRolesData = {
    name: "",
    description: "",
    status: null,
    permission: null
}

const RegistrationAccessRoles = () => {
    
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [pageData, setPageData] = useState({
        AccessRoles: defaultAccessRolesData,
    })
    const [CustomSelect, setCustomSelect] = useState(true)

    useEffect(() => {
        if (id) loadDataEditPage()
    }, [id])

    const loadPermissionInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchPermission({ term: inputValue }, 1, 25)

                let permission_values = []
                response.data.forEach(element => {
                    permission_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(permission_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPermission = debounce(loadPermissionInputOptions, 600)

    const loadDataEditPage = async () => {
        try{
            let data = await loadDataEditform(id)
            for (let i = 0; i < optionsStatus.length; i++) {
                if (optionsStatus[i].value == data.accessRoles.status) {
                    data.accessRoles.status = optionsStatus[i]
                    break
                }
            }
            let PermissionOption = []
            for (let i=0; i<data.accessRoles.permissions.length;i++){
                PermissionOption.push({
                    label: data.accessRoles.permissions[i].name,
                    value: data.accessRoles.permissions[i].id
                })
            }
            data.accessRoles.permission = PermissionOption
            setPageData({AccessRoles: data.accessRoles})

        }catch(e){
            console.log(e)
        }
    }

    const onSubmit = async (form_data) =>{
        try{
            let filter = {}
            let permissions = []
            for (let i=0; i<form_data.permission?.length; i++){
                permissions[i] = form_data.permission[i].value
            }
            if(!form_data.status){
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Selecione o status",
                        type: "error",
                    })
                )
            }
            if(form_data.id) filter.id = form_data.id
            if(form_data.name) filter.name = form_data.name
            if(form_data.status.value) filter.status = form_data.status.value
            if(form_data.description) filter.description = form_data.description
            if(form_data.permission) filter.permission = permissions.join(',')
            if (id) {
                await update(id, filter)
            } else {
                await create(filter)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            history.push("/perfil-de-acesso")
        }catch(e){
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            if (e?.response?.data?.code == "resource_already_created"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: e?.response?.data?.message,
                        type: "error",
                    })
                )
            }
            if (e?.response?.data?.code == "missing_name"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Insira um nome para o Perfil",
                        type: "error",
                    })
                )
            }
            if(e?.response?.data?.code == "resource_not_found"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: e?.response?.data?.message,
                        type: "error",
                    })
                )
            }
            console.log(e)
        }
        
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Perfil de Acesso"
                        breadcrumbItem="Perfil de Acesso"
                    />

                    <Formik
                        initialValues={pageData.AccessRoles}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            Dados do Perfil de Acesso
                                        </CardTitle>
                                        <Row>
                                            <Col sm="3">
                                                <FormGroup>
                                                    <Label>
                                                        Nome
                                                    </Label>
                                                    <FastField
                                                        name="name"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values.name
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>
                                                        Descrição
                                                    </Label>
                                                    <FastField
                                                        name="description"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values.description
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3">
                                                <FormGroup>
                                                    <Label>
                                                        Status
                                                    </Label>
                                                    <FastField
                                                        placeholder="Selecione..."
                                                        name="status"
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        options={
                                                            optionsStatus
                                                        }
                                                        defaultValue={
                                                            values.status
                                                        }
                                                        stateCustom={setCustomSelect}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {id?
                                            <Col sm="12">
                                                <FormGroup>
                                                    <Label>
                                                        Permissões
                                                    </Label>
                                                    <FastField
                                                        placeholder="Selecione..."
                                                        name="permission"
                                                        component={
                                                            AsyncSelectInput
                                                        }
                                                        loadOptions={
                                                            debounceOptionsPermission
                                                        }
                                                        permission = {pageData.AccessRoles.permission}
                                                        defaultValue={
                                                            values.permission
                                                        }
                                                        noOptionsMessage={() =>
                                                            "Sem Resultados. Digite para Buscar"
                                                        }
                                                        stateCustom={setCustomSelect}
                                                        isMulti={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            :""}
                                            
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div align="end">
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="mr-2"
                                                        disabled={CustomSelect}
                                                    >
                                                        Salvar
                                                    </Button>
                                                    <Link
                                                        to="/perfil-de-acesso"
                                                        className="btn btn-danger"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </div>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        )}


                    </Formik>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegistrationAccessRoles