import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
} from "reactstrap"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FastField, Form, Formik } from "formik"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { loadDataEditform, create, update } from "services/vitta-core/permissions"

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="text" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const optionsStatus = [
    { value: "1", label: "Ativo" },
    { value: "0", label: "Inativo" },
]

const defaultPermissionData = {
    name: "",
    description: "",
    status: { value: "1", label: "Ativo" },
}

const RegistrationPermissions = () => {
    
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [pageData, setPageData] = useState({
        permission: defaultPermissionData,
    })

    useEffect(() => {
        if (id) loadDataEditPage()
    }, [id])

    const loadDataEditPage = async () => {
        try{
            let data = await loadDataEditform(id)
            for (let i = 0; i < optionsStatus.length; i++) {
                if (optionsStatus[i].value == data.permission.status) {
                    data.permission.status = optionsStatus[i]
                    break
                }
            }
            setPageData({permission: data.permission})

        }catch(e){
            console.log(e)
        }
    }

    const onSubmit = async (form_data) =>{
        try{
            let filter = {}
            if(form_data.id) filter.id = form_data.id
            if(form_data.name) filter.name = form_data.name
            if(form_data.status.value) filter.status = form_data.status.value
            if(form_data.description) filter.description = form_data.description
            if (id) {
                await update(id, filter)
            } else {
                await create(filter)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            history.push("/permissoes")
        }catch(e){
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            if (e?.response?.data?.code == "resource_already_created"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: e?.response?.data?.message,
                        type: "error",
                    })
                )
            }
            if (e?.response?.data?.code == "missing_name"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Insira um nome para permissão",
                        type: "error",
                    })
                )
            }
            if(e?.response?.data?.code == "resource_not_found"){
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: e?.response?.data?.message,
                        type: "error",
                    })
                )
            }
            console.log(e)
        }
        
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Permissões"
                        breadcrumbItem="Permissão"
                    />

                    <Formik
                        initialValues={pageData.permission}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            Dados da Permissão
                                        </CardTitle>
                                        <Row>
                                            <Col sm="3">
                                                <FormGroup>
                                                    <Label>
                                                        Nome
                                                    </Label>
                                                    <FastField
                                                        name="name"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values.name
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>
                                                        Descrição
                                                    </Label>
                                                    <FastField
                                                        name="description"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values.description
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3">
                                                <FormGroup>
                                                    <Label>
                                                        Status
                                                    </Label>
                                                    <FastField
                                                        placeholder="Selecione..."
                                                        name="status"
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                        options={
                                                            optionsStatus
                                                        }
                                                        defaultValue={
                                                            values.status
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div align="end">
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="mr-2"
                                                    >
                                                        Salvar
                                                    </Button>
                                                    <Link
                                                        to="/permissoes"
                                                        className="btn btn-danger"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </div>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        )}


                    </Formik>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegistrationPermissions