import React, { useState, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
    loadDataEditform,
    loadDataAddform,
    create,
    update,
} from "services/vitta-core/user"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

const RegistrationUsers = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({})
    const [selectAccessRoles, setSelectAccessRoles] = useState(null)
    const [selectUnits, setSelectUnits] = useState(null)

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                let data = await loadDataEditform(id)
                setPageData({
                    user: data.user,
                    units: data.units,
                    access_roles: data.access_roles,
                })
                let access_roles_values = []
                data.user.access_roles.forEach(element => {
                    access_roles_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })
                
                let units_values = []
                data.user.units.forEach(element => {
                    units_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })
                setSelectAccessRoles(access_roles_values)
                setSelectUnits(units_values)
            } catch (e) {
                console.log(e)
            }
        }

        const loadDataAddPage = async () => {
            try {
                let data = await loadDataAddform()
                setPageData({
                    units: data.units,
                    access_roles: data.access_roles,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (id) loadDataEditPage()
        else loadDataAddPage()
    }, [])

    const access_roles = pageData.access_roles
    const accessRolesOptions = useMemo(() => {
        if (!access_roles) return []

        let options_list = []
        access_roles.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [access_roles])

    const units = pageData.units
    const unitsOptions = useMemo(() => {
        if (!units) return []

        let options_list = []
        units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [units])

    const handleSelectAccessRoles = selectvalues => {
        setSelectAccessRoles(selectvalues)
    }

    const handleSelectUnits = selectvalues => {
        setSelectUnits(selectvalues)
    }

    const handleSubmit = async (event, formData) => {
        formData.access_roles = []
        formData.units = []

        if (selectAccessRoles && selectAccessRoles.length) {
            selectAccessRoles.forEach(element => {
                formData.access_roles.push(element.value)
            })
        }

        if (selectUnits && selectUnits.length) {
            selectUnits.forEach(element => {
                formData.units.push(element.value)
            })
        }
        

        try {
            if (id) {
                await update(id, formData)
            } else {
                await create(formData)
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            history.push("/usuarios")
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Usuários"
                        breadcrumbItem="Cadastro de usuários"
                    />
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={handleSubmit}
                    >
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle> Dados gerais</CardTitle>
                                        <CardSubtitle className="mb-5">
                                            É necessário preencher todas as
                                            informações
                                        </CardSubtitle>

                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="Nome"
                                                    id="nome"
                                                    name="name"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                    value={pageData?.user?.name}
                                                    errorMessage=" Preencha o campo Nome ."
                                                />
                                            </Col>

                                            <Col sm="6">
                                                <AvField
                                                    label="E-mail"
                                                    id="email"
                                                    name="email"
                                                    className={"form-control" + (pageData?.user ? " bg-light" : "")}
                                                    type="e-mail"
                                                    required
                                                    disabled={pageData?.user ? true : false}
                                                    readOnly={pageData?.user ? true : false}
                                                    value={
                                                        pageData?.user?.email
                                                    }
                                                    errorMessage=" Preencha o campo E-mail ."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="Senha"
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                    type="password"
                                                    value={
                                                        pageData?.user?.password?pageData?.user?.password :""
                                                    }
                                                    autocomplete="off"
                                                    errorMessage=" Preencha o campo Senha ."
                                                    required={id ? false : true}
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <AvField
                                                    name="status"
                                                    type="select"
                                                    id="select1"
                                                    label="Status"
                                                    required
                                                    value={
                                                        pageData?.user?.status
                                                    }
                                                    errorMessage=" Preencha o campo Status ."
                                                >
                                                    <option>
                                                        Selecionar
                                                    </option>
                                                    <option value="1">
                                                        Ativo
                                                    </option>
                                                    <option value="0">
                                                        Inativo
                                                    </option>
                                                </AvField>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <Label>Perfil de acesso</Label>
                                                <Select
                                                    placeholder="Selecione..."
                                                    name="access_roles"
                                                    isMulti={true}
                                                    onChange={data => {
                                                        handleSelectAccessRoles(
                                                            data
                                                        )
                                                    }}
                                                    value={selectAccessRoles}
                                                    options={accessRolesOptions}
                                                    classNamePrefix="select2-selection"
                                                    required
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        Unidades
                                                    </Label>
                                                    <Select
                                                        name="units"
                                                        placeholder="Selecione..."
                                                        isMulti={true}
                                                        onChange={data => {
                                                            handleSelectUnits(
                                                                data
                                                            )
                                                        }}
                                                        value={selectUnits}
                                                        options={unitsOptions}
                                                        classNamePrefix="select2-selection"
                                                        required={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div align="end">
                                            <Button
                                                color="primary"
                                                type="submit"
                                            >
                                                Salvar
                                            </Button>
                                            <Link
                                                to="/usuarios"
                                                className="btn btn-danger ml-1"
                                            >
                                                Cancelar
                                            </Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </AvForm>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegistrationUsers
