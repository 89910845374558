export default {
    DISPONIVEL: 1,
    BLOQUEADO: 2,
    AGENDADO: 3,
    CONFIRMADO: 4,
    DESMARCADO: 5,
    CANCELADO: 6,
    FALTOU: 7,
    EM_ESPERA: 8,
    EM_ATENDIMENTO: 9,
    CONCLUIDO: 10,
    COMPARECEU: 11,
    PAGO: 12,
    AGUARDANDO_RECEPCAO:  13,
    AGUARDANDO_CAIXA: 14,
    AGUARDANDO_LABORATORIO: 15,
    FALTOU_CONFIRMADO: 16
}