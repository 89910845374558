import { SET_CASH_CONTROL, RESET_CASH_CONTROL } from "./actionTypes";

export const setCashControl = (cashControl) => ({
  type: SET_CASH_CONTROL,
  payload: cashControl,
});

export const resetCashControl = () => ({
  type: RESET_CASH_CONTROL,
});
