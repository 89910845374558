import request from './config/request';

const search = (filter, page = 1, size = 10) => {

    let query = "?page=" + page + "&size=" + size

    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id
    if (filter.item_id) query += "&item_id=" + filter.item_id
    query += "&execution_mode=" + filter.execution_mode
    query += "&comission_type=" + filter.comission_type
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/item-exec-comissions` +
        query,
        vtAuth: true
    })
}

const searchReportItemExecComission = (filter) => {

    let query = "?page=" + "" + "&size=" + ""

    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id
    if (filter.item_id) query += "&item_id=" + filter.item_id

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/item-exec-comissions/report` +
        query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

const create = async data => {

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/item-exec-comissions`,
        data: data,
        vtAuth: true,
    })
}
const update = async (id, data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/item-exec-comissions/${id}`,
        data: data,
        vtAuth: true,
    })
}

const deleteItemExecComission = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/item-exec-comissions/${id}`,
        vtAuth: true
    })
}

export { search, create, update,deleteItemExecComission, searchReportItemExecComission }
