import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Button, Input } from "reactstrap"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
const ModalPrintChoice = ({
    show,
    data,
    bulkPrintLabels,
    getPrintBarCode,
    onClose,
}) => {
    if (!show) return <></>
    const history = useHistory()
    const [printTags, setPrintTags] = useState(false)
    const [redirectFormPrint, setRedirectFormPrint] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setRedirectFormPrint(true)
        setPrintTags(true)
    }, [show])

    const submit = async () => {
        try {
            if (printTags) {
                let dataPrint = getPrintBarCode(data?.recipients)
                await bulkPrintLabels(dataPrint)
            }
            if (redirectFormPrint) {
                history.push("/imprimir/pedidos-de-exame/" + data?.id)
            }
            onClose()
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Modal isOpen={show} centered={false} size="md">
            <div className="card-body">
                <Row>
                    <Col xs="12" sm="10">
                        <div className="modal-title " id="myModalLabel">
                            <h5>
                                <i
                                    className="fas fa-print text-info fa-lg mr-2"
                                    style={{
                                        position: "relative",
                                        top: "-1px",
                                    }}
                                ></i>
                                Impressão
                            </h5>
                        </div>
                    </Col>
                    <Col xs="12" sm="2">
                        <button
                            style={{
                                fontSize: "2rem",
                                position: "relative",
                                top: "-10px",
                            }}
                            type="button"
                            onClick={() => {
                                onClose()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-2">
                    <Col sm="10">
                        <strong className="text-info">
                            Deseja imprimir as etiquetas das amostras agora?
                            <div>
                                <small>
                                    Todas as etiquetas do pedido serão impressas
                                    de uma só vez.
                                </small>
                            </div>
                        </strong>
                    </Col>{" "}
                    <Col sm="2" align="center">
                        {" "}
                        <Input
                            name="print_tags"
                            type="checkbox"
                            checked={printTags}
                            onChange={e => {
                                setPrintTags(e?.target?.checked)
                            }}
                            style={{
                                position: "relative",
                                top: "-2px",
                            }}
                        />
                    </Col>
                </Row>
                <hr />
                <Row className="mt-2">
                    <Col sm="10">
                        <strong className="text-info">
                            Deseja imprimir o pedido de exame do paciente?
                        </strong>
                    </Col>{" "}
                    <Col sm="2" align="center">
                        {" "}
                        <Input
                            name="redirect_form_print"
                            type="checkbox"
                            style={{
                                position: "relative",
                                top: "-2px",
                            }}
                            checked={redirectFormPrint}
                            onChange={e => {
                                setRedirectFormPrint(e?.target?.checked)
                            }}
                        />
                    </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                    <Col sm="12" align="end">
                        <Button
                            color="info"
                            size="sm"
                            className="mr-1"
                            onClick={() => {
                                submit()
                            }}
                        >
                            Confirmar
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalPrintChoice
