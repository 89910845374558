import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.sales_order_id) query += "&sales_order_id=" + filter.sales_order_id;
    if (filter.start_created_at) query += "&start_created_at=" + filter.start_created_at;
    if (filter.end_created_at) query += "&end_created_at=" + filter.end_created_at;
    if(filter.order_by_created_at) query += "&order_by_created_at=" + filter.order_by_created_at;

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments` + query,
        vtAuth: true
    })
}

const create = async data => {

    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments`,
        data: data,
        vtAuth: true
    })
}

const update = async (id, data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments/${id}`,
        data: data,
        vtAuth: true
    })
}

const cancelPayment = async (id, cancelReason) => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments/${id}`,
        data: {
            cancel_reason: cancelReason,
        },
        vtAuth: true
    })
}

const paymentsReport = (filter, page = 1, size = 100) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.sales_order_id) query += "&sales_order_id=" + filter.sales_order_id;
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    if (filter.created_user_id) query += "&created_user_id=" + filter.created_user_id;
    if (filter.start_created_at) query += "&start_created_at=" + filter.start_created_at;
    if (filter.end_created_at) query += "&end_created_at=" + filter.end_created_at;

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments/payments-report` + query,
        vtAuth: true
    })
}

const paymentsReportSalesOrderPaymentServicerExport = (filter)=>{
    let query=""
    if(filter.unit_id) {
        if(query=="") {
            query += "?unit_id=" + filter.unit_id
        }else{
            query += "&unit_id=" + filter.unit_id
        }
    }
    if(filter.created_user_id){
        if(query==""){
            query += "?created_user_id=" + filter.created_user_id
        }else{
            query += "&created_user_id=" + filter.created_user_id
        }
    }
    if(filter.end_created_at){
        if(query==""){
            query += "?end_created_at=" + filter.end_created_at
        }else{
            query += "&end_created_at=" + filter.end_created_at
        }
    }
    if(filter.start_created_at){
        if(query==""){
            query += "?start_created_at=" + filter.start_created_at
        }else{
            query += "&start_created_at=" + filter.start_created_at
        }
    }

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-order-payments/payments-report-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
   
    

}

const checkTransactionUpdates = (id) => {

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order-payments/` + id + "/check-transaction-updates",
        vtAuth: true
    })
}

const changeTypeDiscountsApplied = (sales_order_id)=>
{
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/sales-order/change-type-discounts/${sales_order_id}`,
        vtAuth: true
    })
}

const clinicReceiptsExport = (filter)=>{
    let query=""
    if(filter.unit_id) {
        if(query=="") {
            query += "?unit_id=" + filter.unit_id
        }else{
            query += "&unit_id=" + filter.unit_id
        }
    }
    if(filter.created_user_id){
        if(query==""){
            query += "?created_user_id=" + filter.created_user_id
        }else{
            query += "&created_user_id=" + filter.created_user_id
        }
    }
    if(filter.end_created_at){
        if(query==""){
            query += "?end_created_at=" + filter.end_created_at
        }else{
            query += "&end_created_at=" + filter.end_created_at
        }
    }
    if(filter.start_created_at){
        if(query==""){
            query += "?start_created_at=" + filter.start_created_at
        }else{
            query += "&start_created_at=" + filter.start_created_at
        }
    }

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/sales-order-payments/clinic-receipts` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
   
    

}
export { search, create, cancelPayment, paymentsReport, paymentsReportSalesOrderPaymentServicerExport, update, checkTransactionUpdates,changeTypeDiscountsApplied,clinicReceiptsExport }
