import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import auth from "./auth/reducer"

import { connectRouter } from 'connected-react-router'
import scope from "./scope/reducer"
import cashControl from "./cash-control/reducer";

const rootReducer = (params) => combineReducers({
  router: connectRouter(params.router.history),
  Layout,
  auth,
  scope,
  cashControl
})

export default rootReducer
