import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Modal, CardTitle } from "reactstrap"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { Formik, Form } from "formik"

import { showSweetAlertDialog, showToast } from "store/layout/actions"
import BootstrapTable from "react-bootstrap-table-next"
import { useDispatch } from "react-redux"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import PricingListColumns from "./PricingListColumns"

const PricingList = ({ show, data, user, removePricing, onClose }) => {
    const [pricingList, setPricingList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const inputRefSearch = useRef()
    const dispatch = useDispatch()

    const defaultValues = {
        discount_item: "",
    }

    const confirmDeleteRecord = (data_form, index, value) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja remover este desconto?",
                type: "warning",
                onConfirmAction: () => {
                    deleteRecord(index, data_form)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const deleteRecord = async (index, data_form) => {
        let pricing = []
        for (let i = 0; i < data?.pricing?.length; i++) {
            let use = data?.pricing[i]
            if (i == index) {
                continue
            }
            pricing.push(use)
        }

        let newData = { ...data }
        newData.pricing = pricing
        newData.price = data.price + data_form.value / 100
        removePricing(newData)
        //onclose temporário, ver pq não limpando o state correto na segunda vez que exclui o desconto.
        onClose()
        clearState(newData)

        //manter modal aberto e não exibir o toast, se nao o usuário vai achar que já salvou a venda
    }
    const clearState = newData => {
        let list = { ...pricingList }
        list.data = newData.pricing
        setPricingList(list)
    }
    useEffect(() => {
        search()
    }, [show])

    const search = async (page = 1) => {
        let sizePerPage = 10
        // console.log("teste",data)
        //Clear Data Table
        setPricingList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        try {
            setPricingList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: page * sizePerPage,
                custom: true,
                data: data?.pricing,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    if(!show) return null;
    return (
        <Modal
            isOpen={show}
            centered={false}
            size={"xl"}
            // toggle={onCloseModal}
        >
            <Formik initialValues={defaultValues} enableReinitialize={true}>
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                {/* {console.log("teste",data)} */}
                                Item #{data?.item_id?.value}
                                {" - "}
                                {data?.item_id?.label}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <CardTitle>Valores Aplicados</CardTitle>

                            {!data?.pricing || data?.pricing?.length <= 0 ? (
                                <strong style={{ color: "red" }}>
                                    Sem dados até o momento.
                                </strong>
                            ) : (
                                <PaginationProvider
                                    pagination={paginationFactory(pricingList)}
                                >
                                    {({
                                        paginationProps,
                                        paginationTableProps,
                                    }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            data={pricingList.data || []}
                                            columns={PricingListColumns(
                                                user,
                                                confirmDeleteRecord,
                                                data?.showDeleteDiscount
                                            )}
                                            bootstrap4
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Form
                                                        onSubmit={e => {
                                                            e.preventDefault()
                                                            search()
                                                        }}
                                                    >
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                            </Col>
                                                            <Col sm="4">
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "thead-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default PricingList
