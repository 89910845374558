import { NavLink, NavItem, Nav, Card, CardBody } from "reactstrap"
import classnames from "classnames"

import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import authUtils from "utils/auth"

const Menu = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({})
    const [hasPermission, setHasPermission] = useState(false)
    const userAuth = useSelector(state => state.auth.access?.user)

    useEffect(() => {
        if (
            authUtils.hasPermission(
                userAuth,
                "view_medical_attendance_medical_request"
            )
        )
            setHasPermission(true)
    }, [])

    return (
        <Card className="h-100 shadow-sm  bg-white rounded">
            <CardBody>
                <Nav tab className="nav-tabs-custom flex-column ">
                    <span className="menu-title" align="start">
                        DADOS DO PACIENTE
                    </span>

                    <NavItem>
                        <NavLink
                            style={{
                                cursor: "pointer",
                            }}
                            className={classnames({
                                active:
                                    location.pathname ===
                                    "/pacientes/" + id + "/dados",
                            })}
                        >
                            {location.pathname ===
                            "/pacientes/" + id + "/dados" ? (
                                <i className="fas fa-user-check text-dark fa-2x"></i>
                            ) : (
                                <i className="fas fa-user-check text-dark fa-lg"></i>
                            )}

                            <Link
                                to={"/pacientes/" + id + "/dados"}
                                className="ml-2 text-dark"
                            >
                                Informações Pessoais
                            </Link>
                        </NavLink>
                    </NavItem>

                    {hasPermission &&
                    !authUtils.hasPermission(
                        userAuth,
                        "view_medical_attendance"
                    ) ? (
                        <>
                            <span className="menu-title">DADOS CLÍNICOS </span>

                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" + id + "/exames",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/exames" ? (
                                        <i className="fas fa-syringe text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-syringe text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={"/pacientes/" + id + "/exames"}
                                        className="ml-2 text-dark"
                                    >
                                        Solicitações Médicas
                                    </Link>
                                </NavLink>
                            </NavItem>
                        </>
                    ) : null}

                    <>
                        <span className="menu-title">DADOS CLÍNICOS </span>
                        {authUtils.hasPermission(
                            userAuth,
                            "view_medical_attendance"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" +
                                                id +
                                                "/atendimentos",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/atendimentos" ? (
                                        <i className="fas fa-user-md text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-user-md text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={
                                            "/pacientes/" + id + "/atendimentos"
                                        }
                                        className="ml-2 text-dark"
                                    >
                                        Atendimentos
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(
                            userAuth,
                            "view_medical_attendance"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" + id + "/atestados",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/atestados" ? (
                                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-file-medical-alt text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={"/pacientes/" + id + "/atestados"}
                                        className="ml-2 text-dark"
                                    >
                                        Atestados
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(
                            userAuth,
                            "view_prescriptions"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" + id + "/prescricoes",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/prescricoes" ? (
                                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-file-medical-alt text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={"/pacientes/" + id + "/prescricoes"}
                                        className="ml-2 text-dark"
                                    >
                                        Prescrições
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(
                            userAuth,
                            "view_medical_attendance"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" + id + "/laudos",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/laudos" ? (
                                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-file-medical-alt text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={"/pacientes/" + id + "/laudos"}
                                        className="ml-2 text-dark"
                                    >
                                        Laudos
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(
                            userAuth,
                            "view_medical_requests"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" + id + "/exames",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/exames" ? (
                                        <i className="fas fa-syringe text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-syringe text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={"/pacientes/" + id + "/exames"}
                                        className="ml-2 text-dark"
                                    >
                                        Solicitações Médicas
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}

                        {authUtils.hasPermission(
                            userAuth,
                            "add_exams_orders"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" +
                                                id +
                                                "/pedidos-exame",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/pedidos-exame" ? (
                                        <i className="fas fa-syringe text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-syringe text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={
                                            "/pacientes/" +
                                            id +
                                            "/pedidos-exame"
                                        }
                                        className="ml-2 text-dark"
                                    >
                                        Resultados de Exames
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(userAuth, "view_referrals") ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" +
                                                id +
                                                "/encaminhamentos",
                                    })}
                                >
                                    {location.pathname ===
                                    "/pacientes/" + id + "/encaminhamentos" ? (
                                        <i className="fas fa-comment-medical text-dark fa-2x"></i>
                                    ) : (
                                        <i className="fas fa-comment-medical text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={
                                            "/pacientes/" +
                                            id +
                                            "/encaminhamentos"
                                        }
                                        className="ml-2 text-dark"
                                    >
                                        Encaminhamentos
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {authUtils.hasPermission(
                            userAuth,
                            "view_medical_attendance"
                        ) ? (
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active:
                                            location.pathname ===
                                            "/pacientes/" +
                                                id +
                                                "/exportacao-prontuario",
                                    })}
                                >
                                    {location.pathname ==
                                    "/pacientes/" +
                                        id +
                                        "/exportacao-prontuario" ? (
                                        <i className="fas fa-hospital-user text-dark fa-lg"></i>
                                    ) : (
                                        <i className="fas fa-hospital-user text-dark fa-lg"></i>
                                    )}

                                    <Link
                                        to={
                                            "/pacientes/" +
                                            id +
                                            "/exportacao-prontuario"
                                        }
                                        target="_blank"
                                        className="ml-2 text-dark"
                                    >
                                        Exportação de prontuário
                                    </Link>
                                </NavLink>
                            </NavItem>
                        ) : null}
                    </>
                </Nav>
            </CardBody>
        </Card>
    )
}

export default Menu
