import request from './config/request';

const search = (filter,page=1,size=10) => {
    let query = "?page=" + page + "&size=" + size
   
    query += "&entity_type=" + filter.entity_type
    query += "&integration=" + filter.integration

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/integration-failures`+
        query,
        vtAuth: true
    })
}

export { search }
