import { Row, Col, Container } from "reactstrap"
import { Switch, Route, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import "assets/css/vitta.css"
import Header from "pages/Patients/Detail/Header"
import Menu from "pages/Patients/Detail/Menu"
import PersonalData from "pages/Patients/Detail/pages/PersonalData"
import TimeLineAttendances from "./pages/TimeLineAttendances"
import GeneralAttendances from "./pages/GeneralAttendance/GeneralAttendances"
import CertificateAttendance from "./pages/CertificateAttendance/index"
import PrescriptionAttendance from "./pages/PrescriptionAttendance/index"
import ExamAttendance from "pages/Patients/Detail/pages/ExamAttendance/index"
import ExamOrder from "pages/Patients/Detail/pages/ExamOrder/index"
import ReportAttendance from "pages/Patients/Detail/pages/ReportAttendance/index"
import ReferralAttendance from "pages/Patients/Detail/pages/ReferralAttendance/index"
import { loadDataEditform } from "services/vitta-core/patient"
import { findUnit } from "services/vitta-core/unit"
import { showSweetAlertDialog } from "store/layout/actions"
const Index = () => {
    const [activeTab, setactiveTab] = useState("1")

    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }

    const [pageData, setPageData] = useState({})
    const [patientData, setPatientData] = useState(null)
    const [unitData, setUnitData] = useState(null)
    const [controlState, setControlState] = useState(false)
    const [appointmentId, setAppointmentId] = useState(null)
    const [attendanceData, setAttendanceData] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()

    const routeParams = useParams()
    const { id } = useParams()

    const pathAdd = "/pacientes/" + id + "/atendimentos/adicionar"
    const Scope = useSelector(state => state)

    useEffect(() => {
        let search = location.search
        const params = new URLSearchParams(search)
        const appointment_id = params.get("appointment_id")
        if (appointment_id) handleAppointment(appointment_id)
        if (id) patientFind()
        if (Scope?.unit?.id) unitFind()
    }, [id])

    let count = 0
    let countUnit = 0

    const handleAppointment = appointment_id => {
        setAppointmentId(appointment_id)
    }
    const patientFind = async () => {
        try {
            setControlState(true)

            let result = await loadDataEditform(id)

            setPatientData(result?.patient)
            setControlState(false)
        } catch (e) {
            console.log(e)
            count = count + 1
            if (!navigator.onLine) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ocorreu algum problema!",
                        text:
                            "Verifique sua conexão de internet e tente novamente...",
                        type: "error",
                        showConfirm: true,
                    })
                )
            } else if (count < 3) {
                patientFind()
            } else {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ocorreu algum problema, contate o suporte!",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        }
    }

    const unitFind = async () => {
        try {
            let result = await findUnit(Scope?.unit?.id)

            setUnitData(result)
        } catch (e) {
            console.log(e)
            countUnit = countUnit + 1
            if (!navigator.onLine) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ocorreu algum problema!",
                        text:
                            "Verifique sua conexão de internet e tente novamente...",
                        type: "error",
                        showConfirm: true,
                    })
                )
            } else if (countUnit < 3) {
                patientFind()
            } else {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ocorreu algum problema, contate o suporte!",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        }
    }

    const openedAttendance = attendance_data => {
        setAttendanceData(attendance_data)
    }

    return (
        <React.Fragment>
            <div className="page-content notranslate " translate="no">
                <Container fluid={true}>
                    <Row>
                        <Col xs="12">
                            <Row>
                                <Col sm="12">
                                    <Header
                                        patientData={patientData}
                                        controlState={controlState}
                                        attendanceData={
                                            location?.pathname?.match(
                                                /atendimentos\/.{1,}/
                                            ) != null
                                                ? attendanceData
                                                : null
                                        }
                                    ></Header>
                                </Col>
                            </Row>
                            <Row>
                                {location.pathname.match(
                                    /atendimentos\/.{1,}/
                                ) != null ? (
                                    console.log("tira sidebar")
                                ) : (
                                    <Col sm="3" style={{ minHeight: "600px" }}>
                                        <Menu></Menu>
                                    </Col>
                                )}

                                <Switch>
                                    <Route path="/pacientes/:patient_id/atendimentos/:attendance_id">
                                        <Col sm="12">
                                            <GeneralAttendances
                                                patientData={patientData}
                                                openedAttendance={
                                                    openedAttendance
                                                }
                                            />
                                        </Col>
                                    </Route>
                                    {/* <Route path="/pacientes/:patient_id/atendimentos/:attendance_id">
                                        <Col sm="12">
                                            <GeneralAttendances
                                                path="/pacientes/:patient_id/atendimentos/:attendance_id"
                                                patientData={patientData}
                                            />
                                        </Col>
                                    </Route> */}

                                    <Route path="/pacientes/:patient_id/atendimentos">
                                        <Col sm="9">
                                            <TimeLineAttendances
                                                appointment_id={appointmentId}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:id/dados">
                                        <Col sm="9">
                                            <PersonalData
                                                patientData={patientData}
                                                controlState={controlState}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/atestados">
                                        <Col sm="9">
                                            <CertificateAttendance
                                                path="/pacientes/:patient_id/atestados"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/prescricoes">
                                        <Col sm="9">
                                            <PrescriptionAttendance
                                                path="/pacientes/:patient_id/prescricoes"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/laudos">
                                        <Col sm="9">
                                            <ReportAttendance
                                                path="/pacientes/:patient_id/laudos"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/exames">
                                        <Col sm="9">
                                            <ExamAttendance
                                                path="/pacientes/:patient_id/exames"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/pedidos-exame">
                                        <Col sm="9">
                                            <ExamOrder
                                                path="/pacientes/:patient_id/pedidos-exame"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                    <Route path="/pacientes/:patient_id/encaminhamentos">
                                        <Col sm="9">
                                            <ReferralAttendance
                                                path="/pacientes/:patient_id/encaminhamentos"
                                                patientData={patientData}
                                                unitData={unitData}
                                            />
                                        </Col>
                                    </Route>
                                </Switch>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index
