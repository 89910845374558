import React from "react"
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledTooltip,
    Row,
    Col,
} from "reactstrap"
import RenderPartnerStatus from "./RenderPartnerStatus"
import EXAMS_ORDER_STATUS from "utils/consts/exams-orders-status"
const ShowExamRequests = ({
    values,
    setControlModalAdditionalData,
    confirmationCancelExamOrderItem,
    handleDownloadExamOrderItem,
    printLabel,
    setControlModalInfoPartnerObs,
    setControlModalInfoPartnerStatus,
    hasPermissionToCancelExamsOders,
}) => {
    return (
        <div className="mt-5">
            <table class="table">
                <thead className="">
                    <tr>
                        <th scope="col">
                            Situação{" "}
                            <i
                                id="partnersituationobs"
                                className="fas fa-info ml-1"
                                style={{
                                    cursor: "pointer",
                                    color: "#0195d5",
                                    fontSize: "15px",
                                }}
                                onClick={() => {
                                    setControlModalInfoPartnerStatus({
                                        show: true,
                                    })
                                }}
                            >
                                <UncontrolledTooltip
                                    placement="top"
                                    target={"partnersituationobs"}
                                    style={{
                                        backgroundColor: "#0894d4",
                                    }}
                                >
                                    Clique para abrir
                                </UncontrolledTooltip>
                            </i>
                        </th>
                        <th scope="col">Exames Vittá</th>
                        <th scope="col">Exames Laboratório</th>
                        <th scope="col">Material</th>
                        {/* <th scope="col">Material Laboratório</th> */}
                        <th scope="col">Conservante</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {values?.items?.length > 0
                        ? values?.items?.map((item, index) => (
                              <>
                                  <tr>
                                      <th>
                                          <Row>
                                              <RenderPartnerStatus
                                                  item={item}
                                                  partner={
                                                      values?.partner_id?.value
                                                  }
                                                  partner_status_id={
                                                      item?.partner_status
                                                          ?.partner_status_id
                                                  }
                                                  partner_status_name={
                                                      item?.partner_status
                                                          ?.partner_status_name
                                                  }
                                                  index={index}
                                              />

                                              <Col sm="3">
                                                  {" "}
                                                  {item?.partner_obs ? (
                                                      <i
                                                          className="far fa-eye"
                                                          id={
                                                              "partnerobs" +
                                                              index
                                                          }
                                                          style={{
                                                              position:
                                                                  "relative",
                                                              top: "-1px",
                                                              cursor: "pointer",
                                                              fontSize: "22px",
                                                              position:
                                                                  "relative",

                                                              color: "#0195d5",
                                                          }}
                                                          onClick={() => {
                                                              setControlModalInfoPartnerObs(
                                                                  {
                                                                      show: true,
                                                                      data:
                                                                          item?.partner_obs,
                                                                      type:
                                                                          "exams_order_item",
                                                                  }
                                                              )
                                                          }}
                                                      >
                                                          <UncontrolledTooltip
                                                              placement="top"
                                                              target={
                                                                  "partnerobs" +
                                                                  index
                                                              }
                                                              style={{
                                                                  backgroundColor:
                                                                      "#0894d4",
                                                              }}
                                                          >
                                                              Observações até o
                                                              momento
                                                          </UncontrolledTooltip>
                                                      </i>
                                                  ) : (
                                                      ""
                                                  )}
                                              </Col>
                                          </Row>
                                      </th>
                                      <th>{item?.item_id?.label}</th>
                                      <td>{item?.partner_item_code?.label}</td>
                                      <td>{item?.exams_material_id?.label}</td>
                                      {/* <td>
                                          {item?.partner_exam_material_code}
                                      </td> */}
                                      <td>
                                          {item?.exams_preservative_id?.label}
                                      </td>
                                      <td>
                                          <UncontrolledDropdown>
                                              <DropdownToggle
                                                  tag="i"
                                                  dir
                                                  style={{
                                                      cursor: "pointer",
                                                      marginLeft: "10px",
                                                  }}
                                              >
                                                  <i
                                                      className="bx bx-list-ul"
                                                      style={{
                                                          fontSize: "22px",
                                                      }}
                                                  />
                                              </DropdownToggle>
                                           
                                              <DropdownMenu right>
                                                  {item?.additional_data
                                                      ?.additionalDataItems
                                                      ?.length > 0 ? (
                                                      <DropdownItem
                                                          onClick={() => {
                                                              let additional_data = {
                                                                  ...item.additional_data,
                                                              }
                                                              let new_items = []
                                                              for (
                                                                  let i = 0;
                                                                  i <
                                                                  additional_data
                                                                      ?.additionalDataItems
                                                                      ?.length;
                                                                  i++
                                                              ) {
                                                                  let use =
                                                                      additional_data
                                                                          .additionalDataItems[
                                                                          i
                                                                      ]
                                                                  let obj = {
                                                                      label:
                                                                          use?.description,
                                                                      model:
                                                                          use?.type,
                                                                      name:
                                                                          use?.name,
                                                                      required: true,
                                                                      informed: {
                                                                          name:
                                                                              use?.name,
                                                                          value:
                                                                              use?.value,
                                                                      },
                                                                  }
                                                                  if (
                                                                      use?.type ==
                                                                      "select_options"
                                                                  ) {
                                                                      obj.informed.objectData = {
                                                                          label:
                                                                              use?.select_label_value,
                                                                          value:
                                                                              use?.value,
                                                                      }
                                                                  }
                                                                  new_items.push(
                                                                      obj
                                                                  )
                                                              }
                                                              additional_data.additionalDataItems = new_items
                                                              setControlModalAdditionalData(
                                                                  {
                                                                      show: true,
                                                                      data: additional_data,
                                                                      disabled: true,
                                                                  }
                                                              )
                                                          }}
                                                      >
                                                          <i className="fas fa-clipboard-list text-info mr-1" />
                                                          Informações
                                                          Complementares
                                                      </DropdownItem>
                                                  ) : (
                                                      ""
                                                  )}
                                                  {hasPermissionToCancelExamsOders ? (
                                                      <DropdownItem
                                                          disabled={
                                                              item?.status ==
                                                              EXAMS_ORDER_STATUS.IN_PROGRESS
                                                                  ? false
                                                                  : true
                                                          }
                                                          onClick={() => {
                                                              confirmationCancelExamOrderItem(
                                                                  item
                                                              )
                                                          }}
                                                      >
                                                          <i className="fas fa-trash text-danger mr-1" />
                                                          Cancelar Exame
                                                      </DropdownItem>
                                                  ) : (
                                                      ""
                                                  )}

                                                  {/* <DropdownItem
                                                      style={{
                                                          cursor: "pointer",
                                                      }}
                                                      disabled={
                                                          item?.status ==
                                                              EXAMS_ORDER_STATUS.IN_PROGRESS &&
                                                          values?.partner_order_id
                                                              ? false
                                                              : true
                                                      }
                                                      onClick={async () => {
                                                          try {
                                                              await printLabel(
                                                                  item?.print_bar_code_command
                                                              )
                                                          } catch (e) {
                                                              console.log(e)
                                                          }
                                                      }}
                                                  >
                                                      <i className="fas fa-file mr-1"></i>
                                                      Imprimir etiqueta
                                                  </DropdownItem> */}
                                                  <DropdownItem
                                                      disabled={
                                                          item?.status ==
                                                          EXAMS_ORDER_STATUS.CONCLUDED
                                                              ? false
                                                              : true
                                                      }
                                                      onClick={() => {
                                                          handleDownloadExamOrderItem(
                                                              item
                                                          )
                                                      }}
                                                  >
                                                      <i className="fas fa-file mr-1"></i>
                                                      Ver Resultado
                                                  </DropdownItem>
                                              </DropdownMenu>
                                          </UncontrolledDropdown>
                                      </td>
                                  </tr>
                              </>
                          ))
                        : ""}
                </tbody>
            </table>
        </div>
    )
}

export default ShowExamRequests
