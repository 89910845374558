import { call, put, takeEvery } from "redux-saga/effects";
import { SET_CASH_CONTROL } from "./actionTypes";

function* sagaSetCashControl(action) {
  try {

    localStorage.setItem("cashControl", JSON.stringify(action.payload));
  } catch (error) {
    console.error("Erro ao salvar o cash control no localStorage", error);
  }
}

function* cashControlSaga() {
  yield takeEvery(SET_CASH_CONTROL, sagaSetCashControl);
}

export default cashControlSaga;
