const BANK_CODES_TYPES = [
    { "code": "001", "name": "BCO DO BRASIL S.A.", "ispb": "00000000", "description": "Banco do Brasil S.A." },
    { "code": "070", "name": "BRB - BCO DE BRASILIA S.A.", "ispb": "00000208", "description": "BRB - BANCO DE BRASILIA S.A." },
    { "code": "430", "name": "CCR SEARA", "ispb": "00204963", "description": "COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA" },
    { "code": "272", "name": "AGK CC S.A.", "ispb": "00250699", "description": "AGK CORRETORA DE CAMBIO S.A." },
    { "code": "136", "name": "CONF NAC COOP CENTRAIS UNICRED", "ispb": "00315557", "description": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI" },
    { "code": "407", "name": "ÍNDIGO INVESTIMENTOS DTVM LTDA.", "ispb": "00329598", "description": "ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "104", "name": "CAIXA ECONOMICA FEDERAL", "ispb": "00360305", "description": "CAIXA ECONOMICA FEDERAL" },
    { "code": "077", "name": "BANCO INTER", "ispb": "00416968", "description": "Banco Inter S.A." },
    { "code": "423", "name": "COLUNA S.A. DTVM", "ispb": "00460065", "description": "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS" },
    { "code": "741", "name": "BCO RIBEIRAO PRETO S.A.", "ispb": "00517645", "description": "BANCO RIBEIRAO PRETO S.A." },
    { "code": "330", "name": "BANCO BARI S.A.", "ispb": "00556603", "description": "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A." },
    { "code": "739", "name": "BCO CETELEM S.A.", "ispb": "00558456", "description": "Banco Cetelem S.A." },
    { "code": "743", "name": "BANCO SEMEAR", "ispb": "00795423", "description": "Banco Semear S.A." },
    { "code": "100", "name": "PLANNER CV S.A.", "ispb": "00806535", "description": "Planner Corretora de Valores S.A." },
    { "code": "096", "name": "BCO B3 S.A.", "ispb": "00997185", "description": "Banco B3 S.A." },
    { "code": "747", "name": "BCO RABOBANK INTL BRASIL S.A.", "ispb": "01023570", "description": "Banco Rabobank International Brasil S.A." },
    { "code": "362", "name": "CIELO IP S.A.", "ispb": "01027058", "description": "CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO" },
    { "code": "322", "name": "CCR DE ABELARDO LUZ", "ispb": "01073966", "description": "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz" },
    { "code": "748", "name": "BCO COOPERATIVO SICREDI S.A.", "ispb": "01181521", "description": "BANCO COOPERATIVO SICREDI S.A." },
    { "code": "350", "name": "CREHNOR LARANJEIRAS", "ispb": "01330387", "description": "COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE" },
    { "code": "752", "name": "BCO BNP PARIBAS BRASIL S A", "ispb": "01522368", "description": "Banco BNP Paribas Brasil S.A." },
    { "code": "379", "name": "CECM COOPERFORTE", "ispb": "01658426", "description": "COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU" },
    { "code": "399", "name": "KIRTON BANK", "ispb": "01701201", "description": "Kirton Bank S.A. - Banco Múltiplo" },
    { "code": "108", "name": "PORTOCRED S.A. - CFI", "ispb": "01800019", "description": "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "378", "name": "BCO BRASILEIRO DE CRÉDITO S.A.", "ispb": "01852137", "description": "BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA" },
    { "code": "413", "name": "BCO BV S.A.", "ispb": "01858774", "description": "BANCO BV S.A." },
    { "code": "756", "name": "BANCO SICOOB S.A.", "ispb": "02038232", "description": "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB" },
    { "code": "360", "name": "TRINUS CAPITAL DTVM", "ispb": "02276653", "description": "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "757", "name": "BCO KEB HANA DO BRASIL S.A.", "ispb": "02318507", "description": "BANCO KEB HANA DO BRASIL S.A." },
    { "code": "102", "name": "XP INVESTIMENTOS CCTVM S/A", "ispb": "02332886", "description": "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A" },
    { "code": "084", "name": "UNIPRIME DO BRASIL - COOP", "ispb": "02398976", "description": "UNIPRIME DO BRASIL - COOPERATIVA DE CRÉDITO" },
    { "code": "180", "name": "CM CAPITAL MARKETS CCTVM LTDA", "ispb": "02685483", "description": "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "066", "name": "BCO MORGAN STANLEY S.A.", "ispb": "02801938", "description": "BANCO MORGAN STANLEY S.A." },
    { "code": "015", "name": "UBS BRASIL CCTVM S.A.", "ispb": "02819125", "description": "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A." },
    { "code": "143", "name": "TREVISO CC S.A.", "ispb": "02992317", "description": "Treviso Corretora de Câmbio S.A." },
    { "code": "062", "name": "HIPERCARD BM S.A.", "ispb": "03012230", "description": "Hipercard Banco Múltiplo S.A." },
    { "code": "074", "name": "BCO. J.SAFRA S.A.", "ispb": "03017677", "description": "Banco J. Safra S.A." },
    { "code": "099", "name": "UNIPRIME COOPCENTRAL LTDA.", "ispb": "03046391", "description": "UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO" },
    { "code": "387", "name": "BCO TOYOTA DO BRASIL S.A.", "ispb": "03215790", "description": "Banco Toyota do Brasil S.A." },
    { "code": "326", "name": "PARATI - CFI S.A.", "ispb": "03311443", "description": "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
    { "code": "025", "name": "BCO ALFA S.A.", "ispb": "03323840", "description": "Banco Alfa S.A." },
    { "code": "075", "name": "BCO ABN AMRO S.A.", "ispb": "03532415", "description": "Banco ABN Amro S.A." },
    { "code": "040", "name": "BCO CARGILL S.A.", "ispb": "03609817", "description": "Banco Cargill S.A." },
    { "code": "307", "name": "TERRA INVESTIMENTOS DTVM", "ispb": "03751794", "description": "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda." },
    { "code": "385", "name": "CECM DOS TRAB.PORT. DA G.VITOR", "ispb": "03844699", "description": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V" },
    { "code": "425", "name": "SOCINAL S.A. CFI", "ispb": "03881423", "description": "SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "190", "name": "SERVICOOP", "ispb": "03973814", "description": "SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAI" },
    { "code": "296", "name": "OZ CORRETORA DE CÂMBIO S.A.", "ispb": "04062902", "description": "OZ CORRETORA DE CÂMBIO S.A." },
    { "code": "063", "name": "BANCO BRADESCARD", "ispb": "04184779", "description": "Banco Bradescard S.A." },
    { "code": "191", "name": "NOVA FUTURA CTVM LTDA.", "ispb": "04257795", "description": "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda." },
    { "code": "382", "name": "FIDUCIA SCMEPP LTDA", "ispb": "04307598", "description": "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L" },
    { "code": "064", "name": "GOLDMAN SACHS DO BRASIL BM S.A", "ispb": "04332281", "description": "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
    { "code": "459", "name": "CCM SERV. PÚBLICOS SP", "ispb": "04546162", "description": "COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE" },
    { "code": "097", "name": "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.", "ispb": "04632856", "description": "Credisis - Central de Cooperativas de Crédito Ltda." },
    { "code": "016", "name": "CCM DESP TRÂNS SC E RS", "ispb": "04715685", "description": "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI" },
    { "code": "299", "name": "BCO SOROCRED S.A. - BM", "ispb": "04814563", "description": "BANCO SOROCRED S.A. - BANCO MÚLTIPLO" },
    { "code": "471", "name": "CECM SERV PUBL PINHÃO", "ispb": "04831810", "description": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRES" },
    { "code": "468", "name": "PORTOSEG S.A. CFI", "ispb": "04862600", "description": "PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "012", "name": "BANCO INBURSA", "ispb": "04866275", "description": "Banco Inbursa S.A." },
    { "code": "003", "name": "BCO DA AMAZONIA S.A.", "ispb": "04902979", "description": "BANCO DA AMAZONIA S.A." },
    { "code": "060", "name": "CONFIDENCE CC S.A.", "ispb": "04913129", "description": "Confidence Corretora de Câmbio S.A." },
    { "code": "037", "name": "BCO DO EST. DO PA S.A.", "ispb": "04913711", "description": "Banco do Estado do Pará S.A." },
    { "code": "411", "name": "VIA CERTA FINANCIADORA S.A. - CFI", "ispb": "05192316", "description": "Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos" },
    { "code": "359", "name": "ZEMA CFI S/A", "ispb": "05351887", "description": "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A" },
    { "code": "159", "name": "CASA CREDITO S.A. SCM", "ispb": "05442029", "description": "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor" },
    { "code": "085", "name": "COOPCENTRAL AILOS", "ispb": "05463212", "description": "Cooperativa Central de Crédito - Ailos" },
    { "code": "400", "name": "COOP CREDITAG", "ispb": "05491616", "description": "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT" },
    { "code": "429", "name": "CREDIARE CFI S.A.", "ispb": "05676026", "description": "Crediare S.A. - Crédito, financiamento e investimento" },
    { "code": "410", "name": "PLANNER SOCIEDADE DE CRÉDITO DIRETO", "ispb": "05684234", "description": "PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "114", "name": "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO", "ispb": "05790149", "description": "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP" },
    { "code": "328", "name": "CECM FABRIC CALÇADOS SAPIRANGA", "ispb": "05841967", "description": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA" },
    { "code": "036", "name": "BCO BBI S.A.", "ispb": "06271464", "description": "Banco Bradesco BBI S.A." },
    { "code": "469", "name": "LIGA INVEST DTVM LTDA.", "ispb": "07138049", "description": "LIGA INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "394", "name": "BCO BRADESCO FINANC. S.A.", "ispb": "07207996", "description": "Banco Bradesco Financiamentos S.A." },
    { "code": "004", "name": "BCO DO NORDESTE DO BRASIL S.A.", "ispb": "07237373", "description": "Banco do Nordeste do Brasil S.A." },
    { "code": "458", "name": "HEDGE INVESTMENTS DTVM LTDA.", "ispb": "07253654", "description": "HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "320", "name": "BCO CCB BRASIL S.A.", "ispb": "07450604", "description": "China Construction Bank (Brasil) Banco Múltiplo S/A" },
    { "code": "189", "name": "HS FINANCEIRA", "ispb": "07512441", "description": "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS" },
    { "code": "105", "name": "LECCA CFI S.A.", "ispb": "07652226", "description": "Lecca Crédito, Financiamento e Investimento S/A" },
    { "code": "076", "name": "BCO KDB BRASIL S.A.", "ispb": "07656500", "description": "Banco KDB do Brasil S.A." },
    { "code": "082", "name": "BANCO TOPÁZIO S.A.", "ispb": "07679404", "description": "BANCO TOPÁZIO S.A." },
    { "code": "312", "name": "HSCM SCMEPP LTDA.", "ispb": "07693858", "description": "HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT" },
    { "code": "195", "name": "VALOR SCD S.A.", "ispb": "07799277", "description": "VALOR SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "286", "name": "CCR DE OURO", "ispb": "07853842", "description": "COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO" },
    { "code": "093", "name": "POLOCRED SCMEPP LTDA.", "ispb": "07945233", "description": "PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT" },
    { "code": "391", "name": "CCR DE IBIAM", "ispb": "08240446", "description": "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM" },
    { "code": "273", "name": "CCR DE SÃO MIGUEL DO OESTE", "ispb": "08253539", "description": "Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel" },
    { "code": "368", "name": "BCO CSF S.A.", "ispb": "08357240", "description": "Banco CSF S.A." },
    { "code": "290", "name": "PAGSEGURO INTERNET IP S.A.", "ispb": "08561701", "description": "PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "259", "name": "MONEYCORP BCO DE CÂMBIO S.A.", "ispb": "08609934", "description": "MONEYCORP BANCO DE CÂMBIO S.A." },
    { "code": "395", "name": "F D GOLD DTVM LTDA", "ispb": "08673569", "description": "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "364", "name": "GERENCIANET", "ispb": "09089356", "description": "GERENCIANET S.A." },
    { "code": "157", "name": "ICAP DO BRASIL CTVM LTDA.", "ispb": "09105360", "description": "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda." },
    { "code": "183", "name": "SOCRED SA - SCMEPP", "ispb": "09210106", "description": "SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P" },
    { "code": "014", "name": "STATE STREET BR S.A. BCO COMERCIAL", "ispb": "09274232", "description": "STATE STREET BRASIL S.A. - BANCO COMERCIAL" },
    { "code": "130", "name": "CARUANA SCFI", "ispb": "09313766", "description": "CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "358", "name": "MIDWAY S.A. - SCFI", "ispb": "09464032", "description": "MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "127", "name": "CODEPE CVC S.A.", "ispb": "09512542", "description": "Codepe Corretora de Valores e Câmbio S.A." },
    { "code": "079", "name": "PICPAY BANK - BANCO MÚLTIPLO S.A", "ispb": "09516419", "description": "PICPAY BANK - BANCO MÚLTIPLO S.A" },
    { "code": "340", "name": "SUPERDIGITAL I.P. S.A.", "ispb": "09554480", "description": "SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "081", "name": "BANCOSEGURO S.A.", "ispb": "10264663", "description": "BancoSeguro S.A." },
    { "code": "475", "name": "BCO YAMAHA MOTOR S.A.", "ispb": "10371492", "description": "Banco Yamaha Motor do Brasil S.A." },
    { "code": "133", "name": "CRESOL CONFEDERAÇÃO", "ispb": "10398952", "description": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E" },
    { "code": "323", "name": "MERCADO PAGO IP LTDA.", "ispb": "10573521", "description": "MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA." },
    { "code": "121", "name": "BCO AGIBANK S.A.", "ispb": "10664513", "description": "Banco Agibank S.A." },
    { "code": "083", "name": "BCO DA CHINA BRASIL S.A.", "ispb": "10690848", "description": "Banco da China Brasil S.A." },
    { "code": "138", "name": "GET MONEY CC LTDA", "ispb": "10853017", "description": "Get Money Corretora de Câmbio S.A." },
    { "code": "024", "name": "BCO BANDEPE S.A.", "ispb": "10866788", "description": "Banco Bandepe S.A." },
    { "code": "384", "name": "GLOBAL SCM LTDA", "ispb": "11165756", "description": "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO" },
    { "code": "426", "name": "BIORC FINANCEIRA - CFI S.A.", "ispb": "11285104", "description": "Biorc Financeira - Crédito, Financiamento e Investimento S.A." },
    { "code": "088", "name": "BANCO RANDON S.A.", "ispb": "11476673", "description": "BANCO RANDON S.A." },
    { "code": "319", "name": "OM DTVM LTDA", "ispb": "11495073", "description": "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "274", "name": "BMP SCMEPP LTDA", "ispb": "11581339", "description": "BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA." },
    { "code": "095", "name": "TRAVELEX BANCO DE CÂMBIO S.A.", "ispb": "11703662", "description": "Travelex Banco de Câmbio S.A." },
    { "code": "094", "name": "BANCO FINAXIS", "ispb": "11758741", "description": "Banco Finaxis S.A." },
    { "code": "478", "name": "GAZINCRED S.A. SCFI", "ispb": "11760553", "description": "GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "276", "name": "BCO SENFF S.A.", "ispb": "11970623", "description": "BANCO SENFF S.A." },
    { "code": "447", "name": "MIRAE ASSET CCTVM LTDA", "ispb": "12392983", "description": "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO" },
    { "code": "092", "name": "BRK S.A. CFI", "ispb": "12865507", "description": "BRK S.A. Crédito, Financiamento e Investimento" },
    { "code": "047", "name": "BCO DO EST. DE SE S.A.", "ispb": "13009717", "description": "Banco do Estado de Sergipe S.A." },
    { "code": "144", "name": "BEXS BCO DE CAMBIO S.A.", "ispb": "13059145", "description": "BEXS BANCO DE CÂMBIO S/A" },
    { "code": "332", "name": "ACESSO SOLUCOES PAGAMENTO SA", "ispb": "13140088", "description": "Acesso Soluções de Pagamento S.A." },
    { "code": "450", "name": "FITBANK IP", "ispb": "13203354", "description": "FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A." },
    { "code": "126", "name": "BR PARTNERS BI", "ispb": "13220493", "description": "BR Partners Banco de Investimento S.A." },
    { "code": "325", "name": "ÓRAMA DTVM S.A.", "ispb": "13293225", "description": "Órama Distribuidora de Títulos e Valores Mobiliários S.A." },
    { "code": "301", "name": "DOCK IP S.A.", "ispb": "13370835", "description": "DOCK INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "173", "name": "BRL TRUST DTVM SA", "ispb": "13486793", "description": "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A." },
    { "code": "331", "name": "FRAM CAPITAL DTVM S.A.", "ispb": "13673855", "description": "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A." },
    { "code": "119", "name": "BCO WESTERN UNION", "ispb": "13720915", "description": "Banco Western Union do Brasil S.A." },
    { "code": "396", "name": "HUB IP S.A.", "ispb": "13884775", "description": "HUB INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "309", "name": "CAMBIONET CC LTDA", "ispb": "14190547", "description": "CAMBIONET CORRETORA DE CÂMBIO LTDA." },
    { "code": "254", "name": "PARANA BCO S.A.", "ispb": "14388334", "description": "PARANÁ BANCO S.A." },
    { "code": "268", "name": "BARI CIA HIPOTECÁRIA", "ispb": "14511781", "description": "BARI COMPANHIA HIPOTECÁRIA" },
    { "code": "401", "name": "IUGU IP S.A.", "ispb": "15111975", "description": "IUGU INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "107", "name": "BCO BOCOM BBM S.A.", "ispb": "15114366", "description": "Banco Bocom BBM S.A." },
    { "code": "412", "name": "SOCIAL BANK S/A", "ispb": "15173776", "description": "SOCIAL BANK BANCO MÚLTIPLO S/A" },
    { "code": "124", "name": "BCO WOORI BANK DO BRASIL S.A.", "ispb": "15357060", "description": "Banco Woori Bank do Brasil S.A." },
    { "code": "149", "name": "FACTA S.A. CFI", "ispb": "15581638", "description": "Facta Financeira S.A. - Crédito Financiamento e Investimento" },
    { "code": "197", "name": "STONE IP S.A.", "ispb": "16501555", "description": "STONE INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "439", "name": "ID CTVM", "ispb": "16695922", "description": "ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "313", "name": "AMAZÔNIA CC LTDA.", "ispb": "16927221", "description": "AMAZÔNIA CORRETORA DE CÂMBIO LTDA." },
    { "code": "142", "name": "BROKER BRASIL CC LTDA.", "ispb": "16944141", "description": "Broker Brasil Corretora de Câmbio Ltda." },
    { "code": "529", "name": "PINBANK IP", "ispb": "17079937", "description": "PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "389", "name": "BCO MERCANTIL DO BRASIL S.A.", "ispb": "17184037", "description": "Banco Mercantil do Brasil S.A." },
    { "code": "184", "name": "BCO ITAÚ BBA S.A.", "ispb": "17298092", "description": "Banco Itaú BBA S.A." },
    { "code": "634", "name": "BCO TRIANGULO S.A.", "ispb": "17351180", "description": "BANCO TRIANGULO S.A." },
    { "code": "545", "name": "SENSO CCVM S.A.", "ispb": "17352220", "description": "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A" },
    { "code": "132", "name": "ICBC DO BRASIL BM S.A.", "ispb": "17453575", "description": "ICBC do Brasil Banco Múltiplo S.A." },
    { "code": "298", "name": "VIPS CC LTDA.", "ispb": "17772370", "description": "Vip's Corretora de Câmbio Ltda." },
    { "code": "377", "name": "BMS SCD S.A.", "ispb": "17826860", "description": "BMS SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "321", "name": "CREFAZ SCMEPP LTDA", "ispb": "18188384", "description": "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT" },
    { "code": "260", "name": "NU PAGAMENTOS - NUBANK", "ispb": "18236120", "description": "NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO" },
    { "code": "470", "name": "CDC SOCIEDADE DE CRÉDITO", "ispb": "18394228", "description": "CDC SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESADE PEQUENO PORTE LTDA." },
    { "code": "129", "name": "UBS BRASIL BI S.A.", "ispb": "18520834", "description": "UBS Brasil Banco de Investimento S.A." },
    { "code": "128", "name": "MS BANK S.A. BCO DE CÂMBIO", "ispb": "19307785", "description": "MS Bank S.A. Banco de Câmbio" },
    { "code": "416", "name": "LAMARA SCD S.A.", "ispb": "19324634", "description": "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "461", "name": "ASAAS IP S.A.", "ispb": "19540550", "description": "ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A." },
    { "code": "194", "name": "PARMETAL DTVM LTDA", "ispb": "20155248", "description": "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "383", "name": "EBANX IP LTDA.", "ispb": "21018182", "description": "EBANX INSTITUICAO DE PAGAMENTOS LTDA." },
    { "code": "324", "name": "CARTOS SCD S.A.", "ispb": "21332862", "description": "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "310", "name": "VORTX DTVM LTDA.", "ispb": "22610500", "description": "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
    { "code": "380", "name": "PICPAY", "ispb": "22896431", "description": "PICPAY INSTITUIçãO DE PAGAMENTO S.A." },
    { "code": "163", "name": "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO", "ispb": "23522214", "description": "Commerzbank Brasil S.A. - Banco Múltiplo" },
    { "code": "280", "name": "WILL FINANCEIRA S.A.CFI", "ispb": "23862762", "description": "WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "146", "name": "GUITTA CC LTDA", "ispb": "24074692", "description": "GUITTA CORRETORA DE CAMBIO LTDA." },
    { "code": "343", "name": "FFA SCMEPP LTDA.", "ispb": "24537861", "description": "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA." },
    { "code": "279", "name": "COOP DE PRIMAVERA DO LESTE", "ispb": "26563270", "description": "PRIMACREDI COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE" },
    { "code": "335", "name": "BANCO DIGIO", "ispb": "27098060", "description": "Banco Digio S.A." },
    { "code": "349", "name": "AL5 S.A. CFI", "ispb": "27214112", "description": "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "427", "name": "CRED-UFES", "ispb": "27302181", "description": "COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO" },
    { "code": "374", "name": "REALIZE CFI S.A.", "ispb": "27351731", "description": "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
    { "code": "278", "name": "GENIAL INVESTIMENTOS CVM S.A.", "ispb": "27652684", "description": "Genial Investimentos Corretora de Valores Mobiliários S.A." },
    { "code": "271", "name": "IB CCTVM S.A.", "ispb": "27842177", "description": "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A." },
    { "code": "021", "name": "BCO BANESTES S.A.", "ispb": "28127603", "description": "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO" },
    { "code": "246", "name": "BCO ABC BRASIL S.A.", "ispb": "28195667", "description": "Banco ABC Brasil S.A." },
    { "code": "292", "name": "BS2 DTVM S.A.", "ispb": "28650236", "description": "BS2 Distribuidora de Títulos e Valores Mobiliários S.A." },
    { "code": "751", "name": "SCOTIABANK BRASIL", "ispb": "29030467", "description": "Scotiabank Brasil S.A. Banco Múltiplo" },
    { "code": "352", "name": "TORO CTVM S.A.", "ispb": "29162769", "description": "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "208", "name": "BANCO BTG PACTUAL S.A.", "ispb": "30306294", "description": "Banco BTG Pactual S.A." },
    { "code": "386", "name": "NU FINANCEIRA S.A. CFI", "ispb": "30680829", "description": "NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento" },
    { "code": "746", "name": "BCO MODAL S.A.", "ispb": "30723886", "description": "Banco Modal S.A." },
    { "code": "241", "name": "BCO CLASSICO S.A.", "ispb": "31597552", "description": "BANCO CLASSICO S.A." },
    { "code": "398", "name": "IDEAL CTVM S.A.", "ispb": "31749596", "description": "IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "336", "name": "BCO C6 S.A.", "ispb": "31872495", "description": "Banco C6 S.A." },
    { "code": "612", "name": "BCO GUANABARA S.A.", "ispb": "31880826", "description": "Banco Guanabara S.A." },
    { "code": "604", "name": "BCO INDUSTRIAL DO BRASIL S.A.", "ispb": "31895683", "description": "Banco Industrial do Brasil S.A." },
    { "code": "505", "name": "BCO CREDIT SUISSE S.A.", "ispb": "32062580", "description": "Banco Credit Suisse (Brasil) S.A." },
    { "code": "329", "name": "QI SCD S.A.", "ispb": "32402502", "description": "QI Sociedade de Crédito Direto S.A." },
    { "code": "196", "name": "FAIR CC S.A.", "ispb": "32648370", "description": "FAIR CORRETORA DE CAMBIO S.A." },
    { "code": "342", "name": "CREDITAS SCD", "ispb": "32997490", "description": "Creditas Sociedade de Crédito Direto S.A." },
    { "code": "300", "name": "BCO LA NACION ARGENTINA", "ispb": "33042151", "description": "Banco de la Nacion Argentina" },
    { "code": "477", "name": "CITIBANK N.A.", "ispb": "33042953", "description": "Citibank N.A." },
    { "code": "266", "name": "BCO CEDULA S.A.", "ispb": "33132044", "description": "BANCO CEDULA S.A." },
    { "code": "122", "name": "BCO BRADESCO BERJ S.A.", "ispb": "33147315", "description": "Banco Bradesco BERJ S.A." },
    { "code": "376", "name": "BCO J.P. MORGAN S.A.", "ispb": "33172537", "description": "BANCO J.P. MORGAN S.A." },
    { "code": "348", "name": "BCO XP S.A.", "ispb": "33264668", "description": "Banco XP S.A." },
    { "code": "473", "name": "BCO CAIXA GERAL BRASIL S.A.", "ispb": "33466988", "description": "Banco Caixa Geral - Brasil S.A." },
    { "code": "745", "name": "BCO CITIBANK S.A.", "ispb": "33479023", "description": "Banco Citibank S.A." },
    { "code": "120", "name": "BCO RODOBENS S.A.", "ispb": "33603457", "description": "BANCO RODOBENS S.A." },
    { "code": "265", "name": "BCO FATOR S.A.", "ispb": "33644196", "description": "Banco Fator S.A." },
    { "code": "007", "name": "BNDES", "ispb": "33657248", "description": "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL" },
    { "code": "188", "name": "ATIVA S.A. INVESTIMENTOS CCTVM", "ispb": "33775974", "description": "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES" },
    { "code": "134", "name": "BGC LIQUIDEZ DTVM LTDA", "ispb": "33862244", "description": "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "029", "name": "BANCO ITAÚ CONSIGNADO S.A.", "ispb": "33885724", "description": "Banco Itaú Consignado S.A." },
    { "code": "467", "name": "MASTER S/A CCTVM", "ispb": "33886862", "description": "MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS" },
    { "code": "243", "name": "BANCO MASTER", "ispb": "33923798", "description": "BANCO MASTER S/A" },
    { "code": "397", "name": "LISTO SCD S.A.", "ispb": "34088029", "description": "LISTO SOCIEDADE DE CREDITO DIRETO S.A." },
    { "code": "078", "name": "HAITONG BI DO BRASIL S.A.", "ispb": "34111187", "description": "Haitong Banco de Investimento do Brasil S.A." },
    { "code": "355", "name": "ÓTIMO SCD S.A.", "ispb": "34335592", "description": "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "367", "name": "VITREO DTVM S.A.", "ispb": "34711571", "description": "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "445", "name": "PLANTAE CFI", "ispb": "35551187", "description": "PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "373", "name": "UP.P SEP S.A.", "ispb": "35977097", "description": "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A." },
    { "code": "111", "name": "OLIVEIRA TRUST DTVM S.A.", "ispb": "36113876", "description": "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A." },
    { "code": "512", "name": "FINVEST DTVM", "ispb": "36266751", "description": "FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "516", "name": "FC FINANCEIRA S.A. - CFI", "ispb": "36583700", "description": "FC FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "408", "name": "BONUSPAGO SCD S.A.", "ispb": "36586946", "description": "BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "484", "name": "MAF DTVM SA", "ispb": "36864992", "description": "MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "402", "name": "COBUCCIO S.A. SCFI", "ispb": "36947229", "description": "COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS" },
    { "code": "404", "name": "SUMUP SCD S.A.", "ispb": "37241230", "description": "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "418", "name": "ZIPDIN SCD S.A.", "ispb": "37414009", "description": "ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A" },
    { "code": "414", "name": "LEND SCD S.A.", "ispb": "37526080", "description": "LEND SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "449", "name": "DMCARD SCD S.A.", "ispb": "37555231", "description": "DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "406", "name": "ACCREDITO SCD S.A.", "ispb": "37715993", "description": "ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "403", "name": "CORA SCD S.A.", "ispb": "37880206", "description": "CORA SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "419", "name": "NUMBRS SCD S.A.", "ispb": "38129006", "description": "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "435", "name": "DELCRED SCD S.A.", "ispb": "38224857", "description": "DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "421", "name": "CC LAR CREDI", "ispb": "39343350", "description": "LAR COOPERATIVA DE CRÉDITO - LAR CREDI" },
    { "code": "443", "name": "CREDIHOME SCD", "ispb": "39416705", "description": "CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "457", "name": "UY3 SCD S/A", "ispb": "39587424", "description": "UY3 SOCIEDADE DE CRÉDITO DIRETO S/A" },
    { "code": "428", "name": "CREDSYSTEM SCD S.A.", "ispb": "39664698", "description": "CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "448", "name": "HEMERA DTVM LTDA.", "ispb": "39669186", "description": "HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "452", "name": "CREDIFIT SCD S.A.", "ispb": "39676772", "description": "CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "510", "name": "FFCRED SCD S.A.", "ispb": "39738065", "description": "FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A.." },
    { "code": "462", "name": "STARK SCD S.A.", "ispb": "39908427", "description": "STARK SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "465", "name": "CAPITAL CONSIG SCD S.A.", "ispb": "40083667", "description": "CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "306", "name": "PORTOPAR DTVM LTDA", "ispb": "40303299", "description": "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
    { "code": "463", "name": "AZUMI DTVM", "ispb": "40434681", "description": "AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA." },
    { "code": "451", "name": "J17 - SCD S/A", "ispb": "40475846", "description": "J17 - SOCIEDADE DE CRÉDITO DIRETO S/A" },
    { "code": "444", "name": "TRINUS SCD S.A.", "ispb": "40654622", "description": "TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "454", "name": "MÉRITO DTVM LTDA.", "ispb": "41592532", "description": "MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "460", "name": "UNAVANTI SCD S/A", "ispb": "42047025", "description": "UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A" },
    { "code": "506", "name": "RJI", "ispb": "42066258", "description": "RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA" },
    { "code": "482", "name": "SBCASH SCD", "ispb": "42259084", "description": "SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "017", "name": "BNY MELLON BCO S.A.", "ispb": "42272526", "description": "BNY Mellon Banco S.A." },
    { "code": "174", "name": "PEFISA S.A. - C.F.I.", "ispb": "43180355", "description": "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
    { "code": "481", "name": "SUPERLÓGICA SCD S.A.", "ispb": "43599047", "description": "SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "433", "name": "BR-CAPITAL DTVM S.A.", "ispb": "44077014", "description": "BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "495", "name": "BCO LA PROVINCIA B AIRES BCE", "ispb": "44189447", "description": "Banco de La Provincia de Buenos Aires" },
    { "code": "511", "name": "MAGNUM SCD", "ispb": "44683140", "description": "MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A." },
    { "code": "125", "name": "BANCO GENIAL", "ispb": "45246410", "description": "BANCO GENIAL S.A." },
    { "code": "488", "name": "JPMORGAN CHASE BANK", "ispb": "46518205", "description": "JPMorgan Chase Bank, National Association" },
    { "code": "065", "name": "BCO ANDBANK S.A.", "ispb": "48795256", "description": "Banco AndBank (Brasil) S.A." },
    { "code": "145", "name": "LEVYCAM CCV LTDA", "ispb": "50579044", "description": "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA." },
    { "code": "250", "name": "BCV - BCO, CRÉDITO E VAREJO S.A.", "ispb": "50585090", "description": "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
    { "code": "253", "name": "BEXS CC S.A.", "ispb": "52937216", "description": "Bexs Corretora de Câmbio S/A" },
    { "code": "269", "name": "BCO HSBC S.A.", "ispb": "53518684", "description": "BANCO HSBC S.A." },
    { "code": "213", "name": "BCO ARBI S.A.", "ispb": "54403563", "description": "Banco Arbi S.A." },
    { "code": "139", "name": "INTESA SANPAOLO BRASIL S.A. BM", "ispb": "55230916", "description": "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo" },
    { "code": "018", "name": "BCO TRICURY S.A.", "ispb": "57839805", "description": "Banco Tricury S.A." },
    { "code": "422", "name": "BCO SAFRA S.A.", "ispb": "58160789", "description": "Banco Safra S.A." },
    { "code": "630", "name": "BCO LETSBANK S.A.", "ispb": "58497702", "description": "BANCO LETSBANK S.A." },
    { "code": "224", "name": "BCO FIBRA S.A.", "ispb": "58616418", "description": "Banco Fibra S.A." },
    { "code": "393", "name": "BCO VOLKSWAGEN S.A", "ispb": "59109165", "description": "Banco Volkswagen S.A." },
    { "code": "600", "name": "BCO LUSO BRASILEIRO S.A.", "ispb": "59118133", "description": "Banco Luso Brasileiro S.A." },
    { "code": "390", "name": "BCO GM S.A.", "ispb": "59274605", "description": "BANCO GM S.A." },
    { "code": "623", "name": "BANCO PAN", "ispb": "59285411", "description": "Banco Pan S.A." },
    { "code": "655", "name": "BCO VOTORANTIM S.A.", "ispb": "59588111", "description": "Banco Votorantim S.A." },
    { "code": "479", "name": "BCO ITAUBANK S.A.", "ispb": "60394079", "description": "Banco ItauBank S.A." },
    { "code": "456", "name": "BCO MUFG BRASIL S.A.", "ispb": "60498557", "description": "Banco MUFG Brasil S.A." },
    { "code": "464", "name": "BCO SUMITOMO MITSUI BRASIL S.A.", "ispb": "60518222", "description": "Banco Sumitomo Mitsui Brasileiro S.A." },
    { "code": "341", "name": "ITAÚ UNIBANCO S.A.", "ispb": "60701190", "description": "ITAÚ UNIBANCO S.A." },
    { "code": "237", "name": "BCO BRADESCO S.A.", "ispb": "60746948", "description": "Banco Bradesco S.A." },
    { "code": "381", "name": "BCO MERCEDES-BENZ S.A.", "ispb": "60814191", "description": "BANCO MERCEDES-BENZ DO BRASIL S.A." },
    { "code": "613", "name": "OMNI BANCO S.A.", "ispb": "60850229", "description": "Omni Banco S.A." },
    { "code": "652", "name": "ITAÚ UNIBANCO HOLDING S.A.", "ispb": "60872504", "description": "Itaú Unibanco Holding S.A." },
    { "code": "637", "name": "BCO SOFISA S.A.", "ispb": "60889128", "description": "BANCO SOFISA S.A." },
    { "code": "653", "name": "BANCO VOITER", "ispb": "61024352", "description": "BANCO VOITER S.A." },
    { "code": "069", "name": "BCO CREFISA S.A.", "ispb": "61033106", "description": "Banco Crefisa S.A." },
    { "code": "370", "name": "BCO MIZUHO S.A.", "ispb": "61088183", "description": "Banco Mizuho do Brasil S.A." },
    { "code": "249", "name": "BANCO INVESTCRED UNIBANCO S.A.", "ispb": "61182408", "description": "Banco Investcred Unibanco S.A." },
    { "code": "318", "name": "BCO BMG S.A.", "ispb": "61186680", "description": "Banco BMG S.A." },
    { "code": "626", "name": "BCO C6 CONSIG", "ispb": "61348538", "description": "BANCO C6 CONSIGNADO S.A." },
    { "code": "508", "name": "AVENUE SECURITIES DTVM LTDA.", "ispb": "61384004", "description": "AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "270", "name": "SAGITUR CC", "ispb": "61444949", "description": "SAGITUR CORRETORA DE CÂMBIO S.A." },
    { "code": "366", "name": "BCO SOCIETE GENERALE BRASIL", "ispb": "61533584", "description": "BANCO SOCIETE GENERALE BRASIL S.A." },
    { "code": "113", "name": "NEON CTVM S.A.", "ispb": "61723847", "description": "NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "131", "name": "TULLETT PREBON BRASIL CVC LTDA", "ispb": "61747085", "description": "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA" },
    { "code": "011", "name": "C.SUISSE HEDGING-GRIFFO CV S/A", "ispb": "61809182", "description": "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A" },
    { "code": "611", "name": "BCO PAULISTA S.A.", "ispb": "61820817", "description": "Banco Paulista S.A." },
    { "code": "755", "name": "BOFA MERRILL LYNCH BM S.A.", "ispb": "62073200", "description": "Bank of America Merrill Lynch Banco Múltiplo S.A." },
    { "code": "089", "name": "CREDISAN CC", "ispb": "62109566", "description": "CREDISAN COOPERATIVA DE CRÉDITO" },
    { "code": "643", "name": "BCO PINE S.A.", "ispb": "62144175", "description": "Banco Pine S.A." },
    { "code": "140", "name": "NU INVEST CORRETORA DE VALORES S.A.", "ispb": "62169875", "description": "NU INVEST CORRETORA DE VALORES S.A." },
    { "code": "707", "name": "BCO DAYCOVAL S.A", "ispb": "62232889", "description": "Banco Daycoval S.A." },
    { "code": "288", "name": "CAROL DTVM LTDA.", "ispb": "62237649", "description": "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
    { "code": "363", "name": "SINGULARE CTVM S.A.", "ispb": "62285390", "description": "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
    { "code": "101", "name": "RENASCENCA DTVM LTDA", "ispb": "62287735", "description": "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "487", "name": "DEUTSCHE BANK S.A.BCO ALEMAO", "ispb": "62331228", "description": "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
    { "code": "233", "name": "BANCO CIFRA", "ispb": "62421979", "description": "Banco Cifra S.A." },
    { "code": "177", "name": "GUIDE", "ispb": "65913436", "description": "Guide Investimentos S.A. Corretora de Valores" },
    { "code": "438", "name": "TRUSTEE DTVM LTDA.", "ispb": "67030395", "description": "TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
    { "code": "365", "name": "SIMPAUL", "ispb": "68757681", "description": "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A." },
    { "code": "633", "name": "BCO RENDIMENTO S.A.", "ispb": "68900810", "description": "Banco Rendimento S.A." },
    { "code": "218", "name": "BCO BS2 S.A.", "ispb": "71027866", "description": "Banco BS2 S.A." },
    { "code": "293", "name": "LASTRO RDV DTVM LTDA", "ispb": "71590442", "description": "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda." },
    { "code": "285", "name": "FRENTE CC LTDA.", "ispb": "71677850", "description": "Frente Corretora de Câmbio Ltda." },
    { "code": "080", "name": "B&T CC LTDA.", "ispb": "73622748", "description": "B&T CORRETORA DE CAMBIO LTDA." },
    { "code": "753", "name": "NOVO BCO CONTINENTAL S.A. - BM", "ispb": "74828799", "description": "Novo Banco Continental S.A. - Banco Múltiplo" },
    { "code": "222", "name": "BCO CRÉDIT AGRICOLE BR S.A.", "ispb": "75647891", "description": "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
    { "code": "281", "name": "CCR COOPAVEL", "ispb": "76461557", "description": "Cooperativa de Crédito Rural Coopavel" },
    { "code": "754", "name": "BANCO SISTEMA", "ispb": "76543115", "description": "Banco Sistema S.A." },
    { "code": "311", "name": "DOURADA CORRETORA", "ispb": "76641497", "description": "DOURADA CORRETORA DE CÂMBIO LTDA." },
    { "code": "098", "name": "CREDIALIANÇA CCR", "ispb": "78157146", "description": "Credialiança Cooperativa de Crédito Rural" },
    { "code": "610", "name": "BCO VR S.A.", "ispb": "78626983", "description": "Banco VR S.A." },
    { "code": "712", "name": "BCO OURINVEST S.A.", "ispb": "78632767", "description": "Banco Ourinvest S.A." },
    { "code": "720", "name": "BCO RNX S.A.", "ispb": "80271455", "description": "BANCO RNX S.A." },
    { "code": "010", "name": "CREDICOAMO", "ispb": "81723108", "description": "CREDICOAMO CREDITO RURAL COOPERATIVA" },
    { "code": "440", "name": "CREDIBRF COOP", "ispb": "82096447", "description": "CREDIBRF - COOPERATIVA DE CRÉDITO" },
    { "code": "442", "name": "MAGNETIS - DTVM", "ispb": "87963450", "description": "MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
    { "code": "283", "name": "RB INVESTIMENTOS DTVM LTDA.", "ispb": "89960090", "description": "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA" },
    { "code": "033", "name": "BCO SANTANDER (BRASIL) S.A.", "ispb": "90400888", "description": "BANCO SANTANDER (BRASIL) S.A." },
    { "code": "217", "name": "BANCO JOHN DEERE S.A.", "ispb": "91884981", "description": "Banco John Deere S.A." },
    { "code": "041", "name": "BCO DO ESTADO DO RS S.A.", "ispb": "92702067", "description": "Banco do Estado do Rio Grande do Sul S.A." },
    { "code": "117", "name": "ADVANCED CC LTDA", "ispb": "92856905", "description": "ADVANCED CORRETORA DE CÂMBIO LTDA" },
    { "code": "654", "name": "BCO DIGIMAIS S.A.", "ispb": "92874270", "description": "BANCO DIGIMAIS S.A." },
    { "code": "371", "name": "WARREN CVMC LTDA", "ispb": "92875780", "description": "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA." },
    { "code": "212", "name": "BANCO ORIGINAL", "ispb": "92894922", "description": "Banco Original S.A." },
    { "code": "289", "name": "EFX CC LTDA.", "ispb": "94968518", "description": "EFX CORRETORA DE CÂMBIO LTDA." }
    ]

export default BANK_CODES_TYPES
