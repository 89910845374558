import {
  SET_UNIT_SCOPE,
} from "./actionTypes"

const INIT_STATE = {
  unit: null,
};

const Scope = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case SET_UNIT_SCOPE:
      return {
        ...state,
        unit: action.payload,
      }
    default:
      return state
  }
}

export default Scope
