import axios from 'axios';
import userToken from "./local-user-token"
import authUtils from "utils/auth"

const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_VITTA_SERVICES_HOST
});

async function request(axiosConfig){

    
    if(!axiosConfig) return null;

    if(!axiosConfig.headers)
    {
        axiosConfig.headers = defaultHeaders;
    }
    else
    {
        if(!axiosConfig.headers["Content-Type"]) axiosConfig.headers["Content-Type"] = defaultHeaders['Content-Type'];
        if(!axiosConfig.headers["Accept"]) axiosConfig.headers["Accept"] = defaultHeaders['Accept'];
    }

    if(axiosConfig.vtAuth)
    {
        axiosConfig.headers["Authorization"] = "Bearer " + userToken()
    }

    try
    {
        // console.log("axios config", axiosConfig);
        let data = await instance.request(axiosConfig);

        if(axiosConfig.returnFullResponse) return data;

        return data.data;
    }
    catch(e)
    {
        if(userToken() && e?.response?.status == 401)
        {
            authUtils.removeStoredAccess();
            window.location.href = "/login";
        }
        throw e;
    }
}

export default request;