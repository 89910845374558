import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
} from "./actionTypes"

export const userLogin = (userData) => {
  return {
    type: USER_LOGIN,
    payload: userData,
  }
}

export const userLoginSuccess = (auth) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: auth,
  }
}

export const userLoginFailure = (error) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error,
  }
}

export const userLogout = () => {
  return {
    type: USER_LOGOUT,
  }
}

export const userLogoutSuccess = () => {
  return {
    type: USER_LOGOUT_SUCCESS,
  }
}