import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { deletePatient } from "services/vitta-core/patient"
import ListPatientsColumns from "pages/Patients/ListPatients/ListPatientsColumn"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import checkPermission from "utils/auth"

const ListPatients = () => {
    // const [modal, setModal] = useState()
    const [PatientList, setPatientList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const dispatch = useDispatch()
    const history = useHistory()
    const inputRefSearch = useRef()
    const User = useSelector(state => state.auth.access.user)
    const controlPermissionViewPatientContactData = useMemo(() => {
        let found = checkPermission.hasPermission(
            User,
            "view_patient_contact_data"
        )

        if (found) {
            return true
        } else {
            return false
        }
    }, [User])

    const defaultPageControl = {
        searching: false,
    }

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async (page = 1) => {
        if (pageControl.searching) return

        let sizePerPage = 10

        let termSearch = inputRefSearch?.current?.value

        //Set is searching
        setPageControl({ ...pageControl, ...{ searching: true } })
        //Clear Data Table
        setPatientList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom: true,
            data: [],
        })

        let filter = {
            term: termSearch,
        }

        try {
            let query = page ? "?page=" + page : ""
            if (termSearch) query += "&term=" + termSearch

            history.push({
                pathname: "/pacientes",
                search: query,
                state: {},
            })

            let data = await patientServiceSearch(filter, page, sizePerPage)

            setPatientList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    useEffect(() => {
        let page = 1
        if (location.search) {
            handleQuery()
        } else {
            search(page)
        }
    }, [])

    const deleteRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            await deletePatient(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )
            let tempData = { ...PatientList }
            tempData.data = PatientList.data.filter(elem => elem.id != id)

            setPatientList(tempData)
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }
    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page }) => {
        if (type == "pagination") {
            search(page)
        }
    }

    const toggleModal = () => {
        setModal(!modal)
    }

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }
    const handleQuery = async () => {
        let searchLoc = location.search
        const params = new URLSearchParams(searchLoc)
        const term = params.get("term")

        const page = params.get("page") ? parseFloat(params.get("page")) : 1

        if (term) {
            inputRefSearch.current.value = term
        }

        await search(page)
    }
    let auxColums = ListPatientsColumns()
    auxColums = auxColums.filter(
        item =>
            item.dataField != "email" &&
            item.dataField != "mobile_phone" &&
            item.dataField != "addr_state" &&
            item.dataField != "addr_city"
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Pacientes"
                        breadcrumbItem="Lista de Pacientes"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            PatientList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={PatientList.data || []}
                                                columns={
                                                    controlPermissionViewPatientContactData
                                                        ? ListPatientsColumns(
                                                              deleteRecord
                                                          )
                                                        : auxColums
                                                }
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <Form
                                                                    onSubmit={e => {
                                                                        e.preventDefault()
                                                                        search()
                                                                    }}
                                                                >
                                                                    <div className="search-box mr-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <Input
                                                                                type="text"
                                                                                innerRef={
                                                                                    inputRefSearch
                                                                                }
                                                                            ></Input>
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                    <Button
                                                                        type="submit"
                                                                        color="primary"
                                                                    >
                                                                        Buscar
                                                                    </Button>
                                                                </Form>
                                                            </Col>
                                                            {controlPermissionViewPatientContactData ? (
                                                                <Col sm="4">
                                                                    <div className="text-sm-right ">
                                                                        <Link
                                                                            to={{
                                                                                pathname:
                                                                                    "/pacientes/adicionar",
                                                                                state: {
                                                                                    previousPath:
                                                                                        location?.pathname +
                                                                                        location.search,
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                type="submit"
                                                                                color="primary"
                                                                            >
                                                                                Adicionar
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListPatients
