import request from './config/request';

const create = async data => {
    return request({
        method: "POST",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointments`,
        data: data,
        vtAuth: true
    })
}

const update = async (id, data) => {
    return request({
        method: "POST",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointments/${id}`,
        data: data,
        vtAuth: true
    })
}

const searchCascadeView = (filter) => {
    let query =  "?start_date=" + filter.start_date;
    query += "&end_date=" + filter.end_date;

    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    if (filter.appointment_book_id) query += "&appointment_book_id=" + filter.appointment_book_id;
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id;
    if (filter.service_id) query += "&service_id=" + filter.service_id;
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;
    query +="&execution_mode=" + filter.execution_mode;
    return request({
        method: "GET",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointments/search-cascade-view` +
        query,
        vtAuth: true
    })
}

const searchCascadeViewProf = (filter) => {
    let query =  "?start_date=" + filter.start_date;
    query += "&end_date=" + filter.end_date;

    if (filter.unit_id) query += "&unit_id=" + filter.unit_id;
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;
    query +="&execution_mode=" + filter.execution_mode;

    return request({
        method: "GET",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointments/search-cascade-view/prof` +
        query,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:   process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/appointments/${id}`,
        vtAuth: true
    })
}

const AppointmentAnalyticalReport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;    
    if (filter.user_id) query += "&user_id=" + filter.user_id;    
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id;        
    if (filter.service_id) query += "&service_id=" + filter.service_id;     
    if (filter.is_fit) query += "&is_fit=" + filter.is_fit;  
    if (filter.start_exec_in) query += "&start_exec_in=" + filter.start_exec_in;    
    if (filter.end_exec_in) query += "&end_exec_in=" + filter.end_exec_in;        
    if (filter.book_id) query += "&book_id=" + filter.book_id;    
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment/analytical-report` + query,
        vtAuth: true
    })
}

const AppointmentAnalyticalReportExport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;  
    if (filter.user_id) query += "&user_id=" + filter.user_id;    
    if (filter.professional_id) query += "&professional_id=" + filter.professional_id;  
    if (filter.service_id) query += "&service_id=" + filter.service_id;      
    if (filter.is_fit) query += "&is_fit=" + filter.is_fit;
    if (filter.start_exec_in) query += "&start_exec_in=" + filter.start_exec_in;    
    if (filter.end_exec_in) query += "&end_exec_in=" + filter.end_exec_in;        
    if (filter.book_id) query += "&book_id=" + filter.book_id;       
    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment/analytical-report-export` + query,
        vtAuth: true,
        returnFullResponse: true
    })
}
const ViewMyProfAppointmentAnalyticalReport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;    
    if (filter.user_id) query += "&user_id=" + filter.user_id;        
    if (filter.service_id) query += "&service_id=" + filter.service_id;     
    if (filter.is_fit) query += "&is_fit=" + filter.is_fit;  
    if (filter.start_exec_in) query += "&start_exec_in=" + filter.start_exec_in;    
    if (filter.end_exec_in) query += "&end_exec_in=" + filter.end_exec_in;        
    if (filter.book_id) query += "&book_id=" + filter.book_id;    
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment/analytical-report/view-prof` + query,
        vtAuth: true
    })
}

const ViewMyProfAppointmentAnalyticalReportExport = (filter) => {

    let query = "?start_created_at=" + filter.start_created_at;
    query += "&end_created_at=" + filter.end_created_at;
    query += "&status=" + filter.status.join(',');
    query += "&unit_id=" + filter.unit_id.join(',');
    
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id;  
    if (filter.user_id) query += "&user_id=" + filter.user_id;    
    if (filter.service_id) query += "&service_id=" + filter.service_id;      
    if (filter.is_fit) query += "&is_fit=" + filter.is_fit;
    if (filter.start_exec_in) query += "&start_exec_in=" + filter.start_exec_in;    
    if (filter.end_exec_in) query += "&end_exec_in=" + filter.end_exec_in;        
    if (filter.book_id) query += "&book_id=" + filter.book_id;       
    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment/analytical-report-export/view-prof` + query,
        vtAuth: true,
        returnFullResponse: true
    })
}

const getPreviousAppointments = (filter) => {

    let query = "?appointment_book_id=" + filter.appointment_book_id;
    query += "&patient_id=" + filter.patient_id;


    if (filter.appointment_id) query += "&appointment_id=" + filter.appointment_id;  

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment/previous` + query,
        vtAuth: true,
    })
}

export {
    searchCascadeView,
    create,
    update,
    loadDataEditform,
    searchCascadeViewProf,
    AppointmentAnalyticalReport,
    AppointmentAnalyticalReportExport,
    ViewMyProfAppointmentAnalyticalReportExport,
    ViewMyProfAppointmentAnalyticalReport,
    getPreviousAppointments
}
