import request from './config/request';

const loadVersion = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/version`,
        vtAuth: true,
    })
}


export {
    loadVersion
}
