import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Modal,
    CardBody,
    Label,
    FormGroup,
    Input,
    Table,
    Button,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from "reactstrap"
import { Formik, Form, Field, ErrorMessage } from "formik"
import AsyncSelect from "react-select/async"
import { search as searchSalesOrder } from "services/vitta-core/sales-order"
import { search as partnerServiceSearch } from "services/vitta-core/partner"
import debounce from "debounce-promise"
import { getExamsOrdersBySalesOrder } from "services/vitta-core/orders-exams"
import EXAMS_ORDER_STATUS from "utils/consts/exams-orders-status"
import uniqid from "uniqid"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { createManually } from "services/vitta-core/orders-exams"
import { cancelExamsOrderManually } from "services/vitta-core/orders-exams"
import { updateManually } from "services/vitta-core/orders-exams"
import { getExamOrderResults } from "services/vitta-core/orders-exams"
import SALES_ORDER_STATUS from "utils/consts/sales-orders-status"
const AsyncSelectInputItem = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={async option => {
            setFieldValue(field.name, option)

            if (field?.name == "sales_order_id") {
                props.handlePendentItems(option?.value, setFieldValue)
            }
        }}
        isDisabled={props.disabled}
    />
)
const InputFile = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Input
        type="file"
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const AddExamsOrdersModal = ({ show, data, onClose, patient_id }) => {
    const defaultValues = {
        partner_id: null,
        sales_order_id: null,
        unit_id: null,
        request_professional_id: null,
        request_professional_name: null,
        items: [],
    }
    const [dataForm, setDataForm] = useState(defaultValues)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [controlLoadingData, setControlLoadingData] = useState({
        showLoading: false,
        showData: false,
    })
    const [loadingOpenPdf, setLoadingOpenPdf] = useState(false)
    const [dataFile, setDataFile] = useState(null)
    const [pdfFileUrl, setPdfFileUrl] = useState("")
    const fileInputRef = useRef(null)
    const dispatch = useDispatch()

    const loadDataModal = async () => {
        try {
            setPdfFileUrl("")
            setDataForm(defaultValues)
            setDataFile(null)
            setControlLoadingData({
                showData: false,
                showLoading: false,
            })

            loadDataEditPage()
        } catch (e) {
            console.log(e)
        }
    }

    const loadDataEditPage = () => {
        try {
            if (!data?.id) return

            let items = []
            let form = { ...dataForm }
            form.partner_id = data?.partner
            form.sales_order_id = {
                label: "#" + data?.sales_order_id,
                value: data?.sales_order_id,
            }

            for (let i = 0; i < data?.items?.length; i++) {
                let use = data?.items[i]

                items.push({
                    id: use?.id,
                    item: {
                        id: use?.item_id?.value,
                        name: use?.item_id?.label,
                    },
                    quantity: use?.quantity,
                    status: use?.status,
                })
            }
            form.items = items
            if (data?.result_path) {
                setPdfFileUrl(data?.result_path)
            }
            setDataForm(form)
            let dataShow = { ...controlLoadingData }
            dataShow.showData = true
            setControlLoadingData(dataShow)
        } catch (e) {
            console.log(e)
        }
    }

    const loadPartnerInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await partnerServiceSearch(
                    { term: inputValue, status: 1 },
                    1,
                    20
                )

                let partner_values = []
                response.data.forEach(element => {
                    partner_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(partner_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPartner = debounce(loadPartnerInputOptions, 600)
    const loadSalesOrderInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchSalesOrder(
                    {
                        term: inputValue,
                        patient_id: patient_id,
                        status: [
                            SALES_ORDER_STATUS.PAID,
                            SALES_ORDER_STATUS.INVOICE_ISSUED,
                        ]?.join(","),
                    },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Sem filtro",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} - Criado em: ${new Date(
                            element?.created_at
                        )?.toLocaleDateString("pt-BR")}`,
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsSalesOrder = debounce(loadSalesOrderInputOptions, 600)

    const handlePendentItems = async (sales_order_id, setFieldValue) => {
        let control = { ...controlLoadingData }
        control.showLoading = true
        setControlLoadingData(control)
        try {
            let group_item_ids = []
            group_item_ids = group_item_ids.concat(
                process?.env?.REACT_APP_ITEM_EXAM_GROUP_IDS?.split(","),
                process?.env?.REACT_APP_ITEM_PROCEDIMENT_GROUP_IDS?.split(",")
            )
            let getExams = await getExamsOrdersBySalesOrder(sales_order_id)
            let items = []
            for (let i = 0; i < getExams?.items?.length; i++) {
                let use = getExams?.items[i]
                if (!group_item_ids.includes(use?.item?.group_id?.toString()))
                    continue

                if (use?.quantity > 1) {
                    for (let j = 0; j < use?.quantity; j++) {
                        items.push({
                            id: uniqid(),
                            item: {
                                id: use?.item?.id,
                                name: use?.item?.name,
                            },
                            quantity: 1,
                            status: EXAMS_ORDER_STATUS.IN_PROGRESS,
                        })
                    }
                } else {
                    items.push({
                        id: uniqid(),
                        item: {
                            id: use?.item?.id,
                            name: use?.item?.name,
                        },
                        quantity: 1,
                        status: EXAMS_ORDER_STATUS.IN_PROGRESS,
                    })
                }
            }
            setFieldValue("unit_id", getExams?.unit_id)
            setFieldValue(
                "request_professional_id",
                getExams?.ind_professional_id
            )
            setFieldValue(
                "request_professional_name",
                getExams?.request_prof_name
            )
            setFieldValue("items", items)
        } catch (e) {
            console.log(e)
        }
        control.showData = true
        control.showLoading = false
        setControlLoadingData(control)
    }

    const onSubmit = async values => {
        try {
            if (!values.partner_id?.value) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: "É necessário informar um laboratório.",
                        type: "warning",
                    })
                )
                return
            }
            if (values?.items?.length <= 0) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "É necessário ter ao menos um item para prosseguir com a solicitação.",
                        type: "error",
                    })
                )
                return
            }
            if (!values.sales_order_id?.value) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: "É necessário selecionar o pedido de venda",
                        type: "error",
                    })
                )
                return
            }

            let status = values?.items?.every(
                e => e?.status == EXAMS_ORDER_STATUS.CONCLUDED
            )
                ? EXAMS_ORDER_STATUS.CONCLUDED
                : EXAMS_ORDER_STATUS.IN_PROGRESS

            let remove_file = !dataFile && pdfFileUrl == "" ? true : false

            if (status == EXAMS_ORDER_STATUS.CONCLUDED && remove_file) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "Não é possível deixar o pedido de exame como concluído sem o resultado (PDF).",
                        type: "error",
                    })
                )
                return
            }

            setSubmitLoading(true)
            const dataSend = {
                patient_id: patient_id,
                unit_id: values?.unit_id,
                sales_order_id: values?.sales_order_id?.value,
                partner_id: values?.partner_id?.value,
                request_professional_id: values?.request_professional_id,
                request_professional_name: values.request_professional_name,
                status: status,
                items: values?.items,
                file: { ...dataFile },
                remove_file: remove_file,
            }

            if (!data?.id) {
                await createManually(dataSend)
            } else {
                await updateManually(data?.id, dataSend)
            }

            onClose({ action: "load" })
            dispatch(
                showToast({
                    title: `Pedido de exame ${
                        data?.id ? "atualizado" : "criado"
                    } com sucesso!`,
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...!",
                    text: "Ocorreu um problema, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
        setSubmitLoading(false)
    }

    const handleOptionStatus = (index, value, setFieldValue) => {
        setFieldValue(`items[${index}].status`, parseFloat(value))
    }

    const handleRemoveOption = (id, setFieldValue, values) => {
        let newData = [...values.items]
        newData = newData.filter(e => e?.id != id)
        setFieldValue(`items`, newData)
    }
    const renderStatus = values => {
        if (values?.items?.length <= 0) {
            return (
                <Badge pill className="badge-soft-danger">
                    Sem itens Disponíveis
                </Badge>
            )
        }
        if (data?.status && data?.status == EXAMS_ORDER_STATUS.CANCELED) {
            return (
                <Badge pill className="badge-soft-danger">
                    Cancelado
                </Badge>
            )
        }
        if (
            values?.items?.every(e => e?.status == EXAMS_ORDER_STATUS.CONCLUDED)
        ) {
            return (
                <Badge pill className="badge-soft-success">
                    Concluído
                </Badge>
            )
        } else {
            return <Badge className="badge-soft-info lg">Em Andamento</Badge>
        }
    }
    const handleCancelExamOrder = async () => {
        if (!data?.id) return
        setSubmitLoading(true)
        try {
            await cancelExamsOrderManually(data?.id)
            onClose({ action: "load" })
            dispatch(
                showToast({
                    title: "Pedido de exame cancelado com sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
        }
        setSubmitLoading(false)
    }
    const viewPdfInNewTab = async () => {
        setLoadingOpenPdf(true)
        try {
            if (data?.id && data?.result_path && !dataFile) {
                let response = await getExamOrderResults(data?.id)
                if (response) {
                    window.open(response.url, "_blank")
                }
            } else if (pdfFileUrl) {
                window.open(pdfFileUrl, "_blank")
            }
        } catch (e) {
            console.log(e)
        }
        setLoadingOpenPdf(false)
    }

    const removePdfFile = () => {
        setDataFile(null)
        setPdfFileUrl("")
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
    }
    const changeStatusMass = (value, setFieldValue, values) => {
        if (value == "") return

        let items = [...values.items]
        items = items.map(item => {
            return { ...item, status: value }
        })
        setFieldValue(`items`, items)
    }
    useEffect(() => {
        if (show) loadDataModal()
    }, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            toggle={() => {
                if (!submitLoading) {
                    onClose()
                }
            }}
            size="xl"
        >
            <Formik
                initialValues={dataForm}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <CardBody>
                            {!data?.id ? (
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (!submitLoading) {
                                            onClose()
                                        }
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            ) : null}

                            <Row>
                                <Col sm="6">
                                    {" "}
                                    <div className="">
                                        <div
                                            className="modal-title "
                                            id="myModalLabel"
                                        >
                                            <h5>
                                                <i className="fas fa-file-medical-alt text-info fa-lg mr-2"></i>
                                                {data?.id
                                                    ? `${data?.id} - Resultado de Pedido de Exames `
                                                    : `Novo Resultado de Pedido de Exames`}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                {controlLoadingData.showData ? (
                                    <Col sm="6" align="end">
                                        <div
                                            style={{
                                                position: "relative",
                                                top: "-4px",
                                            }}
                                        >
                                            <h4>
                                                <span
                                                    style={{
                                                        position: "relative",
                                                        top: "1px",
                                                    }}
                                                >
                                                    Status Geral:{" "}
                                                </span>
                                                {controlLoadingData.showData
                                                    ? renderStatus(values)
                                                    : null}
                                            </h4>
                                        </div>
                                    </Col>
                                ) : null}
                            </Row>
                            <hr />
                            <Row className="mb-2">
                                <Col sm="6">
                                    {" "}
                                    <strong className="text-info">
                                        <small>
                                            Informe os dados solicitados:
                                        </small>
                                    </strong>
                                </Col>
                                <Col sm="6" align="end">
                                    {data?.id ? (
                                        <UncontrolledDropdown>
                                            {data?.status !=
                                            EXAMS_ORDER_STATUS.CANCELED ? (
                                                <DropdownToggle
                                                    className=""
                                                    tag="i"
                                                >
                                                    <i
                                                        className="bx bx-grid-alt fa-2x text-info"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />{" "}
                                                </DropdownToggle>
                                            ) : null}

                                            <DropdownMenu right>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={() => {
                                                        window.open(
                                                            `/imprimir/pedidos-de-exame/${data?.id}`,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    <i className="fas fa-print fa-lg text-info mr-1"></i>{" "}
                                                    Imprimir
                                                </DropdownItem>
                                                {data?.status !=
                                                EXAMS_ORDER_STATUS.CANCELED ? (
                                                    <DropdownItem
                                                        href="#"
                                                        onClick={() => {
                                                            return dispatch(
                                                                showSweetAlertDialog(
                                                                    {
                                                                        title:
                                                                            "Tem certeza que deseja cancelar este Pedido de exame?",
                                                                        text:
                                                                            "Essa ação será irreversível...",
                                                                        type:
                                                                            "warning",
                                                                        onConfirmAction: () => {
                                                                            handleCancelExamOrder()
                                                                        },
                                                                        showCancel: true,
                                                                        showConfirm: true,
                                                                        cancelBtnText:
                                                                            "Não",
                                                                        confirmBtnText:
                                                                            "Confirmar",
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <i className="fas fa-window-close fa-lg text-danger mr-1"></i>{" "}
                                                        Cancelar
                                                    </DropdownItem>
                                                ) : null}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <Label>
                                            Pedido de venda{" "}
                                            <strong className="text-danger">
                                                *
                                            </strong>
                                            {
                                                controlLoadingData.showLoading ? (
                                                    <strong className="text-info">
                                                        {" "}
                                                        <small>
                                                            Buscando...
                                                        </small>
                                                    </strong>
                                                ) : data?.id ? null : null // </strong> //     </small> //         Atualizar Items //     <small> //     {" "} // > // onClick={()=>{handlePendentItems(data?.sales_order_id,setFieldValue)}} // <strong className="text-info ml-1" style={{cursor:"pointer",fontWeight:"bold"}}
                                            }
                                        </Label>
                                        <Field
                                            name="sales_order_id"
                                            placeholder="Informe o pedido de venda"
                                            defaultOptions
                                            loadOptions={
                                                debounceOptionsSalesOrder
                                            }
                                            disabled={data?.id ? true : false}
                                            handlePendentItems={
                                                handlePendentItems
                                            }
                                            component={AsyncSelectInputItem}
                                            noOptionsMessage={() =>
                                                "Sem Resultados. Digite para Buscar"
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <Label>
                                            Laboratório{" "}
                                            <strong className="text-danger">
                                                *
                                            </strong>
                                        </Label>
                                        <Field
                                            name="partner_id"
                                            placeholder="Informe o laboratório"
                                            disabled={data?.id ? true : false}
                                            defaultOptions
                                            loadOptions={debounceOptionsPartner}
                                            component={AsyncSelectInputItem}
                                            noOptionsMessage={() =>
                                                "Sem Resultados. Digite para Buscar"
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="6" className="mt-2">
                                    <FormGroup>
                                        {!pdfFileUrl ? (
                                            <Label>
                                                Anexar Resultado (PDF){" "}
                                                <strong className="text-danger">
                                                    *
                                                </strong>
                                            </Label>
                                        ) : (
                                            <Label>
                                                Resultado Anexado (PDF){" "}
                                            </Label>
                                        )}

                                        {!pdfFileUrl ? (
                                            <Input
                                                type="file"
                                                innerRef={fileInputRef}
                                                name="file"
                                                onChange={async e => {
                                                    let file =
                                                        e?.target?.files?.[0]
                                                    if (file) {
                                                        const allowedExtensions = [
                                                            ".pdf",
                                                        ]
                                                        const fileExtension = file?.name
                                                            ?.substring(
                                                                file?.name?.lastIndexOf(
                                                                    "."
                                                                )
                                                            )
                                                            .toLowerCase()

                                                        if (
                                                            !allowedExtensions.includes(
                                                                fileExtension
                                                            )
                                                        ) {
                                                            dispatch(
                                                                showSweetAlertDialog(
                                                                    {
                                                                        title:
                                                                            "Atenção!",
                                                                        text: `É possível anexar somente arquivos com a extensão ".PDF"`,
                                                                        type:
                                                                            "warning",
                                                                    }
                                                                )
                                                            )
                                                            setDataFile(null)
                                                            e.target.value = null
                                                            return
                                                        }

                                                        const reader = new FileReader()
                                                        const objectUrl = URL.createObjectURL(
                                                            file
                                                        )
                                                        setPdfFileUrl(objectUrl)
                                                        reader.onload = () => {
                                                            const base64String = reader.result.split(
                                                                ","
                                                            )[1]
                                                            setDataFile({
                                                                file: base64String,
                                                                filename:
                                                                    file.name,
                                                            }) // base 64
                                                        }

                                                        reader.readAsDataURL(
                                                            file
                                                        )
                                                    }
                                                }}
                                            />
                                        ) : null}

                                        {pdfFileUrl && (
                                            <div className="">
                                                <Button
                                                    disabled={loadingOpenPdf}
                                                    size="sm"
                                                    color="info"
                                                    onClick={viewPdfInNewTab}
                                                >
                                                    📄 Ver PDF{" "}
                                                    {loadingOpenPdf ? (
                                                        <Spinner
                                                            size="sm"
                                                            color="white"
                                                        />
                                                    ) : null}
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    onClick={removePdfFile}
                                                    className="ml-2"
                                                >
                                                    📄 Remover PDF
                                                </Button>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                                {controlLoadingData.showData ? (
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Input
                                                type="select"
                                                onChange={e => {
                                                    changeStatusMass(
                                                        e?.target?.value,
                                                        setFieldValue,
                                                        values
                                                    )
                                                }}
                                                value={
                                                    values?.items?.every(
                                                        e =>
                                                            e?.status ==
                                                            EXAMS_ORDER_STATUS.CONCLUDED
                                                    )
                                                        ? EXAMS_ORDER_STATUS.CONCLUDED
                                                        : EXAMS_ORDER_STATUS.IN_PROGRESS
                                                }
                                            >
                                                <option value={""}>
                                                    Selecionar
                                                </option>
                                                <option
                                                    value={
                                                        EXAMS_ORDER_STATUS.IN_PROGRESS
                                                    }
                                                >
                                                    Em Andamento
                                                </option>
                                                <option
                                                    value={
                                                        EXAMS_ORDER_STATUS.CONCLUDED
                                                    }
                                                >
                                                    Concluído
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null}
                            </Row>
                            {submitLoading ? (
                                <Spinner
                                    color="info"
                                    style={{
                                        position: "absolute",
                                        top: "40%",
                                        left: "45%",
                                    }}
                                />
                            ) : null}
                            {controlLoadingData.showData ? (
                                <>
                                    {" "}
                                    <hr />
                                    <Row>
                                        <Col>
                                            <h5>Itens Disponíveis</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>
                                                            Status{" "}
                                                            {/* <Row>
                                                                <Col sm="8">
                                                                    <span>
                                                                        Status
                                                                    </span>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Input
                                                                        size="sm"
                                                                        type="select"
                                                                        onChange={e => {
                                                                            changeStatusMass(
                                                                                e
                                                                                    ?.target
                                                                                    ?.value,
                                                                                setFieldValue,
                                                                                values
                                                                            )
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Selecionar
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                EXAMS_ORDER_STATUS.IN_PROGRESS
                                                                            }
                                                                        >
                                                                            Em
                                                                            Andamento
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                EXAMS_ORDER_STATUS.CONCLUDED
                                                                            }
                                                                        >
                                                                            Em
                                                                            Concluído
                                                                        </option>
                                                                    </Input>
                                                                </Col>
                                                            </Row> */}
                                                        </th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values?.items?.map(
                                                        (item, index) => (
                                                            <tr
                                                                key={
                                                                    "tr" + index
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        item
                                                                            ?.item
                                                                            ?.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="select"
                                                                        onChange={e => {
                                                                            handleOptionStatus(
                                                                                index,
                                                                                e
                                                                                    ?.target
                                                                                    ?.value,
                                                                                setFieldValue
                                                                            )
                                                                        }}
                                                                        style={{
                                                                            backgroundColor:
                                                                                data?.status ==
                                                                                EXAMS_ORDER_STATUS.CANCELED
                                                                                    ? "#f2f2f2"
                                                                                    : "",
                                                                        }}
                                                                        disabled={
                                                                            data?.status ==
                                                                            EXAMS_ORDER_STATUS.CANCELED
                                                                        }
                                                                    >
                                                                        {item?.status ==
                                                                        EXAMS_ORDER_STATUS.IN_PROGRESS ? (
                                                                            <option
                                                                                value={
                                                                                    EXAMS_ORDER_STATUS.IN_PROGRESS
                                                                                }
                                                                                selected
                                                                            >
                                                                                Em
                                                                                Andamento
                                                                            </option>
                                                                        ) : (
                                                                            <option
                                                                                value={
                                                                                    EXAMS_ORDER_STATUS.IN_PROGRESS
                                                                                }
                                                                            >
                                                                                Em
                                                                                Andamento
                                                                            </option>
                                                                        )}
                                                                        {item?.status ==
                                                                        EXAMS_ORDER_STATUS.CONCLUDED ? (
                                                                            <option
                                                                                value={
                                                                                    EXAMS_ORDER_STATUS.CONCLUDED
                                                                                }
                                                                                selected
                                                                            >
                                                                                Concluído
                                                                            </option>
                                                                        ) : (
                                                                            <option
                                                                                value={
                                                                                    EXAMS_ORDER_STATUS.CONCLUDED
                                                                                }
                                                                            >
                                                                                Concluído
                                                                            </option>
                                                                        )}
                                                                    </Input>
                                                                </td>
                                                                <td align="center">
                                                                    {" "}
                                                                    <i
                                                                        onClick={() => {
                                                                            if (
                                                                                data?.status !=
                                                                                EXAMS_ORDER_STATUS.CANCELED
                                                                            ) {
                                                                                handleRemoveOption(
                                                                                    item?.id,
                                                                                    setFieldValue,
                                                                                    values
                                                                                )
                                                                            }
                                                                        }}
                                                                        className="fas fa-trash text-danger fa-lg"
                                                                        style={{
                                                                            position:
                                                                                "relative",
                                                                            top:
                                                                                "5px",
                                                                            cursor:
                                                                                "pointer",
                                                                        }}
                                                                    ></i>{" "}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {data?.status !=
                                    EXAMS_ORDER_STATUS.CANCELED ? (
                                        <Row>
                                            <Col align="end">
                                                <Button
                                                    disabled={submitLoading}
                                                    type="submit"
                                                    className="btn btn-info mr-2"
                                                >
                                                    {data?.id
                                                        ? "Atualizar"
                                                        : "Adicionar"}{" "}
                                                    {submitLoading ? (
                                                        <Spinner
                                                            color="white"
                                                            size="sm"
                                                        />
                                                    ) : null}
                                                </Button>
                                                <Button
                                                    disabled={submitLoading}
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        if (!submitLoading) {
                                                            onClose()
                                                        }
                                                    }}
                                                >
                                                    Fechar
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </>
                            ) : null}
                        </CardBody>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AddExamsOrdersModal
