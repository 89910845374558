import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Container, Row, Form, Input } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { search as permissionSearch} from "services/vitta-core/permissions"

import ListPermissionsColumns from "./ListPermissionsColumns"


const ListPermission = () => {

    const [PermissionList, setPermissionList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom:true,
        data: [],
    })

    const dispatch = useDispatch()

    const inputRefSearch = useRef();

    const defaultPageControl = {
        searching: false
    };

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async (page = 1) => {

        if(pageControl.searching) return;

        let sizePerPage = 10

        let termSearch = inputRefSearch?.current?.value;

        //Set is searching
        setPageControl({ ...pageControl, ...{ searching: true }});
        //Clear Data Table
        setPermissionList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: 0,
            custom:true,
            data: [],
        })

        let filter = {
            term: termSearch
        }

        try 
        {
            let data = await permissionSearch(filter, page, sizePerPage)

            setPermissionList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages*sizePerPage,
                custom:true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false }});
    }

    useEffect(() => {
        search(1)
    }, [])

    const handleTableChange = (type, { page }) => {
        if(type == "pagination")
        {
            search(page)
        }
    }


    const renderNoDataIndication = () => {
        
        if(pageControl.searching) return (
            <Row align="center" className="mt-5 mb-5">  
               <Col sm="12" className="mt-5 mb-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> 
               </Col>
            </Row>
        )
        else
        {
            return <Row className="mt-3 mb-3">  
                <Col sm="12" >
                    Não há registros que correspondam com a sua busca.
                </Col>
            </Row>
        };
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Permissões"
                        breadcrumbItem="Lista de Permissões"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            PermissionList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={PermissionList.data || []}
                                                columns={ListPermissionsColumns( 
                                                )}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                        <Col sm="8">
                                                                <Form onSubmit={(e) => { e.preventDefault(); search() }}>
                                                                <div className="search-box mr-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <Input 
                                                                            type="text" 
                                                                            innerRef={inputRefSearch}
                                                                        >
                                                                        </Input>
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                    
                                                                </div>
                                                                <Button  
                                                                    type="submit" 
                                                                    color="primary" 
                                                                >
                                                                    Buscar
                                                                </Button>
                                                                </Form>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div className="text-sm-right ">
                                                                    <Link to="/permissoes/adicionar">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                        >
                                                                            Adicionar
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        noDataIndication={ renderNoDataIndication }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListPermission
