import request from './config/request';

const login = async authData => {
    let response = await request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/login`,
        data: authData,
    })

    return response;
}

const logout = () => {
    
}

const getStoredAccess = () => {
    let authUser = localStorage.getItem("vittanet.identity")
    if (authUser) {
        authUser = JSON.parse(authUser)
        if (authUser.token) {
            return authUser
        } else {
            localStorage.removeItem("vittanet.identity")
        }
    }
    return null
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/users` +
        query,
        vtAuth: true
    })
}

const me = () => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/me`,
        vtAuth: true
    })
}

const create = async user_data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users`,
        data: user_data,
        vtAuth: true
    });
}

const update = async (id, user_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/${id}`,
        data: user_data,
        vtAuth: true
    });
}
const uploadImageUser = async (id,dataUser) => {
   
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/upload-image-user/${id}`,
        data:dataUser,
        vtAuth: true,
        headers:{
            'Content-Type':'multipart/form-data',
        }
    })
}

const deleteUser = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/${id}`,
        vtAuth: true
    });
}

const loadDataAddform = async () => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/add`,
        vtAuth: true
    });
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/${id}/edit`,
        vtAuth: true
    });
}

const updateUserPassword = async (id, user_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/users/${id}/update-password`,
        data: user_data,
        vtAuth: true
    });
}

export {
    getStoredAccess,
    login,
    logout,
    search,
    create,
    update,
    deleteUser,
    loadDataAddform,
    loadDataEditform,
    me,
    updateUserPassword,
    uploadImageUser
}
