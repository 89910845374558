import request from './config/request';

const search = () => {
    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/price-items`,
        vtAuth: true
    })
}

export {
    search
}
