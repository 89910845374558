import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"


const ListPriceItemsColumns = () => [
    
    
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "name",
        text: "Nome",
    },
    {
        dataField: "description",
        text: "Descrição",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => (
            <Badge
                className={`font-size-12 badge-soft-${row.status == 1 ? "success" : "danger"}`}
                pill
            >
                {row.status == 1 ? "Ativo" : "Inativo"}
            </Badge>
        ),
    },
   
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id,row) => (
            <>
                <Link to={"/servicos/editar/"+row.id} className="mr-3 text-primary">
                    <i className="mdi mdi-pencil-box-outline font-size-24 mr-3" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                        Editar
          </UncontrolledTooltip>
                </Link>
            </>
        ),
    },
]

export default ListPriceItemsColumns