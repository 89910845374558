import React, { useMemo, useState,useRef, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Label,
    Button,
    Card,
    Input,
    FormGroup,
   
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from 'react-redux';
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async";
import Select from "react-select"
import { Formik, Field, Form } from 'formik';
import SalesAnalysisReport from "pages/Reports/SalesAnalysis/report"
import { salesAnalysisReport } from "services/vitta-core/sales-order"
import debounce from "debounce-promise";

const today = new Date().toLocaleDateString('pt-BR');
const todayOnlyDate = today.substr(6,4) + "-" + today.substr(3,2) + "-" + today.substr(0,2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select {...field} {...props} onChange={option => setFieldValue(field.name, option)}/>
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect {...field} {...props} onChange={option => {setFieldValue(field.name, option);}}/>
)

const SalesAnalysisIndex = () => {

    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false
    });
    const [controlState, setControlState] = useState(false)
    
    const [firstPage, setFirstPage] = useState(true)

    useEffect(() => {
        if(!firstPage){
            setReportControl({
                show: false,
                filter: null,
                data: null
            }); 
            setFirstPage(true)
        }
    }, [firstPage]);

    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.auth.access?.user);
    const Scope = useSelector(state => state.scope)
   
    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []
  
        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadPatientsInputOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try
            {
                let response = await PatientServiceSearch({term: inputValue,fields:"id,name"}, 1, 25);

                let users_values = []
                users_values.push({ label:"Todos", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values);
            }
            catch(e)
            {
                console.error(e);
                return reject([]);
            }
        });
    }
    const debounceOptionsPatient = debounce(loadPatientsInputOptions,600)

    const onSubmit = async (data_form, actions) => {
       
        try
        {
            if(!data_form.unit_id || data_form.unit_id.length == 0) return dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Informe pelo menos uma Unidade Clínica.",
                    type: "error",
                    showConfirm: true,
                })
            )

            let auxData = { }
            auxData.patient_id = data_form.patient_id?.value ? data_form.patient_id?.value : null;
            auxData.unit_id = data_form.unit_id?.value ? data_form.unit_id?.value : null;
            auxData.start_created_at = data_form.start_created_at;
            auxData.end_created_at = data_form.end_created_at;
            auxData.report_source = data_form.report_source?.value ? data_form.report_source?.value : null;

            let unit_ids = [];
            for(let i = 0; i < data_form.unit_id.length; i++)
            {
                unit_ids.push(data_form.unit_id[i].value);
            }
            setControlState(true)
            let dataReport = await salesAnalysisReport(
                {
                    patient_id: auxData.patient_id,
                    unit_id: unit_ids,
                    start_created_at: auxData.start_created_at,
                    end_created_at: auxData.end_created_at,
                    report_source: auxData.report_source,
                }
            )

          
            setControlState(false)
            if(!dataReport)
            {               
                return ;
            }
    
            setReportControl({
                show: true,
                filter: data_form,
                data: dataReport
            });

        }
        catch (e){
            setControlState(false)
            console.log(e);
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: "Ocorreu algum problema, Preencha os campos corretamente e tente novamente.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    if(reportControl.show)
    {
       return <SalesAnalysisReport filter={reportControl.filter} data={reportControl.data} state={setFirstPage}/>
    }else{
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Relatórios"
                            breadcrumbItem="Análise de Vendas"
                        />
                        <Row  >
                            <Col xs="12">
                                <Row className="d-flex justify-content-center">
                                    <Col sm="6">
                                <Card  >
                                    <CardBody>
                                        <h3 align="center" className="mb-4 mt-4">Análise de Vendas</h3>
                                        <Formik
                                            initialValues={{ report_source: {
                                                label: "Vendido", value: "ORDERS"
                                            }, patient_id: null, unit_id: Scope.unit ? [{
                                                label: Scope.unit.name?.substr(0,40), value: Scope.unit.id
                                              }] : null, start_created_at:todayOnlyDate, end_created_at:todayOnlyDate, }}
                                            onSubmit={onSubmit}
                                        >
                                            {(formProps) => (
                                            <Form>
                                                <Row  className="d-flex justify-content-center">
                                                <Col sm="8">
                                                    <FormGroup>
                                                    <Label>Tipo Análise</Label>
                                                    <Field
                                                        name="report_source"
                                                        defaultValue={{
                                                            label: "Vendido", value: "ORDERS"
                                                        }}
                                                        options={[
                                                            {
                                                                label: "Vendido", value: "ORDERS"
                                                            },
                                                            {
                                                                label: "Faturado", value: "INVOICES"
                                                            },
                                                        ]}
                                                        component={ReactSelectInput}
                                                    /> 
                                                    </FormGroup>
                                         
                                                </Col>
                                                </Row>
                                            <Row  className="d-flex justify-content-center">
                                                <Col sm="8">
                                                <FormGroup>
                                                <Label>
                                                       Paciente
                                                    </Label>
                                                    <Field
                                                        name="patient_id"
                                                        placeholder="Selecionar Paciente"
                                                        cacheOptions
                                                        isClearable
                                                        defaultOptions 
                                                        defaultValue={formProps.patient}
                                                        loadOptions={debounceOptionsPatient}
                                                        component={AsyncSelectInput}
                                                    /> 
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-center">
                                                <Col sm="8">
                                                <FormGroup>
                                                    <Label>Unidade</Label>
                                                    <Field
                                                        name={`unit_id`}
                                                        placeholder="Selecione a Unidade"   
                                                        classNamePrefix="select2-selection"
                                                        options={unitsOptions}
                                                        isMulti={true}
                                                        component={ReactSelectInput}
                                                    />   
                                                    </FormGroup>    
                                                </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-center">
                                                <Col sm="8" >
                                                <FormGroup>
                                                    <Label>
                                                        Data inicial{" "}
                                                    </Label>
                                                    <Input 
                                                        onChange={formProps.handleChange}
                                                        onBlur={formProps.handleBlur}
                                                        name={"start_created_at"}
                                                        type="date"
                                                        defaultValue={formProps?.values?.start_created_at}>
                                                            
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-center">
                                                <Col sm="8">
                                                <FormGroup>
                                                    <Label>
                                                        {" "}
                                                        Data final
                                                    </Label>
                                                    <Input 
                                                        onChange={formProps.handleChange}
                                                        onBlur={formProps.handleBlur}
                                                        name={"end_created_at"}
                                                        type="date" 
                                                        defaultValue={formProps?.values?.end_created_at}>
                                                        
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-center">
                                                <Col
                                                    sm="2"
                                                    align="start"
                                                    className="mt-4"
                                                    >
                                                        {controlState ? (
                                                            <div
                                                                className="spinner-border text-dark"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                </Col>
                                                
                                                <Col sm="6" align="end" className="mt-4">
                                                    <Button type="submit" disabled = {controlState} className="btn btn-dark">Gerar Relatório
                                                    <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                    </Button>
                                                </Col>
                                                </Row>
                                          
                                            </Form>
                                            )}
                                        </Formik>  
                                    </CardBody>
                                </Card>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )

    }

    
}

export default SalesAnalysisIndex
