import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
} from "reactstrap"
import states from "../../../utils/states"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useHistory } from "react-router-dom"
import { loadDataEditform, create, update } from "services/vitta-core/unit"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

const CreateUnit = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({})

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                let data = await loadDataEditform(id)
                setPageData({
                    unit: data.unit,
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (id) loadDataEditPage()
    }, [])

    const handleSubmit = async (event, formData) => {
        try {
            if (id) {
                await update(id, formData)
            } else {
                await create(formData)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            history.push("/unidades")
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Unidades"
                        breadcrumbItem="Cadastro de unidades"
                    />
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={handleSubmit}
                    >
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle> Dados gerais</CardTitle>
                                        <CardSubtitle className="mb-5">
                                            É necessário preencher todas as
                                            informações
                                        </CardSubtitle>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="Nome *"
                                                    id="nome"
                                                    name="name"
                                                    className="form-control"
                                                    type="text"
                                                    errorMessage=" Preencha o campo Nome ."
                                                    value={pageData?.unit?.name}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            errorMessage:
                                                                "É necessário pelomenos 3 caracteres",
                                                        },
                                                    }}
                                                />
                                            </Col>

                                            <Col sm="6">
                                                <AvField
                                                    label="Razão social *"
                                                    id="social"
                                                    name="social_name"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            minLength: {
                                                                value: 3,
                                                                errorMessage:
                                                                    "É necessário pelomenos 3 caracteres",
                                                            },
                                                        },
                                                    }}
                                                    errorMessage="Preencha o campo Razão social."
                                                    value={
                                                        pageData?.unit
                                                            ?.social_name
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="CNPJ *"
                                                    id="CNPJ"
                                                    name="cnpj"
                                                    className="form-control"
                                                    type="text"
                                                    errorMessage=" Preencha o campo CNPJ."
                                                    value={pageData?.unit?.cnpj}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        minLength: {
                                                            value: 14,
                                                            errorMessage:
                                                                "É necessário digitar no mínimo 14 dígitos",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <AvField
                                                    className="form-control select2"
                                                    type="select"
                                                    id="selectstatus"
                                                    label="Status"
                                                    name="status"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage=" Preencha o campo Status."
                                                    value={
                                                        pageData?.unit
                                                            ?.status || 1
                                                    }
                                                >
                                                    <option value="1">
                                                        Ativo
                                                    </option>
                                                    <option value="0">
                                                        Inativo
                                                    </option>
                                                </AvField>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="Telefone *"
                                                    id="number"
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage=" Preencha o campo Telefone."
                                                    value={
                                                        pageData?.unit?.phone
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* FIM PRIMEIRO CARD */}

                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            Endereço da Unidade
                                        </CardTitle>
                                        <CardSubtitle className="mb-5">
                                            É necessário preencher todas as
                                            informações
                                        </CardSubtitle>

                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    className="form-control select2"
                                                    label="Estado *"
                                                    type="select"
                                                    name="state"
                                                    id="estados"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage=" Preencha o campo Estado."
                                                    value={
                                                        pageData?.unit?.state
                                                    }
                                                >
                                                    <option>Selecionar</option>
                                                    {states.map(state => (
                                                        <option
                                                            key={state.sigla}
                                                            value={state.sigla}
                                                        >
                                                            {state.estado}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </Col>

                                            <Col sm="6">
                                                <AvField
                                                    label="Cidade *"
                                                    id="cidade"
                                                    name="city"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            errorMessage:
                                                                "É necessário pelomenos 3 caracteres",
                                                        },
                                                    }}
                                                    value={pageData?.unit?.city}
                                                    errorMessage="Preencha o campo Cidade."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    label="Bairro ou distrito *"
                                                    id="bairro"
                                                    name="district"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            minLength: {
                                                                value: 3,
                                                                errorMessage:
                                                                    "É necessário pelomenos 3 caracteres",
                                                            },
                                                        },
                                                    }}
                                                    value={
                                                        pageData?.unit?.district
                                                    }
                                                    errorMessage="Preencha o campo Bairro."
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <AvField
                                                    label="Endereço *"
                                                    id="endereco"
                                                    name="address"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            errorMessage:
                                                                "É necessário pelomenos 3 caracteres",
                                                        },
                                                    }}
                                                    errorMessage="Preencha o campo Endereço"
                                                    value={
                                                        pageData?.unit?.address
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <AvField
                                                    id="codigocep"
                                                    label="CEP *"
                                                    type="number"
                                                    name="cep"
                                                    className="form-control"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            errorMessage:
                                                                "É necessário pelomenos 3 caracteres",
                                                        },
                                                    }}
                                                    errorMessage="Preencha o campo CEP."
                                                    value={pageData?.unit?.cep}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <div align="end">
                                    <Button color="primary" type="submit">
                                        Salvar
                                    </Button>
                                    <Link
                                        to="/unidades"
                                        className="btn btn-danger ml-1"
                                    >
                                        Cancelar
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateUnit
