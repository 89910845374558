import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from 'react-redux';
import { getStoredAccess } from "services/vitta-core/user"
import authUtils from "utils/auth"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  path,
  ...rest
}) => {

  //Depois trocar por analise do acess user do redux 
  const authUser = getStoredAccess();

  return (
    
    <Route
      {...rest}
      render={props => {

        if (isAuthProtected && !authUser) {
          console.log("ops user not loged");
          authUtils.removeStoredAccess();
          return window.location.href = "/login";
          /*return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )*/
        }

        if(path == "/login" && authUser) return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
        
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
