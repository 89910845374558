import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Input,
    Label,
    FormGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Badge,
    UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import Select from "react-select"
import NumberFormat from "react-number-format"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import OS_STATUS from "../../../utils/consts/os-status"
import debounce from "debounce-promise"
import {
    loadDataEditformOs,
    update,
    startOsItem,
    finishOsItem,
    reOpenOsItem,
} from "services/vitta-core/os"

const today = new Date().toLocaleDateString("pt-BR")
const defaultItemValues = {
    item_id: null,
    quantity: 1,
    price_table: 0,
    price: 0,
    exec_professional_id: null,
    exec_in:
        today.substr(6, 4) +
        "-" +
        today.substr(3, 2) +
        "-" +
        today.substr(0, 2),
}
var defaultValues = {
    patient_id: null,
    obs: "",
    items: [defaultItemValues],
}
const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="textarea" rows="1" {...field} {...props} />

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />
const TotalValueInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const [total, setTotal] = useState(0)

    return (
        <NumberFormat
            {...field}
            {...props}
            className={props.className + " bg-light"}
            onChange={e => {
                /*only to not dispatch this event*/
            }}
        />
    )
}
const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const AsyncSelectInputItemService = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
            setFieldValue("items[" + props.input_item_index + "].voucher", null) //Clear input voucher on change item
            if (
                option &&
                option.item &&
                option.item.price &&
                option.item.price > 0
            ) {
                setFieldValue(props.price_input, option.item.price / 100)
                setFieldValue(
                    "items[" + props.input_item_index + "].price_table",
                    option.item.price / 100
                )
            }
            if (
                option &&
                option.item.group_id == process.env.REACT_APP_VOUCHER_GROUP_ID
            ) {
                props.setShowVoucherItemDetail({
                    show: true,
                    item_id: option.item.id,
                    voucher: null,
                    form_item_index: props.input_item_index,
                })
            }
        }}
    />
)
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)
const DetailOrderService = () => {
    const [pageData, setPageData] = useState({
        values: defaultValues,
    })
    const [finish, setFinishData] = useState(false)
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const Scope = useSelector(state => state.scope)

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"  },
                    1,
                    10
                )
                let patients_values = []
                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })
                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadItemInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await itemServiceSearch(
                    { term: inputValue },
                    1,
                    10
                )
                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                        item: element,
                    })
                })
                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    10
                )
                let items_values = []
                items_values.push({
                    label: "Nenhum",
                    value: null,
                })
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })
                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )

    const startOrderServiceItem = async (data, type) => {
        let dataSubmit = {
            itemId: data?.itemId,
            type: type,
        }
        try {
            await startOsItem(id, dataSubmit)

            setFinishData({ show: true })
            dispatch(
                showToast({
                    text: "Ok!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
        }
    }
    const finishOrderServiceItem = async (data, type) => {
        let dataSubmit = {
            itemId: data?.itemId,
            type: type,
        }
        try {
            await finishOsItem(id, dataSubmit)

            setFinishData({ show: true })
            dispatch(
                showToast({
                    text: "Ok!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
        }
    }

    const reOpenOrderServiceItem = async (data, type) => {
        let dataSubmit = {
            itemId: data?.itemId,
            type: type,
        }
        try {
            await reOpenOsItem(id, dataSubmit)

            setFinishData(true)
            setFinishData(false)

            dispatch(
                showToast({
                    text: "Ok!",
                    type: "success",
                })
            )
        } catch (e) {
            if (e.response.data.error_code === "register_status_open") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Para reabrir o serviço é necessário cancelar a nota de repasse.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            console.log(e)
        }
    }
    const onSubmit = async (data_form, status) => {
        let data = JSON.parse(JSON.stringify(data_form))

        let dataSubmit = []
        try {
            for (let i = 0; i < data.items.length; i++) {
                if (
                    !data.items[i].exec_in ||
                    data.items[i].exec_in.length < 10
                ) {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Ops... ",
                            text:
                                "Informe a data de execução de todos os items",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }

                dataSubmit.push({
                    exec_professional_id: data.items[i].exec_professional_id
                        ? data.items[i].exec_professional_id.value
                        : null,
                    item_id: data.items[i].item_id.value,
                    exec_in: data.items[i].exec_in,
                    itemId: data?.items[i]?.itemId,
                })
            }

            await update(id, dataSubmit)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Salvo!",
                    type: "success",
                })
            )

            history.push("/ordens-servico")
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "os_canceled":
                        textError =
                            "Esta OS está cancelada e não pode ser mais alterada."
                        break
                    case "item_not_exists":
                        textError =
                            "Item informado não encontrado no sistema. " +
                            (e.response.data.error_code_detail
                                ? "ID: " + e.response.data.error_code_detail
                                : "")
                        break
                    case "professional_not_exists":
                        textError =
                            "Profissional informado não encontrado no sistema. " +
                            (e.response.data.error_code_detail
                                ? "ID: " + e.response.data.error_code_detail
                                : "")
                        break
                    case "date_exec_invalid":
                        textError =
                            "Data de execução inválida, digite uma data superior ou igual à data atual."
                        break
                    case "sales_invoice_already_created":
                        textError =
                            "Nota fiscal da Clínica já gerada. Realize o cancelamento antes de alterar a OS."
                        break
                    case "prof_transfer_invoice_already_created":
                        textError =
                            "Nota de Repasse Médico já gerada. Realize o cancelamento antes de alterar a OS."
                        break
                }
            }

            console.log(e)

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const loadDataEditPage = async () => {
        let total_calc = 0

        try {
            let data = await loadDataEditformOs(id)

            let items = []
            for (let i = 0; i < data.data.items.length; i++) {
                items.push({
                    id: data.data.items[i].id,
                    item_id: {
                        value: data.data.items[i].item.id,
                        label: data.data.items[i].item.name,
                    },
                    quantity: data.data.items[i].quantity,
                    exec_professional_id: {
                        value: data?.data?.items[i]?.exec_professional?.id,
                        label: data?.data?.items[i]?.exec_professional?.name,
                    },
                    exec_in: data?.data?.items[i]?.exec_in?.substr(0, 10),
                    exec_started_at: data.data.items[i].exec_started_at
                        ? data.data.items[i].exec_started_at.substr(0, 10)
                        : null,
                    exec_finished_at: data.data.items[i].exec_finished_at
                        ? data.data.items[i].exec_finished_at.substr(0, 10)
                        : null,
                    itemId: data?.data?.items[i]?.id,
                })

                total_calc =
                    total_calc +
                    (data.data.items[i].quantity * data.data.items[i].price) /
                        100
            }
            setPageData({
                values: {
                    id: data?.data?.id,
                    patient_id: {
                        value: data?.data?.patient?.id,
                        label: data?.data?.patient?.name,
                    },
                    obs: data?.data?.obs,
                    total_value: total_calc,
                    sales_order_id: data?.data?.sales_order_id,
                    items: items,
                    status: data.data.status ? data.data.status : null,
                },
            })
        } catch (e) {
            if (e?.response?.status == 403) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: "Acesso não autorizado ! ",
                        type: "error",
                        showConfirm: true,
                    })
                )
                history.push("/ordens-servico")
            }
            console.log(e)
        }
    }

    useEffect(() => {
        if (id) loadDataEditPage()
    }, [finish])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Serviço"}
                        breadcrumbItem={"Ordem de Serviço"}
                    />
                    <Formik
                        initialValues={pageData.values}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            isSubmitting,
                            dirty,
                            setFieldValue,
                            submitForm,
                        }) => (
                            <Form>
                                <Row>
                                    <Col xs="12">
                                        <Card>
                                            <CardBody>
                                                <CardTitle></CardTitle>
                                                <Row>
                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label>ID</Label>
                                                            <FastField
                                                                name={`id`}
                                                                value={
                                                                    values.id
                                                                }
                                                                component={
                                                                    TextInput
                                                                }
                                                                readOnly={true}
                                                                disabled={true}
                                                                isDisabled={
                                                                    true
                                                                }
                                                                className="bg-light"
                                                                style={{
                                                                    paddingLeft:
                                                                        "5px",
                                                                    paddingRight:
                                                                        "0px",
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="5">
                                                        <FormGroup>
                                                            <Label>
                                                                Paciente
                                                            </Label>
                                                            <FastField
                                                                name="patient_id"
                                                                placeholder="Selecionar Paciente"
                                                                cacheOptions
                                                                defaultOptions
                                                                isClearable
                                                                defaultValue={
                                                                    values.patient_id
                                                                }
                                                                loadOptions={
                                                                    loadPatientInputOptions
                                                                }
                                                                component={
                                                                    AsyncSelectInput
                                                                }
                                                                isDisabled={
                                                                    id
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        <Col sm="6" align="">
                                                            <FormGroup>
                                                                <Label>
                                                                    Status OS{" "}
                                                                </Label>
                                                                <div>
                                                                    {values.status &&
                                                                    values.status ===
                                                                        OS_STATUS.AWAITING_EXECUTION ? (
                                                                        <Badge className="badge-soft-warning">
                                                                            {/*Aguardando
                                                                        Execução{" "}*/}
                                                                            Paga
                                                                            <i className="fas fa-chalkboard-teacher fa-lg"></i>
                                                                        </Badge>
                                                                    ) : null}
                                                                    {values.status &&
                                                                    values.status ===
                                                                        OS_STATUS.IN_EXECUTION ? (
                                                                        <Badge className="badge-soft-warning">
                                                                            {/*Em
                                                                        Execução{" "} //Depois voltar os status normais quando existir fluxo de execução */}
                                                                            Paga
                                                                            <i className="fas fa-chalkboard-teacher fa-lg"></i>
                                                                        </Badge>
                                                                    ) : null}

                                                                    {values.status &&
                                                                    values.status ===
                                                                        OS_STATUS.COMPLETED ? (
                                                                        <Badge className="badge-soft-success">
                                                                            Concluído{" "}
                                                                            <i className="fas fa-chalkboard-teacher fa-lg"></i>
                                                                        </Badge>
                                                                    ) : null}
                                                                    {values.status &&
                                                                    values.status ===
                                                                        OS_STATUS.CANCELED ? (
                                                                        <Badge className="badge-soft-danger">
                                                                            Cancelado{" "}
                                                                            <i className="fas fa-chalkboard-teacher fa-lg"></i>
                                                                        </Badge>
                                                                    ) : null}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                </Row>

                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label>
                                                                Observações
                                                            </Label>
                                                            <FastField
                                                                name="obs"
                                                                rows={2}
                                                                component={
                                                                    TextAreaInput
                                                                }
                                                                readOnly={
                                                                    id
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col
                                                        md="4"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <FormGroup>
                                                            {values.sales_order_id ? (
                                                                <Link
                                                                    to={
                                                                        "/pedidos/" +
                                                                        values.sales_order_id
                                                                    }
                                                                    className="text-primary"
                                                                    target="_blank"
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            console.log()
                                                                        }}
                                                                    >
                                                                        Pedido
                                                                        de Venda
                                                                    </Button>
                                                                </Link>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>
                                                    Itens da OS
                                                </CardTitle>
                                                <CardSubtitle className=""></CardSubtitle>
                                                <br />
                                                <Row
                                                    style={{
                                                        paddingLeft: "8px",
                                                    }}
                                                >
                                                    {" "}
                                                    {/* Visível somente em tamanhos de tela md, lg, xl*/}
                                                    <Col
                                                        xs="12"
                                                        md="1"
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Item ID</Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md="3"
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Serviço</Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md="1"
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Quant.</Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md="2"
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>
                                                            Data Execução
                                                        </Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md="2"
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>
                                                            Profissional Exec.
                                                        </Label>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    {/* fim item fixo */}
                                                    <Col
                                                        lg="12"
                                                        className="mt-2"
                                                    >
                                                        <FieldArray
                                                            name="items"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {values &&
                                                                    values.items
                                                                        .length >
                                                                        0
                                                                        ? values.items.map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => (
                                                                                  <Row
                                                                                      key={
                                                                                          index
                                                                                      }
                                                                                      className="align-items-center"
                                                                                      style={{
                                                                                          paddingLeft:
                                                                                              "12px",
                                                                                      }}
                                                                                  >
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="1"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Item
                                                                                                  ID
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].id`}
                                                                                                  value={
                                                                                                      item.id
                                                                                                  }
                                                                                                  component={
                                                                                                      TextInput
                                                                                                  }
                                                                                                  readOnly={
                                                                                                      true
                                                                                                  }
                                                                                                  disabled={
                                                                                                      true
                                                                                                  }
                                                                                                  isDisabled={
                                                                                                      true
                                                                                                  }
                                                                                                  className="bg-light"
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="3"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup
                                                                                              style={{
                                                                                                  fontSize:
                                                                                                      "0.7rem",
                                                                                              }}
                                                                                          >
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Serviço
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].item_id`}
                                                                                                  placeholder="Selecione o Item"
                                                                                                  cacheOptions
                                                                                                  defaultOptions
                                                                                                  loadOptions={
                                                                                                      loadItemInputOptions
                                                                                                  }
                                                                                                  classNamePrefix="select2-selection"
                                                                                                  defaultValue={
                                                                                                      item.item_id
                                                                                                  }
                                                                                                  component={
                                                                                                      AsyncSelectInputItemService
                                                                                                  }
                                                                                                  price_input={`items[${index}].price`}
                                                                                                  isDisabled={
                                                                                                      id
                                                                                                          ? true
                                                                                                          : false
                                                                                                  }
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="1"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Quantidade
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].quantity`}
                                                                                                  defaultValue={
                                                                                                      item.quantity
                                                                                                  }
                                                                                                  component={
                                                                                                      NumberInput
                                                                                                  }
                                                                                                  className={`form-control bg-light`}
                                                                                                  readOnly={
                                                                                                      true
                                                                                                  }
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="2"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Data
                                                                                                  Execução
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].exec_in`}
                                                                                                  defaultValue={
                                                                                                      item.exec_in
                                                                                                  }
                                                                                                  disabled={
                                                                                                      item.exec_finished_at !=
                                                                                                          null ||
                                                                                                      values?.status ===
                                                                                                          OS_STATUS.CANCELED
                                                                                                          ? true
                                                                                                          : false
                                                                                                  }
                                                                                                  component={
                                                                                                      DateInput
                                                                                                  }
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="2"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup
                                                                                              style={{
                                                                                                  fontSize:
                                                                                                      "0.7rem",
                                                                                              }}
                                                                                          >
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Profissional
                                                                                                  Executor
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].exec_professional_id`}
                                                                                                  placeholder="Profissional"
                                                                                                  cacheOptions
                                                                                                  defaultOptions
                                                                                                  isClearable
                                                                                                  loadOptions={
                                                                                                      debounceOptionsProfessional
                                                                                                  }
                                                                                                  classNamePrefix="select2-selection"
                                                                                                  defaultValue={
                                                                                                      item.exec_professional_id
                                                                                                  }
                                                                                                  component={
                                                                                                      AsyncSelectInput
                                                                                                  }
                                                                                                  isDisabled={
                                                                                                      item.exec_finished_at !=
                                                                                                          null ||
                                                                                                      values?.status ===
                                                                                                          OS_STATUS.CANCELED
                                                                                                          ? true
                                                                                                          : false
                                                                                                  }
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>

                                                                                      {/*                                                                                       
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="1"
                                                                                          style={{
                                                                                            paddingLeft: "3px", 
                                                                                            paddingRight: "0px"
                                                                                         }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              {item?.exec_started_at ===
                                                                                              null ? (
                                                                                                  <div>
                                                                                                      <Button
                                                                                                      color="dark"
                                                                                                      type="button"
                                                                                                      disabled={true}
                                                                                                        //   onClick={() => {
                                                                                                        //       startOrderServiceItem(
                                                                                                        //           item,
                                                                                                        //           "iniciar"
                                                                                                        //       )
                                                                                                        //   }}
                                                                                                      >
                                                                                                          Iniciar
                                                                                                      </Button>
                                                                                                  </div>
                                                                                              ) : (
                                                                                                  <Button
                                                                                                    //   onClick={() => {
                                                                                                    //       finishOrderServiceItem(
                                                                                                    //           item,
                                                                                                    //           "finalizar"
                                                                                                    //       )
                                                                                                    //   }}
                                                                                                      disabled={
                                                                                                          item.exec_finished_at !=
                                                                                                              null ||
                                                                                                          values?.status ===
                                                                                                              OS_STATUS.CANCELED
                                                                                                              ? true
                                                                                                              : false
                                                                                                      }
                                                                                                  >
                                                                                                      {item.exec_finished_at !=
                                                                                                      null
                                                                                                          ? "Finalizado"
                                                                                                          : "Finalizar"}
                                                                                                  </Button>
                                                                                              )}
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                         */}

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="1"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              {" "}
                                                                                              {item.exec_finished_at !=
                                                                                              null ? (
                                                                                                  <Button
                                                                                                      type="button"
                                                                                                      color="dark"
                                                                                                      className="ml-2"
                                                                                                      disabled={
                                                                                                          true
                                                                                                      }
                                                                                                  >
                                                                                                      Finalizado
                                                                                                  </Button>
                                                                                              ) : (
                                                                                                  ""
                                                                                              )}
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                      <Col
                                                                                          xs="12"
                                                                                          sm="1"
                                                                                      >
                                                                                          <FormGroup>
                                                                                              {" "}
                                                                                              {item.exec_finished_at !=
                                                                                              null ? (
                                                                                                  <Button
                                                                                                      name="reopen"
                                                                                                      type="button"
                                                                                                      color="primary"
                                                                                                      onClick={() => {
                                                                                                          reOpenOrderServiceItem(
                                                                                                              item,
                                                                                                              "reabrir"
                                                                                                          )
                                                                                                      }}
                                                                                                  >
                                                                                                      Reabrir
                                                                                                  </Button>
                                                                                              ) : (
                                                                                                  ""
                                                                                              )}
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                  </Row>
                                                                              )
                                                                          )
                                                                        : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>
                                        <Row>
                                            <Col lg="6">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            {" "}
                                                            Resumo
                                                        </CardTitle>
                                                        <CardSubtitle className="mb-2"></CardSubtitle>
                                                        <br />
                                                        <Row>
                                                            <Col lg="6">
                                                                <Label>
                                                                    Valor Total
                                                                </Label>
                                                                <FastField
                                                                    name={
                                                                        "total_value"
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        "."
                                                                    }
                                                                    decimalSeparator={
                                                                        ","
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        true
                                                                    }
                                                                    allowEmptyFormatting={
                                                                        true
                                                                    }
                                                                    prefix={
                                                                        "R$ "
                                                                    }
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        values.total_value
                                                                    }
                                                                    component={
                                                                        TotalValueInput
                                                                    }
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div align="end">
                                    <Link
                                        to="/ordens-servico"
                                        className="btn btn-danger ml-2"
                                        disabled={isSubmitting}
                                    >
                                        Sair
                                    </Link>
                                    {values?.status === OS_STATUS.CANCELED ||
                                    values?.status === OS_STATUS.COMPLETED ? (
                                        ""
                                    ) : (
                                        <Button
                                            color="primary"
                                            type="submit"
                                            className="ml-2"
                                            disabled={isSubmitting || !dirty}
                                        >
                                            Salvar
                                        </Button>
                                    )}
                                </div>
                                <br />
                            </Form>
                        )}
                    </Formik>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default DetailOrderService
