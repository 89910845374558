import { call, put, takeEvery } from "redux-saga/effects"

import { USER_LOGIN, USER_LOGOUT } from "./actionTypes"
import { userLoginSuccess, userLoginFailure, userLogoutSuccess} from "./actions"
import { setUnitScope } from "store/scope/actions"

import { push } from 'connected-react-router'

import { login as userServiceLogin, logout as userServiceLogout } from "services/vitta-core/user"

function* sagaUserLogin(action) 
{
  try 
  {
    localStorage.clear()
    let result = yield call(userServiceLogin, action.payload);

    if(result.user.units.length == 0) return yield put(userLoginFailure("Usuário sem acesso a unidades."))

    localStorage.setItem("vittanet.identity", JSON.stringify(result))

    yield put(userLoginSuccess(result))
    yield put(push('/dashboard'));

  } catch (e) {
    let errorMessage = "Ops.. Tente novamente mais tarde."
    if(e.response && e.response.status == 401) errorMessage = "Usuário ou Senha inválidos.";
    yield put(userLoginFailure(errorMessage))
  }
}

function* sagaUserLogout() 
{
  try {

    // localStorage.removeItem("vittanet.identity")
    // localStorage.removeItem("vittanet.scope.unit")
    localStorage.clear()
    yield put(userLogoutSuccess())
    yield put(setUnitScope(null))
    yield put(push('/login'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
}

function* authSaga() 
{
  yield takeEvery(USER_LOGIN, sagaUserLogin)
  yield takeEvery(USER_LOGOUT, sagaUserLogout)
}

export default authSaga
