import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"
const dateFormat = (date) => {
    if (!date) return null;
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
};

const ListReceivablesColumns = (setModal) => [

    {
        dataField: "expected_on",
        text: "Data Recebimento",
        formatter: (cellContent, row) => {
            return row.status == 'LIQUIDATED'?dateFormat(row?.paid_at):dateFormat(row?.expected_on)
        },
    },
    // {
    //     isDummyField: true,
    //     text: "Parcela",
    //     formatter: (id, row) => {
    //         return <span>{row?.installment ? row?.installment : 1}</span>
    //     },
    // },
    {
        isDummyField: true,
        text: "Valor",
        formatter: (cellContent, row) => (
            <div className="text-body">
                {(row?.amount / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </div>
        ),
    },
    {
        text: "Ped. Venda",
        formatter: (cellContent, row) => (
            <>
                {row?.recipient?.payment_info
                    ?.map((payment, index) => (
                        <Link
                            key={index}
                            to={"/pedidos/" + payment?.sales_order?.id}
                            target={"_blank"}
                            className="mr-3 text-primary"
                        >
                            # {payment?.sales_order?.id}
                        </Link>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
            </>
        ),
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            const status = row.status == 'LIQUIDATED'?{ label: 'Recebido', className: "badge-soft-success" } :{ label: 'Pendente', className: "badge-soft-warning" };
            return (
                <Badge className={status.className}>
                    {status.label}
                </Badge>
            );
        }
        
    },
    {
        text: "Paciente",
        formatter: (cellContent, row) => {
            return (
                <span>
                    {row?.recipient?.payment_info?.[0]?.sales_order?.patient?.name}
                </span>
            );
        },
    },

    {
        dataField: "paid_at",
        text: "Data Pagamento",
        formatter: (cellContent, row) => {
            return dateFormat(row?.recipient?.sales_transaction?.created_at)
        },
    },
    {
        isDummyField: true,
        text: "Tipo Pagamento",
        formatter: (id, row) => {
            let paymentName;
            switch (row?.recipient?.sales_transaction?.payment_type) {
                case "R$":
                    paymentName = "Dinheiro";
                    break;
                case "PIX":
                    paymentName = "PIX";
                    break;
                case "CC":
                    paymentName = "Cartão de crédito";
                    break;
                case "CD":
                    paymentName = "Cartão de débito";
                    break;
                default:
                    paymentName = "Outro";
                    break;
            }
            return <span>{paymentName}</span>;
        }        
    },
    // {
    //     isDummyField: true,
    //     text: "Ações",
    //     formatter: (id, row) => (
    //         <div className="text-center">
    //             <Link
    //                 to="#"
    //                 className="text-primary"
    //                 onClick={() => {
    //                     setModal({ show: true, data: row })
    //                 }}
    //             >
    //                 <i className="far fa-eye font-size-24" id={"eye" + row.id}></i>
    //                 <UncontrolledTooltip placement="top" target={"eye" + row.id}>
    //                     Detalhes
    //                 </UncontrolledTooltip>
    //             </Link>
    //         </div>
    //     ),
    // },
]

export default ListReceivablesColumns