import React from "react"
import { FastField } from "formik"
import Select from "react-select"
import { Row, Col, Label } from "reactstrap"

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
        isDisabled={props.disabled}
    />
)

const SelectComponent = ({ label, name, options, informed, disabled }) => {
    return (
        <Row>
            <Col sm="12">
                <Label>{label}</Label>
                <FastField
                    placeholder=""
                    name={name}
                    component={ReactSelectInput}
                    defaultValue={
                        informed
                            ? {
                                  label: informed?.objectData?.label,
                                  value: informed?.objectData?.value,
                              }
                            : null
                    }
                    noOptionsMessage={() =>
                        "Sem Resultados. Digite para Buscar"
                    }
                    options={options}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}

export default SelectComponent
