import React from "react"
import { Row, Col, Modal, Label } from "reactstrap"
import NumberFormat from "react-number-format"
import { Formik, Form, FastField } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import checkPermission from "utils/auth"

const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const HandlePricingAllItems = ({ show, data, refreshItemsForm, onClose }) => {
    const dispatch = useDispatch()
    const User = useSelector(state => state.auth.access.user)
    const defaultValues = {
        pricing_percent: 0,
    }

    const confirmChangeData = data_form => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja executar esta ação?",
                type: "warning",
                onConfirmAction: () => {
                    onSubmit(data_form)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const onSubmit = async data_form => {
        try {
            let unitSupervisor = checkPermission.hasPermission(User,"request_sales_order_price_approval")
            
            if (data_form?.pricing_percent <= 0) {
                dispatch(
                    showSweetAlertDialog({
                        title: "É necessário digitar um valor maior que 0.",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            if (
                data_form?.pricing_percent > 5 &&
                !data?.patient_id?.employee_relation
                && !unitSupervisor
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "O Valor máximo é de 5%.",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            if (data_form?.pricing_percent > 100) {
                dispatch(
                    showSweetAlertDialog({
                        title: "O Valor máximo é de 100%.",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }

            let updatedItems = []
            if (data && data.items?.length > 0) {
                for (let i = 0; i < data.items.length; i++) {
                    let item = data.items[i]

                    let item_group_id = item?.item_id?.item?.group_id
                        ? item?.item_id?.item?.group_id.toString()
                        : null

                    if (
                        ![
                            process.env
                                .REACT_APP_INTERNAL_PROCEDIMENTS_GROUP_ID,
                            process.env.REACT_APP_INTERNAL_EXAMS_GROUP_ID,
                            process.env.REACT_APP_PACKET_EXAMS_GROUP_ID,
                            process.env.REACT_APP_LAB_EXAMS_GROUP_ID,
                            process.env.REACT_APP_VACCINES_GROUP_ID,
                        ].includes(item_group_id) &&
                        !data?.patient_id?.employee_relation
                    ) {
                        updatedItems.push(item)
                        continue
                    }

                    let updatedItem = { ...item }
                    let newPricing = [...updatedItem?.pricing]
                    let newPrice = 0
                    let discount =
                        (updatedItem?.price_table *
                            data_form?.pricing_percent) /
                        100

                    if (item?.item_id) {
                        newPricing.push({
                            level: "item",
                            origin: "user",
                            origin_type: "user",
                            type: "DESC",
                            value: Math.trunc(discount * 100),
                        })
                    }

                    newPrice = updatedItem?.price - discount
                    updatedItem.price = newPrice
                    updatedItem.pricing = newPricing
                    updatedItems.push(updatedItem)
                }
            }
            refreshItemsForm(updatedItems)

            // dispatch(
            //     showToast({
            //         title: "Sucesso",
            //         text: "As alterações foram realizaadas!",
            //         type: "success",
            //     })
            // )
            onClose()
        } catch (e) {
            console.log(e)
            if (e.response.data.error_code === "executed_item") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse item já foi executado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    return (
        <Modal isOpen={show} centered={true}>
            <Formik
                initialValues={defaultValues}
                enableReinitialize={true}
                onSubmit={confirmChangeData}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Aplicar desconto em todos Itens
                                <div>
                                    <small className="text-info">
                                        {" "}
                                        Observações:
                                    </small>
                                    <br />
                                    <small className="text-info">
                                        * Até 5% de desconto em exames e
                                        procedimentos internos.
                                    </small>
                                    <br />
                                    {/* <small className="text-info">
                                        * Até 100% de desconto em
                                        cirurgias. (coordenadores de
                                        unidade)
                                    </small> */}
                                </div>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="12">
                                    <Label>Porcentagem a ser descontada</Label>
                                    <FastField
                                        name="pricing_percent"
                                        defaultValue={values?.pricing_percent}
                                        displayType={"input"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        suffix={" %"}
                                        className="form-control"
                                        component={CurrencyInput}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default HandlePricingAllItems
