import { Formik, Field, Form } from "formik"
import { useDispatch } from "react-redux"
import { Modal, Row, Col, Label, Input, Button } from "reactstrap"
import { showSweetAlertDialog } from "store/actions"

const numberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />

const weekday = [
    "segunda", "terca", "quarta", "quinta", "sexta", "sabado", "domingo"
]
const defaultPeriodsAge = {
    service_id: null,
    max_age_years: 0,
    min_age_years: 0,
    max_age_months: 0,
    min_age_months: 0,
}


const ModalServerAdd = ({ show, onClose, data }) => {
    const dispatch = useDispatch()

    const onSubmit = form_data => {
        let formSubmit = JSON.parse(JSON.stringify(form_data))
        if (formSubmit.max_age_months <= 0 && formSubmit.min_age_years <= 0 && formSubmit.max_age_years <= 0 && formSubmit.min_age_months <= 0 ||
            formSubmit.max_age_months < 0 || formSubmit.min_age_years < 0 || formSubmit.max_age_years < 0 || formSubmit.min_age_months < 0) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Não é permitido idades negativas ou todas nulas",
                    type: "error",
                })
            )
        }
        return dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Todas as configurações de idade existentes serão removidas e serão subistituídas pela nova configuração de idade para todos os serviços da agenda. Deseja confirmar o procedimento?",
                type: "warning",
                onConfirmAction: () => {
                    handlesubmit(formSubmit)
                },
                showCancel: true,
                showConfirm: true,
                confirmBtnText: "Sim",
                cancelBtnText: "Não",

            })
        )

    }
    const handlesubmit = form => {
        if (data?.age?.length > 0) data.age.splice(0, data.age.length)//data.age =[] nao apagava o array original
        //console.log(data)
        //periods.diadasemana[0].services[0].service_id
        for (let i = 0; i < weekday.length; i++) {
            for (let x = 0; x < data.periods[weekday[i]].length; x++) {
                for (let y = 0; y < data.periods[weekday[i]][x].services.length; y++) {
                    if(!data.age.find(e => e.service_id.value == data.periods[weekday[i]][x].services[y].service_id.value)){
                        //ArraySaida.push(data.periods[weekday[i]][x].services[y].service_id)
                        form = { ...form, service_id: data.periods[weekday[i]][x].services[y].service_id }
                        data.age.push(form)
                    }
                        
                }
            }
        }
        /*const SaidaSemRep = new Map()
        ArraySaida.forEach((ArraySaida)=>{
            if(!SaidaSemRep.has(ArraySaida.value)){
                SaidaSemRep.set(ArraySaida.value, ArraySaida)
            }
        })*/
        /*for (let i = 0; i < ArraySaida.length; i++) {
            if(ArraySaida[i].label.includes("-")) ArraySaida[i].label = ArraySaida[i].label.substr(4,ArraySaida[i].label.length)
            
           
        }*/
        onClose()
        return
    }

    return (
        <Formik
            initialValues={defaultPeriodsAge}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({ values }) => (
                <Modal
                    size="lg"
                    isOpen={show}
                    centered={true}
                >
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Adicione uma configuração de idade para todos os serviços desta agenda.
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col
                                    sm="3"
                                    className="mt-3"
                                >
                                    <Label>
                                        Idade
                                        mínima
                                        anos
                                    </Label>
                                    <Field
                                        placeholder="Idade mín. ano"
                                        name={"min_age_years"}
                                        component={
                                            numberInput
                                        }
                                    />
                                </Col>
                                <Col
                                    sm="3"
                                    className="mt-3"
                                >
                                    <Label>
                                        Idade
                                        mínima
                                        meses
                                    </Label>
                                    <Field
                                        placeholder="Idade mín. meses"
                                        name={"min_age_months"}
                                        component={
                                            numberInput
                                        }
                                    />
                                </Col>
                                <Col
                                    sm="3"
                                    className="mt-3"
                                >
                                    <Label>
                                        Idade
                                        máxima
                                        anos
                                    </Label>
                                    <Field
                                        placeholder="Idade máx. ano"
                                        name={"max_age_years"}
                                        component={
                                            numberInput
                                        }
                                    />
                                </Col>
                                <Col
                                    sm="3"
                                    className="mt-3"
                                >
                                    <Label>
                                        Idade
                                        máxima
                                        meses
                                    </Label>
                                    <Field
                                        placeholder="Idade máx. meses"
                                        name={"max_age_months"}
                                        component={
                                            numberInput
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <Button
                                type="submit"
                                data-dismiss="modal"
                                color="primary"
                            >
                                Salvar
                            </Button>

                            <Button
                                onClick={() => {
                                    onClose()
                                }}
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                            >
                                Cancelar
                            </Button>
                        </div>
                    </Form>

                </Modal>
            )}
        </Formik>
    )
}

export default ModalServerAdd