import React from "react"
import {
    Row,
    Col,
    Modal,
    Label,
    Card,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    CardText,
    Nav,
    Container,
    CardBody,
    Input,
    CardTitle,
    CardSubtitle,
    Button,
    Form,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useParams } from "react-router-dom"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { search as materialSearch } from "services/vitta-core/material"
import { search as preservativeSearch } from "services/vitta-core/preservative"
import { useState } from "react"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { create, loadDataEditform, update, getCompleteItems } from "services/vitta-core/partner"
import { useEffect } from "react"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import states from "../../../utils/states"
import NumberFormat from "react-number-format"
import debounce from "debounce-promise"
import TYPE_PARTNERS from "utils/consts/type-partners"
import ModalLogs from "pages/AuditLogs/LogsModal/Modal"

const defaultitemValues = {
    id: null,
    item_id: null,
    partner_item_code: null,
    partner_item_name: "",
    price: 0,
    exams_preservative_id: null,
    exams_material_id: null,
    exec_duration: 0
}
var defaultValues = {
    services: [defaultitemValues],
    name: null,
    social_name: null,
    type: {
        label: "Exames Laboratoriais",
        value: TYPE_PARTNERS?.EXAMS_LABORATORY,
    },
    cnpj: null,
    phone: null,
    email: null,
    addr_state: null,
    addr_city: null,
    addr_district: null,
    addr_address: null,
    addr_number: null,
    addr_complement: null,
    addr_cep: null,
}

const RegistrationPartners = () => {
    const { id } = useParams()
    const history = useHistory()

    const dispatch = useDispatch()
    const [activeTab, setactiveTab] = useState("1")
    const [controlState, setControlState] = useState(false)
    const [pageData, setPageData] = useState({})
    const [modalLogs, setModalLogs] = useState({
        show: false,
        data: null,
    })
    const [typeControl, setTypeControl] = useState(
        TYPE_PARTNERS?.EXAMS_LABORATORY
    )
    const Scope = useSelector(state => state.scope)
    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: defaultValues,
    })

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "services",
    })

    const LoadGetItems = async () => {
        setControlState(true)
       
        if (fields.length === 1 && fields.find(e => e.item_id === null)) {
            let ServicesForm = []
            try {
                let data = await getCompleteItems(id);
                for (let i = 0; i < data?.services?.length; i++) {
                    let useService = data?.services[i];
                    if(!useService) continue;
                    ServicesForm.push({
                        id: useService?.id,
                        item_id: {
                            label: useService?.item?.name,
                            value: useService?.item_id,
                        },

                        partner_item_code: useService?.partner_item_code,
                        partner_item_name: useService?.partner_item_name,
                        exams_material_id: {
                            label: useService?.material?.name,
                            value: useService?.material?.id
                        },
                        exams_preservative_id: {
                            label: useService?.preservative?.name,
                            value: useService?.preservative?.id
                        },
                        duration_forecast: useService?.duration_forecast,
                        price: useService?.price / 100,
                    })
                }

                if (ServicesForm.length === 0) ServicesForm?.push(defaultitemValues)
                setValue("services", ServicesForm)
                setControlState(false)
                return

            } catch (error) {
                console.log(error)
                setControlState(false)
            }
        } else {
            setControlState(false)
            return

        }

    }

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                let data = await loadDataEditform(id)
                setPageData({
                    partner: data.partner,
                })
                // console.log("dataedit",data)
                // let ServicesForm = []
                // for (let i = 0; i < data.partner.services.length; i++) {
                //     let useService = data.partner.services[i]
                //     ServicesForm.push({
                //         id: useService?.id,
                //         item_id: {
                //             label: useService.item.name,
                //             value: useService.item_id,
                //         },

                //         partner_item_code: useService?.partner_item_code,
                //         partner_item_name: useService?.partner_item_name,
                //         exams_material_id:{
                //             label:useService?.material?.name,
                //             value:useService?.material?.id
                //         },
                //         exams_preservative_id:{
                //             label:useService?.preservative?.name,
                //             value:useService?.preservative?.id
                //         },
                //         duration_forecast:useService?.duration_forecast,
                //         price: useService.price / 100,
                //     })
                // }

                // ServicesForm =
                //     ServicesForm.length == 0
                //         ? [defaultitemValues]
                //         : ServicesForm

                reset({
                    name: data.partner.name,
                    cnpj: data.partner.cnpj,
                    social_name: data.partner.social_name,
                    phone: data.partner.phone,
                    email: data.partner.email,
                    addr_state: data.partner.addr_state,
                    addr_city: data.partner.addr_city,
                    addr_district: data.partner.addr_district,
                    addr_address: data.partner.addr_address,
                    addr_number: data.partner.addr_number,
                    addr_complement: data.partner.addr_complement,
                    addr_cep: data.partner.addr_cep,
                    status: data?.partner?.status,
                    services: [defaultitemValues],
                    type:data?.partner?.type == TYPE_PARTNERS.DENTISTRY ? {label:"Odontológico",value:TYPE_PARTNERS.DENTISTRY} : TYPE_PARTNERS.EXAMS_LABORATORY ? {label:"Exames Laboratoriais",value:TYPE_PARTNERS.EXAMS_LABORATORY} : null
                })
            } catch (e) {
                console.log(e)
            }
        }

        if (id) loadDataEditPage()
    }, [])

    const loadItemInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await itemServiceSearch(
                    { term: inputValue },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsItem = debounce(loadItemInputOptions, 600)

    const loadItemMaterialOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await materialSearch(
                    { term: inputValue },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsMaterial = debounce(loadItemMaterialOptions, 600)
    const loadPreservativeSearchInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await preservativeSearch(
                    { term: inputValue },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPreservative = debounce(loadPreservativeSearchInputOptions, 600)
    const onSubmit = async data => {
        let services = data.services.filter(e=>e.item_id)
        
        if (services && services.length>0) {
            for (let i = 0; i < services?.length; i++) {
                    if (services[i].item_id?.idline) {
                        services[i].id = services[i].item_id?.idline
                    }
                    services[i].item_id = services[i].item_id?.value

                    if (services[i]?.exams_material_id) {
                        services[i].exams_material_id = services[i].exams_material_id?.value
                    }
                    if (services[i]?.exams_preservative_id) {
                        services[i].exams_preservative_id = services[i].exams_preservative_id?.value
                    }
                    services[i].duration_forecast = parseInt(services[i].duration_forecast);
                    if (typeof services[i].price == "number") {
                        services[i].price = services[i].price * 100
                    }

                    if (typeof services[i].price == "string") {
                        let price = services[i].price.replace(
                            /[^0-9]/g,
                            ""
                        )
                        services[i].price =
                            price && price.length > 0 ? parseInt(price) : 0
                    }

                    if (!services[i].price) {
                        services[i].price = 0
                    }

                    //Ao clicar em alterar a máscara não é aplicada, hack para burlar o problema. Depois melhorar
                    if (services[i].price < 100)
                    services[i].price = services[i].price * 100
                    data.services = services

            }
        } else {
            data.services = []
        }
        data.type = data?.type?.value

      
        try {
            if (id) {
                await update(id, data)
            } else {
                await create(data)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            history.push("/parceiros")
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.error_code == "existing_role") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Regra já criada!",
                        text:
                            "Existem serviços repetidos, favor verifique e tente novamente.",
                        type: "error",
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }
    const options = [
        {
            label: "Exames Laboratoriais",
            value: TYPE_PARTNERS?.EXAMS_LABORATORY,
        },
        {
            label: "Odontologia",
            value: TYPE_PARTNERS?.DENTISTRY,
        },
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Parceiros"
                        breadcrumbItem="Empresas Parceiras"
                    />

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm="10">
                                                <Nav
                                                    tabs
                                                    className="nav-tabs-custom"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        activeTab ===
                                                                        "1",
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                toggle("1")
                                                            }}
                                                        >
                                                            Cadastro de
                                                            Parceiros
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        activeTab ===
                                                                        "2",
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                toggle("2")
                                                                if (id) LoadGetItems()
                                                            }}
                                                        >
                                                            Serviços Parceiros
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                            <Col sm="2" align="end">
                                                <Button
                                                    type="button"
                                                    color="info"
                                                    onClick={() => {
                                                        const data = {
                                                            entity: "partner",
                                                            entity_id: id,
                                                        }

                                                        setModalLogs({
                                                            show: true,
                                                            data: data,
                                                        })
                                                    }}
                                                >
                                                    {" "}
                                                    <i className="fas fa-book fa-lg text-white"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {/* fim abas */}

                                <TabContent
                                    activeTab={activeTab}
                                    className="mt-4"
                                >
                                    <TabPane tabId="1">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>
                                                    Dados Cadastrais
                                                </CardTitle>
                                                <CardSubtitle className="mb-3">
                                                    É necessário preencher todas
                                                    as informações
                                                </CardSubtitle>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>Tipo</Label>
                                                        <Controller
                                                            name="type"
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Select
                                                                    placeholder="Selecione um tipo..."
                                                                    defaultOptions
                                                                    onChange={e => {
                                                                        setTypeControl(
                                                                            e
                                                                                ?.target
                                                                                ?.value
                                                                        )
                                                                        handleItemOnchange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    options={
                                                                        options
                                                                    }
                                                                    classNamePrefix="select2-selection"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Nome</Label>

                                                        <Controller
                                                            name={"name"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>CNPJ</Label>
                                                        <Controller
                                                            name={"cnpj"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>
                                                            Razão Social
                                                        </Label>
                                                        <Controller
                                                            name={"social_name"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>Telefone</Label>
                                                        <Controller
                                                            name={"phone"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>E-mail</Label>
                                                        <Controller
                                                            name={"email"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>Estado</Label>
                                                        <Controller
                                                            name={"addr_state"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="select"
                                                                    {...field}
                                                                >
                                                                    <option>
                                                                        Selecionar
                                                                    </option>
                                                                    {states.map(
                                                                        state => (
                                                                            <option
                                                                                key={
                                                                                    state.sigla
                                                                                }
                                                                                value={
                                                                                    state.sigla
                                                                                }
                                                                            >
                                                                                {
                                                                                    state.estado
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Input>
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Cidade</Label>
                                                        <Controller
                                                            name={"addr_city"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>Bairro</Label>
                                                        <Controller
                                                            name={
                                                                "addr_district"
                                                            }
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Endereço</Label>
                                                        <Controller
                                                            name={
                                                                "addr_address"
                                                            }
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>CEP</Label>
                                                        <Controller
                                                            name={"addr_cep"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>
                                                            Complemento
                                                        </Label>
                                                        <Controller
                                                            name={
                                                                "addr_complement"
                                                            }
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="6">
                                                        <Label>Número</Label>
                                                        <Controller
                                                            name={"addr_number"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Status</Label>
                                                        <Controller
                                                            name={"status"}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Input
                                                                    type="select"
                                                                    {...field}
                                                                >
                                                                    <option
                                                                        value={
                                                                            1
                                                                        }
                                                                    >
                                                                        Ativo
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            0
                                                                        }
                                                                    >
                                                                        Inativo
                                                                    </option>
                                                                </Input>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="2">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-2">
                                                    Serviços
                                                </CardTitle>
                                                <CardSubtitle className="mb-4">
                                                    Preencha as informações de
                                                    amarração.
                                                </CardSubtitle>

                                                {controlState ? (
                                                    <Row>
                                                        <Col align="center">
                                                            <div
                                                                className="spinner-border text-info"
                                                                role="status"

                                                            >
                                                                <span className="sr-only">
                                                                    Sincronizando...
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <>
                                                        <Row>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Serviço VittáNet
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Código Serviço
                                                                    parceiro
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Nome Serviço
                                                                    parceiro
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Conservante
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Material
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                lg="2"
                                                                md="12"
                                                                align="start"
                                                            >
                                                                <Label>
                                                                    Prev. de Resultado (mín)
                                                                </Label>
                                                            </Col>
                                                            {typeControl !=
                                                                TYPE_PARTNERS?.EXAMS_LABORATORY ? (
                                                                <Col
                                                                    lg="3"
                                                                    md="12"
                                                                    align="start"
                                                                >
                                                                    <Label>
                                                                        Valor do
                                                                        Parceiro
                                                                    </Label>
                                                                </Col>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                        <Row>
                                                            {/* fim item fixo */}
                                                            <Col
                                                                lg="12"
                                                                className="mt-2"
                                                            >
                                                                {fields?.map(
                                                                    (
                                                                        services,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Row
                                                                                key={
                                                                                    services?.id
                                                                                }
                                                                                className="mb-3 align-items-center"
                                                                            >
                                                                                <Col
                                                                                    lg="1"
                                                                                    md="12"
                                                                                    className="d-none"
                                                                                >
                                                                                    <Controller
                                                                                        name={`services[${index}].id`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    lg="2"
                                                                                    md="12"
                                                                                >
                                                                                    <Controller
                                                                                        name={`services[${index}].item_id`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <AsyncSelect
                                                                                                placeholder="Busque Aqui"
                                                                                                noOptionsMessage={() => 'Digite para Buscar'}
                                                                                                loadOptions={
                                                                                                    debounceOptionsItem
                                                                                                }
                                                                                                onChange={e => {
                                                                                                    handleItemOnchange(
                                                                                                        e
                                                                                                    )
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    services.item_id
                                                                                                }
                                                                                                classNamePrefix="select2-selection"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm="2">
                                                                                    <Controller
                                                                                        name={`services[${index}].partner_item_code`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm="2">
                                                                                    <Controller
                                                                                        name={`services[${index}].partner_item_name`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    lg="2"
                                                                                    md="12"
                                                                                >
                                                                                    <Controller
                                                                                        name={`services[${index}].exams_preservative_id`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <AsyncSelect
                                                                                                placeholder="Busque Aqui"
                                                                                                noOptionsMessage={() => 'Digite para Buscar'}
                                                                                                loadOptions={
                                                                                                    debounceOptionsPreservative
                                                                                                }
                                                                                                onChange={e => {
                                                                                                    handleItemOnchange(
                                                                                                        e
                                                                                                    )
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    services.exams_preservative_id
                                                                                                }
                                                                                                classNamePrefix="select2-selection"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    lg="2"
                                                                                    md="12"
                                                                                >
                                                                                    <Controller
                                                                                        name={`services[${index}].exams_material_id`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <AsyncSelect
                                                                                                placeholder="Busque Aqui"
                                                                                                noOptionsMessage={() => 'Digite para Buscar'}
                                                                                                loadOptions={
                                                                                                    debounceOptionsMaterial
                                                                                                }
                                                                                                onChange={e => {
                                                                                                    handleItemOnchange(
                                                                                                        e
                                                                                                    )
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    services.exams_material_id
                                                                                                }
                                                                                                classNamePrefix="select2-selection"
                                                                                                {...field}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm="1">
                                                                                    <Controller
                                                                                        name={`services[${index}].duration_forecast`}
                                                                                        control={
                                                                                            control
                                                                                        }
                                                                                        render={({
                                                                                            field,
                                                                                        }) => (
                                                                                            <NumberFormat
                                                                                                displayType={
                                                                                                    "input"
                                                                                                }

                                                                                                className="form-control"
                                                                                                defaultValue={
                                                                                                    services.duration_forecast
                                                                                                }
                                                                                                {...field}
                                                                                            /* component={
                                                                                                CurrencyInput
                                                                                            }*/
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                {typeControl !=
                                                                                    TYPE_PARTNERS?.EXAMS_LABORATORY ? (
                                                                                    <Col sm="3">
                                                                                        <Controller
                                                                                            name={`services[${index}].price`}
                                                                                            control={
                                                                                                control
                                                                                            }
                                                                                            render={({
                                                                                                field,
                                                                                            }) => (
                                                                                                <NumberFormat
                                                                                                    displayType={
                                                                                                        "input"
                                                                                                    }
                                                                                                    thousandSeparator={
                                                                                                        "."
                                                                                                    }
                                                                                                    decimalSeparator={
                                                                                                        ","
                                                                                                    }
                                                                                                    decimalScale={
                                                                                                        2
                                                                                                    }
                                                                                                    fixedDecimalScale={
                                                                                                        true
                                                                                                    }
                                                                                                    allowEmptyFormatting={
                                                                                                        true
                                                                                                    }
                                                                                                    prefix={
                                                                                                        "R$ "
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                    defaultValue={
                                                                                                        services.price
                                                                                                    }
                                                                                                    {...field}
                                                                                                /* component={
                                                                                                    CurrencyInput
                                                                                                }*/
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Col>
                                                                                ) : (
                                                                                    ""
                                                                                )}

                                                                                {index >
                                                                                    0 ? (
                                                                                    <Col
                                                                                        lg="1"
                                                                                        md="12"
                                                                                        className="
                                                                                                    mt-2"
                                                                                    >
                                                                                        <Button
                                                                                            color="danger"
                                                                                            className="inner"
                                                                                            onClick={() => {
                                                                                                remove(
                                                                                                    index
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-trash-alt"></i>
                                                                                        </Button>
                                                                                    </Col>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Row>
                                                                        )
                                                                    }
                                                                )}
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        append(
                                                                            defaultitemValues
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                                <div align="end">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        className="mr-2"
                                    >
                                        Salvar
                                    </Button>
                                    <Link
                                        to="/parceiros"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
            <ModalLogs
                show={modalLogs?.show}
                data={modalLogs?.data}
                onClose={() => {
                    setModalLogs({
                        show: false,
                        data: null,
                    })
                }}
            />
        </React.Fragment>
    )
}

export default RegistrationPartners
