import request from './config/request';

const create = async (schedule_data,unit_id) => {
    return request({
        method: "POST",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointment-book-locks?unit_id=${unit_id}`,
        data: schedule_data,
        vtAuth: true
    })
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.appointment_book_id) query += "&appointment_book_id=" + filter.appointment_book_id
    if (filter.date_start) query += "&date_start=" + filter.date_start;
    if (filter.date_end) query += "&date_end=" + filter.date_end;

    return request({
        method: "GET",
        url:process.env.REACT_APP_VITTA_SERVICES_HOST +`/vittanet/appointment-book-locks` +
        query,
        vtAuth: true
    })
}

// const update = async (id, schedule_data) => {
//     return request({
//         method: "POST",
//         url: process.env.REACT_APP_VITTA_SERVICES_HOST +
//         `/vittanet/appointment-book-locks/${id}`,
//         data: schedule_data,
//         vtAuth: true
//     })
// }

// const loadDataEditform = async id => {
//     return request({
//         method: "GET",
//         url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
//         `/vittanet/appointment-book-locks${id}/edit`,
//         vtAuth: true
//     })
// }

const deleteScheduleLock = async (id,unit_id) => {
    // console.log("teste",id,unit_id)
    return request({
        method: "DELETE",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/appointment-book-locks/${id}?unit_id=${unit_id}`,
        vtAuth: true
    })
}

export {
    search,
    create,
    deleteScheduleLock
}
