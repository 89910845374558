import {
    NavLink,
    NavItem,
    Nav,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    Label,
    Input,
    Badge,
    UncontrolledTooltip,
    Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect, useRef } from "react"
import { Formik, Form, FastField, FieldArray, Field } from "formik"
import CreatableSelect from "react-select/creatable"
import { loadDataEditform } from "services/vitta-core/attendance"
import { update, deleteEntity } from "services/vitta-core/attendance"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { searchCids as searchCids } from "services/vitta-core/attendance"
import { createEntity } from "services/vitta-core/attendance"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import AsyncSelect from "react-select/async"
import MedicalReports from "./MedicalReports"
import MedicalCertificate from "./MedicalCertificate"
import MedicalReferral from "./MedicalReferral"
import MedicalPrescritions from "./MedicalPrescritions"
import MedicalExams from "./MedicalExams"
import debounce from "debounce-promise"
import handleMedicalFormUpdate from "pages/Patients/Detail/pages/GeneralAttendance/Functions/handleMedicalFormUpdate"
import RenderForm from "./components/renderForm"
import draftToHtml from "draftjs-to-html"
import { getPdfMemed } from "services/vitta-core/attendance"
const defaultItemsExams = {
    item_id: null,
    quantity: 1,
}
const defaultItemValues = {
    items: [defaultItemsExams],
    title: "",
    text_object: "",
}

const defaultItemsCertificatesValues = {
    title: "",
    text_object: null,
}

const defaultReportItemsValues = {
    title: "",
    text_object: null,
}
const defaultPrescriptionItemsValues = {
    title: "",
    text_object: null,
}

const defautEvolutionItemsValues = {
    evolution: "",
}
var defaultValues = {
    ana_main_complaint: "",
    ana_hda: "",
    ana_hpp: "",
    ana_hf: "",
    exam_physical: "",
    diag_hypotheses: "",
    diag_definitive: "",
    diag_treatment: "",
    cid: "",
    disease: [],
    medicationsuse: [],
    allergy: [],
    items: [],
    itemsCertificate: [],
    itemsReport: [],
    itemsPrescriptions: [],
    itemsEvolution: [defautEvolutionItemsValues],
    itemsReferral: [],
}

const lastState = {}
const lastStateEvolution = {}
const ClosedService = 0
const OpenService = 1

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="textarea"
        {...field}
        {...props}
        height="auto"
        rows={3}
        onBlur={e => {
            if (field?.name?.includes(".evolution")) {
                handleMedicalFormUpdate(
                    props.attendance_id,
                    props.stateSync,
                    props.datasyncstate,
                    props.entityID,
                    field,
                    props.lastState,
                    "AttendanceEvolution"
                )
            }

            field.onBlur(e)
            props.handleCheck(field, props)
        }}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <CreatableSelect
        {...field}
        {...props}
        onBlur={() => {
            props.handleCheck(field, props)
        }}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const AsyncSelectInputt = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onBlur={() => {
            props.handleCheck(field, props)
        }}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const GeneralAttendances = props => {
    const Scope = useSelector(state => state.scope)
    const [pageData, setPageData] = useState(defaultValues)
    const [tab, setTab] = useState(0)
    const [loading, setLoading] = useState(false)

    const viewForm = async data => {
        let pages = []
        let memedPage = []

        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true
        const professional = pageData.professional
        const patient = pageData.patient
        setLoading(true)
        if (data?.items?.length > 0) {
            for (let i = 0; i < data?.items?.length; i++) {
                let content = {
                    ...data.items[i],
                    print: true,
                    unit: pageData?.unit,
                    created_at: data?.created_at,
                }

                if (
                    data?.items[i].text_object &&
                    data?.items[i].text_object != ""
                ) {
                    content.text = draftToHtml(
                        convertToRaw(
                            data?.items[i].text_object.getCurrentContent()
                        )
                    )
                }

                pages.push(
                    RenderForm({
                        showProfessionalSignature,
                        ShowPatientData,
                        showPrintButton,
                        professional,
                        patient,
                        content,
                    })
                )
            }
        }
        if (data?.itemsCertificate?.length > 0) {
            for (let i = 0; i < data?.itemsCertificate?.length; i++) {
                let content = {
                    ...data.itemsCertificate[i],
                    print: true,
                    unit: pageData?.unit,
                    created_at: data?.created_at,
                    entity: "MedicalCertificate",
                }

                if (
                    data?.itemsCertificate[i].text_object &&
                    data?.itemsCertificate[i].text_object != ""
                ) {
                    content.text = draftToHtml(
                        convertToRaw(
                            data?.itemsCertificate[
                                i
                            ].text_object.getCurrentContent()
                        )
                    )
                }

                pages.push(
                    RenderForm({
                        showProfessionalSignature,
                        ShowPatientData,
                        showPrintButton,
                        professional,
                        patient,
                        content,
                    })
                )
            }
        }
        if (data?.itemsPrescriptions?.length > 0) {
            for (let i = 0; i < data?.itemsPrescriptions?.length; i++) {
                if(!data.itemsPrescriptions[i].integration_provider && !data.itemsPrescriptions[i].integration_id){
                    let content = { ...data.itemsPrescriptions[i], print: true, unit: pageData?.unit, created_at: data?.created_at, entity: "MedicalPrescription" }

                    if (data?.itemsPrescriptions[i].text_object && data?.itemsPrescriptions[i].text_object != "") {
                        content.text = draftToHtml(
                            convertToRaw(data?.itemsPrescriptions[i].text_object.getCurrentContent())
                        )
                    }
    
                    pages.push(RenderForm({
                        showProfessionalSignature,
                        ShowPatientData,
                        showPrintButton,
                        professional,
                        patient,
                        content,
                    }))
                }else{
                    if(data.itemsPrescriptions[i].file){
                        memedPage.push(data.itemsPrescriptions[i].file)
                    }else{
                        let pdf=''
                        const script = document.getElementById("memedScript");
                        if(script){
                            try {
                                const result = await getPdfMemed(script?.dataset?.token, data.itemsPrescriptions[i].integration_id)
                                pdf = result.result
                            } catch (error) {
                                console.log(error.response?.data)
                                // setLoading(false)
                                // if(error.response?.data?.error_code){
                                            
                                //     return dispatch(
                                //         showSweetAlertDialog({
                                //             title:
                                //             error.response.data.error_message,
                                //             type: "error",
                                //             showConfirm: true,
                                //         })
                                //     )
                                // }
                            }
                            
                        }
                        memedPage.push(pdf)
                    } 
                }
                
            }
        }
        if (data?.itemsReport?.length > 0) {
            for (let i = 0; i < data?.itemsReport?.length; i++) {
                let content = {
                    ...data.itemsReport[i],
                    print: true,
                    unit: pageData?.unit,
                    created_at: data?.created_at,
                    entity: "MedicalReport",
                }

                if (
                    data?.itemsReport[i].text_object &&
                    data?.itemsReport[i].text_object != ""
                ) {
                    content.text = draftToHtml(
                        convertToRaw(
                            data?.itemsReport[i].text_object.getCurrentContent()
                        )
                    )
                }

                pages.push(
                    RenderForm({
                        showProfessionalSignature,
                        ShowPatientData,
                        showPrintButton,
                        professional,
                        patient,
                        content,
                    })
                )
            }
        }
        if (data?.itemsReferral?.length > 0) {
            for (let i = 0; i < data?.itemsReferral?.length; i++) {
                let content = {
                    ...data.itemsReferral[i],
                    print: true,
                    unit: pageData?.unit,
                    created_at: data?.created_at,
                    entity: "MedicalReferral",
                }

                if (
                    data?.itemsReferral[i].text_object &&
                    data?.itemsReferral[i].text_object != ""
                ) {
                    content.text = draftToHtml(
                        convertToRaw(
                            data?.itemsReferral[
                                i
                            ].text_object.getCurrentContent()
                        )
                    )
                }

                pages.push(
                    RenderForm({
                        showProfessionalSignature,
                        ShowPatientData,
                        showPrintButton,
                        professional,
                        patient,
                        content,
                    })
                )
            }
        }

        if (pages.length == 0 && memedPage.length == 0) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Nenhum documento para impressão",
                    text:
                        "É preciso ter um documento disponível para imprimir.",
                    type: "error",
                })
            )
        }

        let allPagesHtml = ""

        for (let i = 0; i < pages?.length; i++) {
            allPagesHtml += "<div style='page-break-after: always; page-break-inside: avoid; height: auto; width: auto; margin: 0; box-sizing: border-box; position: relative;'>" + pages[i] + "</div>";
        }
        printAll(memedPage, allPagesHtml);

    }

    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    const printHTMLContent = async(htmlContent) => {
        return new Promise(resolve => {
            const w = 1024;
            const h = 768;
            const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
            const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
            htmlContent += '<script type="text/javascript">window.onload = function() { window.print(); window.onafterprint = function() { window.close(); } }</script>';
            const winUrl = URL.createObjectURL(
                new Blob([htmlContent], { type: "text/html" })
            );
            
            const newWin = window.open(
                winUrl,
                "Documentos",
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
            );
    
            const checkWindowClosed = setInterval(() => {
                if (newWin.closed) {
                    clearInterval(checkWindowClosed);
                    resolve();
                }
            }, 100);
        });
    }
    async function getPdfBlob(pdfUrl) {
        const response = await fetch(pdfUrl);
        return response.blob();
    }
    
    const printMultiplePDFs = async(pdfUrls) => {

        for (let index = 0; index < pdfUrls.length; index++) {
            const blob = await getPdfBlob(pdfUrls[index]);
            const objectURL = window.URL.createObjectURL(blob);
            
            await new Promise(resolve => {
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
        
                iframe.onload = function () {
                    setTimeout(() => {
                        iframe.contentWindow.print();
                        resolve(); 
                    }, 100);
                };
        
                iframe.src = objectURL;
            });
        }
      
    }

    const printAll = async(pdfUrls, htmlContent) => {
        //setLoading(true)
        await printMultiplePDFs(pdfUrls);

        if(htmlContent)await printHTMLContent(htmlContent);
        setLoading(false)
       
    }
    // const loadDiseasesInputOptions = async inputValue => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let response = await searchDisease({ term: inputValue }, 1, 25)

    //             let items_values = []
    //             response.data.forEach(element => {
    //                 items_values.push({
    //                     label:
    //                         /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.name,
    //                     value: element.id,
    //                 })
    //             })

    //             return resolve(items_values)
    //         } catch (e) {
    //             console.error(e)
    //             return reject([])
    //         }
    //     })
    // }
    // const loadAllergyInputOptions = async inputValue => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let response = await searchAllergies(
    //                 { term: inputValue },
    //                 1,
    //                 25
    //             )

    //             let items_values = []
    //             response.data.forEach(element => {
    //                 items_values.push({
    //                     label:
    //                         /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.name,
    //                     value: element.id,
    //                 })
    //             })

    //             return resolve(items_values)
    //         } catch (e) {
    //             console.error(e)
    //             return reject([])
    //         }
    //     })
    // }
    // const loadMedicationsUsesInputOptions = async inputValue => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let response = await searchDrugs({ term: inputValue }, 1, 25)

    //             let items_values = []
    //             response.data.forEach(element => {
    //                 items_values.push({
    //                     label:
    //                         /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.name,
    //                     value: element.id,
    //                 })
    //             })

    //             return resolve(items_values)
    //         } catch (e) {
    //             console.error(e)
    //             return reject([])
    //         }
    //     })
    // }

    const loadCidsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchCids({ term: inputValue }, 1, 25)

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label:
                            /* `${element.problem_id ? element.problem_id : "" }  - ` + */ element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    // const debounceOptionsDisease = debounce(loadDiseasesInputOptions, 600)
    // const debounceOptionsAllergy = debounce(loadAllergyInputOptions, 600)
    // const debounceOptionsMedication = debounce(
    //     loadMedicationsUsesInputOptions,
    //     600
    // )
    const debounceOptionsCid = debounce(loadCidsInputOptions, 600)

    const cardAnamnseRef = useRef()
    const cardEfRef = useRef()
    const cardHdRef = useRef()
    const cardTratamentoRef = useRef()
    const cardAtestadoRef = useRef()
    const cardPrescRef = useRef()
    const cardPedidoRef = useRef()
    const cardLaudoRef = useRef()
    const cardForwardRef = useRef()
    const cardEvolutionRef = useRef()
    const [activeTab, setactiveTab] = useState("1")

    const dispatch = useDispatch()
    const history = useHistory()
    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }

    const { patient_id, attendance_id } = useParams()

    const userAuth = useSelector(state => state.auth.access?.user)
    const [attendance_ids, setAttendance_ids] = useState(attendance_id)
    const [syncState, setSyncState] = useState(1)
    const [dataSyncState, setDataSyncState] = useState({})
    const rota = "/pacientes/" + patient_id + "/atendimentos"

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                let data = await loadDataEditform(attendance_id)

                props.openedAttendance(data?.attendance);

                let diseases = []
                let drugs = []
                let allergies = []
                let itemsExam = []
                let itemsCertificate = []
                let itemsPrescription = []
                let itemsReport = []
                let itemsReferral = []
                let cidAttendance = []
                let itemsEvolution = []
                
                for (let i = 0; i < data.attendance.disease.length; i++) {
                    diseases.push({
                        label: data.attendance.disease[i].problem_name,
                        value: data.attendance.disease[i].problem_id
                            ? data.attendance.disease[i].problem_id
                            : data.attendance.disease[i].problem_name,
                    })
                }
                for (let i = 0; i < data.attendance.drug.length; i++) {
                    drugs.push({
                        label: data.attendance.drug[i].drug_name,
                        value: data.attendance.drug[i].drug_id
                            ? data.attendance.drug[i].drug_id
                            : data.attendance.drug[i].drug_name,
                    })
                }

                for (let i = 0; i < data.attendance.allergy.length; i++) {
                    allergies.push({
                        label: data.attendance.allergy[i].problem_name,
                        value: data.attendance.allergy[i].problem_id
                            ? data.attendance.allergy[i].problem_id
                            : data.attendance.allergy[i].problem_name,
                    })
                }

                for (let i = 0; i < data.attendance.examsOrders.length; i++) {
                    let editorState = null
                    try {
                        editorState = data.attendance.examsOrders[i]
                            .medical_form.text_object
                            ? EditorState.createWithContent(
                                  convertFromRaw(
                                      JSON.parse(
                                          data.attendance.examsOrders[i]
                                              .medical_form.text_object
                                      )
                                  )
                              )
                            : null
                    } catch (e) {
                        console.log(e)
                    }
                    let useOrder = data.attendance.examsOrders[i]
                    let request = {
                        title: useOrder.medical_form.title,
                        text_object: editorState,
                        entity: "MedicalExams",
                        attendance_id: data.attendance.id,
                        itemsExam: [],
                        id: useOrder?.id,
                    }

                    for (
                        let k = 0;
                        k < useOrder.medical_exams_items.length;
                        k++
                    ) {
                        let useOrderExam = useOrder.medical_exams_items[k]
                        let item = {
                            item_id: {
                                label: useOrderExam?.item?.name,
                                value: useOrderExam?.item?.id,
                            },
                            entity: "itemsExam",
                            MedicalExamId: useOrderExam?.medical_exam_id,
                            id: useOrderExam?.id,
                        }
                        request.itemsExam.push(item)
                    }
                    itemsExam.push(request)
                }

                for (
                    let i = 0;
                    i < data.attendance.medicalCertificates.length;
                    i++
                ) {
                    let editorState = null
                    try {
                        editorState = data.attendance.medicalCertificates[i]
                            .medical_form.text_object
                            ? EditorState.createWithContent(
                                  convertFromRaw(
                                      JSON.parse(
                                          data.attendance.medicalCertificates[i]
                                              .medical_form.text_object
                                      )
                                  )
                              )
                            : null
                    } catch (e) {
                        console.log(e)
                    }

                    itemsCertificate.push({
                        title:
                            data.attendance.medicalCertificates[i].medical_form
                                .title,
                        text_object: editorState,
                        id: data.attendance.medicalCertificates[i].id,
                    })
                }

                for (
                    let i = 0;
                    i < data.attendance.medicalReferrals.length;
                    i++
                ) {
                    let editorState = null
                    try {
                        editorState = data.attendance.medicalReferrals[i]
                            .medical_form.text_object
                            ? EditorState.createWithContent(
                                  convertFromRaw(
                                      JSON.parse(
                                          data.attendance.medicalReferrals[i]
                                              .medical_form.text_object
                                      )
                                  )
                              )
                            : null
                    } catch (e) {
                        console.log(e)
                    }
                    let specialty = null

                    if (data?.attendance?.medicalReferrals[i]?.specialty?.id) {
                        specialty = {
                            label:
                                data?.attendance?.medicalReferrals[i]?.specialty
                                    ?.name,
                            value:
                                data?.attendance?.medicalReferrals[i]?.specialty
                                    ?.id,
                        }
                    }
                    itemsReferral.push({
                        title:
                            data.attendance.medicalReferrals[i].medical_form
                                .title,
                        text_object: editorState,
                        id: data.attendance.medicalReferrals[i].id,
                        specialty_id: specialty,
                    })
                }

                for (
                    let i = 0;
                    i < data.attendance.medicalPrescription.length;
                    i++
                ) {
                    let editorState = null
                    try {
                        editorState = data.attendance.medicalPrescription[i]
                            ?.medical_form?.text_object
                            ? EditorState.createWithContent(
                                convertFromRaw(
                                    JSON.parse(
                                        data.attendance.medicalPrescription[i]
                                            ?.medical_form?.text_object
                                    )
                                )
                            )
                            : null
                    } catch (e) {
                        console.log(e)
                    }

                    itemsPrescription.push({
                        title:
                            data.attendance.medicalPrescription[i].medical_form?.title,
                        text_object: editorState,
                        integration_provider:data.attendance.medicalPrescription[i]?.integration_provider,
                        integration_id:data.attendance.medicalPrescription[i]?.integration_id,
                        file:data.attendance.medicalPrescription[i]?.file,
                        id: data.attendance.medicalPrescription[i]?.id,
                    })
                }

                for (
                    let i = 0;
                    i < data.attendance.medicalReports.length;
                    i++
                ) {
                    let editorState = null
                    try {
                        editorState = data.attendance.medicalReports[i]
                            .medical_form.text_object
                            ? EditorState.createWithContent(
                                  convertFromRaw(
                                      JSON.parse(
                                          data.attendance.medicalReports[i]
                                              .medical_form.text_object
                                      )
                                  )
                              )
                            : null
                    } catch (e) {
                        console.log(e)
                    }

                    itemsReport.push({
                        title:
                            data.attendance.medicalReports[i].medical_form
                                .title,
                        text_object: editorState,
                        id: data.attendance.medicalReports[i].id,
                    })
                }

                for (let i = 0; i < data.attendance.cidAttendance.length; i++) {
                    cidAttendance.push({
                        label: data.attendance.cidAttendance[i].name,
                        value: data.attendance.cidAttendance[i].id,
                    })
                }

                for (
                    let i = 0;
                    i < data?.attendance?.recordAttendanceEvolution.length;
                    i++
                ) {
                    itemsEvolution.push({
                        evolution:
                            data?.attendance?.recordAttendanceEvolution[i].text,
                        id: data?.attendance?.recordAttendanceEvolution[i].id,
                    })
                }

                lastState.ana_main_complaint =
                    data.attendance.ana_main_complaint
                lastState.ana_hda = data.attendance.ana_hda
                lastState.ana_hpp = data.attendance.ana_hpp
                lastState.ana_hf = data.attendance.ana_hf
                lastState.exam_physical = data.attendance.exam_physical
                lastState.diag_hypotheses = data.attendance.diag_hypotheses
                lastState.diag_definitive = data.attendance.diag_definitive
                lastState.diag_treatment = data.attendance.diag_treatment
                lastState.cid = cidAttendance
                lastState.disease = diseases
                lastState.allergy = allergies
                lastState.medicationsuse = drugs
                lastStateEvolution.itemsEvolution = itemsEvolution
                setDataSyncState(data?.attendance)
                setPageData({
                    professional: data.attendance.professional,
                    patient: data.attendance.patient,
                    unit: data.attendance.unit,
                    defaultValues: {
                        ana_main_complaint: data.attendance.ana_main_complaint,
                        ana_hda: data.attendance.ana_hda,
                        ana_hpp: data.attendance.ana_hpp,
                        ana_hf: data.attendance.ana_hf,
                        exam_physical: data.attendance.exam_physical,
                        diag_hypotheses: data.attendance.diag_hypotheses,
                        diag_definitive: data.attendance.diag_definitive,
                        diag_treatment: data.attendance.diag_treatment,
                        canceled: data.attendance.status == 2 ? true : false,
                        cid: cidAttendance,
                        disease: diseases,
                        medicationsuse: drugs,
                        allergy: allergies,
                        items: itemsExam,
                        itemsCertificate: itemsCertificate,
                        itemsReport: itemsReport,
                        itemsPrescriptions: itemsPrescription,
                        itemsEvolution: itemsEvolution,
                        itemsReferral: itemsReferral,
                        canceled_at: data.attendance.canceled_at,
                        status:
                            data.attendance.status === 1 &&
                            data.attendance.professional.id === userAuth.Professional?.id 
                                ? 1
                                : 0,
                        created_at: data.attendance.created_at,
                    },
                })
            } catch (e) {
                console.log("catch", e)
            }
        }
        if (attendance_id) loadDataEditPage()
        else console.log("não entrou no edit")
    }, [attendance_id])

    const handleCheck = (field, props) => {
        if (field?.value != lastState[field?.name]) {
            handleUpdate(field, props)
        }
    }
    let count = 0
    const handleUpdate = async (field, props) => {
        setSyncState(0)

        let disease = []
        let medicationsuse = []
        let allergy = []
        let cid = null
        try {
            //disease
            if (field.name == "disease" && field?.value?.length > 0) {
                for (let i = 0; i < field.value.length; i++) {
                    disease.push({
                        id: null,
                        name: field.value[i].value,
                    })
                }
            }

            if (field.name == "allergy" && field?.value?.length > 0) {
                for (let i = 0; i < field.value.length; i++) {
                    allergy.push({
                        id: null,
                        name: field.value[i].value,
                    })
                }
            }

            if (field.name == "medicationsuse" && field?.value?.length > 0) {
                for (let i = 0; i < field.value.length; i++) {
                    medicationsuse.push({
                        id: null,
                        name: field.value[i].value,
                    })
                }
            }

            if (field.name == "cid") {
                cid = {
                    id: field.value.value,
                    name: field.value.label,
                }
            }
            let dataReq = {}

            dataReq[field.name] = field.value
            dataReq.cid = cid
            dataReq.disease =
                field.name == "disease" && field?.value?.length > 0
                    ? disease
                    : field.name == "disease" && !field?.value?.length > 0
                    ? "deleteDisease"
                    : ""
            dataReq.allergy =
                field.name == "allergy" && field?.value?.length > 0
                    ? allergy
                    : field.name == "allergy" && !field?.value?.length > 0
                    ? "deleteAllergy"
                    : ""
            dataReq.medicationsuse =
                field.name == "medicationsuse" && field?.value?.length > 0
                    ? medicationsuse
                    : field.name == "medicationsuse" &&
                      !field?.value?.length > 0
                    ? "deleteMedicationsuse"
                    : ""
            dataReq.status = OpenService

            let data = await update(attendance_id, dataReq)

            setDataSyncState(data)
            setSyncState(1)
            lastState[field.name] = field.value
        } catch (e) {
            console.log(e)
            count = count + 1
            if (count < 7) {
                handleCheck(field, props)
            } else if (count == 7) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Problema na sincronização !",
                        text:
                            "Houve algum problema na comunicação com o servidor, por isso os dados não foram salvos. Contate alguém do suporte ou tente mais tarde...",
                        type: "error",
                    })
                )

                setSyncState(2)
            }
        }
    }
    const handleCreate = async data => {
        try {
            setSyncState(0)

            let created = null

            let itemValue = {}

            itemValue.entity = "AttendanceEvolution"
            itemValue.attendance_id = attendance_id

            created = await createEntity(itemValue, attendance_id)
            itemValue.id = created?.id
            setDataSyncState(created?.form)
            setSyncState(1)

            if (itemValue?.id) {
                return itemValue
            }
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ocorreu algum problema ! ",
                    text:
                        "Houve um erro, tente novamente mais tarde ou contate o suporte",
                    type: "error",
                    showConfirm: true,
                })
            )

            setSyncState(2)
        }
    }
    const handleStatusConfirmation = async values => {
        if (
            values?.ana_main_complaint == "" ||
            values?.ana_main_complaint == null
        ) {
            dispatch(
                showSweetAlertDialog({
                    title: "Campo Obrigatório !",
                    text:
                        'Favor preencher o campo "Queixa Principal / Motivo Atendimento / Detalhes Gerais" para finalizar o atendimento.',
                    type: "warning",
                    showConfirm: true,
                })
            )
            return
        }

        if (values?.itemsEvolution && values?.itemsEvolution.length > 0) {
            for (let i = 0; i < values?.itemsEvolution?.length; i++) {
                if (
                    !values?.itemsEvolution[i].evolution ||
                    values?.itemsEvolution[i].evolution == ""
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "EVOLUÇÃO" ou exclua o registro criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
            }
        }
        if (
            values?.itemsCertificate?.length > 0 ||
            values?.itemsPrescriptions?.length > 0 ||
            values?.itemsReport?.length > 0 ||
            values?.itemsReferral?.length > 0 ||
            values?.items?.length > 0
        ) {
            for (let i = 0; i < values?.itemsCertificate?.length; i++) {
                let itemCertificate = values?.itemsCertificate[i]
                if (itemCertificate.title == "") {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Título" em Atestados ou exclua o Atestado criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
                if (
                    !itemCertificate?.text_object == null ||
                    !itemCertificate?.text_object == ""
                ) {
                    if (
                        (convertToRaw(
                            itemCertificate.text_object.getCurrentContent()
                        ).blocks.length ===
                            1) &
                        (convertToRaw(
                            itemCertificate.text_object.getCurrentContent()
                        ).blocks[0].text ===
                            "")
                    ) {
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    'Preencha o campo "Texto" em Atestados ou exclua o Atestado criado!',
                                type: "warning",
                                showConfirm: true,
                            })
                        )
                        return
                    }
                } else {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Texto" em Atestados ou exclua o Atestado criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
            }

            for (let i = 0; i < values?.itemsPrescriptions?.length; i++) {
                let itemPrescription = values?.itemsPrescriptions[i]
                if(!itemPrescription.integration_id && !itemPrescription.integration_provider){
                    if (itemPrescription.title == "") {
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    'Preencha o campo "Título" em Prescrições ou exclua a Precrição criada!',
                                type: "warning",
                                showConfirm: true,
                            })
                        )
                        return
                    }
                    if (
                        !itemPrescription?.text_object == null ||
                        !itemPrescription?.text_object == ""
                    ) {
                        if (
                            (convertToRaw(
                                itemPrescription.text_object.getCurrentContent()
                            ).blocks.length ===
                                1) &
                            (convertToRaw(
                                itemPrescription.text_object.getCurrentContent()
                            ).blocks[0].text ===
                                "")
                        ) {
                            dispatch(
                                showSweetAlertDialog({
                                    title:
                                        'Preencha o campo "Texto" em Prescrições ou exclua a Precrição criada!',
                                    type: "warning",
                                    showConfirm: true,
                                })
                            )
                            return
                        }
                    } else {
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    'Preencha o campo "Texto" em Prescrições ou exclua a Prescrição criada!',
                                type: "warning",
                                showConfirm: true,
                            })
                        )
                        return
                    } 
                }
                
            }

            for (let i = 0; i < values?.items?.length; i++) {
                let item = values?.items[i]
                if (
                    item.itemsExam &&
                    (item.itemsExam.find(e => !e.item_id) ||
                        item.itemsExam.find(e => !e.item_id.value))
                ) {
                    return dispatch(
                        showSweetAlertDialog({
                            title:
                                "Existe uma linha de serviço não preenchida na solicitação de exames. Preencha o serviço ou remova a linha",
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                }
                if (
                    !item.itemsExam ||
                    (item.itemsExam.every(e => !e?.item_id) &&
                        (item?.text_object == null ||
                            item?.text_object == "" ||
                            !item?.text_object == null ||
                            (!item?.text_object == "" &&
                                convertToRaw(
                                    item.text_object.getCurrentContent()
                                ).blocks.length === 1 &&
                                convertToRaw(
                                    item.text_object.getCurrentContent()
                                ).blocks[0].text === "")))
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Adicione um item ou preencha o campo "Texto" em Solicitações de exame ou exclua a Solicitação criada!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
            }
            for (let i = 0; i < values?.itemsReferral?.length; i++) {
                let itemReferral = values?.itemsReferral[i]
                if (itemReferral.title == "") {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Título" em Encaminhamentos ou exclua o Encaminhamento criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
                if (
                    !itemReferral?.text_object == null ||
                    !itemReferral?.text_object == ""
                ) {
                    if (
                        (convertToRaw(
                            itemReferral.text_object.getCurrentContent()
                        ).blocks.length ===
                            1) &
                        (convertToRaw(
                            itemReferral.text_object.getCurrentContent()
                        ).blocks[0].text ===
                            "")
                    ) {
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    'Preencha o campo "Texto" em Encaminhamentos ou exclua o Encaminhamento criado!',
                                ttype: "warning",
                                showConfirm: true,
                            })
                        )
                        return
                    }
                } else {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Texto" em Encaminhamentos ou exclua o Encaminhamento criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
            }
            for (let i = 0; i < values?.itemsReport?.length; i++) {
                let itemReport = values?.itemsReport[i]
                if (itemReport.title == "") {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Título" em Laudos ou exclua o Laudo criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
                if (
                    !itemReport?.text_object == null ||
                    !itemReport?.text_object == ""
                ) {
                    if (
                        (convertToRaw(
                            itemReport.text_object.getCurrentContent()
                        ).blocks.length ===
                            1) &
                        (convertToRaw(
                            itemReport.text_object.getCurrentContent()
                        ).blocks[0].text ===
                            "")
                    ) {
                        dispatch(
                            showSweetAlertDialog({
                                title:
                                    'Preencha o campo "Texto" em Laudos ou exclua o Laudo criado!',
                                ttype: "warning",
                                showConfirm: true,
                            })
                        )
                        return
                    }
                } else {
                    dispatch(
                        showSweetAlertDialog({
                            title:
                                'Preencha o campo "Texto" em Laudos ou exclua o Laudo criado!',
                            type: "warning",
                            showConfirm: true,
                        })
                    )
                    return
                }
            }
        }

        dispatch(
            showSweetAlertDialog({
                title: "Tem certeza ? ",
                text:
                    "Obs: Após finalizar, você poderá apenas visualizar o registro.",
                type: "warning",
                onConfirmAction: () => {
                    handleStatusService()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Finalizar Atendimento",
            })
        )
    }

    const handleStatusService = async () => {
        try {
            let dataStatus = {}
            dataStatus.status = ClosedService
            await update(attendance_id, dataStatus)

            dispatch(
                showToast({
                    title: "OK!",
                    text: "Atendimento finalizado!",
                    type: "success",
                })
            )

            history.push("/pacientes/" + patient_id + "/atendimentos")
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }
    const handleSaveService = () => {
        history.push("/pacientes/" + patient_id + "/atendimentos")

        dispatch(
            showToast({
                title: "OK!",
                text: "Registro salvo!",
                type: "success",
            })
        )
    }

    const handleDelete = async item => {
        setSyncState(0)

        await deleteEntity(item?.id, "AttendanceEvolution", attendance_id)
        setSyncState(1)
    }
    const formatCreateLabel = inputValue => `Adicionar ${inputValue}`

    function stopEnterSubmitting(e) {
        var keycode = e.keyCode || e.which || e.charCode
        if (keycode == 13) {
            var src = e.srcElement || e.target
            if (src.tagName.toLowerCase() != "textarea") {
                if (e.preventDefault) {
                    e.preventDefault()
                } else {
                    e.returnValue = false
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={
                    pageData?.defaultValues ? pageData?.defaultValues : ""
                }
                validateOnChange={false}
                validateOnBlur={true}
                // validationSchema={validations}
                enableReinitialize={true}
                onSubmit={handleStatusConfirmation}
            >
                {({ values, errors, touched }) => (
                    <Form id="myform" onKeyDown={stopEnterSubmitting}>
                        <Row>
                            <Col
                                sm="2"
                                className=""
                                style={{ height: "300px" }}
                            >
                                <Nav
                                    pills
                                    className="nav-tabs flex-column pills"
                                >
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                                cardAnamnseRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "start",
                                                    }
                                                )
                                            }}
                                        >
                                            Anamnese
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                                cardPedidoRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            <form class="form-floating">
                                                Solicitações Médicas{" "}
                                                <span
                                                    for="floatingInputInvalid"
                                                    style={{
                                                        color: "#008000",
                                                    }}
                                                    className="ml-2 animationsmalltag"
                                                >
                                                    {" "}
                                                    Novo
                                                </span>
                                            </form>
                                        </NavLink>
                                        {/* <span>
                                                    {" "}
                                                    <form class="form-floating">
                                                        <small
                                                            for="floatingInputInvalid"
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                            }}
                                                            className="ml-3 animationsmalltag"
                                                        >
                                                            Disponível
                                                        </small>
                                                    </form>
                                                </span> */}
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                                cardPrescRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            Prescrições
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "4",
                                            })}
                                            onClick={() => {
                                                toggle("4")
                                                cardEvolutionRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            Evoluções
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "5",
                                            })}
                                            onClick={() => {
                                                toggle("5")
                                                cardAtestadoRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            Atestados
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "6",
                                            })}
                                            onClick={() => {
                                                toggle("6")
                                                cardLaudoRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            Laudos
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={classnames({
                                                active: activeTab === "7",
                                            })}
                                            onClick={() => {
                                                toggle("7")
                                                cardForwardRef.current.scrollIntoView(
                                                    {
                                                        behavior: "smooth",
                                                        block: "center",
                                                    }
                                                )
                                            }}
                                        >
                                            Encaminhamentos
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <div>  {syncState == "1" &&
                                    !values?.canceled &&
                                    dataSyncState?.updated_at ? (
                                        <Badge className="badge-soft-info font-size-11" style={{ width:"100%" }}>
                                             <i className="fas fa-check fa-lg mr-1"></i>
                                            Sincronizado - 
                                            {new Date(
                                                dataSyncState?.updated_at
                                            ).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                hour12: false,
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })}
                                        </Badge>
                                    ) : (
                                        <Badge className="badge-soft-danger font-size-11" style={{ width:"100%" }}>
                                            {" "}
                                            <i className="fas fa-times fa-lg mr-1"></i> Cancelado{" "}
                                            -{" "}
                                            {new Date(
                                                values?.canceled_at
                                            ).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                hour12: false,
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })}
                                        </Badge>
                                    )}

                                    {syncState == "0" ? (
                                        <Spinner size="sm" color="info" />
                                    ) : (
                                        ""
                                    )}
                                    {syncState == "2" ? (
                                        <Badge className="badge-soft-danger font-size-11" style={{ width:"100%" }}>
                                            {" "}
                                            Status Sincronização : Não
                                            sincronizado.{" "}
                                            <i className="fas fa-times fa-lg"></i>{" "}
                                        </Badge>
                                    ) : (
                                        ""
                                    )}</div>
                                    </NavItem> */}
                                </Nav>

                                {/* <Row
                                    style={{
                                        position: "absolute",
                                        top: "310px",
                                        left: "21px",
                                    }}
                                >
                                    {syncState == "1" &&
                                    !values?.canceled &&
                                    dataSyncState?.updated_at ? (
                                        <Badge className="badge-soft-info font-size-11">
                                            {" "}
                                            Sincronizado{" "}
                                            <i className="fas fa-check fa-lg"></i>{" "}
                                            -{" "}
                                            {new Date(
                                                dataSyncState?.updated_at
                                            ).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                hour12: false,
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })}
                                        </Badge>
                                    ) : (
                                        <Badge className="badge-soft-danger font-size-11">
                                            {" "}
                                            Cancelado{" "}
                                            <i className="fas fa-times fa-lg"></i>{" "}
                                            -{" "}
                                            {new Date(
                                                values?.canceled_at
                                            ).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                hour12: false,
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })}
                                        </Badge>
                                    )}

                                    {syncState == "0" ? (
                                        <Spinner size="sm" color="info" />
                                    ) : (
                                        ""
                                    )}
                                    {syncState == "2" ? (
                                        <Badge className="badge-soft-danger font-size-11">
                                            {" "}
                                            Status Sincronização : Não
                                            sincronizado.{" "}
                                            <i className="fas fa-times fa-lg"></i>{" "}
                                        </Badge>
                                    ) : (
                                        ""
                                    )}
                                </Row> */}
                            </Col>

                            <Col
                                sm="10"
                                style={{ height: "580px", overflowY: "scroll" }}
                            >
                                <Card innerRef={cardAnamnseRef}>
                                    <CardBody>
                                        <Row>
                                            <Col sm="6">
                                                <CardTitle>
                                                    Introdução
                                                </CardTitle>
                                            </Col>
                                            <Col sm="6">
                                                <div align="end">
                                                    <Link
                                                        to={
                                                            "/pacientes/" +
                                                            patient_id +
                                                            "/exportacao-prontuario?attendance_id=" +
                                                            attendance_id
                                                        }
                                                        className="d-print-none"
                                                        target="_blank"
                                                    >
                                                        <i className="fas fa-print fa-lg mr-1"></i> Imprimir
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <div>
                                            <Label className="text-danger">
                                                Campos obrigatórios caso forem
                                                criados : Atestados / Laudos /
                                                Prescrições / Solicitações de
                                                Exame
                                            </Label>
                                        </div> */}
                                        <Label className="mt-2">
                                            Queixa Principal / Motivo
                                            Atendimento / Detalhes Gerais{" "}
                                            <small className="text-danger">
                                                <strong>(Obrigatório)</strong>
                                            </small>
                                        </Label>

                                        <FastField
                                            name="ana_main_complaint"
                                            component={TextInput}
                                            value={
                                                values?.ana_main_complaint
                                                    ? values?.ana_main_complaint
                                                    : ""
                                            }
                                            handleCheck={handleCheck}
                                            disabled={
                                                values?.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                            className={
                                                errors &&
                                                errors.ana_main_complaint &&
                                                touched &&
                                                touched.ana_main_complaint
                                                    ? "form-control is-invalid"
                                                    : null
                                            }
                                        />
                                        {errors &&
                                        errors.ana_main_complaint &&
                                        touched &&
                                        touched.ana_main_complaint ? (
                                            <div
                                                style={{ color: "red" }}
                                                className="mb-2"
                                            >
                                                {errors.ana_main_complaint}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <Label className="mt-2">
                                            Histórico de doença atual (HDA)
                                        </Label>

                                        <FastField
                                            name="ana_hda"
                                            component={TextInput}
                                            value={
                                                values?.ana_hda
                                                    ? values?.ana_hda
                                                    : ""
                                            }
                                            handleCheck={handleCheck}
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />

                                        <Label className="mt-2">
                                            História Patológica Pregressa (HPP)
                                        </Label>

                                        <FastField
                                            name="ana_hpp"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.ana_hpp
                                                    ? values?.ana_hpp
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />

                                        <Label className="mt-2">
                                            Histórico familiar
                                        </Label>

                                        <FastField
                                            name="ana_hf"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.ana_hf
                                                    ? values?.ana_hf
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />

                                        <Label className="mt-2">
                                            Comorbidades
                                        </Label>
                                        <FastField
                                            name="disease"
                                            placeholder="Adicione"
                                            cacheOptions
                                            defaultOptions
                                            handleCheck={handleCheck}
                                            isMulti
                                            component={AsyncSelectInput}
                                            formatCreateLabel={
                                                formatCreateLabel
                                            }
                                            value={
                                                values?.disease
                                                    ? values?.disease
                                                    : ""
                                            }
                                            isDisabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                            noOptionsMessage={() =>
                                                "Sem Resultados ou item já listado. Digite para Adicionar..."
                                            }
                                        />

                                        <Label className="mt-2">Alergias</Label>
                                        <FastField
                                            name="allergy"
                                            placeholder="Adicione"
                                            cacheOptions
                                            defaultOptions
                                            handleCheck={handleCheck}
                                            isMulti
                                            component={AsyncSelectInput}
                                            formatCreateLabel={
                                                formatCreateLabel
                                            }
                                            value={
                                                values?.allergy
                                                    ? values?.allergy
                                                    : ""
                                            }
                                            isDisabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                            noOptionsMessage={() =>
                                                "Sem Resultados ou item já listado. Digite para Adicionar..."
                                            }
                                        />

                                        <Label className="mt-2">
                                            Medicamentos em uso
                                        </Label>

                                        <FastField
                                            name="medicationsuse"
                                            placeholder="Adicione"
                                            cacheOptions
                                            defaultOptions
                                            handleCheck={handleCheck}
                                            formatCreateLabel={
                                                formatCreateLabel
                                            }
                                            classNamePrefix="select2-selection"
                                            isMulti
                                            component={AsyncSelectInput}
                                            value={
                                                values?.medicationsuse
                                                    ? values?.medicationsuse
                                                    : ""
                                            }
                                            isDisabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                            noOptionsMessage={() =>
                                                "Sem Resultados ou item já listado. Digite para Adicionar..."
                                            }
                                        />
                                    </CardBody>
                                </Card>

                                <Card innerRef={cardEfRef}>
                                    <CardBody>
                                        <CardTitle>Exame Físico</CardTitle>
                                        <Label className="mt-2">
                                            Exame Físico
                                        </Label>

                                        <FastField
                                            name="exam_physical"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.exam_physical
                                                    ? values?.exam_physical
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />
                                    </CardBody>
                                </Card>
                                <Card innerRef={cardHdRef}>
                                    <CardBody>
                                        <CardTitle>Diagnóstico</CardTitle>
                                        <Label className="mt-2">
                                            Hipóteses diagnosticadas
                                        </Label>

                                        <FastField
                                            name="diag_hypotheses"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.diag_hypotheses
                                                    ? values?.diag_hypotheses
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />

                                        <Label className="mt-2">
                                            Diagnóstico definitivo
                                        </Label>

                                        <FastField
                                            name="diag_definitive"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.diag_definitive
                                                    ? values?.diag_definitive
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />
                                        <Label className="mt-2">
                                            Problema - CID 10
                                        </Label>
                                        <FastField
                                            name="cid"
                                            defaultOptions
                                            placeholder="Buscar"
                                            loadOptions={debounceOptionsCid}
                                            component={AsyncSelectInputt}
                                            handleCheck={handleCheck}
                                            value={values.cid}
                                            isDisabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />
                                    </CardBody>
                                </Card>
                                <Card innerRef={cardTratamentoRef}>
                                    <CardBody>
                                        <CardTitle>Tratamento</CardTitle>
                                        <Label className="mt-2">
                                            Tratamento
                                        </Label>
                                        <FastField
                                            name="diag_treatment"
                                            component={TextInput}
                                            handleCheck={handleCheck}
                                            value={
                                                values?.diag_treatment
                                                    ? values?.diag_treatment
                                                    : ""
                                            }
                                            disabled={
                                                values.status === 0
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                values.status === 0
                                                    ? {
                                                          cursor: "not-allowed",
                                                          background: "#f8f9fa",
                                                      }
                                                    : { cursor: "text" }
                                            }
                                        />
                                    </CardBody>
                                </Card>

                                <Card innerRef={cardPedidoRef}>
                                    <CardBody>
                                        <MedicalExams
                                            items={values}
                                            defaultItem={defaultItemValues}
                                            stateSync={setSyncState}
                                            datasyncstate={setDataSyncState}
                                            attendance_id={attendance_id}
                                            patient_id={patient_id}
                                            errors={errors}
                                            touched={touched}
                                            professional={
                                                pageData?.professional
                                            }
                                            patient={pageData?.patient}
                                            unit={pageData?.unit}
                                        />
                                    </CardBody>
                                </Card>
                                <Card innerRef={cardPrescRef}>
                                    <CardBody>
                                        <MedicalPrescritions
                                            items={values}
                                            defaultItem={
                                                defaultPrescriptionItemsValues
                                            }
                                            stateSync={setSyncState}
                                            datasyncstate={setDataSyncState}
                                            attendance_id={attendance_id}
                                            patient_id={patient_id}
                                            errors={errors}
                                            touched={touched}
                                            professional={
                                                pageData?.professional
                                            }
                                            patient={pageData?.patient}
                                            unit={pageData?.unit}
                                        />
                                    </CardBody>
                                </Card>

                                <Card innerRef={cardEvolutionRef}>
                                    <CardBody>
                                        <CardTitle>Evoluções</CardTitle>

                                        <FieldArray
                                            name="itemsEvolution"
                                            render={arrayHelpers => (
                                                <>
                                                    {values &&
                                                    values?.itemsEvolution
                                                        ?.length > 0
                                                        ? values?.itemsEvolution?.map(
                                                              (item, index) => (
                                                                  <Row
                                                                      key={
                                                                          index
                                                                      }
                                                                      className="mb-3 align-items-center"
                                                                  >
                                                                      <Col sm="11">
                                                                          <Label className="mt-2">
                                                                              Evolução
                                                                              -
                                                                              #
                                                                              {index +
                                                                                  1}
                                                                          </Label>
                                                                          <Field
                                                                              name={`itemsEvolution[${index}].evolution`}
                                                                              component={
                                                                                  TextInput
                                                                              }
                                                                              handleCheck={
                                                                                  handleCheck
                                                                              }
                                                                              value={
                                                                                  item?.evolution
                                                                              }
                                                                              entityID={
                                                                                  item?.id
                                                                              }
                                                                              stateSync={
                                                                                  setSyncState
                                                                              }
                                                                              datasyncstate={
                                                                                  setDataSyncState
                                                                              }
                                                                              attendance_id={
                                                                                  attendance_id
                                                                              }
                                                                              lastState={
                                                                                  lastStateEvolution
                                                                              }
                                                                              disabled={
                                                                                  values.status ===
                                                                                  0
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              style={
                                                                                  values.status ===
                                                                                  0
                                                                                      ? {
                                                                                            cursor:
                                                                                                "not-allowed",
                                                                                            background:
                                                                                                "#f8f9fa",
                                                                                        }
                                                                                      : {
                                                                                            cursor:
                                                                                                "text",
                                                                                        }
                                                                              }
                                                                          />
                                                                          <hr />
                                                                      </Col>

                                                                      {values?.status ===
                                                                      0 ? (
                                                                          ""
                                                                      ) : (
                                                                          <Col sm="1">
                                                                              <Button
                                                                                  id={
                                                                                      "trash1" +
                                                                                      index
                                                                                  }
                                                                                  color="danger"
                                                                                  className="inner"
                                                                                  onClick={async () => {
                                                                                      try {
                                                                                          await handleDelete(
                                                                                              item
                                                                                          )

                                                                                          arrayHelpers.remove(
                                                                                              index
                                                                                          )
                                                                                      } catch (e) {
                                                                                          console.log(
                                                                                              e
                                                                                          )
                                                                                      }
                                                                                  }}
                                                                              >
                                                                                  <i className="fas fa-trash-alt"></i>
                                                                                  <UncontrolledTooltip
                                                                                      placement="top"
                                                                                      target={
                                                                                          "trash1" +
                                                                                          index
                                                                                      }
                                                                                  >
                                                                                      Clique
                                                                                      para
                                                                                      excluir
                                                                                  </UncontrolledTooltip>
                                                                              </Button>
                                                                          </Col>
                                                                      )}
                                                                  </Row>
                                                              )
                                                          )
                                                        : null}
                                                    {values?.status == 0 ? (
                                                        ""
                                                    ) : (
                                                        <Button
                                                            id="iconedittooltip1"
                                                            color="info"
                                                            onClick={async () => {
                                                                try {
                                                                    let item = await handleCreate()
                                                                    if (
                                                                        item?.id
                                                                    ) {
                                                                        arrayHelpers.push(
                                                                            item
                                                                        )
                                                                    }
                                                                } catch (e) {
                                                                    console.log(
                                                                        e
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <i className="fas fa-plus"></i>
                                                            <UncontrolledTooltip
                                                                placement="left"
                                                                target={
                                                                    "iconedittooltip1"
                                                                }
                                                            >
                                                                Clique para
                                                                adicionar
                                                            </UncontrolledTooltip>
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </CardBody>
                                </Card>

                                <Card innerRef={cardAtestadoRef}>
                                    <CardBody>
                                        <MedicalCertificate
                                            items={values}
                                            defaultItem={
                                                defaultReportItemsValues
                                            }
                                            datasyncstate={setDataSyncState}
                                            stateSync={setSyncState}
                                            attendance_id={attendance_id}
                                            patient_id={patient_id}
                                            errors={errors}
                                            touched={touched}
                                            professional={
                                                pageData?.professional
                                            }
                                            patient={pageData?.patient}
                                            unit={pageData?.unit}
                                        />
                                    </CardBody>
                                </Card>

                                <Card innerRef={cardLaudoRef}>
                                    <CardBody>
                                        <CardTitle>Laudos</CardTitle>

                                        <MedicalReports
                                            items={values}
                                            defaultItem={
                                                defaultItemsCertificatesValues
                                            }
                                            datasyncstate={setDataSyncState}
                                            stateSync={setSyncState}
                                            errors={errors}
                                            touched={touched}
                                            professional={
                                                pageData?.professional
                                            }
                                            patient={pageData?.patient}
                                            unit={pageData?.unit}
                                        />
                                    </CardBody>
                                </Card>
                                <Card innerRef={cardForwardRef}>
                                    <CardBody>
                                        <MedicalReferral
                                            items={values}
                                            defaultItem={
                                                defaultItemsCertificatesValues
                                            }
                                            attendance_id={attendance_id}
                                            patient_id={patient_id}
                                            datasyncstate={setDataSyncState}
                                            stateSync={setSyncState}
                                            errors={errors}
                                            touched={touched}
                                            professional={
                                                pageData?.professional
                                            }
                                            patient={pageData?.patient}
                                            unit={pageData?.unit}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {values.status === 1 ? (
                            <Row>
                                <Col sm="2"></Col>
                                <Col sm="10">
                                    <div className="d-flex justify-content-between mb-3 mt-3">
                                        <Button disabled={loading} onClick={() => {
                                            viewForm(values)
                                        }
                                        } color="primary" className="mr-2" type="button">
                                           {loading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="sr-only">Loading...</span>
                                            </>
                                            ) : (
                                            <>
                                            <i className="fas fa-print fa-lg mr-1"></i> Imprimir Documentos
                                            </>
                                            )}
                                        </Button>
                                        <div>
                                            <Button
                                                color="info"
                                                className="mr-2"
                                                type="submit"
                                            >
                                                <i className="fas fa-check fa-lg mr-1"></i> Finalizar Atendimento{" "}
                                            </Button>
                                            <Button
                                                color="dark"
                                                className=" mr-2"
                                                onClick={() => {
                                                    handleSaveService()
                                                }}
                                                type="button"
                                            >
                                                <i className="fas fa-sync-alt fa-lg mr-1"></i> Continuar depois
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col sm="2"></Col>
                                <Col sm="10">
                                    <div className="d-flex justify-content-between mb-3 mt-3">
                                        <Button
                                            onClick={() => {
                                                viewForm(values)
                                            }}
                                            color="primary"
                                            className="mr-2"
                                            type="button"
                                        >
                                        <i className="fas fa-print fa-lg mr-1"></i> Imprimir Documentos 
                                        </Button>
                                        <div>
                                            <Link
                                                className="btn btn-dark mr-2"
                                                to={
                                                    "/pacientes/" +
                                                    patient_id +
                                                    "/atendimentos"
                                                }
                                            >
                                                <i className="fas fa-file-medical-alt fa-lg mr-1"></i>  Lista de Atendimentos
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default GeneralAttendances
