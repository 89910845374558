import React, { useEffect, useState, useRef } from "react"
import { useParams, Link } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Form,
    Input,
    UncontrolledTooltip,
} from "reactstrap"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PrescriptionAttendanceColumns from "pages/Patients/Detail/pages/GeneralAttendance/ModalModels/Components/indexColumns"

//import do services de usar dps alterar para os corretos

import { useDispatch } from "react-redux"
import { searchModels as searchModels } from "services/vitta-core/medical-forms-models"
import RenderForm from "../../components/renderForm"
import {deleteModel as deleteModel} from "services/vitta-core/medical-forms-models"
import ModalExamItemCheck from "./ModalExamItemCheck"
const listPrescriptions = ({ props, entity_type, handleModel, values, onClose}) => {
    const [list, setList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })
    const [modalExamItemCheck, setModalExamItemCheck] = useState({
        show: false,
        data: null
    })

    const inputRefSearch = useRef()
    const { SearchBar } = Search

    const dispatch = useDispatch();

    const deleteRecord = async id => {
        
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            await deleteModel(id);

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )
            onClose()
        } catch (e) {
    
            if(e?.response?.data?.code == "not_permission")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Você não tem permissão!",
                        text:
                            "Só é possível excluir os modelos que você criou.",
                        type: "error",
                    })
                )
                return;
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    const search = async (filter, page) => {
        let sizePerPage = 10
        let termSearch = null;
        try {
           
            const form_type = entity_type ? entity_type : ""
            let termSearch = inputRefSearch?.current?.value;

            if(inputRefSearch?.current?.value)
            {
                termSearch=inputRefSearch?.current?.value;
            }
            
       
            let record = await searchModels({
                form_type: form_type,
                term:termSearch,
                specialty_id:values.prof.specialty_id
            },page, sizePerPage)

            setList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: record?.data,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "" }, 1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }
    const RenderModel = async data => {
       if(data.form && data.form.length>0){
            data.form = data.form.sort((a,b)=>{
                if(a.item.name<b.item.name) return -1
                if(a.item.name>b.item.name) return 1
                return 0
            })
            setModalExamItemCheck({
                show: true,
                data: data,
            })
       }else{
        handleModel(data)
       }  
    }

    const RenderMedicalForm = async data => {
        let aux ={...data, unit: values.unit}
        let content = {...aux}
        const professional = {...values.prof};
        const patient ={ ...values.patient};
        const showProfessionalSignature = false;
        const ShowPatientData = true;
        const showPrintButton = false;
        
        RenderForm({showProfessionalSignature,ShowPatientData,showPrintButton,patient,professional,content});
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card className="shadow-lg pr-3 pl-3 pb-3 mb-1 bg-white rounded">
                        <CardBody>
                            <Row className="mb-2">
                                <Col sm="8">
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            search()
                                        }}
                                    >
                                        <div className="search-box mr-2 d-inline-block">
                                            <div className="position-relative">
                                                <Input
                                                    type="text"
                                                    style={{paddingRight:'75px'}}
                                                    innerRef={inputRefSearch}
                                                ></Input>
                                                <i className="bx bx-search-alt search-icon" />
                                            </div>
                                        </div>
                                        <Button
                                            type="button"
                                            color="dark"
                                            id="tooltipprescript"
                                            onClick={()=>{search()}}
                                        >
                                            <span className="text-white">
                                                {" "}
                                                <i className="bx bx-search-alt fa-lg"></i>
                                            </span>
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"tooltipprescript"}
                                            >
                                                {" "}
                                                Clique para buscar
                                            </UncontrolledTooltip>
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                            <PaginationProvider
                                pagination={paginationFactory(list)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={list.data || []}
                                        columns={PrescriptionAttendanceColumns(
                                            RenderMedicalForm,
                                            RenderModel,deleteRecord
                                        )}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={true}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap "
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-dark"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalExamItemCheck
            show={modalExamItemCheck.show}
            data={modalExamItemCheck.data}
            onClose={() => {
                setModalExamItemCheck({
                    show: false,
                    data: null,
                })
            }}
            handleModel = {handleModel}
            >

            </ModalExamItemCheck>
        </React.Fragment>
    )
}

export default listPrescriptions
