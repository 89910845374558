import { useDispatch } from "react-redux"
import { downloadInvoiceNfsePdf } from "services/vitta-core/professional-transfer-invoices"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useState } from "react"

const DownloadInvoiceNfseButton = ({ id, isVisible }) => {
    const [disabled, setDisabled] = useState(false)

    if (!isVisible) return <></>

    const dispatch = useDispatch()

    const performDownload = async () => {
        try {
            setDisabled(true)

            dispatch(
                showToast({
                    title: "Baixando Nota Fiscal",
                    text: "Aguarde...",
                    type: "info",
                })
            )
            await downloadInvoiceNfsePdf(id)
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Não foi possível fazer o download este arquivo.",
                    type: "error",
                    showConfirm: true,
                })
            )
        } finally {
            console.log("finally")
            setDisabled(false)
        }
    }

    return (
        <button
            className="btn btn-primary"
            onClick={() => {
                performDownload()
            }}
            style={{ width: "100%" }}
            disabled={disabled}
        >
            Imprimir NFSe
        </button>
    )
}

export default DownloadInvoiceNfseButton
