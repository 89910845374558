import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, Spinner, UncontrolledTooltip } from "reactstrap"

import PROF_INVOICES_STATUS from "utils/consts/prof-transfer-invoice-status"
import PROF_INVOICES_FISCAL_STATUS from "utils/consts/prof-transfer-invoice-fiscal-status"
const TransferNotesColumns = (ModalInvoices, key) => {
    return [
        {
            isDummyField: true,
            text: "ID Nota",
            formatter: (cellContent, row) => row.id,
        },
        {
            dataField: "created_at",
            text: "Data Nota",
            formatter: (cellContent, row) =>
                new Date(row?.created_at).toLocaleDateString("pt-BR"),
        },
        // {
        //     dataField: "os_id",
        //     text: "ID OS",
        //     formatter: (cellContent, row) => (
        //         <Link
        //             to={"/ordens-servico/" + cellContent}
        //             target="_blank"
        //             className="mr-3 text-primary"
        //         >
        //             {cellContent}
        //         </Link>
        //     ),
        // },
        {
            dataField: "exec_at",
            text: "Execução",
            formatter: (cellContent, row) =>
                new Date(row?.exec_at + " 00:00:00").toLocaleDateString(
                    "pt-BR"
                ),
        },
        {
            dataField: "professional.name",
            text: "Profissional",
        },
        {
            isDummyField: true,
            text: "Items",
            formatter: (id, row) => {
                return row?.items?.map(data => {
                    return (
                        <>
                            {data.item.name} ({data.quantity}) - R${" "}
                            {new Intl.NumberFormat("pt-br", {
                                style: "decimal",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(
                                (data.price ? data.price : 0) / 100
                                // data.quantity *
                                //     ((data.price ? data.price : 0) / 100)
                            )}
                            <br />
                        </>
                    )
                })
            },
        },
        {
            isDummyField: true,
            text: "Total",
            formatter: (id, row) => {
                let total = 0
                for (let i = 0; i < row?.items?.length; i++) {
                    let useItem = row.items[i]
                    total +=
                        useItem.quantity * (useItem.price ? useItem.price : 0)
                }

                return (
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(total / 100)
                )
            },
        },
        {
            dataField: "patient.name",
            text: "Paciente",
        },
        {
            dataField: "unit.name",
            text: "Unidade",
        },
        {
            dataField: "status_fiscal",
            text: "Status Fiscal",
            formatter: (cellContent, row) => {
                let text = ""
                let badgeClass = "badge-soft-success"
                switch (row?.fiscal_status) {
                    case PROF_INVOICES_FISCAL_STATUS.OPEN:
                        text = " Aberta"
                        badgeClass = "badge-soft-info"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.ISSUED:
                        text = " Emitida"
                        badgeClass = "badge-soft-success"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.FAILED:
                        text = " Falha"
                        badgeClass = "badge-soft-danger"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.REJECTED:
                        text = " Rejeitada"
                        badgeClass = "badge-soft-danger"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.CANCELLED:
                        text = " Cancelada"
                        badgeClass = "badge-soft-danger"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.REPLACED:
                        text = " Substituída"
                        badgeClass = "badge-soft-danger"
                        break

                    case PROF_INVOICES_FISCAL_STATUS.TRANSMITTING:
                        text = " Transmitindo"
                        badgeClass = "badge-soft-info"
                        break
                }

                return (
                    <Badge className={"font-size-12 " + badgeClass} pill>
                        {text}
                    </Badge>
                )
            },
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cellContent, row) => {
                let text = ""
                let badgeClass = "badge-soft-success"
                switch (cellContent) {
                    case PROF_INVOICES_STATUS.PAID:
                        text = "Paga"
                        badgeClass = "badge-soft-success"
                        break
                    case PROF_INVOICES_STATUS.OPEN:
                        text = "Aberta"
                        badgeClass = "badge-soft-warning"
                        break
                    case PROF_INVOICES_STATUS.CANCELED:
                        text = "Cancelada"
                        badgeClass = "badge-soft-danger"
                        break
                    // case PROF_INVOICES_STATUS.PARTIALLY_PAID:
                    // text = "Parcialmente Paga"
                    // badgeClass = "badge-soft-secondary"
                    // break
                }

                return (
                    <Badge className={"font-size-12 " + badgeClass} pill>
                        {text}
                    </Badge>
                )
            },
        },

        {
            isDummyField: true,
            text: "Ações",
            formatter: (id, row) => (
                <>
                    <Link
                        to="#"
                        className="mr-3  ml-1 text-primary "
                        onClick={() => {
                            ModalInvoices(row)
                        }}
                    >
                        <i
                            className="far fa-eye font-size-22"
                            id={"invoicetooltip" + row.id}
                        ></i>
                        <UncontrolledTooltip
                            placement="top"
                            target={"invoicetooltip" + row.id}
                        >
                            Detalhes
                        </UncontrolledTooltip>
                    </Link>

                    {/* {row?.status == PROF_INVOICES_STATUS.OPEN ? (
                        <Link
                            to="#"
                            className="mr-3 text-danger"
                            onClick={() => {
                                CancelInvoice(row.id)
                            }}
                        >
                            <i
                                className="fas fa-times-circle font-size-22 dark"
                                id={"cancelinvoicetooltip" + row.id}
                            ></i>
                            <UncontrolledTooltip
                                placement="top"
                                target={"cancelinvoicetooltip" + row.id}
                            >
                                Cancelar Nota
                            </UncontrolledTooltip>
                        </Link>
                    ) : null} */}

                    {/* {row?.status == PROF_INVOICES_STATUS.PAID ? (
                        <Link
                            to="#"
                            className="mr-3 text-danger"
                            onClick={() => {
                                CancelInvoicePayment(row.id)
                            }}
                        >
                            <i
                                className="fas fa-times-circle font-size-22 dark"
                                id={"cancelinvoicetooltip" + row.id}
                            ></i>
                            <UncontrolledTooltip
                                placement="top"
                                target={"cancelinvoicetooltip" + row.id}
                            >
                                Cancelar Pagamento
                            </UncontrolledTooltip>
                        </Link>
                    ) : null} */}
                </>
            ),
        },
    ]
}

export default TransferNotesColumns
