// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SHOW_SWEET_ALERT_DIALOG,
  HIDE_SWEET_ALERT_DIALOG,
  SHOW_TOAST,
  HIDE_TOAST,
  TOGGLE_SELECT_UNIT_SCOPE_MODAL
} from "./actionTypes"

const sweetAlertDialogDefaultState = {
  show: false,
  title: "",
  text: "",
  type: "info",
  onConfirmAction:() => { },
  onCancel: () => { },
  showCancel: false,
  showConfirm: false,
  cancelBtnText: "Fechar",
  confirmBtnText: "OK",
  closeOnClickOutside: true
}
const ToastNotificationDefaultState = {
  show: false,
  title: "",
  text: "",
  type: "success",
  options: {
    preventDuplicates: true,
    hideDuration: 1000,
    showDuration: 300,
    timeOut: 5000,
    onHidden: ()=> {},
    onRedirect: ()=>{}
  },
 
}

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "light",
  leftSideBarType:"default",
  topbarTheme: "dark",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: false,
  leftMenu: true,
  sweetAlertDialog: {
    ...sweetAlertDialogDefaultState
  },
  toastNotification: {
    ...ToastNotificationDefaultState
  },
  selectUnitScopeModal: {
    show: false
  }
}


const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    case SHOW_SWEET_ALERT_DIALOG:
      return {
        ...state,
        sweetAlertDialog: { ...sweetAlertDialogDefaultState, ...action.payload, ...{ show: true } }  //clonando defaultsate e alertdialog e garantindo que o show é true
      }
    case HIDE_SWEET_ALERT_DIALOG:
      return {
        ...state,
        sweetAlertDialog: { ...sweetAlertDialogDefaultState, ...{ show: false } }
      }
    case SHOW_TOAST:
      return {
        ...state,
        toastNotification: { ...ToastNotificationDefaultState, ...action.payload, ...{ show: true } }
      }
    case HIDE_TOAST:
      return {
        ...state,
        toastNotification: { ...ToastNotificationDefaultState }
      }
    case TOGGLE_SELECT_UNIT_SCOPE_MODAL:
      return {
        ...state,
        selectUnitScopeModal: { show: action.payload },
    }
    default:
      return state
  }
}

export default Layout
