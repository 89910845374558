import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

/**
 * Mapeamento das origens de processos.
 * @type {Object<number, string>}
 */
const processOriginMap = {
    1: "Abertura de Caixa",
    2: "Fechamento de Caixa",
    3: "Recebimento de Cliente",
    4: "Depósito Bancário",
    5: "Subsídio Médico",
    6: "Transferencia entre Caixas",
    7: "Máquina de Café",
    8: "Cancelamento de Pagamento do Cliente",
    9: "Pagamento de Repasse",
    10: "Cancelamento Pagamento de Repasse",
    11: "Gastos Diversos",
};

/**
 * Mapeamento dos tipos de movimentação de caixa.
 * @type {Object<string, string>}
 */
const typeMap = {
    I: "Entrada",
    O: "Saída",
};

/**
 * Gera as colunas para a tabela de Movimentações de Caixa.
 * 
 * @returns {Array<Object>} - Array de objetos representando as colunas da tabela.
 */
const CashMovesTableColumns = () => [
    {
        dataField: "type",
        text: "Tipo",
        /**
         * Formata a célula da coluna de tipo.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Texto formatado do tipo de movimentação.
         */
        formatter: (cellContent, row) => typeMap[row.type] || "Desconhecido",
    },
    {
        dataField: "unit_id",
        text: "Unidade",
        /**
         * Formata a célula da coluna de unidade.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Nome da unidade ou "N/A" se não disponível.
         */
        formatter: (cellContent, row) => row.unit.name || "N/A",
    },
    {
        dataField: "amount",
        text: "Valor",
        /**
         * Formata a célula da coluna de valor.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Valor formatado em reais.
         */
        formatter: (cellContent, row) => `R$ ${(row?.amount /100)?.toFixed(2)}`,
    },
    {
        dataField: "process_id",
        text: "Processo",
        /**
         * Formata a célula da coluna de processo.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Nome do processo de origem ou "N/A" se não disponível.
         */
        formatter: (cellContent, row) => processOriginMap[row.process_id] || "N/A",
    },
    {
        dataField: "description",
        text: "Descrição",
        /**
         * Formata a célula da coluna de descrição.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Descrição da movimentação ou "N/A" se não disponível.
         */
        formatter: (cellContent, row) => row.description || "N/A",
    },
    {
        dataField: "created_user_id",
        text: "Usuário Criador",
        /**
         * Formata a célula da coluna de usuário criador.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Nome do usuário criador ou "N/A" se não disponível.
         */
        formatter: (cellContent, row) => row.created_user.name || "N/A",
    },
    {
        dataField: "created_at",
        text: "Criado em",
        /**
         * Formata a célula da coluna de data de criação.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {string} - Data de criação formatada.
         */
        formatter: (cellContent, row) => new Date(row.created_at).toLocaleDateString('pt-BR'),
    },
    {
        isDummyField: true,
        text: "Ações",
        /**
         * Renderiza a célula de ações com o ícone de edição.
         * 
         * @param {string} cellContent - Conteúdo da célula.
         * @param {Object} row - Dados da linha.
         * @returns {React.Element} - Link com ícone para editar o registro.
         */
        formatter: (cellContent, row) => (
            <Link to={`/controle-caixa/${row.id}/editar`}>
                <i className="fas fa-edit font-size-24 mr-3" id={"edittooltip" + row.id} />
                <UncontrolledTooltip placement="top" target={"edittooltip" + row.id}>
                    Editar
                </UncontrolledTooltip>
            </Link>
        ),
    },
];

export default CashMovesTableColumns;
