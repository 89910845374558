
import React, { useState, useEffect }  from "react"
import {
    Row,
    Col,
    Modal,
    Label,
    Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import { Formik, Field, Form } from 'formik';

import Select from "react-select"

import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

import {
    analyze as ApprovalRequestAnalyze,
  } from "services/vitta-core/approval-request"

import APPROVAL_REQUESTS_STATUS from "utils/consts/approval-requests-status"

const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="textarea" rows="1" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select {...field} {...props} onChange={option => setFieldValue(field.name, option)}/>
)

const ModalApprovalAnalyze = ({ show, data, onClose }) =>
{
    const defaultValues = {
        obs: "",
        status: null
    }

    const [pageData, setPageData] = useState({
        form_data: defaultValues,
        approval_data: {},
        operations: {}
    })

    const dispatch = useDispatch()

    useEffect(() => {

        const loadData = async () => {

            try
            {
                let auxPageData = {...pageData};
                auxPageData.approval_data = data;
                setPageData(auxPageData);

            }catch(e)
            {
                console.log(e);
                onClose(pageData.operations);
            }
        }

        if(data?.id) loadData();
        
    }, [data]);

    const analyzed = async (id, status, obs) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja " + (status == APPROVAL_REQUESTS_STATUS.APPROVED ? "aprovar" : "reprovar")  + " esta solicitação?",
                type: "warning",
                onConfirmAction: () => {
                    confirmAnalyzed(id, status, obs)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmAnalyzed = async (id, status, obs) => {
        try
        {
            await ApprovalRequestAnalyze(id, {
                status: status,
                analyzed_obs: obs
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Solicitação finalizada com Sucesso!",
                    type: "success",
                })
            )

            pageData.operations.analyzed = true;
            onClose(pageData.operations);
        }
        catch(e)
        {
            console.log(e);
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true
                })
            )
        }
    }

    const onSubmit = async (data_form) =>
    {
        if(!data_form.status || data_form.status.value == null || data_form.status.value == undefined)
        {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Informe o resultado da análise da solicitação.",
                    type: "warning",
                    showConfirm: true
                })
            )
        }
        analyzed(pageData.approval_data.id, data_form.status.value, data_form.obs);
    }

    return (
        <Modal
            isOpen={show}
           centered={true}
           size="md"
        >
            <Formik
                initialValues={pageData.form_data}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                <Form>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                        Solicitação de Aprovação
                        </h5>
                        <button
                        type="button"
                        onClick={() => {
                            onClose(pageData.operations);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col sm="12" className="mt-2">
                                <strong>Solicitação:</strong> #{pageData.approval_data?.id}
                            </Col>
                            <Col sm="12" className="mt-2">
                                <strong>Solicitante:</strong> {pageData.approval_data.requested_user?.name}
                            </Col>
                            <Col sm="12" className="mt-2">
                                <strong>Data Solicitação:</strong> {new Date(pageData.approval_data?.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"})}
                            </Col>

                        {
                            pageData.approval_data.entity_type == "sales_orders" ? 
                            <>
                                <Col sm="12" className="mt-2">
                                    <strong>Objeto da Solicitação:</strong> Pedido de Venda
                                </Col>
                                <Col sm="12"className="mt-2">
                                    <strong>Motivo:</strong> Desconto Fornecido
                                </Col>

                                <Col sm="12" className="mt-2">
                                    <strong>Detalhes: {" "}</strong>
                                     <Link
                                        to={"/pedidos/" + pageData.approval_data?.entity_id}
                                        className="mr-3 text-primary"
                                        target={"_blank"}
                                    >
                                        Visualizar Pedido
                                    </Link>
                                </Col>
                            </>
                            : null
                        }

                        </Row>

                        <br/>
                        <br/>

                        <Row>
                        <Col sm="12">
                            <Label>Resultado</Label>
                            <Field
                                    name="status"
                                    placeholder="Selecione..."
                                    component={ReactSelectInput}
                                    options={[
                                        {
                                            label: "Aprovado", value: APPROVAL_REQUESTS_STATUS.APPROVED
                                        },
                                        {
                                            label: "Reprovado", value: APPROVAL_REQUESTS_STATUS.DENIED
                                        }
                                        ] }
                                    isClearable
                                /> 
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm="12">
                                <Label>Observação</Label>
                                <Field
                                    name="obs"
                                    rows={3}
                                    component={
                                        TextAreaInput
                                    }
                                />
                            </Col>
                        </Row>

                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                            >
                            Confirmar
                        </button>

                        <button
                            type="button"
                            onClick={() => {
                                onClose(pageData.operations);
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                            >
                            Fechar
                        </button>
                    </div>
              </Form>
          </Formik>          
            
        </Modal>
    )
}

export default  ModalApprovalAnalyze