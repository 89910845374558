import React, { useEffect, useState, useRef,useMemo } from "react"
import {useDispatch,useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Container, Row,Input,Label } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"
import {
  search as SalesQuoteOrdersServiceSearch,
  deleteSalesDoc,
} from "services/vitta-core/sales-quote"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { findUnit } from "services/vitta-core/unit"
import { findPatient } from "services/vitta-core/patient"

import debounce from "debounce-promise";
const today = new Date().toLocaleDateString('pt-BR');
const todayOnlyDate = today.substr(6,4) + "-" + today.substr(3,2) + "-" + today.substr(0,2)

const SalesQuotes = () => {

  const [SalesQuoteOrdersList, setSalesQuoteOrdersList] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 10,
    custom: true,
    data: [],
  })

  const inputPatient = useRef();
  const inputStartCreatedAt = useRef();
  const inputRefUnit = useRef();
  const inputEndCreatedAt = useRef();
  const User = useSelector(state => state.auth.access.user);
  const dispatch = useDispatch()

  const Scope = useSelector(state => state.scope)
  const history = useHistory()
  const search = async (filter, page) => {
    let sizePerPage = 10
    try {
      filter.unit_id = filter.unit_id  ? filter.unit_id  : Scope.unit.id
      
        let data = await SalesQuoteOrdersServiceSearch(
            filter,
            page,
            sizePerPage
        )


        setSalesQuoteOrdersList({
            page: page,
            sizePerPage: sizePerPage,
            totalSize: data.total_pages*sizePerPage,
            custom: true,
            data: data.data,
        })
    } catch (e) {
        console.log("error", e)
    }
}

const deleteRecord = async id => {
    dispatch(
        showSweetAlertDialog({
            title: "Atenção ",
            text: "Tem certeza que deseja excluir este registro?",
            type: "warning",
            onConfirmAction: () => {
                confirmDeleteRecord(id)
            },
            showCancel: true,
            showConfirm: true,
            cancelBtnText: "Não",
            confirmBtnText: "Sim",
        })
    )
}

const confirmDeleteRecord = async id => {
    try {
        await deleteSalesDoc(id)

        dispatch(
            showToast({
                title: "Sucesso",
                text: "Registro Excluído com Sucesso!",
                type: "success",
            })
        )

        searchSales(SalesQuoteOrdersList.page)

        history.push("/orcamentos")
    } catch (e) {
        dispatch(
            showSweetAlertDialog({
                title: "Ops... ",
                text:
                    "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                type: "error",
            })
        )
        console.log(e)
    }
}

useEffect(() => {
  let page = 1
        
  if(location.search)
  {
      handleQuery();
  }

  else
  {
      searchSales(page)
  }
  
}, [])


const searchSales = (page = 1) => {

  let searchObj = {};
  let stateObj = null;

  let patient_id = inputPatient.current.select?.state?.value?.value;
  let start_created_at = inputStartCreatedAt.current.value;
  let end_created_at = inputEndCreatedAt.current.value;

 
  searchObj.expand_patient = true;
  searchObj.expand_user = true
  searchObj.unit_id = inputRefUnit.current?.state?.value?.value;

  if(patient_id) searchObj.patient_id = patient_id;
  if(start_created_at) searchObj.start_created_at = start_created_at;
  if(end_created_at) searchObj.end_created_at = end_created_at;
  

  stateObj = {
    unit_id: inputRefUnit.current?.state?.value
        ? inputRefUnit.current?.state?.value
        : null,
    patient_id: inputPatient.current.select?.state?.value
        ? inputPatient.current.select?.state?.value
        : null,
    start_created_at: inputStartCreatedAt.current.value
        ? inputStartCreatedAt.current.value
        : null,
    end_created_at: inputEndCreatedAt.current.value
        ? inputEndCreatedAt.current.value
        : null,
    page: page,
}


const query = `unit_id=${inputRefUnit.current?.state?.value?.value}${
  patient_id ? "&patient_id=" + patient_id : ""
}${start_created_at ? "&start_created_at=" + start_created_at : ""}${
  end_created_at ? "&end_created_at=" + end_created_at : ""
}${
  start_created_at ? "&start_created_at=" + start_created_at : ""
}${end_created_at ? "&end_created_at=" + end_created_at : ""}${
  page ? "&page=" + page : ""
}`

      history.push({
        pathname: "/orcamentos",
        search: query,
        state: stateObj,
      })
  search(searchObj, page)
};

const onTableChange = (a,b,c) => {
  if(a == "pagination")
  {
    searchSales(b.page)
  }
}

const loadPatientInputOptions = async (inputValue) => {
  return new Promise(async (resolve, reject) => {
      try
      { 
          if(!inputValue) return resolve([])

          let response = await patientServiceSearch({term: inputValue, fields:"id,name,cpf" }, 1, 20);

          let patients_values = []

          response.data.forEach(element => {
            patients_values.push({
                  label: `${element.id} - ` + element.name + (element.cpf ? ` <${element.cpf}>` : ''),
                  value: element.id,
              })
          })

          return resolve(patients_values);
      }
      catch(e)
      {
          console.error(e);
          return reject([]);
      }
  });
}
const unitsOptions = useMemo(() => {
  if (!User?.units) return []

  let options_list = []
  User.units.forEach(element => {
      options_list.push({ label: `#${element.id} ` + element.name?.substr(0,40), value: element.id })
  })
  return options_list
}, [User.units])


const debounceOptionsPatient = debounce(loadPatientInputOptions,600)
const handleQuery = async ()=>
{
    let patient = null;
    let unit =  null;
  
    

    let search = location.search
    const params = new URLSearchParams(search)
    const unit_id = params.get("unit_id");
    const patient_id = params.get("patient_id");
    const start_created_at = params.get("start_created_at");
    const end_created_at = params.get("end_created_at");
    const page = params.get("page") ? parseFloat(params.get("page"))  : 1;

    if(unit_id)
    {
        unit = await findUnit(unit_id);
        if(unit)
        {
            inputRefUnit.current.state.value = {label:unit?.name,value:unit?.id}
        }
    } 
    if(patient_id) 
    {
        patient = await findPatient(patient_id);
        if(patient)
        {
            inputPatient.current.select.state.value = {label:patient?.name, value:patient?.id}
        }
    }

    inputStartCreatedAt.current.value = start_created_at;
    inputEndCreatedAt.current.value = end_created_at;
   
    
    searchSales(page);
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pedidos" breadcrumbItem="Lista de Orçamentos" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(SalesQuoteOrdersList)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={SalesQuoteOrdersList.data || []}
                        columns={DocsListColumns(deleteRecord)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          
                          <React.Fragment>
                                <Row className="">
                           <Col sm="12">
                           <div className="text-sm-right ">
                                  <Link  className="btn btn-primary"
                                     to={{
                                      pathname:"/orcamentos/adicionar",
                                      state:{previousPath:location?.pathname + location.search}
                                  }}>
                                      Novo Orçamento
                                  </Link>
                                </div>
                           </Col>
                           </Row>
                            <Row className="mb-4">
                            <Col sm="3">
                                <Label>Filtrar Unidade</Label>
                                  <Select 
                                   name="unit_id"
                                    defaultValue={Scope.unit ? {
                                      label: `#${Scope.unit.id} ` + Scope.unit.name?.substr(0,40), value: Scope.unit.id
                                    } : null }
                                    ref={inputRefUnit} 
                                    options={ unitsOptions}
   
                                  />
                              </Col>

                              <Col sm="3">
                                <Label>Filtrar Paciente</Label>
                                     <AsyncSelect
                                          name="patient_id"
                                          placeholder="Selecione o Paciente"
                                          ref={inputPatient}
                                          cacheOptions
                                          defaultOptions 
                                          isClearable
                                          loadOptions={debounceOptionsPatient}
                                          noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
                                      />
                              </Col>
                          
                              <Col sm="2">
                                <Label>Criado a partir de </Label>
                                      <Input 
                                        type="date" 
                                        innerRef={inputStartCreatedAt}
                                        defaultValue={todayOnlyDate}
                                      >
                                      </Input>
                              </Col>
                              <Col sm="2">
                                <Label>Criado até</Label>
                              <Input 
                                type="date" 
                                innerRef={inputEndCreatedAt}
                                defaultValue={todayOnlyDate}
                              >
                                      </Input>
                              </Col>
                              <Col sm="1">
                                <div className="">
                                  <Button  className="btn btn-primary mt-4" color="primary" onClick={() => searchSales()}>
                                      Buscar
                                  </Button>
                                </div>
                              </Col>
                           
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={
                                      onTableChange
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesQuotes
