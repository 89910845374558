import React, { useState, useEffect } from "react"
import { Formik, Field, Form, FastField } from "formik"
import {
    Modal,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    Button,
    Input
} from "reactstrap"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/actions"
import { cancelContract, loadCancelReason } from "services/vitta-core/vitta-card-contract"




const ModalCancelContract = ({ show, onClose, search, contract }) => {
    const [modalValue, setModaValue] = useState({
        select: null,
        text: null
    })
    const [optionsStatus, setOptionsStatus] = useState([])

    const LoadCancelReasonInputOption = async ()=>{
        setLoading(true)
        let data = await loadCancelReason()
        let options = []
        for(let i =0; i<data.length;i++){
            let row = data[i]
            options.push({
                value:row.id,
                label:row.name
            })
        }
        setOptionsStatus(options)
        setLoading(false)
        return 
    }
    useEffect(()=>{
        if(show && optionsStatus?.length==0 ){
            LoadCancelReasonInputOption()
        }
       
    },[show])

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    async function onSubmit() {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma o cancelamento deste contrato?",
                type: "warning",
                onConfirmAction: () => {
                    handle()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const handle = async () => {
        try {
            const data = {
                reason: modalValue.select,
                obs: modalValue.text
            }
            setLoading(true)
            await cancelContract(contract.card_id, contract.id, data)
            setLoading(false)
            onClose()
            setModaValue({text:null, select:null})
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: `Contrato cancelado com sucesso!`,
                    type: "success",
                })
            )
            search()

        } catch (e) {
            console.log(e)
            setLoading(false)
            if (e?.response?.data) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: e.response.data.error,
                        type: "error",
                        showConfirm: true,
                    }))
            }
        }


    }

    return (

        <Modal isOpen={show} centered={true} size="md">
                <div className="modal-header">
                    <div className="modal-title " id="myModalLabel">
                        <h5>
                            Motivo do Cancelamento
                        </h5>
                    </div>

                    <button
                        type="button"
                        onClick={() => {
                            onClose()
                            setModaValue({text:null, select:null})
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        disabled={loading}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>



                <div className="modal-body">
                    <Row>
                        <Col xs="12">
                            <Label>
                                <strong>
                                    Qual o motivo para o cancelamento?
                                </strong>
                            </Label>
                            <Input type="select" name={`reason_id`} id="Selectreason" options={optionsStatus}
                                onChange={(e) => {
                                    if (e.target?.value != 0) {
                                        setModaValue({ ...modalValue, select: e.target.value })
                                    } else {
                                        setModaValue({ ...modalValue, select: null })
                                    }
                                }
                                }
                                disabled={loading}
                            >
                                <option value={0}>Selecione um motivo</option>
                                {optionsStatus.map(reason => (
                                    <option
                                        key={reason.value}
                                        value={reason.value}
                                    >
                                        {reason.label}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    <Row className="mt-4">

                        <Col >
                            <Label >
                                <strong>
                                    OBS
                                </strong>
                            </Label>
                            <Input type="textarea" name={`reason_text`} id="Textreason" disabled={loading}
                                onChange={(e) => setModaValue({ ...modalValue, text: e.target.value })}>

                            </Input>
                        </Col>
                    </Row>

                </div>


                <div className="card-body" align="right">
                    {loading ? (
                        <div
                            className="spinner-border text-primary col mr-2"
                            style={{top:"6px"}}
                            role="status"
                        >
                            <span className="sr-only">
                                Loading...
                            </span>
                        </div>
                    ) : null}
                    <button
                        type="button"
                        onClick={() => {
                            onSubmit()
                        }}
                        className="btn btn-primary waves-effect mr-2"
                        data-dismiss="modal"
                        disabled={loading}
                    >
                        <span className="text-white"> Enviar</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            onClose()
                            setModaValue({text:null, select:null})
                        }}
                        className="btn btn-dark waves-effect"
                        data-dismiss="modal"
                        disabled={loading}
                    >
                        <span className="text-white"> Fechar</span>
                    </button>
                </div>
        </Modal >

    )
}

export default ModalCancelContract