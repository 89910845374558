import React, { useEffect, useState } from "react"

import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
    Col,
    Collapse,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap"

import ContractsList from "./ContractsList/ContractsList"

//Import Breadcrumb

//import do services de usar dps alterar para os corretos
import { FastField, FieldArray, Form, Formik } from "formik"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import AsyncSelect from "react-select/async"
import {
    changeStatus,
    create as CreateCard,
    deleteDependent,
} from "services/vitta-core/vitta-cards"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import KINSHIP_TYPES from "utils/consts/kinship-types"
import CARD_STATUS from "utils/consts/vitta-card-status"
import { checkIfCardHasActiveContract } from "./ContractsList/Contract"
const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={async option => {
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
    />
)

const defaultValues = {
    card_id: "",
    cards: [],
}

const indexCardVitta = ({
    show,
    data,
    onClose,
    dataDepedent,
    search,
    patient_id,
    redirectToAddContract,
    redirectToCreateDependents,
    setShowModalCancel
}) => {
    const [pageData, setPageData] = useState(defaultValues)
    const [tab, setTab] = useState(null)
    const history = useHistory()
    useEffect(() => {
        if (data && data?.cards?.length > 0) {
            setPageData(data)
        }
    }, [data])

    useEffect(() => {
        if (
            dataDepedent &&
            dataDepedent?.vitta_card_id &&
            pageData?.cards?.length > 0
        ) {
            handleAddDepenteToArray(dataDepedent)
        }
    }, [dataDepedent])

    const handleAddDepenteToArray = data => {
        try {
            search()
        } catch (e) {
            console.log(e)
        }
    }

    const dispatch = useDispatch()
    const confirmationChangeStatus = async values => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: `Tem certeza que deseja ${
                    values?.status == 1 ? "desativar" : "ativar"
                } esse cartão?`,
                type: "warning",
                onConfirmAction: () => {
                    handleChangeStatus(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const confirmationSubmit = async values => {
        if (!values?.card_id || values?.card_id == "") {
            dispatch(
                showSweetAlertDialog({
                    title: "Preencha o Número do Cartão!",
                    type: "warning",
                    showConfirm: true,
                    confirmBtnText: "OK!",
                })
            )
            return
        }
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma a inclusão de um novo Cartão?",
                type: "warning",
                onConfirmAction: () => {
                    handleCreateCard(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const handleCreateCard = async values => {
        try {
            let dataCreate = {
                patient_id: patient_id,
                card_id: values?.card_id,
            }

            await CreateCard(dataCreate)

            //caso precise mostrar o novo card adicionado sem precisar buscar
            //   if(newCard)
            //   {
            //     let take = {...pageData}
            //     take.cards.push(newCard)

            //     // setPageData(take);

            //   }

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Novo cartão adicionado!",
                    type: "success",
                })
            )
            search()
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.error_code == "invalid_card_data") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Este dependente já existe!",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "card_exists") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Este cartão já está sendo utilizado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "exists_active_card") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Já existe um cartão ativo para o paciente atualmente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "email_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Email incorreto ou não registrado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const handleChangeStatus = async data => {
        try {
            let dataChange = {
                status: data?.status == 1 ? 0 : 1,
            }

            await changeStatus(data?.id, dataChange)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: `Cartão ${
                        dataChange?.status == 1 ? "Ativo" : "Desativado"
                    }!`,
                    type: "success",
                })
            )
            search()
        } catch (e) {
            console.log(e)
            if (
                e?.response?.data?.error_code == "invalid_registered_dependents"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: `Quantidade de dependentes cadastrados(${e?.response?.data?.error_data?.totalDependentsCreated}) é superior a quantidade contratada(${e?.response?.data?.error_data?.totalDependentsContract})!`,
                        text: `Remova os dependentes excedentes para conseguir ativar o cartão.`,
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.status == 403) {
                return dispatch(
                    showSweetAlertDialog({
                        title:
                            "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (e?.response?.data?.error_code == "no_signed_contracts") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Não é possível ativar o cartão pois ele não possui contratos assinados vinculados",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }

            if (e?.response?.data?.error_code == "invalid_card_data") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Os dados do cartão estão incorretos!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "card_not_exists") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Este cartão não existe.!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "exists_active_card") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Já existe um cartão ativo para o paciente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "email_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Email incorreto ou não registrado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "billing_signature_required") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:"Para ativar o cartão é necessário gerar a assinatura de cobrança.",
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "ops...!",
                    text: "Ocorreu algum problema",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    const handleBG = id => {
        let element = document.getElementById(id)
        element.classList.add("bg-info")
        element.classList.add("text-white")
    }
    const removeBG = id => {
        let element = document.getElementById(id)
        element.classList.remove("bg-info")
        element.classList.remove("text-white")
    }
    const handleKinshipType = type => {
        let inf = null
        switch (type) {
            case KINSHIP_TYPES.FATHER:
                inf = { label: "PAI", value: KINSHIP_TYPES.FATHER }
                break
            case KINSHIP_TYPES.MOTHER:
                inf = { label: "MÃE", value: KINSHIP_TYPES.MOTHER }
                break
            case KINSHIP_TYPES.BROTHER:
                inf = { label: "IRMÃO", value: KINSHIP_TYPES.BROTHER }
                break
            case KINSHIP_TYPES.SISTER:
                inf = { label: "IRMÃ", value: KINSHIP_TYPES.SISTER }
                break
            case KINSHIP_TYPES.SON:
                inf = { label: "FILHO", value: KINSHIP_TYPES.SON }
                break
            case KINSHIP_TYPES.DAUGHTER:
                inf = { label: "FILHA", value: KINSHIP_TYPES.DAUGHTER }
                break
            case KINSHIP_TYPES.SPOUSE:
                inf = { label: "CÔNJUGE", value: KINSHIP_TYPES.SPOUSE }
                break
        }
        return inf
    }
    const renderBadge = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case CARD_STATUS.ACTIVATED:
                text = "Ativo"
                badgeClass = "badge-soft-success"
                break
            case CARD_STATUS.DISABLED:
                text = "Desativado"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    const onSendContractSuccess = id => {
        dispatch(
            showToast({
                title: "Sucesso!",
                text: `Contrato enviado!`,
                type: "success",
            })
        )
        search()
    }

    const onCreateSignatureSuccess = id => {
        dispatch(
            showToast({
                title: "Sucesso!",
                text: `Assinatura de Cobrança criada!`,
                type: "success",
            })
        )
        search()
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={pageData}
                enableReinitialize={true}
                onSubmit={confirmationSubmit}
            >
                {({
                    values,
                    isSubmitting,
                    dirty,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <CardBody>
                            <Row>
                                <Col sm="12" className="ml-2">
                                    <CardSubtitle>
                                        Cartões Registrados.
                                    </CardSubtitle>
                                </Col>
                            </Row>

                            <Row className="mt-1">
                                <Col sm="12">
                                    <FieldArray
                                        name="cards"
                                        render={arrayHelpers => (
                                            <div>
                                                {values &&
                                                values?.cards?.length > 0
                                                    ? values?.cards?.map(
                                                          (item, index) => (
                                                              <Row className="ml-1">
                                                                  <Col
                                                                      xs="12"
                                                                      md="12"
                                                                      style={{
                                                                          paddingLeft:
                                                                              "0px",
                                                                          marginTop:
                                                                              "0.1em",
                                                                      }}
                                                                  >
                                                                      <CardHeader
                                                                          style={{
                                                                              height:
                                                                                  "60px",
                                                                              cursor:
                                                                                  "pointer",
                                                                          }}
                                                                          className="shadow-lg bg-white mb-2"
                                                                          onClick={() => {
                                                                              //   setTab(!index)

                                                                              HandleTab(
                                                                                  index
                                                                              )
                                                                          }}
                                                                      >
                                                                          <Row>
                                                                              <Col
                                                                                  xs="10"
                                                                                  className="mt-1"
                                                                              >
                                                                                  <Label>
                                                                                      N.º{" "}
                                                                                      {
                                                                                          item?.card_id
                                                                                      }
                                                                                      <div>
                                                                                          {renderBadge(
                                                                                              item?.status
                                                                                          )}
                                                                                      </div>
                                                                                  </Label>
                                                                              </Col>
                                                                              <Col xs="2">
                                                                                  <div
                                                                                      style={{
                                                                                          flexDirection:
                                                                                              "row",
                                                                                          display:
                                                                                              "flex",
                                                                                          justifyContent:
                                                                                              "space-around",
                                                                                          alignItems:
                                                                                              "center",
                                                                                      }}
                                                                                      title={
                                                                                          item
                                                                                              .contract
                                                                                              .length
                                                                                              ? ""
                                                                                              : "Crie um contrato para ativar este cartão"
                                                                                      }
                                                                                      disabled={
                                                                                          (item?.status ==
                                                                                              0 &&
                                                                                          values?.cards?.some(
                                                                                              e =>
                                                                                                  e?.status ==
                                                                                                  1
                                                                                          )
                                                                                              ? true
                                                                                              : false) ||
                                                                                          (item?.status ==
                                                                                              0 &&
                                                                                              !item
                                                                                                  ?.contract
                                                                                                  ?.length)
                                                                                      }
                                                                                  >
                                                                                      <Button
                                                                                          color={
                                                                                              item?.status ==
                                                                                              1
                                                                                                  ? "danger"
                                                                                                  : "success"
                                                                                          }
                                                                                          className="inner"
                                                                                          onClick={async e => {
                                                                                              e.stopPropagation()
                                                                                              e.preventDefault()
                                                                                              let result = await confirmationChangeStatus(
                                                                                                  item
                                                                                              )
                                                                                              if (
                                                                                                  result
                                                                                              ) {
                                                                                              }
                                                                                          }}
                                                                                          title={
                                                                                              item
                                                                                                  .contract
                                                                                                  .length
                                                                                                  ? ""
                                                                                                  : "Crie um contrato para ativar este cartão"
                                                                                          }
                                                                                          disabled={
                                                                                              (item?.status ==
                                                                                                  0 &&
                                                                                              values?.items?.some(
                                                                                                  e =>
                                                                                                      e?.status ==
                                                                                                      1
                                                                                              )
                                                                                                  ? true
                                                                                                  : false) ||
                                                                                              (item?.status ==
                                                                                                  0 &&
                                                                                                  !item
                                                                                                      ?.contract
                                                                                                      ?.length)
                                                                                          }
                                                                                      >
                                                                                          {item?.status ==
                                                                                          1
                                                                                              ? "Desativar"
                                                                                              : "Ativar"}
                                                                                      </Button>

                                                                                      <div align="end">
                                                                                          {" "}
                                                                                          {tab ==
                                                                                          index ? (
                                                                                              <i className="fas fas fa-arrow-up fa-lg">
                                                                                                  {" "}
                                                                                              </i>
                                                                                          ) : (
                                                                                              <i className="fas fa-arrow-down fa-lg">
                                                                                                  {" "}
                                                                                              </i>
                                                                                          )}{" "}
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                          </Row>
                                                                      </CardHeader>

                                                                      <Collapse
                                                                          isOpen={
                                                                              tab ==
                                                                              index
                                                                          }
                                                                      >
                                                                          <ContractsList
                                                                              card={
                                                                                  item
                                                                              }
                                                                              dispatch={
                                                                                  dispatch
                                                                              }
                                                                              onCreateSignatureSuccess={
                                                                                  onCreateSignatureSuccess
                                                                              }
                                                                              onSendContractSuccess={
                                                                                  onSendContractSuccess
                                                                              }
                                                                              redirectToAddContract={
                                                                                  redirectToAddContract
                                                                              }
                                                                              setShowModalCancel={setShowModalCancel}
                                                                          />
                                                                      </Collapse>

                                                                      <Collapse
                                                                          isOpen={
                                                                              tab ==
                                                                              index
                                                                          }
                                                                      >
                                                                          <Card className="shadow-lg">
                                                                              <CardBody>
                                                                                  {item?.dependent &&
                                                                                  item
                                                                                      ?.dependent
                                                                                      ?.length >
                                                                                      0 ? (
                                                                                      <>
                                                                                          {" "}
                                                                                          <CardTitle>
                                                                                              Dependentes
                                                                                          </CardTitle>
                                                                                          <Row>
                                                                                              <Col sm="6">
                                                                                                  <Label>
                                                                                                      Paciente
                                                                                                  </Label>
                                                                                              </Col>
                                                                                              <Col sm="4">
                                                                                                  <Label>
                                                                                                      Tipo
                                                                                                      Parentesco
                                                                                                  </Label>
                                                                                              </Col>
                                                                                          </Row>
                                                                                      </>
                                                                                  ) : (
                                                                                      ""
                                                                                  )}
                                                                                  <FieldArray
                                                                                      name={`cards[${index}].dependent`}
                                                                                      render={Array => (
                                                                                          <>
                                                                                              <div>
                                                                                                  {item?.dependent &&
                                                                                                  item
                                                                                                      ?.dependent
                                                                                                      ?.length >
                                                                                                      0 ? (
                                                                                                      item?.dependent?.map(
                                                                                                          (
                                                                                                              itemDep,
                                                                                                              indexDep
                                                                                                          ) => (
                                                                                                              <Row
                                                                                                                  key={
                                                                                                                      itemDep?.id
                                                                                                                  }
                                                                                                              >
                                                                                                                  <Col sm="6">
                                                                                                                      <FastField
                                                                                                                          name={`cards[${index}].dependent[${indexDep}].dependent_id`}
                                                                                                                          placeholder="Selecione o Item"
                                                                                                                          classNamePrefix="select2-selection"
                                                                                                                          defaultValue={{
                                                                                                                              label:
                                                                                                                                  itemDep
                                                                                                                                      ?.patient
                                                                                                                                      ?.name,
                                                                                                                              value:
                                                                                                                                  itemDep
                                                                                                                                      ?.patient
                                                                                                                                      ?.id,
                                                                                                                          }}
                                                                                                                          className="bg-light"
                                                                                                                          component={
                                                                                                                              AsyncSelectInput
                                                                                                                          }
                                                                                                                          isDisabled={
                                                                                                                              true
                                                                                                                          }
                                                                                                                      />
                                                                                                                  </Col>
                                                                                                                  <Col sm="4">
                                                                                                                      <FastField
                                                                                                                          name={`cards[${index}].dependent[${indexDep}].kinship_id`}
                                                                                                                          placeholder="Selecione o Item"
                                                                                                                          classNamePrefix="select2-selection"
                                                                                                                          defaultValue={handleKinshipType(
                                                                                                                              itemDep?.type
                                                                                                                          )}
                                                                                                                          className="bg-light"
                                                                                                                          component={
                                                                                                                              AsyncSelectInput
                                                                                                                          }
                                                                                                                          isDisabled={
                                                                                                                              true
                                                                                                                          }
                                                                                                                      />
                                                                                                                  </Col>
                                                                                                                  <Col sm="2">
                                                                                                                      {" "}
                                                                                                                      <FormGroup>
                                                                                                                          <Button
                                                                                                                              color="danger"
                                                                                                                              //   disabled={checkIfCardHasActiveContract(
                                                                                                                              //       item.contract
                                                                                                                              //   )}
                                                                                                                            //   disabled={
                                                                                                                            //       item?.status ==
                                                                                                                            //       1
                                                                                                                            //           ? true
                                                                                                                            //           : false
                                                                                                                            //   }
                                                                                                                              title={
                                                                                                                                  "Não é possível excluir dependentes se o cartão estiver ativo."
                                                                                                                              }
                                                                                                                              onClick={async () => {
                                                                                                                                  try {
                                                                                                                                      await deleteDependent(
                                                                                                                                          itemDep?.id
                                                                                                                                      )

                                                                                                                                      Array.remove(
                                                                                                                                          indexDep
                                                                                                                                      )

                                                                                                                                      dispatch(
                                                                                                                                          showToast(
                                                                                                                                              {
                                                                                                                                                  title:
                                                                                                                                                      "Sucesso!",
                                                                                                                                                  text:
                                                                                                                                                      "Dependente removido!",
                                                                                                                                                  type:
                                                                                                                                                      "success",
                                                                                                                                              }
                                                                                                                                          )
                                                                                                                                      )
                                                                                                                                  } catch (e) {
                                                                                                                                      if (
                                                                                                                                          e &&
                                                                                                                                          e.response &&
                                                                                                                                          e
                                                                                                                                              .response
                                                                                                                                              .data &&
                                                                                                                                          e
                                                                                                                                              .response
                                                                                                                                              .data
                                                                                                                                              .message
                                                                                                                                      ) {
                                                                                                                                          dispatch(
                                                                                                                                              showSweetAlertDialog(
                                                                                                                                                  {
                                                                                                                                                      title:
                                                                                                                                                          "Erro!",
                                                                                                                                                      text:
                                                                                                                                                          e
                                                                                                                                                              .response
                                                                                                                                                              .data
                                                                                                                                                              .message,
                                                                                                                                                      type:
                                                                                                                                                          "error",
                                                                                                                                                      showConfirm: true,
                                                                                                                                                  }
                                                                                                                                              )
                                                                                                                                          )
                                                                                                                                      }
                                                                                                                                  }
                                                                                                                              }}
                                                                                                                          >
                                                                                                                              Excluir
                                                                                                                          </Button>
                                                                                                                      </FormGroup>
                                                                                                                  </Col>
                                                                                                                  <div></div>
                                                                                                              </Row>
                                                                                                          )
                                                                                                      )
                                                                                                  ) : (
                                                                                                      <>
                                                                                                          <CardTitle>
                                                                                                              Dependentes
                                                                                                          </CardTitle>
                                                                                                      </>
                                                                                                  )}
                                                                                              </div>

                                                                                              <Button
                                                                                                  color="primary"
                                                                                                  onClick={
                                                                                                      redirectToCreateDependents
                                                                                                  }
                                                                                              >
                                                                                                  Adicionar
                                                                                              </Button>
                                                                                          </>
                                                                                      )}
                                                                                  />
                                                                              </CardBody>
                                                                          </Card>
                                                                      </Collapse>
                                                                  </Col>
                                                              </Row>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col sm="9" className="ml-2">
                                    <Label className="ml-1">
                                        Número Do Cartão
                                    </Label>
                                </Col>
                                <Col sm="8" className="ml-2 mt-1">
                                    {/* Visível somente em tamanhos de tela xs, sm */}
                                    <FastField
                                        name={`card_id`}
                                        placeholder="Digite o Número"
                                        classNamePrefix="select2-selection"
                                        component={TextInput}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col md="3" className="mt-1 ml-2">
                                    <Button
                                        className="btn btn-info"
                                        disabled={false}
                                    >
                                        Adicionar{" "}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default indexCardVitta
