import { Link } from "react-router-dom"
import { Col, FormGroup } from "reactstrap"

const GenerateNfseButton = ({ isVisible = false, onClick }) => {
    if (!isVisible) return <></>

    return (
        <Col sm="3" className="mt-1">
            <FormGroup>
                <div className=" ">
                    <Link className="btn btn-primary mt-4" onClick={onClick}>
                        Emitir notas fiscais
                    </Link>
                </div>
            </FormGroup>
        </Col>
    )
}

export default GenerateNfseButton
