import request from './config/request';

const searchLogs = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    query+= "&entity=" + filter?.entity
    query+= "&entity_id=" + filter?.entity_id
    if(filter?.log_version) query += "&log_version=" + filter?.log_version
    if (filter.start_created_at) query += "&start_created_at=" + filter.start_created_at;
    if (filter.end_created_at) query += "&end_created_at=" + filter.end_created_at;
    if(filter?.user_id)  query += "&user_id=" + filter.user_id;
   

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/search-logs` +
        query,
        vtAuth: true
    })
}

export { searchLogs }
