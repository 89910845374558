import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
//import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logovitta2 from "assets/images/logovitta2.jpg"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    //props.userForgetPassword(values, props.history)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"/>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Recuperação de Acesso</h5>
                        <p>Informe seu e-mail para alterar sua senha.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logovitta2}
                            alt=""
                            className="rounded-circle"
                            height="100"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="E-mail"
                          className="form-control"
                          placeholder="Digite seu E-mail..."
                          type="email"
                          required
                          errorMessage=" Preencha o campo E-mail ."
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Recuperar
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  Voltar para o {" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                {/*
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"/> by Themesbrand
                </p>
                */}
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default ForgetPasswordPage;
