import request from './config/request';


const searchGeneralDashboard = (filter) =>{

    let query = ""

    if (filter.unit_id) query += "?unit_id=" + filter.unit_id
    if (filter.period_id) query += "&period_id=" + filter.period_id
    if (filter.DStart) query +="&DStart="+ filter.DStart
    if(filter.DEnd) query +="&DEnd="+ filter.DEnd

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/general-dashboard` +
        query,
        vtAuth: true
    })
}

export {searchGeneralDashboard}