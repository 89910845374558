import request from "./config/request"

const search = (filter) => {
    
    let query = ""
    if (filter.unit_id) query += "?unit_id=" + filter.unit_id
    if (filter.status) query += "&status=" + filter.status
    if (filter.exec_in) query += "&exec_in=" + filter.exec_in
    if (filter.mark_exec_at) query += "&mark_exec_at=" + filter.mark_exec_at
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.exec_professional_id)
        query += "&exec_professional_id=" + filter.exec_professional_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/execution-controls` +
            query,
        vtAuth: true,
    })
}

const processExecutions = (ids) => {
    let data = {
        soi_ids: ids,
    }

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/process-executions`,
        data: data,
        vtAuth: true,
    })
}
export { search,processExecutions }
