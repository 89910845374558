import React from "react"

const RenderForm = ({
    showProfessionalSignature,
    ShowPatientData,
    showPrintButton,
    patient,
    professional,
    content,
}) => {
    //    return console.log("TUDO",showProfessionalSignature,ShowPatientData,showPrintButton,patient,professional,content);
    let title = ""
    if (content?.title) title = content?.title
    if (content.medical_form?.title) title = content.medical_form?.title

    let html = "<html> <head>"
    html +=
        '<meta content="text/html; charset=UTF-8" http-equiv="Content-Type">'

    html += `<style>
     @media print {
        @page{
            size: 150mm 210mm;
            margin:0 
        }
    .not_print{
     display: none;
          }
     }
     main span {
        font-size: 8px; 
    }
    main p{
        font-size:8px
    }
    body{
        width: 148mm;
        height: 200mm;
    }
   </style> </head> `
    html += `<body  style ="width:auto;">`
    //html += " <br/> <br/> <br/> <br/> "
    html += `
    <div style ="display: flex;margin-bottom: 0px;
	justify-content: space-between;
	margin-left: 21px; margin-top: 26px;font-size: 11px;">
    <div>
        ${
            content?.created_at
                ? `<span style="font-weight: bold">${
                      content?.created_at?.substr(8, 2) +
                      "/" +
                      content?.created_at?.substr(5, 2) +
                      "/" +
                      content?.created_at?.substr(0, 4)
                  }</span>`
                : `<span style="font-weight: bold"></span>`
        }
            ${
                patient && patient.name
                    ? `<span style="display: block; font-weight: bold; margin-bottom: 10px;margin-top: 15px;">Paciente: ${patient.name}</span>`
                    : `<span style="display: block; font-weight: bold; margin-bottom: 10px;margin-top: 15px;">Paciente: </span>`
            }
            ${
                patient?.birth_date
                    ? `<span style="font-weight: bold">Data de Nascimento: ${
                          patient?.birth_date?.substr(8, 2) +
                          "/" +
                          patient?.birth_date?.substr(5, 2) +
                          "/" +
                          patient?.birth_date?.substr(0, 4)
                      }</span>`
                    : `<span style="font-weight: bold">Data de Nascimento:</span>`
            }
                ${
                    content?.specialty
                        ? ` <span  style="display: block; font-weight: bold; margin-bottom: 10px;margin-top: 15px;">Especialidade: ${content?.specialty?.name}</span>`
                        : ` <span  style="display: block; font-weight: bold; margin-bottom: 10px;margin-top: 15px;">Especialidade: </span>`
                }
        
    </div>
    
    </div> 
    `
    // ${content?.created_at?`
    // <input type="text" disabled="true" value = "${content?.created_at?.substr(8, 2) +
    //     "/" +
    //     content?.created_at?.substr(5, 2) +
    //     "/" +
    //     content?.created_at?.substr(0, 4)}
    //    style="border-color:  rgb(46, 109, 226); border-radius: 5px; text-align: center;">`:

    //    `<input type="text" disabled="true"
    //    style="border-color:  rgb(46, 109, 226); border-radius: 5px; text-align: center;">`
    // }
    // let patient =  patient && ShowPatientData && patient.name? patient?.name : ''

    html += `<main>`
    // html += "<br><br/>"
    if (content?.entity && content?.entity == "MedicalCertificate") {
        html += `<h5 style=" display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;
            margin-right: 35px;margin-bottom: 0px;
            ">ATESTADO</h5>
            
            <hr color="black" width="100%"">`
    }
    if (content?.entity && content?.entity == "MedicalPrescription") {
        html += `<h5 style=" display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;
            margin-right: 35px;margin-bottom: 0px;
            ">PRESCRIÇÃO</h5>
            
            <hr color="black" width="100%"">`
    }
    if (content?.entity && content?.entity == "MedicalReferral") {
        html += `<h5 style=" display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;
            margin-right: 35px;margin-bottom: 0px;
            ">ENCAMINHAMENTO</h5>
            
            <hr color="black" width="100%"">`
    }
    if (content?.entity && content?.entity == "MedicalReport") {
        html += `<h5 style=" display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;
            margin-right: 35px;margin-bottom: 0px;
            ">LAUDO</h5>
            
            <hr color="black" width="100%"">`
    }
    // <input type="text" disabled="true" value ="${   patient?.name}" style="border-style:hidden;border-bottom-style: solid; width: 90%">
    let value = []
    if (
        content?.form?.length > 0 ||
        content?.medical_exams_items ||
        content?.itemsExam
    ) {
        if (content?.form)
            value = content?.form.sort((a, b) => {
                if (a.item?.name < b.item?.name) return -1
                if (a.item?.name > b.item?.name) return 1
                return 0
            })
        if (content?.medical_exams_items)
            value = content?.medical_exams_items.sort((a, b) => {
                if (a.item?.name < b.item?.name) return -1
                if (a.item?.name > b.item?.name) return 1
                return 0
            })
        if (content?.itemsExam)
            value = content?.itemsExam.sort((a, b) => {
                if (a.item_id?.label < b.item_id?.label) return -1
                if (a.item_id?.label > b.item_id?.label) return 1
                return 0
            })
        html += `<h5 style=" display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 35px;
        margin-right: 35px;margin-bottom: 0px;
        ">SOLICITAÇÃO DE EXAMES</h5>

        <hr color="black" width="100%">`
        html += `<div style=" display: block;
    justify-content: left;
    align-items: left;
    margin-left: 35px;
    margin-right: 35px;">`
        for (let i = 0; i < value?.length; i++) {
            let label = ""
            if (value[i] && value[i]?.item_id?.value)
                label = value[i].item_id?.label
            if (value[i] && value[i].item?.name) label = value[i].item?.name

            if (i % 24 === 0 && i !== 0) {
                //se nao for a primeira pagina e o item ser maior q 28 quebra a pagina e adiciona margin top
                html += '<div style="page-break-after: always;"></div>'
            }

            html += `<div style=" padding: 4px; ${
                i % 24 === 0 && i !== 0 ? "margin-top: 3cm;" : ""
            }" >
            ${label ? `<span > ${label} </span> ` : `<span></span>`}
        
        </div>`
        }
        html += "</div>"
    }
    let text = ""

    html += `<div >`
    if (content?.text) text = content?.text
    if (content.medical_form?.text) text = content.medical_form?.text
    if (value.length > 0) {
        let parser = new DOMParser()
        let doc = parser.parseFromString(text, "text/html")

        // remove style se tiver item
        let styledElements = doc.querySelectorAll("[style]")
        styledElements.forEach(el => {
            el.removeAttribute("style")
        })
        text = doc.body.innerHTML
    }
    if (text.length > 340 && value.length == 24) {
        html += '<div style="page-break-after: always;"></div>'
        html += `<div style="font-size: 8px;margin-top: 3cm;margin-left:10px;" >
            ${text}
         </div>`
    } else {
        const lineHeight = 8 //tamanho aproximado da linha
        const pageHeight = 210 // 16cm o conteudo
        const linesPerPage = Math.floor(pageHeight / lineHeight) // quantas linhas por pagina aproximada
        const charsPerLine = 100 // 100 caractes por linha
        const charsPerPage = linesPerPage * charsPerLine // quantos caractes por pagina

        let currentPageText = ""
        for (let i = 0; i < text.length; i += charsPerPage) {
            currentPageText = text.slice(i, i + charsPerPage) //se i nao for a primeira adiciona 3cm
            html += `<div style="font-size: 8px; margin-left:10px; ${
                i !== 0 ? "margin-top: 3cm;" : ""
            }" >
        ${currentPageText}
    </div>`
            if (i + charsPerPage < text.length) {
                //se i+charPerpage for maior q o texto quer dizer que tem mais texto e quebra a pagina
                html += '<div style="page-break-after: always;"></div>'
            }
        }
    }

    html += `
    <footer >
        <div style="display: flex;
        justify-content: right;
        align-items: right;
        margin-top:25px;
        font-size: 8px;">
            <div style="margin-right: 20px;display: inline-block; width: 50%;">
            <hr  width="80%" align="center" noshade style=" margin-bottom: 2px;">
            ${
                professional.register_type &&
                professional.register_code &&
                professional.register_uf
                    ? ` <p style="margin:0px" align="center">${
                          professional.name
                      }</p>
                <p style="margin:0px" align="center">${
                    professional.register_type +
                    " " +
                    professional.register_code +
                    "/" +
                    professional.register_uf
                }</p>`
                    : ` <p style="margin:0px" align="center">${professional.name}</p>`
            }
           
                
            </div>
        </div>
        <br/><br/>
    </footer>

    
`
    html += "</div>"
    if (content.print) return (html += "</main></body> </html>")

    showPrintButton
        ? (html +=
              ' <br/> <br/> <br/><div style="text-align: center"><p><button onClick="window.print()" class="not_print">Imprimir </button></p></div>')
        : ""
    html += "</main></body> </html>"
    let w = 1024
    let h = 768
    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

    const winUrl = URL.createObjectURL(new Blob([html], { type: "text/html" }))

    return window.open(
        winUrl,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, 
        resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    )
}

export default RenderForm
