import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Badge,
    Input, 
    Label,
    UncontrolledTooltip
} from "reactstrap"

import SALES_ORDER_STATUS from "utils/consts/sales-orders-status"

const RequestColumns = (genInvoiceRecord) => {
    return [
    {
        isDummyField: true,
        text: "ID Pedido",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    {
        dataField: "created_at",
        text: "Data Pedido",
        formatter: (cellContent, row) =>
            new Date(row.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"})
    },
    {
        isDummyField: true,
        text: "Total",
        formatter: (cellContent, row) => {
            let total = 0;
            for(let i = 0; i < row?.items?.length; i++)
            {
                let useItem = row.items[i];
                total += useItem.quantity * (useItem.price ? useItem.price : 0)
            }

            return "R$ " + new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(total/100)
        },
    },
    {
        dataField: "patient.name",
        text: "Paciente",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            let text = ""
            let badgeClass = "badge-soft-success"
            switch (cellContent) {
                case SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL:
                    text = "Aguardando Aprovação"
                    badgeClass = "badge-soft-warning"
                    break
                case SALES_ORDER_STATUS.COMPANY_NOT_APPROVED:
                    text = "Não Aprovado"
                    badgeClass = "badge-soft-danger"
                    break
                case SALES_ORDER_STATUS.AWAITING_PAYMENT:
                    text = "Aguardando Pagamento"
                    badgeClass = "badge-soft-success"
                    break
                case SALES_ORDER_STATUS.PAID:
                    text = "Pago"
                    badgeClass = "badge-soft-success"
                    break
                case SALES_ORDER_STATUS.INVOICE_ISSUED:
                    text = "Notas Fiscais Emitidas"
                    badgeClass = "badge-soft-primary"
                    break
                case SALES_ORDER_STATUS.CANCELED:
                    text = "Cancelado"
                    badgeClass = "badge-soft-danger"
                    break
                    case SALES_ORDER_STATUS.PARTIALLY_PAID:
                        text = "Parcialmente Pago"
                        badgeClass = "badge-soft-info"
                        break
            }

            return (
                <Badge className={"font-size-12 " + badgeClass} pill>
                    {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (cellContent, row) => (
            <>
                <Link
                    to="#"
                    className="mr-3  text-primary "
                    onClick={() => {
                        genInvoiceRecord(row?.id)
                    }}
                >
                    <i className="fas fa-file-invoice font-size-22" id={"invoicetooltip"+row.id}></i>
                    <UncontrolledTooltip placement="top" target={"invoicetooltip"+row.id}>
                        Gerar Notas Fiscais
                    </UncontrolledTooltip>
                </Link>

                <Link
                    to={"/pedidos/" + row?.id}
                    className="mr-3 text-primary"
                >
                     <i className="fas fa-eye font-size-22" id={"openordertooltip"+row?.id}></i>
                    <UncontrolledTooltip placement="top" target={"openordertooltip"+row?.id}>
                        Abrir
                    </UncontrolledTooltip>
                           
                </Link>
            </>
        )
    }
]
}

export default RequestColumns
