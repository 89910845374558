import request from './config/request';

const entityStatusChange = (entity_type,entity_id) => {
    let query = "?entity_type=" + entity_type;
        query+= "&entity_id=" + entity_id

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/entity-status-changes/find` +
        query,
        vtAuth: true
    })
}

export { entityStatusChange }
