import React, { useState, useEffect } from "react"
import { Row, Col, Button, CardBody, Card } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import logovitta from "assets/images/logovitta2.jpg"
import { useParams } from "react-router-dom"
import { searchSpecificRecord } from "services/vitta-core/professional-transfer-invoices"
import ComponentInvoice from "../ComponentsInvoices/index"

const printTransferNotes = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({})

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                let items = []
                let total = 0
                let totalModal = 0
                let record = await searchSpecificRecord(id)
              
                for (let i = 0; i < record.items.length; i++) {
                    let recordItems = null
                    recordItems = record.items[i]
                    items.push({
                        quantity: recordItems.quantity,
                        price: recordItems.price / 100,
                        price_table: recordItems.price_table / 100,
                        item_id: {
                            label: recordItems.item.name,
                            value: recordItems.item.value,
                        },
                    })
                    let useItem = record.items[i]
                    total +=
                        useItem.quantity * (useItem.price ? useItem.price : 0)

                    totalModal =
                        "R$ " +
                        new Intl.NumberFormat("pt-br", {
                            style: "decimal",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(total / 100)
                }

                setPageData({
                    patient_id: {
                        label: record?.patient?.name,
                        value: record?.patient?.id,
                    },
                    cpf_patient: record?.patient?.cpf,
                    address_patient: record?.patient?.addr_address,
                    district_patient: record?.patient?.addr_district,
                    complement_patient: record?.patient?.addr_complement,
                    cep_patient: record?.patient?.addr_cep,
                    state_patient: record?.patient?.addr_state,
                    county_patient: {
                        label: record?.patient?.county?.name,
                        value: record?.patient?.county?.id,
                    },
                    professional_id: {
                        label: record?.professional?.name,
                        value: record?.professional?.id,
                    },
                    cpf_professional: record?.professional?.cpf,
                    cnpj_professional: record?.professional?.cnpj,
                    items: items,
                    os_id: record?.os_id,
                    note_id: record?.id,
                    status_fiscal:
                        record?.fiscal_status === 1
                            ? { label: "Aberta", value: 1 }
                            : { label: "Emitida", value: 2 },
                    status: record?.status,
                    data_exec: record?.exec_at,
                    unit: record?.unit,
                    professional: record?.professional,
                    unit_name: record?.unit?.name,
                    created_at:
                        record?.created_at?.substr(0, 4) +
                        "-" +
                        record?.created_at?.substr(5, 2) +
                        "-" +
                        record?.created_at?.substr(8, 2),
                    totalModal: totalModal,
                })
                window.setTimeout(handlePrint, 500)
            } catch (e) {
                console.log("catch", e)
            }
        }

        if (id) loadDataEditPage()
        else console.log("não entrou no edit")
    }, [id])

    const handlePrint = () => {
        window.print()
    }

    return (
        <div className="page-content">
            <Card>
                <CardBody>
                    <Row>
                        <Col
                            md="5"
                            className="d-flex justify-content-start align-items-center"
                        >
                            <img
                                src={logovitta}
                                alt=""
                                height="60px"
                                className="mb-2"
                            />

                            <div className="d-flex flex-column  ">
                                <h4>Unidade {pageData?.unit?.name}</h4>
                                <div className="">
                                    CNPJ: {pageData?.unit?.cnpj}
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div align="end">
                                <Button
                                    color="secondary"
                                    onClick={() => handlePrint()}
                                    className="d-print-none"
                                >
                                    Imprimir
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <h4 align="center">Nota de Repasse Médico</h4>
                    <br />

                    <ComponentInvoice data={pageData} />
                </CardBody>
            </Card>
        </div>
    )
}

export default printTransferNotes
