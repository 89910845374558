import React from "react"
import { Link } from "react-router-dom"
import {
    Row, UncontrolledTooltip, UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap"

const calendarListColumns = () => [
    {
        dataField: "img",
        text: "#",
        formatter: (cellContent, user) => (
            <>
                {!user.img ? (
                    <div className="avatar-xs">
                        <span className="avatar-title rounded-circle">
                            {user.name.charAt(0)}
                        </span>
                    </div>
                ) : (
                    <div>
                        <img
                            className="rounded-circle avatar-xs"
                            src={images[user.img]}
                            alt=""
                        />
                    </div>
                )}
            </>
        ),
    },
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                # {row.id}
            </Link>
        ),
    },
    {
        dataField: "name",
        text: "Agenda",
    },
    {
        dataField: "Profissional",
        text: "Profissional",
        formatter: (cellContent, row) => (
            row.professional?.name
        ),
    },
    {
        dataField: "Unidade",
        text: "Unidade",
        formatter: (cellContent, row) => (
            row.unit?.name
        ),
    },
    {
        dataField: "validity",
        text: "Vigência",
        formatter: (cellContent, row) => (
            row.start_validity && row.end_validity ? row.start_validity.substr(8, 2) + "/" + row.start_validity.substr(5, 2) + "/" + row.start_validity.substr(0, 4) + " - " + row.end_validity.substr(8, 2) + "/" + row.end_validity.substr(5, 2) + "/" + row.end_validity.substr(0, 4) : null
        ),
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <Row>
                <Link
                    to={"/agendas/editar/" + row.id}
                    className="mr-3 text-primary"
                >
                    <i
                        className="mdi mdi-pencil-box-outline font-size-24 mr-3"
                        id={"edittooltip"+row.id}
                    />
                    <UncontrolledTooltip placement="top" target={"edittooltip"+row.id}>
                        Editar
                    </UncontrolledTooltip>
                </Link>
                <UncontrolledDropdown>
                    <DropdownToggle
                        className="card-drop"
                        tag="i"
                    >
                        <i
                            className="bx bx-list-ul"
                            style={{
                                fontSize:
                                    "26px",
                                paddingTop: "5px"
                            }}
                        />
                    </DropdownToggle>
                    <DropdownMenu
                        right
                    >
                        <Link
                            to={"/agendas/copy-from/" + row.id}
                        >
                            <DropdownItem
                                className="mr-3 text-primary"
                            >
                                <i
                                    className="mdi mdi-content-copy mr-3"
                                > Copiar </i>
                            </DropdownItem>
                        </Link>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Row>
        ),
    },
]

export default calendarListColumns
