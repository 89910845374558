import React from "react"
import {
    Modal,
    CardBody,
    Row,
    Col,
    CardTitle,
    FormGroup,
    Label,
    Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { FastField, Field, Form, Formik } from "formik"

const ModalReceivables = ({ show, data, onClose }) => {

    return (
        <Modal
            isOpen={show}
            centered={false}
            size="xl"
        >
            <Formik
                initialValues={data? data:[]}
                enableReinitialize={true}
            >
                {({ values, defaultItemValues }) => (
                    <Form>
                        <CardBody>
                                <Row className="ml-1 mr-1" >
                                    <Col>
                                        <div
                                            className="modal-title "
                                            id="myModalLabel"
                                        >
                                            <h5 className="mt-2">
                                                Detalhe do Recebível
                                            </h5>
                                        </div>
                                    </Col>
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>{" "}
                                </Row>
                                <div className={"modal-body pt-0"}>
                                    {values?.sales_transactions?.payment_info?.map((payment, index) => (
                                        <div key={index}>
                                            <hr />
                                            <Row className="mb-4">
                                                <Col sm="12">
                                                    <h5>
                                                        Pedido de Venda{" "}
                                                        <a
                                                            href={`/pedidos/${payment.sales_order?.id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-primary"
                                                        >
                                                            #{payment.sales_order?.id}
                                                        </a>
                                                    </h5>
                                                </Col>
                                            </Row>
                                            {payment.sales_order?.items?.map((item, idx) => (
                                                <Row key={idx} className="mb-4">
                                                    <Col sm="6">
                                                        <Label>Item</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control bg-light"
                                                            value={item.item?.name || ''}
                                                            readOnly
                                                        />
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Valor</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control bg-light"
                                                            value={(item?.price / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                        
                                    ))}
                                </div>
                        </CardBody>
                    </Form>
                )}
            </Formik>


        </Modal>
    )
}

export default ModalReceivables
