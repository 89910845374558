export const transformISODateTODMY = isoDate => {
    const date = new Date(isoDate)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let dt = date.getDate()

    if (dt < 10) {
        dt = "0" + dt
    }
    if (month < 10) {
        month = "0" + month
    }

    return `${dt}/${month}/${year}`
}

export const transformISODateTODMYWithTime = isoDate => {
    const date = new Date(isoDate)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let dt = date.getDate()

    if (dt < 10) {
        dt = "0" + dt
    }
    if (month < 10) {
        month = "0" + month
    }

    const dateStr = `${dt}/${month}/${year}`

    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (hours < 10) hours = "0" + hours

    if (minutes < 10) minutes = "0" + minutes

    const hoursStr = `${hours}:${minutes}`

    return `${dateStr} às ${hoursStr}`
}
