import { FastField, FieldArray, Form, Formik } from "formik"
import React, { useState } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import Select from "react-select"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Collapse,
    FormGroup,
    Input,
    Label,
    Row,
    UncontrolledTooltip,
} from "reactstrap"
import { refreshNfseStatus } from "services/vitta-core/professional"
import { getInvoiceNfseLogs } from "services/vitta-core/professional-transfer-invoice-payments"
import {
    changeFiscalStatusToIssued,
    changeFiscalStatusToOpen,
} from "services/vitta-core/professional-transfer-invoices"
import PROF_INVOICES_FISCAL_STATUS from "utils/consts/prof-transfer-invoice-fiscal-status"
import PROF_TRANSFER_INVOICE_STATUS from "utils/consts/prof-transfer-invoice-status"
import {
    transformISODateTODMY,
    transformISODateTODMYWithTime,
} from "utils/date"
import CancelInvoiceButton from "./components/CancelInvoiceButton"
import CancelPaymentButton from "./components/CancelPaymentButton"
import DownloadInvoiceNfseButton from "./components/DownloadInvoiceNfseButton"
import PrintInvoiceButton from "./components/PrintInvoiceButton"
import RefreshNfseStatusButton from "./components/RefreshNfseStatusButton"

const SelectInputt = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option),
                handleFiscalStatus(option, props.note_id)
        }}
    />
)
const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const DateInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Input
        type="date"
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const handleFiscalStatus = async (dataStatus, note_id) => {
    if (dataStatus?.value === 1 && note_id) {
        await changeFiscalStatusToOpen(note_id)
    } else if (dataStatus?.value === 2 && note_id) {
        await changeFiscalStatusToIssued(note_id)
    }
}

const TaxLogStatus = ({ id }) => {
    const [logsOpen, setLogsOpen] = useState(false)
    const [events, setEvents] = useState([])
    const [isLoading, setLoading] = useState(false)

    const toggleStatusOpen = () => {
        if (!logsOpen) {
            fetchEvents()
        }
        setLogsOpen(!logsOpen)
    }

    const fetchEvents = () => {
        setLoading(true)
        getInvoiceNfseLogs(id)
            .then(({ result }) => {
                setEvents(result)
            })
            .catch(err => {})
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <a href="#" onClick={toggleStatusOpen}>
                Mostrar log da situação fiscal
            </a>
            <Collapse isOpen={logsOpen}>
                <TaxLogEvents events={events} isLoading={isLoading} />
            </Collapse>
        </div>
    )
}

const TaxLogEvents = ({ events, isLoading }) => {
    if (isLoading) return <span>Carregando...</span>
    if (!Array.isArray(events)) return <></>
    if (!events.length) return <span>Nenhum evento registrado</span>

    const eventsComponent = events.map(event => {
        return <TaxLogEvent event={event} />
    })

    return <>{eventsComponent}</>
}

const TaxLogEvent = ({ event }) => {
    const [isJsonOpen, setJsonOpen] = useState(false)

    const toggleJson = () => {
        setJsonOpen(!isJsonOpen)
    }

    return (
        <Card>
            <CardBody>
                <p>Data: {transformISODateTODMYWithTime(event.created_at)}</p>
                <p>Mensagem: {event.message}</p>
                <a href="#" onClick={toggleJson}>
                    {isJsonOpen ? "Esconder detalhes" : "Exibir detalhes"}
                </a>
                <TaxLogEventJson json={event.json} isOpen={isJsonOpen} />
            </CardBody>
        </Card>
    )
}

const TaxLogEventJson = ({ json, isOpen }) => {
    if (!isOpen) return <></>

    return <pre>{JSON.stringify(json, null, 4)}</pre>
}

const componentInvoice = ({ data, onClose }) => {
    const defaultItemValues = {
        price: 0,
        quantity: 0,
        item_id: "",
        price_table: 0,
    }
    const defaultValues = {
        patient_id: "",
        professional_id: "",
        cpf_patient: "",
        address_patient: "",
        cep_patient: "",
        complement_patient: "",
        district_patient: "",
        state_patient: "",
        county_patient: "",
        cpf_professional: "",
        os_id: "",
        note_id: "",
        data_exec: "",
        items: [defaultItemValues],
        status_fiscal: "",
    }

    const [pageData, setPageData] = useState(defaultValues)

    return (
        <CardBody>
            <Formik
                initialValues={data ? data : pageData}
                enableReinitialize={true}
            >
                {({ values, defaultItemValues }) => (
                    <Form>
                        {onClose ? (
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        data?.status_fiscal?.value !=
                                        values?.status_fiscal?.value
                                            ? onClose("different")
                                            : onClose()
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>{" "}
                                <Row style={{ marginRight: 30 }}>
                                    <Col>
                                        <div
                                            className="modal-title "
                                            id="myModalLabel"
                                        >
                                            <h5 className="mt-2">
                                                Nota de Repasse
                                            </h5>
                                        </div>
                                    </Col>
                                   
                                    {(data?.status ==
                                    PROF_TRANSFER_INVOICE_STATUS.PAID) || (data?.prof_transfer_invoice_payment && data?.prof_transfer_invoice_payment?.length>0) ? (
                                        <Col
                                            sm="3"
                                            align="center"
                                            style={{ maxWidth: 200 }}
                                        >
                                            <CancelPaymentButton
                                                isVisible={true}
                                                statusNote={data?.status}
                                                id={data?.note_id}
                                                onCancelSuccess={() => {
                                                    onClose("different")
                                                }}
                                            />
                                        </Col>
                                    ) : (
                                        <></>
                                    )}

                                    {data?.status !==
                                    PROF_TRANSFER_INVOICE_STATUS.CANCELED ? (
                                        <Col
                                            sm="2"
                                            align="center"
                                            style={{ maxWidth: 150 }}
                                        >
                                            <CancelInvoiceButton
                                                id={data?.note_id}
                                                onCancelSuccess={() =>
                                                    onClose("different")
                                                }
                                            />
                                        </Col>
                                    ) : (
                                        <></>
                                    )}

                                    <Col
                                        sm="2"
                                        align="center"
                                        style={{ maxWidth: 150 }}
                                    >
                                        <PrintInvoiceButton
                                            id={data?.note_id}
                                        />
                                    </Col>

                                    {data?.status_fiscal?.value ==
                                        PROF_INVOICES_FISCAL_STATUS.ISSUED &&
                                    data?.status !==
                                        PROF_TRANSFER_INVOICE_STATUS.CANCELED ? (
                                        <Col
                                            sm="2"
                                            align="center"
                                            style={{ maxWidth: 150 }}
                                        >
                                            <DownloadInvoiceNfseButton
                                                id={data?.note_id}
                                                isVisible={true}
                                            />
                                        </Col>
                                    ) : (
                                        <></>
                                    )}

                                    {data?.status_fiscal?.value !=
                                        PROF_INVOICES_FISCAL_STATUS.OPEN &&
                                    data?.status_fiscal?.value !==
                                        PROF_INVOICES_FISCAL_STATUS.ISSUED &&
                                    data?.status !==
                                        PROF_TRANSFER_INVOICE_STATUS.CANCELED ? (
                                        <Col
                                            sm="3"
                                            align="center"
                                            style={{ maxWidth: 200 }}
                                        >
                                            <RefreshNfseStatusButton
                                                id={data?.note_id}
                                                isVisible={true}
                                                onUpdateSuccess={status => {
                                                    onClose("different")
                                                    // updateFiscalStatus(
                                                    //     data?.note_id,
                                                    //     status
                                                    // )
                                                }}
                                            />
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                                <hr />
                            </div>
                        ) : (
                            ""
                        )}

                        <div className={onClose ? "modal-body" : ""}>
                            <CardTitle className="mb-3">
                                Informações Nota{" "}
                            </CardTitle>
                            <Row>
                                <Col sm="1">
                                    <FormGroup>
                                        <Label className=""> Nota #</Label>
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            className="form-control bg-light"
                                            value={"#" + values.note_id}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                {/* <Col sm="1">
                                    <FormGroup>
                                        <Label className=""> OS #</Label>
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            className="form-control bg-light"
                                            value={"#" + values.os_id}
                                        ></Input>
                                    </FormGroup>
                                </Col> */}
                                <Col sm="2">
                                    <FormGroup>
                                        <Label>Data Execução</Label>
                                        <FastField
                                            className="form-control bg-light"
                                            disabled={true}
                                            component={DateInput}
                                            value={values.data_exec}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="2">
                                    <FormGroup>
                                        <Label>Data Criação Nota</Label>
                                        <FastField
                                            className="form-control bg-light"
                                            disabled={true}
                                            component={DateInput}
                                            value={values.created_at}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="2">
                                    <FormGroup>
                                        <Label>Status Fiscal</Label>{" "}
                                        <FastField
                                            name="status_fiscal"
                                            component={SelectInputt}
                                            value={values?.status_fiscal}
                                            note_id={values.note_id}
                                            options={[
                                                {
                                                    label: "Aberta",
                                                    value: 1,
                                                },
                                                {
                                                    label: "Emitida",
                                                    value: 2,
                                                },
                                                {
                                                    label: "Transmitindo",
                                                    value: 3,
                                                },
                                                {
                                                    label: "Falha",
                                                    value: 4,
                                                },
                                                {
                                                    label: "Rejeitada",
                                                    value: 5,
                                                },
                                                {
                                                    label: "Cancelada",
                                                    value: 6,
                                                },
                                                {
                                                    label: "Substituída",
                                                    value: 7,
                                                },
                                            ]}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="2">
                                    <FormGroup>
                                        <Label>Unidade</Label>{" "}
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            className="form-control bg-light"
                                            value={data?.unit_name}
                                        ></Input>
                                    </FormGroup>
                                </Col>

                                <Col sm="2">
                                    <FormGroup>
                                        <Label>Valor Total Nota</Label>{" "}
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            className="form-control bg-light"
                                            value={data?.totalModal}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="3">
                                    {" "}
                                    <CardTitle className="mb-3">
                                        Informações do Profissional{" "}
                                    </CardTitle>{" "}
                                </Col>

                                {onClose ? (
                                    <Col sm="2" xl="1" align="start">
                                        <Link
                                            to={
                                                "/profissionais/editar/" +
                                                values?.professional_id?.value
                                            }
                                            target="_blank"
                                            align="start"
                                            style={{
                                                position: "absolute",
                                                left: "-35px",
                                                top: "-6px",
                                            }}
                                        >
                                            {" "}
                                            <i
                                                className="fas fa-user-md fa-2x"
                                                id="professionalinvoicetooltip"
                                            ></i>{" "}
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                    "professionalinvoicetooltip"
                                                }
                                            >
                                                Ver dados do profissional
                                            </UncontrolledTooltip>
                                        </Link>
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>

                            <Row>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label className="">
                                            {" "}
                                            Profissional
                                        </Label>

                                        <FastField
                                            name={`professional_id`}
                                            value={values.professional_id}
                                            component={SelectInputt}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label className="">
                                            {" "}
                                            CPF - Profissional
                                        </Label>
                                        <Input
                                            type="text"
                                            disabled={true}
                                            className="form-control bg-light"
                                            value={values.cpf_professional}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                
                                <Col sm="4">
                                    <FormGroup>
                                        <Label className="">
                                            {" "}
                                            CNPJ - Profissional
                                        </Label>
                                        <Input
                                            type="text"
                                            disabled={true}
                                            className="form-control bg-light"
                                            value={values.cnpj_professional
                                            }
                                        ></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="3">
                                    {" "}
                                    <CardTitle className="mb-3">
                                        Informações do Paciente
                                    </CardTitle>{" "}
                                </Col>

                                {onClose ? (
                                    <Col sm="2" xl="1" align="start">
                                        <Link
                                            to={
                                                "/pacientes/" +
                                                values?.patient_id?.value +
                                                "/dados"
                                            }
                                            target="_blank"
                                            align="start"
                                            style={{
                                                position: "absolute",
                                                left: "-58px",
                                                top: "-6px",
                                            }}
                                        >
                                            {" "}
                                            <i
                                                className="fas fa-user fa-2x"
                                                id="patientinvoicetooltip"
                                            ></i>{" "}
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"patientinvoicetooltip"}
                                            >
                                                Cadastro do Paciente
                                            </UncontrolledTooltip>
                                        </Link>
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                            <Row>
                                <Col sm="5">
                                    <FormGroup>
                                        <Label className=""> Paciente</Label>

                                        <FastField
                                            name={`patient_id`}
                                            value={values.patient_id}
                                            component={SelectInputt}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <Label>CPF</Label>
                                    <Input
                                        type="text"
                                        name="cpf_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.cpf_patient}
                                    ></Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="2">
                                    <Label>CEP</Label>
                                    <Input
                                        type="text"
                                        name="cep_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.cep_patient}
                                    ></Input>
                                </Col>
                                <Col sm="3">
                                    <Label>Bairro</Label>
                                    <Input
                                        type="text"
                                        name="district_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.district_patient}
                                    ></Input>
                                </Col>
                                <Col sm="5">
                                    <Label>Endereço</Label>
                                    <Input
                                        type="text"
                                        name="address_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.address_patient}
                                    ></Input>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col sm="2">
                                    <Label>Estado</Label>
                                    <Input
                                        type="text"
                                        name="state_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.state_patient}
                                    ></Input>
                                </Col>
                                <Col sm="3">
                                    <Label>Município</Label>

                                    <FastField
                                        name={`county_patient`}
                                        value={values.county_patient}
                                        component={SelectInputt}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col sm="5">
                                    <Label>Complemento</Label>
                                    <Input
                                        type="text"
                                        name="complement_patient"
                                        disabled={true}
                                        className="form-control bg-light"
                                        value={values.complement_patient}
                                    ></Input>
                                </Col>
                            </Row>

                            <hr />
                            <h5 className="mb-3">Itens</h5>
                            <Row>
                                {" "}
                                {/* Visível somente em tamanhos de tela md, lg, xl*/}
                                <Col
                                    xs="12"
                                    md="5"
                                    className="d-none d-md-block"
                                >
                                    <Label>Item</Label>
                                </Col>
                                <Col
                                    xs="12"
                                    md="1"
                                    className="d-none d-md-block"
                                >
                                    <Label>Quant.</Label>
                                </Col>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-none d-md-block"
                                >
                                    <Label>Prc. Tabela</Label>
                                </Col>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-none d-md-block"
                                >
                                    <Label>Valor Profissional</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FieldArray
                                        name="items"
                                        render={arrayHelpers => (
                                            <div>
                                                {values &&
                                                values?.items?.length > 0
                                                    ? values.items.map(
                                                          (item, index) => (
                                                              <Row
                                                                  key={index}
                                                                  className="align-items-center"
                                                              >
                                                                  <Col
                                                                      xs="12"
                                                                      sm="5"
                                                                  >
                                                                      <FormGroup>
                                                                          <Label className="d-block d-md-none">
                                                                              Serviço
                                                                          </Label>{" "}
                                                                          <FastField
                                                                              name={`items[${index}].item_id`}
                                                                              value={
                                                                                  item.item_id
                                                                              }
                                                                              component={
                                                                                  SelectInputt
                                                                              }
                                                                              isDisabled={
                                                                                  true
                                                                              }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Col
                                                                      xs="12"
                                                                      md="1"
                                                                  >
                                                                      <FormGroup>
                                                                          <Label className="d-block d-md-none">
                                                                              Quantidade
                                                                          </Label>{" "}
                                                                          <FastField
                                                                              name={`items[${index}].quantity`}
                                                                              value={
                                                                                  item.quantity
                                                                              }
                                                                              component={
                                                                                  CurrencyInput
                                                                              }
                                                                              className={`form-control bg-light`}
                                                                              readOnly={
                                                                                  true
                                                                              }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Col
                                                                      xs="12"
                                                                      md="2"
                                                                  >
                                                                      <FormGroup>
                                                                          <FastField
                                                                              name={`items[${index}].price_table`}
                                                                              disabled={
                                                                                  true
                                                                              }
                                                                              displayType={
                                                                                  "input"
                                                                              }
                                                                              thousandSeparator={
                                                                                  "."
                                                                              }
                                                                              decimalSeparator={
                                                                                  ","
                                                                              }
                                                                              decimalScale={
                                                                                  2
                                                                              }
                                                                              fixedDecimalScale={
                                                                                  true
                                                                              }
                                                                              allowEmptyFormatting={
                                                                                  true
                                                                              }
                                                                              prefix={
                                                                                  "R$ "
                                                                              }
                                                                              className="form-control bg-light"
                                                                              component={
                                                                                  CurrencyInput
                                                                              }
                                                                              value={
                                                                                  item?.price_table
                                                                              }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>

                                                                  <Col
                                                                      xs="12"
                                                                      md="2"
                                                                  >
                                                                      <FormGroup>
                                                                          <FastField
                                                                              name={`items[${index}].price`}
                                                                              disabled={
                                                                                  true
                                                                              }
                                                                              displayType={
                                                                                  "input"
                                                                              }
                                                                              thousandSeparator={
                                                                                  "."
                                                                              }
                                                                              decimalSeparator={
                                                                                  ","
                                                                              }
                                                                              decimalScale={
                                                                                  2
                                                                              }
                                                                              fixedDecimalScale={
                                                                                  true
                                                                              }
                                                                              allowEmptyFormatting={
                                                                                  true
                                                                              }
                                                                              prefix={
                                                                                  "R$ "
                                                                              }
                                                                              className="form-control bg-light"
                                                                              component={
                                                                                  CurrencyInput
                                                                              }
                                                                              value={
                                                                                  item.price
                                                                              }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                              </Row>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        )}
                                    />
                                    <hr />
                                </Col>
                            </Row>

                            <TaxLogStatus id={data?.note_id} />

                            {onClose ? (
                                <div align="end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            data?.status_fiscal?.value !=
                                            values?.status_fiscal?.value
                                                ? onClose("different")
                                                : onClose()
                                        }}
                                        className="btn btn-secondary waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Fechar
                                    </button>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </CardBody>
    )
}

export default componentInvoice
