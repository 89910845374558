import { Link } from "react-router-dom"

const PrintInvoiceButton = ({ id, isVisible }) => {
    if (isVisible === false) return <></>

    return (
        <Link
            to={"/imprimir/nota-repasse-medico/" + id}
            className="btn btn-primary"
            style={{ width: "100%" }}
            target="_blank"
        >
            Imprimir
        </Link>
    )
}

export default PrintInvoiceButton
