import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AsyncSelect from "react-select/async"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { deleteScheduleLock } from "services/vitta-core/appointment-book-lock"
import ScheduleLockColumns from "pages/Schedule/ScheduleLock/List/ListColumns"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { search as ScheduleServiceSearchLock } from "services/vitta-core/appointment-book-lock"
import { search as ScheduleServiceSearch } from "services/vitta-core/appointment-book"
import { search as unitServiceSearch } from "services/vitta-core/unit"
import debounce from "debounce-promise"
const ListScheduleLock = () => {
    const [ScheduleLockList, setScheduleLockList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data:[],
    })

    const inputStartDate = useRef()
    const inputEndDate = useRef()
    const inputUnitSearch = useRef()
    const inputScheduleSearch = useRef()

    const dispatch = useDispatch()

    const history = useHistory()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let data = await ScheduleServiceSearchLock(
                filter,
                page,
                sizePerPage
            )

            setScheduleLockList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        let searchObj = {}

        search(searchObj, 1)
    }, [])

    const searchScheduleLock = (page = 1) => {
        let searchObj = {}
        let unit_id = inputUnitSearch.current.select?.state?.value?.value
        let schedule_id =
            inputScheduleSearch.current.select?.state?.value?.value
        let date_start = inputStartDate.current.value
        let date_end = inputEndDate.current.value

        if (date_start) searchObj.date_start = date_start
        if (date_end) searchObj.date_end = date_end
        if (schedule_id) searchObj.appointment_book_id = schedule_id
        if (unit_id) searchObj.unit_id = unit_id

        search(searchObj, page)
    }

    const handleTableChange = (type, { page }) => {
        if (type == "pagination") {
            searchScheduleLock(page)
        }
    }

    const toggleModal = () => {
        setModal(!modal)
    }

    const deleteRecord = async (id,unit_id) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction:  () => {
                   confirmDeleteRecord(id,unit_id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmDeleteRecord = async (id,unit_id) => {
        try {
            await deleteScheduleLock(id,unit_id)
            let tempData = {...ScheduleLockList}
           
            tempData.data = ScheduleLockList?.data?.filter(elem =>id!=elem.id)

            setScheduleLockList(tempData)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    const loadScheduleInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await ScheduleServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let schedule_values = []

                //Vazio
                schedule_values.push({
                    label: "Sem Filtro",
                    value: null,
                })

                response.data.forEach(element => {
                    schedule_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(schedule_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadUnitsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await unitServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let schedule_values = []

                //Vazio
                schedule_values.push({
                    label: "Sem Filtro",
                    value: null,
                })

                response.data.forEach(element => {
                    schedule_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(schedule_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsSchedule = debounce(loadScheduleInputOptions, 600)
    const debounceOptionsUnit = debounce(loadUnitsInputOptions, 600)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Agenda"
                        breadcrumbItem="Bloqueios de Agendas"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            ScheduleLockList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={
                                                    ScheduleLockList.data || []
                                                }
                                                columns={ScheduleLockColumns(
                                                    deleteRecord
                                                )}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="2">
                                                                <Label>
                                                                    Agenda
                                                                </Label>
                                                                <AsyncSelect
                                                                    placeholder="selecione"
                                                                    loadOptions={
                                                                        debounceOptionsSchedule
                                                                    }
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    ref={
                                                                        inputScheduleSearch
                                                                    }
                                                                ></AsyncSelect>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Label>
                                                                    Unidade
                                                                </Label>
                                                                <AsyncSelect
                                                                    placeholder="selecione"
                                                                    loadOptions={
                                                                        debounceOptionsUnit
                                                                    }
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    ref={
                                                                        inputUnitSearch
                                                                    }
                                                                ></AsyncSelect>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Label>
                                                                    Bloqueio de:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputStartDate
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Label>
                                                                    Bloqueio
                                                                    Até:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputEndDate
                                                                    }
                                                                ></Input>
                                                            </Col>

                                                            <Col
                                                                sm="1"
                                                                className="mb-2"
                                                            >
                                                                <Button
                                                                    color="primary"
                                                                    className="mt-4"
                                                                    onClick={
                                                                        searchScheduleLock
                                                                    }
                                                                >
                                                                    Buscar
                                                                </Button>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div className="text-sm-right ">
                                                                    <Link to="/bloqueio-agendas/adicionar">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                        >
                                                                            Adicionar
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            handleTableChange
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListScheduleLock
