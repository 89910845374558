import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const ListPartnersColumns = deleteRecord => [
    {
        dataField: "img",
        text: "#",
        formatter: (cellContent, user) => (
            <>
                {!user.img ? (
                    <div className="avatar-xs">
                        <span className="avatar-title rounded-circle">
                            {user.name.charAt(0)}
                        </span>
                    </div>
                ) : (
                    <div>
                        <img
                            className="rounded-circle avatar-xs"
                            src={images[user.img]}
                            alt=""
                        />
                    </div>
                )}
            </>
        ),
    },
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "name",
        text: "Nome",
    },
    {
        dataField: "cnpj",
        text: "CNPJ",
    },
    {
        dataField: "email",
        text: "Email",
    },
    {
        dataField: "phone",
        text: "Telefone Celular",
    },
    {
        dataField: "addr_state",
        text: "Estado",
    },
    {
        dataField: "addr_city",
        text: "Cidade",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => (
            <Badge
                className={
                    row.status == 1
                        ? "font-size-12 badge-soft-success"
                        : "font-size-12 badge-soft-danger"
                }
                pill
            >
                {row.status == 1 ? "Ativo" : "Inativo"}
            </Badge>
        ),
    },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Link
                    to={"/parceiros/editar/" + row.id}
                    className="mr-3 text-primary"
                >
                    <i
                        className="mdi mdi-pencil-box-outline font-size-24 mr-3"
                        id="edittooltip"
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                        Editar
                    </UncontrolledTooltip>
                </Link>
                {/*<Link to="#" className="text-danger" onClick={() => {deleteRecord(row.id)}}>
                    <i className="mdi mdi-trash-can-outline font-size-24 mr-3" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                        Excluir
          </UncontrolledTooltip>
        </Link>*/}
            </>
        ),
    },
]

export default ListPartnersColumns
