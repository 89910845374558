import React, { useState, useEffect } from "react"
import { Row, Col, Modal, CardTitle, UncontrolledTooltip } from "reactstrap"

import { Link } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { findServiceWithStages } from "services/vitta-core/assessments"
import { useDispatch } from "react-redux"
import { getPaidServices } from "services/vitta-core/sales-order"
import { create as createExec } from "services/vitta-core/sales-order-item-exec"


const ModalExecStage = ({ show, data, onClose }) => {
  
    const [pageData, setPageData] = useState([])
    const [controlLoading, setControlLoading] = useState(false)
    const dispatch = useDispatch()
    const execItemStage = item => {
        return dispatch(
            showSweetAlertDialog({
                title:
                    "Tem certeza que deseja realizar essa execução?",
                text: "Será registado o seu usuário como executor.",
                type: "warning",
                onConfirmAction: () => {
                    handleConfirmationExec(item)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Confirmar",
            })
        )
    }
    
    const handleConfirmationExec = async item => {
        try {
            const sendData = {
                sales_order_item_id: item.sales_order_item_id,
                service_stage_id: item.stage_id,
                service_id: item.item_id
            }
             await createExec(sendData)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Execução realizada com sucesso!",
                    type: "success",
                })
            )
            loadDetails()
            //onClose()
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: e?.response?.data.message,
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Não foi possível realizar a execução, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    // const confirmDeleteExec = id => {
    //     dispatch(
    //         showSweetAlertDialog({
    //             title: "Atenção ",
    //             text: "Tem certeza que deseja remover esta execução?",
    //             type: "warning",
    //             onConfirmAction: () => {
    //                 deleteExec(id)
    //             },
    //             showCancel: true,
    //             showConfirm: true,
    //             cancelBtnText: "Não",
    //             confirmBtnText: "Sim",
    //         })
    //     )
    // }
    // const deleteExec = async id => {
    //     try {
    //         if (!id) return
    //         await removeExec(id)

    //         dispatch(
    //             showToast({
    //                 title: "Sucesso!",
    //                 text: "Execução removida com sucesso!",
    //                 type: "success",
    //             })
    //         )
    //         await loadDataEditPage()
    //         loadDetails()
    //         // onClose()
    //     } catch (e) {
    //         console.log(e)
    //         if (e?.response?.data) {
    //             return dispatch(
    //                 showSweetAlertDialog({
    //                     title: "Ops...",
    //                     text: e?.response?.data.message,
    //                     type: "error",
    //                     showConfirm: true,
    //                 })
    //             )
    //         }
    //         dispatch(
    //             showSweetAlertDialog({
    //                 title: "Ops...",
    //                 text:
    //                     "Não foi possível remover essa execução, tente novamente mais tarde...",
    //                 type: "error",
    //                 showConfirm: true,
    //             })
    //         )
    //     }
    // }

    const loadDetails = async () => {
        setControlLoading(true)
        try {
           
            let getInfo = await getPaidServices(data?.patient_id)
            let result = []
            getInfo.forEach(element => {
                    result.push({
                      id: element.sales_order_id,
                      sales_order_item_id:element.sales_order_item_id,
                      item_name: element.item_name,
                      item_id:element.item_id,
                      stage_name: element.stage_id,
                      stage_id:element.stage_id,
                      tooth_number: element.tooth_number
                    });
                });
              
            
            setPageData(result)
        } catch (e) {
            console.log(e)
        }
        setControlLoading(false)
    }


    useEffect(() => {
        if(show){
            loadDetails()
        }else{
            setPageData([])
        }
        
    }, [show])


    return (
        <Modal
            isOpen={show}
            centered={true}
            size={"xl"}
        // toggle={onCloseModal}
        >



            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Itens Aguardando Execução
                </h5>
                <button
                    type="button"
                    onClick={onClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
               

               
                    <Row className="mt-1">
                        <Col sm="12">
                            <table
                                className="table"
                                style={{ width: "100%" }}
                            >
                                <thead
                                    style={{
                                        backgroundColor:
                                            "#f8f9fa",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <tr>
                                        <th scope="col">
                                            Pedido
                                        </th>
                                        <th scope="col">
                                            Procedimento
                                        </th>
                                        <th scope="col">
                                            Fase
                                        </th>
                                        <th scope="col">
                                            Dente
                                        </th>
                                        <th scope="col">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData?.length >
                                        0 ? (
                                            pageData?.map(
                                            (
                                                item,
                                                index
                                            ) => (
                                                <tr>
                                                    <td align="start">
                                                        {
                                                            item.id
                                                        }
                                                    </td>
                                                    <td align="start">
                                                        {
                                                            item.item_name
                                                        }
                                                    </td>
                                                    <td align="start">
                                                        {
                                                            item.stage_name
                                                        }
                                                    </td>
                                                    <td align="start">
                                                        {
                                                            item.tooth_number
                                                        }
                                                    </td>
                                                    
                                                    <td
                                                        align="start"
                                                        style={{
                                                            paddingTop:
                                                                "4px",
                                                        }}
                                                    >
                                                        
                                                            <Link
                                                                to="#"
                                                                className="text-primary"
                                                                onClick={() =>
                                                                    execItemStage(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className="mdi mdi-plus-box-outline font-size-24"
                                                                    id={
                                                                        "edittooltip" +
                                                                       index
                                                                    }
                                                                />

                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={
                                                                        "edittooltip" +
                                                                        index
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#007bff",
                                                                        color:
                                                                            "white",
                                                                    }}
                                                                >
                                                                    Executar
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                       
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    ) : (
                                       ''
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
              
            </div>


        </Modal>
    )
}

export default ModalExecStage
