import {
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    CardText,
    Nav,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import { create, update } from "services/vitta-core/patient"
import { useParams } from "react-router-dom"
import states from "utils/states"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { search as countySeacrh } from "services/vitta-core/county"
import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import MARITAL_TYPES from "utils/consts/patient-marital-types"
import GENDER_TYPES from "utils/consts/patient-gender-types"
import SKIN_TYPES from "utils/consts/patient-skin-types"
import ORIGIN_TYPES from "utils/consts/patient-origin-types"
import EMPLOYEE_RELATION_TYPES from "utils/consts/employee-relation-types"
import InputMask from "react-input-mask"
import ModalCardVitta from "./CardVitta/Modal"
import checkPermission from "utils/auth"

const RegistrationData = props => {
    //functions image

    /*function doctorimg() {
            const imgdoctor = document.getElementById("img-doctor")
            const inputdoctor = document.getElementById("input-doctor")
            imgdoctor.addEventListener("click", () => inputdoctor.click())
            inputdoctor.addEventListener("change", () => {
                if (inputdoctor.files.length <= 0) {
                    return false
                }
                let file = new FileReader()
                file.onload = () => {
                    imgdoctor.src = file.result
                }
                file.readAsDataURL(inputdoctor.files[0])
            })
        }
        */
    const [activeTab, setactiveTab] = useState("1")

    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }

    const defaultValues = {
        addr_address: "",
        addr_district: "",
    }
    const { id } = useParams()

    const defaultPageControl = {
        submiting: false,
    }

    const [pageData, setPageData] = useState({})
    const [pageControl, setPageControl] = useState(defaultPageControl)
    const [isDisabled, setIsDisabled] = useState(false)

    const dispatch = useDispatch()
    const User = useSelector(state => state.auth.access.user)
    const Scope = useSelector(state => state.scope);
    const history = useHistory()
    const stateLocation = useLocation()
    const [countyInputState, setCountyInputState] = useState(null)
    const [weight, setWeight] = useState("")
    const [stateInputState, setStateInputState] = useState(null)
    const [stateAddress, setStateAddress] = useState(defaultValues)
    const [controlModalVitta, setControlModalVitta] = useState({
        data: null,
        show: false,
    })
    const stateAddressInputRef = useRef(null)

    useEffect(() => {
        const loadDataEditPage = async () => {
            try {
                setPageData({
                    patient: props?.patientData,
                })
                if (!checkPermission.hasPermission(User, "edit_patient"))
                    setIsDisabled(true)

                if (props?.patientData?.weight) {
                    let valor = props?.patientData?.weight
                    if (valor?.toString().length < 5) valor = valor / 1000
                    else if (valor?.toString().length >= 5) valor = valor + " "
                    setWeight(valor.toString())
                }

                setCountyInputState({
                    label: props?.patientData?.county?.name,
                    value: props?.patientData?.county?.id,
                })
                setStateInputState(props?.patientData?.addr_state)

                setStateAddress({
                    addr_address: props?.patientData?.addr_address,
                    addr_district: props?.patientData?.addr_district,
                })
            } catch (e) {
                console.log(e)
            }
        }

        setPageControl(defaultPageControl)

        if (props) loadDataEditPage()
    }, [props])

    const loadCountyInputOptions = async term => {
        return new Promise(async (resolve, reject) => {
            try {

                if (
                    !stateAddressInputRef ||
                    !stateAddressInputRef.current ||
                    !stateAddressInputRef.current.value || !term
                )
                    return resolve([])

                
                //console.log("loading loadCountyInputOptions", stateAddressInputRef.current, stateAddressInputRef.current.value);
             
                let response = await countySeacrh(
                    { state: stateAddressInputRef.current.value, term: term },
                    1,
                    20
                )

                if (!response) return

                let counties_value = []
                response.forEach(element => {
                    counties_value.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(counties_value)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsCounty = debounce(loadCountyInputOptions, 600)

    const handleSubmit = async (event, formData) => {
        if (pageControl.submiting) {
            return
        }

        setPageControl({ ...pageControl, ...{ submiting: true } })

        let data = JSON.parse(JSON.stringify(formData))
        data.unit_id = Scope?.unit?.id;

        if(!id && !data.origin){
            setPageControl({ ...pageControl, ...{ submiting: false } })
            return dispatch(
                    showSweetAlertDialog({
                        title: 'Ops...',
                        text: "É necessário preencher o campo 'Por onde conheceu a clínica?' em Dados Complementares.",
                        type: "error",
                        showConfirm: true,
                  })
            )
        }
        /*
        if(!countyInputState?.value)
        {   
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Necessário informar o município do Paciente.",
                    type: "warning",
                })
            )
        }
        */

        if (data.weight) {
            let str = data.weight.toString().replace(/,/g, ".")
            data.weight = parseFloat(str) * 1000
        } else {
            data.weight = null
        }

        if (data.height) {
            let str = data.height.toString().replace(/,/g, "")
            data.height = parseInt(str) * 100
        } else {
            data.height = null
        }

        if (!data.origin) data.origin = null

        if (data?.gender == "Selecionar") {
            data.gender = ""
        }

        if (data?.phone?.length > 10) {
            let str = data?.phone?.replaceAll("_", "")
            data.phone =
                str?.substr(1, 2) + str?.substr(5, 4) + str?.substr(10, 4)
        }

        data.addr_county_id =
            countyInputState?.value && countyInputState?.value != ""
                ? countyInputState?.value
                : null
        if (data?.mobile_phone?.length > 11) {
            let str = data?.mobile_phone.replaceAll("_", "")
            data.mobile_phone =
                str?.substr(1, 2) + str?.substr(5, 5) + str?.substr(11, 4)
        }

        try {
            if (id) {
                await update(id, data)
            } else {
                await create(data)
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            setPageControl({ ...pageControl, ...{ submiting: false } })

            if (stateLocation?.state?.previousPath) {
                history.goBack()
            } else {
                history.push("/pacientes")
            }
        } catch (e) {
            let title = "Ops..."
            let textError =
                "Houve um erro ao processar sua solicitação, certifique-se de preencher os campos corretamente ou tente novamente mais tarde..."
            if (e?.response?.data?.error_code == "cep_not_existent") {
                title = "CEP INVÁLIDO!"
                textError =
                    "Esse CEP não existe, coloque um CEP válido e tente novamente!"
            }
            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "patient_created_with_cpf":
                        textError =
                            "Já existe um paciente cadastrado com esse CPF."
                        break
                    case "cpf_not_valid":
                        title = "O CPF informado não é válido!"
                        textError =
                            "Verifique o CPF informado e tente novamente."
                        break
                    case "mobile_phone_not_valid":
                        title = "Número do telefone inválido!"
                        textError =
                            "Informe o número com DDD. Mínimo 10 e Máximo 11 dígitos!"
                        break

                    case "addr_number_invalid":
                        title = "Número do endereço inválido!"
                        textError = "Informe apenas números no campo número."
                        break
                    case "phone_not_valid":
                        title = "Telefone Residencial inválido!"
                        textError =
                            "Verifique se o campo foi preenchido corretamente e tente novamente!"
                        break
                    case "mail_invalid":
                        title = "E-mail Inválido!"
                        textError =
                            "O E-mail precisa ter características de um email como '@' e não pode contar informações que relacionem a palavra 'vitta'."
                        break
                    case "user_without_rh_permission":
                        title =
                            'Usuário sem permissão para alterar o campo "Relação de Colaborador"!'
                        textError = ""
                    case "cant_change_cpf":
                        title = "Não é possível alterar o CPF de um paciente!"
                        textError =
                            "Obs: Caso o cpf preenchido estiver errado, favor regristrar o paciente de forma correta!"
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }

        setPageControl({ ...pageControl, ...{ submiting: false } })
    }

    const handleCep = async () => {
        try {
            let dataCep = document.getElementById("cep").value

            if (
                !dataCep ||
                isNaN(dataCep) === true ||
                dataCep.toString().length < 8
            ) {
                return
            }
            const url = "https://viacep.com.br/ws/" + dataCep + "/json/"

            let data = await fetch(url)

            const endereco = await data.json()

            preencher(endereco)
        } catch (e) {
            console.log(e)
        }
    }

    const defaultCepValues = {
        logradouro: "",
        bairro: "",
        uf: "",
        ibge_code: "",
    }
    const preencher = async data => {
        try {
            setStateAddress({
                addr_address: data.logradouro ? data.logradouro : "",
                addr_district: data.bairro ? data.bairro : "",
            })

            setStateInputState(data.uf ? data.uf : "")

            let response = await countySeacrh({ ibge_code: data.ibge })

            setCountyInputState({
                label: response?.name ? response?.name : "",
                value: response?.id ? response?.id : "",
            })
        } catch (e) {
            console.log(e)
        }
    }

    const controlPermissionViewPatientContactData = useMemo(() => {
        let found = checkPermission.hasPermission(
            User,
            "view_patient_contact_data"
        )
        if (found) {
            return true
        } else {
            return false
        }
    }, [User])

    const controlPermissionVittaCard = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "vitta_card_manage") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return "vitta_card_manage"
        } else {
            return ""
        }
    }, [User])
    const genWeightMask = () => {
        if (weight.length < 6) return "9,9999"
        else if (weight.length < 7) return "99,9999"
        return "999,999"
    }
    return (
        <React.Fragment>
            <div
                translate="no"
                className={id ? "notranslate" : "page-content notranslate"}
            >
                <AvForm
                    className="needs-validation notranslate"
                    onValidSubmit={handleSubmit}
                    translate="no"
                >
                    <Row>
                        <Col xs="12">
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <Nav
                                                tabs
                                                className="nav-tabs-custom "
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            active:
                                                                activeTab ===
                                                                "1",
                                                        })}
                                                        onClick={() => {
                                                            toggle("1")
                                                        }}
                                                    >
                                                        Dados Básicos
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            active:
                                                                activeTab ===
                                                                "2",
                                                        })}
                                                        onClick={() => {
                                                            toggle("2")
                                                        }}
                                                    >
                                                        Dados Complementares
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="12" align="start">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <CardText>
                                                <Row>
                                                    <Col xs="12">
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col sm="10">
                                                                        <CardTitle>
                                                                            Informações
                                                                            Pessoais
                                                                        </CardTitle>
                                                                        <CardSubtitle className="mb-3">
                                                                            É
                                                                            necessário
                                                                            preencher
                                                                            todas
                                                                            as
                                                                            informações
                                                                            {/* <div style={{color:"red",fontWeight:"bold",fontSize:11}}> Caso seja inserido um CPF incorreto, ressaltamos que não é possível realizar a alteração posteriormente.</div> */}
                                                                        </CardSubtitle>
                                                                    </Col>
                                                                    {/*controlPermissionVittaCard &&
                                                                    controlPermissionVittaCard ==
                                                                        "vitta_card_manage" ? (
                                                                        <Col
                                                                            sm="2"
                                                                            align="center"
                                                                        >
                                                                            <Button
                                                                                className="btn btn-info text-white"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setControlModalVitta(
                                                                                        {
                                                                                            data: {
                                                                                                patient_id: id,
                                                                                            },
                                                                                            show: true,
                                                                                        }
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                <span className="text-white">
                                                                                    <i className="fas fa-address-card text-white mr-2 fa-lg"></i>
                                                                                    Vittá+{" "}
                                                                                </span>
                                                                            </Button>
                                                                        </Col>
                                                                    ) : (
                                                                        ""
                                                                    )*/}
                                                                </Row>

                                                                <Row>
                                                                    {/*}
                                                                            <Col sm="3" className="mt-4">
                                                                                <FormGroup onLoad={() => {}}>
                                                                                    <div className="form-group col-12 mw-20">
                                                                                        <div className="card-picture d-flex justify-content-center align-items-center ">
                                                                                            <img
                                                                                                src={logoupload}
                                                                                                id="img-doctor"
                                                                                                alt="doctor"
                                                                                                height="105"
                                                                                                name="doctor"
                                                                                                width="105"
                                                                                                className="mt-2"
                                                                                            />
                                                                                            <label>
                                                                                                Foto{" "}
                                                                                                <small>
                                                                                                    120x120
                                                                                                </small>
                                                                                            </label>
                                                                                        </div>
                                                                                        <input
                                                                                            type="file"
                                                                                            id="input-doctor"
                                                                                            name="picture"
                                                                                            accept="/image"
                                                                                            className="d-none "
                                                                                        />
                                                                                    </div>
                                                                                </FormGroup>
                                                                            </Col>*/}
                                                                    <Col
                                                                        sm={"8"}
                                                                    >
                                                                        <AvField
                                                                            label="Nome"
                                                                            id="nomepacient"
                                                                            name="name"
                                                                            disabled={
                                                                                isDisabled
                                                                            }
                                                                            style={
                                                                                isDisabled
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              "#f2f2f2",
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              "white",
                                                                                      }
                                                                            }
                                                                            className="form-control"
                                                                            type="text"
                                                                            errorMessage=" Preencha o campo Nome ."
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                },
                                                                                minLength: {
                                                                                    value: 3,
                                                                                    errorMessage:
                                                                                        "É necessário pelomenos 3 caracteres",
                                                                                },
                                                                            }}
                                                                            value={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.name
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        sm={"4"}
                                                                    >
                                                                        <AvField
                                                                            label="CPF"
                                                                            id="cpfpatient"
                                                                            name="cpf"
                                                                            disabled={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.cpf ||
                                                                                isDisabled
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            style={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.cpf ||
                                                                                isDisabled
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              "#f2f2f2",
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              "white",
                                                                                      }
                                                                            }
                                                                            className="form-control"
                                                                            type="text"
                                                                            // errorMessage=" Preencha o campo CPF ."
                                                                            // validate={{
                                                                            //     required: {
                                                                            //         value: true,
                                                                            //     },
                                                                            //     minLength: {
                                                                            //         value: 11,
                                                                            //         errorMessage:
                                                                            //             "É necessário pelo menos 11 caracteres",
                                                                            //     },
                                                                            // }}
                                                                            value={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.cpf
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="4">
                                                                        <AvField
                                                                            label="Data de Nascimento"
                                                                            id="datanascimento"
                                                                            name="birth_date"
                                                                            className="form-control"
                                                                            type="date"
                                                                            disabled={
                                                                                isDisabled
                                                                            }
                                                                            style={
                                                                                isDisabled
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              "#f2f2f2",
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              "white",
                                                                                      }
                                                                            }
                                                                            errorMessage=" Preencha o campo Data de Nascimento ."
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                },
                                                                            }}
                                                                            value={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.birth_date
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <AvField
                                                                            label="Gênero(Sexo)"
                                                                            id="generopatient"
                                                                            name="gender"
                                                                            className="form-control"
                                                                            type="select"
                                                                            disabled={
                                                                                isDisabled
                                                                            }
                                                                            style={
                                                                                isDisabled
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              "#f2f2f2",
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              "white",
                                                                                      }
                                                                            }
                                                                            errorMessage=" Preencha o campo Gênero ."
                                                                            value={
                                                                                pageData
                                                                                    ?.patient
                                                                                    ?.gender
                                                                            }
                                                                        >
                                                                            <option>
                                                                                Selecionar
                                                                            </option>
                                                                            <option
                                                                                value={
                                                                                    GENDER_TYPES.MASCULINO
                                                                                }
                                                                            >
                                                                                Masculino
                                                                            </option>
                                                                            <option
                                                                                value={
                                                                                    GENDER_TYPES.FEMININO
                                                                                }
                                                                            >
                                                                                Feminino
                                                                            </option>
                                                                            <option
                                                                                value={
                                                                                    GENDER_TYPES.INDEFINIDO
                                                                                }
                                                                            >
                                                                                Indefinido
                                                                            </option>
                                                                        </AvField>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <AvField
                                                                            className="form-control"
                                                                            label="Status"
                                                                            type="select"
                                                                            name="status"
                                                                            disabled={
                                                                                isDisabled
                                                                            }
                                                                            style={
                                                                                isDisabled
                                                                                    ? {
                                                                                          backgroundColor:
                                                                                              "#f2f2f2",
                                                                                      }
                                                                                    : {
                                                                                          backgroundColor:
                                                                                              "white",
                                                                                      }
                                                                            }
                                                                            id="patient_status"
                                                                            value={
                                                                                pageData?.patient
                                                                                    ? pageData
                                                                                          ?.patient
                                                                                          .status
                                                                                    : 1
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    1
                                                                                }
                                                                            >
                                                                                Ativo
                                                                            </option>
                                                                            <option
                                                                                value={
                                                                                    0
                                                                                }
                                                                            >
                                                                                Inativo
                                                                            </option>
                                                                        </AvField>
                                                                    </Col>
                                                                </Row>
                                                                {/* FIM PRIMEIRO linha */}
                                                            </CardBody>
                                                        </Card>
                                                        {controlPermissionViewPatientContactData ? (
                                                            <>
                                                                <Card>
                                                                    <CardBody>
                                                                        <div align="start">
                                                                            <CardTitle>
                                                                                Informações
                                                                                de
                                                                                contato
                                                                            </CardTitle>
                                                                            <CardSubtitle className="mb-2">
                                                                                É
                                                                                necessário
                                                                                preencher
                                                                                todas
                                                                                as
                                                                                informações
                                                                            </CardSubtitle>
                                                                        </div>
                                                                        <Row className="mt-3">
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="Telefone Celular"
                                                                                    id="Telefonecelular"
                                                                                    name="mobile_phone"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    errorMessage=" Preencha o campo Telefone Celular ."
                                                                                    tag={[
                                                                                        InputMask,
                                                                                    ]}
                                                                                    mask="(99) 99999-9999"
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: true,
                                                                                        },
                                                                                        minLength: {
                                                                                            value: 3,
                                                                                            errorMessage:
                                                                                                "É necessário pelomenos 3 caracteres",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.mobile_phone
                                                                                    }
                                                                                ></AvField>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="Telefone Residencial"
                                                                                    id="telresidencial"
                                                                                    name="phone"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    tag={[
                                                                                        InputMask,
                                                                                    ]}
                                                                                    mask="(99) 9999-9999"
                                                                                    errorMessage=" Preencha o campo Telefone Residencial."
                                                                                    validate={{
                                                                                        minLength: {
                                                                                            value: 3,
                                                                                            errorMessage:
                                                                                                "É necessário pelomenos 3 caracteres",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.phone
                                                                                    }
                                                                                ></AvField>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="E-mail"
                                                                                    id="emailpacient"
                                                                                    name="email"
                                                                                    className="form-control"
                                                                                    type="email"
                                                                                    errorMessage=" Preencha o campo E-mail ."
                                                                                    validate={{
                                                                                        minLength: {
                                                                                            value: 3,
                                                                                            errorMessage:
                                                                                                "É necessário pelomenos 3 caracteres",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.email
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        {/* fim segunda linha */}
                                                                    </CardBody>
                                                                </Card>

                                                                <Card id="cardaddrespatient">
                                                                    <CardBody>
                                                                        <CardTitle>
                                                                            Informações
                                                                            de
                                                                            Endereço
                                                                        </CardTitle>
                                                                        <CardSubtitle className="mb-2">
                                                                            É
                                                                            necessário
                                                                            preencher
                                                                            todas
                                                                            as
                                                                            informações
                                                                        </CardSubtitle>

                                                                        <Row className="mt-1">
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="CEP"
                                                                                    id="cep"
                                                                                    name="addr_cep"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    errorMessage=" Preencha o campo CEP."
                                                                                    validate={{
                                                                                        minLength: {
                                                                                            value: 8,
                                                                                            errorMessage:
                                                                                                "É necessário pelo menos 8 caracteres",
                                                                                        },
                                                                                        maxLength: {
                                                                                            value: 8,
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.addr_cep
                                                                                    }
                                                                                    onKeyUp={() => {
                                                                                        handleCep()
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="Endereço"
                                                                                    id="addrespatient"
                                                                                    name="addr_address"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    errorMessage=" Preencha o campo Endereço."
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: false,
                                                                                        },
                                                                                        minLength: {
                                                                                            value: 3,
                                                                                            errorMessage:
                                                                                                "É necessário pelomenos 3 caracteres",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        stateAddress?.addr_address
                                                                                    }
                                                                                ></AvField>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    className="form-control select2"
                                                                                    label="Número"
                                                                                    type="text"
                                                                                    name="addr_number"
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: false,
                                                                                        },
                                                                                    }}
                                                                                    errorMessage=" Preencha o campo Número."
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.addr_number
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    label="Complemento"
                                                                                    id="Complemento"
                                                                                    name="addr_complement"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: false,
                                                                                        },
                                                                                    }}
                                                                                    errorMessage="Preencha o campo Complemento"
                                                                                    value={
                                                                                        pageData
                                                                                            ?.patient
                                                                                            ?.addr_complement
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    className="form-control "
                                                                                    type="text"
                                                                                    id="Bairro"
                                                                                    label="Bairro"
                                                                                    name="addr_district"
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: false,
                                                                                        },
                                                                                    }}
                                                                                    errorMessage=" Preencha o campo Bairro"
                                                                                    value={
                                                                                        stateAddress?.addr_district
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <AvField
                                                                                    innerRef={
                                                                                        stateAddressInputRef
                                                                                    }
                                                                                    className="form-control select2"
                                                                                    type="select"
                                                                                    id="estadoss"
                                                                                    label="Estado"
                                                                                    name="addr_state"
                                                                                    validate={{
                                                                                        required: {
                                                                                            value: false,
                                                                                        },
                                                                                    }}
                                                                                    errorMessage=" Preencha o campo Estado"
                                                                                    value={
                                                                                        stateInputState
                                                                                    }
                                                                                    onChange={data => {
                                                                                        setStateInputState(
                                                                                            data
                                                                                                ?.target
                                                                                                ?.value
                                                                                        )
                                                                                        setCountyInputState(
                                                                                            null
                                                                                        ) //Clear county input, force user add new county
                                                                                    }}
                                                                                >
                                                                                    <option value="">
                                                                                        Selecionar
                                                                                    </option>
                                                                                    {states.map(
                                                                                        state => (
                                                                                            <option
                                                                                                key={
                                                                                                    state.sigla
                                                                                                }
                                                                                                value={
                                                                                                    state.sigla
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    state.estado
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </AvField>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col sm="6">
                                                                                {stateInputState ? ( //need this to load after stateinput have value
                                                                                    <>
                                                                                        <Label>
                                                                                            Município
                                                                                        </Label>
                                                                                        <AsyncSelect
                                                                                            key={
                                                                                                stateInputState
                                                                                            }
                                                                                            name="addr_county_id"
                                                                                            placeholder="Selecionar Município"
                                                                                            defaultOptions
                                                                                            loadOptions={
                                                                                                debounceOptionsCounty
                                                                                            }
                                                                                            value={
                                                                                                countyInputState
                                                                                            }
                                                                                            onChange={data => {
                                                                                                if (
                                                                                                    data?.value &&
                                                                                                    countyInputState?.value &&
                                                                                                    data?.value !=
                                                                                                        countyInputState?.value
                                                                                                ) {
                                                                                                    let record = {
                                                                                                        ...pageData,
                                                                                                    }
                                                                                                    if (
                                                                                                        record
                                                                                                            ?.patient
                                                                                                            ?.addr_cep
                                                                                                    ) {
                                                                                                        record.patient.addr_cep =
                                                                                                            ""
                                                                                                        record.patient.addr_complement =
                                                                                                            ""
                                                                                                        record.patient.addr_number =
                                                                                                            ""
                                                                                                        setPageData(
                                                                                                            record
                                                                                                        )
                                                                                                    } else {
                                                                                                        document.getElementById(
                                                                                                            "cep"
                                                                                                        ).value =
                                                                                                            ""
                                                                                                    }

                                                                                                    preencher(
                                                                                                        defaultCepValues
                                                                                                    )
                                                                                                    setCountyInputState(
                                                                                                        null
                                                                                                    )

                                                                                                    // console.log("teste",record,pageData)
                                                                                                    return
                                                                                                }
                                                                                                setCountyInputState(
                                                                                                    data
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                ) : null}
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Col>
                                                </Row>
                                            </CardText>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="2" align="start">
                                            <CardText>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            Informações Gerais
                                                        </CardTitle>

                                                        <Row className="mt-3">
                                                            <Col sm="4">
                                                                <AvField
                                                                    label="Etnia/Cor"
                                                                    id="raça"
                                                                    name="skin_color"
                                                                    className="form-control"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    type="select"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.skin_color
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecionar
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            SKIN_TYPES.AMARELA
                                                                        }
                                                                    >
                                                                        Amarela
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            SKIN_TYPES.BRANCA
                                                                        }
                                                                    >
                                                                        Branca
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            SKIN_TYPES.INDIGENA
                                                                        }
                                                                    >
                                                                        Indígena
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            SKIN_TYPES.NEGRA
                                                                        }
                                                                    >
                                                                        Negra
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            SKIN_TYPES.PARDA
                                                                        }
                                                                    >
                                                                        Parda{" "}
                                                                    </option>
                                                                </AvField>
                                                            </Col>
                                                            <Col sm="4">
                                                                <AvField
                                                                    label="Tipo Sanguíneo"
                                                                    id="tiposangue"
                                                                    name="blood_type"
                                                                    className="form-control"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    type="select"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.blood_type
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecionar
                                                                    </option>
                                                                    <option value="A+">
                                                                        A+
                                                                    </option>
                                                                    <option value="A-">
                                                                        A-
                                                                    </option>
                                                                    <option value="AB+">
                                                                        AB+
                                                                    </option>
                                                                    <option value="AB-">
                                                                        AB-
                                                                    </option>
                                                                    <option value="B+">
                                                                        B+
                                                                    </option>
                                                                    <option value="B-">
                                                                        B-
                                                                    </option>
                                                                    <option value="O+">
                                                                        O+
                                                                    </option>
                                                                    <option value="O-">
                                                                        O-
                                                                    </option>
                                                                </AvField>
                                                            </Col>
                                                            <Col sm="4">
                                                                <AvField
                                                                    className="form-control select2"
                                                                    label="Estado Civil"
                                                                    type="select"
                                                                    name="marital_status"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    id="estados"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.marital_status ||
                                                                        null
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecionar
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            MARITAL_TYPES.SOLTEIRO
                                                                        }
                                                                    >
                                                                        Solteiro
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            MARITAL_TYPES.CASADO
                                                                        }
                                                                    >
                                                                        Casado
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            MARITAL_TYPES.VIUVO
                                                                        }
                                                                    >
                                                                        Viúvo
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            MARITAL_TYPES.DIVORCIADO
                                                                        }
                                                                    >
                                                                        Divorciado
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            MARITAL_TYPES.UNIAO_ESTAVEL
                                                                        }
                                                                    >
                                                                        União
                                                                        Estável
                                                                    </option>
                                                                </AvField>
                                                            </Col>
                                                            <Col sm="3">
                                                                <AvField
                                                                    label="Peso (kg)"
                                                                    id="peso"
                                                                    name="weight"
                                                                    className="form-control"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    type="text"
                                                                    tag={[
                                                                        InputMask,
                                                                    ]}
                                                                    mask={genWeightMask()}
                                                                    maskChar=""
                                                                    value={
                                                                        weight
                                                                    }
                                                                    onChange={e => {
                                                                        setWeight(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="3">
                                                                <AvField
                                                                    label="Altura (m)"
                                                                    id="altura"
                                                                    name="height"
                                                                    className="form-control"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    type="text"
                                                                    tag={[
                                                                        InputMask,
                                                                    ]}
                                                                    mask="9,99"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.height
                                                                            ? pageData
                                                                                  .patient
                                                                                  .height /
                                                                              100
                                                                            : null
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="3">
                                                                <AvField
                                                                    label="Por onde conheceu a clínica?" //Por onde conheceu a clínica
                                                                    id="origem"
                                                                    name="origin"
                                                                    className="form-control"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    type="select"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.origin
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecionar
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.INDICACAO
                                                                        }
                                                                    >
                                                                        Indicação
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.GOOGLE
                                                                        }
                                                                    >
                                                                        Google
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.INSTAGRAM
                                                                        }
                                                                    >
                                                                        Instagram
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.RADIO
                                                                        }
                                                                    >
                                                                        Rádio
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.ACAO_SOCIAL
                                                                        }
                                                                    >
                                                                        Ação
                                                                        social
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.TV
                                                                        }
                                                                    >
                                                                        TV
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            ORIGIN_TYPES.PANFLETAGEM
                                                                        }
                                                                    >
                                                                        Panfletagem
                                                                    </option>
                                                                </AvField>
                                                            </Col>
                                                            {id ? (
                                                                <Col sm="3">
                                                                    <AvField
                                                                        label="Relação de Colaborador"
                                                                        name="employee_relation"
                                                                        style={
                                                                            isDisabled
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          "#f2f2f2",
                                                                                  }
                                                                                : {
                                                                                      backgroundColor:
                                                                                          "white",
                                                                                  }
                                                                        }
                                                                        className="form-control"
                                                                        disabled={
                                                                            isDisabled
                                                                        }
                                                                        type="select"
                                                                        errorMessage=" Preencha o campo Gênero ."
                                                                        value={
                                                                            pageData
                                                                                ?.patient
                                                                                ?.employee_relation
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Selecionar
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                EMPLOYEE_RELATION_TYPES.COLLABORATOR
                                                                            }
                                                                        >
                                                                            Colaborador
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                EMPLOYEE_RELATION_TYPES.EMPLOYEE_DEPENDENT
                                                                            }
                                                                        >
                                                                            Dependente
                                                                            de
                                                                            colaborador
                                                                        </option>
                                                                    </AvField>
                                                                </Col>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <CardTitle align="left">
                                                            Informações
                                                            Familiares
                                                        </CardTitle>

                                                        <Row className="mt-3">
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="Nome da Mãe"
                                                                    id="nomemae"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="mother_name"
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.mother_name
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="Nome do Pai"
                                                                    id="nomepai"
                                                                    name="father_name"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.father_name
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-3">
                                                            <Col sm="4">
                                                                <AvField
                                                                    className="form-control select2"
                                                                    label="Nome do Responsável"
                                                                    type="text"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="responsible_name"
                                                                    id="nomeresponsavel"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.responsible_name
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <AvField
                                                                    label="CPF do Responsável"
                                                                    id="cpfresponsavel"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="responsible_cpf"
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.responsible_cpf
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <AvField
                                                                    label="Telefone do Responsável"
                                                                    id="telresponsavel"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="responsible_name"
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.responsible_name
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <CardTitle align="left">
                                                            Documentos
                                                        </CardTitle>
                                                        <Row>
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="RG"
                                                                    id="rgpacient"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="rg_number"
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.rg_number
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="UF do RG"
                                                                    id="ufrg"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="rg_uf"
                                                                    className="form-control select2"
                                                                    type="select"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.rg_uf
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecionar
                                                                    </option>
                                                                    {states.map(
                                                                        state => (
                                                                            <option
                                                                                key={
                                                                                    state.sigla
                                                                                }
                                                                                value={
                                                                                    state.sigla
                                                                                }
                                                                            >
                                                                                {
                                                                                    state.estado
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </AvField>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="Orgão Emissor"
                                                                    id="orgaoemissor"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="rg_org"
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.rg_org
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <AvField
                                                                    label="Data de Expedição"
                                                                    id="dataexpedic"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                    style={
                                                                        isDisabled
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      "#f2f2f2",
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      "white",
                                                                              }
                                                                    }
                                                                    name="rg_emission"
                                                                    className="form-control"
                                                                    type="date"
                                                                    value={
                                                                        pageData
                                                                            ?.patient
                                                                            ?.rg_emission
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </CardText>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Col>
                        {props?.controlState ? (
                            <div
                                className="spinner-grow text-info mt-4"
                                role="status"
                                style={{
                                    position: "absolute",
                                    left: "400px",
                                    top: "700",
                                }}
                            >
                                <span className="sr-only">
                                    Sincronizando...
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                        {props?.controlState ? (
                            <div
                                className="spinner-grow text-dark mt-4"
                                role="status"
                                style={{
                                    position: "absolute",
                                    left: "425px",
                                    top: "700",
                                }}
                            >
                                <span className="sr-only">
                                    Sincronizando...
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </Row>
                    <div align="end" className="mb-4">
                        {controlPermissionViewPatientContactData ? (
                            <Button color="primary" type="submit">
                                Salvar
                            </Button>
                        ) : (
                            ""
                        )}

                        <Button
                            onClick={() => {
                                if (stateLocation?.state?.previousPath) {
                                    history.goBack()
                                } else {
                                    history.push("/pacientes")
                                }
                            }}
                            className="btn btn-danger ml-1"
                        >
                            Retornar
                        </Button>
                    </div>
                </AvForm>
            </div>
            <ModalCardVitta
                show={controlModalVitta.show}
                data={controlModalVitta.data}
                onClose={() => {
                    setControlModalVitta({
                        show: false,
                        data: null,
                    })
                }}
            />
        </React.Fragment>
    )
}

export default RegistrationData
