const convertValueToMonetary = (value, ignorePrefix = false) => {
    const result = new Intl.NumberFormat("pt-br", {
        style: "decimal",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value)

    if (ignorePrefix) return result

    return "R$ " + result
}

module.exports = { convertValueToMonetary }
