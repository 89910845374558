export const CONTRACT_STATUS = {
    CREATED: 1,
    WAITING_SIGN: 2,
    SIGNED: 3,
    CANCELED: 4,
}

export const CONTRACT_PAYMENT_METHODS = [
    {
        id: 1,
        name: "Boleto",
    },
    {
        id: 2,
        name: "Cartão de Crédito",
    },
    {
        id: 3,
        name: "PIX",
    },
    // {
    //     id: 4,
    //     name: "Cliente escolhe",
    // },
]

export const CONTRACT_RECURRENCIES = [
    {
        id: 1,
        name: "Mensal",
    },
    {
        id: 2,
        name: "Anual",
    },
]

export function getContractStatusName(status) {
    switch (status) {
        case CONTRACT_STATUS.CREATED:
            return "Criado"

        case CONTRACT_STATUS.WAITING_SIGN:
            return "Aguardando Assinatura"

        case CONTRACT_STATUS.SIGNED:
            return "Assinado"

        case CONTRACT_STATUS.CANCELED:
            return "Cancelado"

        default:
            return "Não identificado"
    }
}

export function contractIsActive(contract) {
    if (
        [CONTRACT_STATUS.CREATED, CONTRACT_STATUS.SIGNED, CONTRACT_STATUS.WAITING_SIGN].includes(
            contract.status
        )
    ) {
        return true
    }

    return false
}

export function checkIfCardHasActiveContract(contracts, notInclude = null) {

    if (!Array.isArray(contracts)) return false

    let hasActive = false
    
    contracts.forEach(contract => {
        if(notInclude != null && contract.id == notInclude.id) return;
        
        if (contractIsActive(contract)) {
            hasActive = true
        }
    })

    return hasActive
}
