import { FastField, Field, Formik } from "formik"
import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Button, CardBody, CardSubtitle, Col, Label, Row, Input } from "reactstrap"
import { createContract } from "services/vitta-core/vitta-card-contract"
import { showSweetAlertDialog, showToast } from "store/actions"
import {
    CONTRACT_PAYMENT_METHODS,
    CONTRACT_RECURRENCIES,
} from "./ContractsList/Contract"

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />

const AddContract = ({ data, onSuccess }) => {
    
    const dispatch = useDispatch()
    const Scope = useSelector(state => state.scope)
    const userAuth = useSelector(state => state.auth.access?.user)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const [state, setState] = useState({
        vitta_card_id: null,
        payment_method_id: null,
        recurrency_id: null,
        unit_id: {
            label:Scope?.unit?.name,
            value:Scope?.unit?.id
        }
    })

    function clear() {
        setState({
            vitta_card_id: null,
            payment_method_id: null,
            recurrency_id: null,
            unit_id: {
                label:Scope?.unit?.name,
                value:Scope?.unit?.id
            }
        })
    }

    const optionsVittaCard = useMemo(() => {
        let options = []

        if (data && data?.cards?.length > 0) {
            for (let i = 0; i < data?.cards?.length; i++) {
                let use = data?.cards[i]
                if (use?.card_id && use?.id) {
                    options.push({ label: use?.card_id, value: use?.id })
                }
            }
        }
        return options
    }, [data])

    const optionsPaymentMethods = CONTRACT_PAYMENT_METHODS.map(method => {
        return {
            value: method.id,
            label: method.name,
        }
    })

    const optionsRecurrency = CONTRACT_RECURRENCIES.map(recurrency => {
        return {
            value: recurrency.id,
            label: recurrency.name,
        }
    })

    const ReactSelectInput = ({
        field, // { name, value, onChange, onBlur }
        form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => (
        <Select
            {...field}
            {...props}
            onChange={option => {
                setFieldValue(field.name, option)
            }}
        />
    )

    async function onSubmit(values) {
        if (
            !values.vitta_card_id ||
            !values.recurrency_id ||
            !values.payment_method_id ||
            !values.dependents_quantity
        ) {
            dispatch(
                showSweetAlertDialog({
                    title:
                        "Preencha todos os campos antes de criar o contrato.",
                    type: "warning",
                    showConfirm: true,
                    confirmBtnText: "OK!",
                })
            )
            return
        }

        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma a inclusão de um novo contrato?",
                type: "warning",
                onConfirmAction: () => {
                    create(values)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    async function create(values) {
        const data = {
            payment_method_id: values.payment_method_id.value,
            recurrency_id: values.recurrency_id.value,
            unit_id: values.unit_id.value,
            dependents_quantity: values.dependents_quantity
        }

        try {
            await createContract(values.vitta_card_id.value, data)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Contrato adicionado ao cartão!",
                    type: "success",
                })
            )

            clear()

            onSuccess()
        } catch (e) {
            let title = "Opss..";
            let textError = "Erro ao criar Contrato";
       
            if(e?.response?.status == 403)
            {
                textError = "Operação não autorizada. Solicite acesso ao seu gestor."
            }
            else if(e?.response?.data?.error)
            {
                textError = e?.response?.data?.error;
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.error(e)
        }
    }

    return (
        <Formik initialValues={state} enableReinitialize={true}>
            {({ values }) => (
                <CardBody>
                    <Row>
                    <Col sm="12" className="ml-2">
                            <CardSubtitle>Adicionar Contrato</CardSubtitle>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                    <Col lg="4" md="12" sm="12" className="mt-1">
                            <Label className="ml-1">Unidade Contrato</Label>
                            <Field
                                name="unit_id"
                                placeholder="Selecione a Unidade"
                                options={unitsOptions}
                                component={ReactSelectInput}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                    <Col lg="4" md="12" sm="12" className="mt-1">
                            <Label className="ml-1">Número do Cartão</Label>
                            <Field
                                name="vitta_card_id"
                                placeholder="Busque o cartão..."
                                options={optionsVittaCard}
                                component={ReactSelectInput}
                            />
                        </Col>
                        <Col lg="4" md="6" sm="12" className="mt-1">
                            <Label className="ml-1">Método de Pagamento</Label>
                            <FastField
                                name="payment_method_id"
                                placeholder="Selecione..."
                                options={optionsPaymentMethods}
                                component={ReactSelectInput}
                            />
                        </Col>
                        <Col lg="2" md="6" sm="12" className=" mt-1">
                            <Label className="ml-1">Recorrência</Label>
                            <FastField
                                name="recurrency_id"
                                placeholder="Selecione..."
                                options={optionsRecurrency}
                                component={ReactSelectInput}
                            />
                        </Col>
                        <Col lg="2" md="6" sm="12" className=" mt-1">
                            <Label className="ml-1">Dependentes</Label>
                            <FastField
                                name="dependents_quantity"
                                component={NumberInput}
                                min={3}
                            />
                        </Col>
                        <Col lg="1" md="12" sm="12" className="mt-2 ml-2">
                            <Button
                                className="btn btn-info mt-4"
                                onClick={() => onSubmit(values)}
                            >
                                Adicionar{" "}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            )}
        </Formik>
    )
}

export default AddContract
