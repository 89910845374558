import React, { useMemo, useState, useRef, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    Label,
    Button,
    Card,
    Input,
    FormGroup,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import AppointmentReport from "pages/Reports/Appointment/report"
import APPOINTMENT_STATUS from "utils/consts/appointments-status"
import {
    AppointmentAnalyticalReport,
    AppointmentAnalyticalReportExport,
    ViewMyProfAppointmentAnalyticalReport,
    ViewMyProfAppointmentAnalyticalReportExport
} from "services/vitta-core/appointment"
import debounce from "debounce-promise"
import SALES_ORDER_STATUS from "utils/consts/sales-orders-status"
import { search as appointmentBookServiceSearch } from "services/vitta-core/appointment-book"
import { search as userServiceSearch } from "services/vitta-core/user"
import { search as itemGroupServiceSearch } from "services/vitta-core/item-groups"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)
import { search as ProfessionalServiceSearch } from "services/vitta-core/professional"
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const SwitchInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <div className="custom-switch custom-switch-lg mb-3" dir="ltr">
        <Input
            type="checkbox"
            className="custom-control-input"
            {...field}
            {...props}
            /*onChange={value => { 
                setFieldValue(field.name, value.target.checked)
            }}*/
            onClick={() => {
                /*this.setState({
                  toggleSwitchLarge: !this.state
                    .toggleSwitchLarge,
                })*/
            }}
        />
        <label className="custom-control-label" htmlFor={props.id}></label>
    </div>
)
const AppointmentAnalyticalIndex = () => {
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })

    const [controlState,setControlState] = useState(false);

    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)
    
    const [firstPage, setFirstPage] = useState(true)
    useEffect(() => {
        if(!firstPage){
            setReportControl({
                show: false,
                filter: null,
                data: null
            });
	        setFirstPage(true)    
        }
      }, [firstPage]);

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const controlPermissionAppointment = useMemo(() => {
        let found = false
        for (let i = 0; i < userAuth?.access_roles?.length; i++) {
            let record = userAuth?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_appointment") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return "view_appointment"
        } else {
            return "view_my_prof_appointment"
        }
    }, [userAuth])

    const AppointmentStatusOptions = useMemo(() => {
        let options_list = [
            { label: "AGENDADO", value: APPOINTMENT_STATUS.AGENDADO },
            { label: "CONFIRMADO", value: APPOINTMENT_STATUS.CONFIRMADO },
            { label: "DESMARCADO (PACIENTE)", value: APPOINTMENT_STATUS.DESMARCADO },
            { label: "CANCELADO (CLÍNICA)", value: APPOINTMENT_STATUS.CANCELADO },
            { label: "FALTOU", value: APPOINTMENT_STATUS.FALTOU },
            { label: "FALTOU CONFIRMADO", value: APPOINTMENT_STATUS.FALTOU_CONFIRMADO },
            { label: "EM ESPERA", value: APPOINTMENT_STATUS.EM_ESPERA },
            {
                label: "EM ATENDIMENTO",
                value: APPOINTMENT_STATUS.EM_ATENDIMENTO,
            },
            { label: "CONCLUIDO", value: APPOINTMENT_STATUS.CONCLUIDO },
            { label: "COMPARECEU", value: APPOINTMENT_STATUS.COMPARECEU },
            { label: "PAGO", value: APPOINTMENT_STATUS.PAGO },
            {
                label: "AGUARDANDO RECEPÇÃO",
                value: APPOINTMENT_STATUS.AGUARDANDO_RECEPCAO,
            },
            {
                label: "AGUARDANDO CAIXA",
                value: APPOINTMENT_STATUS.AGUARDANDO_CAIXA,
            },
            {
                label: "AGUARDANDO LABORATORIO",
                value: APPOINTMENT_STATUS.AGUARDANDO_LABORATORIO,
            },
        ]
        return options_list
    }, [SALES_ORDER_STATUS])

    const FitOptions = useMemo(() => {
        let options_list = [
            { label: "NÃO É ENCAIXE", value: 0 },
            { label: "É ENCAIXE ", value: 1 },
        ]
        return options_list
    }, [SALES_ORDER_STATUS])

    const loadPatientsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await PatientServiceSearch(
                    { term: inputValue,fields:"id,name" },
                    1,
                    25
                )

                let users_values = []
                users_values.push({ label: "Todos", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientsInputOptions, 600)
    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)

    const loadItemGroupInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await itemGroupServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsItemGroups = debounce(loadItemGroupInputOptions, 600)
    const loadServiceInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            if (!inputValue) return resolve([])

            try {
                let response = await itemServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )
                let data_values = []
                data_values.push({ label: "Sem filtro", value: "" })
                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsService = debounce(loadServiceInputOptions, 600)

    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await ProfessionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    50
                )

                let users_values = []

                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )

    const loadAppointmentBookInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await appointmentBookServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsAppointment = debounce(
        loadAppointmentBookInputOptions,
        600
    )
    const onSubmit = async (data_form, actions) => {
        try {
            if (!data_form.unit_id || data_form.unit_id.length == 0)
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: "Informe pelo menos uma Unidade Clínica.",
                        type: "error",
                        showConfirm: true,
                    })
                )

            if (!data_form.status || data_form.status.length == 0)
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: "Informe pelo menos um status.",
                        type: "error",
                        showConfirm: true,
                    })
                )

            let auxData = {}
            auxData.patient_id = data_form.patient_id?.value
                ? data_form.patient_id?.value
                : null
            auxData.professional_id = data_form.professional_id?.value
                ? data_form.professional_id?.value
                : null

            auxData.user_id = data_form.user_id?.value
                ? data_form.user_id?.value
                : null
            auxData.book_id = data_form.book_id?.value
                ? data_form.book_id?.value
                : null
            auxData.service_id = data_form.service_id?.value
                ? data_form.service_id?.value
                : null
            auxData.start_created_at = data_form.start_created_at
            auxData.end_created_at = data_form.end_created_at
            auxData.start_exec_in = data_form.start_exec_in
            auxData.end_exec_in = data_form.end_exec_in
            auxData.is_fit = data_form.accept_fit

            let unit_ids = []
            for (let i = 0; i < data_form.unit_id.length; i++) {
                unit_ids.push(data_form.unit_id[i].value)
            }

            let status = []
            for (let i = 0; i < data_form.status.length; i++) {
                status.push(data_form.status[i].value)
            }
            if(controlPermissionAppointment && controlPermissionAppointment == "view_appointment")
            {
                if (data_form.format?.value == "excel") {
                    setControlState(true);
                   
                    let response = await AppointmentAnalyticalReportExport({
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        professional_id: auxData.professional_id,
                        user_id: auxData.user_id,
                        service_id: auxData.service_id,
                        status: status,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        start_exec_in: auxData.start_exec_in,
                        end_exec_in: auxData.end_exec_in,
                        book_id: auxData.book_id,
                    })
    
                    var headers = response.headers
                    var blob = new Blob([response.data], {
                        type: headers["content-type"],
                    })
                    var link = document.createElement("a")
                    link.href = window.URL.createObjectURL(blob)
                    link.download = "Agendamentos - Analitico"
                    link.click()
                    setControlState(false);
                } else {
                    setControlState(true);
                   
                    let dataReport = await AppointmentAnalyticalReport({
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        status: status,
                        professional_id: auxData.professional_id,
                        service_id: auxData.service_id,
                        user_id: auxData.user_id,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        start_exec_in: auxData.start_exec_in,
                        end_exec_in: auxData.end_exec_in,
                        book_id: auxData.book_id,
                    })
    
                    setControlState(false);
                    if (!dataReport) {
                        return
                    }
    
                    setReportControl({
                        show: true,
                        filter: data_form,
                        data: dataReport,
                    })
                    
                }
            }
            else
            {
                if (data_form.format?.value == "excel") {
                    setControlState(true);
                   
                    let response = await ViewMyProfAppointmentAnalyticalReportExport({
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        user_id: auxData.user_id,
                        service_id: auxData.service_id,
                        status: status,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        start_exec_in: auxData.start_exec_in,
                        end_exec_in: auxData.end_exec_in,
                        book_id: auxData.book_id,
                    })
    
                    var headers = response.headers
                    var blob = new Blob([response.data], {
                        type: headers["content-type"],
                    })
                    var link = document.createElement("a")
                    link.href = window.URL.createObjectURL(blob)
                    link.download = "Agendamentos - Analitico"
                    link.click()
                    setControlState(false);
                } else {
                    setControlState(true);
                   
                    let dataReport = await ViewMyProfAppointmentAnalyticalReport({
                        patient_id: auxData.patient_id,
                        unit_id: unit_ids,
                        status: status,
                        service_id: auxData.service_id,
                        user_id: auxData.user_id,
                        start_created_at: auxData.start_created_at,
                        end_created_at: auxData.end_created_at,
                        start_exec_in: auxData.start_exec_in,
                        end_exec_in: auxData.end_exec_in,
                        book_id: auxData.book_id,
                    })
    
                    setControlState(false);
                    if (!dataReport) {
                        return
                    }
    
                    setReportControl({
                        show: true,
                        filter: data_form,
                        data: dataReport,
                    })
                    
                }
            }

          
        } catch (e) {
            console.log(e)
            setControlState(false);
            let textError =
                "Ocorreu algum problema, Preencha os campos corretamente e tente novamente."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            }

            if(e?.response?.data?.error_code=="professional_not_found")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Profissional não encontrado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return;
            }
            if(e?.response?.data?.error_code=="date_period_limit_exceeded")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text:"O Período máximo de filtro entre datas é de 31 dias.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return;
            }
            if(e?.response?.data?.error_code=="date_invalid")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Data inválida!",
                        text:
                            "Verifique se os filtros de data foram preenchidos corretamente, é necessário utilizar ao menos um filtro de período inicial e final .",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return;
            }
         
        

            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    if (reportControl.show) {
        return (
            <AppointmentReport
                filter={reportControl.filter}
                data={reportControl.data}
                state={setFirstPage}
            />
        )
    }else{
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Relatórios"
                            breadcrumbItem="Agendamentos"
                        />
                        <Row>
                            <Col xs="12">
                                <Row className="d-flex justify-content-center">
                                    <Col xl="8">
                                        <Card>
                                            <CardBody>
                                                <h3
                                                    align="center"
                                                    className="mb-4 mt-4 text-dark"
                                                >
                                                    Agendamentos{" "}
                                                    <i className="fas fa-calendar-check fa-lg"></i>
                                                </h3>
                                                <Formik
                                                    initialValues={{
                                                        patient_id: null,
                                                        unit_id: Scope.unit
                                                            ? [
                                                                  {
                                                                      label: Scope.unit.name?.substr(
                                                                          0,
                                                                          40
                                                                      ),
                                                                      value:
                                                                          Scope.unit
                                                                              .id,
                                                                  },
                                                              ]
                                                            : null,
                                                        start_created_at: "",
                                                        end_created_at: "",
                                                        start_exec_in: todayOnlyDate,
                                                        status: AppointmentStatusOptions,
                                                        end_exec_in: todayOnlyDate,
                                                        format: {
                                                            label: "Visualização",
                                                            value: "view",
                                                        },
                                                    }}
                                                    onSubmit={onSubmit}
                                                >
                                                    {formProps => (
                                                        <Form>
                                                            <Row>
                                                                <Col
                                                                    sm="6"
                                                                    align="start"
                                                                ></Col>
                                                                <Col
                                                                    sm="6"
                                                                    align="end"
                                                                >
                                                                    <Label className="text-dark">
                                                                        Filtros
                                                                        Opcionais
                                                                    </Label>
                                                                </Col>
                                                            </Row>
    
                                                            <Row>
                                                                <Col sm="6">
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Status
                                                                            </Label>
                                                                            <Field
                                                                                name="status"
                                                                                placeholder={
                                                                                    "Selecione"
                                                                                }
                                                                                options={
                                                                                    AppointmentStatusOptions
                                                                                }
                                                                                component={
                                                                                    ReactSelectInput
                                                                                }
                                                                                isMulti={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Unidade
                                                                            </Label>
                                                                            <Field
                                                                                name={`unit_id`}
                                                                                placeholder="Selecione a Unidade"
                                                                                classNamePrefix="select2-selection"
                                                                                options={
                                                                                    unitsOptions
                                                                                }
                                                                                isMulti={
                                                                                    true
                                                                                }
                                                                                component={
                                                                                    ReactSelectInput
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Data
                                                                                Execução
                                                                                Inicial
                                                                            </Label>
                                                                            <Input
                                                                                onChange={
                                                                                    formProps.handleChange
                                                                                }
                                                                                onBlur={
                                                                                    formProps.handleBlur
                                                                                }
                                                                                name="start_exec_in"
                                                                                type="date"
                                                                                defaultValue={
                                                                                    formProps
                                                                                        ?.values
                                                                                        ?.start_exec_in
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Data
                                                                                Execução
                                                                                Final
                                                                            </Label>
                                                                            <Input
                                                                                onChange={
                                                                                    formProps.handleChange
                                                                                }
                                                                                onBlur={
                                                                                    formProps.handleBlur
                                                                                }
                                                                                name="end_exec_in"
                                                                                type="date"
                                                                                defaultValue={
                                                                                    formProps
                                                                                        ?.values
                                                                                        ?.end_exec_in
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Formato
                                                                                de
                                                                                Saída
                                                                            </Label>
                                                                            <Field
                                                                                name="format"
                                                                                placeholder={
                                                                                    "Selecione"
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        label:
                                                                                            "Visualização",
                                                                                        value:
                                                                                            "view",
                                                                                    },
                                                                                    {
                                                                                        label:
                                                                                            "Excel",
                                                                                        value:
                                                                                            "excel",
                                                                                    },
                                                                                ]}
                                                                                component={
                                                                                    ReactSelectInput
                                                                                }
                                                                                isMulti={
                                                                                    false
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Paciente
                                                                            </Label>
                                                                            <Field
                                                                                name="patient_id"
                                                                                placeholder="Selecionar Paciente"
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                isClearable
                                                                                defaultValue={
                                                                                    formProps.patient
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsPatient
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Agenda
                                                                            </Label>
                                                                            <Field
                                                                                name="book_id"
                                                                                placeholder="Selecionar Agenda"
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                defaultValue={
                                                                                    formProps.book_id
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsAppointment
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Usuário
                                                                            </Label>
                                                                            <Field
                                                                                name="user_id"
                                                                                placeholder="Selecionar Usuário"
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                defaultValue={
                                                                                    formProps.user_id
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsUser
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Serviço
                                                                            </Label>
                                                                            <Field
                                                                                name="service_id"
                                                                                placeholder="Selecionar serviço"
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                isClearable
                                                                                defaultValue={
                                                                                    formProps.service_id
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsService
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {controlPermissionAppointment && controlPermissionAppointment != "view_my_prof_appointment" ?   <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Profissional
                                                                            </Label>
                                                                            <Field
                                                                                name="professional_id"
                                                                                placeholder="Selecionar Profissional"
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                defaultValue={
                                                                                    formProps.professional_id
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsProfessional
                                                                                }
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col> : ''}
                                                                  
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Data
                                                                                Marcação
                                                                                inicial{" "}
                                                                            </Label>
                                                                            <Input
                                                                                onChange={
                                                                                    formProps.handleChange
                                                                                }
                                                                                onBlur={
                                                                                    formProps.handleBlur
                                                                                }
                                                                                name={
                                                                                    "start_created_at"
                                                                                }
                                                                                type="date"
                                                                                defaultValue={
                                                                                    formProps
                                                                                        ?.values
                                                                                        .start_created_at
                                                                                }
                                                                            ></Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                {" "}
                                                                                Data
                                                                                Marcação
                                                                                final
                                                                            </Label>
                                                                            <Input
                                                                                onChange={
                                                                                    formProps.handleChange
                                                                                }
                                                                                onBlur={
                                                                                    formProps.handleBlur
                                                                                }
                                                                                name={
                                                                                    "end_created_at"
                                                                                }
                                                                                type="date"
                                                                                defaultValue={
                                                                                    formProps
                                                                                        ?.values
                                                                                        .end_created_at
                                                                                }
                                                                            ></Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {/* <Col sm="12">
                                                                <FormGroup>
                                                                    <Label>
                                                                       Grupo item
                                                                    </Label>
                                                                    <Field
                                                                        name="group_item"
                                                                        placeholder="Selecionar grupo"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        defaultValue={
                                                                            formProps.group_item
                                                                        }
                                                                        loadOptions={
                                                                            debounceOptionsItemGroups
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                        noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
                                                                    />
                                                                     </FormGroup>
                                                                </Col> */}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col
                                                                        sm="1"
                                                                        align="center"
                                                                        className="mt-4 ml-1"
                                                                    >
                                                                        {controlState ? (
                                                                            <div
                                                                                className="spinner-border text-dark"
                                                                                role="status"
                                                                            >
                                                                                <span className="sr-only">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        ) : null}
                                                                    </Col>
                                                                <Col
                                                                    sm="10"
                                                                    align="center"
                                                                    className="mt-4 ml-2"
                                                                >
                                                                {controlState ? (
                                                                            <Button
                                                                                type="button"
                                                                                className="btn btn-dark"
                                                                                disabled
                                                                            >
                                                                                Gerar
                                                                                Relatório
                                                                                <i className="fas fa-lock ml-2 fa-lg"></i>
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                type="submit"
                                                                                className="btn btn-dark"
                                                                            >
                                                                                Gerar
                                                                                Relatório
                                                                                <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                            </Button>
                                                                        )}
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )

    }

    
}

export default AppointmentAnalyticalIndex
