import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import {Link} from "react-router-dom"
// users
import user1 from "../../../assets/images/users/user2.png"

import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from "store/auth/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch();

  const userAuth = useSelector(state => state.auth.access?.user);

  const logOut = () => {
    dispatch(userLogout())
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src= {userAuth?.picture ? userAuth?.picture : user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">{userAuth?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu right>
          {/*<DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle mr-1"/>
            {"Profile"}{" "}
          </DropdownItem>
          <div className="dropdown-divider"/>*/}
          <DropdownItem className="mb-1">
           
          <Link to="/conta" className="text-dark"><i className="fas fa-user font-size-16 align-middle mr-2"></i>Conta</Link> 
          </DropdownItem>

          <DropdownItem onClick={logOut}>
            <i className="bx bx-power-off font-size-17 align-middle mr-1 text-danger"/>
            <span>{"Sair"}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu