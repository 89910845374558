import React, { useState, useEffect } from "react"
import { Container, Row, Col, CardBody, Button, Card, Badge } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { attendancesReport as AttendancesReport } from "services/vitta-core/attendance"
import { useHistory } from "react-router-dom"
import ATTENDANCE_STATUS from "utils/consts/attendances-status"

const ReportMedicalAssistance = ({ filter }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
    })

    const [controlState, setControlState] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        let search = location.search
        const params = new URLSearchParams(search)
        const professional_id = params.get("professional_id")
        const patient_id = params.get("patient_id")
        const unit_id = params.get("unit_id")
        const start_created_at = params.get("start_created_at")
        const end_created_at = params.get("end_created_at")
        const loadData = async () => {
            try {
                setControlState(true)

                let result = await AttendancesReport(
                    {
                        professional_id: professional_id,
                        unit_id: unit_id,
                        patient_id: patient_id,
                        start_created_at: start_created_at,
                        end_created_at: end_created_at,
                    },
                    1,
                    1000
                )

                let total = null
                for (let i = 0; i < result?.result?.data.length; i++) {
                    total = total + 1
                }

                setPageData({
                    header: {
                        unit: result?.unit,
                        professional: result?.professional
                            ? result?.professional
                            : null,
                        start_created_at: start_created_at,
                        end_created_at: end_created_at,
                        total: total,
                    },
                    data: result?.result?.data,
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
                if (e?.response?.data) {
                    switch (e.response.data.error_code) {
                        case "date_period_limit_exceeded":
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Filtro de data inválido!",
                                    text:
                                        "É possível filtrar no máximo 6 meses de diferença entre datas sem paciente e 5 anos com paciente específico, verifique os filtros de data e tente novamente.",
                                    type: "error",
                                    onConfirmAction: () => {
                                        history.push(
                                            "/relatorios/atend-medicos"
                                        )
                                        dispatch(
                                            showToast({
                                                title:
                                                    "Você foi redirecionado!",
                                                type: "success",
                                            })
                                        )
                                    },
                                    showConfirm: true,
                                    confirmBtnText: "Entendi!",
                                })
                            )
                            return
                    }
                }
            }
        }

        if (unit_id) {
            loadData()
        }
    }, [])

    const printReport = () => {
        window.print()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Atendimentos Médicos"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div align="end">
                                        <Link
                                            to="/relatorios/atend-medicos"
                                            className="btn btn-dark d-print-none mr-2"
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Button
                                            className="btn btn-primary d-print-none"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Button>
                                    </div>
                                    <AvForm align="center">
                                        <img
                                            src={logovitta}
                                            alt=""
                                            height="75px"
                                            className="mb-2"
                                        />
                                        <h2 align="center">
                                            {pageData.header?.unit?.name}
                                        </h2>
                                        <p />
                                        <h4>
                                            Relatório de Atendimentos Médicos -{" "}
                                            {pageData.header?.start_created_at
                                                ? pageData.header.start_created_at.substr(
                                                      8,
                                                      2
                                                  ) +
                                                  "/" +
                                                  pageData.header.start_created_at.substr(
                                                      5,
                                                      2
                                                  ) +
                                                  "/" +
                                                  pageData.header.start_created_at.substr(
                                                      0,
                                                      4
                                                  ) +
                                                  " a " +
                                                  pageData.header.start_created_at.substr(
                                                      11,
                                                      8
                                                  )
                                                : null}{" "}
                                            {pageData.header?.end_created_at
                                                ? 
                                                pageData.header.end_created_at.substr(
                                                      8,
                                                      2
                                                  ) +
                                                  "/" +
                                                  pageData.header.end_created_at.substr(
                                                      5,
                                                      2
                                                  ) +
                                                  "/" +
                                                  pageData.header.end_created_at.substr(
                                                      0,
                                                      4
                                                  ) +
                                                  " " +
                                                  pageData.header.end_created_at.substr(
                                                      11,
                                                      8
                                                  )
                                                : null}
                                        </h4>

                                        <h5 className="mt-4">
                                            {pageData.header?.professional
                                                ? "Profissional: " +
                                                  pageData.header?.professional
                                                      ?.name
                                                : null}
                                        </h5>
                                        <p></p>
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">
                                                        Nº do Registro
                                                    </th>
                                                    <th scope="col">Data</th>
                                                    <th scope="col">
                                                        Paciente
                                                    </th>

                                                    <th scope="col">
                                                        Profissional do
                                                        Atendimento
                                                    </th>

                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageData.data.map(
                                                    (row, index) => (
                                                        <tr>
                                                            <td>#{row?.id}</td>
                                                            <td>
                                                                {row.created_at
                                                                    ? new Date( row.created_at).toLocaleDateString('pt-BR', {hour:"2-digit", hour12: false, minute:"2-digit", second:"2-digit"}) 
                                                                    : null}{" "}
                                                            </td>
                                                            <th>
                                                                #
                                                                {
                                                                    row.patient
                                                                        ?.id
                                                                }
                                                                -
                                                                {
                                                                    row.patient
                                                                        ?.name
                                                                }
                                                            </th>
                                                            <td>
                                                                {" "}
                                                                <strong>
                                                                    {
                                                                        row
                                                                            .professional
                                                                            ?.name
                                                                    }
                                                                </strong>
                                                            </td>

                                                            <td>
                                                                {row?.status &&
                                                                row?.status ==
                                                                    ATTENDANCE_STATUS.IN_PROGRESS ? (
                                                                    <Badge className="font-size-12 badge-soft-info  badge badge-pill">
                                                                        Em
                                                                        Andamento
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge className="font-size-13 badge-soft-success badge badge-pill">
                                                                        Finalizado
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        {controlState ? (
                                            <Row>
                                                <Col align="center">
                                                <div
                                                className="spinner-border text-info"
                                                role="status"
                                             
                                            >
                                                <span className="sr-only">
                                                    Sincronizando...
                                                </span>
                                            </div></Col>
                                            </Row>
                                     
                                        ) : (
                                            ""
                                        )}
                                        <br />
                                        <br />
                                        <strong>
                                            {pageData?.header?.total &&
                                            pageData?.header?.total != null
                                                ? "Total Atendimentos : " +
                                                  pageData?.header?.total
                                                : ""}
                                        </strong>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ReportMedicalAssistance
