import React from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"

import { Formik, Form, FastField } from "formik"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

import NumberFormat from "react-number-format"
const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const HandleDiscountItem = ({
    show,
    data,
    applyPricingForm,
    applyRulePricing,
    onClose,
}) => {
    const dispatch = useDispatch()

    const defaultValues = {
        pricing_item: "",
    }

    const confirmChangeData = data_form => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja Aplicar este desconto?",
                type: "warning",
                onConfirmAction: () => {
                    onSubmit(data_form)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const onSubmit = async data_form => {
        try {
            if (!data_form?.pricing_item) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Preencha o campo de desconto!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            if (data?.price - data_form?.pricing_item < 0) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Desconto é maior que o valor do item.",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            let priceResult = data?.price - data_form?.pricing_item

            applyRulePricing({
                origin: "user",
                origin_type: "user",
                value: Math.trunc(data_form?.pricing_item * 100),
                type: "DESC",
                level: "item",
            })
            applyPricingForm(priceResult)

            dispatch(
                showToast({
                    text: "Desconto adicionado com sucesso!",
                    type: "success",
                    showConfirm: true,
                })
            )
            onClose(true)
        } catch (e) {
            console.log(e)

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    return (
        <Modal
            isOpen={show}
            centered={true}
            // toggle={onCloseModal}
        >
            <Formik
                initialValues={defaultValues}
                enableReinitialize={true}
                onSubmit={confirmChangeData}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                {/* {console.log("teste",data)} */}
                                Aplicar desconto - {data?.item_id?.label}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="12">
                                    <Label>Desconto a ser aplicado</Label>
                                    <FastField
                                        name={`pricing_item`}
                                        defaultValue={values?.pricing_item}
                                        displayType={"input"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        prefix={"R$ "}
                                        className="form-control"
                                        formValues={values}
                                        component={CurrencyInput}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default HandleDiscountItem
