import React from "react"
import { Spinner } from "reactstrap"
const Loading = () => {
    return (
        <div>
            <Spinner size="sm" color="primary" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="secondary" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="success" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="danger" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="warning" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="info" type="grow">
                Loading...
            </Spinner>
            <Spinner size="sm" color="light" type="grow">
                Loading...
            </Spinner>
            <Spinner color="dark" size="sm" type="grow">
                Loading...
            </Spinner>
        </div>
    )
}

export default Loading
