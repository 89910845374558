import request from "./config/request"

const update = async (id, professional_data, vitta_payments_electronic_mode) => {

    const entity = vitta_payments_electronic_mode ? "vittapag":"zoop";

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-bank-accounts/update/${id}?entity=${entity}`,
        data: professional_data,
        vtAuth: true,
    })
}
const create = async (id, professional_data, vitta_payments_electronic_mode) => {

    const entity = vitta_payments_electronic_mode ? "vittapag":"zoop";

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-bank-accounts/create/${id}?entity=${entity}`,
        data: professional_data,
        vtAuth: true,
    })
}
const destroy = async (id,vitta_payments_electronic_mode) => {

    const entity = vitta_payments_electronic_mode ? "vittapag":"zoop";

    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-bank-accounts/delete/${id}?entity=${entity}`,
        vtAuth: true,
    })
}
export { update,create,destroy }
