import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import RenderForm from "../GeneralAttendance/components/renderForm"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import ReferralAttendanceColumns from "pages/Patients/Detail/pages/ReferralAttendance/ReferralAttendanceColumns"

//import do services de usar dps alterar para os corretos

import { useDispatch } from "react-redux"
import { searchReferral as searchReferral } from "services/vitta-core/attendance"

const listPrescriptions = props => {
    const { patient_id } = useParams()
    const [prescriptionList, setPrescriptionList] = useState({
        sizePerPage: 10,
        totalSize: 50,
        custom: true,
        data: [],
    })

    const [modalPrescriptionData, setModalPrescriptionData] = useState({
        show: false,
        data: null,
    })
    const { SearchBar } = Search

    const dispatch = useDispatch()

    const search = async (filter, page) => {
        let sizePerPage = 10
        try {
            let dataReferral = []
            let dataFormatPrescription = []
            let record = await searchReferral({
                patient_id: patient_id,
            },page, sizePerPage)

            for (let i = 0; i < record.data.length; i++) {
                dataReferral[i] = record.data[i].medical_referrals
                for (let j = 0; j < dataReferral.length; j++) {
                    for (let m = 0; m < dataReferral[j].length; m++) {
                        dataReferral[j][m].professional =
                            record.data[j].professional
                        dataReferral[j][m].unit =  record.data[j].unit
                    }
                }
            }
            for (let i = 0; i < dataReferral.length; i++) {
                for (let j = 0; j < dataReferral[i].length; j++) {
                    dataFormatPrescription.push(dataReferral[i][j])
                }
            }

            setPrescriptionList({
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: dataFormatPrescription,
            })
        } catch (e) {
            console.log("error", e)
        }
    }

    useEffect(() => {
        search({ term: "" }, 1)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        search({ term: searchText }, page)
    }

    const RenderMedicalForm = async data => {
        let content = { ...data, entity:"MedicalReferral" }
        const professional = { ...data?.professional }
        const patient = { ...props?.patientData }
        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true

        RenderForm({
            showProfessionalSignature,
            ShowPatientData,
            showPrintButton,
            patient,
            professional,
            content,
        })
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Breadcrumbs
                                title="Encaminhamentos"
                                breadcrumbItem={"Lista de Encaminhamentos"}
                            />

                            <PaginationProvider
                                pagination={paginationFactory(prescriptionList)}
                            >
                                {({
                                    paginationProps,
                                    paginationTableProps,
                                }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={prescriptionList.data || []}
                                        columns={ReferralAttendanceColumns(
                                            RenderMedicalForm
                                        )}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2"></Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "table-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={
                                                                    handleTableChange
                                                                }
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default listPrescriptions
