import React from "react"
import { Modal } from "reactstrap"
import IndexModalLogs from "../ListLogs/index"

const ModalLogs = ({ show, data, onClose }) => {
    
    return (
        <Modal isOpen={show} centered={false} size="xl"  style={{maxWidth: '1600px', width: '94%'}}>
            <div className="modal-header">
                <div className="modal-title " id="myModalLabel">
                    <h5>
                        Logs de modificação{" "}
                        <i className="fas fa-file-medical-alt text-dark fa-2x"></i>
                    </h5>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <IndexModalLogs
                data={data}
                onClose={onClose}
              
              
            />
            <div className="card-body" align="right">
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="btn btn-dark waves-effect"
                    data-dismiss="modal"
                >
                    <span className="text-white"> Fechar</span>
                </button>
            </div>
        </Modal>
    )
}

export default ModalLogs