import { useState } from "react"
import { Button, Spinner } from "reactstrap"
import { sendContract } from "services/vitta-core/vitta-card-contract"
import { showSweetAlertDialog } from "store/actions"
import { CONTRACT_STATUS } from "../Contract"

function SendContractButton({
    channel,
    contract,
    dispatch,
    onSendContractSuccess,
    existActiveContract,
}) {
    const [state, setState] = useState({
        loading: false,
    })

    async function handle() {
        setState({
            ...state,
            loading: true,
        })

        try {
            await sendContract(contract.card_id, contract.id, channel)
            onSendContractSuccess(contract.id)
        } catch (e) {
            console.error(e.response.data)
            dispatch(
                showSweetAlertDialog({
                    title: "Opss..",
                    text: e?.response?.data?.error,
                    type: "error",
                    showConfirm: true,
                })
            )
        } finally {
            setState({
                ...state,
                loading: false,
            })
        }
    }

    async function confirm() {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma o envio deste contrato?",
                type: "warning",
                onConfirmAction: () => {
                    handle()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    let textButton = "Enviar contrato via " + channel.charAt(0).toUpperCase() + channel.slice(1);
    let icon = <i className="fas fa-envelope"></i>;
    switch(channel)
    {
        case "sms": 
            icon = <i className="fas fa-mobile"></i>;
            break;
        case "email":
            icon = <i className="fas fa-envelope"></i>;
            break;
    }

    if(channel != contract.channel_sign_request && contract.channel_sign_request != null) return <>{icon}{" "}<Button size="sm" disabled="disabled" color="link" style={{ fontSize: 11, color: "info" }} >{textButton}</Button></>;

    //if (contract.status !== CONTRACT_STATUS.CREATED || existActiveContract) return <>{icon}{" "}<Button size="sm" disabled="disabled" color="link" style={{ fontSize: 11, color: "info" }} >{textButton}</Button></>

    if (state.loading) return <Spinner size="sm" color="success" />

    return (
        <>
            {icon}{" "}
            <Button size="sm" color="link" style={{ fontSize: 11, color: "info" }} onClick={confirm}>
                {textButton}
            </Button>
        </>
    )
}

export default SendContractButton
