import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const DiscountListColumns = (user, confirmDeleteRecord, showDeleteDiscount) => [
    {
        dataField: "origin",
        text: "Origem",
        formatter: (cellContent, row) => (
            <>
                {row?.origin == "vitta_card"
                    ? "Cartão Vittá + Vantagens"
                    : row?.origin == "vittanet" ? "VittáNet" : "Usuário"} {row?.details ? " ("+row?.details+")": null}
            </>
        ),
    },
    {
        dataField: "value",
        text: "Valor(R$)",
        formatter: (cellContent, row) => (
            <div className="text-body">
                &ensp;
                {((row?.value * row?.quantity) / 100).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}
            </div>
        ),
    },

    {
        dataField: "user",
        text: "Usuário",
        formatter: (cellContent, row) => (
            <>
                {row?.origin == "vitta_card" || row?.origin == "vittanet"
                    ? ""
                    : row?.user
                    ? row?.user?.name
                    : !row?.id
                    ? user?.name
                    : ""}
            </>
        ),
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row, index) => (
            <>
                {row?.origin == "user" && showDeleteDiscount ? (
                    <Link
                        Link
                        to="#"
                        className="text-danger"
                        onClick={() => {
                            confirmDeleteRecord(row, index)
                        }}
                    >
                        <i
                            className="mdi mdi-trash-can-outline font-size-24 mr-3"
                            id={"deletetooltip" + index}
                        />
                        <UncontrolledTooltip
                            placement="top"
                            target={"deletetooltip" + index}
                            style={{ backgroundColor: "red", color: "white" }}
                        >
                            Remover desconto
                        </UncontrolledTooltip>
                    </Link>
                ) : (
                    ""
                )}
            </>
        ),
    },
]

export default DiscountListColumns
