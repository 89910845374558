import {
    Row,
    Col,
    Button,
    Input,
    CardTitle,
    Label,
    CardHeader,
    Collapse,
    FormGroup,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
} from "reactstrap"
import { showSweetAlertDialog } from "store/layout/actions"
import React, { useState } from "react"
import { FieldArray, FastField, Field } from "formik"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import { createEntity, deleteEntity } from "services/vitta-core/attendance"
import handleMedicalFormUpdate from "pages/Patients/Detail/pages/GeneralAttendance/Functions/handleMedicalFormUpdate"
import { useDispatch } from "react-redux"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import ModalPrescriptions from "./ModalModels/Components/Modal"
import ModalSaveModels from "./ModalModels/Components/ModalSaveModels"
import MEDICAL_FORMS_TYPES from "utils/consts/medical-form-types"
import { createModel } from "services/vitta-core/medical-forms-models"
import RenderForm from "pages/Patients/Detail/pages/GeneralAttendance/components/renderForm"
import AsyncSelect from "react-select/async"
import { search as searchSpecialties } from "services/vitta-core/specialty"
import debounce from "debounce-promise"
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalReferral",
                null,
                option
            )
        }}
        // onBlur={e => {
        //     field.onBlur(e)
        //     handleMedicalFormUpdate(
        //         props.attendanceId,
        //         props.stateSync,
        //         props.datasyncstate,
        //         props.entityId,
        //         field,
        //         props.lastFormState,
        //         "MedicalExams",
        //         null,
        //         props.itemExam
        //     )
        // }}
    />
)

const InputTextt = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => (
    <Input
        type="text"
        className="mb-3"
        {...props}
        {...field}
        onBlur={e => {
            field.onBlur(e)
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalReferral"
            )
        }}
    />
)

const InputEditor = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => (
    <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        className="mb-2"
        onEditorStateChange={option => {
            setFieldValue(field.name, option)
        }}
        toolbar={{
            options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "image",
                "remove",
                "history",
            ],
        }}
        id={"ad"}
        onBlur={e => {
            handleMedicalFormUpdate(
                props.attendanceId,
                props.stateSync,
                props.datasyncstate,
                props.entityId,
                field,
                props.lastFormState,
                "MedicalReferral"
            )
        }}
        editorState={props.editorState}
        readOnly={props.disabled}
    />
)

const lastFormState = {}

const MedicalReferral = ({
    items,
    defaultItem,
    datasyncstate,
    stateSync,
    attendance_id,
    patient_id,
    errors,
    touched,
    professional,
    patient,
    unit,
}) => {
    const [certificate_id, setCertificateId] = useState(null)
    const [tab, setTab] = useState(0)
    const [modalPrescriptionData, setModalPrescriptionData] = useState({
        show: false,
        entity_type: null,
        data: null,
    })
    const [modalSaveModels, setModalSaveModels] = useState({
        show: false,
        data: null,
    })

    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }
    const dispatch = useDispatch()
    const handleCreate = async data => {
        try {
            stateSync(0)

            let created = null

            let itemValue = {}

            itemValue.entity = "MedicalReferral"
            itemValue.attendance_id = attendance_id
            itemValue.title = ""
            itemValue.text_object = ""
            itemValue.specialty_id = null

            if (data) {
                created = await createEntity(data, attendance_id)
            } else {
                created = await createEntity(itemValue, attendance_id)
            }
            itemValue.id = created?.id
            datasyncstate(created?.form)
            stateSync(1)

            if (itemValue?.id) {
                return itemValue
            }
        } catch (e) {
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ocorreu algum problema ! ",
                    text:
                        "Houve um erro ao criar o certificado, tente novamente ou contate o suporte.",
                    type: "error",
                    showConfirm: true,
                })
            )

            stateSync(2)
        }
    }

    const handleDelete = async item => {
        stateSync(0)
        await deleteEntity(item?.id, "MedicalReferral", attendance_id)
        stateSync(1)
    }
    const handleBG = id => {
        let element = document.getElementById(id)
        element.classList.add("bg-info")
        element.classList.add("text-white")
    }
    const removeBG = id => {
        let element = document.getElementById(id)
        element.classList.remove("bg-info")
        element.classList.remove("text-white")
    }
    const viewForm = data => {
        console.log(data)
        let content = {
            ...data,
            unit: unit,
            created_at: items?.created_at,
            entity: "MedicalReferral",
        }
        const showProfessionalSignature = true
        const ShowPatientData = true
        const showPrintButton = true
        if (data?.text_object && data?.text_object != "") {
            content.text = draftToHtml(
                convertToRaw(data?.text_object.getCurrentContent())
            )
        }

        RenderForm({
            showProfessionalSignature,
            ShowPatientData,
            showPrintButton,
            patient,
            professional,
            content,
        })
    }
    const loadSpecialties = async inputValue => {
        return new Promise(async (resolve, reject) => {
            if (!inputValue) return resolve([])

            try {
                let response = await searchSpecialties(
                    { term: inputValue },
                    1,
                    20
                )
                let data_values = []

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsSpecialties = debounce(loadSpecialties, 600)
    return (
        <React.Fragment>
            <CardTitle>Encaminhamentos</CardTitle>

            <FieldArray
                name="itemsReferral"
                render={arrayHelpers => (
                    <div>
                        {items && items?.itemsReferral?.length > 0
                            ? items?.itemsReferral?.map((item, index) => (
                                  <Row key={index} className="mb-2 ">
                                      <Col sm="11" className="mt-3">
                                          <CardHeader
                                              style={{
                                                  cursor: "pointer",
                                                  height: "50px",
                                              }}
                                              className="shadow-lg bg-white"
                                              onClick={() => {
                                                  //   setTab(!index)

                                                  HandleTab(index)
                                              }}
                                          >
                                              <Row>
                                                  <Col sm="6" className="mt-1">
                                                      <Label>
                                                          #Encaminhamento -{" "}
                                                          {index ? index : 0}{" "}
                                                      </Label>
                                                  </Col>
                                                  <Col sm="6">
                                                      <div align="end">
                                                          {" "}
                                                          {tab == index ? (
                                                              <i className="fas fas fa-arrow-up fa-2x">
                                                                  {" "}
                                                              </i>
                                                          ) : (
                                                              <i className="fas fa-arrow-down fa-2x">
                                                                  {" "}
                                                              </i>
                                                          )}{" "}
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </CardHeader>
                                      </Col>
                                      <Col md="1" className="mt-4">
                                          {items.status &&
                                          items.status === 1 ? (
                                              <UncontrolledDropdown>
                                                  <DropdownToggle
                                                      className="card-drop"
                                                      tag="i"
                                                  >
                                                      <Button
                                                          type="button"
                                                          className="btn btn-info mt-1"
                                                          id={
                                                              "referraldanger" +
                                                              index
                                                          }
                                                      >
                                                          {" "}
                                                          <i className="bx bx-grid-alt fa-lg text-white" />
                                                      </Button>
                                                      <UncontrolledTooltip
                                                          placement="top"
                                                          target={
                                                              "referraldanger" +
                                                              index
                                                          }
                                                      >
                                                          Ações
                                                      </UncontrolledTooltip>
                                                  </DropdownToggle>
                                                  <DropdownMenu
                                                      right
                                                      className="mt-2"
                                                  >
                                                      <DropdownItem
                                                          id="rr4"
                                                          onMouseOver={() => {
                                                              handleBG("rr4")
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG("rr4")
                                                          }}
                                                          onClick={async () => {
                                                              try {
                                                                  setModalSaveModels(
                                                                      {
                                                                          show: true,
                                                                          data: item,
                                                                      }
                                                                  )
                                                              } catch (e) {
                                                                  console.log(e)
                                                              }
                                                          }}
                                                      >
                                                          <i className="fas fa-plus"></i>{" "}
                                                          Salvar Como Modelo
                                                          {/* <Button
                                                              id="iconedittooltip9349"
                                                              color="primary"
                                                              onClick={async () => {
                                                                  try {
                                                                      setModalSaveModels(
                                                                          {
                                                                              show: true,
                                                                              data: item,
                                                                          }
                                                                      )
                                                                  } catch (e) {
                                                                      console.log(
                                                                          e
                                                                      )
                                                                  }
                                                              }}
                                                          >
                                                              Salvar Como Modelo{" "}
                                                              <i className="fas fa-plus ml-1"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "iconedittooltip9349"
                                                                  }
                                                              >
                                                                  Clique para
                                                                  adicionar
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                      </DropdownItem>
                                                      <DropdownItem
                                                          id={"rr3" + index}
                                                          onMouseOver={() => {
                                                              handleBG(
                                                                  "rr3" + index
                                                              )
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG(
                                                                  "rr3" + index
                                                              )
                                                          }}
                                                          onClick={async () => {
                                                              await handleDelete(
                                                                  item
                                                              ),
                                                                  arrayHelpers.remove(
                                                                      index
                                                                  )
                                                          }}
                                                      >
                                                          <i className="fas fa-trash-alt"></i>{" "}
                                                          Excluir
                                                          {/* <Button
                                                              id={
                                                                  "trash5" +
                                                                  index
                                                              }
                                                              color="danger"
                                                              className="inner "
                                                              onClick={async () => {
                                                                  await handleDelete(
                                                                      item
                                                                  ),
                                                                      arrayHelpers.remove(
                                                                          index
                                                                      )
                                                              }}
                                                          >
                                                              Excluir{" "}
                                                              <i className="fas fa-trash-alt"></i>
                                                              <UncontrolledTooltip
                                                                  placement="left"
                                                                  target={
                                                                      "trash5" +
                                                                      index
                                                                  }
                                                              >
                                                                  Clique para
                                                                  excluir
                                                              </UncontrolledTooltip>
                                                          </Button> */}
                                                      </DropdownItem>
                                                      <DropdownItem
                                                          onClick={() => {
                                                              viewForm(item)
                                                          }}
                                                          id={"rr6" + index}
                                                          onMouseOver={() => {
                                                              handleBG(
                                                                  "rr6" + index
                                                              )
                                                          }}
                                                          onMouseLeave={() => {
                                                              removeBG(
                                                                  "rr6" + index
                                                              )
                                                          }}
                                                      >
                                                          <i className="fas fa-binoculars"></i>{" "}
                                                          Pré-visualizar
                                                      </DropdownItem>
                                                  </DropdownMenu>
                                              </UncontrolledDropdown>
                                          ) : (
                                              ""
                                          )}
                                      </Col>

                                      <Col sm="11" className="mt-2">
                                          <Collapse isOpen={tab == index}>
                                              <FormGroup>
                                                  <Label className="mt-2">
                                                      {" "}
                                                      <strong>
                                                          Especialidade
                                                      </strong>{" "}
                                                  </Label>
                                                  <Field
                                                      name={`itemsReferral[${index}].specialty_id`}
                                                      value={item?.specialty_id}
                                                      placeholder="Digite para buscar..."
                                                      component={
                                                          AsyncSelectInput
                                                      }
                                                      cacheOptions
                                                      defaultOptions
                                                      loadOptions={
                                                          debounceOptionsSpecialties
                                                      }
                                                      lastFormState={
                                                          lastFormState
                                                      }
                                                      attendanceId={
                                                          attendance_id
                                                      }
                                                      itemExam={item}
                                                      stateSync={stateSync}
                                                      datasyncstate={
                                                          datasyncstate
                                                      }
                                                      entityId={item?.id}
                                                      disabled={
                                                          items.status === 0
                                                              ? true
                                                              : false
                                                      }
                                                      style={
                                                          items.status === 0
                                                              ? {
                                                                    cursor:
                                                                        "not-allowed",
                                                                    background:
                                                                        "#f8f9fa",
                                                                }
                                                              : {
                                                                    cursor:
                                                                        "text",
                                                                }
                                                      }
                                                      noOptionsMessage={() =>
                                                          "Sem Resultados. Digite para Buscar"
                                                      }
                                                  />
                                              </FormGroup>
                                              <FormGroup>
                                                  <Label className="mt-2">
                                                      {" "}
                                                      <strong>
                                                          Título
                                                      </strong>{" "}
                                                  </Label>
                                                  <FastField
                                                      name={`itemsReferral[${index}].title`}
                                                      value={item?.title}
                                                      maxlength="100"
                                                      component={InputTextt}
                                                      lastFormState={
                                                          lastFormState
                                                      }
                                                      attendanceId={
                                                          attendance_id
                                                      }
                                                      stateSync={stateSync}
                                                      datasyncstate={
                                                          datasyncstate
                                                      }
                                                      entityId={item?.id}
                                                      disabled={
                                                          items.status === 0
                                                              ? true
                                                              : false
                                                      }
                                                      style={
                                                          items.status === 0
                                                              ? {
                                                                    cursor:
                                                                        "not-allowed",
                                                                    background:
                                                                        "#f8f9fa",
                                                                }
                                                              : {
                                                                    cursor:
                                                                        "text",
                                                                }
                                                      }
                                                  />
                                              </FormGroup>

                                              <FormGroup>
                                                  <Label>
                                                      {" "}
                                                      <strong>
                                                          Texto
                                                      </strong>{" "}
                                                  </Label>

                                                  <FastField
                                                      name={`itemsReferral[${index}].text_object`}
                                                      id={`itemsReferral[${index}].text_object`}
                                                      editorState={
                                                          item?.text_object
                                                      }
                                                      component={InputEditor}
                                                      lastFormState={
                                                          lastFormState
                                                      }
                                                      attendanceId={
                                                          attendance_id
                                                      }
                                                      stateSync={stateSync}
                                                      datasyncstate={
                                                          datasyncstate
                                                      }
                                                      entityId={item?.id}
                                                      disabled={
                                                          items.status === 0
                                                              ? true
                                                              : false
                                                      }
                                                  />
                                              </FormGroup>
                                          </Collapse>
                                      </Col>

                                      <Row></Row>
                                  </Row>
                              ))
                            : null}
                        {items.status && items.status === 1 ? (
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        {/* Visível somente em tamanhos de tela xs, sm */}
                                        <Button
                                            type="button"
                                            className="btn btn-info mt-2 mr-2"
                                            onClick={async () => {
                                                try {
                                                    let aux = {
                                                        prof: professional,
                                                        patient: patient,
                                                        unit: unit,
                                                    }
                                                    setModalPrescriptionData({
                                                        show: true,
                                                        entity_type:
                                                            MEDICAL_FORMS_TYPES.ENCAMINHAMENTO,
                                                        data: aux,
                                                    })
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-marker"></i>{" "}
                                            Modelo
                                        </Button>
                                        <Button
                                            type="button"
                                            className="btn btn-info mt-2"
                                            onClick={async () => {
                                                try {
                                                    let item = await handleCreate()
                                                    if (item) {
                                                        arrayHelpers.push(item)
                                                    }
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-plus"></i>{" "}
                                            Manual
                                        </Button>
                                        <ModalPrescriptions
                                            show={modalPrescriptionData?.show}
                                            entity_type={
                                                modalPrescriptionData?.entity_type
                                            }
                                            data={modalPrescriptionData?.data}
                                            onClose={response => {
                                                setModalPrescriptionData({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleModel={async data => {
                                                try {
                                                    let stateEditor = EditorState.createWithContent(
                                                        convertFromRaw(
                                                            JSON.parse(
                                                                data?.text_object
                                                            )
                                                        )
                                                    )

                                                    data.entity =
                                                        "MedicalReferral"
                                                    data.attendance_id = attendance_id
                                                    data.text_object = convertToRaw(
                                                        stateEditor.getCurrentContent()
                                                    )

                                                    let item = await handleCreate(
                                                        data
                                                    )
                                                    let itemModal = { ...item }
                                                    if (data && itemModal) {
                                                        itemModal.title =
                                                            data?.title
                                                        itemModal.text_object = stateEditor

                                                        arrayHelpers.push(
                                                            itemModal
                                                        )
                                                        setModalPrescriptionData(
                                                            {
                                                                show: false,
                                                                data: null,
                                                            }
                                                        )
                                                    }
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo selecionado! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Ocorreu algum problema ! ",
                                                            text:
                                                                "Houve um erro, tente novamente ou contate o suporte.",
                                                            type: "error",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                }
                                            }}
                                        ></ModalPrescriptions>
                                        <ModalSaveModels
                                            show={modalSaveModels.show}
                                            data={modalSaveModels.data}
                                            onClose={response => {
                                                setModalSaveModels({
                                                    show: false,
                                                    data: null,
                                                })
                                            }}
                                            handleSave={async (title, data) => {
                                                try {
                                                    if (
                                                        title == null ||
                                                        !title ||
                                                        title == ""
                                                    ) {
                                                        dispatch(
                                                            showSweetAlertDialog(
                                                                {
                                                                    title:
                                                                        "Modelo não salvo devido a ausência de título!",

                                                                    type:
                                                                        "error",
                                                                    showConfirm: true,
                                                                }
                                                            )
                                                        )
                                                        setModalSaveModels({
                                                            show: false,
                                                            data: null,
                                                        })
                                                        return
                                                    }
                                                    if (
                                                        !data?.text_object ==
                                                            null ||
                                                        !data?.text_object == ""
                                                    ) {
                                                        if (
                                                            (convertToRaw(
                                                                data.text_object.getCurrentContent()
                                                            ).blocks.length ===
                                                                1) &
                                                            (convertToRaw(
                                                                data.text_object.getCurrentContent()
                                                            ).blocks[0].text ===
                                                                "")
                                                        ) {
                                                            dispatch(
                                                                showSweetAlertDialog(
                                                                    {
                                                                        title:
                                                                            'Para criar um modelo, é necessário que os campos  "Título" e "Texto" sejam preenchidos!',
                                                                        type:
                                                                            "warning",
                                                                        showConfirm: true,
                                                                    }
                                                                )
                                                            )
                                                            return
                                                        }
                                                    } else {
                                                        dispatch(
                                                            showSweetAlertDialog(
                                                                {
                                                                    title:
                                                                        'Para criar um modelo, é necessário que o campo "Título" e "Texto" sejam preenchidos!',
                                                                    type:
                                                                        "warning",
                                                                    showConfirm: true,
                                                                }
                                                            )
                                                        )
                                                        return
                                                    }
                                                    const dataSave = {
                                                        title: title,
                                                        form_type:
                                                            MEDICAL_FORMS_TYPES.ENCAMINHAMENTO,
                                                        text: draftToHtml(
                                                            convertToRaw(
                                                                data?.text_object.getCurrentContent()
                                                            )
                                                        ),
                                                        text_object: convertToRaw(
                                                            data?.text_object.getCurrentContent()
                                                        ),
                                                    }
                                                    await createModel(dataSave)

                                                    setModalSaveModels({
                                                        show: false,
                                                        data: null,
                                                    })

                                                    dispatch(
                                                        showSweetAlertDialog({
                                                            title:
                                                                "Modelo adicionado com sucesso! ",
                                                            type: "success",
                                                            showConfirm: true,
                                                        })
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        ></ModalSaveModels>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            />
        </React.Fragment>
    )
}

export default MedicalReferral
