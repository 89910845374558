import React from "react";
import { Modal, Row, Col, CardBody } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const LoadingModalProcess = ({ show, data, onClose }) => {
  return (
    <Modal
      isOpen={show}
      centered={true}
      size="md"
      backdrop="static" // Desabilita fechamento ao clicar fora do modal
      keyboard={false} // Desabilita fechamento ao pressionar ESC
    >
      <CardBody className="text-center">
        <Row className="justify-content-center text-dark" style={{ height: 150 }}>
          <Col xs={12} style={{ textAlign: "center" }}>

            <i
              className="fas fa-exclamation-triangle"
              style={{ fontSize: "3rem", color: "#FF5722", marginBottom: "10px" }}
            ></i>
            <h4 style={{ fontWeight: "bold", fontSize: "1.3rem", color: "#FF5722" }}>
              Aguarde, Notas de repasse em processo de Emissão!
            </h4>
          </Col>

          <div className="spinner-border text-primary align-items-center" style={{ width: "3rem", height: "3rem" }} role="status">
            <span className="sr-only">Carregando...</span>
          </div>
        </Row>
      </CardBody>
    </Modal>
  );
};

export default LoadingModalProcess;
