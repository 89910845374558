import React, { useState } from "react"
import { Link } from "react-router-dom"
import ASSESSMENTS_STATUS from "utils/consts/assessments-status"
import { Badge, UncontrolledTooltip } from "reactstrap"

const RequestColumns = () => [
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "professional.name",
        text: "Avaliador",
    },
    {
        dataField: "patient.name",
        text: "Paciente",
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) =>
            row.created_at
                ? new Date(row.created_at).toLocaleDateString("pt-BR", {
                      hour: "2-digit",
                      hour12: false,
                      minute: "2-digit",
                      second: "2-digit",
                  })
                : null,
    },
    // {
    //     dataField: "user.name",
    //     text: "Usuário",
    // },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            let text = ""
            let badgeClass = "badge-soft-success"
            switch (cellContent) {
                case ASSESSMENTS_STATUS.UNDER_EVALUATION:
                    text = "Em Avaliação"
                    badgeClass = "badge-soft-warning"
                    break
                case ASSESSMENTS_STATUS.IN_NEGOCIATION:
                    text = "Em Negociação"
                    badgeClass = "badge-soft-info"
                    break
                case ASSESSMENTS_STATUS.CONCLUDED:
                    text = "Negociado"
                    badgeClass = "badge-soft-success"
                    break
            }

            return (
                <Badge className={"font-size-12 " + badgeClass} pill>
                    {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Link
                    to={{
                        pathname: "/avaliacoes/" + row.id,
                    }}
                    className=" text-primary"
                >
                    <i
                        style={{ position: "relative", left: "8px" }}
                        className="fas fa-eye font-size-22"
                        id={"openordertooltip" + row.id}
                    ></i>
                    <UncontrolledTooltip
                        placement="top"
                        target={"openordertooltip" + row.id}
                    >
                        Abrir
                    </UncontrolledTooltip>
                </Link>
            </>
        ),
    },
]

export default RequestColumns
