import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Container,Row,Col,Card,CardBody,CardTitle } from "reactstrap"
import Breadcrumbs  from "../../components/Common/Breadcrumb"


const Help = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ajuda" breadcrumbItem="Ajuda" />
            <Row xs="12">
                <Col>
                    <Card>
                    <CardBody>
                    <Row className="justify-content-center">
                     
                      </Row>
                      
                      <Row>
                       
                        
                        Abaixo você encontra links para acessar os vídeos de treinamento. Coloquem a qualidade em 1080p HD.
                        <br/>
                        <br/>

                      </Row>

                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1p0Q5m2RGLqd1Fc49-H2gnNYQAC26qkHn/view?usp=sharing" target="_blank">Alterar Senha de Acesso</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1wYiDqF5UeVP1EExFbTuei5R8hKFwd303/view?usp=sharing" target="_blank">Cadastro de Pacientes</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1fNDm27NVnPxIYa7TV0QRhmqhmdBdb0s6/view?usp=sharing" target="_blank">Orçamentos</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1cxrrPPO5kbgaTAw8q5Eg7UGAS5qE7d3A/view?usp=sharing" target="_blank">Pedidos de Venda</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1KfGEwRTUxGNpZ1pDzxvQeoIxgeh10c9W/view?usp=sharing" target="_blank">Recebimento de Pedidos de Venda</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1ZiKXi3FrPbVQNZ0uzFovlIJntbj7nZeH/view?usp=sharing" target="_blank">Emissão NFe</a>
                      </Row>
                      <br/>
                      <Row>
                        <a href="https://drive.google.com/file/d/1h7B_QTq_GHiUZ9wYtcwav7Kpo_DCMeM5/view?usp=sharing" target="_blank">Repasse Médico</a>
                      </Row>
                      <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1kuqr4vjAOOWHgfCQ6T93DkWLg_rzSbOo/view?usp=sharing" target="_blank">Status Fiscal - Notas de Repasse</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1z44yFjvtrMeCM28C28hPjpZVKIcRsF2n/view?usp=sharing" target="_blank">Configuração de Agendas</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1uluH1lRewWprPZaB9p8vnpZkv7KAAlgI/view?usp=sharing" target="_blank">Cópia de Configurações de Agenda</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1SW-daotHXUcuejZekD9Qsa4R3_f5J_4o/view?usp=share_link" target="_blank">Cópia de Serviços de Outra Agenda</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1K-GFFxUAF5apzArQBnDa2dZ1PbdW4Mih/view?usp=sharing" target="_blank">Bloqueio de Agendas</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1FDHbXKkM0t5UP0c3iTGoNmYtOGdbI526/view?usp=sharing" target="_blank">Agendamentos</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1aaRGz2dOgQRmAWYFLikd__hQn2FVhq8l/view?usp=sharing" target="_blank">Atendimento Médico</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1SLl_lUvWxk9IYFs8VIY0j1nVxKtcApWz/view?usp=share_link" target="_blank">Gestão Cartão Vittá + Vantagens</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1z0YGfU5q97UsqvPfZ0y6UexG7WDPbD3d/view?usp=sharing" target="_blank">Pedidos de Exames Laboratoriais</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1KhfumnHS50sTstXtizxBG2wUyQyZY0iI/view?usp=sharing" target="_blank">Solicitação Médica de Exames e Procedimentos</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href=" https://storage.googleapis.com/vtnt-public/vittanet-help-videos/Adicionar%20Resultados%20de%20Exames%20Manualmente.mp4" target="_blank">Adicionar Resultados de Pedidos de Exames Manual</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1iuuTov0MupwGzEqu4idjLqX9CbF809ni/view?usp=sharing" target="_blank">Prescrição Digital Memed - Início</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1kiB0GcK0CEshJYmQZm064sORTo06ujzH/view?usp=sharing" target="_blank">Prescrição Digital Memed - Excluir, imprimir A5 e Reemitir</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/19oykl5esiauHhGjOEGGvAzH1sI5bjSWv/view?usp=sharing" target="_blank">Prescrição Digital Memed - Alergia, Protocolo e Fórmula</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1W_20R9QiB17QcI5pyHFxxxGBjy9nSzCM/view?usp=sharing" target="_blank">Prescrição Digital Memed - Editar Prescrição</a>
                        </Row>
                        <br/>
                        <Row>
                          <a href="https://drive.google.com/file/d/1ipddAz9iW8VW_hIo5eXjM3mw8BQCWBpb/view?usp=sharing" target="_blank">Prescrição Digital Memed - Assinatura Digital</a>
                        </Row>
                        <br/>
                           
                      </CardBody>

                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Help
