import React from "react"

import SweetAlert from "react-bootstrap-sweetalert"
import { hideSweetAlertDialog } from "../../store/layout/actions"
import { useDispatch, useSelector } from 'react-redux';

const CustomSweetAlert = props => {

  const dispatch = useDispatch();

  const ToggleSweetAlert = useSelector(state => state.Layout.sweetAlertDialog)

  if(!ToggleSweetAlert.show){
    return (<></>)
  }

  return (
    <SweetAlert
      title={ToggleSweetAlert.title}
      onCancel={() => {
          dispatch(hideSweetAlertDialog())
          ToggleSweetAlert.onCancel()
      }}
      onConfirm={() => {
          dispatch(hideSweetAlertDialog())
          ToggleSweetAlert.onConfirmAction()
      }}
      warning={ToggleSweetAlert.type == "warning"}
      success={ToggleSweetAlert.type == "success"}
      error={ToggleSweetAlert.type == "error"}
      info={ToggleSweetAlert.type == "info"}
      showCancel={ToggleSweetAlert.showCancel}
      showConfirm={ToggleSweetAlert.showConfirm}
      confirmBtnText={ToggleSweetAlert.confirmBtnText}
      cancelBtnText={ToggleSweetAlert.cancelBtnText}
      cancelBtnBsStyle={"danger"}
      closeOnClickOutside={ToggleSweetAlert.closeOnClickOutside}
    >
      {ToggleSweetAlert.text ? ToggleSweetAlert.text : null}
    </SweetAlert>
  )
}

export default CustomSweetAlert
