import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "store/layout/actions";

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Input,
    Label,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "components/Common/Breadcrumb";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { search as searchProfessionals } from "services/vitta-core/professional";
import useCashControl from "../../../services/vitta-core/cash-control";

/**
 * Componente para criação de uma nova movimentação financeira.
 */
const NewFinancialMovement = () => {
    const { createCashMove, findLastCashControlByUserId, findFinancialAccounts } = useCashControl();
    const history = useHistory();
    const Scope = useSelector(state => state.scope);
    const dispatch = useDispatch();
    
    const [cashControlId, setCashControlId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        typeMovement: null,
        process: null,
        account: null,
        professional: null,
        type: null,
        amount: 0,
        description: "",
        receipt: "",
    });

    const processOptionsEntry = [
        { value: 7, label: "Máquina de Café" },
    ];
        
    const processOptionsExit = [
        { value: 4, label: "Depósito Bancário" },
        { value: 5, label: "Subsídio Médico" },
        { value: 11, label: "Gastos Diversos" },
    ];

    const bankAccountOptions = { value: 2, label: "Banco" }; 

    useEffect(() => {
        const fetchCashControlId = async () => {
            try {
                const lastCashControl = await findLastCashControlByUserId();
                if (lastCashControl) {
                    setCashControlId(lastCashControl.data.id);
                } else {
                    alert("Nenhum caixa aberto encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar o ID do caixa ativo:", error);
            }
        };

        fetchCashControlId();
    }, []);

    /**
     * Carrega as opções de profissionais com base na pesquisa do usuário.
     * @param {string} inputValue - Texto digitado pelo usuário para a busca.
     * @returns {Promise<Array>} Lista de opções de profissionais.
     */    
    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([]);
                const statusActive = 1;
                let response = await searchProfessionals(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                );
                let data_values = [
                    {
                        label: "Todos Profissionais",
                        value: null,
                    },
                ];

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    });
                });

                return resolve(data_values);
            } catch (e) {
                console.error(e);
                return reject([]);
            }
        });
    };

    /**
     * Carrega as opções de contas financeiras com base na pesquisa do usuário.
     * @param {string} inputValue - Texto digitado pelo usuário para a busca.
     * @returns {Promise<Array>} Lista de opções de contas financeiras.
     */
    const loadFinancialAccountsOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([]);
                let response = await findFinancialAccounts({ term: inputValue }, 1, 20);

                let data_values = response.map(account => ({
                    label: `${account.name} (${account.id})`,
                    value: account.id,
                }));

                return resolve(data_values);
            } catch (e) {
                console.error(e);
                return reject([]);
            }
        });
    };

    const debounceOptionsProfessional = debounce(loadProfessionalInputOptions, 600);
    const debounceOptionsFinancialAccounts = debounce(loadFinancialAccountsOptions, 600);

    /**
     * Atualiza o estado do formulário com os valores selecionados.
     * @param {Object} selectedOption - Opção selecionada no campo.
     * @param {string} field - Campo que foi atualizado.
     */
    const handleChange = (selectedOption, field) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: selectedOption,
        }));

        // Limpar o campo 'process' quando 'typeMovement' mudar
        if (field === "typeMovement") {
            setFormData((prevState) => ({
                ...prevState,
                process: null, // Limpar o campo processo
            }));
        }

       
        if (field === "process" && selectedOption?.value === 4) {
            setFormData((prevState) => ({
                ...prevState,
                account: bankAccountOptions, 
            }));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /**
     * Atualiza o estado do formulário com o arquivo de comprovante selecionado.
     * @param {Object} e - Evento de mudança no campo de arquivo.
     */
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({
            ...prevState,
            receipt: file,
        }));
    };

    /**
     * Cria uma nova movimentação de caixa (cash_move).
     */
    const createNewCashMove = async () => {
        if (!cashControlId) {
            dispatch(showToast({ title: "Erro", text: "Não é possível criar um movimento financeiro sem um caixa ativo.", type: "error" }));
            return;
        }

        setLoading(true); // Inicia o estado de carregamento

        const formDataPayload = {
            unit_id: Scope?.unit.id,
            cash_control_id: cashControlId,
            type: formData.typeMovement?.value,
            amount: Number(formData.value),
            process_id: Number(formData.process?.value),
            description: formData.description,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        };

        if (formData.receipt) {
            formDataPayload.receipt = formData.receipt;
        }
        if (formData.professional) {
            formDataPayload.professional_id = formData.professional.value;
        }
        if (formData.account) {
            formDataPayload.financial_account_id = formData.account.value;
        }

        try {
            await createCashMove(formDataPayload);
            dispatch(showToast({ title: "Sucesso", text: "Movimentação financeira criada com sucesso", type: "success" }));
            history.push("/controle-caixa");
        } catch (error) {
            dispatch(showToast({ title: "Erro", text: "Erro ao criar movimentação financeira.", type: "error" }));
            console.error(error);
        } finally {
            setLoading(false); // Finaliza o estado de carregamento
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Controle de Caixa" breadcrumbItem="Adicionar Movimentação" />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <Card className="p-4">
                            <Row>
                                <Col md="6">
                                    <Label>Tipo Movimento</Label>
                                    <Select
                                        options={[{ value: "I", label: "Entrada" }, { value: "O", label: "Saída" }]}
                                        onChange={(option) => handleChange(option, "typeMovement")}
                                    />
                                </Col>
                                <Col md="6">
                                    <Label>Operação</Label>
                                    <Select
                                        options={formData.typeMovement?.value === "I" ? processOptionsEntry : processOptionsExit}
                                        onChange={(option) => handleChange(option, "process")}
                                        isDisabled={!formData.typeMovement}
                                        value={formData.process}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="6">
                                    <Label>Valor Operação</Label>
                                    <Input
                                        type="text"
                                        name="value"
                                        value={formData.value ? `R$ ${(formData.value / 100)?.toFixed(2)}` : ''}
                                        onChange={(e) => {
                                            const numericValue = Number(e.target.value.replace(/\D/g, ''));
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                value: numericValue
                                            }));
                                        }}
                                        placeholder="Digite o valor da operação"
                                    />
                                </Col>
                                <Col md="6">
                                    {formData.process?.value === 4 && ( // Depósito Bancário
                                        <>
                                            <Label>Conta Bancária</Label>
                                            <Select
                                                value={bankAccountOptions} 
                                                isDisabled 
                                            />
                                        </>
                                    )}
                                    {formData.process?.value === 5 && ( // Subsídio Médico
                                        <>
                                            <Label>Profissional</Label>
                                            <AsyncSelect
                                                name="profissional_id"
                                                placeholder="Selecione o profissional"
                                                classNamePrefix="select2-selection"
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={debounceOptionsProfessional}
                                                noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
                                                onChange={(option) => handleChange(option, "professional")}
                                            />
                                        </>
                                    )}
                                    {formData.process?.value === 7 && (
                                        <>
                                            <Label>Máquina de Café</Label>
                                            <Input
                                                type="text"
                                                value="Máquina de Café"
                                                readOnly
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="12">
                                    <Label>Descrição</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        placeholder="Digite a descrição"
                                    />
                                </Col>
                            </Row>

                            {formData.typeMovement?.value === "O" && (
                                <Row className="mt-3">
                                    <Col md="12">
                                        <Label>Comprovante</Label>
                                        <Input
                                            type="file"
                                            name="receipt"
                                            onChange={handleFileChange}
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Row className="mt-4">
                                <Col className="d-flex justify-content-end gap-2">
                                    <Link to="/controle-caixa">
                                        <Button color="danger">Fechar</Button>
                                    </Link>
                                    <Button 
                                        className="ml-2" 
                                        color="primary" 
                                        onClick={createNewCashMove}
                                        disabled={loading}
                                    >
                                        {loading ? "Salvando..." : "Salvar"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NewFinancialMovement;
