import { SET_CASH_CONTROL, RESET_CASH_CONTROL } from "./actionTypes";

const INIT_STATE = {
  cashControl: null,
};

const CashControl = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CASH_CONTROL:
      console.log("SET_CASH_CONTROL", action.payload);
      return {
        ...state,
        cashControl: action.payload,
      };

    case RESET_CASH_CONTROL:
      return {
        ...state,
        cashControl: null,
      };

    default:
      return state;
  }
};

export default CashControl;
