import request from './config/request';

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/permissions` + 
        query,
        vtAuth: true
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/permissions/${id}/edit`,
        vtAuth: true,
    })
}

const update = async (id, permission_data) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/permissions/${id}`,
        data: permission_data,
        vtAuth: true
    })
}

const create = async permission_data => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/permissions`,
        data: permission_data,
        vtAuth: true
    })
}

export {
    search, loadDataEditform, update, create
    
}
