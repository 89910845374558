import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "store/layout/actions";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Input,
    Label,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import useCashControl from "../../../services/vitta-core/cash-control";

/**
 * Componente para visualizar os detalhes de uma movimentação de caixa (Cash Move).
 * Permite visualizar detalhes e realizar upload e download de comprovantes.
 */
const CashMoveDetails = () => {
    const { findCashMoveById, uploadReceipt, downloadReceipt } = useCashControl(); 
    const history = useHistory();
    const { id } = useParams(); // Pega o ID da URL
    const dispatch = useDispatch();

    // Estado inicial para armazenar os dados da movimentação
    const [formData, setFormData] = useState({
        id: "",
        unit_id: "",
        type: "",
        amount: "",
        process_id: "",
        description: "",
        receipt_file_path: "",
        professional_id: "",
        financial_account_id: "",
        created_user_id: "",
        created_at: "",
        updated_at: ""
    });

    const [receiptFile, setReceiptFile] = useState(null);

    // Mapeamento de IDs de processo para suas descrições
    const processOriginMap = {
        1: "Abertura de Caixa",
        2: "Fechamento de Caixa",
        3: "Recebimento de Cliente",
        4: "Depósito Bancário",
        5: "Subsídio Médico",
        6: "Transferencia entre Caixas",
        7: "Máquina de Café",
        8: "Cancelamento de Pagamento do Cliente",
        9: "Pagamento de Repasse",
        10: "Cancelamento Pagamento de Repasse",
        11: "Gastos Diversos",
    };

    /**
     * Carrega os dados de uma movimentação de caixa pelo ID.
     * @param {number} cashMoveId - O ID da movimentação de caixa a ser carregada.
     */
    const loadCashMoveData = async (cashMoveId) => {
        try {
            const cashMoveData = await findCashMoveById(cashMoveId);
            setFormData({
                id: cashMoveData?.id || "",
                unit_id: cashMoveData?.unit.name || "",
                type: cashMoveData?.type === "I" ? "Entrada" : "Saída" || "",
                amount:  `R$ ${(cashMoveData?.amount /100)?.toFixed(2)}` || "",
                process_id: cashMoveData?.process_id ? processOriginMap[cashMoveData?.process_id] : "",
                description: cashMoveData?.description || "",
                receipt_file_path: cashMoveData?.receipt_file_path || "",
                professional_id: cashMoveData?.professional?.name || null,
                financial_account_id: cashMoveData?.financial_account?.name || null,
                created_user_id: cashMoveData?.created_user?.name || null,
                created_at: cashMoveData?.created_at ? new Date(cashMoveData.created_at).toLocaleString() : "",
                updated_at: cashMoveData?.updated_at ? new Date(cashMoveData.updated_at).toLocaleString() : ""
            });
        } catch (error) {
            console.error("Erro ao carregar os dados do cash move:", error);
        }
    };

    /**
     * Função para salvar o comprovante de upload.
     */
    const saveReceipt = async () => {
        if (!receiptFile) {
            return dispatch(showToast({ title: "Atenção", text: "Por favor, selecione um arquivo para fazer upload.", type: "warning" }));
        }

        const formDataPayload = new FormData();
        formDataPayload.append("receipt_file_path", receiptFile);

        try {
            await uploadReceipt(id, formDataPayload); 
            dispatch(showToast({ title: "Sucesso", text: "Comprovante enviado com sucesso!", type: "success" }));
            history.push("/controle-caixa");
        } catch (error) {
            console.error("Erro ao fazer upload do comprovante:", error);
            dispatch(showToast({ title: "Erro", text: "Erro ao enviar o comprovante.", type: "error" }));
        }
    };

    /**
     * Função para download do comprovante.
     */
    const handleDownloadReceipt = async () => {
        try {
            const downloadUrl = await downloadReceipt(id);
            window.location.href = downloadUrl;
        } catch (error) {
            console.error("Erro ao baixar o comprovante:", error);
        }
    };

    useEffect(() => {
        if (id) {
            loadCashMoveData(id); // Carrega os dados da movimentação
        }
    }, [id]);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Controle de Caixa" breadcrumbItem="Visualizar Movimentação" />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <Card className="p-4">
                            {/* Exibe os detalhes da movimentação de caixa */}
                            <Row>
                                <Col md="6">
                                    <Label>ID</Label>
                                    <Input type="text" value={formData.id} readOnly disabled />
                                </Col>
                                <Col md="6">
                                    <Label>Unidade</Label>
                                    <Input type="text" value={formData.unit_id} readOnly disabled />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="6">
                                    <Label>Tipo de Movimento</Label>
                                    <Input type="text" value={formData.type} readOnly disabled />
                                </Col>
                                <Col md="6">
                                    <Label>Valor</Label>
                                    <Input type="text" value={formData.amount} readOnly disabled />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="6">
                                    <Label>Processo</Label>
                                    <Input type="text" value={formData.process_id} readOnly disabled />
                                </Col>

                                {/* Exibe o campo apropriado (user, professional ou financial account) */}
                                <Col md="6">
                                    {formData.created_user_id && (
                                        <>
                                            <Label>Usuário Criador</Label>
                                            <Input type="text" value={formData.created_user_id} readOnly disabled />
                                        </>
                                    )}
                                    {formData.professional_id && (
                                        <>
                                            <Label>Profissional</Label>
                                            <Input type="text" value={formData.professional_id} readOnly disabled />
                                        </>
                                    )}
                                    {formData.financial_account_id && (
                                        <>
                                            <Label>Conta Financeira</Label>
                                            <Input type="text" value={formData.financial_account_id} readOnly disabled />
                                        </>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="6">
                                    <Label>Criado em</Label>
                                    <Input type="text" value={formData.created_at} readOnly disabled />
                                </Col>
                                <Col md="6">
                                    <Label>Atualizado em</Label>
                                    <Input type="text" value={formData.updated_at} readOnly disabled />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="12">
                                    <Label>Descrição</Label>
                                    <Input type="textarea" value={formData.description} readOnly disabled />
                                </Col>
                            </Row>

                            {/* Opções para upload ou download de comprovante, exceto para Abertura/Fechamento de Caixa */}
                            <Row className="mt-4">
                                <Col md="4" className="d-flex align-items-center">
                                    {formData.process_id !== "Abertura de Caixa" &&
                                    formData.process_id !== "Fechamento de Caixa" &&
                                    formData.process_id !== "Transferencia entre Caixas" && (
                                        formData.receipt_file_path ? (
                                            <Button color="primary" onClick={handleDownloadReceipt}>
                                                <i className="fas fa-download"></i> Baixar Comprovante
                                            </Button>
                                        ) : (
                                            <>
                                                <Input
                                                    type="file"
                                                    onChange={(e) => setReceiptFile(e.target.files[0])}
                                                />
                                                <Button className="ml-2" color="primary" onClick={saveReceipt}>
                                                    <i className="fas fa-upload"></i> Enviar Comprovante
                                                </Button>
                                            </>
                                        )
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col className="d-flex justify-content-end gap-2">
                                    <Link to="/controle-caixa">
                                        <Button color="danger" style={{ width: '100%' }}>Voltar</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CashMoveDetails;
