import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import { search as ProfessionalServiceSearch } from "services/vitta-core/professional"
import { search as PatientServiceSearch } from "services/vitta-core/patient"
import AsyncSelect from "react-select/async"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch} from "react-redux"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import { useHistory } from "react-router-dom"
import debounce from "debounce-promise"
import { attendancesReportExport } from "services/vitta-core/attendance"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const MedicalAssistence = () => {
    const history = useHistory()
    const Scope = useSelector(state => state.scope)
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })
    const dispatch = useDispatch()
    const [controlState, setControlState] = useState(false)
    const userAuth = useSelector(state => state.auth.access?.user)

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await ProfessionalServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                users_values.push({ label: "Sem Filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )
    const loadPatientsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await PatientServiceSearch(
                    { term: inputValue,fields:"id,name" },
                    1,
                    25
                )

                let users_values = []
                users_values.push({ label: "Sem filtro", value: "" })
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientsInputOptions, 600)
    const onSubmit = async (data_form, actions) => {
        try
        {
            let auxData = {}
            auxData.patient = data_form.patient_id?.value
                ? {
                      id: data_form.patient_id.value,
                      name: data_form.patient_id.label,
                  }
                : null
            auxData.professional = data_form.professional_id?.value
                ? {
                      id: data_form.professional_id.value,
                      name: data_form.professional_id.label,
                  }
                : null
            let unit_ids = [];
            if(data_form.unit_id?.length>0){
                for(let i = 0; i < data_form.unit_id.length; i++)
                {
                    unit_ids.push(data_form.unit_id[i].value);
                }
            }
            else unit_ids.push(data_form.unit_id?.value)
            
            auxData.unit_id = unit_ids.join(",")
            auxData.start_created_at = data_form.start_created_at
            auxData.end_created_at = data_form.end_created_at
    
            if (data_form.format?.value == "excel") {
                setControlState(true)
                let response = await attendancesReportExport({
                    professional_id: auxData?.professional?.id,
                    unit_id: auxData?.unit_id,
                    patient_id: auxData?.patient?.id,
                    start_created_at: auxData.start_created_at,
                    end_created_at: auxData.end_created_at,
                })
    
                var headers = response.headers
                var blob = new Blob([response.data], {
                    type: headers["content-type"],
                })
                var link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = "Atendimentos"
                link.click()
                setControlState(false)
                return
            }
    
            setReportControl({
                show: true,
                filter: auxData,
            })
        }
        catch(e)
        {
            console.log(e)
            setControlState(false)
            if (e?.response?.status ==422) {
             
                 
                        dispatch(
                            showSweetAlertDialog({
                                title: "Filtro de data inválido!",
                                text:
                                    "É possível filtrar no máximo 6 meses de diferença entre datas sem paciente e 5 anos com paciente específico, verifique os filtros de data e tente novamente.",
                                type: "error",
                                showConfirm: true
                            
                                
                            })
                        )
                        return
                }
            
        }
      
    }

    if (reportControl.show) {
        let query =
            "?unit_id=" +
            reportControl?.filter?.unit_id +
            "&start_created_at=" +
            reportControl?.filter?.start_created_at +
            "&end_created_at=" +
            reportControl?.filter?.end_created_at
        if (reportControl?.filter?.patient?.id)
            query += "&patient_id=" + reportControl?.filter?.patient?.id
        if (reportControl?.filter?.professional?.id)
            query +=
                "&professional_id=" + reportControl?.filter?.professional?.id

        history.push("/relatorios/atend-medicos/view" + query)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Pagamentos Recebidos/Relatórios" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Atendimentos Médicos{" "}
                                                <i className="fas fa-laptop-medical fa-lg ml-2"></i>
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    patient_id: {
                                                        value: null,
                                                        label: "Sem Filtro",
                                                    },
                                                    professional_id: {
                                                        value: null,
                                                        label: "Sem Filtro",
                                                    },
                                                    unit_id: {
                                                        label: Scope.unit.name?.substr(
                                                            0,
                                                            40
                                                        ),
                                                        value: Scope.unit.id,
                                                    },
                                                    start_created_at: todayOnlyDate,
                                                    end_created_at: todayOnlyDate,
                                                    format: {
                                                        label: "Visualização",
                                                        value: "view",
                                                    },
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form
                                                        method="POST"
                                                        action="/pagamentos-recebidos/relatorio"
                                                    >
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-1"
                                                            >
                                                                <Label>
                                                                    Profissional
                                                                </Label>
                                                                <Field
                                                                    name="professional_id"
                                                                    placeholder="Selecionar Profissional"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.professional_id
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsProfessional
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Paciente
                                                                </Label>
                                                                <Field
                                                                    name="patient_id"
                                                                    placeholder="Selecionar Paciente"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    defaultValue={
                                                                        formProps.patient
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                    noOptionsMessage={() =>
                                                                        "Sem Resultados. Digite para Buscar"
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <Label>
                                                                    Unidade
                                                                </Label>
                                                                <Field
                                                                    name={`unit_id`}
                                                                    placeholder="Selecione a Unidade"
                                                                    classNamePrefix="select2-selection"
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data de
                                                                    atendimento
                                                                    inicial{" "}
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "start_created_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            .start_created_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    {" "}
                                                                    Data de
                                                                    atendimento
                                                                    final
                                                                </Label>
                                                                <Input
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    name={
                                                                        "end_created_at"
                                                                    }
                                                                    defaultValue={
                                                                        formProps
                                                                            ?.initialValues
                                                                            ?.end_created_at
                                                                    }
                                                                    type="date"
                                                                ></Input>
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center mt-2">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Formato
                                                                        de Saída
                                                                    </Label>
                                                                    <Field
                                                                        name="format"
                                                                        placeholder={
                                                                            "Selecione"
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label:
                                                                                    "Visualização",
                                                                                value:
                                                                                    "view",
                                                                            },
                                                                            {
                                                                                label:
                                                                                    "Excel",
                                                                                value:
                                                                                    "excel",
                                                                            },
                                                                        ]}
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                        isMulti={
                                                                            false
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="3"
                                                                align="start"
                                                                className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <div
                                                                        className="spinner-border text-dark"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Loading...
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                sm="5"
                                                                align="end"
                                                                className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        disabled
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MedicalAssistence
