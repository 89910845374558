import React, { useState } from "react"
import { Link } from "react-router-dom"
import ITEM_EXEC_COMISSION_TYPES from "utils/consts/item-exec-comission-types"

const styles = {
    itemLine: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2px',
      color: '#4a4a4a',
    },
    itemDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '5px',
      marginLeft: '',
      position:'relative',
      top:'-1px'
    },
    stage: {
      color: '#6c757d',
      fontWeight: 'bold',
      fontSize: '12px',
      marginLeft:'5px'

    },
    tagExecution: {
      backgroundColor: '#0894d4',
      color: '#fff',
      borderRadius: '3px',
      padding: '0px 4px',
      fontSize: '9px',
      textAlign:'center'
    },
    tagIndication: {
      backgroundColor: '#28a745',
      color: '#fff',
      borderRadius: '3px',
      padding: '0px 4px',
      fontSize: '9px',
      textAlign:'center'
    },
  };
  
  const calcValue = sales_order_item_comission => {
    let value = 0;
    return ((sales_order_item_comission?.comission_calc_value) / 100) * sales_order_item_comission?.quantity
    if (sales_order_item_comission && sales_order_item_comission.length > 0) {
        for (let i = 0; i < sales_order_item_comission.length; i++) {
            let use = sales_order_item_comission[i];
            
      
            let comissionValue = Number(use?.comission_calc_value) || 0;
            let quantity = Number(use?.quantity) || 1; 

            if (comissionValue > 0) {
                value += (comissionValue / 100) * quantity;
            }
        }
    }

    return value;
}
const getPrice = sales_order_item_comission => {
    let price = 0

    return (sales_order_item_comission?.comission_calc_value / 100) * sales_order_item_comission?.quantity
    // if (sales_order_item_comission && sales_order_item_comission.length > 0) {
    //     for (let i = 0; i < sales_order_item_comission.length; i++) {
    //         let use = sales_order_item_comission[i]
    //         if (
    //             use &&
    //             use.comission_type
    //         ) {
    //             price += (use.comission_calc_value / 100) * use?.quantity;
    //         }
    //     }
    // }

    return price
}
const RequestColumns = () => {
    return [
        {
            dataField: "exec_in",
            text: "Data Execução",
            formatter: (cellContent, row) =>
                new Date(row.mark_exec_at + " 00:00:00").toLocaleDateString("pt-BR"),
        },
        // {
        //     dataField: "id",
        //     text: "OS",
        //     formatter: (cellContent, row) => (
        //         <Link
        //             to={"/ordens-servico/" + cellContent}
        //             target={"_blank"}
        //             className="mr-3 text-primary"
        //         >
        //            # {cellContent}
        //         </Link>
        //     ),
        // },
        {
            dataField: "sales_order_id",
            text: "Ped. Venda",
            formatter: (cellContent, row) => (
                <Link
                    to={"/pedidos/" + cellContent}
                    target={"_blank"}
                    className="mr-3 text-primary"
                >
                    # {row?.sales_order_id}
                </Link>
            ),
        },
        {
            dataField: "patient_name",
            text: "Paciente",
        },
        {
            isDummyField: true,
            text: "Items",
            formatter: (id, row) => {
              return row.items.map((data, index) => {
                // Verifica os tipos de comissões existentes
                const hasExecution = data?.comission_type == ITEM_EXEC_COMISSION_TYPES.EXECUTION;
                const hasIndication = data?.comission_type == ITEM_EXEC_COMISSION_TYPES.INDICATION;
          
                return (
                  <div key={index} style={styles.itemContainer}>
                    <div style={styles.itemLine}>
                      {data?.sales_order_item_exec?.item_name} ({data?.quantity}) - 
                      R${" "}
                      {new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(getPrice(data))}{" "}
                      
                      <div style={styles.itemDetailsContainer}>
                        <span style={styles.stage}>
                          {data?.stage_id ? `(${data?.sales_order_item_exec?.stage_name})` : ""}
                        </span>
                        
                        {/* Flex container para as tags */}
                        {hasExecution && <span style={styles.tagExecution}>Comissão</span>}
                        {hasIndication && <span style={styles.tagIndication}>Indicação</span>}
                      </div>
                    </div>
                  </div>
                );
              });
            },
          },
        // {
        //     isDummyField: true,
        //     text: "Items",
        //     formatter: (id, row) => {

        //         return row.items.map((data) => {

        //             return <>
        //                 {data.item.name} ({data.quantity}) - R$ {new Intl.NumberFormat("pt-br", {
        //                                                                 style: "decimal",
        //                                                                 currency: "BRL",
        //                                                                 minimumFractionDigits: 2,
        //                                                                 maximumFractionDigits: 2
        //                                                             }).format(data.quantity * (data.sales_item_exec_comission.split_professional_value/100))
        //                                                         }
        //                                                         <br/>

        //             </>

        //         });

        //     },
        // },
        // {
        //     isDummyField: true,
        //     text: "Total Nota",
        //     formatter: (id, row) => {

        //         let total = 0;
        //         for(let i = 0; i < row.items.length; i++)
        //         {
        //             let useItem = row.items[i];
        //             total += useItem.quantity * useItem.sales_item_exec_comission.split_professional_value
        //         }

        //         return "R$ " + new Intl.NumberFormat("pt-br", {
        //             style: "decimal",
        //             currency: "BRL",
        //             minimumFractionDigits: 2,
        //             maximumFractionDigits: 2
        //         }).format(total/100)

        //     },
        // }
        {
            isDummyField: true,
            text: "Total Nota",
            formatter: (id, row) => {
                let total = 0
              
                for (let i = 0; i < row.items.length; i++) {
                    let useItem = row.items[i]
                    let value = calcValue(useItem) * 100;
                    if(value)
                    {
                        total += value;
                    }
                }
               
                return (
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(total / 100)
                )
            },
        },
    ]
}

export default RequestColumns
