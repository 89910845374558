import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Badge,
    UncontrolledTooltip,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ButtonDropdown,
} from "reactstrap"

import SALES_ORDER_STATUS from "../../../utils/consts/sales-orders-status"

const RequestColumns = (
    cancelRecord,
    setModalPaymentData,
    invoiceRecord,
    cancelInvoicesRecord
) => [
    {
        dataField: "id",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row.id}
            </Link>
        ),
    },
    {
        dataField: "patient.name",
        text: "Paciente",
    },
    {
        dataField: "created_at",
        text: "Criado em",
        formatter: (cellContent, row) =>
            row.created_at
                ? new Date(row.created_at).toLocaleDateString("pt-BR", {
                      hour: "2-digit",
                      hour12: false,
                      minute: "2-digit",
                      second: "2-digit",
                  })
                : null,
    },
    {
        dataField: "created_user.name",
        text: "Usuário",
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            let text = ""
            let badgeClass = "badge-soft-success"
            switch (cellContent) {
                case SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL:
                    text = "Aguardando Aprovação"
                    badgeClass = "badge-soft-warning"
                    break
                case SALES_ORDER_STATUS.COMPANY_NOT_APPROVED:
                    text = "Não Aprovado"
                    badgeClass = "badge-soft-danger"
                    break
                case SALES_ORDER_STATUS.AWAITING_PAYMENT:
                    text = "Aguardando Pagamento"
                    badgeClass = "badge-soft-success"
                    break
                case SALES_ORDER_STATUS.PAID:
                    text = "Pago"
                    badgeClass = "badge-soft-success"
                    break
                case SALES_ORDER_STATUS.INVOICE_ISSUED:
                    text = "Notas Fiscais Emitidas"
                    badgeClass = "badge-soft-primary"
                    break
                case SALES_ORDER_STATUS.CANCELED:
                    text = "Cancelado"
                    badgeClass = "badge-soft-danger"
                    break
                    case SALES_ORDER_STATUS.PARTIALLY_PAID:
                        text = "Parcialmente Pago"
                        badgeClass = "badge-soft-info"
                        break
            }

            return (
                <Badge className={"font-size-12 " + badgeClass} pill>
                    {text}
                </Badge>
            )
        },
    },
    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                {[SALES_ORDER_STATUS.PAID].includes(row.status) ? (
                    <Link
                        to="#"
                        className="mr-3  text-primary "
                        onClick={() => {
                            invoiceRecord(row.id)
                        }}
                    >
                        <i
                            className="fas fa-file-invoice font-size-22"
                            id={"invoicetooltip" + row.id}
                        ></i>
                        <UncontrolledTooltip
                            placement="top"
                            target={"invoicetooltip" + row.id}
                        >
                            Gerar Notas Fiscais
                        </UncontrolledTooltip>
                    </Link>
                ) : null}

                {/* {[
                    SALES_ORDER_STATUS.AWAITING_PAYMENT,
                    SALES_ORDER_STATUS.PAID,
                    SALES_ORDER_STATUS.INVOICE_ISSUED,
                    SALES_ORDER_STATUS.CANCELED,
                ].includes(row.status) ? (
                    <Link
                        to={location?.pathname + location?.search}
                        className="mr-3 text-success "
                        onClick={() => {
                            setModalPaymentData({ show: true, data: row })
                        }}
                    >
                        <i
                            className="far fa-money-bill-alt font-size-24"
                            id={"paymenttooltip" + row.id}
                        ></i>
                        <UncontrolledTooltip
                            placement="top"
                            target={"paymenttooltip" + row.id}
                        >
                            Pagamentos
                        </UncontrolledTooltip>
                    </Link>
                ) : null} */}

                <Link
                    to={{
                        pathname: "/pedidos/" + row.id,
                        state: {
                            previousPath: location?.pathname + location.search,
                        },
                    }}
                    className="mr-3 text-primary"
                >
                    <i
                        className="fas fa-edit font-size-22"
                        id={"openordertooltip" + row.id}
                    ></i>
                    <UncontrolledTooltip
                        placement="top"
                        target={"openordertooltip" + row.id}
                    >
                        Abrir
                    </UncontrolledTooltip>
                </Link>

                {[
                    SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL,
                    SALES_ORDER_STATUS.COMPANY_NOT_APPROVED,
                    SALES_ORDER_STATUS.AWAITING_PAYMENT,
                    SALES_ORDER_STATUS.PAID,
                ].includes(row.status) ? (
                    <Link
                        to="#"
                        className="mr-3 text-danger"
                        onClick={() => {
                            cancelRecord(row.id)
                        }}
                    >
                        <i
                            className="fas fa-times-circle font-size-22"
                            id={"cancelordertooltip" + row.id}
                        ></i>
                        <UncontrolledTooltip
                            placement="top"
                            target={"cancelordertooltip" + row.id}
                        >
                            Cancelar Pedido
                        </UncontrolledTooltip>
                    </Link>
                ) : null}

                {/*
                    [SALES_ORDER_STATUS.INVOICE_ISSUED].includes(row.status) ? 
                        <Link
                            to="#"
                            className="mr-3 text-danger"
                            onClick={() => {
                                cancelInvoicesRecord(row.id)
                            }}
                        >
                            <i className="fas fa-times-circle font-size-22" id={"cancelinvoicetooltip"+row.id}></i>
                            <UncontrolledTooltip placement="top" target={"cancelinvoicetooltip"+row.id}>
                                Cancelar Notas Fiscais
                            </UncontrolledTooltip>
                        </Link>
                    :  null
                    */}
            </>
        ),
    },
]

export default RequestColumns
