import request from "./config/request"


const searchModels = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.form_type) query += "&form_type=" +filter.form_type
    if (filter.specialty_id) query += "&specialty_id=" +filter.specialty_id
    // if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-models` +
            query,
        vtAuth: true,
    })
}
const deleteModel = async id => {
    return request({
        method: "DELETE",
        url: process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/medical-models/${id}`,
        vtAuth: true
    })
}
const createModel = async (data) => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-forms-models`,
        data: data,
        vtAuth: true,
    })
}
export {
   
    searchModels,
    createModel,
    deleteModel
}
