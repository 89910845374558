import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { cancelInvoice } from "services/vitta-core/professional-transfer-invoices"
import { showSweetAlertDialog, showToast } from "store/layout/actions"

const CancelInvoiceButton = ({ id, isVisible, onCancelSuccess }) => {
    const dispatch = useDispatch()

    if (isVisible === false) return <></>

    const confirmCancelInvoice = async id => {
        try {
            if (!id) return

            await cancelInvoice(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Cancelado com Sucesso!",
                    type: "success",
                })
            )

            onCancelSuccess()
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "prof_transfer_invoice_has_paid":
                        textError =
                            "Não é possível Cancelar uma Nota Paga. Cancele o pagamento primeiro."
                        break
                    case "prof_transfer_invoice_has_canceled":
                        textError = "Esta Nota já está cancelada."
                        break
                    case "prof_transfer_invoice_has_erp_sync":
                        textError =
                            "Esta Nota já foi enviada para o ERP e não pode mais ser cancelada."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }

    const performCancel = () => {
        if (id) {
            dispatch(
                showSweetAlertDialog({
                    title: "Atenção ",
                    text:
                        "Tem certeza que deseja cancelar esta Nota de Repasse?",
                    type: "warning",
                    onConfirmAction: () => {
                        confirmCancelInvoice(id)
                    },
                    showCancel: true,
                    showConfirm: true,
                    cancelBtnText: "Não",
                    confirmBtnText: "Sim",
                })
            )
        }
    }

    return (
        <Link
            to={"#"}
            className="btn btn-danger"
            style={{ width: "100%" }}
            onClick={performCancel}
        >
            Cancelar
        </Link>
    )
}

export default CancelInvoiceButton
