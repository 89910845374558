import React, { useState, useMemo } from "react"
import {
  Row,
  Col,
  Modal,
} from "reactstrap"
import Select from "react-select"

import { useDispatch, useSelector } from 'react-redux';
import { setUnitScope } from "store/scope/actions"
import { toggleSelectUnitScopeModal } from "store/layout/actions"

const SelectUnitScopeModal = props => {

  const [selectedUnit, setSelectedUnit] = useState(null)
  const dispatch = useDispatch();

  const UnitScopeModal = useSelector(
    state => state.Layout.selectUnitScopeModal
  )

  const userAuth = useSelector(state => state.auth.access?.user);

  const Scope = useSelector(state => state.scope);

  const unitsOptions = useMemo(() => {
      if (!userAuth?.units) return []

      let options_list = []
      userAuth.units.forEach(element => {
          options_list.push({ label: element.name, value: element.id })
      })
      return options_list
  }, [userAuth])

  if(!userAuth || (!UnitScopeModal.show && Scope.unit != null)){
    return (<></>)
  }

  return (
    <Row>
    <Col sm={6} md={4} xl={3} className="mt-4">
     
      <Modal
        isOpen={true}
        centered={true}
       // toggle={onCloseModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Unidade Preferencial
          </h5>
          <button
            type="button"
            onClick={() => {
              dispatch(toggleSelectUnitScopeModal(false));
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Select
            placeholder="Selecione a Unidade"
            isMulti={false}
            onChange={data => {
              setSelectedUnit(data)
            }}
            value={selectedUnit}
            options={unitsOptions}
            classNamePrefix="select2-selection"
          
          />
        </div>
        <div className="modal-footer">
                      
          <button
            type="button"
            onClick={() => {
              dispatch(toggleSelectUnitScopeModal(false));
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fechar
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => {
              if(!selectedUnit) return;
              dispatch(setUnitScope({id: selectedUnit.value, name: selectedUnit.label}));
              dispatch(toggleSelectUnitScopeModal(false));
            }}
          >
            Confirmar
          </button>
                  
      </div>
                      
     </Modal>
  </Col>
</Row>
  )
}

export default SelectUnitScopeModal
