import React, { useState, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
    Badge,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { loadDataForm } from "services/vitta-core/assessments"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import ASSESSMENTS_STATUS from "utils/consts/assessments-status"
import { useParams } from "react-router-dom"
import SERVICES_TYPES from "utils/consts/services-types"
import { create as createSalesQuote } from "services/vitta-core/sales-quote"
import SalesQuotesList from "./Components/SalesQuotesList"
import AddSalesOrderModal from "./Components/AddSalesOrderModal"
import SalesOrdersList from "./Components/SalesOrdersList"
import SALES_DOCS_TYPE from "utils/consts/sales-docs-type"
import { startNegotiation, updateData } from "services/vitta-core/assessments"
const DentalEvaluationDetails = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({
        obs: "",
    })
    const [controlAddOrderModal, setControlAddOrderModal] = useState({
        show: false,
        data: null,
    })
    const [controlSyncUpdateData, setControlSyncUpdateData] = useState(false)
    const dispatch = useDispatch()

    const loadDataPage = async () => {
        try {
            let data = await loadDataForm(id)
            setPageData(data?.data)
        } catch (e) {
            console.log(e)
        }
    }

    const handleStatus = status => {
        let text = ""
        let badgeClass = ""
        switch (status) {
            case ASSESSMENTS_STATUS.UNDER_EVALUATION:
                text = "Em Avaliação"
                badgeClass = "badge-soft-warning"
                break
            case ASSESSMENTS_STATUS.IN_NEGOCIATION:
                text = "Em Negociação"
                badgeClass = "badge-soft-info"
                break
            case ASSESSMENTS_STATUS.CONCLUDED:
                text = "Negociado"
                badgeClass = "badge-soft-success"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    const handleAddSalesQuote = async () => {
        try {
            let dataToCreate = {
                service_type: SERVICES_TYPES.DENTAL,
                assessment_id: pageData?.id,
                patient_id: pageData?.patient_id,
                unit_id: pageData?.unit_id,
                obs: `Gerado por: Avaliação odontológica #${pageData?.id}`,
                items: [],
            }
            await createSalesQuote(dataToCreate)
            await loadDataPage()
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Pré orçamento criado com sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
            if (
                e?.response?.data?.error_code == "patient_register_incomplete"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "Paciente com cadastro incompleto, preencha as informações e tente novamente...",
                        type: "warning",
                    })
                )
                return
            }
            dispatch(
                showToast({
                    title: "Ops...!",
                    text:
                        "Não conseguimos realizar esta ação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }

    const sendForNegotiation = async () => {
        try {
            await startNegotiation(id)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Negociação iniciada!",
                    type: "success",
                })
            )
            await loadDataPage()
        } catch (e) {
            console.log(e)
            await loadDataPage()
            if (e?.response?.data?.error_code == "no_sales_quotes") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "Para iniciar a negociação é necessário ter feito pelomenos um orçamento com valores!",
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showToast({
                    title: "Ops...!",
                    text:
                        "Não conseguimos iniciar a negociação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    const handleUpdateObs = async () => {
        setControlSyncUpdateData(true)
        try {
            if (!id) return
            await updateData(id, { obs: pageData?.obs })
        } catch (e) {
            console.log(e)
            dispatch(
                showToast({
                    title: "Ops...!",
                    text:
                        "Não conseguimos atualizar os campos, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
        setControlSyncUpdateData(false)
    }

    useEffect(() => {
        if (id) loadDataPage()
    }, [id])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Detalhes Avaliação"
                        breadcrumbItem="Avaliação"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="12" md="2">
                                            <FormGroup>
                                                <Label>ID Avaliação</Label>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    value={pageData?.id}
                                                    style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" md="4">
                                            <FormGroup>
                                                <Label>Avaliador</Label>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    value={
                                                        pageData?.professional
                                                            ?.name
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" md="4">
                                            <FormGroup>
                                                <Label>Paciente</Label>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    value={
                                                        pageData?.patient?.name
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" md="2">
                                            <FormGroup>
                                                <Label>Criado em</Label>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    value={new Date(
                                                        pageData?.created_at
                                                    ).toLocaleDateString(
                                                        "pt-BR"
                                                    )}
                                                    style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="5">
                                            {ASSESSMENTS_STATUS.CONCLUDED ==
                                            pageData?.status ? (
                                                <FormGroup>
                                                    <Label>
                                                        Observações{" "}
                                                    </Label>
                                                    <Input
                                                      style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                    }}
                                                        type="textarea"
                                                        rows={4}
                                                        name="obs"
                                                        value={pageData?.obs}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            ) : (
                                                <FormGroup>
                                                    <Label>
                                                        Observações{" "}
                                                        {controlSyncUpdateData ? (
                                                            <small className="text-info">
                                                                <strong>
                                                                    Sincronizando...
                                                                </strong>
                                                            </small>
                                                        ) : null}
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        onBlur={() => {
                                                            handleUpdateObs()
                                                        }}
                                                        onChange={e => {
                                                            setPageData({
                                                                ...pageData,
                                                                obs:
                                                                    e?.target
                                                                        ?.value,
                                                            })
                                                        }}
                                                        rows={4}
                                                        name="obs"
                                                        value={pageData?.obs}
                                                    />
                                                </FormGroup>
                                            )}
                                        </Col>
                                        <Col xs="12" sm="3" align="end">
                                            <FormGroup className="mt-4">
                                                <Label className="mr-4">
                                                    Status
                                                </Label>
                                                <div>
                                                    {handleStatus(
                                                        pageData?.status
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        {pageData?.status ==
                                        ASSESSMENTS_STATUS.UNDER_EVALUATION ? (
                                            <Col
                                                sm="4"
                                                align="end"
                                                className="mt-4"
                                            >
                                                <FormGroup>
                                                    <Button
                                                        type="button"
                                                        className="btn-sm btn-info waves-effect"
                                                        style={{
                                                            borderRadius: "3px",
                                                        }}
                                                        onClick={() => {
                                                            dispatch(
                                                                showSweetAlertDialog(
                                                                    {
                                                                        title:
                                                                            "Atenção ",
                                                                        text:
                                                                            "Tem certeza que deseja iniciar a negociação?",
                                                                        type:
                                                                            "warning",
                                                                        onConfirmAction: () => {
                                                                            sendForNegotiation()
                                                                        },
                                                                        showCancel: true,
                                                                        showConfirm: true,
                                                                        cancelBtnText:
                                                                            "Não",
                                                                        confirmBtnText:
                                                                            "Sim",
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <i className="fas fa-handshake mr-2"></i>{" "}
                                                        Enviar para Negociação
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        ) : null}
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            <CardTitle>
                                                Orçamentos{" "}
                                                {pageData?.sales_quotes
                                                    ?.length > 0 ? (
                                                    <small className="ml-1">
                                                        <a
                                                            href={`/relatorios/dados-odontologicos?entity_type=${SALES_DOCS_TYPE.SALES_QUOTE}&assessment_id=${pageData?.id}`}
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <i className="fas fa-print"></i>{" "}
                                                            Imprimir todos
                                                        </a>
                                                    </small>
                                                ) : null}
                                            </CardTitle>
                                        </Col>
                                        {pageData?.status ==
                                        ASSESSMENTS_STATUS.UNDER_EVALUATION ? (
                                            <Col sm="6" align="end">
                                                <Button
                                                    type="button"
                                                    size="sm"
                                                    color="info"
                                                    onClick={() => {
                                                        handleAddSalesQuote()
                                                    }}
                                                >
                                                    Adicionar
                                                </Button>
                                            </Col>
                                        ) : null}
                                    </Row>
                                    <SalesQuotesList
                                        data={pageData?.sales_quotes}
                                        show={
                                            pageData?.sales_quotes?.length > 0
                                        }
                                        assessment_status={pageData?.status}
                                        loadDataPage={loadDataPage}
                                    />
                                    {pageData?.status !=
                                    ASSESSMENTS_STATUS.UNDER_EVALUATION ? (
                                        <>
                                            <hr />
                                            <Row>
                                                <Col sm="6">
                                                    <CardTitle>
                                                        Pedidos{" "}
                                                        {pageData?.sales_orders
                                                            ?.length > 0 ? (
                                                            <small className="ml-1">
                                                                <a
                                                                    href={`/relatorios/dados-odontologicos?entity_type=${SALES_DOCS_TYPE.SALES_ORDER}&assessment_id=${pageData?.id}`}
                                                                    target="_blank"
                                                                >
                                                                    {" "}
                                                                    <i className="fas fa-print"></i>{" "}
                                                                    Imprimir
                                                                    todos
                                                                </a>
                                                            </small>
                                                        ) : null}
                                                    </CardTitle>
                                                </Col>
                                                <Col sm="6" align="end">
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        color="info"
                                                        onClick={async () => {
                                                            await loadDataPage()
                                                            setControlAddOrderModal(
                                                                {
                                                                    show: true,
                                                                    data: pageData,
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        Adicionar
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <SalesOrdersList
                                                data={pageData?.sales_orders}
                                                show={
                                                    pageData?.sales_orders
                                                        ?.length > 0
                                                }
                                                loadDataPage={loadDataPage}
                                            />
                                        </>
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <AddSalesOrderModal
                    show={controlAddOrderModal.show}
                    data={controlAddOrderModal.data}
                    quotes={pageData?.sales_quotes}
                    loadDataPage={loadDataPage}
                    onClose={() => {
                        setControlAddOrderModal({
                            show: false,
                            data: null,
                        })
                    }}
                />
            </div>
        </React.Fragment>
    )
}

export default DentalEvaluationDetails
