import request from "./config/request"

const create = async data => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/attendances`,
        data: data,
        vtAuth: true,
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/edit`,
        vtAuth: true,
    })
}

const update = async (id, attendance_data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}`,
        data: attendance_data,
        vtAuth: true,
    })
}

const deleteAttendance = async id => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}`,
        vtAuth: true,
    })
}

const search = (filter, page = 1, size = 100) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.status) query += "&status=" + filter.status

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances` +
            query,
        vtAuth: true,
    })
}

const searchDisease = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances-disease` +
            query,
        vtAuth: true,
    })
}

const searchAllergy = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances-allergy` +
            query,
        vtAuth: true,
    })
}

const searchDrugs = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances-drugs` +
            query,
        vtAuth: true,
    })
}

const searchCids = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances-cids` +
            query,
        vtAuth: true,
    })
}

const attendancesReport = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.professional_id)
        query += "&professional_id=" + filter.professional_id
    if (filter.start_created_at)
        query += "&start_created_at=" + filter.start_created_at
    if (filter.end_created_at)
        query += "&end_created_at=" + filter.end_created_at

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/report` +
            query,
        vtAuth: true,
    })
}

const attendancesReportExport = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.professional_id)
        query += "&professional_id=" + filter.professional_id
    if (filter.start_created_at)
        query += "&start_created_at=" + filter.start_created_at
    if (filter.end_created_at)
        query += "&end_created_at=" + filter.end_created_at

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/report-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

const createEntity = async (data, id) => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/entity`,
        data: data,
        vtAuth: true,
    })
}
const updateEntity = async (data, id, entity_id) => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/entity/${entity_id}`,
        data: data,
        vtAuth: true,
    })
}

const deleteEntity = async (entity_id, entity_type, attendance_id) => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${attendance_id}/entity/${entity_type}/${entity_id}`,
        data: {},
        vtAuth: true,
    })
}

const searchCertificates = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-certificates` +
            query,
        vtAuth: true,
    })
}

const searchPrescription = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-prescriptions` +
            query,
        vtAuth: true,
    })
}

const searchReports = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-reports` +
            query,
        vtAuth: true,
    })
}

const searchReferral = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-referrals` +
            query,
        vtAuth: true,
    })
}

const searchExams = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/medical-exams` +
            query,
        vtAuth: true,
    })
}
const searchChartPatient = (patient_id, filter) => {
    let query = null

    if (filter?.attendance_id) {
        query = "?attendance_id=" + filter?.attendance_id
    }

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/export-chart/${patient_id} ${query ? query : ""}`,
        vtAuth: true,
    })
}

const createReport = async (id, data) => {
    // console.log("create", data, id)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/reports`,
        data: data,
        vtAuth: true,
    })
}

const createExamItem = async (data, id) => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/exam-item`,
        data: data,
        vtAuth: true,
    })
}
const updateExamItem = async (data, id, entity_id) => {
    // console.log("create", data)
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/exam-item/${entity_id}`,
        data: data,
        vtAuth: true,
    })
}

const deleteExamItem = async (id, entity_id) => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/${id}/exam-item/${entity_id}`,
        vtAuth: true,
    })
}
const cancelAttendance = async id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances/cancel/${id}`,
        vtAuth: true,
    })
}

const removePrescriptionMemed = async (prof_id, memed_id) => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/attendances-memed/${memed_id}/${prof_id}`,
        vtAuth: true,
    })
}
const getPdfMemed = async(token_prof, memed_id) =>{
    return request({
        method:'GET',
        url:
        process.env.REACT_APP_VITTA_SERVICES_HOST+
        `/vittanet/attendances-memed/link-pdf/${token_prof}/${memed_id}`,
        vtAuth: true,
    })
}

export {
    create,
    search,
    loadDataEditform,
    update,
    deleteAttendance,
    searchDisease,
    searchAllergy,
    searchDrugs,
    searchCids,
    attendancesReport,
    createEntity,
    updateEntity,
    deleteEntity,
    searchCertificates,
    searchPrescription,
    searchExams,
    searchReports,
    createReport,
    createExamItem,
    updateExamItem,
    deleteExamItem,
    attendancesReportExport,
    searchReferral,
    searchChartPatient,
    cancelAttendance,
    removePrescriptionMemed,
    getPdfMemed
}
